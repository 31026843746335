import axios from 'axios';

export const getZavTemplate = async (caseId) => {
  try {
    const response = await axios.get('/zav_templates', { params: { case_id: caseId } });
    return response.data;
  } catch (e) {
    throw new Error('Something is wrong with our services. Hold on there or send us an email');
  }
};

export const fetchZAVTemplate = async (caseId, callbackSuccess) => {
  const { content } = await getZavTemplate(caseId);

  callbackSuccess(content);
};

export const pdfFromHTML = async (htmlContent) => {
  const data = { pdf: { content: htmlContent } };
  try {
    return await axios.post('/pdf_generations', data, {
      responseType: 'blob',
    });
  } catch (e) {
    throw new Error('Something is wrong with our services. Hold on there or send us an email');
  }
};
