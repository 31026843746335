import axios from 'axios';
import type { CaseType } from 'modules/cases/types/CaseType';

export const getCaseTypes = async (): Promise<Array<CaseType>> => {
  try {
    const { data } = await axios.get<Array<CaseType>>('/case_types');
    return data;
  } catch (e) {
    throw new Error(
      [
        'Something is wrong with our services getting the visa types. ',
        'Hold on there or send us an email',
      ].join(''),
    );
  }
};
