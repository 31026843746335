export const TALENT_TABLE_COLUMNS: {
  header: string;
  id: string;
  adminOnly?: boolean;
}[] = [
  {
    header: 'Name',
    id: 'name',
  },
  {
    header: 'Company',
    id: 'company',
    adminOnly: true,
  },
  {
    header: 'Open cases',
    id: 'open_cases',
  },
  {
    header: 'Status',
    id: 'status',
  },
  {
    header: 'Created on',
    id: 'created_on',
  },
];
