import axios, { AxiosError, AxiosResponse } from 'axios';
import { CompanyUser } from './company_user';
import { Company } from 'modules/company/types/Company';
import { CompanyBaseFields } from 'modules/company/types/CompanyBaseFields';

type CustomError = AxiosError & {
  response?: {
    data?: {
      message?: string;
    };
  };
};

type DataToSendUserUpdate = {
  user?: {
    company_user_attributes?: {
      role_name?: string;
    };
  };
};

export const postUserCreate = async (
  dataToSend: Record<string, unknown>,
  companyId: number,
): Promise<CompanyUser> => {
  try {
    const response: AxiosResponse<CompanyUser> = await axios.post(
      `/companies/${companyId}/users`,
      dataToSend,
      { headers: { Accept: 'application/json' } },
    );

    return response.data;
  } catch (err) {
    const error = err as CustomError;
    const message = error.response?.data?.message || '';

    throw new Error(`Something went wrong. ${message}`);
  }
};

export const postCompanyUpdate = async (
  dataToSend: Record<string, unknown>,
  companyId: number,
): Promise<Company> => {
  try {
    const response: AxiosResponse<Company> = await axios.put(
      `/companies/${companyId}`,
      dataToSend,
      { headers: { Accept: 'application/json' } },
    );
    return response.data;
  } catch (err) {
    const error = err as CustomError;
    const message = error.response?.data?.message || '';

    throw new Error(`Something went wrong. ${message}`);
  }
};

export const postUserUpdate = async (
  dataToSend: DataToSendUserUpdate,
  companyId: number,
  userId: number,
): Promise<CompanyUser> => {
  try {
    const response: AxiosResponse<CompanyUser> = await axios.put(
      `/companies/${companyId}/users/${userId}`,
      dataToSend,
      { headers: { Accept: 'application/json' } },
    );
    return response.data;
  } catch (err) {
    const error = err as CustomError;
    const message = error.response?.data?.message || '';

    throw new Error(`Something went wrong. ${message}`);
  }
};

export const getCompanies = async (): Promise<CompanyBaseFields[]> => {
  try {
    const response: AxiosResponse<CompanyBaseFields[]> = await axios.get('/companies', {
      headers: { Accept: 'application/json' },
    });
    return response.data;
  } catch (err) {
    const error = err as CustomError;
    const message = error.response?.data?.message || '';

    throw new Error(`Something went wrong. ${message}`);
  }
};
