import {
  Anchor,
  Avatar,
  AvatarColorOptions,
  Box,
  getAvatarColor,
  Icon,
  Text,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import React from 'react';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

type TalentCellProps = Pick<ICellRendererParams<CaseReport>, 'data'>;

export const TalentCell = ({ data }: TalentCellProps): React.JSX.Element => {
  const talentId = data?.talentId;
  const routes = useRoutes();

  const tooltip = useTooltipStore();
  const fullName = `${data?.talentFirstName} ${data?.talentLastName}`;

  return (
    <Box.div display="grid" gap="d3" gridTemplateColumns="32px 4fr">
      <Avatar color={getAvatarColor(fullName) as AvatarColorOptions} name={fullName} />

      <Box.div display="flex" flexDirection="column" justifyContent="center">
        <Box.div alignItems="center" display="flex" gap="d1_5">
          <Text.span
            display="block"
            fontSize="fontSize20"
            fontWeight="fontWeightRegular"
            lineHeight="lineHeight30"
          >
            {talentId && (
              <Anchor as={Link} to={routes.talentProfile.get(talentId)}>
                {fullName}
              </Anchor>
            )}
          </Text.span>

          {data?.talentStartAtRisk && (
            <>
              <TooltipAnchor
                render={() => (
                  <Box.button
                    appearance="none"
                    background="transparent"
                    border="none"
                    data-testid="talent-at-risk-icon"
                    display="inline-flex"
                    h="sizeIcon20"
                    margin="m0"
                    padding="p0"
                    w="sizeIcon20"
                  />
                )}
                store={tooltip}
              >
                <Icon
                  color="colorIconError"
                  decorative={false}
                  display="inline-flex"
                  icon="circle-alert"
                  size="sizeIcon20"
                  title="Talent start at risk"
                />
              </TooltipAnchor>
              <Tooltip store={tooltip}>
                {`Talent start date is within ${data.avgProcessingTimeInWeeks} weeks of embassy
                appointment date and current case status is still ongoing.`}
              </Tooltip>
            </>
          )}
        </Box.div>

        {data?.hrisEmployeeId !== null && data?.hrisEmployeeId !== undefined && (
          <Text.span display="block" fontSize="fontSize20" lineHeight="lineHeight30">
            ID: {data.hrisEmployeeId}
          </Text.span>
        )}
      </Box.div>
    </Box.div>
  );
};
