import { FormattedMetadata } from './types/FormattedMetadata';
import { UploadResult } from '@uppy/core';

export const formatMetadata = (
  response: UploadResult | undefined,
  file: File,
): FormattedMetadata => {
  const key = response?.successful[0]?.uploadURL || '';

  return {
    // Object key without prefix
    id: key.match(/\/cache\/([^\?]+)/)?.[1] || '',
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  };
};
