import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { CaseType } from 'modules/cases/types/CaseType';
import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { Country } from 'modules/countries/types/Country';
import { DocumentToTranslate } from '../../types/DocumentToTranslate';
import isUndefined from 'lodash/isUndefined';

export const useDocumentsToTranslate = ({
  caseType,
  country,
}: {
  caseType?: CaseType;
  country?: Country;
}): UseQueryResult<DocumentToTranslate[]> => {
  const contentfulAPI = new ContentfulAPI();

  return useQuery({
    queryKey: ['documents-to-translate', caseType?.name, country?.name],
    queryFn: () => contentfulAPI.fetchDocumentsToTranslate(caseType?.name, country?.name),
    enabled: !isUndefined(caseType) && !isUndefined(country),
  });
};
