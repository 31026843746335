import React, { useState } from 'react';
import { Step1Payload } from 'modules/cases/pages/createCase/types';
import { HRISTalent } from 'modules/integrations/types/HRISTalent';
import { AddNewTalent } from './AddNewTalent/AddNewTalent';
import { Box, Button, Heading, Paragraph } from '@localyze-pluto/components';
import { TalentSelect } from './TalentSelect/TalentSelect';
import { step1InitialValues } from '../../../constants/initialValues';
import { TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { MCU_CASE_CREATION } from 'modules/featureFlags/types/flags';

type Props = {
  isDemoModeActive: boolean;
  handleNextClick(payload: Step1Payload): void;
  values: Step1Payload;
  setHRISData: (talentData: HRISTalent) => void;
  setTalent: (talent: TalentSearchResult) => void;
};

export const Step1 = ({
  isDemoModeActive,
  handleNextClick,
  values,
  setHRISData,
  setTalent,
}: Props): React.JSX.Element => {
  const mcuCaseCreationIsActive = useIsFeatureFlagActive(MCU_CASE_CREATION);

  const [newTalent, setNewTalent] = useState(values.email !== '');

  if (!mcuCaseCreationIsActive || newTalent) {
    return (
      <AddNewTalent
        handleBackClick={() => setNewTalent(false)}
        handleNextClick={handleNextClick}
        isDemoModeActive={isDemoModeActive}
        setHRISData={setHRISData}
        values={values}
      />
    );
  }

  return (
    <Box.div display="flex" flexDirection="column" gap="d10">
      <Box.div>
        <Heading as="h1" color="contentPrimary" marginBottom="d4" size="heading50">
          Select or add a talent
        </Heading>
        <Paragraph>Please select a talent from your talent list.</Paragraph>

        <TalentSelect
          onSelectTalent={(talent) => {
            setTalent(talent);
            handleNextClick({
              ...step1InitialValues,
              talentId: talent.id,
              companyId: talent.company.id.toString(),
            });
          }}
        />
      </Box.div>

      <Box.div alignItems="center" display="flex" gap="d2" textAlign="center">
        <Box.div aria-hidden="true" backgroundColor="borderSecondary" flex="1" h="1px" />
        <Box.div color="contentTertiary" fontSize="fontSize10">
          or
        </Box.div>
        <Box.div aria-hidden="true" backgroundColor="borderSecondary" flex="1" h="1px" />
      </Box.div>

      <Box.div display="flex" justifyContent="center">
        <Button leadingIcon="plus" onClick={() => setNewTalent(true)} variant="secondary">
          Add new talent
        </Button>
      </Box.div>
    </Box.div>
  );
};
