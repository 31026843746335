import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryClient } from 'config/queryClient';
import { CASE_ADDITIONAL_SERVICES_QUERY_KEY } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { Case } from 'modules/cases/types/Case';

type DeleteCaseAdditionalServiceProps = {
  kase: Case;
  caseAdditionalServiceId: number;
};

async function deleteCaseAdditionalService(id: number) {
  return await axios.delete(`/case_additional_services/${id}`);
}

export const useDeleteCaseAdditionalService = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, DeleteCaseAdditionalServiceProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, DeleteCaseAdditionalServiceProps> =>
  useMutation({
    mutationFn: (props) => {
      return deleteCaseAdditionalService(props.caseAdditionalServiceId);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [CASE_ADDITIONAL_SERVICES_QUERY_KEY, String(variables.kase.id)],
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
