import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import NotoSansRegular from 'assets/fonts/NotoSans-Regular.woff';
import React, { ReactNode, useEffect } from 'react';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

const titles = [
  'Cases by state',
  'Cases by case type',
  'Cases by origin country',
  'Cases by destination country',
  'Cases by nationality',
  'Cases by case type and destination country',
];

const color = '#041162';

type ReportingGraphExportedPdfProps = {
  images: string[];
  onRender?: () => void;
  startDate?: string;
  endDate?: string;
  totalCases: number;
  activeCases: number;
  closedCases: number;
  casesAtRisk: number;
};

const HeaderStat = ({ text, value }: { text: string; value: number }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #cbd5e1',
        borderRadius: '6px',
        padding: '4px 10px',
        minWidth: '100px',
      }}
    >
      <Text
        style={{
          color: '#64748b',
          fontSize: 10,
        }}
      >
        {text}
      </Text>
      <Text
        style={{
          color: '#102ee9',
          fontSize: 16,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const GraphWithTitle = ({ image, title }: { image: string; title: string }) => {
  return (
    <View style={{ marginTop: '20px', width: '75%' }}>
      <Text style={{ color, fontSize: '10px', marginBottom: '10px' }}>{title}</Text>
      <View>
        <Image src={image} />
      </View>
    </View>
  );
};

const GraphsWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <View
      style={{
        alignItems: 'center',
        display: 'flex',
        fontFamily: 'NotoSans',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '5px',
        marginTop: '20px',
      }}
    >
      {children}
    </View>
  );
};

export const ReportinGraphsExportedPdf = ({
  images,
  onRender,
  totalCases,
  activeCases,
  closedCases,
  casesAtRisk,
  startDate,
  endDate,
}: ReportingGraphExportedPdfProps): React.JSX.Element => {
  useEffect(() => {
    Font.register({
      family: 'NotoSans',
      fonts: [
        {
          src: NotoSansRegular as string,
        },
      ],
    });
  }, []);

  return (
    <Document onRender={onRender}>
      <Page>
        <Text style={{ color, fontSize: '20px', margin: '10px 0', textAlign: 'center' }}>
          Reporting
        </Text>
        <View
          style={{
            alignItems: 'center',
            display: 'flex',
            fontFamily: 'NotoSans',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginBottom: '10px',
            gap: '10px',
          }}
        >
          <HeaderStat text="Total Cases" value={totalCases} />
          <HeaderStat text="Active Cases" value={activeCases} />
          <HeaderStat text="Closed Cases" value={closedCases} />
          <HeaderStat text="Start date at risk" value={casesAtRisk} />
        </View>
        {(startDate !== undefined || endDate !== undefined) && (
          <View
            style={{
              alignItems: 'center',
              color: '#64748b',
              display: 'flex',
              fontFamily: 'NotoSans',
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'wrap',
              marginBottom: '10px',
              gap: '10px',
            }}
          >
            <Text
              style={{
                color: '#64748b',
                fontSize: 14,
              }}
            >
              {startDate ? `From: ${formatDate(startDate)}` : ''}{' '}
              {endDate ? ` To: ${formatDate(endDate)}` : ''}
            </Text>
          </View>
        )}
        <GraphsWrapper>
          <GraphWithTitle image={images[0]} title={titles[0]} />
          <GraphWithTitle image={images[1]} title={titles[1]} />
        </GraphsWrapper>
      </Page>
      <Page>
        <GraphsWrapper>
          <GraphWithTitle image={images[2]} title={titles[2]} />
          <GraphWithTitle image={images[3]} title={titles[3]} />
          <GraphWithTitle image={images[4]} title={titles[4]} />
        </GraphsWrapper>
      </Page>
      <Page>
        <GraphsWrapper>
          <GraphWithTitle image={images[5]} title={titles[5]} />
        </GraphsWrapper>
      </Page>
    </Document>
  );
};
