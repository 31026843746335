import {
  Avatar,
  Box,
  Search,
  Table,
  TBody,
  Td,
  Th,
  ThButton,
  THead,
  Tr,
} from '@localyze-pluto/components';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import React from 'react';
import TableWrapper from './TableWrapper';
import ThButtonArrowIcon from './ThButtonArrowIcon';
import { TrafficLight } from './TrafficLight';
import { WaitingTime } from 'modules/knowledge/types/WaitingTime';
import { fuzzyFilter } from './utils';
import { lightColors } from './getTrafficLightColor';
import { useWaitingTimes } from 'modules/knowledge/api/useWaitingTimes';

const columnHelper = createColumnHelper<WaitingTime>();

export const WaitingTimes = (): React.JSX.Element => {
  const [globalFilter, setGlobalFilter] = React.useState('');
  const { data: waitingTimes } = useWaitingTimes();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<WaitingTime, string>[]>(
    () => [
      columnHelper.accessor('destinationCountry', {
        cell: (props) => (
          <Box.div alignItems="center" display="flex">
            <Avatar
              name={`${props.row.original.destinationCountry}`}
              showName
              size="small"
              src={props.row.original.countryFlagPublicUrl}
            />
          </Box.div>
        ),
        header: 'Destination',
      }),
      columnHelper.accessor('institution', {
        cell: (props) => props.getValue(),
        header: 'Institution',
      }),
      columnHelper.accessor('process', {
        cell: (props) => props.getValue(),
        header: 'Process',
      }),
      columnHelper.accessor('waitingTime', {
        cell: (props) => {
          return (
            <TrafficLight trafficLight={props.row.original.trafficLight as lightColors}>
              {props.row.original.waitingTime}
            </TrafficLight>
          );
        },
        header: 'Average waiting time',
      }),
    ],
    [],
  );

  const table = useReactTable({
    data: waitingTimes || [],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <Box.div alignItems="center" display="flex" paddingBottom="p6" w="100%">
        <Box.span paddingRight="p4" whiteSpace="nowrap">
          Average waiting times
        </Box.span>
        <Box.div w="100%">
          <Search onChange={(event) => setGlobalFilter(event.target.value)} placeholder="Search" />
        </Box.div>
      </Box.div>
      <TableWrapper>
        <Table striped>
          <THead isSticky stickyTopOffset="0">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    <ThButton onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <ThButtonArrowIcon asc />,
                        desc: <ThButtonArrowIcon />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </ThButton>
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))}
          </TBody>
        </Table>
      </TableWrapper>
    </>
  );
};
