import { BillingPeriod } from '../types/BillingPeriod';

export const isSubmitToStripeDisabled = (selectedBillingPeriod?: BillingPeriod): boolean => {
  if (selectedBillingPeriod) {
    const billingPeriodHasNeedsReviewEntries =
      (selectedBillingPeriod.entries_needs_review_count || 0) > 0;

    const billingPeriodProcessingHasStarted = selectedBillingPeriod.processing_started_at;

    const currentDate = new Date();

    const startDate = new Date(selectedBillingPeriod.start_date);
    const endDate = new Date(selectedBillingPeriod.end_date);

    const isSelectedBillingPeriodCurrentBillingPeriod =
      currentDate >= startDate && currentDate <= endDate;

    const submitToStripeDisabled =
      isSelectedBillingPeriodCurrentBillingPeriod ||
      !!billingPeriodProcessingHasStarted ||
      billingPeriodHasNeedsReviewEntries;

    return submitToStripeDisabled;
  }
  return true;
};
