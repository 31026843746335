import Card from 'deprecated/components/Card/CardContainer';
import Line from 'deprecated/components/Line/Line';

import { PageLayout } from 'modules/layout/components/PageLayout';
import React from 'react';
import StepBasicInformation from './StepBasicInformation';
import StepCompContainer from './StepCompContainer';
import { useGetStep } from './hooks/useGetStep';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { Button } from '@localyze-pluto/components';
import { useHistory } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

const StepEditor = (): React.JSX.Element => {
  const {
    fetchVariables,
    internalVariables,
    loading,
    moveComponentDown,
    moveComponentUp,
    pipeline,
    setInternalVariables,
    step,
    stepComponents,
  } = useGetStep();

  const history = useHistory();
  const routes = useRoutes();

  const goBack = () => history.push(routes.pipelineEditor.url);

  return (
    <PageLayout title="Step Editor">
      <Button leadingIcon="arrow-left" onClick={goBack} variant="ghost">
        Back to pipeline editor
      </Button>

      <Card
        display=""
        height="75vh"
        margin="20px 0px 0px 0px"
        outlined
        overflow="auto"
        padding="20px"
      >
        {step && !loading ? (
          <>
            <StepBasicInformation step={step} />
            <Line />
            <StepCompContainer
              internalVariables={internalVariables}
              onAddComponent={async (componentTypes: string[]) => {
                const variables = await fetchVariables(
                  componentTypes,
                  pipeline?.destination_country?.id,
                );

                setInternalVariables(variables);
              }}
              onMoveDown={moveComponentDown}
              onMoveUp={moveComponentUp}
              pipeline={pipeline}
              stepComponents={stepComponents}
            />
          </>
        ) : (
          <ContainedLoadingState />
        )}
      </Card>
    </PageLayout>
  );
};

export default StepEditor;
