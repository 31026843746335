import { Box, BoxProps } from '@localyze-pluto/components';
import React, { ReactNode } from 'react';

const ItemCardContent = ({
  children,
  gap,
}: {
  children: ReactNode;
  gap?: BoxProps['gap'];
}): React.JSX.Element => {
  return (
    <Box.div display="flex" flexDirection="column" gap={gap}>
      {children}
    </Box.div>
  );
};

const ItemCardAction = ({ children }: { children: ReactNode }): React.JSX.Element => {
  return <Box.div minWidth="fit-content">{children}</Box.div>;
};

const ItemCard = ({ children }: { children: ReactNode }): React.JSX.Element => {
  return (
    <Box.li
      alignItems="flex-start"
      backgroundColor="bgSecondary"
      borderRadius="borderRadius20"
      display="flex"
      gap="d4"
      justifyContent="space-between"
      padding="p4"
    >
      {children}
    </Box.li>
  );
};

ItemCard.Content = ItemCardContent;
ItemCard.Action = ItemCardAction;

export { ItemCard };
