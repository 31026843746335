import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const LocationAssessment = () => {
  useEffect(() => {
    const loadScript = (src) => {
      const tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(tag);
    };
    loadScript('https://paperform.co/__embed.min.js');
  }, []);

  return <div data-paperform-id="g9jtfaol" data-takeover="1" />;
};

export default withRouter(LocationAssessment);
