import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import React from 'react';
import { isEmpty } from 'lodash';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalStore,
} from '@localyze-pluto/components';

type FormType = {
  variableConfirmation: string;
};

const initialValues: FormType = { variableConfirmation: '' };

const validation = Yup.object({
  variableConfirmation: Yup.string().matches(/(Delete variable)/, { excludeEmptyString: false }),
});

export const DeleteConfirmationModal = ({
  state,
  onConfirm,
}: {
  onConfirm: () => void;
  state: ModalStore;
}): React.JSX.Element => {
  return (
    <Modal store={state}>
      <ModalHeader>
        <ModalHeading>Confirm deletion</ModalHeading>
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={onConfirm} validationSchema={validation}>
        {({ dirty, errors, handleChange, isValid, touched, values }) => (
          <Form>
            <ModalBody>
              <Box.div marginBottom="m4">
                You are about to delete a variable. This action can not be undone. Please type in
                “Delete variable” to confirm.
              </Box.div>
              <Input
                autoComplete="off"
                hasError={touched.variableConfirmation && !isEmpty(errors.variableConfirmation)}
                id="variableConfirmation"
                name="variableConfirmation"
                onChange={handleChange}
                placeholder="Delete variable"
                type="text"
                value={values.variableConfirmation}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={state.hide} variant="secondary">
                Cancel
              </Button>
              <Button disabled={!dirty || !isValid} type="submit" variant="destructive">
                Delete
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
