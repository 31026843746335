import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Paragraph,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import { isAxiosError } from 'axios';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useDeleteTalent } from 'modules/talent/api/useDeleteTalent/useDeleteTalent';
import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

type DeleteTalentProps = {
  talentId: Maybe<number>;
};

export const DeleteTalent = ({ talentId }: DeleteTalentProps): React.JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const routes = useRoutes();

  const { mutate: deleteTalent, isPending } = useDeleteTalent({
    onSuccess: () => {
      history.push(routes.talents.url);
    },
    onError: (error) => {
      const errorMessage = isAxiosError(error)
        ? (error as AxiosErrorWithMessage).response?.data.message
        : '';
      toast(`There was an error while deleting this Talent. ${errorMessage || ''}`, 'error');
    },
  });

  const [confirmationValue, setConfirmationValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const deleteDialog = useModalStore();
  const isDeleteDialogOpen = deleteDialog.useState('open');

  const closeDialog = () => {
    deleteDialog.hide();
    setConfirmationValue('');
  };

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setConfirmationValue(value);
    setIsValid(value === 'DELETE TALENT');
  };

  return (
    <>
      <Button onClick={deleteDialog.show} variant="destructive">
        Delete talent
      </Button>
      {isDeleteDialogOpen && (
        <Modal store={deleteDialog}>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>
            <Paragraph size="large">
              By deleting this talent, the talent, case and all information associated with them
              will be removed from Localyze. This cannot be undone.
            </Paragraph>
            <Paragraph size="large">
              Invoices are not affected. Please check with the finance team if you need to send or
              delete any invoices related to the talent.
            </Paragraph>
            <Paragraph size="large">
              To confirm you want to delete this talent, please type DELETE TALENT below:
            </Paragraph>
            <Input
              aria-label="Confirm Talent deletion"
              onChange={onChange}
              type="text"
              value={confirmationValue}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeDialog} variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={!isValid}
              loading={isPending}
              onClick={() => deleteTalent(Number(talentId))}
              variant="destructive"
            >
              Delete talent
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
