import React from 'react';
import { Anchor, Box, BoxProps, Button, Heading } from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { RequiredActionItem } from 'modules/glomoRequests/types/RequiredActionItem';
import { RequiredActionsList } from '../RequiredActionsList/RequiredActionsList';
import { RiskLevelBadge } from '../RiskLevelBadge/RiskLevelBadge';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';

export const RequiredActionsSection = ({
  requiredActions,
  userType,
  assessementUrl,
  helpUrl,
  title,
  riskLevel,
  ...props
}: BoxProps & {
  userType: 'admin' | 'hr';
  title: string;
  assessementUrl: string;
  helpUrl: string;
  riskLevel: GlomoRequestRisk;
  requiredActions: RequiredActionItem[];
}): React.JSX.Element => {
  return (
    <SectionCard {...props}>
      <SectionCard.Header padding={'d4 d6' as BoxProps['padding']}>
        <Box.div
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box.div display="flex">
            <Heading
              as="h3"
              color="contentPrimary"
              marginBottom="d0"
              marginRight="d4"
              size="title-subsection"
            >
              {title}
            </Heading>
            <RiskLevelBadge riskLevel={riskLevel} />
          </Box.div>
          {userType === 'admin' && (
            <Box.div>
              <Button
                as={Anchor}
                href={helpUrl}
                marginRight="d4"
                target="_blank"
                trailingIcon="square-arrow-out-up-right"
                variant="ghost"
              >
                Help guide
              </Button>
              <Button
                as={Box.a}
                color="colorBackground"
                href={assessementUrl}
                target="_blank"
                trailingIcon="square-arrow-out-up-right"
                variant="primary"
              >
                Go to risk assessment
              </Button>
            </Box.div>
          )}
        </Box.div>
      </SectionCard.Header>
      <SectionCard.Content>
        <Box.div w="100%">
          <RequiredActionsList
            marginBottom="d0"
            paddingLeft="d0"
            requiredActions={requiredActions}
            title=""
          />
        </Box.div>
      </SectionCard.Content>
    </SectionCard>
  );
};
