import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function CardContainer({
  outlined = false,
  children,
  minHeight = '',
  minWidth = '',
  height = '',
  width = '',
  maxHeight = '',
  maxWidth = '',
  margin = '',
  padding = '',
  alignItems = '',
  overflow = '',
  border = false,
  borderWidth = '1px',
  borderColor = '',
  display = 'flex',
  withShadow = false,
  borderRadius = '5px',
}) {
  return (
    <CardStyled
      outlined={outlined}
      minHeight={minHeight}
      minWidth={minWidth}
      height={height}
      width={width}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      margin={margin}
      padding={padding}
      alignItems={alignItems}
      overflow={overflow}
      border={border}
      borderWidth={borderWidth}
      borderColor={borderColor}
      display={display}
      withShadow={withShadow}
      borderRadius={borderRadius}
    >
      {children}
    </CardStyled>
  );
}

const getBorder = (props) => {
  if (props.outlined && !props.border) {
    return '1px solid #d8d8d8';
  }

  if (props.outlined && props.border) {
    return '1px solid var(--secondary-color-full)';
  }

  return '';
};

const CardStyled = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '')};
  margin: ${(props) => (props.margin ? props.margin : '')};
  height: ${(props) => (props.height ? props.height : '')};
  width: ${(props) => (props.width ? props.width : '')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};
  display: ${(props) => props.display};
  align-items: ${(props) => (props.alignItems ? 'center' : '')};
  border: ${(props) => getBorder(props)};
  border-width: ${(props) => props.borderWidth};
  border-color: ${(props) => props.borderColor};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
  overflow: ${(props) => (props.overflow ? 'auto' : '')};
  box-shadow: ${(props) => (props.withShadow ? 'var(--box-shadow)' : '')};
  background-color: var(--color-background-white);
`;

CardContainer.propTypes = {
  outlined: PropTypes.bool,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.any,
  alignItems: PropTypes.string,
  overflow: PropTypes.string,
  border: PropTypes.bool,
  display: PropTypes.string,
  withShadow: PropTypes.bool,
  borderRadius: PropTypes.string,
};
