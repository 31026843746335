import React, { ReactNode } from 'react';

export interface Props {
  /** Inner content of the container */
  readonly children: ReactNode;
  readonly className?: string;
}

export const Container = ({ children, className }: Props): React.JSX.Element => (
  <section className={className} data-testid="container">
    {children}
  </section>
);
