export const getCaseOverviewsParams = (
  isAdmin: boolean,
  isViewer: boolean,
  userId: number,
): string => {
  if (isViewer) {
    return '';
  }

  const commonParams = 'talent_sign_up_status=active&start_date_at_risk=false';

  return isAdmin
    ? `?process_state=case_evaluation,ongoing,waiting&case_manager_id=${userId}&${commonParams}`
    : `?process_state=ongoing,waiting&${commonParams}`;
};
