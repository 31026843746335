import axios from 'axios';
import { Message } from 'modules/talent/types/Message';

export type UpdateMessageProps = {
  messageId: number;
  pinned: boolean;
};

export const updateMessage = async ({
  messageId,
  pinned,
}: UpdateMessageProps): Promise<Message> => {
  const url = `/messages/${messageId}`;
  const { data } = await axios.patch<Message>(url, {
    message: {
      pinned,
    },
  });

  return data;
};
