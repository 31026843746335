import React from 'react';
import cx from 'clsx';
import { Sizes, Status, StepCircle } from '../Step/StepCircle';
import { Box, Icon, Menu } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';

export type CaseStepProps = {
  label: string;
  status: Status;
  divider?: boolean;
  size?: Sizes;
  previewStep?: () => void;
  openStep?: () => void;
  deleteStep?: () => void;
};

const BACKGROUND_BY_STATUS = {
  0: 'tw-text-gray-500',
  1: 'tw-text-blue-500',
  2: 'tw-text-gray-900',
};

export const CaseStep = ({
  label,
  status,
  openStep,
  deleteStep,
  previewStep,
  size = Sizes.Large,
  divider = true,
}: CaseStepProps): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const showOpenStepIcon = isAdmin && status !== Status.Current && size !== Sizes.Large;

  return (
    <Box.div
      alignItems="flex-start"
      display="flex"
      gap="d4"
      justifyContent="space-between"
      marginBottom={divider ? 'm0' : 'm4'}
    >
      <Box.div display="flex" marginRight="m4">
        <Box.div alignItems="center" display="flex" flexDirection="column">
          <StepCircle disabled={true} size={size} status={status} />
          {divider && (
            <div className="tw-flex-grow tw-w-px tw-bg-blue-400 tw-my-1" data-testid="divider" />
          )}
        </Box.div>
        <Box.div marginBottom="d5" marginLeft="m4">
          <Box.span
            className={cx(
              `tw-mr-2 ${BACKGROUND_BY_STATUS[status]} ${
                size !== Sizes.Large && 'hover:tw-underline tw-cursor-pointer'
              } `,
              {
                'tw-text-sm tw-align-top': size === Sizes.Small,
                'tw-text-lg tw-align-text-bottom': size === Sizes.Large,
              },
            )}
            onClick={previewStep}
          >
            {label}
          </Box.span>
        </Box.div>
      </Box.div>
      {showOpenStepIcon && (
        <Menu
          items={[
            {
              label: (
                <Box.div alignItems="center" display="flex" gap="d2">
                  <Icon decorative icon="check-square" size="sizeIcon20" />
                  <Box.span>Set as To-Do</Box.span>
                </Box.div>
              ),
              onClick: () => openStep && openStep(),
            },
            {
              label: (
                <Box.div alignItems="center" display="flex" gap="d2">
                  <Icon decorative icon="trash-2" size="sizeIcon20" />
                  <Box.span>Delete</Box.span>
                </Box.div>
              ),
              onClick: () => deleteStep && deleteStep(),
              disabled: status !== Status.Incomplete,
            },
          ]}
        />
      )}
    </Box.div>
  );
};

export { Sizes };
