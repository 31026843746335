import React from 'react';
import { ErrorMessage } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Input } from '@localyze-pluto/components';

export default function FormInput({ name, label, type, multiline = false, ...props }) {
  return (
    <>
      <Input
        name={name}
        id={name}
        placeholder={label}
        type={type}
        margin="dense"
        variant="outlined"
        fullWidth
        multiline={multiline}
        {...props}
      />
      <ErrorWrapper>
        <ErrorMessage name={name}>
          {(msg) => <div className={`${`error__validation__${name}`}`}>{msg}</div>}
        </ErrorMessage>
      </ErrorWrapper>
    </>
  );
}

const ErrorWrapper = styled.div`
  color: var(--color-red);
  font-size: 14px;
`;

FormInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  type: PropTypes.string,
  multiline: PropTypes.bool,
};
