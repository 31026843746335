const EMPTY_STATE_MESSAGE_BASED_ON_TAB: Record<string, string> = {
  my: 'You are currently not assigned as responsible HR Manager to any talent.',
  all: 'There are no talents to see yet. Grow your international team by adding a new case.',
  following: `You have not been added as a Follower to any talent yet. \
Please reach out to your team’s Superadmin or the assigned HR manager.`,
};

export const getCaseOverviewsEmptyMessage = (tab: string, hasAppliedFilters: boolean): string => {
  if (hasAppliedFilters) {
    return 'There are no talents that match your chosen filters. Try changing them.';
  }

  return EMPTY_STATE_MESSAGE_BASED_ON_TAB[tab];
};
