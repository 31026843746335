import React, { HTMLAttributes, useState } from 'react';
import { SelectMenuOption } from '../types/SelectMenuOption';
import { SelectMenuProps } from '../types/SelectMenuProps';
import { Arrow } from './Arrow';
import { Combobox } from '@headlessui/react';
import { DisplayedValue } from './DisplayedValue';
import { FloatingLabel } from './FloatingLabel';
import { VariantValues } from '../types/VariantValues';
import { autocompleteStyles } from './SelectMenu.styles';
import cx from 'clsx';
import { isEmpty } from 'lodash';
import { withSelectMenu } from './withSelectMenu';
import { withSelectMenuOption } from './withSelectMenuOption';
import { withSelectMenuOptions } from './withSelectMenuOptions';

export type AutocompleteProps = Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> &
  Omit<SelectMenuProps, 'error'>;

const AUTOCOMPLETE_VARIANTS: VariantValues = {
  borderless: {
    displayValueClassName: 'tw-absolute tw-inset-0 tw-pl-0 tw-pr-4 tw-py-2',
    errorMessageClasses: 'tw-text-red-600',
    inputClasses: `tw-border-0 focus:tw-ring-0 tw-bg-transparent tw-text-base tw-font-normal tw-pl-0
    focus:tw-font-medium`,
    showErrorMessage: false,
    showPlaceholder: true,
  },
  default: {
    displayValueClassName: 'tw-absolute tw-inset-0 tw-px-4 tw-py-2',
    errorMessageClasses: '',
    inputClasses: 'tw-pl-3',
    showErrorMessage: true,
    showPlaceholder: false,
  },
};

const ComboboxWrapper = withSelectMenu(Combobox);
const ComboboxOption = withSelectMenuOption(Combobox.Option);
const ComboboxOptions = withSelectMenuOptions(Combobox.Options);

const NothingFound = () => (
  <div className="tw-cursor-default tw-select-none tw-relative tw-py-2 tw-px-4">
    <span className="tw-text-sm tw-leading-5 tw-text-gray-700">Nothing found.</span>
  </div>
);

export const Autocomplete = ({
  options,
  label,
  selected,
  onSelect,
  disabled = false,
  name,
  hasError = false,
  errorMessage = '',
  placeholder = ' ',
  getDisplayValue = (option?: SelectMenuOption) => option?.label || '',
  variant = 'default',
  setHasFocus,
  elementRef,
  hasCursorPointer = false,
  ...props
}: AutocompleteProps): React.JSX.Element => {
  const [query, setQuery] = useState('');

  const lowerCasedWithoutSpaces = (value: string): string =>
    value.toLowerCase().replace(/\s+/g, '');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          lowerCasedWithoutSpaces(option.label).includes(lowerCasedWithoutSpaces(query)),
        );

  const currentVariant = AUTOCOMPLETE_VARIANTS[variant];

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (setHasFocus) setHasFocus(true);
    event.target.select();
  };

  const displayValueClassName = cx(currentVariant.displayValueClassName, {
    [currentVariant.errorMessageClasses as string]: hasError,
    'tw-text-gray-500': disabled,
  });
  const showPlaceholder = !selected && !query && currentVariant.showPlaceholder;
  const noResults = isEmpty(filteredOptions) && query !== '';

  return (
    <ComboboxWrapper
      {...props}
      disabled={disabled}
      errorEnabled={!!currentVariant.showErrorMessage}
      errorMessage={errorMessage}
      hasCursorPointer={hasCursorPointer}
      hasError={hasError}
      onChange={onSelect}
      value={selected}
    >
      {({ open }) => (
        <>
          <Combobox.Input
            autoComplete="off"
            className={cx(autocompleteStyles.input, currentVariant.inputClasses as string, {
              'group-hover:tw-cursor-pointer': hasCursorPointer,
              [`tw-border-red-600 focus:tw-border-red-600 
                  focus:tw-ring-red-600 error ${currentVariant.errorMessageClasses as string}`]:
                hasError,
              'tw-text-gray-500': disabled,
              'tw-text-gray-900': !disabled,
            })}
            displayValue={getDisplayValue}
            name={name}
            onBlur={() => setHasFocus && setHasFocus(false)}
            onChange={(event) => setQuery(event.target.value)}
            onFocus={handleFocus}
            ref={elementRef as React.Ref<HTMLInputElement>}
          />
          {showPlaceholder && (
            <DisplayedValue className={displayValueClassName} label={placeholder} />
          )}
          <Combobox.Button
            className={cx(
              `tw-truncate tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center 
                tw-bg-transparent tw-border-0`,
              { 'group-hover:tw-cursor-pointer': hasCursorPointer },
            )}
            data-testid="select-menu-button"
          >
            <Arrow hasError={hasError} open={open} />
          </Combobox.Button>

          <FloatingLabel label={label} />
          <ComboboxOptions options={filteredOptions} show={open}>
            {noResults ? (
              <NothingFound />
            ) : (
              (option) => (
                <ComboboxOption
                  disabled={false}
                  key={`select-menu-option-${option.value}`}
                  option={option}
                />
              )
            )}
          </ComboboxOptions>
        </>
      )}
    </ComboboxWrapper>
  );
};
