import { useMutation, UseMutationResult, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from 'config/queryClient';
import { AxiosError } from 'axios';
import { createAttachment } from 'modules/documents/api/attachments/createAttachment';
import { uploadToS3AndShrineIt } from 'deprecated/api/uploads/uploadToS3AndShrineIt';
import { DOCUMENTS_QUERY_KEY } from '../useDocuments/useDocuments';

type UploadAttachmentProps = {
  name: string;
  file: File;
  onProgress?: (progressPercentage: number) => void;
  caseId: number;
  onCancel?: () => void;
};

export const uploadAttachment = async ({
  name,
  file,
  caseId,
  onProgress,
  onCancel,
}: UploadAttachmentProps): Promise<Document | unknown> => {
  try {
    const shrineData = await uploadToS3AndShrineIt(file, onProgress, onCancel);

    if (!shrineData) {
      return undefined;
    }

    return createAttachment({
      name,
      file: shrineData,
      case_id: caseId,
    });
  } catch (err) {
    throw err;
  }
};

export const useUploadAttachment = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, UploadAttachmentProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, UploadAttachmentProps> =>
  useMutation({
    mutationFn: uploadAttachment,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [DOCUMENTS_QUERY_KEY] });
      onSuccess?.(data, variables, context);
    },
    onError,
  });
