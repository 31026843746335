import { DynamicField } from 'components/ControlledFormField/DynamicField';
import React from 'react';
import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';
import { useBillingCenterOptions } from 'deprecated/api/hooks/billing-centers/hooks/useBillingCenters/useBillingCenters';

export const GENERAL_COMPANY_FIELDS: DynamicField[] = [
  {
    id: 'name',
    label: 'Company Name',
    required: true,
    helpText: (
      <span className="tw-block tw-text-left">
        Used to identify your company across Localyze. This can be your brand name.
      </span>
    ),
    type: 'text',
  },
  {
    id: 'hubspot_id',
    label: 'Hubspot Company ID',
    type: 'number',
  },
  {
    id: 'industry',
    label: 'Industry',
    type: 'text',
  },
  {
    id: 'employee_count',
    label: 'Number of employees',
    type: 'number',
  },
  {
    id: 'founding_year',
    label: 'Founded (year)',
    type: 'number',
  },
];

export const useCompanyFields = (
  admin: boolean,
): {
  billingFields: DynamicField[];
  generalFields: DynamicField[];
} => {
  const { data: countryOptions } = useCountryOptions();
  const { data: billingCenterOptions } = useBillingCenterOptions();

  const billingFields: DynamicField[] = [
    {
      id: 'registered_name',
      label: 'Registered entity name',
      required: true,
      helpText: (
        <span className="tw-block tw-text-left">
          Officially registered name of this location to be used when communicating with
          authorities.
        </span>
      ),
      type: 'text',
    },
    {
      id: 'billing_center_id',
      label: 'Localyze Billing Center',
      type: 'select',
      options: billingCenterOptions || [],
      required: true,
    },
    {
      id: 'billing_invoice_email',
      label: 'Billing email',
      required: true,
      helpText: (
        <span className="tw-block tw-text-left">We will send our invoices to this email.</span>
      ),
      type: 'email',
    },
    {
      id: 'billing_street_and_number',
      label: 'Street and Number',
      required: true,
      type: 'text',
    },
    {
      id: 'billing_postal_code',
      label: 'Postal / Zip Code',
      required: true,
      type: 'text',
    },
    {
      id: 'billing_city',
      label: 'City',
      required: true,
      type: 'text',
    },
    {
      id: 'billing_country_id',
      label: 'Country',
      options: countryOptions || [],
      required: true,
      type: 'select',
    },
    {
      id: 'vat_number',
      label: 'VAT Number',
      required: true,
      type: 'text',
    },
  ];

  const generalFields = GENERAL_COMPANY_FIELDS.filter((field) => {
    return field.id !== 'hubspot_id' || admin;
  });

  return {
    billingFields,
    generalFields,
  };
};
