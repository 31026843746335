import * as Yup from 'yup';

export const LABELS = {
  autoComplete: 'Required',
  checkbox: 'Please select',
  confirmPassword: 'Confirm your password',
  confirmPasswordMatch: 'Password does not match',
  date: 'Date is Required',
  dateInvalid: 'Please enter a valid date',
  dateFormat: 'Invalid Date Format',
  dropDown: 'Please select one option',
  dropDownMulti: 'Please select one option',
  email: 'Required',
  emailInvalid: 'Invalid email address',
  filePdf: 'Please upload this file',
  password: 'Enter your password',
  passwordMin: 'Password must contain at least 8 characters',
  required: 'Required',
  terms: 'Must Accept Terms and Conditions',
  url: 'Please enter a valid URL',
};

const MIN_PASSWORD_LENGTH = 8;
const MIN_DROPDOWN_SELECT = 1;

export const Validation = {
  autoComplete: Yup.string().required(LABELS.autoComplete).nullable(),
  checkbox: Yup.boolean().oneOf([true], LABELS.checkbox),
  confirmPassword: Yup.string()
    .required(LABELS.confirmPassword)
    .oneOf([Yup.ref('password')], LABELS.confirmPasswordMatch),
  date: Yup.date().required(LABELS.date).nullable(),
  dateFormat: Yup.string()
    .matches(/\d{4}-\d{2}-\d{2}/, LABELS.dateInvalid)
    .required(LABELS.dateFormat),
  dropDown: Yup.string().required(LABELS.dropDown),
  dropDownMulti: Yup.array().min(MIN_DROPDOWN_SELECT, LABELS.dropDownMulti).required(),
  email: Yup.string().email(LABELS.emailInvalid).required(LABELS.email),
  filePdf: Yup.mixed().required(LABELS.filePdf),
  password: Yup.string().min(MIN_PASSWORD_LENGTH, LABELS.passwordMin).required(LABELS.password),
  required: Yup.mixed().required(LABELS.required),

  // I'm not sure but Yup.mixed() did not work with Pluto + React Hook Form
  requiredString: Yup.string().required(LABELS.required),
  terms: Yup.boolean().oneOf([true], LABELS.terms),
};
