import axios from 'axios';
import { OfficeWithOfficeInformation } from 'deprecated/api/companies/types/OfficeAPIResponse';
import { formattedOfficePayload } from 'modules/offices/api/useAddOffice/useAddOffice';
import { AddOfficePayload } from 'modules/offices/api/useAddOffice/useAddOffice.types';

export type UpdateOfficeProps = {
  companyId: number;
  officeId: number;
  formValues: AddOfficePayload;
};

export const updateOffice = async ({
  companyId,
  officeId,
  formValues,
}: UpdateOfficeProps): Promise<OfficeWithOfficeInformation> => {
  const payload = formattedOfficePayload(formValues);

  const response = await axios.put<OfficeWithOfficeInformation>(
    `/companies/${companyId}/offices/${officeId}`,
    payload,
    { headers: { Accept: 'application/json' } },
  );

  return response.data;
};
