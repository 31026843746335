import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { INTERNAL_VARIABLES_QUERY_KEY } from 'modules/variableEditor/api/useVariables';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

const deleteVariable = async (id: number): Promise<void> => {
  await axios.delete(`/admin/internal_variables/${id}`);
};

export const useDeleteVariable = ({
  onError,
  onSuccess,
}: {
  onError?: (error: AxiosErrorWithMessage) => void;
  onSuccess?: () => void;
}): UseMutationResult<void, AxiosErrorWithMessage, number> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteVariable,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [INTERNAL_VARIABLES_QUERY_KEY],
      });

      onSuccess?.();
    },
    onError,
  });
};
