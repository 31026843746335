import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Message } from 'modules/talent/types/Message';
import { getMessages } from './getMessages';

export const MESSAGES_QUERY_KEY = 'messages';

export const useMessages = ({
  caseId,
  internalMessage = false,
}: {
  caseId: number;
  internalMessage?: boolean;
}): UseQueryResult<Message[], AxiosError> =>
  useQuery({
    queryKey: [MESSAGES_QUERY_KEY, internalMessage, caseId],
    queryFn: () => getMessages({ caseId, internalMessage }),
    enabled: !Number.isNaN(Number(caseId)),
    throwOnError: false,
  });
