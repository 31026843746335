import {
  Box,
  Button,
  ConfirmationModal,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import Card from 'deprecated/components/Card/CardContainer';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import {
  submitDeletePipelineStep,
  submitUpdatePipelineStepOrder,
} from 'deprecated/pages/viewsNew/Admin/PipelineEditor/utils/pipeline_editor';
import AddNewStep from './AddNewStep';
import { P1 } from 'deprecated/components/Fonts';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export default function StepsList({ pipeline, hookAfterOrderStep = () => {} }) {
  const [steps, setSteps] = useState(pipeline.steps);
  const modalState = useModalStore();
  const deleteModalState = useModalStore();

  const isModalOpen = modalState.useState('open');
  const isDeleteModalOpen = deleteModalState.useState('open');

  const [stepIdToDelete, setStepIdToDelete] = useState('');
  const toast = useToast();

  useEffect(() => {
    setSteps(pipeline.steps);
  }, [pipeline]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const pipelineStepId = result.draggableId;
    const newPosition = result.destination.index + 1;

    const callbackSuccess = () => {
      hookAfterOrderStep();
      toast('Successfully updated', 'success');
    };
    const callbackError = () =>
      toast(
        'Something went wrong updating the position, please try again refreshing the page',
        'error',
      );

    submitUpdatePipelineStepOrder({
      pipelineStepId,
      newPosition,
      callbackSuccess,
      callbackError,
    });
    setSteps(reorder(steps, result.source.index, result.destination.index));
  };

  const deleteStep = (pipelineStepId) => {
    const callbackSuccess = () => {
      setSteps(steps.filter((e) => e.pipeline_step_id !== pipelineStepId));
      hookAfterOrderStep();
      setStepIdToDelete('');
    };
    submitDeletePipelineStep({ pipelineStepId, callbackSuccess });
  };

  const hookSuccessAdd = ({ stepId, pipelineStepId, title }) => {
    const newStep = { id: stepId, pipeline_step_id: pipelineStepId, title };
    setSteps(steps.concat(newStep));
    hookAfterOrderStep();
    modalState.hide();
  };

  const routes = useRoutes();
  const history = useHistory();
  const showStep = (id) => history.push(`${routes.stepEditor.get(id)}?pipelineId=${pipeline.id}`);

  if (!pipeline) return null;

  return (
    <div>
      <Card outlined display="" padding="20px" height="50vh" overflow="auto" borderRadius="0px">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {(steps || []).map((step, index) => {
                  return (
                    <Draggable
                      key={step.id}
                      draggableId={step.pipeline_step_id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <Step
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <P1>{step.title}</P1>
                          <Box.div display="flex" gap="d2">
                            <Button
                              variant="ghost"
                              iconOnly
                              leadingIcon="trash-2"
                              onClick={() => {
                                setStepIdToDelete(step.pipeline_step_id);
                                deleteModalState.toggle();
                              }}
                              aria-label="Delete"
                            />

                            <Button
                              as="a"
                              variant="ghost"
                              iconOnly
                              leadingIcon="pencil"
                              onClick={() => showStep(step.id)}
                              aria-label="Edit"
                            />
                          </Box.div>
                        </Step>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>

      <Card outlined padding="10px" display="" borderRadius="0px 0px 5px 5px">
        <Box.div marginTop="m2" marginBottom="m2" marginLeft="m2">
          <Button
            variant="secondary"
            disabled={!pipeline.id}
            onClick={modalState.show}
            leadingIcon="plus"
          >
            Add new step
          </Button>
        </Box.div>
      </Card>
      {isModalOpen && (
        <AddNewStep
          closeHook={() => modalState.hide()}
          hookSuccessAdd={hookSuccessAdd}
          pipelineId={pipeline.id}
          modalState={modalState}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmationModal
          store={deleteModalState}
          onConfirm={() => deleteStep(stepIdToDelete)}
          confirmLabel="Delete"
          destructive
        >
          Are you sure you want to delete the step? It will be removed from this pipeline.
        </ConfirmationModal>
      )}
    </div>
  );
}

StepsList.propTypes = {
  pipeline: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.string,
    destinationCountryId: PropTypes.number,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        pipeline_step_id: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
  }),
  hookAfterOrderStep: PropTypes.func,
};

const Step = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  box-shadow: var(--box-shadow);
  margin: 15px 0px 15px 0px;
  border-radius: 2.5px;
  align-items: center;
`;
