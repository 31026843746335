import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  CreateCompanyPayload,
  CreateCompanyRequestPayload,
  UseCreateCompanyProps,
  UseCreateCompanyResult,
} from './useCreateCompany.types';
import { queryClient } from 'config/queryClient';
import { ADMIN_COMPANIES_QUERY_KEY } from '../useAdminCompanies/useAdminCompanies';

const createCompany = async (payload: CreateCompanyPayload): Promise<unknown> => {
  const isTestCompany = payload.test === 'true';
  const formattedPayload: CreateCompanyRequestPayload = {
    name: payload.companyName,
    hubspot_id: payload.hubspotId ? payload.hubspotId : undefined,
    billing_center_id: payload.billingCenter,
    test: isTestCompany,
  };
  const { data } = await axios.post<unknown>('/companies', formattedPayload, {
    headers: { Accept: 'application/json' },
  });

  return data;
};

export const useCreateCompany = ({
  onSuccess,
  onError,
}: UseCreateCompanyProps): UseCreateCompanyResult => {
  return useMutation({
    mutationFn: createCompany,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [ADMIN_COMPANIES_QUERY_KEY] });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
