import { LogArgument } from 'rollbar';

type Payload = {
  request?: {
    url?: string;
  };
};

export const shouldIgnoreError = (
  isUncaught: boolean,
  _: LogArgument[],
  payload: Payload,
): boolean => {
  if (isUncaught && payload.request?.url) {
    const url = payload.request.url || '';

    // ignores errors if they have new relic (nr-data.net) url
    return /https:\/\/[a-z-]{0,}\.nr-data\.net\.*/.test(url);
  }

  return false;
};
