import React from 'react';

import { Box, Heading, useToast } from '@localyze-pluto/components';
import { UserForm } from 'modules/users/components/UserForm/UserForm';
import { useUser } from 'modules/users/api/useUser/useUser';
import { useUpdateUser } from 'modules/users/api/useUpdateUser/useUpdateUser';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { TwoFactorAuthentication } from 'deprecated/pages/views/companyusers/Profile/TwoFactorAuthentication';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';

export const UserProfile = (): React.JSX.Element => {
  const toast = useToast();
  const { id } = useCurrentUser();

  const { isPending: userPending, data: userInfo } = useUser({ params: { userId: id } });

  const { mutate: updateUser, isPending: isSubmitting } = useUpdateUser({
    onSuccess: () => {
      toast('Profile updated', 'success');
    },
    onError: (err) => {
      toast(err.response?.data.message || 'Something went wrong', 'error');
    },
  });

  return (
    <PageLayout title="Profile">
      <Box.div background="Background" borderRadius="borderRadius40" maxW={960} padding="d9">
        {userPending ? (
          <ContainedLoadingState />
        ) : (
          <>
            {userInfo && (
              <>
                <Heading as="h2" size="heading50">
                  Your information
                </Heading>
                <UserForm
                  handleFormSubmit={updateUser}
                  isSubmitting={isSubmitting}
                  values={{
                    id: userInfo.id,
                    first_name: userInfo.first_name,
                    last_name: userInfo.last_name,
                    email: userInfo.email,
                    birthdate: userInfo.birthdate || '',
                  }}
                />
              </>
            )}
            <Box.hr marginBottom="d9" marginTop="d9" />
            <TwoFactorAuthentication />
          </>
        )}
      </Box.div>
    </PageLayout>
  );
};
