import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { GlomoRequest } from '../types/GlomoRequest';
import { getGlomoRequests, UseGlomoRequestsParams } from './getGlomoRequests';

export type UseGlomoRequestsProps<Result> = Omit<
  UseQueryOptions<GlomoRequest[], AxiosError, Result>,
  'queryFn' | 'queryKey'
> & {
  params?: UseGlomoRequestsParams;
};

export const GLOMO_REQUESTS_QUERY_KEY = 'glomo-requests';

export const useGlomoRequests = <Result = GlomoRequest[]>({
  params,
}: UseGlomoRequestsProps<Result> = {}): UseQueryResult<Result> => {
  return useQuery({
    queryKey: [GLOMO_REQUESTS_QUERY_KEY, params],
    queryFn: () => getGlomoRequests(params),
  });
};
