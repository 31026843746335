import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { Case } from 'modules/cases/types/Case';
import { useOffices } from 'modules/offices/api/useOffices/useOffices';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type EditOfficeFormTypes = {
  officeId: string;
};

const validationSchema: Yup.ObjectSchema<EditOfficeFormTypes> = Yup.object({
  officeId: Yup.string().required('This field is required'),
});

export const EditOfficeModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const { office_id: officeId } = kase;
  const toast = useToast();
  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Office updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Office could not be updated', 'error'),
  });

  const { data: offices, isSuccess: isSuccessOffices } = useOffices({
    params: { companyId: kase.company_id },
  });

  const officeOptions = (offices || []).map(({ name, id }) => ({
    label: name,
    value: id.toString(),
  }));

  const { handleSubmit, control } = useForm({
    defaultValues: { officeId: officeId.toString() },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditOfficeFormTypes> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { office_id: Number(values.officeId) },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Office</ModalHeading>
      </ModalHeader>
      {!isSuccessOffices ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Box.form id="edit-office-form" onSubmit={handleSubmit(onSubmit)}>
              <ControlledFormSelect
                control={control}
                id="officeId"
                items={officeOptions}
                label="Office"
                name="officeId"
                placeholder="Select an office"
                required
              />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={modal.hide} type="button" variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={isPendingUpdateCase}
              form="edit-office-form"
              loading={isPendingUpdateCase}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
