import { SystemProp, Theme } from '@localyze-pluto/theme';
import { MessageType } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/MessageType';

export const getMessageItemBackgroundColor = (
  type: MessageType,
):
  | {
      backgroundColor?: SystemProp<keyof Theme['colors'], Theme>;
    }
  | undefined => {
  switch (type) {
    case 'hr_message': {
      return {
        backgroundColor: 'bgSecondary',
      };
    }

    case 'internal':
    case 'admin_message': {
      return {
        backgroundColor: 'bgInfo',
      };
    }
  }
};
