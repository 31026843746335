import { Section, SectionProps } from '../Section/Section';

import { CaseEvaluationSummarySection } from 'modules/pipelines/pages/caseEvaluationSummary/types/CaseEvaluationSummarySection';
import { Checkbox } from '@localyze-pluto/components';
import { EditorWithMacro } from 'modules/pipelines/pages/caseEvaluationSummary/components/EditorWithMacro/EditorWithMacro';
import React from 'react';
import { convertMacrosToItems } from 'modules/pipelines/pages/caseEvaluationSummary/utils/convertMacrosToItems/convertMacrosToItems';
import { useFormContext } from 'react-hook-form';
import { useMacros } from 'modules/pipelines/pages/caseEvaluationSummary/content/useMacros/useMacros';

export const FamilySupport = ({
  disabled,
  title,
}: Pick<SectionProps, 'disabled' | 'title'>): React.JSX.Element => {
  const { register, setValue, unregister, watch } = useFormContext();

  const { data: macros } = useMacros({ section: CaseEvaluationSummarySection.FamilySupport });
  const watchedShowFamilySupport = watch('family_support_section') as boolean;

  const handleShowFamilySupport = (value: boolean) => {
    if (!value) {
      unregister('family_support_comment', { keepDirty: false });
    }
    setValue('family_support_section', value);
  };

  return (
    <Section
      description="Please select the checkbox below to show this section to your talent if they
    will be accompanied by their family members. You can customise the message template
    below or send it as it is."
      title={title}
    >
      <Checkbox
        checked={watchedShowFamilySupport}
        disabled={disabled}
        id="family_support_section"
        onCheckedChange={handleShowFamilySupport}
        {...register('family_support_section')}
      >
        Show this section to your talent
      </Checkbox>

      <EditorWithMacro
        disabled={disabled || !watchedShowFamilySupport}
        items={convertMacrosToItems(macros)}
        label="Message"
        name="family_support_comment"
        rules={{
          required: { value: watchedShowFamilySupport, message: 'This field is required.' },
        }}
      />
    </Section>
  );
};
