import axios from 'axios';
import { StepComponentResponse } from 'deprecated/api/steps/types/StepComponentResponse';

export const moveDown = async (
  stepId: number,
  componentId: number,
): Promise<StepComponentResponse[]> => {
  const res = await axios.patch<StepComponentResponse[]>(
    `/admin/steps/${stepId}/components/${componentId}/move_down`,
  );
  return res.data;
};
