import { Box, Heading, ListItem, UnorderedList } from '@localyze-pluto/components';
import { Section, SectionProps } from '../Section/Section';

import { CaseEvaluationSummarySection } from 'modules/pipelines/pages/caseEvaluationSummary/types/CaseEvaluationSummarySection';
import { DocumentRequirementsTable } from './components/DocumentRequirementsTable';
import { EditorWithMacro } from 'modules/pipelines/pages/caseEvaluationSummary/components/EditorWithMacro/EditorWithMacro';
import React from 'react';
import { convertMacrosToItems } from 'modules/pipelines/pages/caseEvaluationSummary/utils/convertMacrosToItems/convertMacrosToItems';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import { useCaseType } from 'modules/cases/api/useCaseType/useCaseType';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { useDocumentsToTranslate } from 'modules/pipelines/pages/caseEvaluationSummary/content/useDocumentsToTranslate/useDocumentsToTranslate';
import { useMacros } from 'modules/pipelines/pages/caseEvaluationSummary/content/useMacros/useMacros';
import { useParams } from 'react-router-dom';
import { usePipelineRequiredDocuments } from 'modules/pipelines/hooks/usePipelineRequiredDocuments';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCase } from 'modules/cases/api/useCase/useCase';

export const RequiredDocuments = ({
  title,
  disabled,
}: Pick<SectionProps, 'disabled' | 'title'>): React.JSX.Element => {
  const { caseId } = useParams<{ caseId: string }>();

  const {
    queryParams: { pipelineId },
  } = useQueryParams();

  const { data: documents, isSuccess: isRequiredDocumentsSuccess } = usePipelineRequiredDocuments(
    Number(pipelineId),
  );
  const documentsProvidedByTalent = filter(documents, { provided_by_admin: false });
  const documentsProvidedByAdmin = filter(documents, { provided_by_admin: true });

  const { data: kase } = useCase({ caseId: Number(caseId) });
  const { data: caseType } = useCaseType(Number(kase?.case_type_id));
  const { data: countries } = useCountries({});
  const country = find(countries, (country) => country.id === kase?.destination_country_id);
  const { data: documentsToTranslate, isSuccess: isDocumentsToTranslateSuccess } =
    useDocumentsToTranslate({ caseType, country });
  const { data: macros } = useMacros({ section: CaseEvaluationSummarySection.RequiredDocuments });

  return (
    <Section description="Required documents based on the selected pipeline." title={title}>
      {isRequiredDocumentsSuccess ? (
        <>
          {!isEmpty(documentsProvidedByTalent) && (
            <Box.section aria-label="Documents provided by the talent">
              <Heading as="h3" fontSize="fontSize20" lineHeight="lh8" marginBottom="d0">
                Provided by the talent
              </Heading>
              <UnorderedList marginBottom="d0">
                {map(documentsProvidedByTalent, (document) => (
                  <ListItem key={uniqueId('document')}>{document.name}</ListItem>
                ))}
              </UnorderedList>
            </Box.section>
          )}
          {!isEmpty(documentsProvidedByAdmin) && (
            <Box.section aria-label="Documents provided by the case manager">
              <Heading as="h3" fontSize="fontSize20" lineHeight="lh8" marginBottom="d0">
                Provided by the case manager
              </Heading>
              <UnorderedList marginBottom="d0">
                {map(documentsProvidedByAdmin, (document) => (
                  <ListItem key={uniqueId('document')}>{document.name}</ListItem>
                ))}
              </UnorderedList>
            </Box.section>
          )}
        </>
      ) : (
        <ContainedLoadingState />
      )}

      {isDocumentsToTranslateSuccess ? (
        <Box.div display="flex" flexDirection="column" gap="d4">
          <Heading
            as="h3"
            fontSize="fontSize30"
            fontWeight="fontWeightMedium"
            lineHeight="lh8"
            marginBottom="d0"
          >
            Required documents that need to be translated and/or legalised
          </Heading>
          <DocumentRequirementsTable documents={documentsToTranslate} />
        </Box.div>
      ) : (
        <ContainedLoadingState />
      )}

      <EditorWithMacro
        disabled={disabled}
        items={convertMacrosToItems(macros)}
        label="Additional comments (optional)"
        name="required_documents_comment"
      />
    </Section>
  );
};
