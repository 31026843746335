import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { completeGlomoRequest } from './completeGlomoRequest';
import { CompleteGlomoRequestValues } from 'modules/glomoRequests/types/CompleteGlomoRequestValues';
import { GLOMO_REQUEST_QUERY_KEY } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { RequiredActionId } from 'modules/glomoRequests/components/RequiredActions/RequiredActions';
import { RequiredActionItem } from 'modules/glomoRequests/types/RequiredActionItem';
import { PENDING_COUNTERS_KEY } from 'modules/home/api/usePendingCounters';

export const useCompleteGlomoRequest = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}): UseMutationResult<
  GlomoRequest,
  unknown,
  {
    values: Omit<CompleteGlomoRequestValues, RequiredActionId> & {
      requiredActions: RequiredActionItem[];
    };
    glomoRequestId: number;
  }
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ values, glomoRequestId }) =>
      completeGlomoRequest({
        values,
        glomoRequestId,
      }),
    onSuccess: (glomoRequest) => {
      queryClient.invalidateQueries({
        queryKey: [GLOMO_REQUEST_QUERY_KEY, glomoRequest.id],
      });
      queryClient.invalidateQueries({
        queryKey: [PENDING_COUNTERS_KEY],
      });

      onSuccess?.();
    },
    onError,
  });
};
