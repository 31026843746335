import axios from 'axios';
import { CaseEvaluationSummary } from '../../types/CaseEvaluationSummary';

export const getCaseEvaluationSummary = async (
  caseEvaluationSummaryId: number,
): Promise<CaseEvaluationSummary> => {
  const url = `/case_evaluation/summaries/${caseEvaluationSummaryId}`;
  const { data } = await axios.get<CaseEvaluationSummary>(url);
  return data;
};
