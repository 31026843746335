import { FormikValues } from 'formik';
import { CaseInformationSubmission } from 'modules/pipelines/hooks/useSubmitCaseStep';

/* FILTER NULL VALUES COMING FROM FORMIK */
export const formikValuesToSubmitStepInformation = (
  data: FormikValues,
): CaseInformationSubmission[] =>
  Object.entries(data)
    .filter(([key]) => key.includes('|cd_'))
    .filter(([_key, value]) => ![null, undefined].includes(value))
    .map(([key, value]) => {
      const referenceCode = key.split('|')[1];

      return {
        internal_reference_code: referenceCode,
        value: value as unknown,
      };
    });
