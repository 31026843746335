import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { ModalMode } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/ModalMode';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';
import { getVariablePayload } from 'deprecated/variablesEditor/components/VariablesEditor/getVariablePayload';
import { parseErrorMessages } from 'deprecated/variablesEditor/utils/parseErrorMessages';
import { ToastContextProps, useToast } from '@localyze-pluto/components';
import { VariableFormValues } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/VariableFormValues';
import { useDeleteVariable } from 'modules/variableEditor/api/useDeleteVariable';
import { useCreateVariable, VariablePayload } from 'modules/variableEditor/api/useCreateVariable';
import { useUpdateVariable } from 'modules/variableEditor/api/useUpdateVariable';

type Arguments = {
  onSuccess: () => void;
};

type FunctionArgs = {
  mode: ModalMode;
  formValues?: VariableFormValues;
  currentVariable?: Variable;
  isDeletingVariable: boolean;
};

type HandeVariableModification = ({}: FunctionArgs) => void;

type HookReturn = {
  handleVariableModification: HandeVariableModification;
};

export const errorToast = (
  error: AxiosErrorWithMessage,
  toast: ToastContextProps['toast'],
): void => {
  parseErrorMessages(error).map((errorMessage) => {
    toast(errorMessage as string, 'error');
  });
};

export const useVariableModification = ({ onSuccess }: Arguments): HookReturn => {
  const toast = useToast();

  const { mutate: deleteVariable } = useDeleteVariable({
    onSuccess: () => {
      toast('The variable was successfully deleted!', 'success');
      onSuccess();
    },
    onError: (error) => errorToast(error, toast),
  });

  const { mutate: createVariable } = useCreateVariable({
    onSuccess: () => {
      toast('A new variable was created successfully!', 'success');
      onSuccess();
    },
    onError: (error) => errorToast(error, toast),
  });

  const { mutate: updateVariable } = useUpdateVariable({
    onSuccess: () => {
      toast('Your changes were saved successfully!', 'success');
      onSuccess();
    },
    onError: (error) => errorToast(error, toast),
  });

  const handleVariableModification = async ({
    mode,
    formValues,
    currentVariable,
    isDeletingVariable,
  }: FunctionArgs) => {
    if (['createVariable', 'createGroup'].includes(mode) && formValues) {
      const variablePayload = getVariablePayload(formValues);
      await createVariable(variablePayload);
    }

    if (mode === 'editVariable' && formValues && currentVariable && !isDeletingVariable) {
      const variablePayload: Partial<VariablePayload> = {
        value: formValues.variableValue,
      };
      if (currentVariable.variable_type === 'link') {
        variablePayload['link_title'] = formValues.linkTitle;
        variablePayload['link_label'] = formValues.linkLabel;
      }
      await updateVariable({ id: currentVariable.id, params: variablePayload });
    }

    if (mode === 'editVariable' && currentVariable && isDeletingVariable) {
      await deleteVariable(currentVariable.id);
    }
  };

  return { handleVariableModification };
};
