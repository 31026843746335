import { FileProgress, UploadResult } from '@uppy/core';

import { uppy } from './setup';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';

const progressValues = {
  start: 0,
  minimium: 0.1,
  almost: 98,
  close: 99,
  finish: 100,
};

export const uploadToS3 = async (
  file: File,
  onProgress?: (progressPercentage: number) => void,
  onCancel?: () => void,
  onError?: () => void,
): Promise<UploadResult | undefined> => {
  const fileId = uppy.addFile({
    data: file,
    isRemote: false,
    name: file.name,
    source: 'Local',
    type: file.type,
  });

  const onCancelCallback = () => {
    onCancel?.();
    onProgress?.(progressValues.start);
  };

  const onProgressCallback = (_: unknown, progress: FileProgress) => {
    let progressPercentage = (progress.bytesUploaded / progress.bytesTotal) * 100;

    if (progressPercentage < progressValues.minimium) {
      progressPercentage = progressValues.minimium;
    }

    if (progressPercentage > progressValues.almost) {
      progressPercentage = progressValues.close;
    }

    onProgress?.(progressPercentage);
  };

  const onUploadSuccessCallback = () => onProgress?.(progressValues.finish);

  uppy.on('upload-success', onUploadSuccessCallback);
  uppy.on('upload-progress', onProgressCallback);
  uppy.on('cancel-all', onCancelCallback);

  onProgress?.(progressValues.minimium);

  try {
    // Response object can be undefined if uploads are cancelled
    const response = (await uppy.upload()) as UploadResult | undefined;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (response?.failed?.length) {
      throw response.failed[0]?.error;
    }

    uppy.removeFile(fileId);
    uppy.off('upload-progress', onProgressCallback);
    uppy.off('cancel-all', onCancelCallback);

    return response;
  } catch (err) {
    const error = err as Error;
    uppy.off('upload-progress', onProgressCallback);
    uppy.off('cancel-all', onCancelCallback);
    uppy.removeFile(fileId);

    onError?.();
    rollbarInstance.error(`Error uploading to S3: ${error}`);

    throw err;
  }
};

export const cancelAllUploadsToS3 = (): void => {
  uppy.cancelAll();
};

export const cancelUploadToS3 = (name: string): void => {
  const state = uppy.getState();

  Object.keys(state.files).forEach((id) => {
    if (state.files[id].name === name) {
      uppy.removeFile(id);
    }
  });
};
