import FormFields from 'deprecated/components/FormFields';
import InfoBlock from 'deprecated/components/InfoBlock/InfoBlock';
import { Form, Formik } from 'formik';
import { P2 } from 'deprecated/components/Fonts';
import PropTypes from 'prop-types';
import React from 'react';
import { SingleDocumentContainer } from '../../DocumentsContainer/SingleDocumentContainer';
import { Box, Button } from '@localyze-pluto/components';

export const buildInitialValues = (components) => {
  const tempInitialValues = {};

  components.forEach((component) => {
    switch (component.component_type) {
      case 'text':
        tempInitialValues[component.target] = component.dataSubmitted || '';
        break;
      case 'checkbox':
        tempInitialValues[component.target] =
          ['true', true, 't'].includes(component.dataSubmitted) || false;
        break;
      case 'datetime':
        tempInitialValues[component.target] = component.dataSubmitted;
        break;
      case 'checkboxAppointmentEmbassy':
        tempInitialValues[component.target] =
          ['true', true, 't'].includes(component.dataSubmitted) || false;
        break;
      case 'datetimeAppointmentEmbassy':
        tempInitialValues[component.target] = component.dataSubmitted;
        break;
      case 'document':
      case 'information':
        break;
      default:
        tempInitialValues[component.target] = '';
        break;
    }
  });
  return tempInitialValues;
};

export const getStepsComponents = (components, caseId) =>
  components.map((component) => {
    switch (component.component_type) {
      case 'text': {
        return (
          <Box.div key={component.id} marginBottom="d5">
            <FormFields fieldType="input" name={component.target} label={component.content} />
          </Box.div>
        );
      }
      case 'description':
        return (
          <div key={component.id} style={{ marginBottom: '35px' }}>
            <div dangerouslySetInnerHTML={{ __html: component.content }} />
          </div>
        );

      case 'checkbox': {
        return (
          <div key={component.id} style={{ marginBottom: '10px' }}>
            <FormFields
              fieldType="checkbox"
              wrapper
              name={component.target}
              id={component.target}
              label={<P2>{component.content}</P2>}
            />
          </div>
        );
      }

      case 'datetime':
        return (
          <Box.div key={component.id} marginBottom="d5">
            <FormFields fieldType="dateTime" name={component.target} label={component.content} />
          </Box.div>
        );

      case 'checkboxAppointmentEmbassy': {
        return (
          <div key={component.id} style={{ marginBottom: '10px' }}>
            <FormFields
              fieldType="checkbox"
              wrapper
              name={component.target}
              id={component.target}
              label={<P2>No appointment available</P2>}
            />
          </div>
        );
      }

      case 'datetimeAppointmentEmbassy':
        return (
          <Box.div key={component.id} marginBottom="d5">
            <FormFields
              data-testid="datetimeAppointmentEmbassy"
              fieldType="dateTime"
              name={component.target}
              label={component.content}
            />
          </Box.div>
        );

      case 'information':
        return (
          <div key={component.id} style={{ marginBottom: '35px' }}>
            <InfoBlock info={component.content} />
          </div>
        );

      case 'link':
        return (
          <div key={component.id} style={{ marginBottom: '35px' }}>
            <Button
              variant="outline"
              target="_blank"
              href={component.content}
              rel="noopener noreferrer"
              as="a"
            >
              Click here
            </Button>
          </div>
        );

      case 'paperform':
        return (
          <div
            key={component.id}
            style={{
              marginBottom: '35px',
              height: '100%',
            }}
          >
            <iframe
              src={`https://${component.content}.paperform.co?case_id=${caseId}`}
              title="paper-form"
              width="100%"
              style={{
                border: 0,
                height: 450,
              }}
            />
          </div>
        );

      case 'document': {
        return (
          <div key={component.id}>
            <SingleDocumentContainer
              document={component.document}
              caseId={caseId}
              outlined={false}
              padding={'0 10px'}
              title={component.content}
            />
          </div>
        );
      }

      default:
        return <p key={component.id} />;
    }
  });

export default function MapStepComponents({ caseId, components, onSubmit, Buttons }) {
  const initialValues = buildInitialValues(components);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        {components.length !== 0 && <div>{getStepsComponents(components, caseId)}</div>}
        {Buttons}
      </Form>
    </Formik>
  );
}

export const validationRequestAppointmentException = (paramsToSend) => {
  const internalReferenceCodes = Object.keys(paramsToSend);
  const containsRequestAppointmentReference = internalReferenceCodes
    .join('')
    .includes('cd_datetime_date_appointment_embassy_cd');

  if (!containsRequestAppointmentReference) return true;

  const isValidAppointmentDateAndCheckBox = () => {
    const getKeyInParamsToSend = (key) => internalReferenceCodes.find((e) => e.includes(key));
    const appointmentValue =
      paramsToSend[getKeyInParamsToSend('cd_datetime_date_appointment_embassy_cd')];
    const noAppointmentSelected = [null, '', undefined].includes(appointmentValue);
    const checkboxTrue = paramsToSend[getKeyInParamsToSend('cd_no_embassy_appointment_cd')];
    const passValidation1 = noAppointmentSelected && checkboxTrue;
    const passValidation2 = !noAppointmentSelected && !checkboxTrue;

    return passValidation1 || passValidation2;
  };
  return isValidAppointmentDateAndCheckBox();
};

MapStepComponents.propTypes = {
  internationalId: PropTypes.string,
  Buttons: PropTypes.any,
  onSubmit: PropTypes.func,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      component_type: PropTypes.string,
      content: PropTypes.string,
      user_type: PropTypes.string,
    }),
  ),
  children: PropTypes.any,
};
