export const MAX_FILE_SIZE = 50 * 1024 * 1024;
export const TWELVE_MB = 12 * 1024 * 1024;
export const THREE_MB = 3 * 1024 * 1024;
export const FIVE_HUNDRED_KB = 500 * 1024;

export const PDF_EXTENSION = {
  'application/pdf': ['.pdf'],
};

export const IMAGE_EXTENSION = {
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
};
