import React, { ReactNode, useContext } from 'react';
import { ExtendedUser, User } from 'deprecated/api/user/types/User';
import { UserRoleName } from 'modules/users/types/UserRoleName';

const CurrentUserContext = React.createContext<ExtendedUser | undefined>(undefined);

export const useCurrentUser = (): ExtendedUser => {
  const user = useContext(CurrentUserContext);
  if (!user) {
    throw new Error('CurrentUserContext: No value provided');
  }

  return user;
};

export const extendUser = (user: User): ExtendedUser => {
  const isAdmin = user.localyze_admin;

  return {
    ...user,
    isAdmin,
    isHR: !isAdmin,
    isHrViewer: user.user_role === UserRoleName.Viewer,
    isHrAdmin: user.user_role === UserRoleName.SuperAdmin,
  };
};

export const CurrentUserProvider = ({
  user,
  children,
}: {
  children: ReactNode;
  user: User;
}): React.JSX.Element => (
  <CurrentUserContext.Provider value={extendUser(user)}>{children}</CurrentUserContext.Provider>
);
