import { Filter } from 'modules/filters/types/Filter';
import { COMPANY_FILTER } from 'deprecated/libs/filters';
import { Country } from 'modules/countries/types/Country';
import { AUTOMATICALLY_UPDATED_FILTER } from '../filters/automaticallyUpdatedFilter';
import { CASE_MANAGER_FILTER } from '../filters/caseManagerFilter';
import { CASE_TYPE_FILTER } from '../filters/caseTypeFilter';
import { destinationCountryFilter } from '../../filters/destinationCountryFilter';
import { officeFilter } from '../filters/officeFilter';
import { CASE_STATE_FILTER } from '../filters/caseStateFilter';
import { SORT_BY_FILTER } from '../filters/sortByFilter';
import { START_DATE_AT_RISK_FILTER } from 'modules/caseOverview/filters/startDateAtRiskFilter';
import { TALENT_SIGN_UP_STATUS_FILTER } from '../filters/talentSignUpStatusFilter';

export const getCaseOverviewsFilters = (
  companyId: number,
  countries: Country[],
): Filter<string>[] => [
  SORT_BY_FILTER,
  AUTOMATICALLY_UPDATED_FILTER,
  CASE_MANAGER_FILTER,
  CASE_STATE_FILTER,
  CASE_TYPE_FILTER,
  COMPANY_FILTER,
  destinationCountryFilter(countries),
  officeFilter(companyId),
  START_DATE_AT_RISK_FILTER,
  TALENT_SIGN_UP_STATUS_FILTER,
];
