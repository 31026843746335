import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { ChangeEvent, useEffect, useState } from 'react';

export type UseSearchResult = {
  search: string;
  debouncedSearch: string;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const useSearch = (): UseSearchResult => {
  const { addQueryParam, removeQueryParam, queryParams } = useQueryParams();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useEffect(() => {
    const updateSearch = () => {
      setDebouncedSearch(search);
      !!search ? addQueryParam('search', search) : removeQueryParam('search');
    };

    const debouncedUpdateSearch = setTimeout(updateSearch, 300);

    // Prevent debounced value from updating if value is changed within delay timeout.
    return () => clearTimeout(debouncedUpdateSearch);
    // adding the queryParams functions would break setting the pagination
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    addQueryParam('page', '1');
  };

  useEffect(() => {
    if (queryParams.search !== search) setSearch(queryParams.search || '');
    // when loading the page from a link we want to set the search value from the params only once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    search,
    debouncedSearch,
    onSearch,
  };
};
