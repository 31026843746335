import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeading,
  DrawerStore,
  Heading,
  Icon,
  IconProps,
  Paragraph,
} from '@localyze-pluto/components';
import { StackOneCompanyIntegration } from 'modules/integrations/types/HRISCompanyIntegration';
import React, { ReactNode } from 'react';

const Section = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: IconProps['icon'];
  children: ReactNode;
}) => {
  return (
    <Box.section
      backgroundColor="bgSecondary"
      display="flex"
      flexDirection="column"
      gap="d4"
      padding="p4"
    >
      <Box.div alignItems="center" display="flex" gap="d2">
        <Icon decorative icon={icon} size="sizeIcon30" />
        <Heading as="h3" fontSize="titleBody" marginBottom="m0">
          {title}
        </Heading>
      </Box.div>
      <Box.div>{children}</Box.div>
    </Box.section>
  );
};

const List = ({ items, columnCount = 2 }: { items: string[]; columnCount?: number }) => {
  return (
    <Box.ul marginBottom="m0" maxH={140} paddingLeft="d14" style={{ columnCount }}>
      {items.map((item) => (
        <Box.li fontSize="fontSize20" fontWeight="fontWeightRegular" key={item} maxW={215}>
          {item}
        </Box.li>
      ))}
    </Box.ul>
  );
};

export const SyncedDataDrawer = ({
  state,
  integration,
}: {
  state: DrawerStore;
  // TODO: replace this type once feature flag is removed
  integration?: StackOneCompanyIntegration & {
    provider_data?: StackOneCompanyIntegration['provider_data'] & {
      stackone_file_category_label?: string;
    };
  };
}): React.JSX.Element => {
  return (
    <Drawer store={state}>
      <DrawerHeader>
        <DrawerHeading>Synced data</DrawerHeading>
      </DrawerHeader>
      <DrawerBody>
        <Box.div display="flex" gap="d2" marginBottom="m6">
          <Heading
            as="h2"
            color="colorTextStrongest"
            fontSize="titleBody"
            fontWeight="fontWeightRegular"
            marginBottom="m0"
            paddingTop="p6"
          >
            Bidirectional data transfer between the Human Resources Information System (HRIS) and
            Localyze.
          </Heading>
        </Box.div>
        <Box.div display="flex" flexDirection="column" gap="d4">
          <Section icon="cloud-download" title="Data requested by Localyze">
            <List
              items={[
                'First name',
                'Last name',
                'Date of birth',
                'Citizenship(s)',
                'Country of residence',
                'Destination country',
                'Email',
                'Job title',
                'Start date',
                'Job description',
                'Employee ID',
                'Hiring manager',
                'Cost center',
              ]}
            />
          </Section>
          <Section icon="cloud-upload" title="Data sent to HRIS">
            <List
              columnCount={1}
              items={['Visa scan', 'Residence permit scan', 'Health insurance scan']}
            />
          </Section>
          <Section icon="shield-alert" title="Security and privacy">
            <Paragraph fontSize="fontSize20" lineHeight="lh5" marginBottom="d0" marginLeft="d8">
              We partner with the unified-API provider StackOne for advanced data privacy. It&apos;s
              an embedded unified-API integration platform that never stores the customers&apos;
              data by default.
            </Paragraph>
          </Section>
          {integration?.provider_data.stackone_file_category_label && (
            <Section icon="paperclip" title="Selected File Category">
              <Paragraph fontSize="fontSize20" lineHeight="lh5" marginBottom="d0" marginLeft="d8">
                {integration.provider_data.stackone_file_category_label}
              </Paragraph>
            </Section>
          )}
        </Box.div>
      </DrawerBody>
    </Drawer>
  );
};
