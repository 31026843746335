import { Link } from 'react-router-dom';
import React from 'react';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { TalentSearchResultWrapper } from 'components/TalentSearch/TalentSearchResultOption';
import { TalentSearch } from 'components/TalentSearch/TalentSearch';
import { Anchor } from '@localyze-pluto/components';

const TalentProfileLink: TalentSearchResultWrapper = ({ talent, children }) => {
  const routes = useRoutes();

  return (
    <Anchor
      as={Link}
      display="block"
      onClick={() => trackEvent('topbar: select talent from search')}
      textDecoration="none"
      to={routes.talentProfile.get(talent.id)}
    >
      {children}
    </Anchor>
  );
};

export const TalentProfileSearch = (): JSX.Element => (
  <TalentSearch resultWrapper={TalentProfileLink} />
);
