import {
  Box,
  Input,
  Label,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import React from 'react';
import { addDays, format } from 'date-fns';
import { useUID } from 'react-uid';
import { useCase } from 'modules/cases/api/useCase/useCase';

type WaitingUntilProps = {
  setWaitingUntil: (value: string) => void;
  waitingUntil: string;
  caseId: number;
};

export const WaitingUntilDatePicker = ({
  waitingUntil,
  setWaitingUntil,
  caseId,
}: WaitingUntilProps): React.ReactElement => {
  const tooltip = useTooltipStore({
    defaultOpen: false,
    placement: 'top',
  });
  const { data: kase } = useCase({ caseId });

  const oneDay = 1;
  const today = new Date();
  const tomorrow = addDays(today, oneDay);
  const formattedDateTomorrow = format(tomorrow, 'yyyy-MM-dd');

  const onDateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const date = ev.target.value;
    setWaitingUntil(date);
  };

  const caseIsClosed = 'closed' === kase?.current_state;
  const inputID = useUID();

  const datePickerProps = {
    'data-testid': 'waiting-until',
    disabled: caseIsClosed,
    id: inputID,
    min: formattedDateTomorrow,
    name: 'Waiting until',
    onChange: onDateChange,
    value: waitingUntil,
  };

  return (
    <Box.div w="10rem">
      <Label htmlFor={inputID}>Waiting until</Label>
      {caseIsClosed ? (
        <TooltipAnchor store={tooltip}>
          <Input {...datePickerProps} type="date" />
        </TooltipAnchor>
      ) : (
        <Input {...datePickerProps} type="date" />
      )}
      <Tooltip store={tooltip} style={{ zIndex: '40' }}>
        {"Closed cases cannot be set to 'waiting'."}
      </Tooltip>
    </Box.div>
  );
};
