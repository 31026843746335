import { isValid, format } from 'date-fns';

/**
 * Formats a ISO date string, date object, or Date.now value
 * to  format or `-` if the date is falsy
 * @param dateTime - date string, date object, or Date.now value
 * @param dateFormat - format of the returned string, defaults to `dd MMM yyyy`
 * @param invalidDateString - string returned for invalid dates, defaults to `-`
 * @returns formatted date or `-`
 */
export const formatDate = (
  dateTime?: Maybe<Date | number | string>,
  dateFormat = 'dd MMM yyyy',
  invalidDateString = '-',
): string => {
  if (dateTime) {
    const date = new Date(dateTime);
    if (isValid(date)) {
      return format(date, dateFormat);
    }
  }
  return invalidDateString;
};
