import { CaseInformationResponse } from 'modules/caseDetails/components/CaseManagement/CaseSteps/types/CaseInformationResponse';
import { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';
import { formatUrl } from './formatUrl';
import { StepComponent } from 'modules/pipelines/types/StepComponent';

export type FormattedComponent = {
  component_type: string;
  content: string;
  id: number;
  dataSubmitted: unknown;
  target: string;
  document?: DocumentWithUrl;
};

export const formatDocument = (
  codesUploaded: string[],
  internal_reference_code: string,
  content: string,
  caseId: number,
): DocumentWithUrl => {
  return {
    docCode: internal_reference_code,
    docDate: null,
    docName: content,
    docUrl: formatUrl(codesUploaded, internal_reference_code, caseId),
  };
};

export const formatComponents = (
  componentsList: StepComponent[],
  codesUploaded: string[],
  submittedData: CaseInformationResponse[],
  caseId: number,
): FormattedComponent[] => {
  return componentsList.map((component) => {
    const { component_type, id, internal_reference_code, content } = component;

    const submittedReference = submittedData.find(
      (information) => information.internal_reference_code === internal_reference_code,
    );

    return {
      component_type,
      content,
      dataSubmitted: submittedReference ? submittedReference.value : null,
      document:
        component_type === 'document'
          ? formatDocument(codesUploaded, internal_reference_code || '', content || '', caseId)
          : null,
      id,
      // The BE has defined that way to handle the submitted data.
      target: `${id}|${internal_reference_code}`,
    } as FormattedComponent;
  });
};
