import { Box, BoxProps } from '@localyze-pluto/components';
import { SystemProp } from '@localyze-pluto/theme';
import React, { forwardRef, ReactNode, Ref } from 'react';

type SectionCardHeaderProps = {
  children?: ReactNode;
  padding?: BoxProps['padding'];
  paddingBottom?: BoxProps['padding'];
  h?: BoxProps['h'];
  px?: BoxProps['padding'];
  py?: BoxProps['padding'];
};

const SectionCardHeader = forwardRef(
  (
    { children, padding = 'd6', paddingBottom = 'd6', h = 'auto', px, py }: SectionCardHeaderProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Box.div
      borderBottom="1px solid"
      borderBottomColor="colorBorderWeakest"
      display="flex"
      flexDirection="column"
      h={h}
      padding={padding}
      paddingBottom={paddingBottom}
      px={px}
      py={py}
      ref={ref}
    >
      {children}
    </Box.div>
  ),
);

const SectionCardContent = ({
  children,
  flexDirection = 'row',
  justifyContent = 'center',
  padding = 'd6',
  maxH,
}: {
  children?: ReactNode;
  flexDirection?: BoxProps['flexDirection'];
  justifyContent?: BoxProps['justifyContent'];
  padding?: BoxProps['padding'];
  maxH?: BoxProps['maxH'];
}) => (
  <Box.div
    display="flex"
    flexDirection={flexDirection}
    h="100%"
    justifyContent={justifyContent}
    maxH={maxH}
    overflow="auto"
    padding={padding}
  >
    {children}
  </Box.div>
);

const SectionCard = ({
  gridColumn,
  children,
  h,
  maxH,
  flexed,
  ...props
}: BoxProps & {
  gridColumn?: SystemProp<BoxProps['gridColumn'], BoxProps>;
  children?: ReactNode;
  h?: BoxProps['h'];
  maxH?: BoxProps['maxH'];
  flexed?: boolean;
}): React.JSX.Element => {
  return (
    <Box.section
      backgroundColor="bgPrimary"
      borderColor="borderSecondary"
      borderRadius="borderRadius40"
      borderStyle="borderStyleSolid"
      borderWidth="borderWidth10"
      display={flexed ? 'flex' : 'grid'}
      flexDirection={flexed ? 'column' : undefined}
      gridColumn={gridColumn}
      gridTemplateRows="minmax(0, max-content)"
      h={h}
      maxH={maxH}
      overflow="hidden"
      {...props}
    >
      {children}
    </Box.section>
  );
};

SectionCardHeader.displayName = 'SectionCardHeader';

SectionCard.Header = SectionCardHeader;
SectionCard.Content = SectionCardContent;

export { SectionCard };
