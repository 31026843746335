import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { OfficeWithOfficeInformation } from 'deprecated/api/companies/types/OfficeAPIResponse';

export type UseOfficeProps<Result> = Pick<
  UseQueryOptions<OfficeWithOfficeInformation, AxiosError, Result>,
  'enabled'
> & {
  params: {
    companyId: number;
    officeId: number;
  };
};

/**
 * Makes a get request to fetch office by company and office
 * @param params.companyId
 * @param params.officeId
 * @returns office
 */
const getOffice = async (
  companyId: number,
  officeId: number,
): Promise<OfficeWithOfficeInformation> => {
  const { data } = await axios.get<OfficeWithOfficeInformation>(
    `/companies/${companyId}/offices/${officeId}`,
    { headers: { Accept: 'application/json' } },
  );

  return data;
};

/**
 * Gets the office
 * @param params.companyId - company to fetch offices for
 * @param params.officeId - office to fetch details for
 * @returns Promise<OfficeWithOfficeInformation>
 */
export const useOffice = <Result = OfficeWithOfficeInformation>({
  params,
  enabled,
  ...props
}: UseOfficeProps<Result>): UseQueryResult<Result, AxiosError> =>
  useQuery({
    queryKey: ['company-office', params.companyId, params.officeId],
    queryFn: () => getOffice(params.companyId, params.officeId),
    enabled,
    ...props,
  });
