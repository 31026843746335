import * as Yup from 'yup';

import {
  Box,
  Button,
  ControlledFormInput,
  ControlledFormSelect,
  Heading,
} from '@localyze-pluto/components';

import { ControlledFileUploader } from 'components/ControlledFileUploader/ControlledFileUploader';
import React from 'react';
import { Step3Payload } from 'modules/cases/pages/createCase/types';
import { Validation } from 'constants/formValidations';
import { useAdditionalServices } from 'modules/additionalServices/api/useAdditionalServices/useAdditionalServices';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import isEmpty from 'lodash/isEmpty';
import { useOfficesWithSelectOptions } from 'modules/offices/api/useOfficesWithSelectOptions/useOfficesWithSelectOptions';

const validationSchema = Yup.object({
  jobDescriptionMetadata: Validation.requiredString,
  jobTitle: Validation.requiredString,
  startDate: Validation.dateFormat,
  workContractMetadata: Validation.requiredString,
  costCenter: Yup.string().defined(),
  hiringManager: Yup.string().defined(),
  officeId: Validation.dropDown,
});

type Props = {
  isDemoModeActive: boolean;
  handleBackClick(payload: Step3Payload): void;
  handleNextClick(payload: Step3Payload, skipToSubmit: boolean): void;
  values: Step3Payload;
  destinationCountry: string;
  companyId: string;
  isCreatingCase: boolean;
};

export const Step3 = ({
  isDemoModeActive,
  handleBackClick,
  handleNextClick,
  values,
  destinationCountry,
  companyId,
  isCreatingCase,
}: Props): React.JSX.Element => {
  const officesQuery = useOfficesWithSelectOptions({
    params: {
      companyId: isEmpty(companyId) ? undefined : Number(companyId),
    },
  });

  const { isSuccess: isAdditionalServicesSuccess, data: additionalServices } =
    useAdditionalServices({
      destinationCountryId: isDemoModeActive ? undefined : Number(destinationCountry),
    });

  const { handleSubmit, control, getValues } = useForm<Step3Payload>({
    defaultValues: values,
    mode: 'onTouched',
    resolver: isDemoModeActive ? undefined : yupResolver(validationSchema),
  });

  if (!isAdditionalServicesSuccess) {
    return (
      <Box.div alignItems="center" display="flex" flexDirection="column">
        <ContainedLoadingState />
      </Box.div>
    );
  }

  const skipToSubmit = additionalServices.length === 0;

  const onSubmit = (data: Step3Payload) => {
    handleNextClick(data, skipToSubmit);
  };

  return (
    <Box.form
      display="flex"
      flexDirection="column"
      gap="d5"
      h="100%"
      justifyContent="space-between"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box.div display="flex" flexDirection="column" gap="d5">
        <Heading as="h1" color="contentPrimary" marginBottom="d0" size="heading50">
          Add employment information
        </Heading>
        <ControlledFormSelect
          control={control}
          disabled={isEmpty(companyId) || officesQuery.isLoading}
          helpText={isEmpty(companyId) ? 'Select a company to see offices' : ''}
          id="officeId"
          items={officesQuery.data || []}
          label="Office"
          name="officeId"
          required={!isDemoModeActive}
        />
        <ControlledFormInput
          control={control}
          id="hiringManager"
          label="Hiring manager (optional)"
          name="hiringManager"
          type="text"
        />

        <ControlledFormInput
          control={control}
          id="costCenter"
          label="Cost center (optional)"
          name="costCenter"
          type="text"
        />

        <ControlledFormInput
          control={control}
          id="jobTitle"
          label="Job title"
          name="jobTitle"
          required={!isDemoModeActive}
          type="text"
        />

        <ControlledFormInput
          control={control}
          id="startDate"
          label="Expected start date"
          name="startDate"
          required={!isDemoModeActive}
          type="date"
        />

        <ControlledFileUploader
          accept=".pdf"
          control={control}
          label="Job description"
          name="jobDescriptionMetadata"
          required
        />

        <ControlledFileUploader
          accept=".pdf"
          control={control}
          label="Work contract"
          name="workContractMetadata"
          required
        />
      </Box.div>

      <Box.div alignItems="center" display="flex" gap="d3" justifyContent="flex-end">
        <Button
          disabled={isCreatingCase}
          leadingIcon="chevron-left"
          onClick={() => handleBackClick(getValues())}
          type="button"
          variant="outline"
        >
          Back
        </Button>
        <Button
          disabled={isCreatingCase}
          loading={isCreatingCase}
          trailingIcon="chevron-right"
          type="submit"
          variant="primary"
        >
          {skipToSubmit ? 'Submit' : 'Next'}
        </Button>
      </Box.div>
    </Box.form>
  );
};
