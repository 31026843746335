import { Icon } from '@localyze-pluto/components';
import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';

const inputClassName = `tw-py-2 tw-pl-10 tw-pr-3 tw-w-full tw-bg-white
tw-border tw-border-solid tw-rounded-md tw-border-gray-300
tw-items-center tw-text-sm tw-font-normal tw-font-sans tw-placeholder-gray-500
tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500`;

type SearchInputProps = {
  value: string;
  onSearch: (search: string) => void;
};

export const SearchInput = ({ onSearch, value }: SearchInputProps): React.JSX.Element => {
  const searchInput = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState(value);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      onSearch(searchInput.current?.value || '');
    }
  };

  const onFocus = () => {
    searchInput.current?.select();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="tw-flex tw-relative">
      <span className="tw-absolute tw-py-2 tw-pl-3">
        <Icon
          className="tw-text-gray-400 tw-w-5 tw-h-5"
          decorative
          icon="search"
          size="sizeIcon30"
        />
      </span>
      <input
        className={inputClassName}
        data-testid="search-input"
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder="Search by title"
        ref={searchInput}
        value={searchValue}
      />
    </div>
  );
};
