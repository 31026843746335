import React from 'react';
import { Box, Modal, ModalBody, ModalHeader, ModalHeading } from '@localyze-pluto/components';
import { useAuthCheck } from 'modules/auth/api/useAuthCheck/useAuthCheck';
import { DialogStore } from '@ariakit/react';
import { useTalent } from 'modules/talent/pages/talentProfile/components/TalentContext/hooks/useTalent';

type LettersAutomationProps = {
  modalState: DialogStore;
};

const LETTERS_AUTOMATION_URL = process.env.REACT_APP_LETTERS_AUTOMATION_URL;

export const LettersAutomation = ({ modalState }: LettersAutomationProps): React.ReactNode => {
  const { auth0Token } = useAuthCheck();
  const talent = useTalent();

  const lettersAutomationArgs = Object.entries({
    companyId: talent.company_id,
    talentId: talent.id,
    token: auth0Token,
  }).reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '');

  return (
    <Modal store={modalState}>
      <ModalHeader>
        <ModalHeading>Generate Letters</ModalHeading>
      </ModalHeader>

      <ModalBody>
        <Box.iframe
          border={0}
          h="100%"
          minH="550px"
          src={`${LETTERS_AUTOMATION_URL}?${lettersAutomationArgs}`}
          title="Generate Letters"
          w="100%"
        />
      </ModalBody>
    </Modal>
  );
};
