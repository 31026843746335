import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';

export const shouldShowConsentCallout = ({
  is_activated,
  consented_to_company_terms,
  required_to_consent_to_company_terms,
}: Partial<TalentWithPersonalInformation>): boolean => {
  if (!is_activated) {
    return false;
  }

  if (!required_to_consent_to_company_terms) {
    return false;
  }

  return !consented_to_company_terms;
};
