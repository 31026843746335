import {
  AccountEntriesParams,
  AccountEntriesResponse,
  UseAccountEntriesProps,
} from 'modules/billing/hooks/useAccountEntries/useAccountEntries.types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { identity, pickBy } from 'lodash';

export const ACCOUNT_ENTRIES_QUERY_KEY = 'account-entries';

const getAccountEntries = async (
  params?: AccountEntriesParams,
): Promise<AccountEntriesResponse> => {
  const filteredParams = params
    ? pickBy(
        {
          search: params.search,
          talent_id: params.talentId,
          type: params.type,
          product_type: params.productType !== 'all' ? params.productType : undefined,
          billing_period_id: params.billingPeriodId !== 'all' ? params.billingPeriodId : undefined,
          page: params.page,
          company_id: params.companyId,
          by_status: params.by_status,
          customer_type: params.customerType,
        },
        identity,
      )
    : undefined;

  const { data } = await axios.get<AccountEntriesResponse>(
    '/admin/account_entries',
    params
      ? {
          params: filteredParams,
        }
      : undefined,
  );

  return data;
};

/**
 * Gets list of account entries for billing
 * @param props.select - transform returned data
 * @param props.params.page - which page to fetch
 * @param props.params.productType - filter by product type
 * @param props.params.billingPeriodId - filter by billing period
 * @param props.params.companyId - filter by company's entries
 * @param props.params.search - filters for entry by talent name
 * @returns companies
 */
export const useAccountEntries = <Result = AccountEntriesResponse>({
  select,
  params,
}: UseAccountEntriesProps<Result>): UseQueryResult<Result, AxiosError> => {
  return useQuery({
    queryKey: [ACCOUNT_ENTRIES_QUERY_KEY, ...Object.values(params)],
    queryFn: () => getAccountEntries(params),
    select,
  });
};
