import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledCheckbox,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { CaseAdditionalService } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { useCreateCaseAdditionalService } from 'modules/additionalServices/api/useCreateCaseAdditionalService/useCreateCaseAdditionalService';
import { useDeleteCaseAdditionalService } from 'modules/additionalServices/api/useDeleteCaseAdditionalService/useDeleteCaseAdditionalService';
import { FAMILY_MEMBERS_SERVICE_NAME } from 'modules/additionalServices/constants/familyMembersServiceName';
import { ProductAdditionalService } from 'modules/additionalServices/types/ProductAdditionalService';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type EditFamilySupportFormTypes = {
  includesFamilySupport: boolean;
};

const validationSchema: Yup.ObjectSchema<EditFamilySupportFormTypes> = Yup.object({
  includesFamilySupport: Yup.boolean().required('This field is required'),
});

export const EditFamilySupportModal = ({
  modal,
  kase,
  caseAdditionalServices,
  familySupportProductAdditionalService,
}: {
  modal: DialogStore;
  kase: Case;
  caseAdditionalServices: CaseAdditionalService[];
  familySupportProductAdditionalService: ProductAdditionalService;
}): React.JSX.Element => {
  const familySupportCaseAdditionalService = caseAdditionalServices.find(
    (service) =>
      service.additional_service.name === FAMILY_MEMBERS_SERVICE_NAME &&
      !service.marketplace_order_id,
  );
  const includesFamilySupport = !!familySupportCaseAdditionalService;
  const toast = useToast();
  const onMutationSuccess = () => {
    toast('Family support updated successfully', 'success');
    modal.hide();
  };
  const onMutationError = () => toast('Family support could not be updated', 'error');

  const { mutate: addFamilyAdditionalService, isPending: isPendingAddAdditionalService } =
    useCreateCaseAdditionalService({
      onSuccess: onMutationSuccess,
      onError: onMutationError,
    });
  const { mutate: deleteFamilyAdditionalService, isPending: isPendingDeleteAdditionalService } =
    useDeleteCaseAdditionalService({ onSuccess: onMutationSuccess, onError: onMutationError });

  const { handleSubmit, control } = useForm({
    defaultValues: { includesFamilySupport },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditFamilySupportFormTypes> = (values) => {
    if (values.includesFamilySupport !== includesFamilySupport) {
      values.includesFamilySupport
        ? addFamilyAdditionalService({
            kase,
            additionalServiceId: familySupportProductAdditionalService.additional_service.id,
          })
        : deleteFamilyAdditionalService({
            kase,
            caseAdditionalServiceId: Number(familySupportCaseAdditionalService?.id),
          });
    } else {
      modal.hide();
    }
  };

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Family support</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form id="edit-family-support-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledCheckbox
            control={control}
            id="includesFamilySupport"
            name="includesFamilySupport"
          >
            The company will pay to extend all booked services to the talent&apos;s family
          </ControlledCheckbox>
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isPendingAddAdditionalService || isPendingDeleteAdditionalService}
          form="edit-family-support-form"
          loading={isPendingAddAdditionalService || isPendingDeleteAdditionalService}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
