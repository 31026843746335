import React, { useEffect } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Heading } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { HRCallout } from 'modules/glomoRequests/components/HRCallout';
import { DocumentLink } from 'modules/glomoRequests/components/DocumentLink/DocumentLink';
import { useGlomoRequest } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { humanizeGlomoRequestType } from 'modules/glomoRequests/utils/humanizeGlomoRequestType';
import { trackPageView } from 'modules/analytics/analytics';
import { GlomoRequestStatusBadge } from 'modules/glomoRequests/components/GlomoRequestStatusBadge';
import { GlomoRequestQuestionsTable } from 'modules/glomoRequests/components/GlomoRequestQuestionsTable/GlomoRequestQuestionsTable';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export const HRGlomoRequestLegacy = (): React.JSX.Element => {
  const { id: glomoRequestId } = useParams<{ id: string }>();
  const { data: glomoRequest, isSuccess } = useGlomoRequest(Number(glomoRequestId));
  const routes = useRoutes();

  const requestType = glomoRequest?.type;

  useEffect(() => {
    if (requestType) {
      trackPageView('glomo request: request details', {
        request_type: requestType,
      });
    }
  }, [requestType]);

  if (!isSuccess) {
    return <ContainedLoadingState />;
  }

  const {
    status,
    risk_assessment_url,
    risk_level,
    employee_first_name,
    employee_last_name,
    questions,
    type,
  } = glomoRequest;

  const employeeFullName = `${employee_first_name} ${employee_last_name}`;

  return (
    <PageLayout
      flexed
      titleButton={
        <Button
          as={Link}
          leadingIcon="arrow-left"
          to={routes.glomoRequestsList.url}
          variant="ghost"
        >
          Back to all requests
        </Button>
      }
    >
      <Box.div display="flex" flexDirection="column" gap="d8" maxW="815px" py="p8">
        <Box.div alignItems="center" display="flex" justifyContent="space-between">
          <Box.div alignItems="center" display="flex" gap="d4">
            <Heading as="h1" marginBottom="m0" size="heading40">
              {humanizeGlomoRequestType[type]} request
            </Heading>
            <Box.div>
              <GlomoRequestStatusBadge status={status} />
            </Box.div>
          </Box.div>
        </Box.div>
        <Box.div display="flex" flexDirection="column" gap="d4">
          <HRCallout glomoRequest={glomoRequest} />
          {status === 'complete' && (
            <DocumentLink
              name={employeeFullName}
              risk={risk_level as GlomoRequestRisk}
              url={String(risk_assessment_url)}
            />
          )}
          <GlomoRequestQuestionsTable questions={questions} />
        </Box.div>
      </Box.div>
    </PageLayout>
  );
};
