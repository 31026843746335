import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DeleteMessageProps, deleteMessage } from './deleteMessage';

import { MESSAGES_QUERY_KEY } from '../useMessages/useMessages';

export const useDeleteMessage = ({
  onError,
  onSuccess,
  internalMessage = false,
}: {
  onError?: (error: AxiosError) => void;
  onSuccess?: () => void;
  internalMessage?: boolean;
}): UseMutationResult<void, unknown, DeleteMessageProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteMessage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MESSAGES_QUERY_KEY, internalMessage],
      });

      onSuccess?.();
    },
    onError,
  });
};
