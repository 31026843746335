import { deleteStepComponent, postStepComponent, putStepComponent } from 'deprecated/api/steps';

export const submitUpdateComponent = async ({
  stepId,
  id,
  content,
  callbackSuccess,
  callbackError,
}) => {
  try {
    await putStepComponent({ stepId, componentId: id, content });
    callbackSuccess({ content });
  } catch (e) {
    callbackError();
  }
};

export const submitCreateComponent = async ({
  stepId,
  content,
  componentType,
  callbackSuccess,
  callbackError,
}) => {
  try {
    const { id } = await postStepComponent({ stepId, content, componentType });
    callbackSuccess({ id, content });
  } catch (e) {
    callbackError();
  }
};

export const submitDeleteComponent = async ({ stepId, id, callbackSuccess, callbackError }) => {
  try {
    await deleteStepComponent({ stepId, componentId: id });
    callbackSuccess();
  } catch (e) {
    callbackError(e.message);
  }
};
