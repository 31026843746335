/**
 * Takes a formatted number string and converts it into a number
 * Note: This removes all non digit characters and won't work with decimals
 * Note: Will return 0 if parsed value is not a number
 * @param numberString - the number as a string (ie. "$1,000")
 * @param maxLength - max length of number
 * @returns number
 */
export const parseNumberFromString = (numberString: string, maxLength?: number): number => {
  const stringWithNonDigitCharsRemoved = numberString.replace(/\D/g, '');

  const parsedNumber =
    maxLength !== undefined
      ? parseFloat(stringWithNonDigitCharsRemoved.substring(0, maxLength))
      : parseFloat(stringWithNonDigitCharsRemoved);

  return Number.isNaN(parsedNumber) ? 0 : parsedNumber;
};
