import React from 'react';
import * as Yup from 'yup';
import { Box, Button, ControlledFormInput } from '@localyze-pluto/components';
import { Validation } from 'constants/formValidations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserFormPayload } from 'modules/users/types/UserFormPayload';

type Props = {
  handleFormSubmit(payload: UserFormPayload): void;
  isSubmitting?: boolean;
  values?: UserFormPayload;
};

// ** Form to add and edit users
export const UserForm = ({
  handleFormSubmit,
  isSubmitting = false,
  values,
}: Props): React.JSX.Element => {
  const validationSchema = Yup.object({
    first_name: Validation.requiredString,
    last_name: Validation.requiredString,
    birthdate: Validation.requiredString,
    email: Validation.requiredString,
  });

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: values,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: UserFormPayload) => {
    handleFormSubmit(data);
  };

  return (
    <Box.div>
      <Box.form marginBottom="m4" onSubmit={handleSubmit(onSubmit)}>
        <Box.div
          columnGap="d12"
          display="grid"
          gridTemplateColumns={{ lg: 2 }}
          marginBottom="m3"
          rowGap="d3"
        >
          <ControlledFormInput
            control={control}
            id="first_name"
            label="First name"
            name="first_name"
            required
            type="text"
          />
          <ControlledFormInput
            control={control}
            id="last_name"
            label="Last name"
            name="last_name"
            required
            type="text"
          />
          <ControlledFormInput
            control={control}
            disabled
            helpText={values?.email && 'To update your email, contact your Case Manager.'}
            id="email"
            label="Email"
            name="email"
            type="email"
          />

          <ControlledFormInput
            control={control}
            id="birthdate"
            label="Birthdate"
            name="birthdate"
            required
            type="date"
          />
        </Box.div>

        <Box.div alignItems="center" display="flex" gap="d3" justifyContent="flex-end">
          <Button disabled={!isDirty} loading={isSubmitting} type="submit" variant="primary">
            Save
          </Button>
        </Box.div>
      </Box.form>
    </Box.div>
  );
};
