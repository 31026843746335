import { Box, BoxProps, Heading } from '@localyze-pluto/components';
import React, { HTMLAttributes, ReactNode } from 'react';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { ListItem } from './components/ListItem';
import { isEmpty } from 'lodash';
import { EmptyState } from 'components/EmptyState/EmptyState';

export type DocumentListProps = HTMLAttributes<HTMLUListElement> & {
  children: ReactNode | ReactNode[];
  title?: ReactNode | string;
  actions?: ReactNode | string;
  h?: BoxProps['h'];
};

const DocumentList = ({ title, actions, children, h }: DocumentListProps): React.JSX.Element => {
  const { Header, Content } = SectionCard;
  const showHeader = !isEmpty(title) || !isEmpty(actions);
  const hasChildren = Boolean(React.Children.count(children));

  return (
    <SectionCard>
      {showHeader && (
        <Header>
          <Box.div alignItems="center" display="flex" justifyContent="space-between">
            {title && (
              <Heading as="h3" color="colorTextStrongest" marginBottom="m0" size="heading60">
                {title}
              </Heading>
            )}
            {actions}
          </Box.div>
        </Header>
      )}
      <Content maxH={600} padding="p0">
        {hasChildren && (
          <Box.ul
            h={h}
            listStyleType="none"
            margin="d0"
            overflowX="visible"
            overflowY="auto"
            paddingLeft="d0"
            w="100%"
          >
            {children}
          </Box.ul>
        )}
        {!hasChildren && (
          <EmptyState
            illustration={<Box.span>📜</Box.span>}
            text="You don't have any documents yet"
          />
        )}
      </Content>
    </SectionCard>
  );
};

DocumentList.Item = ListItem;

export { DocumentList };
