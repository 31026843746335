import React from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { Icon } from '@localyze-pluto/components';
import { DocumentList } from 'modules/caseDetails/components/Documents/components/DocumentList';
import { useKnowledgeDocuments } from 'modules/knowledge/api/useKnowledgeDocuments';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

export const DocumentsList = (): React.JSX.Element => {
  const { data, isSuccess } = useKnowledgeDocuments();

  if (!isSuccess) return <ContainedLoadingState />;

  return (
    <DocumentList h="288" title="Documents">
      {data.map((document) => (
        <DocumentList.Item
          contentClassName="tw-flex tw-items-center"
          contentContainerClassName="tw-items-center"
          key={document.name}
          prefix={
            <Icon className="tw-text-gray-400" decorative icon="paperclip" size="sizeIcon30" />
          }
          suffix={document.date}
        >
          <a
            className={`tw-align-top tw-text-sm tw-leading-5 tw-font-normal tw-text-blue-500
            tw-cursor-pointer`}
            href={document.publicFilePath}
            onClick={() => trackEvent('knowledge: view document')}
            rel="noopener noreferrer"
            target="_blank"
          >
            {document.name}
          </a>
        </DocumentList.Item>
      ))}
    </DocumentList>
  );
};
