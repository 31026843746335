import { Box, ControlledRadioGroup, Heading, Radio, Paragraph } from '@localyze-pluto/components';
import React from 'react';
import { CaseType } from 'modules/cases/types/CaseType';
import { Control } from 'react-hook-form';
import { useCaseTypeCategories } from 'modules/cases/api/useCaseTypeCategories/useCaseTypeCategories';
import {
  MappedCaseTypeAndCategories,
  mapCaseTypeAndCategories,
} from 'modules/cases/utils/mapCaseTypeAndCategories';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

type Props = {
  caseTypes: CaseType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
};

const CategorizedItems = ({ items }: { items: MappedCaseTypeAndCategories[] }) =>
  items.map(({ caseTypeCategory, caseTypes }) => {
    return (
      <Box.div
        aria-label={caseTypeCategory.human_name}
        key={caseTypeCategory.category}
        marginBottom="d4"
      >
        <Heading
          as="h4"
          fontSize="fontSize30"
          fontWeight="fontWeightMedium"
          lineHeight="lh8"
          marginBottom="d0"
        >
          {caseTypeCategory.human_name}
        </Heading>
        <Paragraph>{caseTypeCategory.description}</Paragraph>
        {caseTypes.map((caseType) => {
          return (
            <Box.div
              display="flex"
              flexDirection="column"
              gap="d0_5"
              key={caseType.name}
              marginBottom="d2"
            >
              <Radio label={caseType.name} value={`${caseType.id}`} />
              <Paragraph
                color="colorText"
                fontSize="fontSize10"
                lineHeight="lineHeight10"
                marginBottom="d0"
                marginLeft="d6"
              >
                {caseType.description}
              </Paragraph>
            </Box.div>
          );
        })}
      </Box.div>
    );
  });

export const CaseTypeOptions = ({ caseTypes, control }: Props): React.JSX.Element => {
  const { data: caseTypeCategories, isSuccess: isSuccessCaseTypeCategories } =
    useCaseTypeCategories();

  if (!isSuccessCaseTypeCategories) {
    return <ContainedLoadingState />;
  }

  const categorizedItems = mapCaseTypeAndCategories(caseTypeCategories, caseTypes);

  return (
    <ControlledRadioGroup control={control} name="caseTypeId">
      <CategorizedItems items={categorizedItems} />
    </ControlledRadioGroup>
  );
};
