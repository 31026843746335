import { useState } from 'react';
import { ColumnSort, SortingState, Updater } from '@tanstack/react-table';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';

export type UseTableSortingProps = {
  sorting?: SortingState;
  onSortingChange?: (updaterOrValue: Updater<ColumnSort[]>) => void;
};

export const useTableSorting = (): UseTableSortingProps => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { addQueryParam, removeQueryParam } = useQueryParams();

  const setQueryParams = (sortBy?: string, sortDirection?: string) => {
    if (sortBy) {
      addQueryParam('sort_by', sortBy);
    } else {
      removeQueryParam('sort_by');
    }

    if (sortDirection) {
      addQueryParam('sort_direction', sortDirection);
    } else {
      removeQueryParam('sort_direction');
    }
  };

  const onSortingChange = (updaterOrValue: Updater<ColumnSort[]>) => {
    const sort = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;

    setQueryParams(sort.at(0)?.id, sort.at(0)?.desc ? 'desc' : 'asc');
    setSorting(sort);
  };

  return {
    sorting,
    onSortingChange,
  };
};
