import { DialogStore } from '@ariakit/react';
import {
  Box,
  Button,
  ControlledFormInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useToast,
} from '@localyze-pluto/components';
import { useUpdateCaseInformations } from 'modules/cases/api/useUpdateCaseInformations/useUpdateCaseInformations';
import { CaseInformation } from 'modules/cases/types/CaseInformation';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { formatDatesToTimestamps } from './utils/formatDatesToTimestamps';

type EditRelevantDateModalProps = {
  modal: DialogStore;
  caseId: number;
  date: CaseInformation;
};

type EditRelevantDateModalFormValues = {
  [key: string]: string;
};

const formatPayload = (values: EditRelevantDateModalFormValues) => {
  return Object.entries(values).map(([key, value]) => ({
    internal_reference_code: key,
    value,
  }));
};

export const EditRelevantDateModal = ({
  modal,
  caseId,
  date,
}: EditRelevantDateModalProps): React.JSX.Element => {
  const toast = useToast();

  const { mutate: updateCaseInformations, isPending: isUpdatingCaseInformations } =
    useUpdateCaseInformations({
      onSuccess: () => {
        toast(`${date.description} updated`, 'success');
        modal.hide();
      },
      onError: () => toast(`${date.description} could not be updated`, 'error'),
    });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      [date.internal_reference_code]: formatDate(date.value, 'yyyy-MM-dd'),
    },
  });

  const onSubmit: SubmitHandler<EditRelevantDateModalFormValues> = (values) => {
    const formatted = formatDatesToTimestamps(values);

    updateCaseInformations({
      caseId,
      caseInformations: formatPayload(formatted),
    });
  };

  return (
    <Modal key={date.id} store={modal}>
      <ModalHeader>Edit {date.description}</ModalHeader>
      <ModalBody>
        <Box.form id="edit-relevant-dates-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            id={date.internal_reference_code}
            label={date.description}
            name={date.internal_reference_code}
            required
            type="date"
          />
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isUpdatingCaseInformations}
          form="edit-relevant-dates-form"
          loading={isUpdatingCaseInformations}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
