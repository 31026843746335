import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeading,
  DrawerStore,
  Heading,
  Paragraph,
} from '@localyze-pluto/components';
import React, { ReactNode } from 'react';

const Section = ({ children }: { children: ReactNode }) => {
  return (
    <Box.section
      backgroundColor="bgSecondary"
      borderRadius="borderRadius20"
      display="flex"
      flexDirection="column"
      gap="d2"
      padding="d4"
    >
      <Box.div>{children}</Box.div>
    </Box.section>
  );
};

export const PartnerSettingsDrawer = ({ state }: { state: DrawerStore }): React.JSX.Element => {
  return (
    <Drawer store={state}>
      <DrawerHeader>
        <DrawerHeading>About partner settings</DrawerHeading>
      </DrawerHeader>
      <DrawerBody>
        <Heading
          as="h2"
          color="colorTextStrongest"
          fontWeight="fontWeightRegular"
          marginBottom="m0"
          size="title-body"
        >
          Set your partner preferences for services on our platform.
        </Heading>
        <Box.div display="flex" flexDirection="column" gap="d2">
          <Section>
            <Badge color="blue" icon="star">
              Preferred Partner
            </Badge>
            <Heading as="h2" marginBottom="d0" marginTop="d2" size="title-body">
              Select a preferred partner per service category
            </Heading>
            <Paragraph>
              Select a preferred partner for each service category. When you set a preferred
              partner, we will prioritize them for the services you book within this category
              whenever possible.
            </Paragraph>
            <Heading as="h2" marginBottom="d0" size="title-body">
              What happens if my preferred partner cannot deliver the service?
            </Heading>
            <Paragraph marginBottom="d0">
              While we will always aim to select your preferred partner, they may occasionally be
              unable to provide the service due to geographical coverage or capacity constraints. In
              those cases, your Localyze Case Manager will assign an alternative partner to ensure
              your service is delivered as expected.
            </Paragraph>
          </Section>
          <Section>
            <Badge color="red">Disabled</Badge>
            <Paragraph marginBottom="d0" marginTop="d2">
              You can manually disable specific partners for each service category. Disabled
              partners won’t be selected for your talent.
            </Paragraph>
          </Section>
        </Box.div>
      </DrawerBody>
    </Drawer>
  );
};
