import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Report, ReportResults, ReportType } from 'modules/reporting/api/types/Report';
import { isEmpty, omit } from 'lodash';

type DateParams = {
  start_date?: string;
  end_date?: string;
};

type ReportParams = DateParams & ({ company_id?: number } | { hr_manager_id?: number });

export const getReport = async <T extends ReportResults>(
  reportId: ReportType,
  params?: ReportParams,
): Promise<Report<T> | undefined> => {
  const { data } = await axios.get<Report<T>>(`/reports/${reportId}`, {
    params: {
      ...omit(params || {}, ['start_date', 'end_date']),
      ...(!isEmpty(params?.start_date) && { start_date: params?.start_date }),
      ...(!isEmpty(params?.end_date) && { end_date: params?.end_date }),
    },
  });

  return data;
};

/**
 * Fetch a report for a company or the company of the current user if ommitted.
 * @param reportId - id of the report
 * @param companyId - company to get the report for, only works for Localyze Admins.
 */
export const useReport = <T extends ReportResults>(
  reportId: ReportType,
  params?: ReportParams,
): UseQueryResult<Report<T>, AxiosError> => {
  return useQuery({
    queryKey: ['case-metrics-report', reportId, JSON.stringify(params)],

    queryFn: () => getReport<T>(reportId, params),
  });
};
