import upperFirst from 'lodash/upperFirst';
import startCase from 'lodash/startCase';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';
import React from 'react';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';

export const PersonalInformation = ({
  talent,
}: {
  talent: TalentWithPersonalInformation;
}): React.JSX.Element => {
  const hrisIntegration = talent.international_integrations.find(
    ({ provider_name }) => provider_name !== 'intercom',
  );

  const items = [
    {
      key: 'First name',
      value: talent.first_name,
    },
    {
      key: 'Last name',
      value: talent.last_name,
    },
    {
      key: 'Email',
      value: talent.email,
    },
    {
      key: 'Gender',
      value: talent.gender && startCase(talent.gender),
    },
    {
      key: 'Date of birth',
      value: formatDate(talent.birthday),
    },
    ...(hrisIntegration?.provider_public_uid
      ? [
          {
            key: `Employee ID (${upperFirst(hrisIntegration.provider_name)})`,
            value: hrisIntegration.provider_public_uid,
          },
        ]
      : [{ key: 'Employee ID', value: null }]),
    {
      key: 'Talent left the company',
      value: talent.left_company ? 'Yes' : 'No',
    },
  ];

  return <KeyValueList items={items} />;
};
