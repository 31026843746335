import { Callout, Text } from '@localyze-pluto/components';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const getMessageBasedOnUser = (isAdmin: boolean): ReactNode => {
  if (isAdmin) {
    return (
      <>
        <Text.span>
          It looks like you selected a destination country that we don’t support yet. If you think
          that’s not correct, we might miss information in our database. Please reach out to the
          product team for assistance.
        </Text.span>
      </>
    );
  } else {
    return (
      <>
        <Text.span>
          It looks like you selected a destination country we do not support yet.
        </Text.span>
        <br />
        <Text.span display="block" marginBottom="m2">
          Please submit a Pre-Check and we will find a solution together.
        </Text.span>
      </>
    );
  }
};

export const NoCaseTypes = ({ isAdmin }: { isAdmin: boolean }): React.JSX.Element => {
  return (
    <Callout variant="warning">
      {getMessageBasedOnUser(isAdmin)}
      {isAdmin ? null : (
        <Link
          style={{
            textDecoration: 'underline',
          }}
          to="/company/pre-check"
        >
          Submit Pre-Check
        </Link>
      )}
    </Callout>
  );
};
