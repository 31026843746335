import axios from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { InternalVariablesResponse } from 'deprecated/api/internal_variables/types/InternalVariablesResponse';

export type GetVariablesParams = {
  [key: string]: unknown;
  page: number;
  search?: string;
};

export const INTERNAL_VARIABLES_QUERY_KEY = 'internal-variables';

const getInternalVariables = async (
  params: GetVariablesParams,
): Promise<InternalVariablesResponse> => {
  const { data } = await axios.get<InternalVariablesResponse>('/admin/internal_variable_groups', {
    params,
  });

  return data;
};

export const useVariables = (
  params: GetVariablesParams,
): UseQueryResult<InternalVariablesResponse> => {
  return useQuery({
    queryKey: [INTERNAL_VARIABLES_QUERY_KEY, ...Object.values(params)],
    queryFn: () => getInternalVariables(params),
  });
};
