import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { UseUserProps } from './useUser.types';
import { User } from 'deprecated/api/user/types/User';

export const USER_QUERY_KEY = 'user';

const getUser = async (userId?: number): Promise<User> => {
  if (userId === undefined) {
    throw new Error('User ID is required to fetch user');
  }

  const { data } = await axios.get<User>(`/users/${userId}`);

  return data;
};

/**
 * Gets user
 * @param props.select - transform returned data
 * @param props.params.userId - Id of user to fetch
 * @returns User
 */
export const useUser = <Result = User>({
  select,
  params,
  enabled,
}: UseUserProps<Result> = {}): UseQueryResult<Result, AxiosError> => {
  return useQuery({
    queryKey: [USER_QUERY_KEY, params?.userId],
    queryFn: () => getUser(params?.userId),
    enabled: enabled ?? !!params?.userId,
    select,
  });
};
