import { Icon, Box } from '@localyze-pluto/components';
import { CaseOverviewCell } from '../CaseOverviewCell';
import React from 'react';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';
import { useCurrentUser } from 'config/CurrentUserContext';

export const ServiceLevelCell = ({
  caseOverview,
}: {
  caseOverview: CaseOverview;
}): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const isPremium = caseOverview.service_level_name === 'Premium';

  return (
    <CaseOverviewCell caseOverview={caseOverview} isAdmin={isAdmin}>
      <Box.div alignItems="flex-start" display="flex">
        {isPremium ? (
          <Icon color="cream600" decorative={false} icon="crown" title="Premium Service Level" />
        ) : null}
      </Box.div>
    </CaseOverviewCell>
  );
};
