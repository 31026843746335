import { isNil, pick } from 'lodash';
import {
  RequiredActionId,
  requiredActionsKeys,
} from '../components/RequiredActions/RequiredActions';
import { CompleteGlomoRequestValues } from 'modules/glomoRequests/types/CompleteGlomoRequestValues';
import { RequiredActionItem } from '../types/RequiredActionItem';

// Formats an object where keys are required actions and the values are, well, their current value
//  to an array where every item has is {action: name_of_the_required_action, value: value }
export const formatRequiredActions = (
  data: Pick<CompleteGlomoRequestValues, RequiredActionId>,
): RequiredActionItem[] => {
  const requiredActions = pick(data, requiredActionsKeys);

  const requiredActionsItems: RequiredActionItem[] = Object.entries(requiredActions)
    .map(([key, value]) => ({
      action: key as RequiredActionId,
      value,
    }))
    .filter(({ value }) => !isNil(value));

  return requiredActionsItems;
};
