import { Report } from 'modules/reporting/api/types/Report';
import { CaseMetrics } from 'modules/reporting/api/types/CaseMetrics';

type ChartItem = { label: string; count: number };

const formatCasesByState = (report: Report<CaseMetrics>): ChartItem[] => {
  return [
    { label: 'Case evaluation', count: report.results.caseEvaluationCases },
    { label: 'Ongoing', count: report.results.ongoingCases },
    { label: 'Waiting', count: report.results.waitingCases },
    { label: 'Closed', count: report.results.closedCases },
  ];
};

export const getCasesByState = (report: Report<CaseMetrics>): ChartItem[] => {
  return formatCasesByState(report).filter(({ count }) => count > 0);
};
