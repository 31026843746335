// update account entry
import axios from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from 'config/queryClient';
import { ACCOUNT_ENTRIES_QUERY_KEY } from '../useAccountEntries/useAccountEntries';
import { AccountEntry } from 'modules/billing/types/AccountEntry';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { BillingStatus } from 'modules/billing/types/BillingStatus';

const setAccountEntryStatusReviewed = async (accountEntry: AccountEntry): Promise<AccountEntry> => {
  const { data } = await axios.put<AccountEntry>(`/admin/account_entries/${accountEntry.id}`, {
    ...accountEntry,
    status: BillingStatus.REVIEWED,
  });

  return data;
};

/**
 * Updates an AccountEntry's status
 * @param props.onSuccess - callback to call on success of request
 * @param props.onError - callback to call on error of request
 */
export const useSetAccountEntryStatusReviewed = ({
  onSuccess,
  onError,
}: UseMutationOptions<
  AccountEntry,
  AxiosErrorWithMessage,
  AccountEntry,
  'onError' | 'onSuccess'
>): UseMutationResult<AccountEntry, AxiosErrorWithMessage, AccountEntry> => {
  return useMutation({
    mutationFn: setAccountEntryStatusReviewed,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_ENTRIES_QUERY_KEY] });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
