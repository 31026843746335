import React from 'react';
import { Avatar, Td } from '@localyze-pluto/components';

type Props = {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
};

export const NameCell = ({ firstName, lastName, email }: Props): React.JSX.Element => (
  <Td>
    <div className="tw-flex">
      <Avatar name={`${firstName} ${lastName}`} size="medium" />
      <div className="tw-flex tw-flex-col tw-pl-4 tw-text-sm tw-leading-5">
        <span className="tw-font-medium">
          {firstName} {lastName}
        </span>
        <span className="tw-text-gray-500 tw-font-normal">{email}</span>
      </div>
    </div>
  </Td>
);
