import { Document } from './types/Document';
import { createAttachment } from './createAttachment';
import { uploadToS3AndShrineIt } from 'deprecated/api/uploads/uploadToS3AndShrineIt';

export const uploadAttachment = async (
  name: string,
  file: File,
  caseId: number,
  onProgress?: (progressPercentage: number) => void,
  onCancel?: () => void,
): Promise<Document | undefined> => {
  try {
    const shrineData = await uploadToS3AndShrineIt(file, onProgress, onCancel);

    if (!shrineData) {
      return undefined;
    }

    return createAttachment({
      name,
      file: shrineData,
      case_id: caseId,
    });
  } catch (err) {
    throw err;
  }
};
