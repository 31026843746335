import axios from 'axios';
import { GLOMO_REQUEST_QUERY_KEY } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { GlomoRequest } from '../types/GlomoRequest';
import { PENDING_COUNTERS_KEY } from 'modules/home/api/usePendingCounters';

type ApproveGlomoRequestProps = { glomoRequestId: number };

const approveGlomoAssessmentRequest = async ({
  glomoRequestId,
}: ApproveGlomoRequestProps): Promise<GlomoRequest> => {
  const url = `/glomo/requests/${glomoRequestId}/assessment/approvals`;
  const { data } = await axios.post<GlomoRequest>(url, {});

  return data;
};

export const useApproveGlomoAssessmentRequest = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}): UseMutationResult<GlomoRequest, unknown, ApproveGlomoRequestProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: approveGlomoAssessmentRequest,
    onSuccess: (glomoRequest) => {
      queryClient.invalidateQueries({
        queryKey: [GLOMO_REQUEST_QUERY_KEY, glomoRequest.id],
      });
      queryClient.invalidateQueries({ queryKey: [PENDING_COUNTERS_KEY] });

      onSuccess?.();
    },
    onError,
  });
};
