import { Button } from '@localyze-pluto/components';
import { Link } from 'react-router-dom';
import React from 'react';

export const AllItemsLink = ({
  path,
  label,
  onClick,
}: {
  path: string;
  label: string;
  onClick?: () => void;
}): React.JSX.Element => (
  <Button
    as={Link}
    onClick={onClick}
    to={path}
    trailingIcon="square-arrow-out-up-right"
    variant="ghost"
  >
    {label}
  </Button>
);
