import axios from 'axios';
import { isEmpty } from 'lodash';

const DEFAULT_SHARE_REPORT_ENDPOINT = '/reports/default/share';
const VISUAL_SHARE_REPORT_ENDPOINT = '/reports/case_metrics/share';

type Payload = { emails: string; pdf?: unknown; start_date?: string; end_date?: string };

/**
 * Calls the endpoint to share report.
 * @param emails - email or email addresses that the report will be shared with.
 * @param pdf - optional pdf object that will be shared.
 */
export const shareReport = async ({
  emails,
  pdf,
  start_date,
  end_date,
}: Payload): Promise<void> => {
  const payload: Payload = {
    emails,
    ...(!isEmpty(pdf) && { pdf }),
    ...(!isEmpty(start_date) && { start_date }),
    ...(!isEmpty(end_date) && { end_date }),
  };

  const endpoint = pdf ? VISUAL_SHARE_REPORT_ENDPOINT : DEFAULT_SHARE_REPORT_ENDPOINT;
  return axios.post(endpoint, payload);
};
