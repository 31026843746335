import {
  ADD_DOCUMENT,
  OPEN_DELETE_DOCUMENT,
} from 'deprecated/store/actions/DocumentsContainerActions';
import { Form, Formik } from 'formik';
import { P2 } from 'deprecated/components/Fonts';
import React, { useCallback, useContext } from 'react';
import { DocsContainerStore } from 'deprecated/store/root';
import FormFields from 'deprecated/components/FormFields';
import PropTypes from 'prop-types';
import { cancelAllUploadsToS3 } from 'deprecated/api/uploads/uploadToS3';
import styled from 'styled-components';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { updateAttachmentAndShrineIt } from 'modules/documents/api/attachments/updateAttachmentAndShrineIt';
import { uploadCaseDocument } from 'deprecated/api/talents/uploadCaseDocument';
import { DocumentAnchor } from 'modules/caseDetails/components/Documents/components/DocumentAnchor';
import { Box, Button } from '@localyze-pluto/components';

export default function DownloadBar({ doc, caseId, onClickDelete }) {
  const { docUrl, docName, docCode, id, type } = doc;
  const initialValues = {
    file: {},
    docCode,
  };

  const PreviewContext = useContext(DocsContainerStore);
  const { dispatch } = PreviewContext;

  const handleDeleteClick = useCallback(() => {
    dispatch({
      type: OPEN_DELETE_DOCUMENT,
      payload: {
        src: docUrl,
        docName,
        docCode,
        id,
        type,
      },
    });
    onClickDelete();
  }, [dispatch, docCode, docName, docUrl, id, type, onClickDelete]);

  const onUploadDocument = useCallback(
    (value, _, onProgress) => {
      if (type === 'Attachment') {
        trackEvent('documents: upload document');
        return updateAttachmentAndShrineIt(id, value.value, onProgress);
      } else {
        trackEvent('documents: upload document');

        return uploadCaseDocument(value.name, value.value, caseId, onProgress);
      }
    },
    [caseId, id, type],
  );

  const onUploadSuccess = useCallback(
    (url) => {
      dispatch({
        type: ADD_DOCUMENT,
        payload: {
          docCode,
          docUrl: url,
          type,
        },
      });
    },
    [dispatch, docCode, type],
  );

  return (
    <DownloadbarWrapper>
      <div
        data-testid="download-bar"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <P2>{docName}</P2>
      </div>
      <Box.div display="flex" alignItems="center" gap="d2">
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          <Form>
            <FormFields
              fieldType="file"
              name={docCode || docName}
              docName={docName}
              onUpload={onUploadDocument}
              onUploadSuccess={onUploadSuccess}
              onCancel={cancelAllUploadsToS3}
              component={
                <Button
                  variant="ghost"
                  iconOnly
                  leadingIcon="cloud-upload"
                  data-testid="upload-document-button"
                  type="button"
                  aria-label="Upload"
                />
              }
            />
          </Form>
        </Formik>
        <DocumentAnchor href={docUrl} />

        <Button
          variant="ghost"
          iconOnly
          leadingIcon="trash-2"
          data-testid="delete-document-button"
          onClick={handleDeleteClick}
          type="button"
          aria-label="Delete"
        />
      </Box.div>
    </DownloadbarWrapper>
  );
}

DownloadBar.propTypes = {
  doc: PropTypes.shape({
    docUrl: PropTypes.string,
    docName: PropTypes.string,
    docDate: PropTypes.string,
    docCode: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  }),
};

const DownloadbarWrapper = styled.div`
  margin: 15px 0px 15px 0px;
  border-radius: 8px;
  padding: 5px 10px 5px 10px;
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
