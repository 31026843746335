import React, { useEffect } from 'react';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';

type Props = {
  resetErrorBoundary(): void;
};

export const ErrorBoundaryFallback = ({ resetErrorBoundary }: Props): React.JSX.Element => {
  // On unmount, reset error boundary
  // This allows for a retry if users navigate back
  useEffect(() => {
    return () => {
      resetErrorBoundary();
    };
  }, [resetErrorBoundary]);

  return <ErrorStatePage />;
};
