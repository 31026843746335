import React, { ReactNode } from 'react';
import { Box, BoxProps, HeadingProps } from '@localyze-pluto/components';
import { Header } from './Header/Header';

type Paddings = 'd0' | 'd2' | 'd8' | 'd16';

type PageLayoutProps = {
  readonly children: ReactNode;
  readonly title?: string;
  readonly subTitle?: string;
  flexed?: boolean;
  titleButton?: React.JSX.Element;
  titleSize?: HeadingProps['size'];
  maxW?: BoxProps['maxW'];
  mx?: BoxProps['mx'];
  px?: Paddings;
  w?: BoxProps['w'];
};

const headerPx: Record<Paddings, Paddings> = {
  d0: 'd0',
  d2: 'd2',
  d8: 'd8',
  d16: 'd16',
};

export const PageLayout = ({
  titleButton,
  titleSize = 'title-screen',
  flexed,
  title,
  subTitle,
  children,
  maxW,
  mx,
  w,
  px = 'd8',
}: PageLayoutProps): React.JSX.Element => {
  return (
    <>
      <Header
        px={headerPx[px]}
        subTitle={subTitle}
        title={title}
        titleButton={titleButton}
        titleSize={titleSize}
      />
      <Box.div
        display={flexed ? 'flex' : 'block'}
        flexDirection={flexed ? 'column' : undefined}
        flexGrow={flexed ? '1' : undefined}
        maxW={maxW}
        mx={mx}
        px={px}
        w={w}
      >
        {children}
      </Box.div>
    </>
  );
};
