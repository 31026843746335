export enum CaseType {
  BusinessTrip = 'Business Trip',
  DomesticRelocation = 'Domestic relocation (incl. EU)',
  FamilyOnly = 'Family only',
  FullRelocation = 'Full relocation',
  NotSureYet = 'Not sure yet',
  RemoteEmploymentSetup = 'Remote employment setup',
  ResidencePermitRenewalAndChange = 'Residence Permit (incl. Renewal & Change)',
  TemporaryRemoteWork = 'Temporary remote work (incl. A1)',
  VisaOnly = 'Visa only',
  Workation = 'Workation',
}
