import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SelectItemProps } from '@localyze-pluto/components';
import { Country } from 'modules/countries/types/Country';
import { useCountries } from '../useCountries/useCountries';
import { UseCountryProps } from '../useCountries/useCountries.types';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';

/**
 * Gets list of country as select options
 * @param props.params.association - filters
 * @param props.params.filter - filters results based on matching string
 * @param props.allLabel - which label should be added for the option if no value is selected
 * @param props - remaining useQuery params are available
 * @returns countries transformed into options usable by a select
 */

export const useCountryOptions = ({
  params,
  allLabel,
  ...props
}:
  | (Omit<UseCountryProps<SelectItemProps[]>, 'select'> & {
      allLabel?: string;
    })
  | undefined = {}): UseQueryResult<SelectItemProps[], AxiosError> => {
  const transformCountries = (countries: Country[]): SelectItemProps[] =>
    mapToSelectOptions({ records: countries, valueProp: 'id', labelProp: 'name', allLabel });

  return useCountries({ params, select: transformCountries, ...props });
};
