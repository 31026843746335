import { Paragraph } from '@localyze-pluto/components';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

type DefaultCellProps = Pick<ICellRendererParams, 'value' | 'valueFormatted'>;

/**
 * Generic cell used in table to apply proper text styling
 */
export const DefaultCell = ({ value, valueFormatted }: DefaultCellProps): React.JSX.Element => {
  return (
    <Paragraph
      display="block"
      fontSize="fontSize20"
      fontWeight="fontWeightRegular"
      lineHeight="lineHeight30"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {valueFormatted || value}
    </Paragraph>
  );
};
