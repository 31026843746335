import { Box, Button, Heading, Text, Paragraph } from '@localyze-pluto/components';
import React from 'react';
import Sadlama from 'assets/img/lhama.svg';
import { Link } from 'react-router-dom';

type Props = {
  title?: string;
};

/**
 * Full page error state, used to render in place of a page when an error occurs
 * @param [title] - Optionally override the default title
 */
export const ErrorStatePage = ({
  title = 'Oh no - something went wrong',
}: Props): React.JSX.Element => {
  return (
    <Box.div backgroundColor="colorBackgroundBody" padding="d9" paddingTop="d25">
      <Box.div alignItems="center" display="flex" flexDirection="column">
        <Box.img marginBottom="d9" src={Sadlama} />
        <Heading as="h1" marginBottom="m0" size="heading40">
          {title}
        </Heading>
        <Paragraph color="colorText" fontSize="fontSize20" marginBottom="d6" marginTop="d2">
          We cannot find the page you were looking for. Please try again or{' '}
          <Text.a
            color="colorText"
            href="https://airtable.com/shrQMKQNzOiS5eGRh"
            textDecoration="underline"
          >
            contact support.
          </Text.a>{' '}
        </Paragraph>
        <Box.div
          alignItems="center"
          display="grid"
          gap="d2"
          gridTemplateColumns={{ _: '1', md: 'repeat(3, auto)' }}
          justifyItems="center"
        >
          <Button
            as="a"
            href="https://localyze.statuspage.io/"
            rel="noopener noreferrer"
            target="_blank"
            variant="ghost"
          >
            Check application status
          </Button>
          <Button as={Link} to="/" variant="secondary">
            Go back to dashboard
          </Button>
        </Box.div>
      </Box.div>
    </Box.div>
  );
};
