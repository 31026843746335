import { getDocuments } from 'deprecated/api/international/documents';
import { getCaseInformation } from 'deprecated/api/international/getCaseInformation';
import { CODES } from './constants';
import { Document } from 'modules/documents/api/attachments/types/Document';
import { CaseInformationResponse } from 'modules/caseDetails/components/CaseManagement/CaseSteps/types/CaseInformationResponse';
import { StepData } from './types/StepData';
import { formatComponents } from 'deprecated/utils/helper/international/formatComponents';
import { formatDocumentsList } from './formatDocumentsList';
import { CaseStepWithComponents } from 'modules/pipelines/types/CaseStep';
import { StepComponent } from 'modules/pipelines/types/StepComponent';

const DOCUMENT_TYPE = 'document';

const isDocument = (component: StepComponent) => component.component_type === DOCUMENT_TYPE;

const getAppointmentComponent = (components: StepComponent[]) => {
  return components.find(
    (component) => component.internal_reference_code === CODES.embassyAppointmentDate,
  );
};

const parseNonDocumentsWithAppointment = (components: StepComponent[]) =>
  components.map((component) =>
    component.internal_reference_code === CODES.embassyAppointmentDate
      ? { ...component, component_type: 'datetimeAppointmentEmbassy' }
      : component,
  );

const getCodes = (components: StepComponent[]) =>
  components.filter((e) => e.internal_reference_code).map((e) => e.internal_reference_code);

export const progressStepData = async (
  step: CaseStepWithComponents,
  callbackSuccess: (data: StepData) => void,
  caseId: number,
): Promise<void> => {
  const { components } = step;

  let parsedComponents = components;

  const docComponents = components.filter(isDocument);
  const documentCodes = docComponents.map((component) => component.internal_reference_code);
  const nonDocReferenceCodes = getCodes(parsedComponents);

  const { name, description } = step;

  try {
    const { data: documents } = (await getDocuments(documentCodes, caseId)) as {
      data: Document[];
    };

    /* EXCEPTION FOR THE APPOINTMENT DATE */
    const appointmentComponent = getAppointmentComponent(parsedComponents);

    if (appointmentComponent) {
      nonDocReferenceCodes.push(CODES.noEmbassyAppointment);
      parsedComponents.push({
        ...appointmentComponent,
        component_type: 'checkboxAppointmentEmbassy',
        internal_reference_code: CODES.noEmbassyAppointment,
      });

      parsedComponents = parseNonDocumentsWithAppointment(parsedComponents);
    }

    const { data: submittedData } = (await getCaseInformation(caseId, nonDocReferenceCodes)) as {
      data: CaseInformationResponse[];
    };

    const codesUploaded = documents
      .filter((code) => code.internal_reference_code !== null)
      .map((component) => component.internal_reference_code as string);

    const formattedComponents = formatComponents(
      parsedComponents,
      codesUploaded,
      submittedData,
      caseId,
    );

    const documentsList = formatDocumentsList(docComponents, codesUploaded, caseId);

    callbackSuccess({
      components: formattedComponents,
      description,
      listDocs: documentsList,
      title: name,
    });
  } catch (e) {
    callbackSuccess({
      components: [],
      description,
      listDocs: [],
      title: name,
    });
  }
};
