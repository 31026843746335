import { Button, Heading, useModalStore } from '@localyze-pluto/components';
import { CardWithTabs } from 'components/CardWithTabs/CardWithTabs';
import React from 'react';
import { useTalent } from 'modules/talent/pages/talentProfile/components/TalentContext/hooks/useTalent';
import { EditTalentModal } from 'modules/talent/pages/talentProfile/components/EditTalentModal/EditTalentModal';
import { getTalentInformationTabs } from '../../getTalentInformationTabs';

export const TalentInformationCard = (): React.JSX.Element => {
  const { Header, Content } = CardWithTabs;

  const talent = useTalent();

  const modal = useModalStore();
  const isModalOpen = modal.useState('open');

  return (
    <>
      <CardWithTabs h="400" tabs={getTalentInformationTabs(talent)}>
        <Header
          action={
            <Button leadingIcon="square-pen" onClick={modal.show} variant="ghost">
              Edit
            </Button>
          }
          heading={
            <Heading color="colorTextStrongest" marginBottom="m0" size="title-subsection">
              Talent information
            </Heading>
          }
          tabListAriaLabel="Talent information tabs"
        />
        <Content padding="p0" />
      </CardWithTabs>
      {isModalOpen && <EditTalentModal modal={modal} talent={talent} />}
    </>
  );
};
