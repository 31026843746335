import React, { useRef, useState } from 'react';
import { TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';
import { isEmpty } from 'lodash';
import { searchTalents } from 'deprecated/api/search/searchTalents';

const SEARCH_WAITING_TIME = 1000;

type UseSearchReturnType = {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  talents: TalentSearchResult[];
  setTalents: (talents: TalentSearchResult[]) => void;
  search: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isPending: boolean;
};

export const useTalentSearch = (companyId?: number): UseSearchReturnType => {
  const [searchTerm, setSearchTerm] = useState('');
  const [talents, setTalents] = useState<TalentSearchResult[]>([]);
  const [isPending, setIsPending] = useState(false);

  const timeoutRef = useRef<number>();

  const search = async (event: React.ChangeEvent<HTMLInputElement>) => {
    window.clearTimeout(timeoutRef.current);
    const term = event.target.value;
    setSearchTerm(term);

    if (isEmpty(term)) {
      setTalents([]);
      setIsPending(false);
      return;
    }

    setIsPending(true);
    timeoutRef.current = window.setTimeout(async () => {
      const { data } = await searchTalents({ search: term, companyId });
      setIsPending(false);
      setTalents(data);
    }, SEARCH_WAITING_TIME);
  };

  return {
    isPending,
    search,
    searchTerm,
    setSearchTerm,
    setTalents,
    talents,
  };
};
