import { DialogStore } from '@ariakit/react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { FollowersOptionsHelpText } from 'components/FollowersOptionsHelpText/FollowersOptionsHelpText';
import { MultiUserSelect } from 'components/MultiUserSelect/MultiUserSelect';
import { mapUsersToMultiUserSelectItems } from 'utils/mapUsersToMultiUserSelectItems';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { Case } from 'modules/cases/types/Case';
import { useCompanyUsers } from 'modules/company/api/useCompanyUsers/useCompanyUsers';
import { getAllowedFollowers } from 'modules/utils/getAllowedFollowers';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type EditFollowersFormTypes = {
  followers: string[];
};

export const EditFollowersModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const toast = useToast();
  const { data: hrUsers, isSuccess: isSuccessHRUsers } = useCompanyUsers({
    params: { companyId: kase.company_id },
  });
  const followersSelectOptions = mapUsersToMultiUserSelectItems(
    getAllowedFollowers(hrUsers, kase.hr_manager_id),
  );
  const defaultFollowers = kase.followers.map((follower) => follower.id.toString());
  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Followers updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Followers could not be updated', 'error'),
  });

  const { handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      followers: defaultFollowers,
    },
  });

  const onSubmit: SubmitHandler<EditFollowersFormTypes> = (values) =>
    updateCase({ caseId: kase.id, payload: { follower_ids: values.followers } });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Followers</ModalHeading>
      </ModalHeader>
      {!isSuccessHRUsers ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Box.form id="edit-followers-form" onSubmit={handleSubmit(onSubmit)}>
              <MultiUserSelect
                emptyMessage="There are no team members available."
                helpText={<FollowersOptionsHelpText />}
                items={followersSelectOptions}
                label="Add Follower"
                name="followers"
                onChange={(value) => setValue('followers', value as string[])}
                value={getValues('followers')}
              />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={modal.hide} type="button" variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={isPendingUpdateCase}
              form="edit-followers-form"
              loading={isPendingUpdateCase}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
