import React from 'react';
import { Box } from '@localyze-pluto/components';
import {
  GLOMO_REQUEST_RISK_LEVEL,
  GLOMO_REQUEST_RISK_COLOR,
} from 'modules/glomoRequests/constants';
import { GlomoRequestRisk } from '../api/types/GlomoRequestRisk';

export const RiskLevel = ({ risk }: { risk: GlomoRequestRisk }): React.JSX.Element => {
  return (
    <Box.div alignItems="center" display="flex">
      <Box.span
        backgroundColor={GLOMO_REQUEST_RISK_COLOR[risk].dot}
        borderRadius="borderRadiusCircle"
        h={8}
        marginRight="m2"
        w={8}
      />
      <Box.span fontSize="fontSize20" style={{ whiteSpace: 'nowrap' }}>
        {GLOMO_REQUEST_RISK_LEVEL[risk]}
      </Box.span>
    </Box.div>
  );
};
