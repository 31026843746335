import { Filter } from 'modules/filters/types/Filter';

export const VARIABLE_TYPE_FILTER: Filter<string> = {
  attribute: 'variable_type',
  label: 'Variable Type',
  options: [
    {
      filterAttribute: 'variable_type',
      label: 'All',
      userTypes: ['admin'],
      value: 'all',
    },
    {
      filterAttribute: 'variable_type',
      label: 'Checkbox',
      userTypes: ['admin'],
      value: 'checkbox',
    },
    {
      filterAttribute: 'variable_type',
      label: 'Datetime',
      userTypes: ['admin'],
      value: 'datetime',
    },
    {
      filterAttribute: 'variable_type',
      label: 'Document',
      userTypes: ['admin'],
      value: 'document',
    },
    {
      filterAttribute: 'variable_type',
      label: 'Information',
      userTypes: ['admin'],
      value: 'information',
    },
    {
      filterAttribute: 'variable_type',
      label: 'Link',
      userTypes: ['admin'],
      value: 'link',
    },
    {
      filterAttribute: 'variable_type',
      label: 'Paperform',
      userTypes: ['admin'],
      value: 'paperform',
    },
    {
      filterAttribute: 'variable_type',
      label: 'Text',
      userTypes: ['admin'],
      value: 'text',
    },
  ],
  type: 'multiselect',
  userTypes: ['admin'],
};
