import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { PartnerService } from 'modules/partners/types/PartnerService';

const STALE_TIME_5_MINUTES = 5 * 60 * 1000;

export type GetPartnerServicesParams = {
  serviceCategoryId?: number;
};

const getPartnerServices = async ({ serviceCategoryId }: GetPartnerServicesParams = {}): Promise<
  PartnerService[]
> => {
  const { data } = await axios.get<PartnerService[]>('/partner_services', {
    params: { service_category_id: serviceCategoryId },
  });

  return data;
};

export const usePartnerServices = ({
  serviceCategoryId,
  enabled,
}: GetPartnerServicesParams &
  Pick<UseQueryOptions<PartnerService[]>, 'enabled'> = {}): UseQueryResult<PartnerService[]> => {
  return useQuery({
    queryKey: ['partner-services', serviceCategoryId],
    queryFn: () => getPartnerServices({ serviceCategoryId }),
    staleTime: STALE_TIME_5_MINUTES,
    enabled,
  });
};
