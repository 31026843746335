import { DialogStore } from '@ariakit/react/dialog';
import { useToast } from '@localyze-pluto/components';
import { validationRequestAppointmentException } from 'deprecated/pages/viewsNew/common/ProgressStep/MapStepComponents/MapStepComponents';
import { formikValuesToSubmitStepInformation } from 'deprecated/utils/helper/international/formikValuesToSubmitStepInformation';
import { FormikValues } from 'formik';
import { useCasePipelines } from 'modules/pipelines/hooks/useCasePipelines';
import { useSubmitCaseStep } from 'modules/pipelines/hooks/useSubmitCaseStep';
import { UserType } from 'modules/pipelines/types/Step';
import { Task } from 'modules/tasks/types/Task';
import { useState } from 'react';

type UseCaseStepSubmissionProps = {
  caseId: number;
  selectedTask?: Task;
  onCompleteTask: () => void;
  stepDialog: DialogStore;
  isHR: boolean;
};

export const useCaseStepSubmission = ({
  caseId,
  selectedTask,
  onCompleteTask,
  stepDialog,
  isHR,
}: UseCaseStepSubmissionProps): {
  onCompleteStep: (values: FormikValues[]) => void;
  isUpdateButtonDisabled: boolean;
} => {
  const toast = useToast();
  const [submitting, setSubmitting] = useState(false);
  const { data: casePipelines, isSuccess: isSuccessCasePipelines } = useCasePipelines(caseId);

  const { mutate: submitCaseStep } = useSubmitCaseStep({
    caseId,
    onSuccess: () => {
      setSubmitting(false);
      onCompleteTask();
      stepDialog.hide();
    },
    onError: () => toast('The step could not be submitted.', 'error'),
  });

  const onCompleteStep = (values: FormikValues[]) => {
    const isAppointmentValid = validationRequestAppointmentException(values) as boolean;

    if (!isAppointmentValid) {
      toast(
        'Something went wrong. To proceed, please enter a date or click the checkbox if no ' +
          'appointment is available',
        'error',
      );
    } else {
      setSubmitting(true);
      submitCaseStep({
        id: Number(selectedTask?.case_step_id),
        caseInformations: formikValuesToSubmitStepInformation(values),
      });
    }
  };

  const selectedCaseStep = (casePipelines || [])
    .flatMap((casePipeline) => casePipeline.case_steps)
    .find(({ id }) => id === selectedTask?.case_step_id);

  const isUpdateButtonDisabled =
    !isSuccessCasePipelines ||
    submitting ||
    selectedCaseStep?.status === 'preview' ||
    (isHR && selectedCaseStep?.user_type !== UserType.HrManager);

  return {
    onCompleteStep,
    isUpdateButtonDisabled,
  };
};
