import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import axios, { AxiosError } from 'axios';
import { CasePipeline } from 'modules/pipelines/types/CasePipeline';
import { queryClient } from 'config/queryClient';
import { getCasePipelinesQueryKey } from 'modules/pipelines/hooks/useCasePipelines';

type AssignPipelineProps = {
  caseId: number;
  pipelineId: number;
};

const assignPipeline = async ({
  caseId,
  pipelineId,
}: AssignPipelineProps): Promise<CasePipeline> => {
  const params = {
    case_id: caseId,
    pipeline_id: pipelineId,
  };

  const { data } = await axios.post<CasePipeline>('/case/pipelines', params);

  return data;
};

export const useAssignPipeline = ({
  onError,
  onSuccess,
}: Pick<
  UseMutationOptions<CasePipeline, AxiosError, AssignPipelineProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<CasePipeline, unknown, AssignPipelineProps> => {
  return useMutation({
    mutationFn: assignPipeline,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getCasePipelinesQueryKey(variables.caseId),
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
