import { UserRoleName } from 'modules/users/types/UserRoleName';
import { Tab } from 'types/Tab';

export const MY_TALENT_TABS: Tab<string>[] = [
  {
    name: 'My talent',
    key: 'my',
    roles: [UserRoleName.Regular, UserRoleName.SuperAdmin],
  },
  {
    name: 'Following',
    key: 'following',
    roles: [UserRoleName.Viewer],
  },
  {
    name: 'All talent',
    key: 'all',
    roles: [UserRoleName.Regular, UserRoleName.SuperAdmin],
  },
];
