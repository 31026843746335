import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalSubHeading,
  Paragraph,
  SelectItemProps,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { Validation } from 'constants/formValidations';
import { CompanyUser } from 'deprecated/api/companies/company_user';
import { useCompanyUsers } from 'modules/company/api/useCompanyUsers/useCompanyUsers';
import { useDeleteUser } from 'modules/users/api/useDeleteUser/useDeleteUser';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { createReplacementMemberOptions } from './utils/createReplacementMemberOptions/createReplacementMemberOptions';

type RemoveTeamMemberButtonAndModalProps = {
  userToRemove: Pick<CompanyUser, 'first_name' | 'id' | 'last_name' | 'role_name'>;
  companyId: number;
};

export const RemoveTeamMemberButtonAndModal = ({
  userToRemove,
  companyId,
}: RemoveTeamMemberButtonAndModalProps): React.JSX.Element => {
  const toast = useToast();
  const modalState = useModalStore();
  const isModalOpen = modalState.useState('open');

  const { data: users, isSuccess: isCompanyUsersOptionsSuccess } = useCompanyUsers<
    SelectItemProps[]
  >({
    params: {
      companyId: companyId,
    },
    select: (users) => {
      return createReplacementMemberOptions(users, userToRemove);
    },
  });
  const fullName = `${userToRemove.first_name} ${userToRemove.last_name}`;

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      replacementUserId: '',
    },
    mode: 'onTouched',
    resolver: yupResolver(
      Yup.object({
        replacementUserId: Validation.dropDown,
      }),
    ),
  });
  const { mutate: removeUser, isPending: isSubmitting } = useDeleteUser({
    companyId,
    onSuccess: async () => {
      reset();
      modalState.hide();
      toast(`${fullName} was removed successfully`, 'success');
    },
    onError: (error) => {
      toast(error.response?.data.message || 'There was an error removing the team member', 'error');
    },
  });

  const onSubmit = ({ replacementUserId }: { replacementUserId: string }) => {
    removeUser({ userId: userToRemove.id, replacementUserId: Number(replacementUserId) });
  };

  return (
    <>
      <Button
        aria-label="Remove team member"
        iconOnly
        leadingIcon="trash-2"
        onClick={modalState.show}
        variant="ghost"
      />

      {isModalOpen && (
        <Modal store={modalState}>
          <ModalHeader>
            <ModalHeading>Remove team member</ModalHeading>
            <ModalSubHeading>
              {fullName} will be removed from the company and the member chosen below will take over
              their talents, pre-checks, and tasks.
            </ModalSubHeading>
          </ModalHeader>

          <ModalBody>
            {isCompanyUsersOptionsSuccess && users.length > 0 ? (
              <Box.form id="remove-member-form" onSubmit={handleSubmit(onSubmit)}>
                <ControlledFormSelect
                  control={control}
                  helpText="Talents, pre-checks, and tasks will be transfered to this team member."
                  id="replacementUserId"
                  items={users}
                  label="Team member"
                  name="replacementUserId"
                  required
                />
              </Box.form>
            ) : isCompanyUsersOptionsSuccess && users.length === 0 ? (
              <Paragraph>
                You need a team member with the same or higher role to be able to transfer any
                applicable talents, pre-checks, and tasks and remove this team member.
              </Paragraph>
            ) : (
              <ContainedLoadingState />
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={isSubmitting}
              onClick={modalState.toggle}
              type="button"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting || users?.length === 0}
              form="remove-member-form"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              variant="destructive"
            >
              Remove
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
