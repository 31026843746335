import { TBody, Table, Td, Tr } from '@localyze-pluto/components';
import { HRISTalent } from 'modules/integrations/types/HRISTalent';
import React from 'react';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

export const DataTable = ({ hrisTalent }: { hrisTalent: HRISTalent }): React.JSX.Element => (
  <Table>
    <TBody style={{ fontSize: '14px' }}>
      <Tr>
        <Td>First name</Td>
        <Td>{hrisTalent.first_name}</Td>
      </Tr>
      <Tr>
        <Td>Last name</Td>
        <Td>{hrisTalent.last_name}</Td>
      </Tr>
      <Tr>
        <Td>Email</Td>
        <Td>{hrisTalent.email}</Td>
      </Tr>
      <Tr>
        <Td>Date of birth</Td>
        {/* solves a timestamp with T00:00:00 to be formatted to a day before in negative GMT  */}
        <Td>{formatDate(String(hrisTalent.birthday).replace(/T.*/, 'T12:00:00'))}</Td>
      </Tr>
    </TBody>
  </Table>
);
