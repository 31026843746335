import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { WaitingTime } from 'modules/knowledge/types/WaitingTime';

const publicFileUrl = (entryUrl: string) => entryUrl.replace('//images', 'https://images');

const getWaitingTimes = async (): Promise<WaitingTime[]> => {
  const waitingTimes = await new ContentfulAPI().fetchWaitingTime();

  return waitingTimes.map(({ countryFlag, ...attributes }) => ({
    ...attributes,
    countryFlagPublicUrl: publicFileUrl(countryFlag.fields.file.url),
  }));
};

export const useWaitingTimes = (): UseQueryResult<WaitingTime[]> => {
  return useQuery({ queryKey: ['waiting-times'], queryFn: getWaitingTimes });
};
