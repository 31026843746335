import { Filter } from 'modules/filters/types/Filter';
import { FilterArguments } from './types/FilterArguments';
import { filterByUserType } from 'deprecated/utils/helper/filterByUserType';
import { includesTab } from './includesTab';

/**
 * Runs the populateOptions method of a filter (e.g. query the API for options).
 * @param filter
 * @param isAdmin
 * @returns filter with populated options
 */
const populateFilterWithOptions = async (
  filter: Filter<string>,
  isAdmin: boolean,
): Promise<Filter<string>> => {
  return {
    ...filter,
    options: filter.populateOptions ? await filter.populateOptions({ isAdmin }) : filter.options,
  };
};

/**
 * Filters array of supplied filters by user type and tab, then populates each with options.
 * @param FilterArguments
 * @return filtered array of filters populated with options.
 */
export const populateFilters = async ({
  isAdmin,
  tab,
  filters,
}: FilterArguments): Promise<Filter<string>[]> =>
  await Promise.all(
    filters
      .filter((filter) => filterByUserType(filter, isAdmin))
      .filter((filter) => !tab || includesTab(filter, tab))
      .map((filter) => populateFilterWithOptions(filter, isAdmin)),
  );
