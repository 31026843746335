import { Box, Button, Input } from '@localyze-pluto/components';
import React, { useRef } from 'react';

type CopyToClipboardProps = {
  textToCopy: string;
  onError?: () => void;
  onSuccess?: () => void;
};

export const CopyToClipboard = ({
  textToCopy,
  onSuccess,
  onError,
}: CopyToClipboardProps): React.JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      onSuccess?.();
    } catch (error) {
      onError?.();
    }
  };

  return (
    <Box.div position="relative">
      <Input
        aria-label="Text to copy"
        defaultValue={textToCopy}
        paddingRight="d10"
        readOnly
        ref={inputRef}
        textOverflow="ellipsis"
        type="text"
      />
      <Box.div
        alignItems="center"
        bottom="1px"
        display="flex"
        position="absolute"
        right="1px"
        top="1px"
      >
        <Button
          aria-label="Copy text to clipboard"
          iconOnly
          leadingIcon="copy"
          onClick={copyTextToClipboard}
          type="button"
          variant="ghost"
        />
      </Box.div>
    </Box.div>
  );
};
