import { UseQueryResult, useQuery } from '@tanstack/react-query';

import isNaN from 'lodash/isNaN';
import { getCaseEvaluationSummary } from './getCaseEvaluationSummary';
import { CaseEvaluationSummary } from '../../types/CaseEvaluationSummary';

export const useCaseEvaluationSummary = ({
  caseEvaluationSummaryId,
}: {
  caseEvaluationSummaryId: number;
}): UseQueryResult<CaseEvaluationSummary> => {
  return useQuery({
    queryKey: ['case-evaluation-summary', String(caseEvaluationSummaryId)],
    queryFn: () => getCaseEvaluationSummary(Number(caseEvaluationSummaryId)),
    enabled: !isNaN(caseEvaluationSummaryId),
  });
};
