export const shutDownIntercom = () => {
  if (window.analytics) {
    if (!!window.Intercom) {
      window.Intercom('shutdown');
    }
  }
};

/**
 * Track an event in Segment/Amplitude
 * @param {string} event - event name
 * @param {Object} [properties] - a dictionary of properties for the event (path will automatically get added). If the event was 'Added to Cart', it might have properties like price and productType.
 */
export const trackEvent = (event, properties) => {
  const propertiesAndPath = {
    path: window.location.pathname,
    ...properties,
  };

  if (window.analytics) {
    analytics.track(event, propertiesAndPath);
  }
};
