import { Box, Modal, ModalHeader, ModalHeading } from '@localyze-pluto/components';

import { DisclosureStore } from '@ariakit/react';
import { PostNewMessage } from 'modules/caseDetails/components/CaseActivityCard/CreateMessageModal/components/PostNewMessage/PostNewMessage';
import React from 'react';

export type Props = {
  state: DisclosureStore;
  caseId: number;
};

export const CreateMessageModal = ({ state, caseId }: Props): React.JSX.Element => (
  <Modal store={state}>
    <ModalHeader>
      <ModalHeading>
        <Box.div>Post a new message</Box.div>
      </ModalHeading>
    </ModalHeader>
    <PostNewMessage caseId={caseId} modalState={state} />
  </Modal>
);
