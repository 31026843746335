import { InternalVariable, StepDescription } from './StepsComponents';
import React, { useState } from 'react';
import {
  submitCreateComponent,
  submitDeleteComponent,
  submitUpdateComponent,
} from 'deprecated/pages/viewsNew/Admin/PipelineEditor/utils/step_editor';
import { P3 } from 'deprecated/components/Fonts';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { uniq } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  ConfirmationModal,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';

const BE_LINK_REF = 'link';
const BE_DATE_REF = 'datetime';
const BE_CHECK_REF = 'checkbox';
const BE_DOC_REF = 'document';
const BE_PAPERFORM_REF = 'paperform';
const BE_INFO_REF = 'information';
const BE_DESC_REF = 'description';
const BE_TEXT_REF = 'text';

const componentTypesToAdd = [
  BE_TEXT_REF,
  BE_DATE_REF,
  BE_DOC_REF,
  BE_CHECK_REF,
  BE_PAPERFORM_REF,
  BE_LINK_REF,
  BE_INFO_REF,
  BE_DESC_REF,
];

export default function StepCompContainer({
  pipeline,
  internalVariables,
  stepComponents = [],
  onAddComponent,
  onMoveUp,
  onMoveDown,
}) {
  const toast = useToast();
  const { stepId } = useParams();

  const deleteComponentModalState = useModalStore();
  const isDeleteComponentModalOpen = deleteComponentModalState.useState('open');

  const [componentIdToDelete, setComponentIdToDelete] = useState('');

  const [components, setComponents] = useState(
    stepComponents.map((component) => ({ ...component, editing: false })),
  );

  const handleEditing = (id) =>
    setComponents(components.map((e) => (e.id === id ? { ...e, editing: !e.editing } : e)));

  const handleNewComponent = ({ componentType, content, index }) => {
    const callbackSuccess = ({ id, content: newContent }) => {
      setComponents(
        components.map((e, ind) =>
          ind !== index ? e : { ...e, id, content: newContent, editing: false },
        ),
      );
      toast('Successfully created.', 'success');
    };
    const callbackError = () =>
      toast(
        'Something went wrong creating the component, please try again refreshing the page',
        'error',
      );
    submitCreateComponent({
      content,
      stepId: Number(stepId),
      componentType,
      callbackSuccess,
      callbackError,
    });
  };

  const handleUpdateComponent = ({ id, content }) => {
    const callbackSuccess = ({ content: newContent }) => {
      setComponents(
        components.map((e) =>
          e.id !== id ? e : { ...e, id, content: newContent, editing: false },
        ),
      );
      toast('Successfully updated.', 'success');
    };
    const callbackError = () =>
      toast(
        'Something went wrong updating the component, please try again refreshing the page',
        'error',
      );
    submitUpdateComponent({ id, content, stepId, callbackSuccess, callbackError });
  };

  const handleSave = ({ id, componentType, content }, index) => {
    const isNew = id === null;
    if (isNew) {
      handleNewComponent({ componentType, content, index });
    } else {
      handleUpdateComponent({ id, content });
    }
  };

  const buildEmptyComponent = ({ id = null, componentType, content = '', editing = true }) => ({
    id,
    componentType,
    content,
    editing,
    stepId,
  });

  const handleAddComp = (compType) => {
    setComponents([...components, buildEmptyComponent({ componentType: compType })]);
    onAddComponent(uniq([...components.map((component) => component.componentType), compType]));
    trackEvent(`pipeline editor: add ${compType} to step`);
  };

  const handleDelete = (id) => {
    const callbackSuccess = () => {
      toast('Successfully deleted.', 'success');
      setComponentIdToDelete('');
      setComponents(components.filter((e) => e.id !== id));
    };
    const callbackError = (message) => toast(message, 'error');
    submitDeleteComponent({ stepId, id, callbackSuccess, callbackError });
  };

  const getCancelSaveButtons = (id) => (
    <Box.div display="flex" justifyContent="end" gap="d2">
      <Button variant="secondary" onClick={() => handleEditing(id)}>
        Cancel
      </Button>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Box.div>
  );

  const renderComponent = (component, index) => {
    const { id, componentType } = component;
    const commonProps = {
      component,
      handleSave,
      buttons: getCancelSaveButtons(id),
      index,
      onMoveDown,
      onMoveUp,
    };

    switch (componentType) {
      case BE_LINK_REF:
      case BE_DATE_REF:
      case BE_CHECK_REF:
      case BE_DOC_REF:
      case BE_PAPERFORM_REF:
      case BE_INFO_REF:
      case BE_TEXT_REF:
        return (
          <InternalVariable
            {...commonProps}
            internalVariables={internalVariables.filter(
              (internalVariable) => internalVariable.variable_type === componentType,
            )}
            pipeline={pipeline}
          />
        );
      case BE_DESC_REF:
        return <StepDescription {...commonProps} />;
      default:
        return null;
    }
  };

  const getEditDeleteButtons = ({ id, editing }) => {
    const getEdit = () => (
      <Button
        onClick={() => handleEditing(id)}
        variant="ghost"
        iconOnly
        leadingIcon="pencil"
        aria-label="Edit"
        data-testid="edit-component-button"
      />
    );

    const getDelete = () => (
      <Button
        variant="ghost"
        iconOnly
        leadingIcon="trash-2"
        data-testid="delete-component-button"
        onClick={() => {
          setComponentIdToDelete(id);
          deleteComponentModalState.toggle();
        }}
        aria-label="Delete"
      />
    );

    return (
      <div style={{ position: 'absolute', right: '0px', top: '0px', margin: '10px' }}>
        {!editing && getEdit()}
        {getDelete()}
      </div>
    );
  };

  const capitalize = (string) => string.replace(/^\w/, (c) => c.toUpperCase());

  const getAddButtons = () =>
    componentTypesToAdd.map((compType, i) => (
      <div
        style={{ display: 'flex', cursor: 'pointer' }}
        key={compType}
        onClick={() => handleAddComp(compType)}
        onKeyDown={() => handleAddComp(compType)}
        role="button"
        tabIndex={i}
      >
        <IconSec className="add circle icon pointer" />
        <P3 bold>{capitalize(compType)}</P3>
      </div>
    ));

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '10px',
          alignItems: 'center',
        }}
      >
        {getAddButtons()}
      </div>
      {components.map((comp, index) => (
        <StepCompWrapper key={comp.id}>
          {getEditDeleteButtons(comp)}
          {renderComponent(comp, index)}
        </StepCompWrapper>
      ))}
      {isDeleteComponentModalOpen && (
        <ConfirmationModal
          store={deleteComponentModalState}
          onConfirm={() => handleDelete(componentIdToDelete)}
          confirmLabel="Delete"
          destructive
        >
          Are you sure you want to delete the component? It will be removed from this step.
        </ConfirmationModal>
      )}
    </>
  );
}

const StepCompWrapper = styled.div`
  padding: 20px;
  position: relative;
  box-shadow: var(--box-shadow);
  margin-top: 20px;
`;

const IconSec = styled.i`
  color: var(--secondary-color-full);
`;

StepCompContainer.propTypes = {
  internalVariables: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.string,
      variable_type: PropTypes.string,
      internal_references: PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
      }),
      destination_country: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        code: PropTypes.string,
      }),
      origin_country: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        code: PropTypes.string,
      }),
    }),
  ),
  stepComponents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      componentType: PropTypes.string,
      content: PropTypes.string,
      stepId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};
