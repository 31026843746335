import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CompanyResponse, getCompany } from './getCompany';

type UseCompanyProps<UserRole> = Pick<
  UseQueryOptions<CompanyResponse<UserRole>, AxiosError>,
  'enabled' | 'throwOnError'
> & {
  companyId?: number;
};

type UseCompanyResult<UserRole> = UseQueryResult<CompanyResponse<UserRole>, AxiosError>;

export const COMPANY_QUERY_KEY = 'company';

export const useCompany = <UserRole>({
  companyId,
  enabled = true,
  throwOnError = true,
}: UseCompanyProps<UserRole>): UseCompanyResult<UserRole> => {
  return useQuery({
    queryKey: [COMPANY_QUERY_KEY, companyId] as QueryKey,
    queryFn: () => getCompany<UserRole>(companyId),
    enabled: companyId !== undefined && enabled,
    throwOnError,
  });
};
