import { DialogStore } from '@ariakit/react';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { CaseState } from 'modules/cases/types/CaseState';
import { getStateOptions } from 'modules/caseDetails/components/CaseManagement/CaseManagement';

export const EditCaseStateModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const { current_state: currentState } = kase;
  const toast = useToast();
  const stateOptions = getStateOptions(kase);

  const { mutate: updateCase, isPending: isUpdatingCase } = useUpdateCase({
    onSuccess: () => {
      toast('Case state updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Case state could not be updated', 'error'),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: { state: currentState },
  });

  const onSubmit: SubmitHandler<FieldValues> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { state: values.state as CaseState },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Change case state</ModalHeading>
      </ModalHeader>

      <ModalBody>
        <Box.form id="change-case-state-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormSelect
            control={control}
            disabled={isUpdatingCase}
            id="state"
            items={stateOptions}
            label="Case state"
            name="state"
          />
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isUpdatingCase}
          form="change-case-state-form"
          loading={isUpdatingCase}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
