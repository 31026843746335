import { Box, BoxProps, Button } from '@localyze-pluto/components';
import React from 'react';

type KeyValueListItem = {
  key: string;
  value: React.JSX.Element | string | null | undefined;
  onEdit?: () => void;
};

type KeyValueListProps = {
  items: KeyValueListItem[];
  px?: BoxProps['px'];
  py?: BoxProps['px'];
  borderBottomStyle?: BoxProps['borderBottomStyle'];
};

export const KeyValueList = ({
  items,
  px = 'd6',
  py = 'd4',
  borderBottomStyle,
}: KeyValueListProps): React.JSX.Element => (
  <Box.ul
    borderBottomColor="colorBorderWeakest"
    borderBottomStyle={borderBottomStyle}
    borderBottomWidth="borderWidth10"
    mb="0"
    p="0"
  >
    {items.map(({ key, value, onEdit }, index) => (
      <Box.li
        alignItems="center"
        borderBottomColor="colorBorderWeakest"
        borderBottomStyle="borderStyleSolid"
        borderBottomWidth={index !== items.length - 1 ? 'borderWidth10' : 'borderWidth0'}
        display="flex"
        fontSize="fontSize20"
        gap="d4"
        key={key}
        px={px}
        py={py}
      >
        <Box.div color="contentSecondary" w="250px">
          {key}
        </Box.div>
        <Box.div alignItems="center" display="flex" justifyContent="space-between" w="100%">
          {React.isValidElement(value) ? value : (value ?? '-')}
          {onEdit && (
            <Box.div>
              <Button
                aria-label={`Edit ${key}`}
                iconOnly
                leadingIcon="square-pen"
                onClick={onEdit}
                variant="ghost"
              />
            </Box.div>
          )}
        </Box.div>
      </Box.li>
    ))}
  </Box.ul>
);
