import React, { useEffect } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeading,
  Heading,
  useToast,
} from '@localyze-pluto/components';
import { StatusUpdates } from 'modules/statusUpdates/components/StatusUpdates';
import { ServiceDetails } from '../ServiceDetails/ServiceDetails';
import type { DialogStore as DrawerState } from '@ariakit/react';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';
import { useStatusUpdates } from 'modules/statusUpdates/api/useStatusUpdates/useStatusUpdates';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

export type ServiceDrawerProps = {
  heading: string;
  state: DrawerState;
  caseAdditionalServiceId: number;
};

export const ServiceDrawer: React.FC<ServiceDrawerProps> = ({
  heading,
  state,
  caseAdditionalServiceId,
}) => {
  const {
    data: updates,
    isError,
    isSuccess,
    isLoading,
    error,
  } = useStatusUpdates({
    subjectType: 'CaseAdditionalService',
    subjectId: caseAdditionalServiceId,
    enabled: !!caseAdditionalServiceId,
  });

  const toast = useToast();

  useEffect(() => {
    if (isError) {
      toast(error.message || 'Error fetching status updates.', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Drawer
      getPersistentElements={() => document.querySelectorAll('[role="region"]')}
      store={state}
    >
      <DrawerHeader>
        <DrawerHeading>{heading}</DrawerHeading>
      </DrawerHeader>
      <DrawerBody>
        <Box.div display="flex" flexDirection="column" gap="d4" h="100%">
          <Box.div
            borderColor="borderSecondary"
            borderRadius="borderRadius30"
            borderStyle="borderStyleSolid"
            borderWidth="borderWidth10"
            display="flex"
            flexDirection="column"
            minH="50%"
          >
            <Heading
              as="h6"
              fontWeight="fontWeightBold"
              marginBottom="m0"
              padding="p4"
              size="heading60"
            >
              Status updates
            </Heading>
            <SectionDivider />
            {isLoading ? (
              <ContainedLoadingState />
            ) : (
              isSuccess && <StatusUpdates padding="d4" updates={updates} />
            )}
          </Box.div>
          <ServiceDetails caseAdditionalServiceId={caseAdditionalServiceId} />
        </Box.div>
      </DrawerBody>
    </Drawer>
  );
};
