import axios from 'axios';
import { GLOMO_REQUEST_QUERY_KEY } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { GlomoRequest } from '../types/GlomoRequest';
import { PENDING_COUNTERS_KEY } from 'modules/home/api/usePendingCounters';

type DenyGlomoRequestProps = { glomoRequestId: number; denialReason: string };

const denyGlomoRequest = async ({
  glomoRequestId,
  denialReason,
}: DenyGlomoRequestProps): Promise<GlomoRequest> => {
  const url = `/glomo/requests/${glomoRequestId}/denials`;
  const { data } = await axios.post<GlomoRequest>(url, {
    glomo_request: { denial_reason: denialReason },
  });

  return data;
};

export const useDenyGlomoRequest = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}): UseMutationResult<GlomoRequest, unknown, DenyGlomoRequestProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: denyGlomoRequest,
    onSuccess: (glomoRequest) => {
      queryClient.invalidateQueries({
        queryKey: [GLOMO_REQUEST_QUERY_KEY, glomoRequest.id],
      });
      queryClient.invalidateQueries({ queryKey: [PENDING_COUNTERS_KEY] });

      onSuccess?.();
    },
    onError,
  });
};
