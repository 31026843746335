import { Table, TBody, Th, THead, Tr } from '@localyze-pluto/components';
import React, { ReactNode } from 'react';

import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { ColumnId, glomoRequestRowCells } from './GlomoRequestRowCell';

export type TableRowCells<ColumnId extends string> = Record<
  ColumnId,
  { render: (glomoRequest: GlomoRequest) => ReactNode }
>;

type Columns<Identifier> = { id: Identifier; name: string }[];

type Props<Identifier extends string> = {
  glomoRequests: GlomoRequest[];
  columns: Columns<Identifier>;
};

export const GlomoRequestsTable = <Identifier extends ColumnId>({
  glomoRequests,
  columns,
}: Props<Identifier>): React.JSX.Element => {
  return (
    <Table striped>
      <THead>
        <Tr>
          {columns.map((column) => (
            <Th key={column.id}>{column.name}</Th>
          ))}
        </Tr>
      </THead>
      <TBody>
        {glomoRequests.map((glomoRequest) => (
          <Tr hasHover key={glomoRequest.id}>
            {columns.map((column) => glomoRequestRowCells[column.id].render(glomoRequest))}
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};
