import React from 'react';
import { useTalent } from '../TalentContext/hooks/useTalent';
import { useCases } from 'modules/cases/api/useCases/useCases';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { Box, Heading } from '@localyze-pluto/components';
import { CaseCardRow } from './CaseCardRow/CaseCardRow';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';

export const CasesCard = (): React.JSX.Element => {
  const { Content, Header } = SectionCard;
  const talent = useTalent();
  const { data: cases } = useCases({ talentId: talent.id });

  return (
    <SectionCard>
      <Header>
        <Heading as="h3" marginBottom="m0" size="heading60">
          Cases
        </Heading>
      </Header>
      <Content padding="p0">
        <Box.ul marginBottom="m0" paddingLeft="p0" w="100%">
          {cases?.map((kase, index) => {
            return (
              <Box.li key={index}>
                <CaseCardRow kase={kase} key={kase.id} />
                {index !== cases.length - 1 && <SectionDivider />}
              </Box.li>
            );
          })}
        </Box.ul>
      </Content>
    </SectionCard>
  );
};
