import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { CaseStateFilter } from 'modules/cases/types/CaseStateFilter';

/**
 * Gets the number of cases by status type
 * @param type - status type: CaseStatusType
 * @param data - report data array
 * @returns number
 */
export const getCasesNumber = (type: CaseStateFilter, data: CaseReport[]): number => {
  const baseNumber = 0;

  if (type === 'active') {
    return data.filter((val) => {
      if (val.currentState !== 'closed') {
        return true;
      } else {
        return false;
      }
    }).length;
  }

  if (type === 'closed') {
    return data.filter((val) => {
      if (val.currentState === 'closed') {
        return true;
      } else {
        return false;
      }
    }).length;
  }

  return baseNumber;
};
