import { Anchor, Box, Paragraph, Switch, useToast } from '@localyze-pluto/components';
import { CreateCaseSteps, Step1Payload, Step2Payload, Step3Payload, Step4Payload } from './types';
import { Link, Prompt } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Step1, Step2, Step3, Step4, Success } from './components/Steps';
import {
  step1InitialValues,
  step2InitialValues,
  step3InitialValues,
  step4InitialValues,
} from './constants/initialValues';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { HRISTalent } from 'modules/integrations/types/HRISTalent';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { StepInfoCard } from './components/StepInfoCard';
import { useCreateCase } from '../../api/useCreateCase/useCreateCase';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { trackPageView } from 'modules/analytics/analytics';
import { useCurrentUser } from 'config/CurrentUserContext';
import { TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export const CreateCase = (): React.JSX.Element => {
  const [confirmBeforeLeaving, setConfirmBeforeLeaving] = useState(false);
  const { isAdmin, company_id } = useCurrentUser();
  const routes = useRoutes();
  const toast = useToast();
  const {
    data: newCase,
    mutate: addCase,
    isSuccess: hasCreatedCaseSuccessfully,
    isPending,
  } = useCreateCase({
    onSuccess: () => {
      setConfirmBeforeLeaving(false);
    },
    onError: (err) => {
      toast(err.response?.data.message || 'Something went wrong', 'error');
    },
  });
  const isDemoModeAvailable = useIsFeatureFlagActive('add_talent_demo');
  const [isDemoModeActive, setIsDemoModeActive] = useState(false);
  const [demoSuccess, setDemoSuccess] = useState(false);

  const [currentStep, setCurrentStep] = useState(CreateCaseSteps.Step1);
  const [talent, setTalent] = useState<TalentSearchResult>();

  // The final payload sent to the endpoint will the aggregate of the step payloads
  const [step1Payload, setStep1Payload] = useState<Step1Payload>({
    ...step1InitialValues,
    // Admins have to select the company, while non-admins will have their company defaulted
    companyId: isAdmin ? '' : company_id.toString(),
  });
  const [step2Payload, setStep2Payload] = useState<Step2Payload>(step2InitialValues);
  const [step3Payload, setStep3Payload] = useState<Step3Payload>(step3InitialValues);
  const [step4Payload, setStep4Payload] = useState<Step4Payload>(step4InitialValues);

  const resetPayloads = () => {
    setStep1Payload(step1InitialValues);
    setStep2Payload(step2InitialValues);
    setStep3Payload(step3InitialValues);
    setStep4Payload(step4InitialValues);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    trackPageView('CreateCase');
  }, []);

  if (hasCreatedCaseSuccessfully || demoSuccess) {
    return <Success caseId={newCase?.id} />;
  }

  const setHRISData = (talentData: HRISTalent) => {
    const citizenship = talentData.citizenship_ids?.[0];

    setStep1Payload({
      ...step1Payload,
      ...(talentData.employee_id && { employeeId: talentData.employee_id }),
      ...(talentData.providerName && { providerName: talentData.providerName }),
      ...(talentData.first_name && { firstName: talentData.first_name }),
      ...(talentData.last_name && { lastName: talentData.last_name }),
      ...(talentData.email && { email: talentData.email }),
    });

    setStep2Payload({
      ...step2Payload,
      ...(citizenship && { citizenshipCountry: citizenship.toString() }),
      ...(talentData.destination_country_id && {
        destinationCountry: talentData.destination_country_id.toString(),
      }),
      ...(talentData.origin_country_id && {
        countryOfResidence: talentData.origin_country_id.toString(),
      }),
    });

    setStep3Payload({
      ...step3Payload,
      ...(talentData.hiring_manager && { hiringManager: talentData.hiring_manager }),
      ...(talentData.cost_centers && { costCenter: talentData.cost_centers.join(', ') }),
      ...(talentData.office_id && {
        officeId: talentData.office_id.toString(),
      }),
      ...(talentData.first_day_at_work && {
        startDate: formatDate(talentData.first_day_at_work, 'yyyy-MM-dd'),
      }),
      ...(talentData.job_title && { jobTitle: talentData.job_title }),
    });
  };

  return (
    <PageLayout flexed title="Create a new case">
      <>
        {!isAdmin && (
          <Paragraph>
            Not sure if this Talent is eligible for a visa or residence permit?{' '}
            <Anchor
              as={Link}
              onClick={() => {
                trackEvent('create case: navigate to submit a pre-check first');
              }}
              to={routes.preCheck.get()}
            >
              Submit a pre-check first
            </Anchor>
          </Paragraph>
        )}
      </>

      {(isDemoModeAvailable || isAdmin) && (
        <Box.div display="flex" justifyContent="flex-end" marginBottom="m4">
          <Switch
            checked={isDemoModeActive}
            disabled={currentStep !== CreateCaseSteps.Step1}
            onCheckedChange={() => {
              setIsDemoModeActive(!isDemoModeActive);
            }}
          >
            Demo
          </Switch>
        </Box.div>
      )}
      <Box.div
        alignItems="start"
        display="grid"
        gap="d9"
        gridTemplateColumns={{ _: '1', lg: '33% auto' }}
        h="100%"
        paddingBottom="d9"
      >
        <StepInfoCard currentStep={currentStep} talent={talent} />
        <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius30" padding="d6">
          {currentStep === CreateCaseSteps.Step1 && (
            <Step1
              handleNextClick={(payload) => {
                setStep1Payload(payload);
                setCurrentStep(CreateCaseSteps.Step2);
                setConfirmBeforeLeaving(true);
              }}
              isDemoModeActive={isDemoModeActive}
              setHRISData={setHRISData}
              setTalent={setTalent}
              values={step1Payload}
            />
          )}
          {currentStep === CreateCaseSteps.Step2 && (
            <Step2
              companyId={step1Payload.companyId}
              handleBackClick={(payload) => {
                if (talent) {
                  setTalent(undefined);
                  resetPayloads();
                } else {
                  setStep2Payload(payload);
                }

                setCurrentStep(CreateCaseSteps.Step1);
              }}
              handleNextClick={(payload) => {
                setStep2Payload(payload);
                setCurrentStep(CreateCaseSteps.Step3);
              }}
              isDemoModeActive={isDemoModeActive}
              values={step2Payload}
            />
          )}
          {currentStep === CreateCaseSteps.Step3 && (
            <Step3
              companyId={step1Payload.companyId}
              destinationCountry={step2Payload.destinationCountry}
              handleBackClick={(payload) => {
                setStep3Payload(payload);
                setCurrentStep(CreateCaseSteps.Step2);
              }}
              handleNextClick={(payload, skipToSubmit) => {
                setStep3Payload(payload);

                if (skipToSubmit) {
                  addCase({
                    ...step1Payload,
                    ...step2Payload,
                    ...payload,
                    ...step4Payload,
                  });
                } else {
                  setCurrentStep(CreateCaseSteps.Step4);
                }
              }}
              isCreatingCase={isPending}
              isDemoModeActive={isDemoModeActive}
              values={step3Payload}
            />
          )}
          {currentStep === CreateCaseSteps.Step4 && (
            <Step4
              caseTypeId={step2Payload.caseTypeId}
              companyId={step1Payload.companyId}
              destinationCountry={step2Payload.destinationCountry}
              handleBackClick={(payload) => {
                setStep4Payload(payload);
                setCurrentStep(CreateCaseSteps.Step3);
              }}
              handleNextClick={async (payload) => {
                setStep4Payload(payload);

                if (isDemoModeActive) {
                  setDemoSuccess(true);
                } else {
                  addCase({
                    ...step1Payload,
                    ...step2Payload,
                    ...step3Payload,
                    ...payload,
                  });
                }
              }}
              isCreatingCase={isPending}
              isDemoModeActive={isDemoModeActive}
              values={step4Payload}
            />
          )}
        </Box.div>

        <Prompt
          // eslint-disable-next-line max-len
          message="Are you sure you want to leave this page? No information about this case will be saved if you leave this form."
          when={confirmBeforeLeaving}
        />
      </Box.div>
    </PageLayout>
  );
};
