import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Badge } from '@localyze-pluto/components';
import React from 'react';
import { Task } from 'modules/tasks/types/Task';
import { TaskCell } from '../components/TaskCell';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

const columnHelper = createColumnHelper<Task>();

export const getColumns = (): Array<ColumnDef<Task, string>> => [
  columnHelper.accessor('description', {
    id: 'description',
    cell: ({ row: { original } }) => (
      <TaskCell caseId={original.case_id}>{original.description}</TaskCell>
    ),
    header: 'Description',
  }),
  columnHelper.accessor((row) => row.associations.international.first_name, {
    id: 'talent_name',
    cell: ({ row: { original } }) => (
      <TaskCell caseId={original.case_id}>
        {`${original.associations.international.first_name}
              ${original.associations.international.last_name}`}
      </TaskCell>
    ),
    header: 'Name',
  }),
  columnHelper.accessor((row) => row.created_at, {
    id: 'created_at',
    cell: ({ row: { original } }) => (
      <TaskCell caseId={original.case_id}>
        <Badge>{formatDate(original.created_at)}</Badge>
      </TaskCell>
    ),
    header: 'Task creation date',
  }),
  columnHelper.accessor((row) => row.associations.case_type.name, {
    id: 'case_type',
    cell: ({ row: { original } }) => (
      <TaskCell caseId={original.case_id}>{original.associations.case_type.name}</TaskCell>
    ),
    header: 'Case type',
  }),
];
