import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  SelectItemProps,
  useToast,
} from '@localyze-pluto/components';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { Case } from 'modules/cases/types/Case';
import { CommunicationPriority } from 'modules/cases/types/CommunicationPriority';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

export const COMMUNICATION_PRIORITY_OPTIONS: SelectItemProps[] = [
  { value: 'normal', label: 'Normal' },
  { value: 'high', label: 'High' },
  { value: 'immediately', label: 'Immediately' },
];

type EditCommunicationPriorityFormTypes = {
  communicationPriority: CommunicationPriority;
};

const validationSchema: Yup.ObjectSchema<EditCommunicationPriorityFormTypes> = Yup.object({
  communicationPriority: Yup.string()
    .oneOf(COMMUNICATION_PRIORITY_OPTIONS.map((option) => option.value) as CommunicationPriority[])
    .required('This field is required'),
});

export const EditCommunicationPriorityModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const { communication_priority: communicationPriority } = kase;
  const toast = useToast();
  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Communication priority updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Communication priority could not be updated', 'error'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: { communicationPriority },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditCommunicationPriorityFormTypes> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { communication_priority: values.communicationPriority },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Communication priority</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form id="edit-communication-priority-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormSelect
            control={control}
            id="communicationPriority"
            items={COMMUNICATION_PRIORITY_OPTIONS}
            label="Communication priority"
            name="communicationPriority"
            required
          />
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isPendingUpdateCase}
          form="edit-communication-priority-form"
          loading={isPendingUpdateCase}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
