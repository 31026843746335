import { Box, BoxProps, Icon, IconProps } from '@localyze-pluto/components';
import React from 'react';

type StatusStepperStatus = 'complete' | 'incomplete' | 'ongoing';

export type StatusStepperStep = {
  text: string;
  icon?: IconProps['icon'];
  status?: StatusStepperStatus;
};

type StepperProps = {
  steps: StatusStepperStep[];
};

const Dot = ({
  backgroundColor,
  status,
}: {
  backgroundColor: BoxProps['backgroundColor'];
  status?: StatusStepperStatus;
}) => (
  <Box.div
    backgroundColor={backgroundColor}
    borderRadius="borderRadiusCircle"
    data-testid={`dot-${status || ''}`}
    h={12}
    w={12}
  />
);

const Bar = ({ isComplete }: { isComplete: boolean }) => (
  <Box.div
    backgroundColor={isComplete ? 'contentInfo' : 'gray400'}
    borderRadius="borderRadiusPill"
    data-testid={`progress-bar-${isComplete ? 'complete' : 'incomplete'}`}
    h={6}
    left="calc(50% + 26px)"
    position="absolute"
    w="calc(100% - 52px)"
  />
);

const DescriptionList = ({ steps }: { steps: StatusStepperStep[] }) => {
  return steps.map((step) => {
    const isOnGoing = step.status === 'ongoing';
    const isComplete = step.status === 'complete';

    return (
      <Box.li display="grid" justifyContent="center" key={step.text}>
        <Box.div
          color={isComplete || isOnGoing ? 'contentPrimary' : 'contentTertiary'}
          fontSize="fontSize10"
          fontWeight="fontWeightBold"
          lineHeight="lh4"
          maxW={190}
          textAlign="center"
        >
          {step.text}
        </Box.div>
      </Box.li>
    );
  });
};

const ProgressIcons = ({ steps }: { steps: StatusStepperStep[] }) => {
  return steps.map((step, index) => {
    const isOnGoing = step.status === 'ongoing';
    const isComplete = step.status === 'complete';

    let label = step.text;

    if (isOnGoing) {
      label += ' on going';
    }

    if (isComplete) {
      label += ' complete';
    }

    const backgroundColor = isComplete ? 'contentInfo' : 'transparent';
    const highlightColor = isComplete || isOnGoing ? 'contentInfo' : 'gray400';
    const isLastStep = index === steps.length - 1;
    const icon = step.icon || 'check';

    const nextStatus = steps[index + 1]?.status;

    return (
      <Box.li
        alignItems="center"
        display="grid"
        justifyContent="center"
        key={step.text}
        position="relative"
      >
        <Box.div display="grid" justifyContent="center" maxW={190}>
          <Box.div
            alignItems="center"
            aria-label={label}
            backgroundColor={backgroundColor}
            borderColor={highlightColor}
            borderRadius="borderRadiusCircle"
            borderStyle="borderStyleSolid"
            borderWidth="borderWidth20"
            display="grid"
            h={40}
            justifyItems="center"
            justifySelf="center"
            w={40}
          >
            {isComplete ? (
              <Icon
                color="contentInverse"
                data-testid={`status-icon-${icon}`}
                decorative
                icon={icon}
                size="sizeIcon40"
              />
            ) : (
              <Dot backgroundColor={highlightColor} status={step.status || 'incomplete'} />
            )}
          </Box.div>
        </Box.div>
        {isLastStep ? null : (
          <Bar isComplete={['ongoing', 'complete'].includes(String(nextStatus))} />
        )}
      </Box.li>
    );
  });
};

export const StatusStepper = ({ steps, ...props }: BoxProps & StepperProps): React.JSX.Element => {
  const gridTemplateColumns = `repeat(${steps.length}, 1fr)`;

  return (
    <Box.div display="block" {...props}>
      <Box.ul
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        marginBottom="d2"
        padding="d0"
      >
        <ProgressIcons steps={steps} />
      </Box.ul>
      <Box.ul
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        marginBottom="d0"
        padding="d0"
      >
        <DescriptionList steps={steps} />
      </Box.ul>
    </Box.div>
  );
};
