import axios from 'axios';

export const getInternalVariables = async (params) => {
  try {
    const { data } = await axios.get('/admin/internal_variables', {
      params,
    });
    return data.internal_variables;
  } catch (e) {
    throw new Error(
      'Something is wrong getting the internal variables. Hold on there or send us an email',
    );
  }
};
