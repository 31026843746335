import { format } from 'date-fns';

const DD_MMM_YYYY = 'd LLL u'; // 18 Aug 2018

// 2018-08-18 to 18 Aug 2018
export const formatDateDashesToFriendly = (date: string): string => {
  const [day, month, year] = date.split('-');

  const toDate = new Date(Number(day), Number(month) - 1, Number(year));

  return format(toDate, DD_MMM_YYYY).toString();
};
