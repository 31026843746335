import React from 'react';

type ErrorMessageProps = {
  hasError: boolean;
  errorMessage?: string;
};

export const ErrorMessage = ({
  hasError,
  errorMessage,
}: ErrorMessageProps): React.JSX.Element | null => {
  const className = `tw-absolute tw-text-xs tw-leading-none
  tw-font-normal tw-font-sans tw-text-red-500`;

  if (hasError) {
    return (
      <span className={className} data-testid="select-menu-error-message">
        {errorMessage}
      </span>
    );
  }
  return null;
};
