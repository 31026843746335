import { Pie } from 'react-chartjs-2';
import { Context } from 'chartjs-plugin-datalabels';
import React from 'react';
import { ChartData } from 'chart.js';

export type PieChartProps = {
  data: ChartData<'pie', unknown[], unknown>;
  items: unknown[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatter: (value: any, context: Context) => any;
  legendPosition?: 'bottom' | 'right';
  padding?: number;
  aspectRatio?: number;
};

export const PieChart = ({
  data,
  items,
  formatter,
  legendPosition = 'right',
  padding = 20,
  aspectRatio = 1,
}: PieChartProps): React.JSX.Element => {
  return (
    <Pie
      data={data}
      options={{
        layout: {
          padding,
        },
        borderColor: items.length < 2 ? 'transparent' : 'white',
        plugins: {
          legend: { position: legendPosition },
          datalabels: {
            align: 'end',
            anchor: 'end',
            formatter,
          },
        },
        aspectRatio,
      }}
    />
  );
};
