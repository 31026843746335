import { Box } from '@localyze-pluto/components';
import React from 'react';
import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon';

type ContainedLoadingStateProps = {
  fullPage?: boolean;
};

/**
 * This component is used to display a loading indicator that fills
 * and is centered within the parent container.
 */
export const ContainedLoadingState = ({
  fullPage,
}: ContainedLoadingStateProps): React.JSX.Element => (
  <Box.div
    alignItems="center"
    data-testid="loading-state"
    display="flex"
    flexDirection="column"
    gap="d3"
    h="100%"
    justifyContent="center"
    {...(fullPage && { h: '85vh' })}
  >
    <LoadingIcon size="sizeIcon60" />
    <Box.span>Loading...</Box.span>
  </Box.div>
);
