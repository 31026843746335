import { PipelineWithSteps } from 'deprecated/api/pipelines/types/PipelineWithSteps';
import { isEqual } from 'lodash';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';

type FilterByComponentTypeAndCountryProps = {
  componentType: string;
  internalVariables: Variable[];
  pipeline: PipelineWithSteps;
};

const isSameType = (variableType: string, componentType: string) => variableType === componentType;

const getVariablesByDestinationCountryAndType = (
  internalVariables: Variable[],
  componentType: string,
  pipelineDestinationCountryId?: number,
): Variable[] =>
  internalVariables.filter((variable) => {
    const { destination_country, variable_type } = variable;
    const sameDestinationCountry = destination_country?.id === pipelineDestinationCountryId;

    return isSameType(variable_type, componentType) && !!sameDestinationCountry;
  });

export const filterByComponentTypeAndCountry = ({
  componentType,
  internalVariables,
  pipeline,
}: FilterByComponentTypeAndCountryProps): Variable[] => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const pipelineDestinationCountryId = pipeline?.destination_country?.id;

  const variablesByDestinationCountryAndType = getVariablesByDestinationCountryAndType(
    internalVariables,
    componentType,
    pipelineDestinationCountryId,
  );

  if (!pipelineDestinationCountryId) return variablesByDestinationCountryAndType;

  const filteredDefaultVariables = internalVariables.filter(
    (variable) =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      variable.destination_country === null &&
      !variablesByDestinationCountryAndType.some((item) => isEqual(variable.code, item.code)) &&
      isSameType(variable.variable_type, componentType),
  );

  return [...variablesByDestinationCountryAndType, ...filteredDefaultVariables];
};
