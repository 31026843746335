import React from 'react';
import { Box } from '@localyze-pluto/components';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';

type ServiceDetailsItemProps = {
  isLabel?: boolean;
  children: React.ReactNode;
  htmlFor?: string;
  isLast?: boolean;
  title: string;
};

export const ServiceDetailsItem: React.FC<ServiceDetailsItemProps> = ({
  isLabel,
  children,
  htmlFor,
  isLast,
  title,
}) => (
  <>
    <Box.div display="grid" gridTemplateColumns="1fr 3fr">
      <Box.div
        as={isLabel ? 'label' : 'p'}
        color="contentSecondary"
        fontSize="fontSize20"
        htmlFor={htmlFor}
        margin="m4"
      >
        {title}
      </Box.div>
      {children}
    </Box.div>
    {!isLast && <SectionDivider />}
  </>
);
