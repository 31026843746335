import styled, { css } from 'styled-components';

const FONT_WEIGHT = 600;

const fontWeight = ({ bold, semi }: { bold?: boolean; semi?: boolean }) => {
  if (semi) {
    return '600';
  }
  if (bold) {
    return 'bold';
  }
  return 'normal';
};

const fontColor = ({ color }: { color?: string }) => {
  if (color === 'second') {
    return 'var(--color-grey)';
  }
  if (color === 'third') {
    return 'var(--color-green)';
  }
  if (color === 'fourth') {
    return 'var(--secondary-color-full)';
  }
  return 'var(--colorFont-localyze-black)';
};

const createCSS = css<{ bold?: boolean; color?: string; semi?: boolean }>`
  padding: 0px;
  margin: 0px;
  font-style: normal;
  font-weight: ${(props) => fontWeight(props)};
  color: ${(props) => fontColor(props)};
`;

export const Heading = styled.p`
  ${createCSS}
  font-weight: bold;
  font-size: 25px;
  line-height: 42px;
`;

export const SubHeading = styled.p<{ bold?: boolean; semi?: boolean }>`
  ${createCSS}
  font-weight: ${(props) => (props.semi ? FONT_WEIGHT : 'bold')};
  font-size: 18px;
  line-height: 25px;
`;

export const P = styled.p<{ bold?: boolean; color?: string }>`
  ${createCSS}
  font-weight: normal;
  color: ${(props) => fontColor(props)};
  font-size: 18px;
  line-height: 30px;
`;

export const P1 = styled.p<{ bold?: boolean; semi?: boolean }>`
  ${createCSS}
  font-size: 14px;
  line-height: 28px;
`;

export const P2 = styled.p<{ italic?: boolean; bold?: boolean }>`
  ${createCSS}
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  font-size: 14px;
  line-height: 23px;
`;

export const P3 = styled.p<{ italic?: boolean }>`
  ${createCSS}
  font-size: 12px;
  line-height: 16px;
`;
