import { ColumnDef } from '@tanstack/react-table';

import React from 'react';
import { Task } from 'modules/tasks/types/Task';
import { DataTable } from 'components/DataTable/DataTable';
import { UseTableSortingProps } from 'components/DataTable/useTableSorting';
import { Box } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';

export const TasksTable = ({
  columns,
  tasks,
  sorting,
  onSortingChange,
}: UseTableSortingProps & {
  columns: ColumnDef<Task, string>[];
  tasks: Task[];
}): React.JSX.Element => {
  const { isHrViewer } = useCurrentUser();

  return (
    <Box.div flex="1 1 auto" minWidth="100%" w="100%">
      <DataTable<Task, string>
        columns={columns}
        data={tasks}
        emptyStateText="Woo-hoo! You're all caught up! Check back later."
        isRowClickable={!isHrViewer}
        onSortingChange={onSortingChange}
        sorting={sorting}
        testId={'tasks-table'}
      />
    </Box.div>
  );
};
