import React, { useCallback, useEffect } from 'react';
import { Box, Heading, SelectItemProps, useToast } from '@localyze-pluto/components';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { CaseState } from 'modules/cases/types/CaseState';
import { FieldValues, useForm } from 'react-hook-form';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { CaseSteps } from 'modules/caseDetails/components/CaseManagement/CaseSteps/CaseSteps';
import { useCurrentUser } from 'config/CurrentUserContext';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { Case } from 'modules/cases/types/Case';
import { EditMenu } from 'modules/caseDetails/components/CaseManagement/EditMenu';

export const getStateOptions = (kase: Case): SelectItemProps[] => {
  return [kase.current_state, ...kase.allowed_transitions].map((state) => ({
    label: state.toString(),
    value: state.toString(),
  }));
};

export const CaseManagement = ({ caseId }: { caseId: number }): React.JSX.Element => {
  const toast = useToast();

  const { isAdmin } = useCurrentUser();

  const { data: kase, isSuccess: isSuccessCase } = useCase({ caseId });
  const { mutate: updateCase } = useUpdateCase({
    onSuccess: () => toast('Case state updated successfully', 'success'),
    onError: () => toast('Case state could not be updated', 'error'),
  });

  const { handleSubmit, watch } = useForm({
    defaultValues: { state: kase?.current_state },
    mode: 'onTouched',
  });

  const onSubmit = useCallback(
    (values: FieldValues) => {
      if (kase?.current_state === values.state) return;

      updateCase({ caseId, payload: { state: values.state as CaseState } });
    },
    [kase, updateCase, caseId],
  );

  // this allows submitting the form onChange of the select box
  useEffect(() => {
    const subscription = watch((value) => {
      if (kase && value.state !== kase.current_state) {
        handleSubmit(onSubmit)();
      }
    });

    // Unsubscribe when component unmounts
    return () => subscription.unsubscribe();
  }, [handleSubmit, kase, onSubmit, watch]);

  if (!isSuccessCase) return <ContainedLoadingState />;

  return (
    <SectionCard>
      <SectionCard.Header>
        <Box.div
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          zIndex="zIndex10"
        >
          <Heading as="h3" color="colorTextStrongest" marginBottom="m0" size="heading60">
            Case management
          </Heading>
          {isAdmin && <EditMenu kase={kase} />}
        </Box.div>
      </SectionCard.Header>
      <SectionCard.Content maxH={600} padding="d4">
        <CaseSteps caseId={caseId} />
      </SectionCard.Content>
    </SectionCard>
  );
};
