import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';

const SORT_BY_OPTIONS: FilterOption<string>[] = [
  {
    filterAttribute: 'sort_by',
    label: 'Alphabetically by name',
    userTypes: ['admin', 'hr'],
    value: 'name',
  },
  {
    filterAttribute: 'sort_by',
    label: 'Starting date',
    userTypes: ['admin', 'hr'],
    value: 'start_date',
  },
  {
    filterAttribute: 'sort_by',
    label: 'Last updated',
    userTypes: ['admin'],
    value: 'last_updated',
  },
];

export const SORT_BY_FILTER: Filter<string> = {
  attribute: 'sort_by',
  default: 'start_date',
  label: 'Sort by',
  options: SORT_BY_OPTIONS,
  type: 'singleselect',
  userTypes: ['admin', 'hr'],
};
