import { ContentEditableEvent, DefaultEditor } from 'react-simple-wysiwyg';
import React, { useEffect, useState } from 'react';

import { fetchZAVTemplate } from 'deprecated/api/templates/zav';
import { pdfFromHTML } from 'deprecated/api/templates/index';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
} from '@localyze-pluto/components';
import { DialogStore } from '@ariakit/react';

export const ZavTemplateModal = ({
  modalState,
  caseId,
}: {
  caseId: number;
  modalState: DialogStore;
}): React.JSX.Element => {
  const isModalOpen = modalState.useState('open');
  const [isLoading, setIsLoading] = useState(false);
  const [html, setHtml] = useState({ data: '', done: false });

  useEffect(() => {
    const callbackSuccess = (data: string) => setHtml({ data, done: true });

    fetchZAVTemplate(caseId, callbackSuccess);
  }, [caseId]);

  const onChange = (event: ContentEditableEvent) => setHtml({ ...html, data: event.target.value });

  const generateHtml = async () => {
    setIsLoading(true);
    const response = await pdfFromHTML(html.data);
    setIsLoading(false);

    const file = new Blob([response.data], { type: 'application/pdf' });
    window.open(URL.createObjectURL(file));
  };

  return (
    <>
      {isModalOpen && (
        <Modal store={modalState}>
          <ModalHeader>
            <ModalHeading>Application for labour market approval (GER)</ModalHeading>
          </ModalHeader>
          <ModalBody>
            <DefaultEditor onChange={onChange} value={html.data} />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={generateHtml}
              variant="primary"
            >
              Generate PDF
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
