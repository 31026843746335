import axios from 'axios';
import { Message } from 'modules/talent/types/Message';

export type CreateMessageProps = {
  body: string;
  caseId: number;
  internal?: boolean;
};

export const createMessage = async ({
  body,
  internal,
  caseId,
}: CreateMessageProps): Promise<Message> => {
  const messagePayload = {
    body,
    case_id: caseId,
    ...(internal && { internal_message: internal }),
  };

  const { data } = await axios.post<Message>('/messages', messagePayload);

  return data;
};
