import { StackOneCompanyIntegration } from 'modules/integrations/types/HRISCompanyIntegration';
import { AxiosError } from 'axios';
import { UseQueryResult } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
// prettier-ignore
import {
  useCompanyHRISIntegrations
} from 'modules/integrations/hooks/useCompanyHRISIntegrations/useCompanyHRISIntegrations';

/**
 * Gets the name of the company's active HRIS integration (if one exists)
 * @param props.companyId - company to fetch HRIS integrations for
 * @returns the name of the active HRIS integration (or undefined)
 */
export const useActiveCompanyHRISIntegration = (
  companyId?: number,
): UseQueryResult<StackOneCompanyIntegration | undefined, AxiosError> => {
  return useCompanyHRISIntegrations({
    params: { companyId },
    select: (integrations: StackOneCompanyIntegration[]) => {
      if (!isEmpty(integrations)) {
        return integrations[0];
      }
      return undefined;
    },
  });
};
