import React from 'react';
import { SubscriptionPlanWithFeatures } from '../../types/SubscriptionPlanWithFeatures';
import clsx from 'clsx';
import { Currency } from 'types/currency';
import { CURRENCY_SYMBOL } from 'deprecated/pages/viewsNew/ChewpacaLayout/Subscription/components/SubscriptionCard/constants/currencySymbol';
import { Box, Button, Icon } from '@localyze-pluto/components';

export type SubscriptionCardProps = {
  recommended: boolean;
  current: boolean;
  subscription: SubscriptionPlanWithFeatures;
  onSelect: (subscription: SubscriptionPlanWithFeatures) => void;
  currency?: Currency;
};

export const SubscriptionCard = ({
  subscription,
  current,
  recommended,
  onSelect,
  currency,
}: SubscriptionCardProps): React.JSX.Element => (
  <div className="tw-relative tw-align-center">
    {recommended && (
      <div className="tw-absolute tw--top-3 tw-w-full tw-text-center">
        <span
          className={`tw-bg-blue-500 tw-text-white tw-uppercase tw-text-sm
        tw-rounded-full tw-px-4 tw-py-1`}
        >
          Recommended
        </span>
      </div>
    )}

    <div
      className={clsx('tw-rounded-lg tw-bg-white tw-w-64 tw-shadow-lg tw-pt-8', {
        'tw-border-solid tw-border-gray-300': current,
        'tw-border-solid tw-border-blue-500': recommended,
      })}
    >
      <h4 className="tw-capitalize tw-text-lg tw-text-center">{subscription.name}</h4>
      <div className="tw-text-center tw-mb-9">
        <span className="tw-text-3xl tw-align-middle">
          {currency ? CURRENCY_SYMBOL[currency] : ''}
        </span>
        <span className="tw-text-5xl tw-font-black tw-pl-2 tw-pr-3 tw-align-bottom">
          {subscription.price}
        </span>
        <span className="tw-text-xl tw-text-gray-500 tw-align-text-bottom">/mo</span>
      </div>
      <Box.div
        alignItems="center"
        className="tw-bg-gray-50 tw-py-6 tw-px-9 tw-border-gray-100 tw-rounded-b-lg
       tw-border-t tw-border-solid"
        display="flex"
        flexDirection="column"
      >
        <ul className="tw-list-none tw-m-0 tw-p-0">
          {subscription.features.map((feature, index) => (
            <li
              className="tw-flex tw-items-center tw-text-xs tw-text-gray-500 tw-my-1"
              key={`feature-${subscription.name}-${index}`}
            >
              <Icon className="tw-mr-3 tw-text-green-500 tw-w-6 tw-h-6" decorative icon="check" />
              {feature}
            </li>
          ))}
        </ul>

        <Button
          className="tw-mt-14"
          disabled={current}
          onClick={() => onSelect(subscription)}
          variant={recommended ? 'primary' : 'secondary'}
        >
          {current ? 'Current plan' : 'Select plan'}
        </Button>
      </Box.div>
    </div>
  </div>
);
