import { Box } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';
import { useCaseInformations } from 'modules/cases/api/useCaseInformation/useCaseInformations';
import { CaseInformation } from 'modules/cases/types/CaseInformation';
import React from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

const formattedValue = (caseInformation: CaseInformation): string => {
  if (caseInformation.reference_type === 'datetime') {
    return formatDate(caseInformation.value);
  } else if (caseInformation.value === 't') {
    return 'yes';
  } else if (caseInformation.value === 'f') {
    return 'no';
  }
  return caseInformation.value;
};

export const CaseData = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const { data: caseInfo, isSuccess } = useCaseInformations({ caseId: Number(id) });

  if (!isSuccess) return <ContainedLoadingState />;

  const items = caseInfo.map((caseInformation: CaseInformation) => ({
    key: caseInformation.description,
    value: <Box.b>{formattedValue(caseInformation)}</Box.b>,
  }));

  return <KeyValueList borderBottomStyle="borderStyleSolid" items={items} py="d6" />;
};
