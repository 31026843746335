import {
  Button,
  Dropzone,
  FileTypes,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
} from '@localyze-pluto/components';
import { MAX_FILE_SIZE, PDF_EXTENSION } from 'deprecated/components/Chewpaca/FileUpload/constants';
import React, { useEffect, useState } from 'react';

import { DisclosureStore } from '@ariakit/react';

type Props = {
  error?: string;
  heading: string;
  state: DisclosureStore;
  onClose: () => void;
  onDrop: (files: File[]) => void;
  progress?: number;
  onCancel?: () => void;
  isLoading?: boolean;
  maxFileSize?: number;
  fileTypes?: FileTypes;
};

const generateKey = () => Math.random().toString(16).substring(2);

export const FileUploadModal = ({
  error,
  heading,
  onClose,
  progress,
  onDrop,
  onCancel,
  state,
  isLoading,
  maxFileSize = MAX_FILE_SIZE,
  fileTypes = PDF_EXTENSION,
}: Props): React.JSX.Element => {
  const [key, setKey] = useState(generateKey());
  const isModalOpen = state.useState('open');

  useEffect(() => {
    if (!isModalOpen || error) {
      setKey(generateKey());
    }
  }, [isModalOpen, error]);

  return (
    <Modal store={state}>
      <ModalHeader>
        <ModalHeading>{heading}</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Dropzone
          error={error}
          fileTypes={fileTypes}
          key={key}
          maxFileSize={maxFileSize}
          onCancel={() => onCancel?.()}
          onDrop={(files) => onDrop(files)}
          progress={progress}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            onCancel?.();
            onClose();
            state.hide();
          }}
          variant="primary"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
