import type { Configuration } from 'rollbar';
import Rollbar from 'rollbar';
import { shouldIgnoreError } from 'utils/log/shouldIgnoreError';

export const rollbarConfig: Configuration = {
  enabled: ['production', 'staging'].includes(process.env.NODE_ENV),
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: [/.*request failed with status code 401.*/gi],
  checkIgnore: (isUncaught, _, payload) => shouldIgnoreError(isUncaught, _, payload),
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
};

export const rollbarInstance = new Rollbar(rollbarConfig);
