import axios from 'axios';
import { Partner } from 'modules/additionalServices/types/Partner';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const PARTNERS_QUERY_KEY = 'partners';

export const getPartners = async (): Promise<Partner[]> => {
  const { data } = await axios.get<Partner[]>('/partners', {
    headers: { Accept: 'application/json' },
  });

  return data;
};

export const usePartners = <Result = Partner[]>(): UseQueryResult<Result> => {
  return useQuery({
    queryKey: [PARTNERS_QUERY_KEY],
    queryFn: getPartners,
  });
};
