import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { Case } from '../../types/Case';

const getCase = async (id: number): Promise<Case> => {
  const url = `/cases/${id}`;
  const { data } = await axios.get<Case>(url, { headers: { Accept: 'application/json' } });

  return data;
};

type UseCaseProps = {
  caseId: number;
  enabled?: boolean;
};

export const getCaseQueryKey = (caseId: number): Array<number | string> => ['case', caseId];

export const useCase = ({ caseId, enabled }: UseCaseProps): UseQueryResult<Case> => {
  return useQuery({
    queryKey: getCaseQueryKey(caseId),
    queryFn: () => getCase(Number(caseId)),
    enabled: enabled ?? !Number.isNaN(Number(caseId)),
    throwOnError: false,
    refetchOnMount: false,
  });
};
