import { Box, Callout, Input, Label, Select, SelectItemProps } from '@localyze-pluto/components';
import { Section, SectionProps } from '../Section/Section';

import { CaseEvaluationSummarySection } from 'modules/pipelines/pages/caseEvaluationSummary/types/CaseEvaluationSummarySection';
import { EditorWithMacro } from 'modules/pipelines/pages/caseEvaluationSummary/components/EditorWithMacro/EditorWithMacro';
import React from 'react';
import { convertMacrosToItems } from 'modules/pipelines/pages/caseEvaluationSummary/utils/convertMacrosToItems/convertMacrosToItems';
import { humanizeTimeInDays } from './utils/humanizeTimeInDays';
import { isNumber } from 'lodash';
import range from 'lodash/range';
import { useEstimatedCaseDuration } from 'modules/cases/api/useEstimatedCaseDuration/useEstimatedCaseDuration';
import { useFormContext } from 'react-hook-form';
import { useMacros } from 'modules/pipelines/pages/caseEvaluationSummary/content/useMacros/useMacros';
import { useParams } from 'react-router-dom';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { useCaseInformations } from 'modules/cases/api/useCaseInformation/useCaseInformations';
import { useCase } from 'modules/cases/api/useCase/useCase';

const MIN_WEEKS = 1;
const MAX_WEEKS = 13;

const canMeetStartDateOptions: SelectItemProps[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const weekOptions = [
  ...range(MIN_WEEKS, MAX_WEEKS).map((n) => ({
    value: String(n),
    label: `${n} week${n > MIN_WEEKS ? 's' : ''}`,
  })),
  { label: '4 months', value: '16' },
  { label: '5 months', value: '20' },
  { label: '6 months', value: '24' },
];

export const ExpectedTimeline = ({
  disabled,
  title,
}: Pick<SectionProps, 'disabled' | 'title'>): React.JSX.Element => {
  const { caseId } = useParams<{ caseId: string }>();
  const {
    queryParams: { pipelineId },
  } = useQueryParams();
  const { data: kase } = useCase({ caseId: Number(caseId) });
  const { data: talentAppointments, isSuccess } = useCaseInformations({
    caseId: Number(kase?.id),
    referenceType: 'datetime',
  });
  const { data, isSuccess: isEstimatedCaseDurationSuccess } = useEstimatedCaseDuration({
    pipelineId: Number(pipelineId),
    originCountryId: kase?.origin_country_id,
  });
  const estimatedCaseDurationInDays = data?.estimated_case_duration_in_days;

  const { register, setValue, unregister, watch } = useFormContext();
  const { data: macros } = useMacros({ section: CaseEvaluationSummarySection.ExpectedTimeline });

  const watchCanMeetStartDate = !!watch('start_date_can_be_met');

  const handleSelectMeetStartDate = (option: string[] | string) => {
    if (!!option) {
      unregister('start_date_postponed_weeks', { keepDirty: false });
    }
    setValue('start_date_can_be_met', option === 'true');
  };

  const startDateAppointment = talentAppointments?.find(
    (appointment) =>
      appointment.internal_reference_code === 'cd_date_appointment_first_day_work_cd',
  );

  return (
    <Section
      description="Please give your talent some more context on their expected timelines."
      title={title}
    >
      <Box.div display="flex" flexDirection="column" gap="d4">
        {isEstimatedCaseDurationSuccess && (
          <Callout>
            {isNumber(estimatedCaseDurationInDays)
              ? `Based on data we collected from previous cases the estimated case duration \
            will be: ${humanizeTimeInDays(estimatedCaseDurationInDays)}`
              : "Unfortunately, we don't have enough data to show an average duration for this \
            talent's case yet."}
          </Callout>
        )}
        {isSuccess ? (
          <Box.div w="50%">
            <Label htmlFor="startDate">Start date</Label>
            <Input
              disabled
              id="startDate"
              name="startDate"
              type="date"
              value={formatDate(startDateAppointment?.value, 'yyyy-MM-dd', '')}
            />
          </Box.div>
        ) : (
          <ContainedLoadingState />
        )}
        <Box.div w="50%">
          <Label htmlFor="start_date_can_be_met">Can the original start date be met?</Label>
          <Select
            disabled={disabled}
            id="start_date_can_be_met"
            items={canMeetStartDateOptions}
            setValue={handleSelectMeetStartDate}
            value={String(watchCanMeetStartDate)}
          />
        </Box.div>
        {!watchCanMeetStartDate && (
          <Box.div w="50%">
            <Label htmlFor="start_date_postponed_weeks" required>
              How long should the start date be postponed?
            </Label>
            <Select
              disabled={disabled}
              id="start_date_postponed_weeks"
              items={weekOptions}
              setValue={(option) => setValue('start_date_postponed_weeks', option)}
              {...register('start_date_postponed_weeks')}
            />
          </Box.div>
        )}

        <EditorWithMacro
          disabled={disabled}
          items={convertMacrosToItems(macros)}
          label="Estimated waiting time for authority appointments"
          name="waiting_time_for_authority_appointment"
          rules={{ required: 'This field is required.' }}
        />
      </Box.div>
    </Section>
  );
};
