import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { StatusUpdate } from 'modules/statusUpdates/types/StatusUpdate';
import { AxiosError } from 'axios';
import { getStatusUpdates } from './getStatusUpdates';
import { StatusUpdateSubjectType } from 'modules/statusUpdates/types/StatusUpdateSubjectType';

export const STATUS_UPDATES_QUERY_KEY = 'status-updates';

export const getStatusUpdatesQueryKey = ({
  subjectType,
  subjectId,
}: {
  subjectType?: StatusUpdateSubjectType;
  subjectId?: number;
}): string[] =>
  !subjectType || !subjectId
    ? [STATUS_UPDATES_QUERY_KEY]
    : [STATUS_UPDATES_QUERY_KEY, String(subjectType), String(subjectId)];

export const useStatusUpdates = ({
  subjectType,
  subjectId,
  enabled = true,
}: {
  subjectType?: StatusUpdateSubjectType;
  subjectId?: number;
  onError?: (error: AxiosError) => void;
  enabled?: boolean;
} = {}): UseQueryResult<StatusUpdate[]> => {
  return useQuery({
    queryKey: getStatusUpdatesQueryKey({ subjectType, subjectId }),
    queryFn: () => getStatusUpdates({ subjectType, subjectId }),
    throwOnError: false,
    enabled,
  });
};
