import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { CompanyUser } from 'deprecated/api/companies/company_user';
import { UseCompanyUsersProps } from './useCompanyUsers.types';

export const COMPANY_USERS_QUERY_KEY = 'company-users';

/**
 * Makes a get request to fetch users by company
 * @param params.companyId
 * @returns users
 */
const getCompanyUsers = async (companyId?: number): Promise<CompanyUser[]> => {
  if (companyId === undefined) {
    throw new Error("Company ID is required to fetch company's users");
  }

  const { data } = await axios.get<CompanyUser[]>(`/companies/${companyId}/users`, {
    headers: { Accept: 'application/json' },
  });

  return data;
};

/**
 * Gets list of users by company
 * @param params.companyId
 * @returns users
 */
export const useCompanyUsers = <Result = CompanyUser[]>({
  params,
  enabled,
  ...props
}: UseCompanyUsersProps<Result>): UseQueryResult<Result, AxiosError> =>
  useQuery({
    queryKey: [COMPANY_USERS_QUERY_KEY, params.companyId],
    queryFn: () => getCompanyUsers(params.companyId),
    enabled: params.companyId !== undefined && enabled,
    ...props,
  });
