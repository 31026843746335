import React, { Fragment, ReactNode } from 'react';

import { SelectMenuOption } from '../SelectMenu';
import { Transition } from '@headlessui/react';

export type WithSelectMenuOptionsProps = {
  className?: string;
  children: ReactNode;
};

export type SelectMenuOptionsProps = {
  options: ReadonlyArray<SelectMenuOption>;
  children: React.JSX.Element | ((option: SelectMenuOption) => React.JSX.Element);
  show: boolean;
};

const optionsStyle = `tw-absolute tw-w-full tw-mt-1 tw-min-w-60 tw-mb-0 tw-border-none
  tw-overflow-auto tw-bg-white tw-rounded-lg tw-shadow-xl tw-max-h-60 tw-list-none tw-p-0 tw-z-20`;

export const withSelectMenuOptions = (
  WrappedComponent: React.ComponentType<WithSelectMenuOptionsProps>,
): React.ComponentType<SelectMenuOptionsProps> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithOptions = ({
    options,
    children,
    show,
  }: SelectMenuOptionsProps): React.JSX.Element => (
    <Transition
      as={Fragment}
      enter="tw-transition tw-duration-100 tw-ease-out"
      enterFrom="tw-transform tw-scale-95 tw-opacity-0"
      enterTo="tw-transform tw-scale-100 tw-opacity-100"
      leave="tw-transition tw-duration-75 tw-ease-out"
      leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
      leaveTo="tw-transform tw-scale-95 tw-opacity-0"
      show={show}
    >
      <WrappedComponent className={optionsStyle} data-testid="select-menu-options">
        {typeof children === 'function' ? options.map((option) => children(option)) : children}
      </WrappedComponent>
    </Transition>
  );

  ComponentWithOptions.displayName = `withSelectMenuOptions(${displayName})`;

  return ComponentWithOptions;
};
