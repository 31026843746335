import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';

export const START_DATE_AT_RISK_OPTIONS: FilterOption<string>[] = [
  {
    filterAttribute: 'start_date_at_risk',
    label: 'All',
    userTypes: ['admin', 'hr'],
    value: '',
  },
  {
    filterAttribute: 'start_date_at_risk',
    label: 'Yes',
    userTypes: ['admin', 'hr'],
    value: 'true',
  },
  {
    filterAttribute: 'start_date_at_risk',
    label: 'No',
    userTypes: ['admin', 'hr'],
    value: 'false',
  },
];

export const START_DATE_AT_RISK_FILTER: Filter<string> = {
  attribute: 'start_date_at_risk',
  label: 'Start date at risk',
  tooltipText: 'Risk that the start date cannot be met due to late visa appointment date',
  options: START_DATE_AT_RISK_OPTIONS,
  type: 'singleselect',
  userTypes: ['admin', 'hr'],
};
