import { DialogStore } from '@ariakit/react';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  useToast,
} from '@localyze-pluto/components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDeleteCasePipeline } from 'modules/pipelines/hooks/useDeleteCasePipeline';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCasePipelines } from 'modules/pipelines/hooks/useCasePipelines';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

type DeleteCasePipelineFormValues = {
  casePipelineId: string;
};

const validationSchema: Yup.ObjectSchema<DeleteCasePipelineFormValues> = Yup.object({
  casePipelineId: Yup.string().required('This field is required'),
});

export const DeleteCasePipelineModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const toast = useToast();
  const { data: casePipelines, isSuccess: isSuccessCasePipelines } = useCasePipelines(kase.id);
  const casePipelineOptions = (casePipelines || []).map((casePipeline) => ({
    label: casePipeline.pipeline.title,
    value: String(casePipeline.id),
  }));

  const { mutate: deleteCasePipeline, isPending: isDeletingCasePipeline } = useDeleteCasePipeline({
    caseId: kase.id,
    onSuccess: () => {
      toast('Case pipeline deleted successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Case pipeline could not be deleted', 'error'),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: { casePipelineId: '' },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<DeleteCasePipelineFormValues> = async (values) => {
    deleteCasePipeline({ casePipelineId: Number(values.casePipelineId) });
  };

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Delete case pipeline</ModalHeading>
      </ModalHeader>
      {!isSuccessCasePipelines ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Paragraph>
              Please select a pipeline to delete. Remember, this process cannot be undone.
            </Paragraph>
            <Box.form id="delete-case-pipeline-form" onSubmit={handleSubmit(onSubmit)}>
              <ControlledFormSelect
                control={control}
                disabled={isDeletingCasePipeline}
                id="casePipelineId"
                items={casePipelineOptions}
                label="Pipeline"
                name="casePipelineId"
                placeholder="Select pipeline"
                required
              />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={isDeletingCasePipeline}
              loading={isDeletingCasePipeline}
              onClick={modal.hide}
              type="button"
              variant="secondary"
            >
              Close
            </Button>
            <Button
              disabled={isDeletingCasePipeline}
              form="delete-case-pipeline-form"
              loading={isDeletingCasePipeline}
              type="submit"
              variant="destructive"
            >
              Delete pipeline
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
