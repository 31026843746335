import React, { ReactNode } from 'react';
import {
  Anchor,
  Badge,
  Box,
  BoxProps,
  Button,
  Heading,
  Icon,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { RisksAndWarningsItem } from 'modules/glomoRequests/types/RisksAndWarnings';
import { groupBy } from 'lodash';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';

const SubHeader = ({
  text,
  tooltipText,
  ...props
}: BoxProps & { text: string; tooltipText: string }) => {
  const tooltip = useTooltipStore({
    showTimeout: 0,
    placement: 'top',
  });

  return (
    <Box.div
      alignItems="center"
      display="flex"
      marginBottom="d0"
      paddingBottom="d4"
      paddingTop="d6"
      px="d6"
      {...props}
    >
      <Heading as="h5" color="contentPrimary" marginBottom="d0" marginRight="d2" size="title-group">
        {text}
      </Heading>

      <TooltipAnchor render={<Box.div />} store={tooltip}>
        <Icon
          color="contentSecondary"
          decorative={false}
          display="flex"
          h={20}
          icon="info"
          size="sizeIcon30"
          title="Risk information"
        />
      </TooltipAnchor>
      <Tooltip store={tooltip}>{tooltipText}</Tooltip>
    </Box.div>
  );
};

const RiskTypeBadge = ({ text }: { text: 'Risk' | 'Warning' }) => {
  return (
    <Box.div>
      <Badge
        color="gray"
        icon={text === 'Warning' ? 'triangle-alert' : undefined}
        style={{ fontWeight: 500 }}
      >
        {text}
      </Badge>
    </Box.div>
  );
};

const TextItem = ({ text }: { text: string }) => (
  <Box.span color="contentPrimary" fontSize="fontSize30" lineHeight="lh6">
    {text}
  </Box.span>
);

const ListItem = ({ children }: { children: ReactNode }) => (
  <Box.li
    borderTopColor="colorBorderWeakest"
    borderTopStyle="borderStyleSolid"
    borderTopWidth="borderWidth10"
    display="grid"
    gridTemplateColumns="120px auto"
    px="d6"
    py="d4"
  >
    {children}
  </Box.li>
);

export const HighlightedRisksAndWarnings = ({
  risksAndWarnings,
  reportUrl,
  userType,
  glomoRequest,
  ...props
}: BoxProps & {
  risksAndWarnings: RisksAndWarningsItem[];
  reportUrl: string;
  userType?: 'admin' | 'hr';
  glomoRequest?: GlomoRequest;
}): React.JSX.Element => {
  const grouped = groupBy(risksAndWarnings, 'risk_type') as {
    warning?: RisksAndWarningsItem[];
    risk?: RisksAndWarningsItem[];
  };

  return (
    <SectionCard {...props}>
      <SectionCard.Header padding={'d4 d6' as BoxProps['padding']}>
        <Box.div
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding="d0"
        >
          <Heading as="h3" color="contentPrimary" marginBottom="d0" size="title-subsection">
            Highlighted risks and warnings
          </Heading>

          <Button
            as={Anchor}
            href={reportUrl}
            onClick={() => {
              trackEvent('glomo request: click view full report', {
                user_type: userType,
                status: glomoRequest?.status,
                company_id: glomoRequest?.company_id,
              });
            }}
            target="_blank"
            trailingIcon="square-arrow-out-up-right"
            variant="ghost"
          >
            View full report
          </Button>
        </Box.div>
      </SectionCard.Header>
      <SectionCard.Content padding={'d6 d0 d0' as BoxProps['padding']}>
        <Box.div w="100%">
          <SubHeader
            paddingTop="d0"
            text="Risks"
            // eslint-disable-next-line max-len
            tooltipText="A situation that needs to be addressed to minimize potential issues. Actions to mitigate this risk will be provided in the next step."
          />
          <Box.ul listStyleType="none" margin="d0" padding="d0" textAlign="left">
            {grouped.risk?.map((item) => (
              <ListItem key={item.description}>
                <RiskTypeBadge text="Risk" />
                {item.description}
              </ListItem>
            ))}
          </Box.ul>

          <SubHeader
            borderTopColor="colorBorderWeakest"
            borderTopStyle="borderStyleSolid"
            borderTopWidth="borderWidth10"
            text="Warnings"
            // eslint-disable-next-line max-len
            tooltipText="A situation that should be monitored internally to decide if an action is needed. "
          />
          <Box.ul listStyleType="none" margin="d0" padding="d0" textAlign="left" w="100%">
            {grouped.warning?.map((item) => (
              <ListItem key={item.description}>
                <RiskTypeBadge text="Warning" />
                <TextItem text={item.description} />
              </ListItem>
            ))}
          </Box.ul>
        </Box.div>
      </SectionCard.Content>
    </SectionCard>
  );
};
