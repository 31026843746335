import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { populateFilters } from 'deprecated/libs/filters';
import type { FilterArguments } from 'deprecated/libs/filters/types/FilterArguments';
import type { Filter } from 'modules/filters/types/Filter';
import { AxiosError } from 'axios';

type FilterResult = Filter<string>[];

export const useFiltersOptions = ({
  queryKey,
  filterParams,
  enabled = true,
}: Pick<UseQueryOptions<FilterResult, AxiosError, FilterResult>, 'enabled' | 'select'> & {
  queryKey: string;
  filterParams: FilterArguments;
}): UseQueryResult<FilterResult, AxiosError> => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [queryKey],
    queryFn: () => populateFilters(filterParams),
    enabled,
  });
};
