import React, { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeading,
  DrawerStore,
  Label,
  Select,
} from '@localyze-pluto/components';
import { Filter } from 'modules/filters/types/Filter';

export type FiltersValues = { [key: string]: string[] | string };

export const FiltersDrawer = ({
  store,
  availableFilters,
  initialFilterValues,
  onApply,
}: {
  store: DrawerStore;
  availableFilters: Filter<string>[];
  initialFilterValues: FiltersValues;
  onApply: (filtersData: FiltersValues) => void;
}): React.JSX.Element => {
  const [filtersData, setFiltersData] = useState(initialFilterValues);
  const defaultFilters = availableFilters
    .filter((filter) => filter.default)
    .reduce((acc, filter) => ({ ...acc, [filter.attribute]: filter.default }), {});

  return (
    <Drawer store={store}>
      <DrawerHeader>
        <DrawerHeading>Filter</DrawerHeading>
      </DrawerHeader>

      <DrawerBody padding="p6">
        <Box.div display="grid" gap="d4" h="100%">
          <Box.div display="grid" gap="d4" gridAutoRows="min-content">
            {availableFilters.map((filter) => (
              <Box.div key={filter.attribute} w={352}>
                <Label htmlFor={filter.attribute}>{filter.label}</Label>
                <Select
                  id={filter.attribute}
                  items={filter.options}
                  name={filter.attribute}
                  placeholder="Select filter"
                  setValue={(value) => {
                    setFiltersData({
                      ...filtersData,
                      [`${filter.attribute}`]:
                        filter.type === 'multiselect'
                          ? value.includes('all')
                            ? []
                            : value
                          : value === 'all'
                            ? ''
                            : value,
                    });
                  }}
                  value={filtersData[filter.attribute] || (filter.type === 'multiselect' ? [] : '')}
                />
              </Box.div>
            ))}
          </Box.div>
          <Box.div alignSelf="end" display="grid" gap="d3" gridTemplateColumns="1fr 1fr">
            <Button
              onClick={() => {
                setFiltersData(defaultFilters);
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button onClick={() => onApply(filtersData)} variant="primary">
              Apply
            </Button>
          </Box.div>
        </Box.div>
      </DrawerBody>
    </Drawer>
  );
};
