import { Control, useController } from 'react-hook-form';
import {
  ConfirmationModal,
  FileUploader,
  FileUploaderButton,
  FileUploaderButtonProps,
  FileUploaderProps,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import React, { useState } from 'react';
import { FormattedMetadata } from 'deprecated/api/uploads/types/FormattedMetadata';
import { uploadToS3AndShrineIt } from 'deprecated/api/uploads/uploadToS3AndShrineIt';

type ControlledFileUploaderProps = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  accept?: FileUploaderButtonProps['accept'];
  /** Invoked with `useForm`. Set to any to allow `any` number of form inputs. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  maxFileSize?: FileUploaderProps['maxFileSize'];
};

/**
 * File Uploader that handles uploading file to S3
 * and saving stringified metadata to React Hook Form
 * @param props.label - label for the selector
 * @param props.control - control from React Hook Forms
 * @param props.name - name of the field in the form
 * @param props.accept - file types accepted
 * @param props.maxFileSize - max file size
 * @param props.required - whether the field is required
 * @param props.disabled - whether the field is disabled
 */
export const ControlledFileUploader = ({
  label,
  control,
  name,
  accept,
  disabled,
  maxFileSize = '50 MB',
}: ControlledFileUploaderProps): React.JSX.Element => {
  const deleteDialogStore = useModalStore();
  const isModalOpen = deleteDialogStore.useState('open');
  const { field, fieldState } = useController({ name, control });
  const toast = useToast();
  const [progress, setProgress] = useState(0);
  const parsedFileMetadata = field.value
    ? (JSON.parse(field.value) as FormattedMetadata)
    : ({} as Partial<FormattedMetadata>);
  const [fileName, setFileName] = useState<string | undefined>(
    parsedFileMetadata.metadata?.filename,
  );

  const resetField = () => {
    field.onChange('');
    setFileName(undefined);
  };

  const handleError = () => {
    toast('We ran into an issue uploading your document.', 'error');
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFileName(file?.name);

    if (file) {
      try {
        const response = await uploadToS3AndShrineIt(file, setProgress, resetField, handleError);
        field.onChange(
          JSON.stringify({
            ...JSON.parse(String(response)),
            url: window.URL.createObjectURL(file as Blob),
          }),
        );
      } catch {
        handleError();
      }
    }
  };

  return (
    <>
      <FileUploader
        disabled={disabled}
        errorMessage={fieldState.error?.message}
        fileName={fileName}
        fileUrl={parsedFileMetadata.url}
        label={label}
        maxFileSize={maxFileSize}
        onCancel={resetField}
        onRemove={deleteDialogStore.show}
        progress={progress}
      >
        <FileUploaderButton
          accept={accept}
          disabled={disabled}
          id={name}
          onChange={handleFileChange}
          trailingIcon="arrow-up-from-line"
          type="button"
          variant={'outline'}
        />
      </FileUploader>
      {isModalOpen && (
        <ConfirmationModal
          confirmLabel="Delete"
          destructive
          onConfirm={resetField}
          store={deleteDialogStore}
        >
          Are you sure you want to delete this document? This action cannot be undone.
        </ConfirmationModal>
      )}
    </>
  );
};
