import { AdminCountriesParams } from '../useAdminCompanies/useAdminCompanies.types';
import { AxiosError } from 'axios';
import { SelectItemProps } from '@localyze-pluto/components';
import { UseQueryResult } from '@tanstack/react-query';
import { useAdminCompanies } from '../useAdminCompanies/useAdminCompanies';
import { sortByLabel } from 'utils/sortByLabel';

/**
 * Gets list of companies as select options, must be admin
 * @param params.search - filters results based on matching string
 * @param params.companyState - filters results based on company state
 * @returns companies transformed into options usable by a select
 */
export const useAdminCompanyOptions = (
  params?: AdminCountriesParams,
): UseQueryResult<SelectItemProps[], AxiosError> => {
  return useAdminCompanies({
    select: (companies) => {
      return companies
        .map((company) => ({
          label: company.name,
          value: company.id.toString(),
        }))
        .sort(sortByLabel);
    },
    params,
  });
};
