import { Box, HelpText } from '@localyze-pluto/components';

import { PERMISSIONS_DOCUMENTATION_URL } from 'constants/permissions';
import React from 'react';

export const HRManagerOptionsHelpText = (): React.JSX.Element => {
  return (
    <HelpText>
      <Box.span>
        HR Managers can be anyone in your team except for Viewers.{' '}
        <Box.a href={PERMISSIONS_DOCUMENTATION_URL} rel="noreferrer" target="_blank">
          Learn more about roles and permissions.
        </Box.a>
      </Box.span>
    </HelpText>
  );
};
