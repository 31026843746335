import { ACTIVE, PENDING } from '../../../constants';

import React from 'react';
import { Badge, Td } from '@localyze-pluto/components';

type Props = {
  readonly active: boolean;
};

export const StatusCell = ({ active }: Props): React.JSX.Element => {
  const badgeColor = active ? 'green' : 'gray';
  const statusText = active ? ACTIVE : PENDING;

  return (
    <Td>
      <Badge color={badgeColor}>{statusText}</Badge>
    </Td>
  );
};
