import {
  Box,
  BoxProps,
  Button,
  Heading,
  Icon,
  IconProps,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import React, { JSX, ReactNode, useState } from 'react';
import { EmptyState } from './EmptyState';
import { SystemProp } from '@xstyled/system';
import { isArray } from 'lodash';
import { SectionCard } from 'components/SectionCard/SectionCard';

type Category = 'check' | 'error' | 'info' | 'warning';

type CategoryIcon = {
  icon: IconProps['icon'];
  iconColor: IconProps['color'];
  iconBackgroundColor: BoxProps['backgroundColor'];
};

const iconProperties: Record<Category, CategoryIcon> = {
  check: {
    icon: 'check-square',
    iconColor: 'colorIconInfo',
    iconBackgroundColor: 'bgInfo',
  },
  info: {
    icon: 'info',
    iconColor: 'colorIconInfo',
    iconBackgroundColor: 'bgInfo',
  },
  warning: {
    icon: 'octagon-pause',
    iconColor: 'colorIconWarning',
    iconBackgroundColor: 'bgWarning',
  },
  error: {
    icon: 'triangle-alert',
    iconColor: 'colorIconError',
    iconBackgroundColor: 'bgError',
  },
};

type OverviewCardProps = {
  title: string;
  tooltipText?: string;
  emptyStateText?: string;
  emptyStateIcon?: IconProps['icon'];
  count?: number;
  hasShowMore?: boolean;
  children?: ReactNode | ReactNode[];
  headerRightItem?: ReactNode;
  iconCategory: Category;
  isExpanded?: boolean;
  maxHeightExpanded?: BoxProps['maxH'];
  onClickShowMore?: () => void;
  gridColumn?: SystemProp<BoxProps['gridColumn'], BoxProps>;
};

export const OverviewCard = ({
  title,
  tooltipText,
  count,
  emptyStateIcon,
  emptyStateText,
  iconCategory,
  children,
  headerRightItem,
  hasShowMore,
  isExpanded,
  maxHeightExpanded = 600,
  onClickShowMore,
  gridColumn,
}: OverviewCardProps): JSX.Element => {
  const [isCardExpanded, setIsCardExpanded] = useState(!!isExpanded);
  const iconProps = iconProperties[iconCategory];
  const tooltipStore = useTooltipStore();

  const isChildrenArrayFilled =
    isArray(children) && (children as unknown as ReactNode[]).some((child) => !!child);

  const hasChildren = isChildrenArrayFilled || (!isArray(children) && !!children);

  return (
    <SectionCard gridColumn={gridColumn}>
      <SectionCard.Header>
        <Box.div alignItems="center" display="flex" justifyContent="space-between" w="100%">
          <Box.div alignItems="center" display="flex">
            <Box.div
              alignItems="center"
              backgroundColor={iconProps.iconBackgroundColor}
              borderRadius="borderRadius20"
              display="flex"
              h={36}
              justifyContent="center"
              w={36}
            >
              <Icon
                color={iconProps.iconColor}
                decorative
                icon={iconProps.icon}
                size="sizeIcon30"
              />
            </Box.div>
            <Heading
              alignItems="center"
              as="h3"
              color="contentPrimary"
              display="flex"
              fontSize="fontSize40"
              fontWeight="fontWeightBold"
              gap="d0"
              lineHeight="lh6"
              marginBottom="d0"
              marginLeft="d4"
            >
              {title} {count === undefined ? null : `(${count})`}
              {tooltipText ? (
                <>
                  <TooltipAnchor store={tooltipStore} style={{ display: 'flex' }}>
                    <Icon color="colorIconStrong" decorative icon="info" size="sizeIcon40" />
                  </TooltipAnchor>
                  <Tooltip store={tooltipStore}>{tooltipText}</Tooltip>{' '}
                </>
              ) : null}
            </Heading>
          </Box.div>
          {headerRightItem}
        </Box.div>
      </SectionCard.Header>
      <SectionCard.Content>
        {!hasChildren ? (
          <EmptyState icon={emptyStateIcon} text={emptyStateText} />
        ) : (
          <Box.div w="100%">
            <Box.div
              marginBottom={hasShowMore ? 'd2' : undefined}
              maxH={isCardExpanded ? maxHeightExpanded : undefined}
              overflow={isCardExpanded ? 'auto' : 'hidden'}
              py="p2"
            >
              {children}
            </Box.div>
            {hasShowMore && (
              <Box.div alignSelf="start" display="flex" justifyContent="center" position="relative">
                <Box.div
                  borderBottomColor="colorBorderWeakest"
                  borderBottomStyle="borderStyleSolid"
                  borderBottomWidth="borderWidth10"
                  position="absolute"
                  top="50%"
                  w="100%"
                />
                <Box.div position="relative" zIndex="zIndex10">
                  <Button
                    onClick={() => {
                      setIsCardExpanded(true);
                      onClickShowMore?.();
                    }}
                    variant="secondary"
                  >
                    Show more
                  </Button>
                </Box.div>
              </Box.div>
            )}
          </Box.div>
        )}
      </SectionCard.Content>
    </SectionCard>
  );
};
