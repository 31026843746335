import { Box, Button, Icon, Menu, MenuItemProps } from '@localyze-pluto/components';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/img/localyze-logo.svg';
import React from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { Navbar } from 'modules/layout/components/Navbar/Navbar';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

const SIDEBAR = {
  FOOTER: {
    CONTACT: 'Contact',
    IMPRINT: 'Imprint',
    PRIVACY: 'Privacy',
  },
  NEW_CASE_BUTTON: 'New Case',
};

const IMPRINT_URL = 'https://www.localyze.com/legal#imprint';
const PRIVACY_URL = 'https://www.localyze.com/privacy-policy#privacy-app';
const SUPPORT_EMAIL = 'hello@localyze.com';

const CaseLabel = () => (
  <Box.div alignItems="center" display="flex" gap="d2">
    <Icon decorative icon="user-plus" size="sizeIcon20" />
    <Box.span>Case</Box.span>
  </Box.div>
);

const PreCheckLabel = () => (
  <Box.div alignItems="center" display="flex" gap="d2">
    <Icon decorative icon="clipboard-check" size="sizeIcon20" />
    <Box.span>Pre-Check</Box.span>
  </Box.div>
);

export const Sidebar = (): React.JSX.Element => {
  const { isHrViewer, isAdmin } = useCurrentUser();

  const history = useHistory();
  const routes = useRoutes();

  const userCanCreateCases = !isHrViewer;

  const menuItems: MenuItemProps[] = [
    {
      onClick: () => {
        trackEvent('sidebar: start new process', { page: window.location.pathname });
        history.push(routes.createCase.url);
      },
      label: <CaseLabel />,
    },
    {
      onClick: () => {
        trackEvent('sidebar: start new pre-check', { page: window.location.pathname });
        history.push(routes.preCheck.url);
      },
      label: <PreCheckLabel />,
    },
  ];

  return (
    <Box.aside
      backgroundColor="bgPrimary"
      display="flex"
      flexDirection="column"
      h="100vh"
      minWidth={280}
      paddingBottom="p6"
      paddingTop="p8"
      position="sticky"
      px="p6"
      top="0"
      w={280}
    >
      <Box.div
        alignItems="center"
        data-testid="localyze-logo"
        display="flex"
        justifyContent="center"
        marginBottom="m8"
      >
        <Logo height="32px" width="172px" />
      </Box.div>
      <Box.div
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        my="d5"
        overflow="hidden"
        style={{ flex: '1 1 0%' }}
      >
        {isAdmin && (
          <Button
            as={Link}
            leadingIcon="user-plus"
            onClick={() => {
              trackEvent('sidebar: start new process', { page: window.location.pathname });
            }}
            to="/create-case"
            variant="primary"
          >
            {SIDEBAR.NEW_CASE_BUTTON}
          </Button>
        )}
        {!isAdmin && userCanCreateCases && (
          <Menu
            items={menuItems}
            menuButton={
              <Button fullWidth trailingIcon="chevron-down" variant="primary">
                New
              </Button>
            }
          />
        )}
        <Navbar />
      </Box.div>
      <Box.footer display="flex" fontSize="fontSize10" fontWeight="fontWeightRegular">
        <Box.div
          color="colorTextStronger"
          display="flex"
          justifyContent="space-between"
          textDecoration="none"
          w="100%"
        >
          <Box.a
            color="colorTextStronger"
            href={`mailto:${SUPPORT_EMAIL}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {SIDEBAR.FOOTER.CONTACT}
          </Box.a>

          <Box.a
            color="colorTextStronger"
            href={IMPRINT_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            {SIDEBAR.FOOTER.IMPRINT}
          </Box.a>
          <Box.a
            color="colorTextStronger"
            href={PRIVACY_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            {SIDEBAR.FOOTER.PRIVACY}
          </Box.a>
        </Box.div>
      </Box.footer>
    </Box.aside>
  );
};
