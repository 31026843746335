import { Anchor, Button, Paragraph } from '@localyze-pluto/components';
import { Link } from 'react-router-dom';
import { PERMISSIONS_DOCUMENTATION_URL } from 'constants/permissions';
import React from 'react';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useCurrentUser } from 'config/CurrentUserContext';
import { UnauthorizedErrorPage } from 'components/UnauthorizedErrorPage';

export const CaseForbiddenError = (): React.JSX.Element => {
  const { id: userId, isAdmin, isHrViewer } = useCurrentUser();
  const routes = useRoutes();

  return (
    <UnauthorizedErrorPage heading="No access to this case">
      <Paragraph marginBottom="m6" size="large">
        You can ask your Superadmin or this case&apos;s HR manager to add you as a Follower. Read
        more about who has access to case profiles{' '}
        <Anchor href={PERMISSIONS_DOCUMENTATION_URL} rel="noopener noreferrer" target="_blank">
          here
        </Anchor>
        .
      </Paragraph>
      <Button as={Link} to={routes.cases.get(isAdmin, isHrViewer, userId)} variant="secondary">
        Go to case list
      </Button>
    </UnauthorizedErrorPage>
  );
};
