import { postUserCreate } from 'deprecated/api/companies/company';

import { UserRoleName } from 'modules/users/types/UserRoleName';

export const OPTION_ROLES = [
  { value: UserRoleName.SuperAdmin, label: 'Superadmin' },
  { value: UserRoleName.Regular, label: 'Regular' },
  { value: UserRoleName.Viewer, label: 'Viewer' },
];

const formatUserCreateDataToSend = (componentFormData) => {
  const {
    firstName: first_name,
    lastName: last_name,
    email,
    phoneNumber: phone_number,
    roleName,
  } = componentFormData;
  return {
    user: {
      first_name,
      last_name,
      email,
      phone_number,
      company_user_attributes: { role_name: roleName },
    },
  };
};

export const submitCompanyUserCreateInfo = (formData, companyId) => {
  const dataToSend = formatUserCreateDataToSend(formData);
  return postUserCreate(dataToSend, companyId);
};
