export const adminColumns = [
  {
    header: '',
    id: 'service_level',
  },
  {
    header: 'Name',
    id: 'name',
  },
  {
    header: 'Case ID',
    id: 'case_id',
  },
  {
    header: 'Prio',
    id: 'communication_priority',
  },
  {
    header: 'Company',
    id: 'company',
  },
  {
    header: 'Starting Date',
    id: 'starting_date',
  },
  {
    header: 'Case Type',
    id: 'case_type',
  },
  {
    header: 'Current Step',
    id: 'current_step',
  },
  {
    header: 'Last Update',
    id: 'last_status_update_message',
  },
  {
    header: 'Internal Note',
    id: 'internal_note',
  },
  {
    header: 'Last Updated',
    id: 'last_updated',
  },
  {
    header: '',
    id: 'dot',
  },
];

export const hrColumns = [
  {
    header: 'Name',
    id: 'name',
  },
  {
    header: 'Case ID',
    id: 'case_id',
  },
  {
    header: 'Office',
    id: 'office',
  },
  {
    header: 'Starting Date',
    id: 'starting_date',
  },
  {
    header: 'Case Type',
    id: 'case_type',
  },
  {
    header: 'Current Step',
    id: 'current_step',
  },
  {
    header: 'Last Update',
    id: 'last_status_update_message',
  },
  {
    header: 'Task',
    id: 'task',
  },
  {
    header: 'Progress',
    id: 'progress',
  },
];
