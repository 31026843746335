import { InternalNotes } from '../components/CaseActivityCard/InternalNotes/InternalNotes';
import { Messages } from '../components/CaseActivityCard/Messages/Messages';
import React from 'react';
import { Updates } from '../components/CaseActivityCard/Updates/Updates';
import { Tasks } from '../components/CaseActivityCard/Tasks/Tasks';
import { CaseInformation } from 'modules/caseDetails/components/CaseDetailsCard/CaseInformation/CaseInformation';
import { PersonResponsible } from '../components/CaseDetailsCard/PersonResponsible/PersonResponsible';
import { RelevantDates } from '../components/CaseDetailsCard/RelevantDates/RelevantDates';
import { CaseData } from '../components/CaseDetailsCard/CaseData/CaseData';
import { Tab } from 'types/Tab';

export const CASE_ACTIVITY_TABS: Tab<string>[] = [
  {
    key: 'tasks-tab',
    name: 'Tasks',
    component: <Tasks />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'updates-tab',
    name: 'Updates',
    component: <Updates />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'messages-tab',
    name: 'Messages',
    component: <Messages />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'internal-notes-tab',
    name: 'Internal notes',
    component: <InternalNotes />,
    userTypes: ['admin'],
  },
];

export const CASE_DETAILS_TABS: Tab<string>[] = [
  {
    key: 'case-information-tab',
    name: 'Case information',
    component: <CaseInformation />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'person-responsible-tab',
    name: 'Person responsible',
    component: <PersonResponsible />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'relevant-dates-tab',
    name: 'Relevant dates',
    component: <RelevantDates />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'case-data-tab',
    name: 'Case data',
    component: <CaseData />,
    userTypes: ['admin'],
  },
];
