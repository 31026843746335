import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, Select } from '@localyze-pluto/components';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';

export default function DropDown({ name, label, options, disabled, value, onChange, withNone }) {
  const selectOptions = (withNone ? [{ label: 'None', value: '' }] : []).concat(
    mapToSelectOptions({
      records: options,
      labelProp: 'label',
      valueProp: 'value',
    }),
  );

  return (
    <>
      <Box.div marginTop="m2">
        <Field name={name} type="select">
          {({ form }) => {
            const setValue = (newValue) => {
              form.setFieldValue(name, newValue);
              onChange(newValue);
            };

            return (
              <Select
                placeholder={label}
                title={label}
                items={selectOptions}
                setValue={setValue}
                disabled={disabled}
                value={String(value)}
              />
            );
          }}
        </Field>
      </Box.div>
      <ErrorWrapper>
        <ErrorMessage name={name} />
      </ErrorWrapper>
    </>
  );
}

const ErrorWrapper = styled.div`
  color: var(--color-red);
  font-size: 14px;
`;

DropDown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
};
