import { Icon } from '@localyze-pluto/components';
import React from 'react';
import { SystemProp, Theme } from '@localyze-pluto/theme';

type LoadingIconProps = {
  size: SystemProp<keyof Theme['sizes'], Theme>;
};

export const LoadingIcon = ({ size }: LoadingIconProps): React.JSX.Element => (
  <Icon
    animation="spin 1s linear infinite"
    color="colorIconInfo"
    decorative
    icon="earth"
    size={size}
  />
);
