import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Offices } from 'modules/company/pages/Offices/Offices';
import { Route } from 'react-router-dom';
import { IntegrationsList } from 'modules/integrations/IntegrationsList';
import { PreCheck } from 'modules/precheck/pages/precheck/PreCheck';
import React from 'react';
import { Reporting } from 'modules/reporting/pages/reporting/Reporting';
import { HRGlomoRequests } from 'modules/glomoRequests/pages/HRGlomoRequests';
import { HRGlomoRequest } from 'modules/glomoRequests/pages/HRGlomoRequest';
import { useCurrentUser } from 'config/CurrentUserContext';
import { ManagePartners } from '../../partners/pages/ManagePartners';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { PARTNERS_HUB } from 'modules/featureFlags/types/flags';

export const HRRoutes = (): React.JSX.Element => {
  const { isHrAdmin } = useCurrentUser();
  const routes = useRoutes();
  const partnersHubIsActive = useIsFeatureFlagActive(PARTNERS_HUB);

  return (
    <>
      <Route key={routes.preCheck.url} path={routes.preCheck.url} render={() => <PreCheck />} />
      <Route exact key={routes.offices.url} path={routes.offices.url} render={() => <Offices />} />
      <Route
        key={routes.integrations.url}
        path={routes.integrations.url}
        render={() => <IntegrationsList />}
      />
      <Route
        exact
        key={routes.glomoRequestsList.url}
        path={routes.glomoRequestsList.url}
        render={() => <HRGlomoRequests />}
      />
      <Route
        exact
        key={routes.glomoRequest.url}
        path={routes.glomoRequest.url}
        render={() => <HRGlomoRequest />}
      />
      {isHrAdmin && (
        <Route
          key={routes.reporting.url}
          path={routes.reporting.url}
          render={() => <Reporting />}
        />
      )}
      {isHrAdmin && partnersHubIsActive && (
        <Route
          exact
          key={routes.partners.url}
          path={routes.partners.url}
          render={() => <ManagePartners />}
        />
      )}
    </>
  );
};
