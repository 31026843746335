import { AxiosError } from 'axios';
import { CaseTypeCategory } from 'modules/cases/types/CaseTypeCategory';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCaseTypeCategories } from './getCaseTypeCategories';

export const useCaseTypeCategories = (): UseQueryResult<CaseTypeCategory[], AxiosError> => {
  return useQuery({
    queryKey: ['case-type-categories'],
    queryFn: getCaseTypeCategories,
  });
};
