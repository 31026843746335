import { Avatar, Box, Text, useModalStore } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { CompanyUser } from 'deprecated/api/companies/company_user';
import { User } from 'deprecated/api/user/types/User';
import { EditCaseManagerModal } from 'modules/caseDetails/components/CaseDetailsCard/PersonResponsible/EditCaseManagerModal/EditCaseManagerModal';
import { EditCostCenterModal } from 'modules/caseDetails/components/CaseDetailsCard/PersonResponsible/EditCostCenterModal/EditCostCenterModal';
import { EditFollowersModal } from 'modules/caseDetails/components/CaseDetailsCard/PersonResponsible/EditFollowersModal/EditFollowersModal';
import { EditHiringManagerModal } from 'modules/caseDetails/components/CaseDetailsCard/PersonResponsible/EditHiringManagerModal/EditHiringManagerModal';
import { EditHRManagerModal } from 'modules/caseDetails/components/CaseDetailsCard/PersonResponsible/EditHRManagerModal/EditHRManagerModal';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { useUser } from 'modules/users/api/useUser/useUser';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from 'config/CurrentUserContext';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';

const UserItem = ({ user }: { user: CompanyUser | User }): React.JSX.Element => {
  const userFullName = fullName(user);

  return (
    <Box.div alignItems="center" display="flex" gap="d2">
      <Avatar name={userFullName} size="small" src={user.avatar_url ?? ''} />
      <Text.span fontSize="fontSize20" fontWeight="fontWeightBold">
        {userFullName}
      </Text.span>
    </Box.div>
  );
};

export const PersonResponsible = (): React.JSX.Element => {
  const { id: caseId } = useParams<{ id: string }>();
  const { isAdmin } = useCurrentUser();
  const { data: kase, isSuccess: isSuccessCase } = useCase({ caseId: Number(caseId) });
  const { data: caseManager, isSuccess: isSuccessCaseManager } = useUser({
    params: { userId: Number(kase?.case_manager_id) },
    enabled: isAdmin && !!kase?.case_manager_id,
  });
  const { data: hrManager, isSuccess: isSuccessHrManager } = useUser({
    params: { userId: Number(kase?.hr_manager_id) },
    enabled: isSuccessCase && !!kase.hr_manager_id,
  });

  const caseManagerModal = useModalStore();
  const isCaseManagerModalOpen = caseManagerModal.useState('open');
  const hrManagerModal = useModalStore();
  const isHRManagerModalOpen = hrManagerModal.useState('open');
  const hiringManagerModal = useModalStore();
  const isHiringManagerModalOpen = hiringManagerModal.useState('open');
  const costCenterModal = useModalStore();
  const isCostCenterModalOpen = costCenterModal.useState('open');
  const followersModal = useModalStore();
  const isFollowersModalOpen = followersModal.useState('open');

  if (
    !isSuccessCase ||
    (isAdmin && !!kase.case_manager_id && !isSuccessCaseManager) ||
    (!!kase.hr_manager_id && !isSuccessHrManager)
  ) {
    return <ContainedLoadingState />;
  }

  const items = [
    ...(isAdmin
      ? [
          {
            key: 'Case Manager',
            value: caseManager && <UserItem user={caseManager} />,
            onEdit: caseManagerModal.show,
          },
        ]
      : []),
    {
      key: 'HR Manager',
      value: hrManager && <UserItem user={hrManager} />,
      onEdit: hrManagerModal.show,
    },
    {
      key: 'Followers',
      value: (
        <Box.div display="flex" flexWrap="wrap" gap="d3">
          {kase.followers.map((follower) => (
            <UserItem key={follower.id} user={follower} />
          ))}
        </Box.div>
      ),
      onEdit: followersModal.show,
    },
    {
      key: 'Hiring Manager',
      value: <Box.b>{kase.hiring_manager_name || '-'}</Box.b>,
      onEdit: hiringManagerModal.show,
    },
    {
      key: 'Cost Center',
      value: <Box.b>{kase.cost_center || '-'}</Box.b>,
      onEdit: costCenterModal.show,
    },
  ];

  return (
    <>
      <KeyValueList borderBottomStyle="borderStyleSolid" items={items} py="d6" />

      {isCaseManagerModalOpen && (
        <EditCaseManagerModal caseId={kase.id} caseManager={caseManager} modal={caseManagerModal} />
      )}
      {isHRManagerModalOpen && (
        <EditHRManagerModal hrManager={hrManager} kase={kase} modal={hrManagerModal} />
      )}
      {isHiringManagerModalOpen && (
        <EditHiringManagerModal kase={kase} modal={hiringManagerModal} />
      )}
      {isCostCenterModalOpen && <EditCostCenterModal kase={kase} modal={costCenterModal} />}
      {isFollowersModalOpen && <EditFollowersModal kase={kase} modal={followersModal} />}
    </>
  );
};
