import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalHeading,
  ModalFooter,
  Paragraph,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import React from 'react';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { useApproveGlomoAssessmentRequest } from 'modules/glomoRequests/api/useApproveGlomoAssessmentRequest/useApproveGlomoAssessmentRequest';

// TODO: should this component change to SubmitForAssessmentButton after the flag is removed?
export const ApproveForAssessmentButton = ({
  glomoRequest,
}: {
  glomoRequest: GlomoRequest;
}): React.JSX.Element => {
  const toast = useToast();
  const modal = useModalStore({});

  const { mutate: approveGlomoAssessmentRequest } = useApproveGlomoAssessmentRequest({
    onSuccess: () => {
      modal.hide();
      toast('Request successfully approved for assessment', 'success');
    },
    onError: () => {
      toast('Something went wrong, please try again', 'error');
    },
  });

  return (
    <>
      <Modal store={modal} style={{ maxWidth: 500 }}>
        <ModalHeader>
          <ModalHeading>Submit for risk assessment</ModalHeading>
        </ModalHeader>
        <ModalBody>
          <Box.div alignItems="center" display="flex" flexDirection="column" gap="d4">
            <Box.div textAlign="left">
              <Paragraph color="colorTextStrongest" marginBottom="d0">
                If you submit this request for risk assessment, the trip will be evaluated. Your
                company will be billed 79€ in the next billing cycle.
              </Paragraph>
            </Box.div>
          </Box.div>
        </ModalBody>
        <ModalFooter>
          <Button minWidth={120} onClick={modal.hide} variant="secondary">
            Cancel
          </Button>
          <Button
            minWidth={120}
            onClick={() => approveGlomoAssessmentRequest({ glomoRequestId: glomoRequest.id })}
            variant="primary"
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <Button onClick={() => modal.show()} variant="primary">
        Submit for risk assessment
      </Button>
    </>
  );
};
