import { CaseStateFilter } from 'modules/cases/types/CaseStateFilter';

export const TOOLTIP_TEXTS: Record<CaseStateFilter, string> = {
  [CaseStateFilter.All]: 'Count of cases created',
  [CaseStateFilter.Active]: 'Count of cases in case state case evaluation, ongoing or waiting',
  [CaseStateFilter.StartDateAtRisk]:
    'Count of cases with a risk of the start date' +
    ' not being met due to a late visa appointment date',
  [CaseStateFilter.Closed]: 'Count of cases in case state closed',
};
