import { Step } from 'modules/pipelines/types/Step';
import { MappedStepComponent, mapStepComponents } from '../helpers/mapStepComponents';
import { getStep, getStepComponents } from 'deprecated/api/steps';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PipelineWithSteps } from 'deprecated/api/pipelines/types/PipelineWithSteps';
import { StepComponentResponse } from 'deprecated/api/steps/types/StepComponentResponse';
import { getInternalVariables } from 'deprecated/api/variables/internal_variables';
import { getPipeline } from 'deprecated/api/pipelines/getPipeline';
import { moveDown } from 'deprecated/api/components/moveDown';
import { moveUp } from 'deprecated/api/components/moveUp';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';

type UseGetStepProps = {
  readonly loading: boolean;
  readonly pipeline: PipelineWithSteps | null;
  readonly step: Maybe<Step>;
  readonly stepComponents: MappedStepComponent[];
  readonly internalVariables: Variable[];
  readonly setInternalVariables: (internalVariables: Variable[]) => void;
  readonly fetchVariables: (
    stepComponentsTypes: string[],
    destinationCountryId?: number,
  ) => Promise<Variable[]>;
  readonly moveComponentUp: (componentId: number) => void;
  readonly moveComponentDown: (componentId: number) => void;
};

const fetchVariables = async (
  stepComponentsTypes: string[],
  destinationCountryId?: number,
): Promise<Variable[]> => {
  const params: Record<string, string[]> = {
    destination_country_id: ['default'],
    variable_type: stepComponentsTypes,
  };

  if (destinationCountryId) {
    params.destination_country_id = [
      ...params.destination_country_id,
      String(destinationCountryId),
    ];
  }

  return getInternalVariables(params) as Promise<Variable[]>;
};

export const useGetStep = (): UseGetStepProps => {
  const { stepId } = useParams<{
    stepId: string;
  }>();
  const { search } = useLocation();

  const pipelineId = new URLSearchParams(search).get('pipelineId');

  const [step, setStep] = useState<Maybe<Step>>(null);
  const [internalVariables, setInternalVariables] = useState<Variable[]>([]);
  const [stepComponents, setStepComponents] = useState<MappedStepComponent[]>([]);
  const [pipeline, setPipeline] = useState<PipelineWithSteps | null>(null);
  const [loading, setLoading] = useState(false);

  const moveComponent = async (
    doRequest: (stepId: number, componentId: number) => Promise<StepComponentResponse[]>,
    componentId: number,
  ) => {
    if (componentId) {
      setLoading(true);
      const components = await doRequest(Number(stepId), componentId);
      setStepComponents(mapStepComponents(components));
      setLoading(false);
    }
  };

  const fetchData = () => {
    const stepPromise = getStep(Number(stepId));
    const pipelinePromise = getPipeline(Number(pipelineId));
    const stepComponentsPromise = getStepComponents(Number(stepId));

    setLoading(true);

    Promise.all([stepPromise, pipelinePromise, stepComponentsPromise])
      .then(([step, pipeline, stepComponents]) => {
        const mappedStepcompents = mapStepComponents(stepComponents);
        setStep(step);
        setPipeline(pipeline);
        setStepComponents(mappedStepcompents);

        return { pipeline, stepComponents: mappedStepcompents };
      })
      .then(({ pipeline, stepComponents }) => {
        const componentTypes: string[] = stepComponents.map((step) => step.componentType);
        const destinationCountryId = pipeline.destination_country?.id;

        return fetchVariables(componentTypes, destinationCountryId);
      })
      .then((variables) => {
        setInternalVariables(variables);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchVariables,
    internalVariables,
    loading,
    pipeline,
    setInternalVariables,
    step,
    stepComponents,
    moveComponentUp: (componentId: number) => moveComponent(moveUp, componentId),
    moveComponentDown: (componentId: number) => moveComponent(moveDown, componentId),
  };
};
