import { DateCell } from './cells/DateCell';
import { NameCell } from './cells/NameCell';
import React from 'react';
import { RoleCell } from './cells/RoleCell';
import { StatusCell } from './cells/StatusCell';
import { TABLE_HEADERS } from '../../constants';
import { CompanyUser } from 'deprecated/api/companies/company_user';
import { Table, TBody, Td, Th, THead, Tr } from '@localyze-pluto/components';
import { RemoveTeamMemberButtonAndModal } from 'modules/company/pages/Team/components/RemoveTeamMemberButtonAndModal/RemoveTeamMemberButtonAndModal';
import { useCurrentUser } from 'config/CurrentUserContext';

type Props = {
  readonly companyId: number;
  readonly canEdit: boolean;
  readonly teamMembers: CompanyUser[];
};

export const TeamTable = ({ companyId, canEdit, teamMembers }: Props): React.JSX.Element => {
  const { isAdmin, id: currentUserId } = useCurrentUser();

  const headers = isAdmin ? ['Identifier', ...TABLE_HEADERS] : TABLE_HEADERS;

  return (
    <Table striped>
      <THead isSticky>
        <Tr>
          {headers.map((header) => (
            <Th key={header}>{header}</Th>
          ))}
        </Tr>
      </THead>
      <TBody>
        {teamMembers.map(({ id, first_name, last_name, email, created_at, active, role_name }) => (
          <Tr key={id}>
            {isAdmin && <Td>User-{id}</Td>}
            <NameCell email={email} firstName={first_name} lastName={last_name} />
            <DateCell dateString={created_at} />
            <StatusCell active={active} />
            <RoleCell
              companyId={companyId}
              disabled={!canEdit}
              memberId={id}
              roleName={role_name}
            />
            <Td>
              {canEdit && id !== currentUserId && (
                <RemoveTeamMemberButtonAndModal
                  companyId={companyId}
                  userToRemove={{ first_name, last_name, role_name, id }}
                />
              )}
            </Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};
