import { UseMutationResult, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from 'config/queryClient';
import axios, { AxiosError } from 'axios';
import { DOCUMENTS_QUERY_KEY } from '../useDocuments/useDocuments';

type DeleteDocumentProps = {
  internalReferenceCode: string;
  caseId: number;
};

export const deleteDocument = async ({
  internalReferenceCode,
  caseId,
}: DeleteDocumentProps): Promise<void> => {
  return await axios.delete(`/documents/${internalReferenceCode}`, {
    params: { case_id: caseId },
  });
};

export const useDeleteDocument = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, DeleteDocumentProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, DeleteDocumentProps> =>
  useMutation({
    mutationFn: deleteDocument,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEY],
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
