import React, { ReactNode } from 'react';

import { Option } from './Option';
import { SelectMenuOption } from '../SelectMenu';
import cx from 'clsx';

export type WithSelectMenuOptionProps = {
  className: ({ active }: { active: boolean }) => string;
  disabled: boolean;
  value: SelectMenuOption;
  children: ReactNode | ((option: { selected: boolean }) => React.JSX.Element);
};

export type SelectMenuOptionProps = {
  disabled: boolean;
  option: SelectMenuOption;
};

export const withSelectMenuOption = (
  WrappedComponent: React.ComponentType<WithSelectMenuOptionProps>,
): React.ComponentType<SelectMenuOptionProps> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithOption = ({ option, disabled }: SelectMenuOptionProps): React.JSX.Element => (
    <WrappedComponent
      className={({ active }: { active: boolean }): string =>
        cx('tw-cursor-pointer tw-select-none tw-relative tw-py-2 tw-px-4', {
          'tw-bg-gray-100': active,
          'tw-text-gray-900': !active,
        })
      }
      data-testid="select-menu-option"
      disabled={disabled}
      key={option.value}
      value={option}
    >
      {({ selected }: { selected: boolean }) => <Option label={option.label} selected={selected} />}
    </WrappedComponent>
  );

  ComponentWithOption.displayName = `withSelectMenuOption(${displayName})`;

  return ComponentWithOption;
};
