import { Box, Button, Heading } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

export const Settings = (): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const params = useParams<{ companyId: string }>();

  const { data: company } = useCompany({
    companyId: Number(params.companyId),
    enabled: isAdmin && !!params.companyId,
  });

  const pageTitle = isAdmin && company ? `Settings - ${company.name}` : 'Settings';

  const routes = useRoutes();

  return (
    <PageLayout
      flexed
      maxW={1094}
      mx="auto"
      px="d8"
      title={isAdmin ? undefined : pageTitle}
      titleButton={
        isAdmin ? (
          <Button
            as={Link}
            leadingIcon="arrow-left"
            p="d0"
            to={routes.companies.url}
            variant="ghost"
          >
            Back to Companies
          </Button>
        ) : undefined
      }
      w="100%"
    >
      {isAdmin && (
        <Box.div>
          <Heading size="title-screen">{pageTitle}</Heading>
        </Box.div>
      )}
      <Box.ul
        backgroundColor="bgPrimary"
        borderColor="borderSecondary"
        borderRadius="borderRadius40"
        borderStyle="borderStyleSolid"
        borderWidth="borderWidth10"
        display="flex"
        flexDirection="column"
        listStyleType="none"
        mb="d0"
        paddingLeft="d0"
        w="100%"
      >
        <Box.li>
          <Button
            as={Link}
            color="contentPrimary"
            fontWeight="fontWeightBold"
            fullWidth
            justifyContent="space-between"
            lineHeight="lh6"
            p="d6"
            to={routes.team.get(params.companyId)}
            trailingIcon="chevron-right"
            variant="ghost"
          >
            Manage your team
          </Button>
        </Box.li>
      </Box.ul>
    </PageLayout>
  );
};
