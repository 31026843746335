import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

:root {
  --primary-color-full:  ${'rgba(114, 206, 170, 1)'};
	--primary-color-light: ${'rgba(199, 235, 221, 1)'};
	--secondary-color-full: ${'rgba(98, 72, 255, 1)'};
	--secondary-color-light:${'rgba(98, 72, 255, 0.15)'};

	--colorFont-localyze-black:  #323232;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 10px rgba(0, 0, 0, 0.25);

  /* Below colors are used in HR dashboard, Employee Notes, Average waiting times page */
  --color-grey: #9B9B9B;
  --color-light-grey: #D8D8D8;
  --color-red: #ff101f;
  --color-green: #72CEAA;
  --color-background-white: #fff;
  font-size: 16px;
}
`;
