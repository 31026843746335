import clsx from 'clsx';
import React from 'react';

export interface SimpleCounterProps {
  readonly title: string;
  /**
   * @desc The count value of the status counter
   * @default 0
   */
  readonly count?: number;
  readonly className?: string;
  readonly lineBreak?: boolean;
}

export const SimpleCounter = ({
  title,
  count = 0,
  lineBreak = true,
  className,
}: SimpleCounterProps): React.JSX.Element => (
  <div className={className} data-testid="simple-status-counter">
    <span
      aria-label={title}
      className={clsx('tw-text-4xl tw-leading-10 tw-font-black tw-w-fit', {
        'tw-block': lineBreak,
      })}
    >
      {count}
    </span>
    <span
      className={clsx('tw-text-xs tw-leading-4 tw-text-base tw-lowercase', {
        'tw-block': lineBreak,
        'tw-ml-1': !lineBreak,
      })}
    >
      {title}
    </span>
  </div>
);
