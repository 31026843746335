import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';

import React from 'react';
import { useCurrentUser } from 'config/CurrentUserContext';

type FeatureFlagProviderProps = {
  children: React.ReactElement;
};

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagProviderProps): React.ReactElement => {
  const { company_id, id, isAdmin } = useCurrentUser();

  const splitId = `User-${id}`;

  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey: process.env.REACT_APP_SPLIT_IO_API_KEY || '',
          key: 'default',
        },
      }}
      updateOnSdkUpdate={true}
    >
      <SplitClient
        attributes={{
          company: `Company-${company_id}`,
          localyze_admin: isAdmin,
        }}
        splitKey={splitId}
      >
        {children}
      </SplitClient>
    </SplitFactory>
  );
};
