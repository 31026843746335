import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { PipelineWithSteps } from 'deprecated/api/pipelines/types/PipelineWithSteps';

const PIPELINES_QUERY_KEY = 'pipelines';

export const getPipelines = async (): Promise<PipelineWithSteps[]> => {
  const { data } = await axios.get<PipelineWithSteps[]>('/admin/pipelines');
  return data;
};

export const usePipelines = (): UseQueryResult<PipelineWithSteps[], AxiosError> =>
  useQuery({
    queryKey: [PIPELINES_QUERY_KEY],
    queryFn: getPipelines,
  });
