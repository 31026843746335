import { Box, useToast } from '@localyze-pluto/components';
import React from 'react';
import { useTaskTemplates } from '../TemplatesDropdown/useTaskTemplates';
import { isAxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useCreateTask } from 'modules/tasks/api/useCreateTask/useCreateTask';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { PostNewItem } from '../PostNewItem/PostNewItem';

export const PostNewTask = (): React.JSX.Element => {
  const { data: taskTemplates } = useTaskTemplates();

  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const caseId = Number(id);

  const { mutate: createTask } = useCreateTask({
    onSuccess: () => {
      toast('New Task was created successfully.', 'success');
    },
    onError: (error) => {
      const errorMessage = isAxiosError(error)
        ? (error as AxiosErrorWithMessage).response?.data.message
        : '';
      toast(errorMessage || 'Error creating new Task.', 'error');
    },
  });

  const onPostTask = (item: string) => {
    createTask({ description: item, caseId });
  };

  return (
    <Box.div
      borderLeftColor="borderSecondary"
      borderLeftStyle="borderStyleSolid"
      borderLeftWidth="borderWidth10"
      p="d6"
    >
      <PostNewItem
        onPostNewItem={onPostTask}
        placeholder="Post a new task..."
        showTemplatesDropdown
        templates={taskTemplates}
        textAreaAriaLabel="Post a new task"
      />
    </Box.div>
  );
};
