import { CircularProgress } from 'deprecated/components/Chewpaca/Progress/CircularProgress';
import React from 'react';
import { CaseOverviewCell } from '../CaseOverviewCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  caseOverview: CaseOverview;
  percentage: Maybe<number>;
};

export const ProgressCell = ({ caseOverview, percentage }: Props): React.JSX.Element => (
  <CaseOverviewCell caseOverview={caseOverview}>
    {percentage === null ? (
      <span>-</span>
    ) : (
      <CircularProgress
        labelClassName="tw-text-gray-900 tw-text-xxs tw-font-normal"
        progress={percentage}
        strokeWidth={8}
      />
    )}
  </CaseOverviewCell>
);
