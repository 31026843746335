import { Tab } from 'types/Tab';
import React from 'react';
import { AccountInformation } from 'modules/talent/pages/talentProfile/components/AccountInformation/AccountInformation';
import { PersonalInformation } from 'modules/talent/pages/talentProfile/components/PersonalInformation/PersonalInformation';
import { MobilityTracking } from './components/TalentInformationCard/MobilityTracking/MobilityTracking';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';

export const getTalentInformationTabs = (talent: TalentWithPersonalInformation): Tab<string>[] => [
  {
    key: 'personal-tab',
    name: 'Personal',
    component: <PersonalInformation talent={talent} />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'mobility-tracking-tab',
    name: 'Work abroad tracker',
    component: <MobilityTracking talent={talent} />,
    userTypes: ['admin', 'hr'],
  },
  {
    key: 'account-tab',
    name: 'Account',
    component: <AccountInformation borderBottomStyle="borderStyleSolid" talent={talent} />,
    userTypes: ['admin', 'hr'],
  },
];
