import {
  Box,
  BoxProps,
  ControlledFormInput,
  ControlledFormSelect,
  Heading,
} from '@localyze-pluto/components';
import { ControlledFileUploader } from 'components/ControlledFileUploader/ControlledFileUploader';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const RISK_LEVELS = [
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'High',
    value: 'high',
  },
];

export const RiskAssessmentResult = ({
  isDisabled,
  status,
}: {
  isDisabled: boolean;
  status: GlomoRequestStatus;
}): React.JSX.Element => {
  const { control } = useFormContext();

  return (
    <Box.div marginBottom="d4">
      <SectionCard>
        <SectionCard.Header padding={'d4 d6' as BoxProps['padding']}>
          <Box.div
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Heading as="h3" color="contentPrimary" marginBottom="d0" size="title-subsection">
              Risk assessment result
            </Heading>
          </Box.div>
        </SectionCard.Header>
        <SectionCard.Content>
          <Box.div w="100%">
            <Heading
              as="h4"
              color="contentPrimary"
              fontSize="titleGroup"
              fontWeight="fontWeightBold"
              lineHeight="lh6"
              marginBottom="d2"
              textAlign="left"
            >
              Upload risk assessment and select the overall risk level
            </Heading>
            <Box.div display="grid" gap="d4">
              <ControlledFormInput
                control={control}
                disabled={isDisabled || status === 'risk_assessment_in_review'}
                id="riskAssessmentUrl"
                label=""
                name="riskAssessmentUrl"
                placeholder="Risk assessment link"
                type="text"
              />

              <ControlledFormSelect
                control={control}
                disabled={isDisabled}
                id="riskLevel"
                items={RISK_LEVELS}
                label=""
                name="riskLevel"
                placeholder="Select overall risk level"
              />

              <ControlledFileUploader
                accept=".pdf"
                control={control}
                data-testid="ahoy"
                disabled={isDisabled}
                label="Upload risk assessment report as pdf"
                name="report_pdf"
              />
            </Box.div>
          </Box.div>
        </SectionCard.Content>
      </SectionCard>
    </Box.div>
  );
};
