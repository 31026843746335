import { sortByLabel } from 'utils/sortByLabel';
import { SelectItemProps } from '@localyze-pluto/components';

type RecordToMap = Record<string, boolean | number | string>;
type StringOrFunctionProp<T extends RecordToMap> = string | ((record: T) => string);
type MapToSelectOptionsProps<T extends RecordToMap> = {
  records: T[];
  labelProp: StringOrFunctionProp<T>;
  valueProp: StringOrFunctionProp<T>;
  sorted?: boolean;
  allLabel?: string;
};

const transformedValue = <T extends RecordToMap>(
  record: T,
  prop: StringOrFunctionProp<T>,
): string => (typeof prop === 'string' ? record[prop].toString() : prop(record));

export const mapToSelectOptions = <T extends RecordToMap>({
  records,
  labelProp,
  valueProp,
  sorted = false,
  allLabel,
}: MapToSelectOptionsProps<T>): SelectItemProps[] => {
  const recordOptions = records.map((record) => ({
    label: transformedValue<T>(record, labelProp),
    value: transformedValue<T>(record, valueProp),
  }));

  const options = allLabel ? [{ label: allLabel, value: 'all' }, ...recordOptions] : recordOptions;

  return sorted ? options.sort(sortByLabel) : options;
};
