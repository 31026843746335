import {
  Box,
  Button,
  ModalBody,
  ModalFooter,
  TextArea,
  useToast,
} from '@localyze-pluto/components';
import React, { useState } from 'react';
import { DisclosureStore } from '@ariakit/react';
import { useCreateMessage } from 'modules/talent/api/useCreateMessage/useCreateMessage';
import { CharacterCounter } from '../../../TemplatesDropdown/CharacterCounter';

export type PostNewMessageProps = {
  modalState: DisclosureStore;

  caseId: number;
};

const MESSAGE_CHAR_LIMITS = 500;

export const PostNewMessage = ({ caseId, modalState }: PostNewMessageProps): React.ReactElement => {
  const toast = useToast();
  const [message, setMessage] = useState('');

  const { mutate: createMessage } = useCreateMessage({
    onSuccess: () => {
      toast('New Message was created successfully.', 'success');
      modalState.hide();
    },
    onError: (error) => {
      const toastMessage = error.message || 'Error creating new Message.';

      toast(toastMessage, 'error');
    },
    internalMessage: false,
  });

  const onChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const message = ev.target.value;
    setMessage(message);
  };

  const handlePostOnClick = () => {
    createMessage({
      caseId,
      body: message,
      internal: false,
    });
  };

  return (
    <>
      <ModalBody disableOverflow>
        <TextArea
          maxLength={MESSAGE_CHAR_LIMITS}
          onChange={onChange}
          placeholder="Write something here..."
          value={message}
        />
        <Box.div
          color="colorIconWeaker"
          display="flex"
          fontSize="fontSize20"
          justifyContent="flex-end"
        >
          <Box.div paddingTop="p1">
            <CharacterCounter limit={MESSAGE_CHAR_LIMITS} value={message} />
          </Box.div>
        </Box.div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!message.length} onClick={handlePostOnClick} variant="primary">
          Post message
        </Button>
      </ModalFooter>
    </>
  );
};
