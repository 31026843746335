import {
  Box,
  Heading,
  Tr,
  Text,
  TBody,
  Td,
  BoxProps,
  Button,
  Avatar,
} from '@localyze-pluto/components';
import React from 'react';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { GlomoRequestType } from 'modules/glomoRequests/api/types/GlomoRequestType';

const TableRow = ({ question, answer }: { question: string; answer: string }) => {
  return (
    <Tr>
      <Td color="contentSecondary" fontSize="fontSize20" px="d6" w="50%">
        <Text.span>{question}</Text.span>
      </Td>
      <Td color="colorTextStrongest" fontSize="fontSize20">
        <Text.span>{answer}</Text.span>
      </Td>
    </Tr>
  );
};

export const RequestSummary = ({
  fullName,
  isEU,
  tripStartDate,
  tripEndDate,
  tripDestinationCountry,
  onClickHeaderButton,
  type,
}: {
  fullName: string;
  tripStartDate: string;
  tripEndDate: string;
  tripDestinationCountry: string;
  isEU: boolean;
  onClickHeaderButton: () => void;
  type: GlomoRequestType;
}): React.JSX.Element => {
  const formattedStartDate = formatDate(`${tripStartDate}T12:00:00.000Z`, 'dd.MM.yyyy');
  const formattedEndDate = formatDate(`${tripEndDate}T12:00:00.000Z`, 'dd.MM.yyyy');
  const formattedDate = `${formattedStartDate} - ${formattedEndDate}`;
  const periodQuestion = `${type === 'WorkationAssessment' ? 'Workation' : 'Business Trip'} period`;

  return (
    <SectionCard>
      <SectionCard.Header padding={'d4 d6' as BoxProps['padding']}>
        <Box.div
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding="d0"
          zIndex="zIndex10"
        >
          <Heading as="h3" color="contentPrimary" marginBottom="d0" size="title-subsection">
            Request summary
          </Heading>
          <Button leadingIcon="info" onClick={onClickHeaderButton} variant="ghost">
            View full request
          </Button>
        </Box.div>
      </SectionCard.Header>
      <SectionCard.Content padding="d0">
        <Box.div w="100%">
          <Box.table w="100%">
            <TBody>
              <Tr key="talent">
                <Td color="contentSecondary" fontSize="fontSize20" px="d6" w="50%">
                  <Text.span>Talent</Text.span>
                </Td>
                <Td color="colorTextStrongest" fontSize="fontSize20" fontWeight="fontWeightBold">
                  <Avatar name={fullName} showName size="small" />
                </Td>
              </Tr>
              <TableRow answer={formattedDate} question={periodQuestion} />
              <TableRow answer={tripDestinationCountry} question="Destination country" />
              <TableRow
                answer={isEU ? 'Yes' : 'No'}
                question="Is the destination country in the EU?"
              />
            </TBody>
          </Box.table>
        </Box.div>
      </SectionCard.Content>
    </SectionCard>
  );
};
