import axios from 'axios';
import { VariablePayload } from 'modules/variableEditor/api/useCreateVariable';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { INTERNAL_VARIABLES_QUERY_KEY } from 'modules/variableEditor/api/useVariables';

type UpdateVariableProps = {
  id: number;
  params: Partial<VariablePayload>;
};

const updateVariable = async ({ id, params }: UpdateVariableProps): Promise<void> => {
  await axios.put(`/admin/internal_variables/${id}`, params);
};

export const useUpdateVariable = ({
  onError,
  onSuccess,
}: {
  onError?: (error: AxiosErrorWithMessage) => void;
  onSuccess?: () => void;
}): UseMutationResult<void, AxiosErrorWithMessage, UpdateVariableProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateVariable,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [INTERNAL_VARIABLES_QUERY_KEY],
      });

      onSuccess?.();
    },
    onError,
  });
};
