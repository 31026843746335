import React from 'react';
import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Autocomplete } from 'deprecated/components/Chewpaca/SelectMenu/components/Autocomplete';

export default function FormAutoComplete({ name, label, options, ...props }) {
  return (
    <>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          const selected = options.find((option) => option.value === value);

          return (
            <Autocomplete
              options={options}
              selected={selected}
              onSelect={({ value }) => setFieldValue(name, value)}
              label={label}
              {...props}
            />
          );
        }}
      </Field>
      <ErrorWrapper>
        <ErrorMessage name={name} />
      </ErrorWrapper>
    </>
  );
}

const ErrorWrapper = styled.div`
  color: var(--color-red);
  font-size: 14px;
`;

FormAutoComplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  type: PropTypes.string,
};
