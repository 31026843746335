export type ReferenceType = 'checkbox' | 'datetime' | 'document' | 'information' | 'link' | 'text';

export const VARIABLE_TYPE_OPTIONS: { label: string; value: ReferenceType }[] = [
  {
    label: 'Document',
    value: 'document',
  },
  {
    label: 'Link',
    value: 'link',
  },
  {
    label: 'Information',
    value: 'information',
  },
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
];
