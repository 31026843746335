import { Box } from '@localyze-pluto/components';
import React from 'react';
import { GLOMO_REQUEST_RISK_COLOR } from 'modules/glomoRequests/constants';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';

export const GLOMO_REQUEST_RISK_LEVEL_TEXT: Record<GlomoRequestRisk, string> = {
  low: 'Low risk',
  medium: 'Medium risk',
  high: 'High risk',
};

export const RiskLevelBadge = ({
  riskLevel,
}: {
  riskLevel: GlomoRequestRisk;
}): React.JSX.Element => {
  return (
    <Box.div
      alignItems="center"
      backgroundColor={GLOMO_REQUEST_RISK_COLOR[riskLevel].background}
      borderRadius="borderRadiusPill"
      display="inline-flex"
      marginRight="d2"
      px="d4"
      py="d1_5"
    >
      <Box.span
        backgroundColor={GLOMO_REQUEST_RISK_COLOR[riskLevel].dot}
        borderRadius="borderRadiusCircle"
        h={8}
        marginRight="m2"
        w={8}
      />
      <Box.span
        color="badgeContentNeutral"
        fontSize="fontSize20"
        lineHeight="lh5"
        style={{ whiteSpace: 'nowrap' }}
      >
        {GLOMO_REQUEST_RISK_LEVEL_TEXT[riskLevel]}
      </Box.span>
    </Box.div>
  );
};
