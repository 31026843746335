import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { filter, find, isUndefined } from 'lodash';

import { CaseEvaluationSummarySection } from '../../types/CaseEvaluationSummarySection';
import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { Macro } from '../../types/Macro';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { useParams } from 'react-router-dom';
import { useCase } from 'modules/cases/api/useCase/useCase';

export const useMacros = ({
  section,
}: {
  section: CaseEvaluationSummarySection;
}): UseQueryResult<Macro[]> => {
  const contentfulAPI = new ContentfulAPI();
  const { caseId } = useParams<{ caseId: string }>();

  const { data: kase, isSuccess: isSuccessCase } = useCase({ caseId: Number(caseId) });
  const { data: countries } = useCountries({});

  const destinationCountry = find(countries, { id: kase?.destination_country_id });
  const originCountry = find(countries, { id: kase?.origin_country_id });

  return useQuery({
    queryKey: ['case-evaluation-summary-macros', destinationCountry?.name, originCountry?.name],
    queryFn: () =>
      contentfulAPI.fetchCaseEvaluationSummaryMacros({
        destinationCountry: destinationCountry?.name,
        originCountry: originCountry?.name,
      }),
    enabled: !isUndefined(destinationCountry) && !isUndefined(originCountry) && isSuccessCase,
    select: (macros) => filter(macros, { section }),
  });
};
