import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormInput,
  HelpText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { useAddAllowanceFunding } from 'deprecated/api/allowance_fundings/useAddAllowanceFunding/useAddAllowanceFunding';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formatPriceCents } from 'utils/formatters/formatPrice/formatPrice';
import { parseNumberFromString } from 'utils/formatters/parseNumberFromString/parseNumberFromString';
import * as Yup from 'yup';

type EditAllowanceFormTypes = {
  allowanceAmount: string;
};

const validationSchema: Yup.ObjectSchema<EditAllowanceFormTypes> = Yup.object({
  allowanceAmount: Yup.string().required('This field is required'),
});

export const EditAllowanceModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const {
    total_allowance_fundings_cents: totalAllowanceFundingsCents,
    total_remaining_allowance_cents: totalRemainingAllowanceCents,
    price_currency: currency,
  } = kase;
  const totalAllowanceFundings = totalAllowanceFundingsCents / 100;
  const toast = useToast();
  const { mutate: addAllowanceFunding, isPending: isPendingAddAllowanceFunding } =
    useAddAllowanceFunding({
      onSuccess: () => {
        toast('Allowance updated successfully', 'success');
        modal.hide();
      },
      onError: () => toast('Allowance could not be updated', 'error'),
    });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { allowanceAmount: '' },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditAllowanceFormTypes> = (values) => {
    const amountDifference = parseNumberFromString(values.allowanceAmount) - totalAllowanceFundings;

    addAllowanceFunding({
      caseId: kase.id,
      amount: amountDifference,
      currency,
    });
  };

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Allowance</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form id="edit-allowance-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            id="allownaceAmount"
            label="Allowance amount"
            name="allowanceAmount"
            onChange={(event) => {
              const formattedValue = parseNumberFromString(event.target.value, 5)
                .toLocaleString(undefined, {
                  style: 'currency',
                  currency,
                  minimumFractionDigits: 0,
                })
                .replace(/^[^\d]+/, (match) => `${match} `);

              setValue('allowanceAmount', formattedValue);
            }}
            required
            type="text"
          />
          <HelpText>{formatPriceCents(totalRemainingAllowanceCents, currency)} remaining</HelpText>
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isPendingAddAllowanceFunding}
          form="edit-allowance-form"
          loading={isPendingAddAllowanceFunding}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
