import React, { useEffect } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { trackPageView } from 'modules/analytics/analytics';
import { CaseOverviewDashboard } from 'modules/caseOverview/pages/CaseOverviewDashboard';
import { useCurrentUser } from 'config/CurrentUserContext';

export const HomePage = (): React.JSX.Element => {
  const user = useCurrentUser();
  const firstName = user.first_name;

  useEffect(() => {
    trackPageView('Home');
  }, []);

  const title = `Hello, ${firstName}! 👋`;
  const subtitle = "Here is what's important for you today.";

  return (
    <PageLayout
      flexed
      maxW={1000}
      mx="auto"
      px="d8"
      subTitle={subtitle}
      title={title}
      titleSize="title-display"
    >
      <CaseOverviewDashboard />
    </PageLayout>
  );
};
