import { Provider as RollbarProvider } from '@rollbar/react';

import { GlobalMobility, LocationAssessment } from 'deprecated/pages/viewsNew/LandingPages';
import {
  BaseStyles as PlutoBaseStyles,
  GlobalStyles as PlutoGlobalStyles,
  ThemeProvider as PlutoThemeProvider,
  theme as plutoTheme,
} from '@localyze-pluto/theme';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Auth0ProviderWithHistory } from 'modules/auth/components/Auth0ProviderWithHistory';
import { FeatureFlagsProvider } from 'modules/featureFlags/FeatureFlagsProvider';
import { ToastContainer as PlutoToastContainer } from '@localyze-pluto/components';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import UnderMaintenance from './deprecated/pages/views/maintenance/UnderMaintenance';
import { queryClient } from './config/queryClient';
import { rollbarConfig } from 'config/rollbar/rollbarConfig';
import { UserAuthManager } from 'config/UserAuthManager';
import { AppLayout } from 'modules/layout/components/AppLayout';

export const App = (): React.JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <RollbarProvider config={rollbarConfig}>
        <GlobalStyles />
        <PlutoThemeProvider theme={plutoTheme}>
          <PlutoGlobalStyles />
          <PlutoBaseStyles />
          <PlutoToastContainer>
            <Router>
              <Auth0ProviderWithHistory>
                <UserAuthManager>
                  <FeatureFlagsProvider>
                    <Switch>
                      <Route exact path="/under-maintenance" render={() => <UnderMaintenance />} />
                      <Route path="/globalmobility" render={() => <GlobalMobility />} />
                      <Route path="/location-assessment" render={() => <LocationAssessment />} />

                      {/* TODO: Delete it when we release Business Trips and Requests */}
                      <Redirect exact from="/workation-requests" to="/requests" />
                      <Redirect from="/workation-requests/:id" to="/requests/:id" />

                      {/* The following redirect is needed to prevent the user from
                       using the old login (if bookmarked, for example) and seeing
                       a blank screen.
                       TODO: Remove it when we find a better solution
                       for not found pages in this app */}
                      <Route path={['/app/login', '/sso']}>
                        <Redirect to="/" />
                      </Route>
                      <AppLayout />
                    </Switch>
                  </FeatureFlagsProvider>
                </UserAuthManager>
              </Auth0ProviderWithHistory>
            </Router>
          </PlutoToastContainer>
        </PlutoThemeProvider>
      </RollbarProvider>
    </QueryClientProvider>
  );
};
