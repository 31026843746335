import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';

const AUTOMATICALLY_UPDATED_OPTIONS: FilterOption<string>[] = [
  {
    filterAttribute: 'automatically_updated',
    label: 'All',
    userTypes: ['admin'],
    value: '',
  },
  {
    filterAttribute: 'automatically_updated',
    label: 'Yes',
    userTypes: ['admin'],
    value: 'true',
  },
  {
    filterAttribute: 'automatically_updated',
    label: 'No',
    userTypes: ['admin'],
    value: 'false',
  },
];

export const AUTOMATICALLY_UPDATED_FILTER: Filter<string> = {
  attribute: 'automatically_updated',
  label: 'Case has been automatically updated',
  options: AUTOMATICALLY_UPDATED_OPTIONS,
  type: 'singleselect',
  userTypes: ['admin'],
};
