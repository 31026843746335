import { sortByLabel } from 'utils/sortByLabel';
import { CaseMetricEntry } from 'modules/reporting/api/types/CaseMetrics';

const percentage = (count: number, totalCount: number) => count / totalCount;

const FIVE_PERCENT = 0.05;

export const getFilteredChartEntries = (data: CaseMetricEntry[]): CaseMetricEntry[] => {
  const sortedData = data.sort(sortByLabel);
  const totalCount = sortedData.reduce((sum, row) => sum + row.count, 0);

  const entriesWithFivePercentOrMore = sortedData.filter(
    (row) => percentage(row.count, totalCount) >= FIVE_PERCENT,
  );

  const otherEntries = sortedData.filter((row) => percentage(row.count, totalCount) < FIVE_PERCENT);

  return [
    ...entriesWithFivePercentOrMore,
    ...(otherEntries.length === 0
      ? []
      : [
          {
            label: 'Other',
            count: otherEntries.reduce((sum, row) => sum + row.count, 0),
          },
        ]),
  ];
};
