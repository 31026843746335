import React from 'react';

export const CharacterCounter = ({
  value,
  limit,
}: {
  value: string;
  limit: number;
}): React.JSX.Element => {
  const hasError = value.length === limit;

  return (
    <div className={hasError ? 'tw-text-red-500' : ''}>
      {value.length}/{limit}
    </div>
  );
};
