import React, { useState } from 'react';
import { Select } from '@localyze-pluto/components';
import { TaskTemplate } from 'modules/caseDetails/components/CaseActivityCard/TemplatesDropdown/types/TaskTemplate';
import { StatusUpdateTemplate } from './types/StatusUpdateTemplate';

export type Template = StatusUpdateTemplate | TaskTemplate;

type Props = {
  onChange: (value: Template) => void;
  templates?: StatusUpdateTemplate[] | TaskTemplate[];
  isLoading?: boolean;
};

export const TemplatesDropdown = ({ onChange, templates, isLoading }: Props): React.JSX.Element => {
  const [templateId, setTemplateId] = useState<string>('');

  const onSelect = (value: string) => {
    const selectedTemplate = templates?.find((item) => item.sys.id === value);
    if (!selectedTemplate) return;

    setTemplateId(selectedTemplate.sys.id);
    onChange(selectedTemplate);
  };

  const items = templates?.map((item) => ({ label: item.title, value: item.sys.id }));
  const noTemplates = templates === undefined || templates.length === 0;

  return (
    <Select
      aria-label="Select template"
      disabled={noTemplates || isLoading}
      items={items || []}
      placeholder={isLoading ? 'Loading...' : 'Template'}
      sameWidth={false}
      setValue={onSelect}
      value={templateId}
    />
  );
};
