import { OverviewCard } from 'modules/caseOverview/components/OverviewCard/OverviewCard';
import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button } from '@localyze-pluto/components';
import { useTasks } from 'modules/tasks/api/useTasks/useTasks';
import { Task as TaskType } from 'modules/tasks/types/Task';
import { AllItemsLink } from 'modules/caseOverview/components/AllItemsLink';
import { isEmpty } from 'lodash';
import { ItemCard } from 'components/ItemCard/ItemCard';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { Link } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';

export const MyTasks = (): React.JSX.Element => {
  const { id, user_role, company_id } = useCurrentUser();
  const routes = useRoutes();

  const { data, isSuccess } = useTasks({
    open: true,
    hr_manager_id: id,
    all_items: true, // avoid pagination, assuming no HR manager has so many tasks
  });
  const allTasks = useMemo(() => data?.tasks || [], [data]);
  const count = allTasks.length;

  const [tasks, setTasks] = useState<TaskType[]>([]);

  const showMore = () => {
    trackEvent('cases dashboard: click on show more', {
      user_role,
      company_id,
      hr_dashboard_section: 'waiting cases',
    });

    setTasks(allTasks.slice(0, tasks.length + 3));
  };

  useEffect(() => {
    setTasks(allTasks.slice(0, 3));
  }, [setTasks, allTasks]);

  return (
    <OverviewCard
      count={count}
      emptyStateIcon="check-square"
      emptyStateText="There are no open tasks."
      gridColumn="1 / span 2"
      hasShowMore={allTasks.length > 0 && tasks.length < allTasks.length}
      headerRightItem={
        <AllItemsLink
          label="All tasks"
          onClick={() =>
            trackEvent('cases dashboard: click on all tasks', {
              user_role,
              company_id,
              hr_dashboard_section: 'my tasks',
            })
          }
          path={`${routes.tasks.url}?tab=all-tasks`}
        />
      }
      iconCategory="check"
      onClickShowMore={showMore}
      title="My tasks"
    >
      {isSuccess && !isEmpty(tasks) && (
        <Box.ul display="flex" flexDirection="column" gap="d4" paddingLeft="p0">
          {tasks.map((task) => {
            const talent = task.associations.international;

            return (
              <ItemCard key={task.id}>
                <ItemCard.Content gap="d4">
                  <Box.div alignItems="center" display="flex">
                    <Avatar
                      name={fullName(talent)}
                      showName
                      size="small"
                      src={talent.avatar_url || undefined}
                    />
                    <Box.span
                      color="contentSecondary"
                      fontSize="fontSize10"
                      fontWeight="fontWeightRegular"
                      marginLeft="p4"
                    >
                      Case type: {task.associations.case_type.name} | Task created on:{' '}
                      {formatDate(task.created_at)}
                    </Box.span>
                  </Box.div>
                  <Box.div>{task.description}</Box.div>
                </ItemCard.Content>
                <ItemCard.Action>
                  <Button
                    as={Link}
                    onClick={() => {
                      trackEvent('cases dashboard: click on all tasks', {
                        user_role,
                        company_id,
                        hr_dashboard_section: 'my tasks',
                      });
                    }}
                    to={routes.case.get(task.case_id)}
                    variant="ghost"
                  >
                    View task
                  </Button>
                </ItemCard.Action>
              </ItemCard>
            );
          })}
        </Box.ul>
      )}
    </OverviewCard>
  );
};
