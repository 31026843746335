import { AccountEntry } from 'modules/billing/types/AccountEntry';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Box, Button } from '@localyze-pluto/components';
import React from 'react';

export const TalentLink = ({
  invoiceable,
}: {
  invoiceable: AccountEntry['invoiceable'];
}): React.JSX.Element => {
  const routes = useRoutes();

  if (!invoiceable.talent_id) return <Box.span>-</Box.span>;

  return (
    <Button as="a" href={routes.talentProfile.get(invoiceable.talent_id)} variant="ghost">
      {invoiceable.talent_name}
    </Button>
  );
};
