import { TBody, THead, Table, Td, Th, Tr } from '@localyze-pluto/components';

import { DocumentToTranslate } from 'modules/pipelines/pages/caseEvaluationSummary/types/DocumentToTranslate';
import React from 'react';
import includes from 'lodash/includes';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';

const CheckIcon = ({ label }: { label: string }): React.JSX.Element => (
  <span aria-label={label} role="img">
    &#9989;
  </span>
);

type DocumentRequirementsProps = {
  documents: Maybe<DocumentToTranslate[]>;
};

export const DocumentRequirementsTable = ({
  documents,
}: DocumentRequirementsProps): React.JSX.Element => (
  <Table bordered>
    <THead>
      <Tr>
        <Th>Document</Th>
        <Th textAlign="center">Translation</Th>
        <Th textAlign="center">Legalization/apostille</Th>
        <Th>Accepted languages</Th>
      </Tr>
    </THead>
    <TBody>
      {map(documents, (document) => (
        <Tr key={uniqueId('document')}>
          <Td>{document.name}</Td>
          <Td textAlign="center">
            {includes(document.type, 'Translation') && <CheckIcon label="Translation required" />}
          </Td>
          <Td textAlign="center">
            {includes(document.type, 'Legalization/Apostille') && (
              <CheckIcon label="Legalization/apostille required" />
            )}
          </Td>
          <Td>{document.acceptedLanguages.join(', ')}</Td>
        </Tr>
      ))}
    </TBody>
  </Table>
);
