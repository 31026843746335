import React from 'react';
import { Search } from '@localyze-pluto/components';

type ReportingTableSearchProps = {
  onSearch: (value: string) => void;
};

/**
 * Lets a user quickly filter the table with a full table text search
 * @param props.onSearch - executes on search of text
 */
export const ReportingTableSearch = ({
  onSearch,
}: ReportingTableSearchProps): React.JSX.Element => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onSearch(event.target.value);
  };

  return <Search data-testid="report-search" onChange={onChange} placeholder="Search table" />;
};
