import { Box, BoxProps, Heading, HeadingProps } from '@localyze-pluto/components';
import { HeaderMenu } from './HeaderMenu';
import React from 'react';
import { TalentProfileSearch } from '../TalentProfileSearch/TalentProfileSearch';

type HeaderProps = {
  title?: string;
  titleSize: HeadingProps['size'];
  px?: BoxProps['px'];
  subTitle?: string;
  children?: React.JSX.Element;
  titleButton?: React.JSX.Element;
  maxW?: BoxProps['maxW'];
};

export const Header = ({
  title,
  titleSize,
  subTitle,
  children,
  titleButton,
  maxW,
  px,
}: HeaderProps): React.JSX.Element => (
  <Box.header
    alignItems="flex-end"
    alignSelf="center"
    display="flex"
    h="auto"
    justifyContent="space-between"
    marginBottom="m4"
    maxW={maxW}
    px={px}
    py="p8"
    w="100%"
  >
    <div>
      {(title || titleButton) && (
        <Box.div display="flex" flexDirection="row">
          <Box.div alignSelf="center">
            {title && (
              <Heading as="h1" color="colorTextStrongest" marginBottom="m0" size={titleSize}>
                {title}
              </Heading>
            )}
          </Box.div>
          {titleButton && <Box.div paddingLeft="p4">{titleButton}</Box.div>}
        </Box.div>
      )}
      {subTitle && (
        <Box.div
          alignSelf="center"
          className="tw-leading-5"
          color="contentSecondary"
          fontSize="fontSize30"
          fontWeight="fontWeightMedium"
          lineHeight="lineHeight40"
          paddingTop="p2"
        >
          {subTitle}
        </Box.div>
      )}
      <Box.div alignSelf="center">{children}</Box.div>
    </div>

    <Box.div
      alignItems="center"
      alignSelf="flex-start"
      display="flex"
      flex={1}
      gap="d4"
      justifyContent="end"
    >
      <Box.div backgroundColor="bgError" maxWidth={{ _: 512, lg: 320 }} w="100%">
        <TalentProfileSearch />
      </Box.div>
      <HeaderMenu />
    </Box.div>
  </Box.header>
);
