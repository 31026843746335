import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import axios from 'axios';
import { User } from 'deprecated/api/user/types/User';
import { UserUpdateProps } from './useUpdateUser.types';
import { queryClient } from 'config/queryClient';
import { USER_QUERY_KEY } from '../useUser/useUser';
import { CURRENT_USER_QUERY_KEY } from 'modules/users/api/useCurrentUserQuery/useCurrentUserQuery';
import { useCurrentUser } from 'config/CurrentUserContext';

/**
 * Makes a PUT request to the users endpoint
 * @param userUpdates - info about the user to be updated
 */
const updateUser = async (userUpdates: UserUpdateProps): Promise<User> => {
  if (!userUpdates.id) throw new Error('User id is required to update user');

  const { data } = await axios.put<User>(`/users/${userUpdates.id}`, {
    ...userUpdates,
  });

  return data;
};

/**
 * Update user
 * @param onSuccess - Callback to be executed when the mutation is successful
 */
export const useUpdateUser = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<User, AxiosErrorWithMessage, UserUpdateProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<User, AxiosErrorWithMessage, UserUpdateProps> => {
  const { id } = useCurrentUser();

  return useMutation({
    mutationFn: updateUser,
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY, String(data.id)] }),
        ...(id === data.id
          ? [queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] })]
          : []),
      ]);

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
