import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';
import { FilterSelector } from 'modules/filters/FilterSelector';
import React from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { useCurrentUser } from 'config/CurrentUserContext';

const INITIAL_PAGE = 1;

type Props = {
  availableFilters: Maybe<Filter<string>[]>;
};

export const TasksFilters = ({ availableFilters }: Props): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const { queryParams, removeQueryParam, updateQueryParams } = useQueryParams();

  const getSelectorProps = (attribute: string) => {
    const filter = availableFilters?.find(
      (filter) => filter.attribute === attribute,
    ) as Filter<string>;

    const selectedOption = filter.options.find((option) => option.value === queryParams[attribute]);

    const onSelectorChange = ({ value }: FilterOption<string>) => {
      if (value === 'all') {
        removeQueryParam(attribute);
      } else {
        updateQueryParams({ ...queryParams, [attribute]: value, page: String(INITIAL_PAGE) });
      }
      trackEvent('my tasks: filter tasks by company');
    };

    return { filter, onChange: onSelectorChange, placeholder: filter.label, selectedOption };
  };

  return (
    <div className="tw-flex tw-space-x-2">
      {availableFilters && isAdmin && (
        <FilterSelector
          className="tw-w-60"
          {...getSelectorProps('company_id')}
          placeholder="Company"
        />
      )}
    </div>
  );
};
