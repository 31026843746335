import React from 'react';
import { LinkToTalentCell } from './LinkToTalentCell';
import { Avatar, Box } from '@localyze-pluto/components';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import { Talent } from 'modules/talent/types/Talent';

export const AvatarAndNameCell = ({ talent }: { talent: Talent }): React.JSX.Element => {
  const talentName = fullName(talent);

  return (
    <LinkToTalentCell talentId={talent.id} w="210px">
      <Box.div alignItems="center" display="flex">
        <Box.div paddingRight="d1_5">
          <Avatar name={talentName} showName size="small" src={talent.avatar_url || undefined} />
        </Box.div>
      </Box.div>
    </LinkToTalentCell>
  );
};
