export enum Sizes {
  Small,
  Large,
}

export enum Status {
  Incomplete = 0,
  Current = 1,
  Complete = 2,
}
