import { ColDef } from 'ag-grid-community';
import { parseISO } from 'date-fns';

export const agGridDateFilter: Pick<ColDef, 'filter' | 'filterParams'> = {
  filter: 'agDateColumnFilter',
  filterParams: {
    browserDatePicker: true,
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string | null): number => {
      const cellValueIsLessThanFilterDate = -1;
      const cellValueIsSameAsFilterDate = 0;
      const cellValueIsGreaterThanFilterDate = 1;
      if (cellValue === null) {
        return cellValueIsSameAsFilterDate;
      }

      const cellDate = parseISO(cellValue);

      if (cellDate < filterLocalDateAtMidnight) {
        return cellValueIsLessThanFilterDate;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return cellValueIsGreaterThanFilterDate;
      }
      return cellValueIsSameAsFilterDate;
    },
    filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
    inRangeFloatingFilterDateFormat: 'DD/MM/YYYY',
  },
};
