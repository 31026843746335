import { getPreviewDownloadDocUrl } from 'deprecated/utils/helper/international/getPreviewDownloadDocUrl';
import axios from 'axios';

export const postDocument = async (internalReferenceCode, file, callbackSuccess, caseId) => {
  const formData = new FormData();
  formData.append('document[file]', file);
  formData.append('document[internal_reference_code]', internalReferenceCode);
  formData.append('case_id', caseId);

  try {
    await axios.post('/documents', formData);
    const mockedResponse = {
      data: {
        url: getPreviewDownloadDocUrl(internalReferenceCode, caseId),
      },
    };
    callbackSuccess(mockedResponse);
    return mockedResponse;
  } catch (e) {
    const { message } = e.response.data;
    throw new Error(`Something went wrong. ${message}`);
  }
};

export const deleteDocument = async (internalReferenceCode, callbackSuccess, caseId) => {
  try {
    const response = await axios.delete(`/documents/${internalReferenceCode}`, {
      params: { case_id: caseId },
    });
    callbackSuccess(response);
  } catch (e) {
    throw new Error('Something is wrong with our services. Hold on there or send us an email');
  }
};

export const getDocuments = async (internalReferenceCodes, caseId) => {
  // internalReferenceCodes = null => BE returns all.
  const params = {
    case_id: caseId,
    internal_reference_codes: internalReferenceCodes,
  };

  try {
    return axios.get('/documents', { params });
  } catch (e) {
    throw new Error('Something is wrong with our services. Hold on there or send us an email');
  }
};
