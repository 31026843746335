import {
  DocsContainerInit,
  DocsContainerReducer,
} from 'deprecated/store/reducers/DocumentsContainerReducer';
import React, { useReducer } from 'react';

import { DocsContainerStore } from 'deprecated/store/root';

// eslint-disable-next-line react/display-name
export const withDocsProvider = (Component) => (props) => {
  const [docContainerState, dispatchDocsContainer] = useReducer(
    DocsContainerReducer,
    DocsContainerInit,
  );

  return (
    <div>
      <DocsContainerStore.Provider
        value={{ state: docContainerState, dispatch: dispatchDocsContainer }}
      >
        <Component {...props} />
      </DocsContainerStore.Provider>
    </div>
  );
};

export default withDocsProvider;
