import {
  Box,
  BoxProps,
  Heading,
  Tab as PlutoTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import React, { forwardRef, ReactNode } from 'react';
import { CardWithTabsProvider, useCardWithTabsContext } from './CardWithTabsProvider';
import { Tab } from 'types/Tab';
import { useCurrentUser } from 'config/CurrentUserContext';
import { filterByUserType } from 'deprecated/utils/helper/filterByUserType';

type CardWithTabsProps = {
  children: ReactNode;
  tabs: Tab<string>[];
  h?: BoxProps['h'];
  maxH?: BoxProps['maxH'];
  flexed?: boolean;
};

type CardWithTabsHeaderProps = {
  heading: ReactNode;
  tabListAriaLabel: string;
  action?: ReactNode;
  h?: BoxProps['h'];
};

type CardWithTabsContentProps = {
  padding?: BoxProps['padding'];
  maxH?: BoxProps['maxH'];
};

const CardWithTabsHeader = forwardRef<HTMLDivElement, CardWithTabsHeaderProps>(
  ({ heading, tabListAriaLabel, action, h }, ref) => {
    const tabs = useCardWithTabsContext();

    return (
      <SectionCard.Header h={h} paddingBottom="d0" ref={ref}>
        <Box.div
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          marginBottom="m4"
        >
          <Box.div display="flex" gap="d2">
            {typeof heading === 'string' ? (
              <Heading color="colorTextStrongest" marginBottom="m0" size="title-subsection">
                {heading}
              </Heading>
            ) : (
              heading
            )}
          </Box.div>
          {action && (
            <Box.div display="flex" justifyItems="flex-end">
              {action}
            </Box.div>
          )}
        </Box.div>
        <TabList aria-label={tabListAriaLabel}>
          {tabs.map((tab) => (
            <PlutoTab id={tab.key} key={tab.key}>
              {tab.name}
            </PlutoTab>
          ))}
        </TabList>
      </SectionCard.Header>
    );
  },
);

const CardWithTabsContent = ({ padding, maxH }: CardWithTabsContentProps) => {
  const tabs = useCardWithTabsContext();

  return (
    <SectionCard.Content maxH={maxH} padding={padding}>
      <TabPanels>
        {tabs.map((tab, index) => {
          if (!tab.component) {
            return null;
          }

          return (
            <TabPanel
              key={index}
              style={{
                width: '100%',
                height: '100%',
              }}
              tabId={tab.key}
              unmountOnHide
            >
              {tab.component}
            </TabPanel>
          );
        })}
      </TabPanels>
    </SectionCard.Content>
  );
};

const CardWithTabs = ({
  h,
  maxH,
  children,
  tabs,
  flexed = false,
}: CardWithTabsProps): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();

  if (!children) {
    return <></>;
  }

  const filteredTabs = tabs.filter((tab) =>
    tab.userTypes ? filterByUserType({ userTypes: tab.userTypes }, isAdmin) : true,
  );

  return (
    <CardWithTabsProvider tabs={filteredTabs}>
      <SectionCard flexed={flexed} h={h} maxH={maxH}>
        <Tabs initialTabId={tabs[0].key}>{children}</Tabs>
      </SectionCard>
    </CardWithTabsProvider>
  );
};

CardWithTabsHeader.displayName = 'CardWithTabsHeader';

CardWithTabs.Header = CardWithTabsHeader;
CardWithTabs.Content = CardWithTabsContent;

export { CardWithTabs };
