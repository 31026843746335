import { format, isValid } from 'date-fns';

import type { UpdateItemProps } from '../types/UpdateItemProps';
import React from 'react';
import { Box, Button, Paragraph } from '@localyze-pluto/components';

export const waitingUntilText = (waitingUntil: string): string =>
  `Waiting until ${format(new Date(waitingUntil), 'dd.MM.yyyy')}: `;

export const UpdateItem = ({
  id,
  date,
  message,
  type = 'default',
  onDelete,
  className,
  waitingUntil,
  isFirstUpdate,
  isLast,
  ...rest
}: UpdateItemProps): React.JSX.Element => (
  <Box.div
    as="li"
    className={className}
    display="flex"
    marginBottom="m1"
    minHeight="3rem"
    {...rest}
  >
    <Box.div alignItems="center" display="flex" flexDirection="column" marginRight="m3">
      <Box.div paddingBottom="p1">
        <Box.div
          backgroundColor="contentPrimary"
          border="2px"
          borderColor="borderSecondary"
          borderRadius="borderRadius40"
          borderStyle="borderStyleSolid"
          h="8px"
          marginTop="m1"
          w="8px"
        />
      </Box.div>
      {!isLast ? <Box.div backgroundColor="colorBorderWeakest" h="100%" w="0.125rem" /> : null}
    </Box.div>
    <Box.div
      backgroundColor={type === 'blocker' ? 'bgError' : 'colorBackground'}
      borderRadius={type === 'blocker' ? 'borderRadius20' : 'borderRadius0'}
      data-testid="feed-update-content-container"
      display="flex"
      justifyContent="space-between"
      paddingBottom="p1"
      w="100%"
    >
      <Box.div color="colorTextHeading" margin="m0" w={onDelete ? 'calc(11/12*100%)' : '100%'}>
        <Paragraph
          color="colorBackgroundStrongest"
          fontSize="fontSize10"
          lineHeight="lineHeight10"
          marginBottom="d0"
        >
          {isValid(date) && format(new Date(date), 'dd. MMMM yyyy')}
        </Paragraph>
        {waitingUntil && (
          <Paragraph
            data-testid="waiting-until-date"
            fontSize="fontSize20"
            fontWeight={isFirstUpdate ? 'fontWeightBold' : 'fontWeightMedium'}
            lineHeight="lineHeight30"
            marginBottom="d0"
          >
            {waitingUntilText(waitingUntil)}
          </Paragraph>
        )}
        <Paragraph fontFamily="fontFamilyNotoSans" fontSize="fontSize20" lineHeight="lineHeight40">
          {message}
        </Paragraph>
      </Box.div>
      {onDelete && (
        <Box.div alignItems="start" display="flex" minWidth="max-content">
          <Button
            aria-label="Delete update"
            data-testid="feed-update-delete-button"
            iconOnly
            id={id}
            leadingIcon="x"
            onClick={() => onDelete(id)}
            variant="ghost"
          />
        </Box.div>
      )}
    </Box.div>
  </Box.div>
);
