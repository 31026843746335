import {
  Box,
  Button,
  ConfirmationModal,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { EmptyState } from 'components/EmptyState/EmptyState';
import React, { useState } from 'react';
import { TaskItem } from './TaskItem';
import { Task } from 'modules/tasks/types/Task';
import ProgressStepWithDocs from 'deprecated/pages/viewsNew/common/ProgressStep/ProgressStep';
import { useTalentQuery } from 'modules/talent/api/useTalentQuery/useTalentQuery';
import { useCaseStepSubmission } from 'modules/caseDetails/hooks/useCaseStepSubmission';
import { useManageTasks } from 'modules/caseDetails/hooks/useManageTasks';
import { useParams } from 'react-router-dom';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { useCurrentUser } from 'config/CurrentUserContext';
import { VerifyIntegrationModal } from 'modules/integrations/components/VerifyIntegrationModal/VerifyIntegrationModal';
import { PostNewTask } from './PostNewTask';

export const Tasks = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const caseId = Number(id);
  const toast = useToast();

  const { data: talentCase, isPending: isPendingCase } = useCase({
    caseId: Number(caseId),
  });
  const talentId = Number(talentCase?.talent_id);

  const { data: talent, isPending: isPendingTalent } = useTalentQuery(talentId);

  const { isHR, isAdmin } = useCurrentUser();

  const stepDialog = useModalStore();
  const isStepDialogOpen = stepDialog.useState('open');

  const completeTaskDialog = useModalStore();
  const isCompleteTaskDialogOpen = completeTaskDialog.useState('open');

  const hrisProviderDialog = useModalStore();
  const isHrisDialogOpen = hrisProviderDialog.useState('open');

  const [selectedTask, setSelectedTask] = useState<Task>();

  const { tasks, updateTask, deleteTask, isPendingTasks } = useManageTasks({ caseId });

  const onCompleteTask = async () => {
    if (!selectedTask) return;

    updateTask({ taskId: selectedTask.id, payload: { completed: true } });
  };

  const { onCompleteStep, isUpdateButtonDisabled } = useCaseStepSubmission({
    caseId,
    selectedTask,
    onCompleteTask,
    stepDialog,
    isHR,
  });

  if (isPendingTasks || isPendingTalent || isPendingCase) {
    return <ContainedLoadingState />;
  }

  return (
    <>
      <Box.div display="grid" gridTemplateColumns={isAdmin ? '2fr 1fr' : undefined} h="100%">
        {tasks.length === 0 ? (
          <EmptyState
            gap="d2"
            illustration={
              <Icon color="contentTertiary" decorative icon="check-square" size="sizeIcon30" />
            }
            text="There are no open tasks."
          />
        ) : (
          <Box.ul
            display="flex"
            flexDirection="column"
            gap="d4"
            marginBottom="d0"
            maxW="100%"
            overflowY="auto"
            padding="d6"
          >
            {tasks.map((task) => {
              return (
                <TaskItem
                  key={task.id}
                  onComplete={() => {
                    setSelectedTask(task);
                    if (task.task_type === 'talent_hris_id') {
                      hrisProviderDialog.show();
                    } else if (isHR && task.case_step_id) {
                      stepDialog.show();
                    } else {
                      completeTaskDialog.show();
                    }
                  }}
                  onDelete={() => {
                    setSelectedTask(task);
                    deleteTask({ task, caseId });
                  }}
                  onView={() => {
                    if (task.case_step_id) {
                      setSelectedTask(task);
                      stepDialog.show();
                    }
                  }}
                  task={task}
                />
              );
            })}
          </Box.ul>
        )}
        {isAdmin && <PostNewTask />}
      </Box.div>
      {selectedTask && isCompleteTaskDialogOpen && (
        <ConfirmationModal
          confirmLabel="I have completed this task"
          onConfirm={onCompleteTask}
          store={completeTaskDialog}
        >
          Have you completed this task?
          {` ${selectedTask.description}`}
        </ConfirmationModal>
      )}
      {selectedTask?.task_type === 'talent_hris_id' && isHrisDialogOpen && (
        <VerifyIntegrationModal
          caseId={caseId}
          onError={() => toast('Something went wrong while updating integration', 'error')}
          onSuccess={() => {
            hrisProviderDialog.hide();
            toast('Integration updated successfully', 'success');
            updateTask({ taskId: selectedTask.id, payload: { completed: true } });
          }}
          store={hrisProviderDialog}
        />
      )}
      {isStepDialogOpen && talent && (
        <Modal store={stepDialog}>
          <ModalHeader>Complete the step</ModalHeader>
          <ModalBody>
            <ProgressStepWithDocs
              buttons={
                <Box.div display="flex" justifyContent="flex-end">
                  <Button disabled={isUpdateButtonDisabled} type="submit" variant="primary">
                    Update
                  </Button>
                </Box.div>
              }
              caseId={selectedTask?.case_id}
              caseStepId={selectedTask?.case_step_id}
              submit={onCompleteStep}
              talent={talent}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
