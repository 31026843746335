import axios from 'axios';
import { logError } from 'utils/log/logError';

export { axios };

axios.defaults.withCredentials = false;
axios.defaults.xsrfCookieName = 'csrf_talent_token';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const NOT_FOUND = 404;
const UNPROCESSABLE_ENTITY = 422;
const NOT_ACCEPTED = 406;

// Interceptor to not capture unauthorized requests and log errors in Rollbar
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === UNAUTHORIZED) {
      window.location.href = String(process.env.REACT_APP_AUTH0_DOMAIN);
    }

    if (
      ![UNAUTHORIZED, FORBIDDEN, NOT_FOUND, UNPROCESSABLE_ENTITY, NOT_ACCEPTED].includes(
        error.response?.status,
      )
    ) {
      const message = `interceptor: ${error.response?.status} ${error.config?.url || ''}`;

      logError(message, error, error.response?.data);
    }

    return Promise.reject(error);
  },
);

// This configuration object is passed to all axios requests.
// The withCredentials attribute were removed from here and defined in axios.defaults above.
export const configAxios = {};
