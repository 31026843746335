import React, { useEffect, useState } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { GlomoRequestsEmptyState } from '../components/GlomoRequestsEmptyState';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { Box, Button, useDrawerStore } from '@localyze-pluto/components';
import { isEmpty } from 'lodash';
import { GlomoRequestFilterCommonKey } from 'modules/glomoRequests/constants';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { Filter } from 'modules/glomoRequests/components/GlomoRequestFilters/GlomoRequestFilters';
import { useAdminCompanyOptions } from 'modules/company/api/useAdminCompanyOptions/useAdminCompanyOptions';
import { trackPageView } from 'modules/analytics/analytics';
import { useGlomoRequests } from 'modules/glomoRequests/api/useGlomoRequests/useGlomoRequests';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { GlomoRequestsTable } from 'modules/glomoRequests/components/GlomoRequestsTable';
import { GlomoRequestType } from 'modules/glomoRequests/api/types/GlomoRequestType';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';
import { FiltersData, FiltersDrawer } from '../components/FiltersDrawer/FiltersDrawer';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { ColumnId } from '../components/GlomoRequestRowCell';
import { getGlomoRequestFilters } from '../utils/getGlomoRequestFilters';

type FilterKey = GlomoRequestFilterCommonKey | 'company_id';

type TableColumn = { id: ColumnId; name: string };

const columns: TableColumn[] = [
  { id: 'AdminNotification', name: '' },
  { id: 'Name', name: 'Name' },
  { id: 'RequestType', name: 'Request type' },
  { id: 'Company', name: 'Company' },
  { id: 'TravelStartDate', name: 'Travel start date' },
  { id: 'Status', name: 'Status' },
  { id: 'RiskLevel', name: 'Risk level' },
  { id: 'RequestDate', name: 'Request date' },
];

export const AdminGlomoRequests = (): React.JSX.Element => {
  useEffect(() => {
    trackPageView('glomo requests: list');
  }, []);

  const drawerStore = useDrawerStore();

  const { queryParams, updateQueryParams } = useQueryParams();

  const [companyId, setCompany] = useState<string | undefined>(queryParams.company_id);

  const { data: companies, isPending: isPendingCompanies } = useAdminCompanyOptions({});

  const filters = [
    {
      id: 'company-filter',
      key: 'company_id',
      items: [
        {
          label: 'All companies',
          value: '',
        },
        ...(companies || []),
      ],
      placeholder: 'Company',
      ariaLabel: 'Select one company',
      value: companyId,
    } as Filter<FilterKey>,
    ...getGlomoRequestFilters(true),
  ];

  const [riskLevel, setRiskLevel] = useState<GlomoRequestRisk | undefined>(
    queryParams.risk_level as GlomoRequestRisk,
  );

  const [status, setStatus] = useState<GlomoRequestStatus | undefined>(
    queryParams.status as GlomoRequestStatus,
  );

  const [requestType, setRequestType] = useState<GlomoRequestType | undefined>(
    queryParams.type as GlomoRequestType,
  );

  const { data: glomoRequests, isPending: isPendingGlomoRequests } = useGlomoRequests({
    params: {
      risk_level: riskLevel,
      status: status ? [status] : status,
      company_id: companyId ? Number(companyId) : undefined,
      type: requestType,
      sort_by: 'created_at',
      sort_direction: 'desc',
    },
  });

  filters[1].value = riskLevel;
  filters[2].value = status;
  filters[3].value = requestType;

  const onApplyFilters = (filtersData: FiltersData) => {
    setRiskLevel(filtersData.risk_level || undefined);
    setStatus(filtersData.status || undefined);
    setRequestType(filtersData.type || undefined);
    setCompany(filtersData.company_id || undefined);

    updateQueryParams(filtersData);

    drawerStore.hide();

    trackEvent('glomo request: filter requests', {
      user_type: 'admin',
      status: filtersData.status,
      type: filtersData.type,
      risk_level: filtersData.risk_level,
    });
  };

  return (
    <PageLayout flexed title="Manage Requests">
      <Box.div display="flex" flexDirection="column" gap="d6" marginBottom="m8">
        <Box.div alignItems="center" display="flex" gap="d3" marginBottom="m6">
          <Button leadingIcon="sliders-vertical" onClick={drawerStore.show} variant="secondary">
            Filter
          </Button>
        </Box.div>
        {isPendingGlomoRequests || isPendingCompanies ? (
          <ContainedLoadingState />
        ) : (
          <>
            {isEmpty(glomoRequests) ? (
              <GlomoRequestsEmptyState />
            ) : (
              <GlomoRequestsTable
                columns={columns}
                glomoRequests={glomoRequests as GlomoRequest[]}
              />
            )}
          </>
        )}
      </Box.div>
      <FiltersDrawer
        filters={filters}
        initialFilterValues={{
          risk_level: riskLevel,
          status: status,
          type: requestType,
          company_id: companyId,
        }}
        onApply={onApplyFilters}
        store={drawerStore}
      />
    </PageLayout>
  );
};
