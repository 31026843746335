import AddTeamMemberForm, { validationSchema } from './AddTeamMemberForm';
import { OPTION_ROLES, submitCompanyUserCreateInfo } from 'deprecated/utils/helper/companies/users';
import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { UserRoleName } from 'modules/users/types/UserRoleName';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useToast,
} from '@localyze-pluto/components';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  roleName: UserRoleName.Regular,
};

export function AddTeamMember({ companyId, onSubmit, state }) {
  const toast = useToast();
  const [error, setError] = useState(undefined);

  const onSuccess = useCallback(() => {
    toast('New user created', 'success');
    if (onSubmit) onSubmit();
    state.toggle();
  }, [state, onSubmit, toast]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await submitCompanyUserCreateInfo(values, companyId);
        onSuccess();
        setError(undefined);
      } catch (e) {
        setError(e.message);
      }
    },
    [companyId, onSuccess],
  );

  return (
    <Modal store={state}>
      <ModalHeader>
        <Box.div fontSize="fontSize50" fontWeight="fontWeightBold" paddingTop="p2">
          Add a team member
        </Box.div>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <AddTeamMemberForm error={error} roleOptions={OPTION_ROLES} />
        </Formik>
        <ModalFooter>
          <Button variant="secondary" onClick={state.hide}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" form="addTeamMemberForm">
            Submit
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}

AddTeamMember.propTypes = {
  companyId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
};
