import React from 'react';
import { Box, Heading } from '@localyze-pluto/components';
import { Bar } from 'react-chartjs-2';
import { getChartConfig } from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getChartConfig';
import { CaseMetricEntry } from 'modules/reporting/api/types/CaseMetrics';

type BarChartProps = {
  heading: string;
  entries: CaseMetricEntry[];
};

const barOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const BarChart = ({ heading, entries }: BarChartProps): React.JSX.Element => {
  return (
    <Box.div>
      <Heading as="h2" size="heading60">
        {heading}
      </Heading>
      <Bar data={getChartConfig(entries)} options={barOptions} />
    </Box.div>
  );
};
