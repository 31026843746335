import { getTrafficLightColor, lightColors } from './getTrafficLightColor';
import { Box } from '@localyze-pluto/components';
import React from 'react';

export const TrafficLight = ({
  children,
  trafficLight,
}: {
  children: React.ReactNode;
  trafficLight: lightColors;
}): React.JSX.Element => {
  return (
    <Box.div display="flex">
      <Box.div paddingRight="p2" paddingTop="p1">
        <Box.div
          borderRadius="borderRadiusCircle"
          data-testid="traffic-light"
          h="1rem"
          w="1rem"
          {...getTrafficLightColor(trafficLight)}
        />
      </Box.div>
      <Box.span>{children}</Box.span>
    </Box.div>
  );
};
