import * as Yup from 'yup';
import FormFields from 'deprecated/components/FormFields';
import Line from 'deprecated/components/Line/Line';

import { Form } from 'formik';
import { P1 } from 'deprecated/components/Fonts';
import PropTypes from 'prop-types';
import React from 'react';
import { Validation } from 'constants/formValidations';
import styled from 'styled-components';
import { Box } from '@localyze-pluto/components';

export const validationSchema = Yup.object({
  firstName: Validation.required,
  lastName: Validation.required,
  email: Validation.email,
  roleName: Validation.required,
});

export default function AddTeamMemberForm({ error, roleOptions }) {
  return (
    <Form id="addTeamMemberForm">
      <Box.div display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="d5">
        <P1>Personal Information</P1>
        <Box.div>
          <FormFields fieldType="input" name="firstName" label="First Name" />
        </Box.div>
        <Box.div>
          <FormFields fieldType="input" name="lastName" label="Last Name" />
        </Box.div>
        <Box.div gridArea="2 / 2 / 2 / 2">
          <FormFields fieldType="input" name="email" label="Email" />
        </Box.div>
        <Box.div gridArea="3 / 1 / 4 / 4">
          <Line margin="20px 0px 20px 0px" />
        </Box.div>
        <Box.div gridRow="4" gridColumn="1" marginBottom="m8">
          <P1>Role</P1>
        </Box.div>
        <Box.div gridRow="4" gridColumn="2" marginBottom="m8">
          <FormFields fieldType="dropdown" name="roleName" label="Role" options={roleOptions} />
        </Box.div>
      </Box.div>
      {error && (
        <div className="mt-3">
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      )}
    </Form>
  );
}

AddTeamMemberForm.propTypes = {
  error: PropTypes.string,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
};

const ErrorMessage = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.439545px;
  color: #ff101f;
`;
