import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';
import { filterByUserType } from 'deprecated/utils/helper/filterByUserType';

const CASE_STATE_OPTIONS: FilterOption<string>[] = [
  {
    filterAttribute: 'case_state',
    label: 'All',
    userTypes: ['admin'],
    value: 'all',
  },
  {
    filterAttribute: 'case_state',
    label: 'Case evaluation',
    userTypes: ['admin'],
    value: 'case_evaluation',
  },
  {
    filterAttribute: 'case_state',
    label: 'Ongoing',
    userTypes: ['admin', 'hr'],
    value: 'ongoing',
  },
  {
    filterAttribute: 'case_state',
    label: 'Waiting',
    userTypes: ['admin', 'hr'],
    value: 'waiting',
  },
  {
    filterAttribute: 'case_state',
    label: 'Closed',
    userTypes: ['admin', 'hr'],
    value: 'closed',
  },
];

export const CASE_STATE_FILTER: Filter<string> = {
  attribute: 'process_state',
  label: 'Case State',
  options: [],
  populateOptions: ({ isAdmin }) =>
    Promise.resolve(CASE_STATE_OPTIONS.filter((option) => filterByUserType(option, isAdmin))),
  type: 'multiselect',
  userTypes: ['admin', 'hr'],
};
