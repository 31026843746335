import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useTalentQuery } from 'modules/talent/api/useTalentQuery/useTalentQuery';
import { useParams } from 'react-router-dom';
import { TalentContext } from './TalentContext';
import React from 'react';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { AxiosError } from 'axios';
import { TalentForbiddenError } from 'modules/talent/pages/talentProfile/components/TalentForbiddenError';
import { isForbiddenError } from 'modules/utils/isForbiddenError';

export const TalentContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element => {
  const { id: talentId } = useParams<{ id: string }>();
  const { data: talent, isSuccess, isError, error } = useTalentQuery(Number(talentId));

  if (isError && isForbiddenError(error as AxiosError)) {
    return <TalentForbiddenError />;
  }

  if (isError) return <ErrorStatePage />;

  if (!isSuccess) {
    return <ContainedLoadingState />;
  }

  return <TalentContext.Provider value={talent}>{children}</TalentContext.Provider>;
};
