import { Attachment } from './types/Attachment';
import { Document } from './types/Document';
import axios from 'axios';

export const createAttachment = async (payload: Attachment): Promise<Document> => {
  const res = await axios.post<Document>('/attachments', {
    attachment: {
      ...payload,
    },
  });

  return res.data;
};
