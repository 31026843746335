import { Box } from '@localyze-pluto/components';
import { PERMISSIONS_DOCUMENTATION_URL } from 'constants/permissions';
import React from 'react';

export const FollowersOptionsHelpText = (): React.JSX.Element => {
  return (
    <Box.span>
      Followers can be anyone in your team except for Superadmins. The case information they can
      view is determined by the permissions of their user role.{' '}
      <Box.a href={PERMISSIONS_DOCUMENTATION_URL} rel="noreferrer" target="_blank">
        Learn more about roles and permissions.
      </Box.a>
    </Box.span>
  );
};
