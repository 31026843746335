import { Link } from 'react-router-dom';
import { Box } from '@localyze-pluto/components';
import React, { ReactNode } from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export const GlomoRequestTableLink = ({
  children,
  id,
}: {
  children: NonNullable<ReactNode>;
  id: number;
}): React.JSX.Element => {
  const routes = useRoutes();

  return (
    <Box.a
      alignItems="center"
      as={Link}
      color="inherit"
      display="flex"
      h="100%"
      onClick={() => trackEvent('glomo request: request clicked')}
      padding="p4"
      to={routes.glomoRequest.get(id)}
    >
      {children}
    </Box.a>
  );
};
