import { Box, Button, Heading, Paragraph } from '@localyze-pluto/components';

import Logo from 'assets/img/localyze-logo.svg';
import { LogoutOptions } from '@auth0/auth0-react';
import React from 'react';
import Sadlama from 'assets/img/lhama.svg';

type Props = {
  errorMessage?: string;
  logout(options: LogoutOptions): void;
};

export const AuthError = ({
  errorMessage = 'We had an issue signing you in.',
  logout,
}: Props): React.JSX.Element => {
  return (
    <Box.div backgroundColor="colorBackgroundBody" h="100vh" overflowY="auto" padding="d9">
      <Box.img height="auto" marginBottom="d9" src={Logo} w="200px" />

      <Box.div
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Box.img height="auto" marginBottom="d9" src={Sadlama} w="300px" />
        <Heading as="h1" marginBottom="m6" size="heading40">
          Oh no - something went wrong
        </Heading>
        <Paragraph fontSize="fontSize30" marginBottom="d9">
          {errorMessage}
        </Paragraph>
        <Box.div
          alignItems="center"
          display="grid"
          gap="d6"
          gridTemplateColumns="repeat(3, auto)"
          justifyItems="center"
        >
          <Button
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }}
            variant="primary"
          >
            Log out
          </Button>
          or
          <Button
            onClick={() => {
              location.reload();
            }}
            variant="secondary"
          >
            Refresh
          </Button>
        </Box.div>
      </Box.div>
    </Box.div>
  );
};
