import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCaseTypes } from 'modules/cases/api/useCaseTypes/useCaseTypes';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { CaseTypeOptions } from 'modules/cases/components/CaseTypeOptions/CaseTypeOptions';
import { Case } from 'modules/cases/types/Case';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type EditCaseTypeFormTypes = {
  caseTypeId: string;
};

const validationSchema: Yup.ObjectSchema<EditCaseTypeFormTypes> = Yup.object({
  caseTypeId: Yup.string().required('This field is required'),
});

export const EditCaseTypeModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const { case_type_id: caseTypeId, destination_country_id: destinationCountryId } = kase;
  const toast = useToast();
  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Case type updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Case type could not be updated', 'error'),
  });

  const { data: caseTypes, isSuccess: isSuccessCaseTypes } = useCaseTypes({
    destinationCountryId: kase.destination_country_id,
  });

  const { data: destinationCountry, isSuccess: isSuccessDestinationCountry } = useCountries({
    select: (countries) => countries.find((country) => country.id === Number(destinationCountryId)),
  });

  const flagUrl = `https://flagcdn.com/24x18/${destinationCountry?.code.toLowerCase()}.png`;

  const { handleSubmit, control } = useForm({
    defaultValues: { caseTypeId: caseTypeId.toString() },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditCaseTypeFormTypes> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { case_type_id: Number(values.caseTypeId) },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>
          Edit Case type{' '}
          {destinationCountry && (
            <>
              (
              <Box.img aria-hidden px="p0_5" src={flagUrl} />
              {destinationCountry.name})
            </>
          )}
        </ModalHeading>
      </ModalHeader>
      {!isSuccessCaseTypes || !isSuccessDestinationCountry ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Box.form id="edit-case-type-form" onSubmit={handleSubmit(onSubmit)}>
              <CaseTypeOptions caseTypes={caseTypes} control={control} />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={modal.hide} type="button" variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={isPendingUpdateCase}
              form="edit-case-type-form"
              loading={isPendingUpdateCase}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
