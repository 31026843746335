import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { BillingCenter } from '../../types/BillingCenters.types';
import { SelectItemProps } from '@localyze-pluto/components';
import { UseBillingCentersProps, UseBillingCentersResult } from './useBillingCenters.type';

/**
 * Makes a get request to fetch billing centers
 * @returns billing centers
 */
const getBillingCenters = async (): Promise<BillingCenter[]> => {
  const { data } = await axios.get<BillingCenter[]>('/billing_centers');

  return data;
};

/**
 * Gets list of billing centers
 * @param select - transform returned data
 * @returns billing centers
 */
export const useBillingCenters = <Result = BillingCenter[]>({
  select,
  enabled,
}: UseBillingCentersProps<Result> = {}): UseBillingCentersResult<Result> =>
  useQuery({ queryKey: ['billing-centers'], queryFn: getBillingCenters, select, enabled });

/**
 * Gets list of billing centers as select options
 * @returns billing centers transformed into options usable by a select
 */
export const useBillingCenterOptions = (
  props: Omit<UseBillingCentersProps<SelectItemProps[]>, 'select'> = {},
): UseQueryResult<SelectItemProps[], AxiosError> => {
  const transformBillingCenters = (billingCenters: BillingCenter[]): SelectItemProps[] => {
    return billingCenters.map((billingCenter) => ({
      label: billingCenter.name,
      value: billingCenter.id.toString(),
    }));
  };

  return useBillingCenters({ select: transformBillingCenters, ...props });
};
