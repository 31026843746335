import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';
import { DynamicField } from 'components/ControlledFormField/DynamicField';
import { AddOfficePayload } from 'modules/offices/api/useAddOffice/useAddOffice.types';
import { OfficeWithOfficeInformation } from 'deprecated/api/companies/types/OfficeAPIResponse';

const GENERAL_FIELDS: DynamicField[] = [
  {
    id: 'registeredCompanyName',
    label: 'Registered company name',
    type: 'text',
    required: true,
  },
  {
    id: 'legalForm',
    label: 'Legal form',
    type: 'text',
  },
  {
    id: 'companyRegistrationNumber',
    label: 'Company registration number',
    type: 'text',
  },
  {
    id: 'competentCourt',
    label: 'Competent court',
    type: 'text',
  },
  {
    id: 'commercialRegistrationNumber',
    label: 'Commercial registration number',
    type: 'text',
  },
  {
    id: 'legalRepresentative',
    label: 'Legal representative',
    type: 'text',
  },
  {
    id: 'foundingYear',
    label: 'Office founding year',
    type: 'text',
  },
];

const EMPLOYEE_FIELDS: DynamicField[] = [
  {
    id: 'averageEmployeeCount',
    label: 'Number of employees in previous year',
    type: 'text',
  },
  {
    id: 'euEmployeeCount',
    label: 'Number of EU employees at this office',
    type: 'text',
  },
  {
    id: 'nonEUEmployeeCount',
    label: 'Number of non-EU employees at this office',
    type: 'text',
  },
];

const TAX_FIELDS: DynamicField[] = [
  {
    type: 'checkbox',
    id: 'noCriminalProceedings',
    label:
      'The office was not involved in any criminal or administrative' +
      ' proceedings in the past 5 years.',
  },
  {
    type: 'checkbox',
    id: 'noInsolvencyProceedings',
    label: 'The office was not involved in any insolvency proceedings in the past 5 years.',
  },
  {
    type: 'checkbox',
    id: 'noSocialSecurityContributions',
    label: 'There are no open contributions for social security.',
  },
];

// eslint-disable-next-line sonarjs/cognitive-complexity
const getInitialValues = (office?: OfficeWithOfficeInformation): AddOfficePayload => {
  const officeInformation = office?.office_information;

  return {
    registeredCompanyName: office?.name || '',
    city: office?.city || '',
    countryId: office?.country.id.toString() || '',
    legalRepresentative: office?.legal_representative || '',
    street: officeInformation?.street || '',
    streetNumber: officeInformation?.house_num || '',
    postalCode: officeInformation?.postal_code || '',
    foundingYear: officeInformation?.founding_year || '',
    companyRegistrationNumber: officeInformation?.registration_number || '',
    legalForm: officeInformation?.company_legal_form || '',
    commercialRegistrationNumber: officeInformation?.commercial_registration_number || '',
    competentCourt: officeInformation?.competent_court || '',
    averageEmployeeCount: officeInformation?.average_employee_count || undefined,
    euEmployeeCount: officeInformation?.eu_employee_count || undefined,
    nonEUEmployeeCount: officeInformation?.non_eu_employee_count || undefined,
    noCriminalProceedings: officeInformation?.no_criminal_proceedings || false,
    noInsolvencyProceedings: officeInformation?.no_insolvency_proceedings || false,
    noSocialSecurityContributions: officeInformation?.no_social_security_contributions || false,
  };
};

export const useOfficeFields = (): {
  isPending: boolean;
  addressFields: DynamicField[];
  generalFields: DynamicField[];
  taxFields: DynamicField[];
  employeeFields: DynamicField[];
  getInitialValues: (office?: OfficeWithOfficeInformation) => AddOfficePayload;
} => {
  const countriesQuery = useCountryOptions();
  const isPending = countriesQuery.isPending || !countriesQuery.data;

  const addressFields: DynamicField[] = [
    {
      id: 'street',
      label: 'Street',
      type: 'text',
      required: true,
    },
    {
      id: 'streetNumber',
      label: 'Street number',
      type: 'text',
      required: true,
    },
    {
      id: 'postalCode',
      label: 'Postal code',
      type: 'text',
      required: true,
    },
    {
      id: 'city',
      label: 'City',
      type: 'text',
      required: true,
    },
    {
      id: 'countryId',
      label: 'Country',
      type: 'select',
      required: true,
      disabled: isPending,
      options: countriesQuery.data || [],
    },
  ];

  return {
    addressFields,
    generalFields: GENERAL_FIELDS,
    taxFields: TAX_FIELDS,
    employeeFields: EMPLOYEE_FIELDS,
    isPending,
    getInitialValues,
  };
};
