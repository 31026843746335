import { convertDaysToWeeks } from './convertDaysToWeeks';
import { convertWeeksToMonths } from './convertWeeksToMonths';

export const humanizeTimeInDays = (numberOfDays: number): string => {
  const WEEKS_MONTHS_THRESHOLD = 12;

  const numberOfWeeks = convertDaysToWeeks(numberOfDays);

  if (numberOfWeeks === 1) {
    return '1 week';
  } else if (numberOfWeeks <= WEEKS_MONTHS_THRESHOLD) {
    return `${numberOfWeeks} weeks`;
  } else {
    return `${convertWeeksToMonths(numberOfWeeks)} months`;
  }
};
