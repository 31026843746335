import { Country } from 'modules/countries/types/Country';
import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';

const mapCountriesToFilterOptions = (countries: Country[]): FilterOption<string>[] =>
  countries.map((country: Country) => ({
    filterAttribute: 'destination_country_id',
    label: country.name,
    userTypes: ['admin'],
    value: country.id.toString(),
  }));

const ALL_COUNTRIES_OPTION: FilterOption<string> = {
  filterAttribute: 'destination_country_id',
  label: 'All',
  userTypes: ['admin'],
  value: 'all',
};

export const destinationCountryFilter = (countries: Country[]): Filter<string> => ({
  attribute: 'destination_country_id',
  label: 'Destination Country',
  options: [],
  populateOptions: async () => [ALL_COUNTRIES_OPTION, ...mapCountriesToFilterOptions(countries)],
  type: 'multiselect',
  userTypes: ['admin'],
});
