import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { TalentCell } from 'modules/reporting/pages/reporting/components/ReportingTable/cells/TalentCell/TalentCell';
import { getTalentValue } from 'modules/reporting/pages/reporting/components/ReportingTable/getters/getTalentValue/getTalentValue';
import { CaseStatusCell } from 'modules/reporting/pages/reporting/components/ReportingTable/cells/CaseStatusCell/CaseStatusCell';
import { getCaseStatusValue } from 'modules/reporting/pages/reporting/components/ReportingTable/getters/getCaseStatusValue/getCaseStatusValue';
import { formatReportingDateValue } from 'modules/reporting/pages/reporting/components/ReportingTable/formatters/formatReportingDateValue/formatReportingDateValue';
import { agGridDateFilter } from 'modules/reporting/pages/reporting/components/ReportingTable/filters/dateFilter';
import { formatCurrency } from 'modules/reporting/pages/reporting/components/ReportingTable/formatters/formatCurrency/formatCurrency';
import { agGridNumberFilter } from 'modules/reporting/pages/reporting/components/ReportingTable/filters/numberFilter';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useState } from 'react';
import { DefaultCell } from 'modules/reporting/pages/reporting/components/ReportingTable/cells/DefaultCell/DefaultCell';
import { CaseReport } from 'modules/reporting/api/types/CaseReport';

export const useReportingColumnDefs = (): { columnDefs: ColDef[]; defaultColDef: ColDef } => {
  const defaultColDef: ColDef = {
    autoHeight: true,
    cellRenderer: DefaultCell,
    filter: true,
    filterParams: {
      buttons: ['reset'],
      filterOptions: ['contains', 'notContains'],
      maxNumConditions: 1,
    },
    resizable: true,
    sortable: true,
    wrapText: true,
  };
  const routes = useRoutes();

  const getTalentProfileLink = ({ data }: Pick<ValueGetterParams<CaseReport>, 'data'>): string => {
    const talentId = data?.talentId;

    return talentId ? `${window.location.origin}${routes.talentProfile.get(talentId)}` : '';
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: 'Talent',
      cellRenderer: TalentCell,
      minWidth: 250,
      valueGetter: getTalentValue,
      pinned: 'left',
    },
    {
      headerName: 'Employee ID',
      field: 'hrisEmployeeId',
      hide: true,
    },
    { headerName: 'Email', field: 'talentEmail', minWidth: 250, wrapText: false },
    {
      headerName: 'Case Status',
      field: 'currentState',
      cellRenderer: CaseStatusCell,
      minWidth: 250,
      valueGetter: getCaseStatusValue,
    },
    {
      headerName: 'Talent Start at Risk',
      field: 'talentStartAtRisk',
      hide: true,
    },
    { headerName: 'Case Type', field: 'caseTypeName' },
    {
      headerName: 'Creation Date',
      field: 'talentCreatedDate',
      valueFormatter: formatReportingDateValue,
      ...agGridDateFilter,
    },
    {
      headerName: 'Job Title',
      field: 'talentJobTitle',
    },
    {
      headerName: 'First Day of Work',
      field: 'talentStartDate',
      valueFormatter: formatReportingDateValue,
      ...agGridDateFilter,
    },
    {
      headerName: 'Latest Update (Date)',
      field: 'latestMessageCreatedDate',
      valueFormatter: formatReportingDateValue,
      ...agGridDateFilter,
    },
    { headerName: 'Latest Update (Comment)', field: 'latestMessage', minWidth: 250 },
    { headerName: 'Country of Origin', field: 'originCountryName' },
    { headerName: 'Country of Residence', field: 'originCountryName' },
    { headerName: 'Destination Country', field: 'destinationCountryName' },
    { headerName: 'City/Office Location', field: 'officeCity' },
    {
      headerName: 'Case Description',
      field: 'caseTypeDescription',
    },
    { headerName: 'Service Level', field: 'serviceLevelName' },
    {
      headerName: 'Case Price',
      field: 'casePrice',
      valueFormatter: formatCurrency,
      ...agGridNumberFilter,
    },
    { headerName: 'Accompanying Family', field: 'accompanyingFamilyMembers' },
    { headerName: 'Additional Services', field: 'additionalServices' },
    {
      headerName: 'Price of Services',
      field: 'additionalServicesPrice',
      valueFormatter: formatCurrency,
      ...agGridNumberFilter,
    },
    {
      headerName: 'Allowance Assigned',
      field: 'allowanceAssigned',
      valueFormatter: formatCurrency,
    },
    {
      headerName: 'Allowance Used',
      field: 'allowanceUsed',
      valueFormatter: formatCurrency,
    },
    {
      headerName: 'Total Cost (Tax included)',
      field: 'totalCost',
      valueFormatter: formatCurrency,
      ...agGridNumberFilter,
    },
    { headerName: 'Case Manager', field: 'caseManagerName' },
    { headerName: 'HR Manager', field: 'hrManagerName' },
    { headerName: 'Last Task', field: 'mostRecentTaskDescription' },
    {
      headerName: 'Embassy Appointment Date',
      field: 'embassyAppointmentDate',
      valueFormatter: formatReportingDateValue,
      ...agGridDateFilter,
    },
    {
      headerName: 'Residence Permit Expiry Date',
      field: 'residencePermitExpirationDate',
      valueFormatter: formatReportingDateValue,
      ...agGridDateFilter,
    },
    {
      headerName: 'Case Closing Date',
      field: 'caseClosedDate',
      valueFormatter: formatReportingDateValue,
      ...agGridDateFilter,
    },
    {
      headerName: 'Talent Profile Link',
      field: 'talentId',
      valueGetter: getTalentProfileLink,
      hide: true,
    },
  ]);

  return { defaultColDef, columnDefs };
};
