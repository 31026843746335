import { ADMIN_ROUTES, AdminOnlyRoutes } from '../admin_routes';
import { COMMON_ROUTES } from '../common_routes';
import { HR_ROUTES, HROnlyRoutes } from '../hr_routes';
import { Route } from 'types/Route';
import { useCurrentUser } from 'config/CurrentUserContext';

export type CommonRoutes = Record<keyof typeof COMMON_ROUTES, Route>;
export type AdminRoutes = AdminOnlyRoutes & CommonRoutes;
export type HrRoutes = CommonRoutes & HROnlyRoutes;

export type ConditionalRoutes<IsAdmin extends boolean> = IsAdmin extends true
  ? AdminRoutes
  : HrRoutes;

const getRoutes = <IsAdmin extends boolean>({
  isAdmin,
}: {
  isAdmin: IsAdmin;
}): ConditionalRoutes<IsAdmin> => {
  const routes = {
    ...COMMON_ROUTES,
    ...(isAdmin ? ADMIN_ROUTES : HR_ROUTES),
  };

  return routes as ConditionalRoutes<IsAdmin>;
};

export const useRoutes = (): AdminRoutes | HrRoutes => {
  const { isAdmin } = useCurrentUser();

  return getRoutes({ isAdmin });
};
