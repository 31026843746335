import axios from 'axios';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { RequiredActionId } from 'modules/glomoRequests/components/RequiredActions/RequiredActions';
import { CompleteGlomoRequestValues } from 'modules/glomoRequests/types/CompleteGlomoRequestValues';

export type CompleteGlomoRequestProps = {
  values: Omit<CompleteGlomoRequestValues, RequiredActionId> & {
    requiredActions: { action: RequiredActionId; value: boolean | string }[];
  };
  glomoRequestId: number;
};

export const completeGlomoRequest = async ({
  values,
  glomoRequestId,
}: CompleteGlomoRequestProps): Promise<GlomoRequest> => {
  const url = `/glomo/requests/${glomoRequestId}/assessment/completions`;

  const { data } = await axios.post<GlomoRequest>(url, {
    glomo_request: {
      report_pdf: values.report_pdf,
      risks_and_warnings: values.risksAndWarnings,
      required_actions: values.requiredActions,
      risk_level: values.riskLevel,
      risk_assessment_url: values.riskAssessmentUrl,
    },
  });

  return data;
};
