import { Badge, BadgeProps, Box } from '@localyze-pluto/components';

import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { CaseState } from 'modules/cases/types/CaseState';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { formatCaseStatus } from '../../formatters/formatCaseStatus/formatCaseStatus';

type CaseStatusCellProps = Pick<ICellRendererParams<CaseReport>, 'data'>;

const STATE_TO_COLOR_MAPPING: Record<CaseState, BadgeProps['color']> = {
  [CaseState.Closed]: 'gray',
  [CaseState.InAssessment]: 'yellow',
  [CaseState.Ongoing]: 'blue',
  [CaseState.Onboarding]: 'green',
  [CaseState.SubmittingInformation]: 'purple',
  [CaseState.Waiting]: 'yellow',
};

/**
 * Cell for case status
 */
export const CaseStatusCell = ({ data }: CaseStatusCellProps): React.JSX.Element => {
  const atRisk = data?.talentStartAtRisk;
  if (!data?.currentState) {
    return <></>;
  }

  return (
    <Box.div
      alignItems="flex-start"
      display="flex"
      flexWrap="wrap"
      gap="d2"
      lineHeight="lineHeight10"
    >
      {atRisk && (
        <Box.div flex="0 0 auto">
          <Box.div
            alignItems="center"
            backgroundColor="colorBackgroundDestructive"
            borderRadius="borderRadiusPill"
            color="colorTextInverse"
            display="inline-flex"
            fontSize="fontSize10"
            fontWeight="fontWeightBold"
            gap="d1"
            lineHeight="lineHeight30"
            paddingBottom="p1"
            paddingLeft="p3"
            paddingRight="p3"
            paddingTop="p0_5"
          >
            Start Date Approaching
          </Box.div>
        </Box.div>
      )}
      <Box.div flex="0 0 auto">
        <Badge color={STATE_TO_COLOR_MAPPING[data.currentState]}>
          {formatCaseStatus(data.currentState)}
        </Badge>
      </Box.div>
    </Box.div>
  );
};
