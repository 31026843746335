import axios from 'axios';

const EMAIL_IS_AVAILABLE_STATUS_CODE = 404;
const EMAIL_TAKEN_STATUS_CODE = 200;

export const getEmailAvailability = async (email: string): Promise<boolean> => {
  const { status } = await axios.get(`/emails/${email}`, {
    validateStatus: (status) =>
      status === EMAIL_IS_AVAILABLE_STATUS_CODE || status === EMAIL_TAKEN_STATUS_CODE,
  });

  switch (status) {
    case EMAIL_IS_AVAILABLE_STATUS_CODE:
      return true;
    case EMAIL_TAKEN_STATUS_CODE:
      return false;
    default:
      throw new Error(`Unexpected status code ${status} when checking email availability`);
  }
};
