import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import type { AdditionalService } from 'modules/additionalServices/types/AdditionalService';
import type { Partner } from 'modules/additionalServices/types/Partner';

export type BookedBy = {
  avatar_url: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
};

export type DetailedCaseAdditionalService = {
  id: number;
  additional_service: AdditionalService;
  partner: Maybe<Partner>;
  booked_by: Maybe<BookedBy>;
  created_at: string;
  status: Maybe<string>;
  case_id: number;
};

export const DETAILED_CASE_ADDITIONAL_SERVICE_QUERY_KEY = 'detailed-case-additional-service';

const getDetailedCaseAdditionalService = async (
  caseAdditionalServiceId: number,
): Promise<DetailedCaseAdditionalService> => {
  const { data } = await axios.get<DetailedCaseAdditionalService>(
    `/case_additional_services/${caseAdditionalServiceId}`,
  );
  return data;
};

export const useDetailedCaseAdditionalService = (
  caseAdditionalServiceId: number,
): UseQueryResult<DetailedCaseAdditionalService, AxiosError> => {
  return useQuery({
    queryKey: [DETAILED_CASE_ADDITIONAL_SERVICE_QUERY_KEY, String(caseAdditionalServiceId)],
    queryFn: () => getDetailedCaseAdditionalService(caseAdditionalServiceId),
    throwOnError: false,
  });
};
