import React from 'react';
import { UpdateItem } from 'modules/statusUpdates/updateItem/UpdateItem';
import { Box, BoxProps, Icon, useToast } from '@localyze-pluto/components';
import { AxiosError, isAxiosError } from 'axios';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { useDeleteStatusUpdate } from '../api/useDeleteStatusUpdate/useDeleteStatusUpdate';
import { StatusUpdate } from '../types/StatusUpdate';
import { EmptyState } from 'components/EmptyState/EmptyState';

const FIRST = 0;

export type StatusUpdatesProps = {
  updates: StatusUpdate[];
  isDeletable?: boolean;
  maxH?: BoxProps['maxH'];
  padding?: BoxProps['padding'];
};

export const StatusUpdates: React.FC<StatusUpdatesProps> = ({
  updates,
  isDeletable,
  maxH,
  padding,
}) => {
  const toast = useToast();

  const { mutate: deleteStatusUpdate } = useDeleteStatusUpdate({
    onSuccess: () => {
      toast('Deleted update successfully.', 'success');
    },
    onError: (error: AxiosError) => {
      const errorMessage = isAxiosError(error)
        ? (error as AxiosErrorWithMessage).response?.data.message
        : '';
      toast(errorMessage || 'Error deleting update.', 'error');
    },
  });

  return updates.length === 0 ? (
    <EmptyState
      gap="d2"
      illustration={<Icon color="contentTertiary" decorative icon="list-plus" size="sizeIcon30" />}
      text="There are no updates yet."
    />
  ) : (
    <Box.ul marginBottom="m0" maxH={maxH} minH="50%" overflowY="auto" padding={padding}>
      {updates.map((update, index) => (
        <UpdateItem
          className="tw-break-words"
          date={new Date(update.created_at)}
          id={update.id.toString()}
          isFirstUpdate={index === FIRST}
          isLast={updates.length - 1 === index}
          key={update.id}
          message={update.message}
          onDelete={
            isDeletable
              ? () => {
                  deleteStatusUpdate({ statusUpdate: update });
                }
              : undefined
          }
          type={update.status_type}
          waitingUntil={update.waiting_until}
        />
      ))}
    </Box.ul>
  );
};
