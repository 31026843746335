import { DialogStore } from '@ariakit/react';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { Case } from 'modules/cases/types/Case';
import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type EditCountryOfOriginFormTypes = {
  originCountryId: string;
};

export const EditCountryOfOriginModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const toast = useToast();
  const { data: countries, isPending: isCountriesPending } = useCountryOptions();

  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Country of origin updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Country of origin could not be updated', 'error'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: { originCountryId: kase.origin_country_id.toString() },
  });

  const onSubmit: SubmitHandler<EditCountryOfOriginFormTypes> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { origin_country_id: Number(values.originCountryId) },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Country of origin</ModalHeading>
      </ModalHeader>
      {isCountriesPending ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Box.form id="edit-country-of-origin-form" onSubmit={handleSubmit(onSubmit)}>
              <ControlledFormSelect
                control={control}
                id="originCountryId"
                items={countries || []}
                label="Country of origin"
                name="originCountryId"
                required
              />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={modal.hide} type="button" variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={isPendingUpdateCase}
              form="edit-country-of-origin-form"
              loading={isPendingUpdateCase}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
