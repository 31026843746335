import React from 'react';
import cx from 'clsx';
import { Sizes, Status } from '../../types';
import {
  completeContainerClasses,
  currentContainerClasses,
  getContainerSizeClass,
  getDotSizeClass,
  getIconSizeClass,
  incompleteContainerClasses,
} from './StepCircle.styles';
import { Icon } from '@localyze-pluto/components';

export type StepCircleProps = {
  readonly status: Status;
  readonly disabled?: boolean;
  readonly size?: Sizes;
};

export const StepCircle = ({
  status,
  disabled,
  size = Sizes.Large,
}: StepCircleProps): React.JSX.Element => {
  const statusesClassNames = [
    {
      status: Status.Complete,
      container: cx(completeContainerClasses, getContainerSizeClass(size), {
        'hover:tw-bg-blue-700': !disabled,
      }),
      child: cx('tw-text-white tw-absolute', getIconSizeClass(size)),
    },
    {
      status: Status.Current,
      container: cx(currentContainerClasses, getContainerSizeClass(size)),
      child: cx('tw-rounded-full tw-bg-blue-500 tw-p-0.5', getDotSizeClass(size)),
    },
    {
      status: Status.Incomplete,
      container: cx(incompleteContainerClasses, getContainerSizeClass(size), {
        'group-hover:tw-border-gray-400': !disabled,
      }),
      child: cx('tw-rounded-full tw-bg-transparent tw-p-0.5', getDotSizeClass(size), {
        'group-hover:tw-bg-gray-300': !disabled,
      }),
    },
  ];

  const renderChildComponent = ({ status, ...props }: { status: Status }) => {
    if (status === Status.Complete) {
      return (
        <Icon
          size={size === Sizes.Large ? 'sizeIcon30' : 'sizeIcon20'}
          {...props}
          decorative
          icon="check"
        />
      );
    }
    return <div {...props} />;
  };

  const classNames = statusesClassNames.find((element) => element.status === status);

  const childProps = {
    'aria-hidden': true,
    'data-testid': 'step-circle-child',
    className: classNames?.child,
  };

  return (
    <div className="tw-group">
      <div
        aria-valuemax={2}
        aria-valuemin={0}
        aria-valuenow={status}
        aria-valuetext={Status[status]}
        className={classNames?.container}
        role="progressbar"
      >
        {renderChildComponent({ status, ...childProps })}
      </div>
    </div>
  );
};

export { Sizes, Status };
