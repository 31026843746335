import React from 'react';
import { Tr, Th, Box } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';
import { TALENT_TABLE_COLUMNS } from '../constants';

export const HeaderRow = (): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const headers = TALENT_TABLE_COLUMNS.filter(
    ({ adminOnly }) => !adminOnly || adminOnly === isAdmin,
  );

  return (
    <Tr>
      {headers.map(({ id, header }) => (
        <Th key={id}>
          <Box.div whiteSpace="nowrap">{header}</Box.div>
        </Th>
      ))}
    </Tr>
  );
};
