import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GlomoRequest } from '../types/GlomoRequest';

export const GLOMO_REQUEST_QUERY_KEY = 'glomo-request';

const getGlomoRequest = async (glomoRequestId: number): Promise<GlomoRequest> => {
  const url = `/glomo/requests/${glomoRequestId}`;
  const { data } = await axios.get<GlomoRequest>(url);
  return data;
};

export const useGlomoRequest = (
  glomoRequestId: number,
): UseQueryResult<GlomoRequest, AxiosError> => {
  return useQuery({
    queryKey: [GLOMO_REQUEST_QUERY_KEY, glomoRequestId],
    queryFn: () => getGlomoRequest(glomoRequestId),
    enabled: !isNaN(glomoRequestId),
  });
};
