import { Box, BoxProps } from '@localyze-pluto/components';
import { PaginationInformation } from 'deprecated/api/types/PaginationInformation';
import React from 'react';

type Props = BoxProps & {
  readonly paginationInfo: PaginationInformation;
  readonly itemsPerPage?: number;
  readonly hide?: boolean;
};

const ITEMS_PER_PAGE = 15;
const DEFAULT_VALUE = 0;
const START_OR_PLUS_ONE = 1;
const NO_PAGE_VALUE = 0;

export const PaginatedResultsCounter = ({
  paginationInfo,
  itemsPerPage = ITEMS_PER_PAGE,
  hide = false,
  ...rest
}: Props): React.JSX.Element => {
  const total = paginationInfo.count;

  if (total === NO_PAGE_VALUE && hide) return <div />;

  const startNumber = (paginationInfo.prev || DEFAULT_VALUE) * itemsPerPage + START_OR_PLUS_ONE;

  const endNumber = Math.min(Math.ceil(itemsPerPage * paginationInfo.page), total);

  const message =
    total === NO_PAGE_VALUE
      ? 'Showing 0 results'
      : `Showing ${startNumber} to ${endNumber} of ${total} results`;

  return (
    <Box.span data-testid="paginated-results-counter" fontSize="fontSize20" {...rest}>
      {message}
    </Box.span>
  );
};
