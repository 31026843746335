import { Badge, Box } from '@localyze-pluto/components';
import React from 'react';
import { CaseOverviewCell } from '../CaseOverviewCell';
import { formatDateDashesToFriendly } from 'utils/formatters/formatDateDashesToFriendly/formatDateDashesToFriendly';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  caseOverview: CaseOverview;
  statusLastUpdatedAt: string;
  isAdmin?: boolean;
};

export const StatusLastUpdatedAtCell = ({
  isAdmin = false,
  statusLastUpdatedAt,
  caseOverview,
}: Props): React.JSX.Element => {
  return (
    <CaseOverviewCell
      caseOverview={caseOverview}
      isAdmin={isAdmin}
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      <Box.div alignItems="center" display="flex">
        {statusLastUpdatedAt ? (
          <Box.div whiteSpace="nowrap">
            {' '}
            <Badge>{formatDateDashesToFriendly(statusLastUpdatedAt)}</Badge>
          </Box.div>
        ) : (
          '-'
        )}
      </Box.div>
    </CaseOverviewCell>
  );
};
