import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  CreateAccountEntryPayload,
  UseCreateAccountEntryProps,
  UseCreateAccountEntryResult,
} from './useCreateAccountEntry.types';
import { AccountEntry } from 'modules/billing/types/AccountEntry';

const createAccountEntry = async (payload: CreateAccountEntryPayload): Promise<AccountEntry> => {
  const { data } = await axios.post<AccountEntry>('/admin/account_entries', payload);

  return data;
};

export const useCreateAccountEntry = ({
  onSuccess,
  onError,
}: UseCreateAccountEntryProps = {}): UseCreateAccountEntryResult => {
  return useMutation({
    mutationFn: createAccountEntry,
    onSuccess,
    onError,
  });
};
