import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { StatusUpdateTemplate } from './types/StatusUpdateTemplate';
import { PostNewUpdateType } from '../Updates/PostNewUpdate';

export const useStatusUpdateTemplates = ({
  type,
  enabled = true,
}: {
  type?: PostNewUpdateType;
  enabled?: boolean;
}): UseQueryResult<StatusUpdateTemplate[]> => {
  const contentfulAPI = new ContentfulAPI();

  return useQuery({
    queryKey: ['status-update-templates', type],
    queryFn: () => contentfulAPI.fetchStatusUpdateTemplates({ type }),
    enabled,
  });
};
