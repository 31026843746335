import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { INTERNAL_VARIABLES_QUERY_KEY } from 'modules/variableEditor/api/useVariables';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

export type VariablePayload = {
  title: string;
  value: string;
  variable_type: string;
  destination_country_id?: number;
  origin_country_id?: number;
  link_title?: string;
  link_label?: string;
};

const createVariable = async (payload: VariablePayload): Promise<void> => {
  await axios.post('/admin/internal_variables', payload);
};

export const useCreateVariable = ({
  onError,
  onSuccess,
}: {
  onError?: (error: AxiosErrorWithMessage) => void;
  onSuccess?: () => void;
}): UseMutationResult<void, AxiosErrorWithMessage, VariablePayload> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createVariable,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [INTERNAL_VARIABLES_QUERY_KEY],
      });

      onSuccess?.();
    },
    onError,
  });
};
