import React, { TdHTMLAttributes } from 'react';
import { TooltipCell } from './TooltipCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = TdHTMLAttributes<HTMLTableCellElement> & {
  text: Maybe<string>;
  caseOverview: CaseOverview;
  isAdmin?: boolean;
};

export const TextCell = ({ caseOverview, text, isAdmin, ...rest }: Props): React.JSX.Element => {
  return (
    <TooltipCell caseOverview={caseOverview} isAdmin={isAdmin} tooltipText={text} {...rest}>
      {text}
    </TooltipCell>
  );
};
