import React from 'react';

import { Box, ControlledRadioGroup, Heading, Icon, Radio } from '@localyze-pluto/components';
import { Control } from 'react-hook-form';
import { SERVICE_LEVELS } from './serviceLevels';
import { YellowIcon } from '../YellowIcon';
import { GrayIcon } from '../GrayIcon';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  selectedServiceLevelId: string;
};

export const ServiceLevelOptions = ({
  selectedServiceLevelId,
  control,
}: Props): React.JSX.Element => {
  const selectedServiceLevel = SERVICE_LEVELS.find(
    (serviceLevel) => serviceLevel.id === Number(selectedServiceLevelId),
  );

  return (
    <Box.div columnGap="d12" display="grid" gridTemplateColumns={{ lg: '33% auto' }} rowGap="d3">
      <ControlledRadioGroup control={control} name="serviceLevelId">
        {SERVICE_LEVELS.map((serviceLevel) => (
          <Radio key={serviceLevel.id} label={serviceLevel.label} value={`${serviceLevel.id}`} />
        ))}
      </ControlledRadioGroup>
      <Box.div backgroundColor="colorBackgroundWeak" padding="p5">
        {selectedServiceLevelId === '3' && (
          <Box.div alignItems="center" display="flex" flexDirection="column" marginBottom="d5">
            <Heading as="h4" fontSize="fontSize20" lineHeight="lh8" marginBottom="d0_5">
              Talent Satisfaction Score
            </Heading>
            <Box.div alignItems="end" display="flex">
              <YellowIcon decorative icon="star" size="sizeIcon30" />
              <YellowIcon decorative icon="star" size="sizeIcon30" />
              <YellowIcon decorative icon="star" size="sizeIcon30" />
              <YellowIcon decorative icon="star" size="sizeIcon30" />
              <Box.div h={20} position="relative">
                <GrayIcon decorative icon="star" size="sizeIcon30" />
                <Box.div left="0" overflow="hidden" position="absolute" top="0" w="45%">
                  <YellowIcon decorative icon="star" size="sizeIcon30" />
                </Box.div>
              </Box.div>
            </Box.div>
          </Box.div>
        )}
        <Box.ul display="grid" gap="d0_5" listStyleType="none" margin="m0" padding="p0">
          {selectedServiceLevel?.features.map((feature) => (
            <Box.li
              alignItems="center"
              display="flex"
              fontSize="fontSize20"
              key={`feature-${feature}`}
            >
              <Box.div marginRight="m1">
                <Icon color="colorIconSuccess" decorative={true} icon="check" />
              </Box.div>
              {feature}
            </Box.li>
          ))}
        </Box.ul>
      </Box.div>
    </Box.div>
  );
};
