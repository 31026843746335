import { DialogStore } from '@ariakit/react';
import {
  Box,
  Button,
  ControlledFormInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
} from '@localyze-pluto/components';
import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon';
import { debounce } from 'lodash';
import { useActiveCompanyHRISIntegration } from 'modules/integrations/hooks/useActiveCompanyHRISIntegration/useActiveCompanyHRISIntegration';
import { useHRISTalent } from 'modules/integrations/hooks/useHRISTalent/useHRISTalent';
import { useUpdateHRISTalent } from 'modules/integrations/hooks/useUpdateHRISTalent/useUpdateHRISTalent';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Instructions } from './Instructions';
import { HelpText } from './HelpText';
import { DataTable } from './DataTable';
import { useTalentQuery } from 'modules/talent/api/useTalentQuery/useTalentQuery';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

export const VerifyIntegrationModal = ({
  store,
  caseId,
  onSuccess,
  onError,
}: {
  store: DialogStore;
  caseId: number;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}): React.JSX.Element => {
  const [search, setSearch] = useState('');
  const { data: kase } = useCase({ caseId });
  const { data: talent } = useTalentQuery(Number(kase?.talent_id));

  const companyId = Number(talent?.company_id);
  const { data: activeIntegration } = useActiveCompanyHRISIntegration(companyId);

  const { mutate: updateHRISTalent, isPending: isUpdatingTalent } = useUpdateHRISTalent({
    onSuccess: onSuccess,
    onError: onError,
  });

  const { control, setValue } = useForm({
    defaultValues: {
      employee_id: '',
    },
    mode: 'onChange',
  });

  const {
    data: hrisTalent,
    isLoading: isLoadingHRISTalent,
    isError,
  } = useHRISTalent({
    enabled: !!search,
    params: {
      companyId,
      providerName: 'stackone',
      uid: search.trim(),
    },
  });

  const onClickAdd = () => {
    updateHRISTalent({
      companyId,
      providerName: activeIntegration?.provider_data.stackone_provider_name,
      employeeId: hrisTalent?.employee_id,
      talentId: talent?.id,
    });
  };

  const onChangeDebounce = useRef(debounce(setSearch, 500));

  const isButtonDisabled = isLoadingHRISTalent || !hrisTalent;

  return (
    <Modal maxWidth="452" store={store}>
      <ModalHeader style={{ alignItems: 'center' }}>
        <ModalHeading>Complete task</ModalHeading>
      </ModalHeader>
      <ModalBody>
        {kase && talent ? (
          <Box.div display="flex" flexDirection="column" gap="d4">
            {activeIntegration && (
              <Instructions activeIntegration={activeIntegration} talent={talent} />
            )}
            <Box.form onSubmit={(ev) => ev.preventDefault()}>
              <ControlledFormInput
                autoComplete="off"
                control={control}
                helpText={
                  activeIntegration ? (
                    <HelpText activeIntegration={activeIntegration} isError={isError} />
                  ) : (
                    ''
                  )
                }
                id="employee_id"
                label="Employee ID"
                name="employee_id"
                onChange={(ev) => {
                  setValue('employee_id', ev.target.value);
                  onChangeDebounce.current(ev.target.value);
                }}
                trailingIcon="search"
                type="text"
              />
            </Box.form>
            {hrisTalent && <DataTable hrisTalent={hrisTalent} />}
          </Box.div>
        ) : (
          <ContainedLoadingState />
        )}
      </ModalBody>
      <ModalFooter>
        {isLoadingHRISTalent || isUpdatingTalent ? (
          <Box.div display="flex" h="d9" justifyContent="center" w="100%">
            <LoadingIcon size="sizeIcon40" />
          </Box.div>
        ) : (
          <Button
            disabled={isButtonDisabled}
            loading={isLoadingHRISTalent}
            onClick={onClickAdd}
            variant="primary"
          >
            Add Employee ID
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
