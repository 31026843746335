import * as Yup from 'yup';

import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalStore,
  SelectItemProps,
} from '@localyze-pluto/components';

import { HRISTalent } from 'modules/integrations/types/HRISTalent';
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';

const validationSchema = Yup.object({
  selectedEmail: Yup.string(),
  selectedCitizenship: Yup.string(),
});

type HRISDataSelectionModalProps = {
  state: ModalStore;
  talent: HRISTalent;
  setHRISData: (talentData: HRISTalent) => void;
};

export const hasMultipleEmails = (talent: HRISTalent): boolean =>
  !!(talent.work_email && talent.personal_email);

export const hasMultipleCitizenships = (talent: HRISTalent): boolean =>
  !!(talent.citizenship_ids && talent.citizenship_ids.length > 1);

const emailOptions = (talent: HRISTalent): SelectItemProps[] => [
  { label: talent.work_email || '', value: talent.work_email || '' },
  { label: talent.personal_email || '', value: talent.personal_email || '' },
];

const defaultValues = (talent: HRISTalent) => {
  const selectedEmail = hasMultipleEmails(talent) ? '' : talent.email || '';
  const selectedCitizenship = hasMultipleCitizenships(talent)
    ? ''
    : (talent.citizenship_ids && talent.citizenship_ids[0]?.toString()) || '';

  return {
    selectedCitizenship,
    selectedEmail,
  };
};

export const HRISDataSelectionModal = ({
  state,
  talent,
  setHRISData,
}: HRISDataSelectionModalProps): React.JSX.Element => {
  const { control, handleSubmit } = useForm({
    defaultValues: defaultValues(talent),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const { data: countries } = useCountryOptions();
  const citizenShipCountries = (countries || []).filter((option) =>
    talent.citizenship_ids?.includes(Number(option.value)),
  );

  const onSubmit = ({
    selectedEmail,
    selectedCitizenship,
  }: {
    selectedEmail?: string;
    selectedCitizenship?: string;
  }) => {
    setHRISData({
      email: selectedEmail,
      ...(selectedCitizenship && { citizenship_ids: [Number(selectedCitizenship)] }),
    });
    state.hide();
  };

  return (
    <Modal store={state}>
      <ModalHeader>
        <ModalHeading>Please select attributes</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.div marginBottom="m4">
          The imported data contains multiple options for some of the attributes.
        </Box.div>
        <Box.div marginBottom="m4">
          Please select the values you want to create the case with:
        </Box.div>
        <Box.form
          display="flex"
          flexDirection="column"
          gap="d4"
          id="dataSelectionForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          {hasMultipleEmails(talent) && (
            <ControlledFormSelect
              control={control}
              id="selectedEmail"
              items={emailOptions(talent)}
              label="Email"
              name="selectedEmail"
            />
          )}
          {hasMultipleCitizenships(talent) && (
            <ControlledFormSelect
              control={control}
              id="selectedCitizenship"
              items={citizenShipCountries}
              label="Citizenship"
              name="selectedCitizenship"
            />
          )}
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button form="dataSelectionForm" loading={false} type="submit" variant="primary">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
