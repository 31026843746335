import {
  Button,
  HelpText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalStore,
  Paragraph,
  TextArea,
  useToast,
} from '@localyze-pluto/components';

import React from 'react';
import { useFormik } from 'formik';

type ShareFormProps = {
  reportEmailAddresses?: string;
};

type ReportingTableShareModalProps = {
  focusRef: React.RefObject<HTMLTextAreaElement>;
  state: ModalStore;
  onSubmit: (emailAddress: string) => Promise<void>;
  isLoading?: boolean;
};

/**
 * Modal containing the share report form.
 * @param props.focusRef - initial focus ref for the modal
 * @param props.state - ModalStore
 */
export const ReportingTableShareModal = ({
  focusRef,
  state,
  isLoading,
  onSubmit,
}: ReportingTableShareModalProps): React.JSX.Element => {
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      reportEmailAddresses: '',
    },
    onSubmit: async (values: ShareFormProps, { resetForm }) => {
      if (values.reportEmailAddresses) {
        try {
          await onSubmit(values.reportEmailAddresses);
          state.toggle();
          resetForm();
          toast('Reports have been successfully sent to recipients!', 'success');
        } catch {
          toast('An error has occurred sending your report. Please try again.', 'error');
        }
      }
    },
    validate: (values: ShareFormProps) => {
      const errors: ShareFormProps = {};

      if (!values.reportEmailAddresses) {
        errors.reportEmailAddresses =
          'At least one email address is required. Please enter an email address.';
      }

      if (values.reportEmailAddresses) {
        const emailRegex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
        const result: boolean = values.reportEmailAddresses
          .split(/[, ]+/)
          .every((email) => emailRegex.test(email));
        if (result === false) {
          errors.reportEmailAddresses = 'One or more of the email addresses provided are invalid.';
        }
      }

      return errors;
    },
  });

  return (
    <Modal initialFocus={focusRef} store={state}>
      <ModalHeader>
        <ModalHeading>Share Report</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Paragraph>
          Enter each email address below, separated by a comma. Recipients will be sent a link to
          download the full report as a CSV.
          <br />
          Be sure to share only with people you trust as they will be able to see Talent
          information.
        </Paragraph>
        <form id="reportEmailForm" onSubmit={formik.handleSubmit}>
          <TextArea
            aria-describedby="reportEmailAddressesHelpText"
            aria-label="Email addresses"
            hasError={formik.errors.reportEmailAddresses !== undefined}
            id="reportEmailAddresses"
            name="reportEmailAddresses"
            onChange={formik.handleChange}
            placeholder="example@email.com, example2@email.com, example3@email.com"
            ref={focusRef}
            value={formik.values.reportEmailAddresses}
          />
          {formik.errors.reportEmailAddresses && (
            <HelpText hasError id="reportEmailAddressesHelpText">
              {formik.errors.reportEmailAddresses}
            </HelpText>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={state.toggle} variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={!formik.isValid || !formik.dirty}
          form="reportEmailForm"
          loading={isLoading}
          type="submit"
          variant="primary"
        >
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};
