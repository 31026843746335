import React from 'react';
import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Input, Label } from '@localyze-pluto/components';

export default function DateTimePick({ label, name, disabled = false, ...props }) {
  return (
    <>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <>
              <Label>{label}</Label>
              <Input
                {...field}
                {...props}
                disabled={disabled}
                autoComplete="off"
                onChange={(ev) => setFieldValue(name, ev.target.value)}
                type="datetime-local"
                value={value}
              />
            </>
          );
        }}
      </Field>
      <ErrorWrapper>
        <ErrorMessage name={name} />
      </ErrorWrapper>
    </>
  );
}

const ErrorWrapper = styled.div`
  color: var(--color-red);
  font-size: 14px;
`;

DateTimePick.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  name: PropTypes.string,
};
