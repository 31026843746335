import { Tab } from 'types/Tab';
import { useEffect, useState } from 'react';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { isEqual, map } from 'lodash';

type QueryParams = Record<string, unknown>;

export type TalentTab<T extends string> = Tab<T> & {
  queryParams?: QueryParams;
};

type TabState<T extends string> = {
  selectedTab: TalentTab<T>;
  tabs: TalentTab<T>[];
  updateSelectedTab: (tabIndex: number) => TalentTab<T>;
  getSelectedTabIndex: () => number;
  getTabIndex: (tabs: { key: string }[], key: string) => number;
};

const DEFAULT_TAB_INDEX = 0;

const getTabIndex = (tabs: { key: string }[], key: string) =>
  tabs.findIndex((tab) => tab.key === key);

export const useTabWithQueryParams = <T extends string>(tabs: TalentTab<T>[]): TabState<T> => {
  const [allTabs, setTabs] = useState(tabs);
  const { queryParams, updateQueryParams } = useQueryParams();

  const initialTab = queryParams['tab'];

  const initialTabIndex = initialTab ? getTabIndex(tabs, initialTab) : DEFAULT_TAB_INDEX;
  const tabIndex = initialTabIndex < 0 ? DEFAULT_TAB_INDEX : initialTabIndex;
  const [selectedTab, setSelectedTab] = useState<TalentTab<T>>(tabs[tabIndex]);

  const updateSelectedTab = (tabIndex: number): TalentTab<T> => {
    const updatedTabs = allTabs.map((tab) => {
      if (tab.key === selectedTab.key) {
        return { ...tab, queryParams };
      }
      return tab;
    });

    setTabs(updatedTabs);

    const newSelectedTab = updatedTabs[tabIndex];
    setSelectedTab(newSelectedTab);

    updateQueryParams({ ...newSelectedTab.queryParams, tab: newSelectedTab.key }, true);

    return newSelectedTab;
  };

  useEffect(() => {
    const hasTheSameKeys = isEqual(map(tabs, 'key'), map(allTabs, 'key'));

    if (!hasTheSameKeys) {
      setTabs(tabs);
      setSelectedTab(tabs[tabIndex]);
    }
  }, [allTabs, tabs, tabIndex]);

  const getSelectedTabIndex = () => getTabIndex(allTabs, selectedTab.key);

  return { getSelectedTabIndex, selectedTab, tabs: allTabs, updateSelectedTab, getTabIndex };
};
