import React from 'react';
import { Badge } from '@localyze-pluto/components';

type Status = 'noOpenTask' | 'task';

const statuses: Record<
  Status,
  {
    color: 'green' | 'yellow';
    label: string;
  }
> = {
  noOpenTask: {
    label: 'No open task',
    color: 'green',
  },
  task: {
    label: 'Task',
    color: 'yellow',
  },
};

export const StatusBadge = ({ status }: { status: Status }): React.JSX.Element => (
  <Badge color={statuses[status].color}>{statuses[status].label}</Badge>
);
