import React from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';

class UnderMaintenance extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="under-maintenance">
          <div className="message">
            <div className="paragraphs">
              <h1 className="title" style={{ fontWeight: 'bold' }}>
                {"We'll be back soon!"}
              </h1>
              <h3 style={{ fontWeight: 'bold' }}>Scheduled downtime on Friday March 13th 🧙‍♀️</h3>
              <p>
                We are currently updating the system to ensure a faster & improved service to you in
                the future.
              </p>
              <p>
                If you have any urgent question in the meantime, please contact the customer
                happiness team via our chat.
              </p>
              <p>Thank you for your patiente and understading!</p>
              <p>- The Localyze Team</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UnderMaintenance);
