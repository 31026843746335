import axios from 'axios';
import { CaseInformation } from 'modules/cases/types/CaseInformation';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { CASE_INFORMATIONS_QUERY_KEY } from '../useCaseInformation/useCaseInformations';

type CaseInformationsPayload = Pick<CaseInformation, 'internal_reference_code' | 'value'>[];

type UpdateCaseInformationsProps = {
  caseId: number;
  caseInformations: CaseInformationsPayload;
};

export type UpdateCaseInformationsPayload = {
  case_id: number;
  case_informations: CaseInformationsPayload;
};

const updateCaseInformations = async ({
  caseId,
  caseInformations,
}: UpdateCaseInformationsProps): Promise<void> => {
  const payload: UpdateCaseInformationsPayload = {
    case_id: caseId,
    case_informations: caseInformations,
  };

  await axios.put('/case_informations/bulk', payload);
};

export const useUpdateCaseInformations = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<void, AxiosErrorWithMessage, UpdateCaseInformationsProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<void, AxiosErrorWithMessage, UpdateCaseInformationsProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCaseInformations,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [CASE_INFORMATIONS_QUERY_KEY],
      });
      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
