import React from 'react';
import { Box } from '@localyze-pluto/components';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { AccountEntry } from '../types/AccountEntry';
import { FormattedAmount } from 'modules/billing/components/FormattedAmount/FormattedAmount';
import { TalentLink } from 'modules/billing/components/TalentLink';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import AccountEntryMenuCell from '../components/AccountEntryMenuCell/AccountEntryMenuCell';
import { StatusCell } from '../components/StatusCell/StatusCell';

const columnHelper = createColumnHelper<AccountEntry>();

// there is a problem with tanstack-table and the typing of diverse fields
// if you want to have fields with more than one data type, you have to define them explicitly
// see this link for more details and updates on the issue:
// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BILLING_DETAILS_COLUMNS: ColumnDef<AccountEntry, any>[] = [
  columnHelper.accessor('description', {
    id: 'description',
    cell: (props) => (<Box.div minWidth="200px"> {props.getValue()}</Box.div>) as React.JSX.Element,
    header: 'Service',
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'invoiceable',
    cell: (props) => <TalentLink invoiceable={props.row.original.invoiceable} />,
    header: 'Talent name',
    enableSorting: false,
  }),
  columnHelper.accessor('amount', {
    id: 'amount',
    cell: (props) => (
      <FormattedAmount
        accountEntryType={props.row.original.type}
        amount={props.row.original.amount}
        currency={props.row.original.currency}
      />
    ),
    header: 'Amount',
    enableSorting: false,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (props) => <StatusCell accountEntry={props.row.original} />,
    header: 'Status',
    enableSorting: false,
  }),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    cell: (props) => formatDate(props.getValue()),
    header: 'Created at',
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'menu',
    cell: (props) => <AccountEntryMenuCell accountEntry={props.row.original} />,
    header: '',
    enableSorting: false,
  }),
];
