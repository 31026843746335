import React, { LiHTMLAttributes, ReactNode } from 'react';
import cx from 'clsx';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';

export type ListItemProps = Omit<LiHTMLAttributes<HTMLLIElement>, 'prefix'> & {
  readonly children: ReactNode;
  readonly prefix?: ReactNode;
  readonly suffix?: ReactNode | string;
  readonly className?: string;
  readonly contentContainerClassName?: string;
  readonly contentClassName?: string;
  readonly isLast?: boolean;
};

export const ListItem = ({
  children,
  prefix,
  suffix,
  className,
  contentContainerClassName,
  contentClassName,
  isLast,
  ...rest
}: ListItemProps): React.JSX.Element => (
  <>
    <li className={cx(className, 'tw-border-0 tw-border-solid')} {...rest}>
      <div
        className={cx('tw-flex tw-justify-between tw-p-6', contentContainerClassName)}
        data-testid="doc-list-item-content-container"
      >
        <div className={contentClassName} data-testid="doc-list-item-content">
          {prefix && <span className="tw-pr-3">{prefix}</span>}
          {children}
        </div>
        {suffix && (
          <span className="tw-text-xs tw-leading-none tw-font-normal tw-text-gray-500">
            {suffix}
          </span>
        )}
      </div>
    </li>
    {isLast ? null : <SectionDivider />}
  </>
);
