import { Box, Icon, Text } from '@localyze-pluto/components';

import React from 'react';

export const HeaderSkeleton = (): React.JSX.Element => {
  return (
    <Box.div animation="pulse" display="grid" gap="d7" gridTemplateColumns="repeat(4, 1fr)">
      <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius20" h={110} />
      <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius20" h={110} />
      <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius20" h={110} />
      <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius20" h={110} />
    </Box.div>
  );
};

export const MainSkeleton = (): React.JSX.Element => {
  return (
    <Box.div
      alignItems="center"
      animation="pulse"
      backgroundColor="bgPrimary"
      borderRadius="borderRadius20"
      display="flex"
      h="600px"
      justifyContent="center"
      w="100%"
    >
      <Box.div textAlign="center">
        <Box.div animation="spin" color="colorTextLinkStrong" display="inline-flex">
          <Icon decorative icon="earth" size="sizeIcon40" />
        </Box.div>
        <Text.div fontSize="fontSize20">Loading...</Text.div>
      </Box.div>
    </Box.div>
  );
};
