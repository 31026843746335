import { Box } from '@localyze-pluto/components';
import React from 'react';

export const BackgroundHeader = ({
  children,
  backgroundImage,
}: {
  children: React.ReactNode;
  backgroundImage?: string;
}): React.JSX.Element => {
  return (
    <Box.div
      backgroundColor="colorBackgroundPrimaryStrong"
      backgroundImage={`url(${backgroundImage})`}
      backgroundPosition="right top"
      backgroundRepeat="no-repeat"
      borderRadius="borderRadius40"
      display="flex"
      flexDirection="column"
      position="relative"
      px="p12"
      py="p8"
      w="100%"
    >
      {children}
    </Box.div>
  );
};
