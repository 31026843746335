import React, { useEffect } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { StatusBadge } from 'modules/billing/components/StatusBadge/StatusBadge';
import { BillingStatus } from 'modules/billing/types/BillingStatus';
import { BillingHeader } from 'modules/billing/pages/BillingHeader';
import { Box, Callout } from '@localyze-pluto/components';
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';
import { DataTable } from 'components/DataTable/DataTable';
import { BillingCompany } from 'modules/billing/types/BillingCompany';
import { useBillingCompanies } from 'modules/billing/hooks/useBillingCompanies/useBillingCompanies';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { useHistory } from 'react-router-dom';
import { useSearch } from 'modules/filters/hooks/useSearch';
import { usePagination } from 'modules/filters/hooks/usePagination';
import isEmpty from 'lodash/isEmpty';
import { useParameterFilter } from 'modules/filters/hooks/useParameterFilter';
import { useBillingPeriods } from '../hooks/useBillingPeriods/useBillingPeriods';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { PaginationWithCounter } from 'components/PaginationWithCounter/PaginationWithCounter';

const columnHelper = createColumnHelper<BillingCompany>();

const columns: ColumnDef<BillingCompany, BillingStatus>[] = [
  columnHelper.accessor('name', {
    id: 'name',
    cell: (props) => props.getValue(),
    header: 'Company',
    enableSorting: false,
  }),
  columnHelper.accessor('billing_status', {
    id: 'billing_status',
    cell: (props) => <StatusBadge status={props.getValue()} />,
    header: 'Status',
    enableSorting: false,
  }),
];

export const Billing = (): React.JSX.Element => {
  const history = useHistory();
  const [page, setPage] = usePagination();
  const [billingPeriodId, setBillingPeriodId] = useParameterFilter('billing_period_id');
  const { data: billingPeriods } = useBillingPeriods();
  const { search, onSearch, debouncedSearch } = useSearch();

  const { data, isPending, isSuccess, refetch } = useBillingCompanies({
    params: {
      page,
      search: debouncedSearch,
      billingPeriodId,
    },
  });

  useEffect(() => {
    refetch();
  }, [page, search, billingPeriodId, refetch]);

  const { queryParams } = useQueryParams();

  const handleRowClick = (row: Row<BillingCompany>) => {
    let billingPeriod = '';
    if (queryParams.billing_period_id) {
      billingPeriod = `?billing_period_id=${queryParams.billing_period_id}`;
    }

    history.push(`/billing/${row.original.id}${billingPeriod}`);
  };

  const selectedBillingPeriod = billingPeriods?.find(
    (period) => period.id === Number(billingPeriodId),
  );

  const shouldShowCallout = (selectedBillingPeriod?.entries_needs_review_count || 0) > 0;

  return (
    <PageLayout flexed title="Billing">
      <BillingHeader
        billingPeriodId={billingPeriodId}
        onSearch={onSearch}
        search={search}
        setBillingPeriodId={setBillingPeriodId}
      />
      {shouldShowCallout && (
        <Box.div marginBottom="m4">
          <Callout icon="circle-alert">
            {`There are open debits in the ${selectedBillingPeriod?.name} billing
            cycle that need to be reviewed and sent to Stripe.`}
          </Callout>
        </Box.div>
      )}
      {isPending ? (
        <ContainedLoadingState />
      ) : !isSuccess ? (
        <ErrorStatePage />
      ) : (
        <Box.div marginBottom="m4">
          <DataTable<BillingCompany, BillingStatus>
            columns={columns}
            data={data.companies}
            emptyStateText="No companies"
            handleRowClick={handleRowClick}
            isRowClickable
            testId={'billing-table'}
          />
        </Box.div>
      )}
      {isSuccess && !isEmpty(data.companies) && (
        <PaginationWithCounter paginationInfo={data.meta} setPage={setPage} />
      )}
    </PageLayout>
  );
};
