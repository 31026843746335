import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { Country } from 'modules/countries/types/Country';
import { CountryParams, UseCountryProps } from './useCountries.types';

/**
 * Makes a get request to fetch countries
 * @param params.association - filters
 * @param params.filter - filters results based on matching string
 * @returns countries
 */
const getCountries = async (params: CountryParams = {}): Promise<Country[]> => {
  const { data } = await axios.get<Country[]>('/countries', { params });

  return data;
};

/**
 * Gets list of countries
 * @param params.association - filters
 * @param params.filter - filters results based on matching string
 * @param select - transform returned data
 * @returns countries
 */
export const useCountries = <Result = Country[]>({
  params,
  ...props
}: UseCountryProps<Result>): UseQueryResult<Result, AxiosError> =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['countries', params?.association, params?.filter],
    queryFn: () => getCountries(params),
    ...props,
  });
