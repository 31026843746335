import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import axios, { AxiosError } from 'axios';
import { Currency } from 'types/currency';
import { AllowanceFunding } from 'deprecated/api/allowance_fundings/types/AllowanceFunding';
import { queryClient } from 'config/queryClient';
import { getCaseQueryKey } from 'modules/cases/api/useCase/useCase';

const CENTS = 100;

export type AddAllowanceFundingProps = {
  caseId: number;
  amount: number;
  currency: Currency;
};

export const addAllowanceFunding = async ({
  caseId,
  amount,
  currency,
}: AddAllowanceFundingProps): Promise<unknown> => {
  const payload: AllowanceFunding = {
    price_cents: amount * CENTS,
    currency: currency,
    case_id: caseId,
  };
  const res = await axios.post<AllowanceFunding>('/allowance_fundings', payload);

  return res.data;
};

export const useAddAllowanceFunding = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, AddAllowanceFundingProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, AddAllowanceFundingProps> =>
  useMutation({
    mutationFn: addAllowanceFunding,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getCaseQueryKey(variables.caseId),
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
