import { PDF_TEMPLATE_IDS } from 'deprecated/api/templates/index';
import React from 'react';
import { usePdfGenerator } from 'deprecated/hooks/custom/usePdfGenerator';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { Button, Menu, useModalStore } from '@localyze-pluto/components';
import { LETTERS_AUTOMATION } from 'modules/featureFlags/types/flags';
import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon';
import { LettersAutomation } from 'modules/caseDetails/components/Documents/components/DocumentFillingMenu/components/LettersAutomation';
import { ZavTemplateModal } from 'modules/caseDetails/components/Documents/components/DocumentFillingMenu/components/ZavTemplateModal';

export const DocumentFillingMenu = ({ caseId }: { caseId: number }): React.JSX.Element => {
  const isLettersAutomationActive = useIsFeatureFlagActive(LETTERS_AUTOMATION);

  const [submittingZav, openGeneratedZavPdf] = usePdfGenerator({
    caseId,
    pdfTemplateId: PDF_TEMPLATE_IDS['ZAV'],
  });

  const [submittingEuBlueCard, openGeneratedEuBlueCardPdf] = usePdfGenerator({
    caseId,
    pdfTemplateId: PDF_TEMPLATE_IDS['EU_BLUE_CARD'],
  });

  const lettersModalState = useModalStore();
  const isLetterModalOpen = lettersModalState.useState('open');

  const zavModalState = useModalStore();
  const isZavModalOpen = zavModalState.useState('open');

  const isLoading = submittingZav || submittingEuBlueCard;
  return (
    <>
      <Menu
        items={[
          {
            label: 'DE - ZAV PDF',
            onClick: openGeneratedZavPdf,
            disabled: submittingZav,
          },
          {
            label: 'DE - ZAV Beiblatt',
            onClick: zavModalState.show,
          },
          {
            label: 'NL - Blue Card',
            onClick: openGeneratedEuBlueCardPdf,
            disabled: submittingEuBlueCard,
          },
          ...(isLettersAutomationActive
            ? [
                {
                  label: 'Generate Letters',
                  onClick: lettersModalState.show,
                },
              ]
            : []),
        ]}
        menuButton={
          <Button loading={isLoading} trailingIcon="chevron-down" type="button" variant="secondary">
            Document filling
            {isLoading ? <LoadingIcon size="sizeIcon10" /> : ''}
          </Button>
        }
      />
      {isZavModalOpen && <ZavTemplateModal caseId={caseId} modalState={zavModalState} />}
      {isLettersAutomationActive && isLetterModalOpen && (
        <LettersAutomation modalState={lettersModalState} />
      )}
    </>
  );
};
