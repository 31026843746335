export const NAVBAR = {
  COMPANIES: 'Companies',
  PRICES: 'Prices',
  COMPANY_PROFILE: 'Company profile',
  HOME: 'Home',
  INTEGRATIONS: 'Integrations',
  INVOICES: 'Invoices',
  KNOWLEDGE: 'Knowledge',
  MY_TASKS: 'My tasks',
  OFFICES: 'Offices',
  PIPELINE_EDITOR: 'Pipeline editor',
  PRE_CHECK: 'Pre-Check',
  REPORTING: 'Reporting',
  SECURITY: 'Security',
  SETTINGS: 'Settings',
  SUBSCRIPTION: 'Subscription',
  TEAM: 'Team',
  VARIABLES: 'Variables',
  BILLING: 'Billing',
  REQUESTS: 'Manage Requests',
  MY_CASES: 'My Cases',
  MY_TALENT: 'My Talent',
};
