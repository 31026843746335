import axios from 'axios';
import { Office } from 'modules/offices/types/Office';

/**
 * Fetches all offices for a company
 * @param params.companyId
 * @returns offices
 */
export const getOffices = async (companyId?: number): Promise<Office[]> => {
  if (companyId === undefined) {
    throw new Error("Company ID is required to fetch company's offices");
  }

  const { data } = await axios.get<Office[]>(`/companies/${companyId}/offices`, {
    headers: { Accept: 'application/json' },
  });

  return data;
};
