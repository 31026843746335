import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { KnowledgeDocument } from 'modules/knowledge/types/KnowledgeDocument';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

const getKnowledgeDocuments = async (): Promise<KnowledgeDocument[]> => {
  const documents = await new ContentfulAPI().fetchKnowledgeDocuments();

  return documents.map((document) => ({
    name: document.nameOfTheDocument,
    publicFilePath: document.pdf.fields.file.url.replace('//assets', 'https://images'),
    date: formatDate(document.pdf.sys.updatedAt),
  }));
};

export const useKnowledgeDocuments = (): UseQueryResult<KnowledgeDocument[]> => {
  return useQuery({ queryKey: ['knowledge-documents'], queryFn: getKnowledgeDocuments });
};
