import { QueryClient } from '@tanstack/react-query';

// TODO: Consider a global staleTime if more queries need it
// Currently, it’s set per query to reduce unnecessary re-fetching
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      throwOnError: true,
      retry: false,
    },
  },
});
