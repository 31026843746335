import { axios, configAxios } from 'config/axios/axios';

export const companyStateOptions = (callbackSuccess, callbackError) => {
  axios('/admin/companies/company_state', {
    method: 'get',
    config: configAxios,
  })
    .then((response) => callbackSuccess(response))
    .catch((error) => callbackError(error));
};

export const updateCompany = (id, data, callbackSuccess, callbackError) => {
  axios(`/admin/companies/${id}`, {
    method: 'put',
    config: configAxios,
    data,
  })
    .then((response) => callbackSuccess(response))
    .catch((error) => callbackError(error));
};
