import { Anchor, Badge, Box, Heading, Paragraph } from '@localyze-pluto/components';
import React, { useEffect } from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';

import { Link } from 'react-router-dom';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { PreCheckForm } from './components/PreCheckForm';
import { formatPrice } from 'utils/formatters/formatPrice/formatPrice';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { trackPageView } from 'modules/analytics/analytics';
import { useCurrentUser } from 'config/CurrentUserContext';

export const PreCheck = (): React.JSX.Element => {
  const isPaidPrechecksActive = useIsFeatureFlagActive('paid_prechecks');

  const { company_id } = useCurrentUser();
  const {
    data: company,
    isPending,
    isSuccess: isCompanyQuerySuccess,
  } = useCompany({
    companyId: company_id,
  });

  useEffect(() => {
    trackPageView('Precheck');
  }, []);

  return (
    <PageLayout flexed maxW={1000} mx="auto" px="d8" title="Our experts are standing by...">
      <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius40" padding="d9">
        {isPending ? (
          <Box.div alignItems="center" display="flex" flexDirection="column">
            <ContainedLoadingState />
          </Box.div>
        ) : (
          <>
            <Box.div
              columnGap="d12"
              display="grid"
              gridTemplateColumns={{ lg: 2 }}
              marginBottom="m12"
            >
              <Box.div marginBottom="m12">
                <Box.div alignItems="center" display="flex" gap="d3" marginBottom="m3">
                  <Heading as="h2" marginBottom="m0" size="heading60">
                    Request a Pre-check
                  </Heading>
                  {isPaidPrechecksActive && isCompanyQuerySuccess && (
                    <Badge size="large">{formatPrice(79, company.currency)}</Badge>
                  )}
                </Box.div>
                <Paragraph>
                  Request a pre-check when you are unsure about a candidate’s eligibility, timeline,
                  or process.*
                </Paragraph>
                <Paragraph>
                  Looking for information about embassy and consulate wait times?{' '}
                  <Anchor
                    as={Link}
                    onClick={() => trackEvent('precheck: view knowledge page')}
                    to="/company/knowledge"
                  >
                    Check out our knowledge page.
                  </Anchor>
                </Paragraph>
              </Box.div>
              <PreCheckForm />
            </Box.div>
            <Box.div
              backgroundColor="colorBackgroundWeaker"
              borderRadius="borderRadius10"
              padding="p4"
            >
              <Paragraph>
                *Our feedback on your pre-checks are researched with utmost care and are based on
                Localyze&apos;s experience with 3000+ global mobility processes. Keep in mind
                though, the information provided does not contain legal advice. For the solution of
                specific legal matters, please consult a lawyer.
              </Paragraph>
            </Box.div>
          </>
        )}
      </Box.div>
    </PageLayout>
  );
};
