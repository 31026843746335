import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { queryClient } from 'config/queryClient';
import { CASE_ADDITIONAL_SERVICES_QUERY_KEY } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { Case } from 'modules/cases/types/Case';

type CreateCaseAdditionalServiceProps = {
  kase: Case;
  additionalServiceId: number;
};

export type CreateCaseAdditionalServicePayload = {
  additional_service_id: number;
  case_id: number;
};

async function createCaseAdditionalService(caseId: number, additionalServiceId: number) {
  const params: CreateCaseAdditionalServicePayload = {
    additional_service_id: additionalServiceId,
    case_id: caseId,
  };
  return await axios.post('/case_additional_services', params);
}

export const useCreateCaseAdditionalService = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, CreateCaseAdditionalServiceProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, CreateCaseAdditionalServiceProps> =>
  useMutation({
    mutationFn: (props) => {
      return createCaseAdditionalService(props.kase.id, props.additionalServiceId);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [CASE_ADDITIONAL_SERVICES_QUERY_KEY, String(variables.kase.id)],
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
