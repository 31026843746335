import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { AdditionalService } from 'modules/additionalServices/types/AdditionalService';
import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { RawAdditionalServiceContent } from '../../types/AdditionalServiceContent';
import { mapAdditionalServiceContentById } from 'modules/additionalServices/api/useAdditionalServices/mapAdditionalServiceContentById/mapAdditionalServiceContentById';

export const ADDITIONAL_SERVICES_QUERY_KEY = 'additional-services';

/**
 * Make a get request to fetch all additional services
 * @param destinationCountryId - additional services will get filtered by this destination country
 * @returns additional services
 */
const getAdditionalServices = async (
  destinationCountryId?: number,
): Promise<AdditionalService[]> => {
  const { data } = await axios.get<AdditionalService[]>(
    `/additional_services`,
    destinationCountryId !== undefined
      ? {
          params: {
            ['filter[destination_country_id]']: destinationCountryId,
          },
        }
      : undefined,
  );

  return data;
};

/**
 * Gets all of the additional services (filtered for destination country if provided)
 * and text from Contentful
 * @param destinationCountryId - additional services will get filtered by this destination country
 * @returns additional services
 */
export const useAdditionalServices = <Result = AdditionalService[]>({
  destinationCountryId,
  enabled = true,
  select,
}: Pick<UseQueryOptions<AdditionalService[], AxiosError, Result>, 'enabled' | 'select'> & {
  destinationCountryId?: number;
} = {}): UseQueryResult<Result, AxiosError> => {
  const contentfulAPI = new ContentfulAPI();

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey:
      destinationCountryId !== undefined
        ? [ADDITIONAL_SERVICES_QUERY_KEY, destinationCountryId]
        : [ADDITIONAL_SERVICES_QUERY_KEY],
    queryFn: async () => {
      const [additionalServices, content] = await Promise.all([
        getAdditionalServices(destinationCountryId),
        contentfulAPI.fetchAdditionalServices() as Promise<RawAdditionalServiceContent[]>,
      ]);

      const mappedContent = mapAdditionalServiceContentById(content);

      return additionalServices.map((service) => {
        // There may not be content for that service but TS doesn't realize it
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const serviceContent = mappedContent[service.id] || {};

        return {
          ...service,
          name: serviceContent.name || service.name,
          description: serviceContent.description || service.description,
        } as AdditionalService;
      });
    },
    enabled,
    select,
  });
};
