import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StatusUpdates } from 'modules/statusUpdates/components/StatusUpdates';
import { Box, useToast } from '@localyze-pluto/components';
import { useStatusUpdates } from 'modules/statusUpdates/api/useStatusUpdates/useStatusUpdates';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { PostNewUpdate } from './PostNewUpdate';
import { useCurrentUser } from 'config/CurrentUserContext';

export const Updates = (): React.JSX.Element => {
  const { id } = useParams<{
    id: string;
  }>();
  const caseId = Number(id);
  const {
    data: updates,
    isSuccess,
    isError,
    error,
  } = useStatusUpdates({
    subjectType: 'Case',
    subjectId: caseId,
    enabled: !!caseId,
  });
  const { isAdmin } = useCurrentUser();
  const toast = useToast();

  useEffect(() => {
    if (isError) {
      toast(error.message || 'Error fetching status updates.', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  if (!isSuccess) {
    return <ContainedLoadingState />;
  }

  return (
    <Box.div display="grid" gridTemplateColumns={isAdmin ? '2fr 1fr' : undefined} h="100%">
      <StatusUpdates isDeletable={isAdmin} padding="d6" updates={updates} />
      {isAdmin && <PostNewUpdate />}
    </Box.div>
  );
};
