import { DocumentList } from './DocumentList/DocumentList';
import React, { useEffect, useState } from 'react';
import { DeleteDocument } from './DeleteDocument';
import { DocumentAnchor } from './DocumentAnchor';
import { DocumentType } from 'modules/documents/api/attachments/types/DocumentType';
import { FileUploadModal } from 'components/FileUploadModal/FileUploadModal';
import { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';
import { cancelAllUploadsToS3 } from 'deprecated/api/uploads/uploadToS3';
import { updateAttachmentAndShrineIt } from 'modules/documents/api/attachments/updateAttachmentAndShrineIt';
import { Box, Button, Icon, useModalStore, useToast } from '@localyze-pluto/components';
import { AxiosError } from 'axios';
import { useUploadDocument } from '../api/useUploadDocument/useUploadDocument';

export type DocumentListItemProps = DocumentWithUrl & {
  type?: DocumentType;
  id?: number;
  isLast: boolean;
  caseId: number;
};

const INITIAL_PROGRESS = 0;

export const DocumentListItem = ({
  caseId,
  docName,
  docCode,
  docDate,
  docUrl,
  type,
  id,
  isLast,
}: DocumentListItemProps): React.JSX.Element => {
  const uploadModal = useModalStore();
  const deleteModal = useModalStore();
  const toast = useToast();

  const isUploadModalOpen = uploadModal.useState('open');
  const isDeleteModalOpen = deleteModal.useState('open');

  const [progress, setProgress] = useState(INITIAL_PROGRESS);
  const [fileUploadError, setFileUploadError] = useState<string | undefined>(undefined);
  const { mutate: uploadDocument } = useUploadDocument({
    onSuccess: () => {
      setProgress(INITIAL_PROGRESS);
      toast(`${docName} was uploaded successfully`, 'success');
    },
    onError: (error: AxiosError) => {
      toast(error.message || 'Error uploading document.', 'error');
      setProgress(INITIAL_PROGRESS);
    },
  });

  const fireRequest = async (files: File[]) => {
    if (type === 'Attachment') {
      const response = await updateAttachmentAndShrineIt(Number(id), files[0], setProgress, () => {
        setProgress(INITIAL_PROGRESS);
      });

      if (response) {
        setProgress(INITIAL_PROGRESS);
        toast(`${docName} was successfully updated`, 'success');
        uploadModal.hide();
      }
    } else {
      if (!caseId) return;

      uploadDocument({
        referenceCode: docCode || docName,
        file: files[0],
        caseId,
        onProgress: setProgress,
      });
    }
  };

  const onSubmit = async (files: File[]) => {
    setFileUploadError(undefined);

    try {
      await fireRequest(files);
    } catch (error) {
      setFileUploadError('Something went wrong. Try uploading your file again.');
      setProgress(INITIAL_PROGRESS);
    }
  };

  useEffect(() => {
    if (!isUploadModalOpen) {
      setProgress(INITIAL_PROGRESS);
      setFileUploadError(undefined);
      cancelAllUploadsToS3();
    }
  }, [isUploadModalOpen]);

  return (
    <DocumentList.Item
      contentClassName="tw-flex tw-items-center"
      contentContainerClassName="tw-items-center"
      isLast={isLast}
      prefix={<Icon color="colorText" decorative icon="paperclip" size="sizeIcon30" />}
      suffix={
        <Box.div alignItems="center" display="flex" gap="d2">
          <span>{docDate}</span>
          <Button
            aria-label="Update document"
            data-testid="update-document-button"
            iconOnly
            leadingIcon="upload"
            onClick={uploadModal.show}
            type="button"
            variant="ghost"
          />
          <Button
            aria-label="Delete document"
            data-testid="delete-document-button"
            iconOnly
            leadingIcon="trash-2"
            onClick={deleteModal.show}
            title="Delete document"
            type="button"
            variant="ghost"
          />
        </Box.div>
      }
    >
      <DocumentAnchor href={docUrl} label={docName} />
      {isUploadModalOpen && (
        <FileUploadModal
          error={fileUploadError}
          heading={`Upload ${docName}`}
          onCancel={() => {
            cancelAllUploadsToS3();
            setProgress(INITIAL_PROGRESS);
          }}
          onClose={cancelAllUploadsToS3}
          onDrop={onSubmit}
          progress={progress}
          state={uploadModal}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteDocument
          caseId={caseId}
          code={docCode}
          id={id}
          name={docName}
          state={deleteModal}
          type={type}
        />
      )}
    </DocumentList.Item>
  );
};
