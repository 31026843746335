import { Box, Icon, Text } from '@localyze-pluto/components';
import { Status, StepCircle } from '../Step/StepCircle';
import React from 'react';
import { styled } from '@localyze-pluto/theme';

const CollapsibleIconContainer = styled.div`
  transition: all 0.2s;
  transform: ${(props: { isOpen: boolean }) => props.isOpen && 'rotate(-180deg)'};
`;

export const PipelineTitle = ({
  title,
  areStepsOpened,
  onClick,
  status,
}: {
  title: string;
  areStepsOpened?: boolean;
  onClick?: () => void;
  status: Status;
}): React.JSX.Element => {
  return (
    <Box.div
      alignItems="center"
      backgroundColor={{
        _: 'bgSecondary',
        hover: 'colorBorderWeakest',
      }}
      borderRadius="borderRadius30"
      cursor="pointer"
      display="flex"
      justifyContent="space-between"
      marginBottom="m0"
      onClick={onClick}
      padding="p4"
    >
      <Box.div alignItems="center" display="flex" gap="d4">
        <StepCircle disabled={true} status={status} />
        <Text.span fontSize="fontSize30" fontWeight="fontWeightBold" lineHeight="lineHeight40">
          {title}
        </Text.span>
      </Box.div>
      <Box.div
        alignItems="center"
        as={CollapsibleIconContainer}
        display="flex"
        h="36"
        isOpen={areStepsOpened}
        justifyContent="center"
        w="36"
      >
        <Icon decorative icon="chevron-down" size="sizeIcon30" />
      </Box.div>
    </Box.div>
  );
};
