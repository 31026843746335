import { DialogStore } from '@ariakit/react';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { useForm } from 'react-hook-form';
import { usePipelines } from 'modules/caseDetails/hooks/usePipelines';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useAssignPipeline } from 'modules/pipelines/hooks/useAssignPipeline';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { SERVICE_LEVELS } from 'modules/cases/components/ServiceLevelOptions/serviceLevels';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

type AssignPipelineFormValues = {
  pipelineId: string;
};

const validationSchema: Yup.ObjectSchema<AssignPipelineFormValues> = Yup.object({
  pipelineId: Yup.string().required('This field is required'),
});

export const AssignPipelineModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const toast = useToast();
  const routes = useRoutes();

  const { data: pipelines, isPending } = usePipelines();
  const pipelineOptions = (pipelines || []).map((pipeline) => ({
    label: pipeline.title,
    value: String(pipeline.id),
  }));

  const { mutate: assignPipeline, isPending: isAssigningPipeline } = useAssignPipeline({
    onSuccess: () => {
      toast('Pipeline assigned successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Pipeline could not be assigned', 'error'),
  });

  const kaseServiceLevel = SERVICE_LEVELS.find(
    (serviceLevel) => serviceLevel.id === kase.service_level_id,
  );
  const isBasicServiceLevel = kaseServiceLevel?.label === 'Basic';

  const { control, getValues } = useForm({
    defaultValues: { pipelineId: '' },
    resolver: yupResolver(validationSchema),
  });

  const getEventProperties = (pipelineId: string) => ({
    talentId: kase.talent_id,
    caseId: kase.id,
    pipelineId,
    pipelineTitle: pipelineOptions.find((option) => option.value === pipelineId)?.label,
    serviceLevel: kaseServiceLevel?.label,
  });

  const openCaseEvaluationSummaryForm = () => {
    const pipelineId = getValues('pipelineId');

    trackEvent(
      'case evaluation summary: click on complete case evaluation button',
      getEventProperties(pipelineId),
    );
    window.open(routes.createCaseEvaluationSummary.get(kase.id, pipelineId), '_blank');
    modal.hide();
  };

  const assignPipelineWithoutCaseEvaluation = () => {
    const pipelineId = getValues('pipelineId');

    trackEvent(
      isBasicServiceLevel
        ? 'pipeline: click on assign button'
        : 'case evaluation summary: click on skip button',
      getEventProperties(pipelineId),
    );
    assignPipeline({ pipelineId: Number(pipelineId), caseId: kase.id });
  };

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Assign pipeline</ModalHeading>
      </ModalHeader>
      {isPending ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Box.form id="assign-pipeline-form">
              <ControlledFormSelect
                control={control}
                disabled={isAssigningPipeline}
                id="pipelineId"
                items={pipelineOptions}
                label="Pipeline"
                name="pipelineId"
                placeholder="Select pipeline"
                required
              />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            {isBasicServiceLevel ? (
              <Button
                disabled={isAssigningPipeline}
                loading={isAssigningPipeline}
                onClick={assignPipelineWithoutCaseEvaluation}
                variant="primary"
              >
                Assign pipeline
              </Button>
            ) : (
              <>
                <Button
                  disabled={isAssigningPipeline}
                  loading={isAssigningPipeline}
                  onClick={assignPipelineWithoutCaseEvaluation}
                  variant="secondary"
                >
                  Assign without case evaluation
                </Button>
                <Button
                  disabled={isAssigningPipeline}
                  loading={isAssigningPipeline}
                  onClick={openCaseEvaluationSummaryForm}
                  variant="primary"
                >
                  Assign with case evaluation
                </Button>
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
