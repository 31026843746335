import { Box, BoxProps, Button, Heading, Paragraph } from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import React, { useState } from 'react';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { RequiredActionsList } from '../RequiredActionsList/RequiredActionsList';
import { RiskLevelBadge } from '../RiskLevelBadge/RiskLevelBadge';
import { trackEvent } from 'deprecated/utils/helper/segment';

export const RiskAssessmentResultSection = ({
  glomoRequest,
  onClickApprove,
  onClickDeny,
}: {
  glomoRequest: GlomoRequest;
  onClickApprove: () => void;
  onClickDeny: () => void;
}): React.JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { risk_level, required_actions } = glomoRequest;

  return (
    <Box.div marginBottom="d4">
      <SectionCard>
        <SectionCard.Header padding={'d4 d6' as BoxProps['padding']}>
          <Box.div alignItems="center" display="flex" flexDirection="row" gap="d4" padding="d0">
            <Heading as="h3" color="contentPrimary" marginBottom="d0" size="title-subsection">
              Risk assessment results
            </Heading>
            {isExpanded && risk_level && <RiskLevelBadge riskLevel={risk_level} />}
          </Box.div>
        </SectionCard.Header>
        <SectionCard.Content>
          <Box.div w="100%">
            {!isExpanded && (
              <Box.div display="grid" gridTemplateColumns="204px auto">
                <Box.div
                  borderRightColor="dividerBorderDefault"
                  borderRightStyle="borderStyleSolid"
                  borderRightWidth="borderWidth10"
                  paddingRight="d12"
                >
                  <Heading
                    as="h4"
                    color="contentPrimary"
                    fontSize="titleGroup"
                    fontWeight="fontWeightBold"
                    lineHeight="lh6"
                    marginBottom="d2"
                  >
                    Overall risk level
                  </Heading>

                  {risk_level && <RiskLevelBadge riskLevel={risk_level} />}
                </Box.div>
                <Box.div paddingLeft="d12">
                  <Heading
                    as="h4"
                    color="contentPrimary"
                    fontSize="titleGroup"
                    fontWeight="fontWeightBold"
                    lineHeight="lh6"
                    marginBottom="d2"
                  >
                    What happens now?
                  </Heading>
                  <Paragraph
                    color="contentPrimary"
                    fontSize="fontSize20"
                    lineHeight="lh5"
                    marginBottom="d4"
                  >
                    Review the risk assessment results below. Before approving or denying the trip,
                    view the required actions to minimize risks on the next step.
                  </Paragraph>
                  <Button
                    onClick={() => {
                      setIsExpanded(true);

                      trackEvent('glomo request: click view required actions', {
                        user_type: 'hr',
                        status: glomoRequest.status,
                        company_id: glomoRequest.company_id,
                        glomo_request_id: glomoRequest.id,
                      });
                    }}
                    variant="primary"
                  >
                    View required actions
                  </Button>
                </Box.div>
              </Box.div>
            )}
            {isExpanded && (
              <Box.div>
                <Heading
                  as="h4"
                  color="contentPrimary"
                  fontSize="titleGroup"
                  fontWeight="fontWeightBold"
                  lineHeight="lh6"
                  marginBottom="d2"
                >
                  Approve trip
                </Heading>
                <Paragraph
                  color="contentPrimary"
                  fontSize="fontSize20"
                  fontWeight="fontWeightRegular"
                  lineHeight="lh5"
                  marginBottom="d8"
                  textAlign="left"
                >
                  If you approve this trip, we will initiate the following required actions to
                  minimize the risks. The cost of completing these actions through Localyze is 149€.
                </Paragraph>
                <RequiredActionsList requiredActions={required_actions} />
                <Box.div
                  borderBottomColor="dividerBorderDefault"
                  borderBottomStyle="borderStyleSolid"
                  borderBottomWidth="borderWidth10"
                  textAlign="center"
                >
                  <Button onClick={onClickApprove} variant="primary">
                    Approve trip and required actions (for 149€)
                  </Button>
                  <Box.span display="block" paddingBottom="d3" paddingTop="d4">
                    or
                  </Box.span>
                </Box.div>
                <Box.div
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  paddingTop="d12"
                  textAlign="center"
                >
                  <Heading
                    as="h5"
                    color="contentPrimary"
                    marginBottom="d2"
                    size="title-group"
                    w="100%"
                  >
                    Deny trip
                  </Heading>
                  <Paragraph fontSize="fontSize20" marginBottom="d4" maxWidth={475}>
                    If you deny the trip, the request will be closed and no further actions will be
                    taken. The employee will be informed of your decision.
                  </Paragraph>
                  <Button onClick={onClickDeny} variant="secondary">
                    Deny trip
                  </Button>
                </Box.div>
              </Box.div>
            )}
          </Box.div>
        </SectionCard.Content>
      </SectionCard>
    </Box.div>
  );
};
