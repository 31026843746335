import isUndefined from 'lodash/isUndefined';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';
import { SelectItemProps } from '@localyze-pluto/components';

type UserWithName = { first_name: string; last_name: string };

export const fullName = (user: UserWithName): string => `${user.first_name} ${user.last_name}`;

export const mapUsersToSelectOptions = (users?: UserWithName[]): SelectItemProps[] => {
  if (isUndefined(users)) {
    return [];
  }

  return mapToSelectOptions({ records: users, labelProp: fullName, valueProp: 'id', sorted: true });
};
