import {
  BillingCompaniesResponse,
  BillingCompanyParams,
  UseBillingCompaniesProps,
} from 'modules/billing/hooks/useBillingCompanies/useBillingCompanies.types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { identity, pickBy } from 'lodash';

export const BILLING_COMPANIES_QUERY_KEY = 'billing-companies';

const getBillingCompanies = async (
  params?: BillingCompanyParams,
): Promise<BillingCompaniesResponse> => {
  const filteredParams = params
    ? pickBy(
        {
          search: params.search,
          billing_period_id: params.billingPeriodId !== 'all' ? params.billingPeriodId : null,
          page: params.page,
        },
        identity,
      )
    : undefined;

  const { data } = await axios.get<BillingCompaniesResponse>(
    '/billing/companies',
    filteredParams
      ? {
          params: filteredParams,
        }
      : undefined,
  );

  return data;
};

/**
 * Gets list of companies for billing
 * @param props.select - transform returned data
 * @param props.params.page - which page to fetch
 * @param props.params.search - filters for company by name
 * @param props.params.billingPeriodId - filters for companies with account entries in
 *                                       the given period
 * @returns companies
 */
export const useBillingCompanies = <Result = BillingCompaniesResponse>({
  select,
  params,
}: UseBillingCompaniesProps<Result>): UseQueryResult<Result, AxiosError> => {
  return useQuery({
    queryKey: [BILLING_COMPANIES_QUERY_KEY, ...Object.values(params)],
    queryFn: () => getBillingCompanies(params),
    select,
  });
};
