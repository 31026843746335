import { Document } from './types/Document';
import axios from 'axios';

export const updateAttachment = async (id: number, fileMetadata: string): Promise<Document> => {
  const res = await axios.put<Document>(`/attachments/${id}`, {
    attachment: {
      file: fileMetadata,
    },
  });

  return res.data;
};
