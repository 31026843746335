import {
  Box,
  TBody,
  THead,
  Table,
  TableProps,
  Td,
  Th,
  Tr,
  ThButton,
  Icon,
} from '@localyze-pluto/components';
import React from 'react';
import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { UseTableSortingProps } from 'components/DataTable/useTableSorting';

export type DataTableProps<T, P> = Omit<TableProps, 'children'> &
  UseTableSortingProps & {
    data: T[];
    columns: ColumnDef<T, P>[];
    emptyStateText: string;
    isRowClickable?: boolean;
    handleRowClick?: (row: Row<T>) => void;
    testId?: string;
  };

export const DataTable = <T, P>({
  data,
  columns,
  emptyStateText,
  isRowClickable = false,
  handleRowClick,
  testId,
  sorting,
  onSortingChange,
}: DataTableProps<T, P>): React.JSX.Element => {
  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
    state: {
      sorting,
    },
    manualSorting: true,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isEmpty(data)) {
    return <EmptyState text={emptyStateText} />;
  }

  return (
    <Table data-testid={testId} striped>
      <THead isSticky>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th key={header.id}>
                <Box.div minWidth="10rem" onClick={header.column.getToggleSortingHandler()}>
                  <ThButton>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <Icon decorative icon="arrow-up" size="sizeIcon10" />,
                      desc: <Icon decorative icon="arrow-down" size="sizeIcon10" />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </ThButton>
                </Box.div>
              </Th>
            ))}
          </Tr>
        ))}
      </THead>
      <TBody>
        {table.getRowModel().rows.map((row) => (
          <Tr
            data-testid={testId ? `${testId}-row` : null}
            hasHover
            isClickable={isRowClickable}
            key={row.id}
            onClick={() => handleRowClick && handleRowClick(row)}
          >
            {row.getVisibleCells().map((cell) => (
              <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
            ))}
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};
