import { shutDownIntercom, trackEvent } from 'deprecated/utils/helper/segment';
import { LogoutOptions } from '@auth0/auth0-react';

export function logout(auth0Logout: (options?: LogoutOptions | undefined) => void): void {
  shutDownIntercom();
  trackEvent('logout');

  auth0Logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}
