import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { UserStatistics } from '../types/UserStatistics';
import axios from 'axios';

const getUserStatistics = async (): Promise<UserStatistics> => {
  const { data } = await axios.get<UserStatistics>('/glomo/dashboard/user_statistics');
  return data;
};

export const useUserStatistics = (): UseQueryResult<UserStatistics> =>
  useQuery({
    queryKey: ['statistics'],
    queryFn: getUserStatistics,
  });
