import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { User } from 'deprecated/api/user/types/User';
import { axios } from 'config/axios/axios';

export const CURRENT_USER_QUERY_KEY = 'current-user';

type UseCurrentUserQueryProps = Omit<UseQueryOptions<User>, 'queryFn' | 'queryKey'>;

const getCurrentUser = async (): Promise<User> => {
  const { data } = await axios.get<User>(`/sessions/current_user`);

  return data;
};

export const useCurrentUserQuery = (props: UseCurrentUserQueryProps = {}): UseQueryResult<User> => {
  return useQuery({
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: getCurrentUser,
    staleTime: Infinity,
    ...props,
  });
};
