import React from 'react';
import { ConfirmationModal, Menu, useModalStore, useToast } from '@localyze-pluto/components';
import { AccountEntry } from 'modules/billing/types/AccountEntry';
import { useSetAccountEntryStatusReviewed } from 'modules/billing/hooks/useUpdateAccountEntry/useSetAccountEntryStatusReviewed';

import { AccountEntryType } from 'modules/billing/types/AccountEntryType';
import CancelEntryConfirmationDialog from 'modules/billing/components/CancelEntryConfirmationDialog/CancelEntryConfirmationDialog';
import { useCancelAccountEntry } from 'modules/billing/hooks/useCancelAccountEntry/useCancelAccountEntry';

const AccountEntryMenuCell = ({
  accountEntry,
}: {
  accountEntry: AccountEntry;
}): React.JSX.Element => {
  const toast = useToast();
  const setAsReviewedModalState = useModalStore();
  const cancelEntryModalState = useModalStore();

  const isSetAsReviewedModalOpen = setAsReviewedModalState.useState('open');
  const isCancelEntryModalOpen = cancelEntryModalState.useState('open');

  const { mutate: cancelAccountEntry } = useCancelAccountEntry({
    onSuccess: () => {
      toast('The account entry was declined.', 'success');
    },
    onError: (error) => {
      toast(
        error.response?.data.message || 'There was an error canceling the account entry.',
        'error',
      );
    },
  });

  const { mutate: reviewAccountEntry } = useSetAccountEntryStatusReviewed({
    onSuccess: () => {
      toast('The account entry was set as reviewed.', 'success');
    },
    onError: (error) => {
      toast(
        error.response?.data.message || 'There was an error setting the account entry to canceled.',
        'error',
      );
    },
  });

  const onDeclineAccountEntry = (cancellation_reason: string) => {
    cancelAccountEntry({ accountEntry, cancellation_reason });
  };

  const isNotCancellable =
    ['cancelled', 'synced'].includes(accountEntry.status) ||
    accountEntry.type === AccountEntryType.Credit ||
    (!!accountEntry.credit_sum && accountEntry.credit_sum > 0);

  return (
    <>
      <Menu
        items={[
          {
            label: 'Set as reviewed',
            onClick: setAsReviewedModalState.toggle,
            disabled: accountEntry.status !== 'needs_review',
          },
          {
            label: 'Cancel entry',
            onClick: cancelEntryModalState.toggle,
            disabled: isNotCancellable,
          },
        ]}
      />
      {isSetAsReviewedModalOpen && (
        <ConfirmationModal
          confirmLabel="Set as reviewed"
          onConfirm={() => reviewAccountEntry(accountEntry)}
          store={setAsReviewedModalState}
        >
          Are you sure you want to set this account entry to reviewed? This action cannot be undone.
        </ConfirmationModal>
      )}
      {isCancelEntryModalOpen && (
        <CancelEntryConfirmationDialog
          onConfirm={onDeclineAccountEntry}
          state={cancelEntryModalState}
        />
      )}
    </>
  );
};

export default AccountEntryMenuCell;
