import axios from 'axios';
import { Message } from 'modules/talent/types/Message';

type MessageParams = {
  caseId: number;
  internalMessage?: boolean;
};

export const getMessages = async ({
  caseId,
  internalMessage,
}: MessageParams): Promise<Message[]> => {
  const { data } = await axios.get<Message[]>('/messages', {
    params: { case_id: caseId, internal_message: internalMessage },
  });

  return data;
};
