import * as FLAGS from 'modules/featureFlags/types/flags';
import { useSplitTreatments } from '@splitsoftware/splitio-react';

const allFlags = Object.values(FLAGS);

export const useIsFeatureFlagActive = (flagName: FLAGS.Flag): boolean => {
  const { treatments } = useSplitTreatments({ names: allFlags });

  return treatments[flagName].treatment === 'on';
};
