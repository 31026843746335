import React from 'react';
import { Box } from '@localyze-pluto/components';
import { CaseMetrics } from 'modules/reporting/api/types/CaseMetrics';

const StatsItem = ({ text, count }: { text: string; count: number }) => {
  return (
    <Box.li
      backgroundColor="bgSecondary"
      borderRadius="borderRadius20"
      display="inline-grid"
      minH={76}
      minWidth={226}
      padding="p4"
    >
      <Box.span fontSize="fontSize20" lineHeight="lh5" style={{ color: '#000' }}>
        {text}
      </Box.span>
      <Box.span alignSelf="end" fontSize="fontSize50" fontWeight="fontWeightBold" lineHeight="lh6">
        {count}
      </Box.span>
    </Box.li>
  );
};

export const StatsHeader = ({ results }: { results: CaseMetrics }): React.JSX.Element => {
  return (
    <Box.ul
      display="grid"
      gap="d4"
      gridTemplateColumns={{ md: 3, lg: 4 }}
      listStyleType="none"
      margin="m0"
      marginBottom="m4"
      overflow="hidden"
      padding="p0"
    >
      <StatsItem count={results.totalCases} text="Total number of talents" />
      <StatsItem count={results.ongoingCases} text="Number of ongoing cases" />
      <StatsItem count={results.waitingCases} text="Number of waiting cases" />
      <StatsItem count={results.closedCases} text="Number of closed cases" />
    </Box.ul>
  );
};
