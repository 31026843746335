export const autocompleteStyles = {
  input: `tw-truncate tw-w-full tw-py-2  tw-pr-10 tw-min-w-40 tw-text-sm 
tw-leading-5 tw-rounded-md tw-font-sans tw-border-gray-300 tw-border-solid focus:tw-ring-blue-500`,
};

export const simpleSelectStyles = {
  button: `tw-truncate tw-w-full tw-min-w-40 tw-relative tw-flex tw-py-2 
    tw-text-sm tw-leading-5 tw-text-blue-500 tw-font-sans tw-font-normal tw-rounded-md
    tw-border-gray-300 focus:tw-border-blue-500 focus:tw-outline-none focus:tw-ring-blue-500`,
};
