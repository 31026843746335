import { Box, Text } from '@localyze-pluto/components';

import React from 'react';

type ReportingTableCompanyProps = {
  company: string;
};

/**
 * Company name for the reporting table.
 */
export const ReportingTableCompany = ({
  company,
}: ReportingTableCompanyProps): React.JSX.Element => {
  return (
    <Box.div marginBottom="m2" marginTop="m2">
      <Text.span
        color="colorText"
        display="block"
        fontSize="fontSize20"
        fontWeight="fontWeightMedium"
        lineHeight="lineHeight30"
      >
        Company: <Text.span color="colorTextHeading">{company}</Text.span>
      </Text.span>
    </Box.div>
  );
};
