import { useCallback, useState } from 'react';

import { generatePdfFromTemplate } from 'deprecated/api/templates/index';
import { isAxiosError } from 'axios';
import { useToast } from '@localyze-pluto/components';

type UsePDFGeneratorProps = {
  caseId: number;
  pdfTemplateId: number;
};

type UsePDFGeneratorReturn = Readonly<[boolean, () => Promise<void>]>;

export const usePdfGenerator = ({
  caseId,
  pdfTemplateId,
}: UsePDFGeneratorProps): UsePDFGeneratorReturn => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const openGeneratedPdf = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const result = await generatePdfFromTemplate(caseId, pdfTemplateId);
      setIsSubmitting(false);
      window.open(result.data.download_url, '_blank');
    } catch (error) {
      setIsSubmitting(false);
      const errorMessage = isAxiosError(error)
        ? error.message
        : 'Something went wrong trying to generate the PDF';
      toast(errorMessage, 'error');
    }
  }, [caseId, pdfTemplateId, toast]);

  return [isSubmitting, openGeneratedPdf];
};
