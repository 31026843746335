import { DynamicField } from 'components/ControlledFormField/DynamicField';
import { VARIABLE_TYPE_OPTIONS } from 'deprecated/variablesEditor/constants';
import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';
import { ModalMode } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/ModalMode';
import { VariableFormValues } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/VariableFormValues';

export const useVariableFields = (
  mode: ModalMode,
  formValues: VariableFormValues,
): {
  groupFields: DynamicField[];
  variableFields: DynamicField[];
} => {
  const { data: countryOptions } = useCountryOptions({ allLabel: 'Default' });
  const createGroup = mode === 'createGroup';
  const editVariable = mode === 'editVariable';

  const hasGroupValuesSet = !!formValues.title && !!formValues.variableType;

  const groupFields: DynamicField[] = [
    {
      id: 'title',
      label: 'Title',
      required: true,
      disabled: !createGroup,
      type: 'text',
    },
    {
      id: 'variableType',
      label: 'Type',
      type: 'select',
      options: VARIABLE_TYPE_OPTIONS,
      required: true,
      disabled: !createGroup,
    },
  ];
  const variableFields: DynamicField[] = [
    {
      id: 'destinationCountryId',
      label: 'Destination country',
      type: 'select',
      options: countryOptions || [],
      required: true,
      disabled: editVariable || !hasGroupValuesSet,
    },
    {
      id: 'originCountryId',
      label: 'Origin country',
      type: 'select',
      options: countryOptions || [],
      required: true,
      disabled: editVariable || !hasGroupValuesSet,
    },
    {
      id: 'linkTitle',
      label: 'Link Title',
      type: 'text',
      required: true,
      disabled: !hasGroupValuesSet,
    },
    {
      id: 'linkLabel',
      label: 'Link Label (CTA)',
      type: 'text',
      required: true,
      disabled: !hasGroupValuesSet,
    },
    {
      id: 'variableValue',
      label: 'Value',
      type: 'text',
      required: true,
      disabled: !hasGroupValuesSet,
    },
  ];

  const filterdVariableFields = variableFields.filter(
    (field) => formValues.variableType === 'link' || !['linkTitle', 'linkLabel'].includes(field.id),
  );

  return { groupFields, variableFields: filterdVariableFields };
};
