import { StepComponentResponse } from 'deprecated/api/steps/types/StepComponentResponse';

export type MappedStepComponent = {
  id: string;
  componentType: string;
  content: string;
  stepId: string;
};

export const mapStepComponents = (stepComponents: StepComponentResponse[]): MappedStepComponent[] =>
  stepComponents.map(({ id, component_type, content, step_id }) => ({
    componentType: component_type,
    content,
    id: String(id),
    stepId: String(step_id),
  }));
