import { UseMutationResult, useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Task } from 'modules/tasks/types/Task';
import { getTasksQueryKey } from 'modules/tasks/api/useTasks/useTasks';
import { queryClient } from 'config/queryClient';
import { PENDING_COUNTERS_KEY } from 'modules/home/api/usePendingCounters';

export type CreateTaskProps = {
  description: string;
  caseId: number;
};

const createTask = async ({ description, caseId }: CreateTaskProps): Promise<Task> => {
  const { data } = await axios.post<Task>('/tasks', { description, case_id: caseId });

  return data;
};

export const useCreateTask = ({
  onError,
  onSuccess,
}: {
  onError?: (error: AxiosError) => void;
  onSuccess?: () => void;
} = {}): UseMutationResult<Task, unknown, CreateTaskProps> =>
  useMutation({
    mutationFn: createTask,
    onSuccess: async (task) => {
      await queryClient.invalidateQueries({
        queryKey: getTasksQueryKey({ case_id: task.case_id, all_items: true }),
      });
      queryClient.invalidateQueries({ queryKey: [PENDING_COUNTERS_KEY] });

      onSuccess?.();
    },
    onError,
  });
