import React, { useEffect, useState } from 'react';
import { VariableFormValues } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/VariableFormValues';
import { ModalMode } from './types/ModalMode';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';
import { useVariableModification } from 'deprecated/variablesEditor/hooks/useVariableModification';
import {
  Box,
  Button,
  ConfirmationModal,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalStore,
  useModalStore,
  Paragraph,
} from '@localyze-pluto/components';
import { useForm } from 'react-hook-form';
import {
  confirmationContent,
  DESCRIPTIONS,
  EVENTS,
  getInitialValues,
  groupLinkSchema,
  groupSchema,
  SUBMIT_BUTTON_LABELS,
  TITLES,
} from 'deprecated/variablesEditor/components/VariablesEditorFormModal/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Instructions } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/components/Instructions';
import { ControlledFormField } from 'components/ControlledFormField/ControlledFormField';
import { trackEvent } from 'deprecated/utils/helper/segment/index';
import { isEqual } from 'lodash';
import { DeleteConfirmationModal } from 'deprecated/variablesEditor/components/VariableEditorDeleteConfirmationModal/DeleteConfirmationModal';
import { useVariableFields } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/useVariableFields';
import FormSection from 'modules/company/components/FormSection/FormSection';

export type VariablesEditorFormModal = {
  state: ModalStore;
  onSuccess: () => void;
  mode: ModalMode;
  variable?: Variable;
};

export const VariablesEditorFormModal = ({
  state,
  mode,
  variable,
  onSuccess,
}: VariablesEditorFormModal): React.JSX.Element => {
  const createGroup = mode === 'createGroup';
  const editVariable = mode === 'editVariable';

  const { handleVariableModification } = useVariableModification({ onSuccess });

  const deleteModalState = useModalStore();
  const confirmModalState = useModalStore();

  const isDeleteModalOpen = deleteModalState.useState('open');
  const isConfirmModalOpen = confirmModalState.useState('open');

  const initialValues = getInitialValues(variable, editVariable);

  const [formValues, setFormValues] = useState<VariableFormValues>(initialValues);
  const { variableFields, groupFields } = useVariableFields(mode, formValues);

  const validation = formValues.variableType === 'link' ? groupLinkSchema : groupSchema;

  const { control, handleSubmit, getValues, formState, watch } = useForm({
    values: initialValues,
    resolver: yupResolver(validation),
    mode: 'onTouched',
  });

  const referenceValues = watch(['variableType', 'title']).join('');

  useEffect(() => {
    if (
      !isEqual(getValues(['variableType', 'title']), [formValues.variableType, formValues.title])
    ) {
      setFormValues(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.title, formValues.variableType, referenceValues]);

  const onConfirmCreateOrEdit = () => {
    handleVariableModification({
      currentVariable: variable,
      formValues: formValues,
      isDeletingVariable: false,
      mode,
    });
    confirmModalState.hide();

    trackEvent(EVENTS[mode]);
  };

  const onSubmit = (values: VariableFormValues) => {
    setFormValues(values);
    confirmModalState.show();
  };

  return (
    <Modal store={state}>
      <ModalHeader>
        <ModalHeading>{TITLES[mode]}</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Paragraph size="large">{DESCRIPTIONS[mode]}</Paragraph>
        <Box.form id="variableForm" onSubmit={handleSubmit(onSubmit)}>
          <FormSection columns={2} heading="Variable Group">
            {createGroup && <Instructions step="createGroup" />}
            {groupFields.map((field) => {
              return (
                <ControlledFormField
                  control={control}
                  field={field}
                  isEditing={true}
                  key={field.id}
                />
              );
            })}
          </FormSection>
          <FormSection border={false} columns={2} heading="Variable">
            {!editVariable && <Instructions step="createVariable" />}
            {variableFields.map((field) => {
              return (
                <ControlledFormField
                  control={control}
                  field={field}
                  isEditing={true}
                  key={field.id}
                />
              );
            })}
          </FormSection>
        </Box.form>
      </ModalBody>
      <ModalFooter>
        {editVariable && (
          <Button onClick={deleteModalState.show} variant="destructive">
            Delete
          </Button>
        )}
        <Button disabled={!formState.isValid} form="variableForm" type="submit" variant="primary">
          {SUBMIT_BUTTON_LABELS[mode]}
        </Button>
      </ModalFooter>
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          onConfirm={() => {
            handleVariableModification({
              currentVariable: variable,
              isDeletingVariable: true,
              mode,
            });
            deleteModalState.hide();
          }}
          state={deleteModalState}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmationModal onConfirm={onConfirmCreateOrEdit} store={confirmModalState}>
          {confirmationContent(mode)}
        </ConfirmationModal>
      )}
    </Modal>
  );
};
