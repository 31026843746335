import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import { Step } from 'modules/pipelines/types/Step';

const saveStep = async ({ id, title, description, user_type }: Step): Promise<void> => {
  await axios.put<Step>(`/admin/steps/${id}`, {
    id,
    title,
    description,
    user_type,
  });
};

export const useSaveStep = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: (step: Step) => void;
} = {}): UseMutationResult<void, unknown, Step> => {
  return useMutation({
    mutationFn: saveStep,
    onSuccess: (_, step) => onSuccess && onSuccess(step),
    onError,
  });
};
