import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { User } from 'deprecated/api/user/types/User';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { useCaseManagers } from 'modules/users/api/useCaseManagers/useCaseManagers';
import { mapUsersToSelectOptions } from 'modules/users/utils/mapUsersToSelectOptions';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type EditCaseManagerFormValues = {
  caseManagerId: string;
};

const validationSchema: Yup.ObjectSchema<EditCaseManagerFormValues> = Yup.object({
  caseManagerId: Yup.string().required('This field is required'),
});

export const EditCaseManagerModal = ({
  modal,
  caseId,
  caseManager,
}: {
  modal: DialogStore;
  caseId: number;
  caseManager: User | undefined;
}): React.JSX.Element => {
  const toast = useToast();
  const { data: caseManagers, isSuccess: isSuccessCaseManagers } = useCaseManagers();
  const caseManagersSelectOptions = mapUsersToSelectOptions(caseManagers);

  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Case manager updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Case manager could not be updated', 'error'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: { caseManagerId: caseManager?.id.toString() || '' },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditCaseManagerFormValues> = (values) =>
    updateCase({ caseId, payload: { case_manager_id: Number(values.caseManagerId) } });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Case Manager</ModalHeading>
      </ModalHeader>
      {!isSuccessCaseManagers ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Box.form id="edit-case-manager-form" onSubmit={handleSubmit(onSubmit)}>
              <ControlledFormSelect
                control={control}
                id="caseManagerId"
                items={caseManagersSelectOptions}
                label="Case Manager"
                name="caseManagerId"
                placeholder="Select a case manager"
                required
              />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={modal.hide} type="button" variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={isPendingUpdateCase}
              form="edit-case-manager-form"
              loading={isPendingUpdateCase}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
