import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './index.scss';
import './bootstrap-legacy.css';
import './pluto-tailwind-fixes.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from 'App';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<App />);
