import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createMessage, CreateMessageProps } from './createMessage';
import { Message } from 'modules/talent/types/Message';

import { MESSAGES_QUERY_KEY } from '../useMessages/useMessages';

export const useCreateMessage = ({
  onError,
  onSuccess,
  internalMessage = false,
}: {
  onError?: (error: AxiosError) => void;
  onSuccess?: () => void;
  internalMessage?: boolean;
}): UseMutationResult<Message, unknown, CreateMessageProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMessage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MESSAGES_QUERY_KEY, internalMessage],
      });

      onSuccess?.();
    },
    onError,
  });
};
