import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { CaseStepWithComponents } from 'modules/pipelines/types/CaseStep';
import { queryClient } from 'config/queryClient';
import { getCasePipelinesQueryKey } from 'modules/pipelines/hooks/useCasePipelines';

export type CaseInformationSubmission = { internal_reference_code: string; value: unknown };

export type SubmitCaseStepProps = {
  id: number;
  caseInformations: CaseInformationSubmission[];
};

const submitCaseStep = async ({ id, caseInformations }: SubmitCaseStepProps): Promise<void> => {
  await axios.post<CaseStepWithComponents>(`/case/steps/${id}/submit`, {
    case_informations: caseInformations,
  });
};

export const useSubmitCaseStep = ({
  caseId,
  onError,
  onSuccess,
}: Pick<UseMutationOptions<void, AxiosError, SubmitCaseStepProps>, 'onError' | 'onSuccess'> & {
  caseId: number;
}): UseMutationResult<void, unknown, SubmitCaseStepProps> => {
  return useMutation({
    mutationFn: submitCaseStep,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getCasePipelinesQueryKey(caseId),
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
