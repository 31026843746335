import React from 'react';
import {
  Anchor,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
} from '@localyze-pluto/components';
import { DisclosureStore } from '@ariakit/react';
import { useStripeBillingPortalSession } from 'modules/invoices/api/useStripeBillingPortalSession/useStripeBillingPortalSession';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';

interface Props {
  companyId?: number;
  modalState: DisclosureStore;
}

/**
 * Modal that fetches stripe info, links to stripe invoices
 * If no companyId is passed, the current user's company is used
 */
export const InvoicesModal = ({ companyId, modalState }: Props): React.JSX.Element => {
  const { company_id } = useCurrentUser();

  const { data: stripeBillingPortalSession } = useStripeBillingPortalSession({
    params: { companyId: companyId || company_id },
  });

  return (
    <Modal store={modalState}>
      <ModalHeader>
        <ModalHeading>Invoices</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Paragraph marginBottom="m0">
          It may take up to 7 days between payment and the invoice showing as paid in the platform.
          If you have any other questions please reach out to the Localyze Customer Success Team or
          visit{' '}
          <Anchor
            href="https://www.notion.so/localyze/Localyze-invoices-c1b5508892394d6b8dc92db392103e5a"
            isExternal={true}
            onClick={() => {
              trackEvent('invoices: click to open documentation page in Notion');
            }}
          >
            our documentation page
          </Anchor>
          .
        </Paragraph>
      </ModalBody>
      <ModalFooter>
        <Button
          as="a"
          disabled={!stripeBillingPortalSession}
          href={stripeBillingPortalSession?.url}
          loading={!stripeBillingPortalSession}
          onClick={() => {
            modalState.hide();
            trackEvent('invoices: click to open Stripe invoices portal');
          }}
          rel="noopener noreferrer"
          target="_blank"
          variant="primary"
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
