import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { CaseInformation } from 'modules/cases/types/CaseInformation';
import { ReferenceType } from 'deprecated/variablesEditor/constants';

export const CASE_INFORMATIONS_QUERY_KEY = 'case-informations';

type GetCaseInformationsProps = {
  caseId: number[] | number;
  referenceType?: ReferenceType;
  internalReferenceCodes?: string[];
};

const getCaseInformations = async ({
  caseId,
  referenceType,
  internalReferenceCodes,
}: GetCaseInformationsProps): Promise<CaseInformation[]> => {
  const params = {
    case_id: caseId,
    internal_reference_codes: internalReferenceCodes,
    reference_type: referenceType,
  };

  const response = await axios.get<CaseInformation[]>('/case_informations', { params });
  return response.data;
};

export const useCaseInformations = <Result = CaseInformation[]>({
  caseId,
  referenceType,
  internalReferenceCodes,
  enabled = true,
}: GetCaseInformationsProps &
  Pick<UseQueryOptions<CaseInformation[], AxiosError, Result>, 'enabled'>): UseQueryResult<
  Result,
  AxiosError
> => {
  return useQuery({
    queryKey: [CASE_INFORMATIONS_QUERY_KEY, caseId, referenceType, internalReferenceCodes],
    queryFn: () => getCaseInformations({ caseId, referenceType, internalReferenceCodes }),
    enabled,
  });
};
