import { Icon } from '@localyze-pluto/components';
import cx from 'clsx';
import React from 'react';

type OptionProps = {
  label: string;
  selected: boolean;
};

export const Option = ({ label, selected }: OptionProps): React.JSX.Element => (
  <div className="tw-flex tw-justify-between tw-items-center" data-testid="select-menu-item">
    <span className={cx('tw-text-sm tw-leading-5', selected ? 'tw-font-medium' : 'tw-font-normal')}>
      {label}
    </span>
    {selected && (
      <div>
        <Icon
          aria-hidden="true"
          className="tw-w-5 tw-h-5 tw-text-blue-500"
          data-testid="select-menu-option-check"
          decorative
          icon="check"
        />
      </div>
    )}
  </div>
);
