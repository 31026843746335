import React from 'react';

import { useHistory } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { logout } from 'modules/auth/utils/logout';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Button, Icon, IconProps, Menu } from '@localyze-pluto/components';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import { useCurrentUser } from 'config/CurrentUserContext';

const MenuItemWithIcon = ({ icon, label }: { icon: IconProps['icon']; label: string }) => {
  return (
    <Box.div alignItems="center" display="flex" gap="d4">
      <Icon as="span" decorative={false} icon={icon} size="sizeIcon30" title={label} />
      <Box.span>{label}</Box.span>
    </Box.div>
  );
};

export const HeaderMenu = (): React.JSX.Element => {
  const user = useCurrentUser();
  const routes = useRoutes();
  const history = useHistory();

  const { logout: auth0Logout } = useAuth0();

  return (
    <Menu
      items={[
        {
          label: <MenuItemWithIcon icon="user" label="My Profile" />,
          onClick: () => history.push(routes.userProfile.url),
        },
        {
          label: <MenuItemWithIcon icon="log-out" label="Sign out" />,
          onClick: () => logout(auth0Logout),
        },
      ]}
      menuButton={
        <Button
          aria-label="Open user menu"
          borderColor="gray300"
          color="gray500"
          trailingIcon="chevron-down"
          variant="secondary"
        >
          <Avatar name={fullName(user)} size="small" style={{ margin: '-6px' }} />
        </Button>
      }
      zIndex="zIndex10"
    />
  );
};
