import axios from 'axios';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { CompleteGlomoRequestValues } from 'modules/glomoRequests/types/CompleteGlomoRequestValues';
import { GLOMO_REQUEST_QUERY_KEY } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { RequiredActionItem } from 'modules/glomoRequests/types/RequiredActionItem';
import { PENDING_COUNTERS_KEY } from 'modules/home/api/usePendingCounters';

type Values = Pick<CompleteGlomoRequestValues, 'riskLevel' | 'risksAndWarnings'> & {
  requiredActions: RequiredActionItem[];
  report_pdf?: string;
};

export type UpdateGlomoRequestProps = {
  values: Values;
  glomoRequestId: number;
};

export const updateGlomoRequest = async ({
  values,
  glomoRequestId,
}: UpdateGlomoRequestProps): Promise<GlomoRequest> => {
  const url = `/glomo/requests/${glomoRequestId}`;

  const { data } = await axios.put<GlomoRequest>(url, {
    glomo_request: {
      report_pdf: values.report_pdf,
      risks_and_warnings: values.risksAndWarnings,
      required_actions: values.requiredActions,
      risk_level: values.riskLevel,
    },
  });

  return data;
};

export const useUpdateGlomoRequest = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}): UseMutationResult<
  GlomoRequest,
  unknown,
  {
    values: Values;
    glomoRequestId: number;
  }
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ values, glomoRequestId }) =>
      updateGlomoRequest({
        values,
        glomoRequestId,
      }),
    onSuccess: (glomoRequest) => {
      queryClient.invalidateQueries({
        queryKey: [GLOMO_REQUEST_QUERY_KEY, glomoRequest.id],
      });
      queryClient.invalidateQueries({
        queryKey: [PENDING_COUNTERS_KEY],
      });

      onSuccess?.();
    },
    onError,
  });
};
