import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { useEffect, useState } from 'react';

export type UseParameterFilterResult = [value: string, onChange: (value: number | string) => void];

export const useParameterFilter = (key: string): UseParameterFilterResult => {
  const { queryParams, addQueryParam, removeQueryParam } = useQueryParams();
  const [value, setValue] = useState('');

  const onChange = (value: number | string) => {
    const parsedValue = String(value);
    parsedValue === 'all' ? removeQueryParam(key) : addQueryParam(key, parsedValue);
    setValue(parsedValue);

    // changing a filter that is not the page should always return the first page
    if (key !== 'page') addQueryParam('page', '1');
  };

  useEffect(() => {
    if (queryParams[key] !== value) setValue(queryParams[key] || '');
    // when loading the page from a link we want to set the search value from the params only once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, onChange];
};
