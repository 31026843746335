import {
  ADD_DOCUMENT,
  ADD_NEW_DOCUMENT,
  DELETE_DOCUMENT,
  FETCH_DOCUMENTS,
  OPEN_DELETE_DOCUMENT,
} from 'deprecated/store/actions/DocumentsContainerActions';
import {
  addNewDocToDocList,
  updateDocUrl,
} from 'deprecated/utils/helper/Chewpaca/documentsListHelperFunctions';

export const DocsContainerInit = {
  loading: true,
  src: '',
  docName: '',
  docCode: '',
  docList: [],
};

export const DocsContainerReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS: {
      const { docList } = action.payload;
      return {
        ...state,
        docList,
        loading: false,
      };
    }

    case OPEN_DELETE_DOCUMENT: {
      const { src, docName, docCode, id, type } = action.payload;
      return {
        ...state,
        docName,
        docCode,
        src,
        id,
        type,
      };
    }

    case ADD_DOCUMENT: {
      const { docCode, docUrl, id } = action.payload;
      return {
        ...state,
        docList: updateDocUrl(state.docList, { docCode, docUrl, id }),
      };
    }

    case ADD_NEW_DOCUMENT: {
      const { payload } = action;

      return {
        ...state,
        docList: addNewDocToDocList(state.docList, payload),
      };
    }

    case DELETE_DOCUMENT: {
      const { docCode, id, type } = action.payload;
      return {
        ...state,
        docList:
          type === 'Attachment'
            ? state.docList.filter((doc) => doc.id !== id)
            : updateDocUrl(state.docList, { docCode, docUrl: '', id, type }),
      };
    }

    default:
      return state;
  }
};
