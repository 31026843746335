import { Autocomplete } from './components/Autocomplete';
import React from 'react';
import { SelectMenuOption } from './types/SelectMenuOption';
import { SelectMenuProps } from './types/SelectMenuProps';
import { SimpleSelect } from './components/SimpleSelect';

export type { SelectMenuOption };

/*
 If there are 10 or more than 10 options, the SelectMenu change its
 behavior to autocomplete
 */
const SIMPLE_SELECT_OPTIONS_LIMIT = 10;

export const SelectMenu = ({ options, error, ...rest }: SelectMenuProps): React.JSX.Element => {
  const hasError = error !== undefined;
  const errorMessage = error;

  return options.length >= SIMPLE_SELECT_OPTIONS_LIMIT ? (
    <Autocomplete errorMessage={errorMessage} hasError={hasError} {...rest} options={options} />
  ) : (
    <SimpleSelect
      errorMessage={errorMessage}
      hasError={hasError}
      {...rest}
      backgroundColor="tw-bg-white"
      options={options}
    />
  );
};
