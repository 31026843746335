import { Container } from 'deprecated/components/Chewpaca/Container';
import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { trackEvent } from 'deprecated/utils/helper/segment';

import { Button, useDrawerStore, useModalStore } from '@localyze-pluto/components';
import { Country } from 'modules/countries/types/Country';
import { Filter } from 'modules/filters/types/Filter';
import { ModalMode } from '../VariablesEditorFormModal/types/ModalMode';
import { NoResultsFound } from './NoResultsFound';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { SearchInput } from '../SearchInput/SearchInput';
import { VARIABLE_TYPE_FILTER } from '../filters/variableType';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';
import { VariablesEditorFormModal } from '../VariablesEditorFormModal/VariablesEditorFormModal';
import { VariablesList } from '../VariablesList/VariablesList';
import { parseSelectedFilters } from 'deprecated/libs/filters';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { trackPageView } from 'modules/analytics/analytics';
import { PaginationWithCounter } from 'components/PaginationWithCounter/PaginationWithCounter';
import { useFiltersOptions } from 'modules/filters/hooks/useFiltersOptions';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { useVariables } from 'modules/variableEditor/api/useVariables';
import { useStoreState } from '@ariakit/react';
import {
  FiltersDrawer,
  FiltersValues,
} from 'modules/filters/components/FiltersDrawer/FiltersDrawer';
import { destinationCountryFilter } from 'modules/filters/destinationCountryFilter';

export const getFilters = (countries: Country[]): Filter<string>[] => [
  destinationCountryFilter(countries),
  VARIABLE_TYPE_FILTER,
];

export const VariablesEditor = (): React.JSX.Element => {
  const { data: countries, isSuccess: isSuccessCountries } = useCountries({
    params: {
      association: 'internal_variables',
      filter: 'destination',
    },
  });
  const { queryParams, addQueryParam, updateQueryParams, removeQueryParam } = useQueryParams();

  const { data: availableFilters = [], isPending: isPendingFilters } = useFiltersOptions({
    queryKey: 'variable-editor-filters',
    filterParams: {
      filters: getFilters(countries || []),
      isAdmin: true,
    },
    enabled: isSuccessCountries,
  });

  const drawerStore = useDrawerStore();
  const isDrawerOpen = useStoreState(drawerStore, 'open');

  const modalState = useModalStore();
  const isModalOpen = modalState.useState('open');
  const [currentVariable, setCurrentVariable] = useState<Variable | undefined>();
  const [modalMode, setModalMode] = useState<ModalMode>('createGroup');

  const selectedFilters = useMemo(
    () => parseSelectedFilters(availableFilters, queryParams),
    [availableFilters, queryParams],
  );

  const {
    data,
    isPending,
    isSuccess: isSuccessVariables,
  } = useVariables({
    page: Number(queryParams['page']) || 1,
    search: queryParams['search'],
    ...selectedFilters,
  });

  useEffect(() => {
    trackPageView('Variables');
  }, []);

  const changePage = (page: number | string) => {
    addQueryParam('page', String(page));
  };

  const search = (title: string) => {
    if (queryParams['page']) {
      removeQueryParam('page');
    }

    if (title === '') {
      removeQueryParam('search');
    } else {
      addQueryParam('search', title);
      trackEvent('variables: search by title');
    }
  };

  const searchValue = queryParams['search'] || '';

  const onApplyDrawerFilters = (filtersData: FiltersValues) => {
    availableFilters.forEach((filter) => {
      const filterValue = filtersData[filter.attribute];
      const formattedUrlValue = Array.isArray(filterValue) ? filterValue.join(',') : filterValue;

      updateQueryParams({
        [`${filter.attribute}`]: formattedUrlValue,
      });
      trackEvent('variables: change filters');
    });

    drawerStore.hide();
  };

  return (
    <PageLayout flexed title="Variables">
      <Container className="tw-flex tw-flex-grow tw-flex-col tw-rounded-lg tw-p-2">
        <section className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex tw-space-x-4 tw-mb-4">
            <Button
              disabled={!isSuccessVariables || isPendingFilters}
              leadingIcon="sliders-vertical"
              onClick={drawerStore.show}
              variant="secondary"
            >
              Filter
            </Button>
            <SearchInput onSearch={search} value={searchValue} />
          </div>
          <Button
            leadingIcon="plus"
            onClick={() => {
              setCurrentVariable(undefined);
              setModalMode('createGroup');
              modalState.show();
              trackEvent('variables: open create group modal');
            }}
            variant="secondary"
          >
            New variable group
          </Button>
        </section>
        {isEmpty(data?.internal_variable_groups) && !isPending ? (
          <NoResultsFound />
        ) : (
          <VariablesList
            handleRowClick={(data) => {
              setCurrentVariable(data.internal_variables[0]);
              setModalMode('createVariable');
              modalState.show();
              trackEvent('variables: open new variable modal');
            }}
            handleTitleClick={(variable) => {
              setCurrentVariable(variable);
              setModalMode('editVariable');
              modalState.show();
              trackEvent('variables: open edit variable modal');
            }}
            isPending={isPending}
            variableGroups={data?.internal_variable_groups || []}
          />
        )}
      </Container>
      {data?.meta && <PaginationWithCounter paginationInfo={data.meta} setPage={changePage} />}
      {isModalOpen && (
        <VariablesEditorFormModal
          mode={modalMode}
          onSuccess={modalState.hide}
          state={modalState}
          variable={currentVariable}
        />
      )}
      {isSuccessVariables && isDrawerOpen && (
        <FiltersDrawer
          availableFilters={availableFilters}
          initialFilterValues={selectedFilters as FiltersValues}
          onApply={onApplyDrawerFilters}
          store={drawerStore}
        />
      )}
    </PageLayout>
  );
};
