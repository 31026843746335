import React from 'react';
import cx from 'clsx';

type DisplayedValueProps = {
  className?: string;
  label: string;
};

export const DisplayedValue = ({ className, label }: DisplayedValueProps): React.JSX.Element => (
  <span
    className={cx('tw-truncate tw-text-left tw-mr-1 tw-flex-1', className)}
    data-testid="select-menu-display-value"
  >
    {label}
  </span>
);
