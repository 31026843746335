import React, { ReactNode } from 'react';
import { gradientCounterStyles } from '../StatusCounter.styles';

export interface GradientCounterProps {
  readonly title: string;
  /**
   * @desc The icon to show above the count inside the status counter
   */
  readonly icon?: ReactNode;
  /**
   * @desc The count value of the status counter
   * @default 0
   */
  readonly count?: number;
  readonly className?: string;
  readonly gradientClasses?: string;
}

export const GradientCounter = ({
  title,
  count = 0,
  className,
  icon,
  gradientClasses = 'tw-bg-gradient-to-b tw-from-cyan-500 tw-to-blue-500',
}: GradientCounterProps): React.JSX.Element => (
  <div
    className={gradientCounterStyles(gradientClasses, className)}
    data-testid="gradient-status-counter"
  >
    {icon}
    <span
      aria-label={title}
      className="tw-block tw-text-6xl tw-leading-none tw-font-black tw-text-white"
    >
      {count}
    </span>
    <span className="tw-block tw-text-base tw-leading-6 tw-font-medium tw-font-sans tw-text-white">
      {title}
    </span>
  </div>
);
