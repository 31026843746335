import { VariableFormValues } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/VariableFormValues';
import { VariablePayload } from 'modules/variableEditor/api/useCreateVariable';

export const getVariablePayload = (formValues: VariableFormValues): VariablePayload => {
  return {
    title: formValues.title,
    variable_type: formValues.variableType,
    destination_country_id:
      formValues.destinationCountryId === 'all'
        ? undefined
        : Number(formValues.destinationCountryId),
    origin_country_id:
      formValues.originCountryId === 'all' ? undefined : Number(formValues.originCountryId),
    value: formValues.variableValue,
    link_title: formValues.linkTitle,
    link_label: formValues.linkLabel,
  };
};
