import React from 'react';
import {
  Box,
  Button,
  Text,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import { ItemCard } from 'components/ItemCard/ItemCard';
import isEmpty from 'lodash/isEmpty';
import { Task } from 'modules/tasks/types/Task';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { useCurrentUser } from 'config/CurrentUserContext';

export type TaskItemProps = {
  task: Task;
  onDelete?: () => void;
  onComplete?: () => void;
  onView?: () => void;
};

export const TaskItem = ({
  task,
  onDelete,
  onComplete,
  onView,
}: TaskItemProps): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const tooltip = useTooltipStore({
    showTimeout: 0,
  });

  const completedAt = task.completed_at;
  const completedByUserFirstName = task.completed_by_user?.first_name;
  const caseStepId = task.case_step_id;

  const showDeleteButton = !completedAt && isAdmin;
  const showCompleteButton = !completedAt && !isAdmin;
  const showViewButtonWithStep = completedAt && caseStepId;
  const showViewButtonWithToolTip = completedAt && !caseStepId;

  return (
    <ItemCard key={task.id}>
      <ItemCard.Content>
        <Box.div display="flex" flexDirection="column" gap="d2">
          <Text.span color="contentSecondary" fontSize="fontSize10" lineHeight="lh4">
            {`${completedAt ? 'Completed' : 'Created'}`} on{' '}
            {formatDate(new Date(completedAt ? completedAt : task.created_at))}
            {completedAt && !isEmpty(completedByUserFirstName) && ` by ${completedByUserFirstName}`}
          </Text.span>
          <Text.span
            color={completedAt ? 'colorText' : 'colorTextStrongest'}
            fontSize="fontSize20"
            lineHeight="lh5"
          >
            {task.description}
          </Text.span>
        </Box.div>
      </ItemCard.Content>
      <ItemCard.Action>
        {showDeleteButton && (
          <Button
            aria-label="Delete task"
            iconOnly
            leadingIcon="x"
            onClick={() => onDelete?.()}
            size="small"
            variant="ghost"
          />
        )}
        {showCompleteButton && (
          <Button aria-label="Complete task" onClick={() => onComplete?.()} variant="secondary">
            Complete
          </Button>
        )}
        {showViewButtonWithStep && (
          <Button aria-label="View task" onClick={() => onView?.()} variant="ghost">
            View
          </Button>
        )}
        {showViewButtonWithToolTip && (
          <>
            <TooltipAnchor store={tooltip}>
              <Button aria-label="View task" disabled variant="ghost">
                View
              </Button>
            </TooltipAnchor>
            <Tooltip store={tooltip}>Sorry, there is no review available for this task.</Tooltip>
          </>
        )}
      </ItemCard.Action>
    </ItemCard>
  );
};
