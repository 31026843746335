import { CardWithTabs } from 'components/CardWithTabs/CardWithTabs';
import { CASE_DETAILS_TABS } from 'modules/caseDetails/utils/constants';
import React from 'react';

export const CaseDetailsCard = (): React.JSX.Element => {
  const { Header, Content } = CardWithTabs;

  return (
    <CardWithTabs h={600} tabs={CASE_DETAILS_TABS}>
      <Header heading="Case details" tabListAriaLabel="Case details tabs" />
      <Content maxH="600" padding="d0" />
    </CardWithTabs>
  );
};
