import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { StackOneProvider } from 'modules/integrations/types/HRISCompanyIntegration';
import axios from 'axios';

export const HRIS_FILE_CATEGORIES_QUERY_KEY = 'HRIS-file-categories';

export type FileCategory = { id: string; label: string };
export type FileCategories = { file_categories: FileCategory[] };

export type UseHRISTalentProps = Omit<
  UseQueryOptions<Promise<FileCategories>, AxiosErrorWithMessage, FileCategories>,
  'queryFn' | 'queryKey'
> & {
  params: {
    accountId?: string;
    providerName?: StackOneProvider;
  };
};

export const getHRISFileCategories = async (
  accountId?: string,
  providerName?: StackOneProvider,
): Promise<FileCategories> => {
  const response = await axios.get<FileCategories>('/hris/file_categories', {
    params: { provider_name: providerName, account_id: accountId },
  });

  return response.data;
};

export const useHRISFileCategories = ({
  params,
  enabled,
  ...props
}: UseHRISTalentProps): UseQueryResult<FileCategories, AxiosErrorWithMessage> =>
  useQuery({
    queryKey: [HRIS_FILE_CATEGORIES_QUERY_KEY, params.accountId, params.providerName],
    queryFn: () => getHRISFileCategories(params.accountId, params.providerName),
    enabled: !!params.accountId && !!params.providerName && enabled,
    ...props,
  });
