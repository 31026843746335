import { CasePipeline } from 'modules/talent/pages/talentProfile/components/CasePipeline/CasePipeline';
import React, { useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import ProgressStepWithDocs from 'deprecated/pages/viewsNew/common/ProgressStep/ProgressStep';
import { formikValuesToSubmitStepInformation } from 'deprecated/utils/helper/international/formikValuesToSubmitStepInformation';
import { useCasePipelines } from 'modules/pipelines/hooks/useCasePipelines';
import { validationRequestAppointmentException } from 'deprecated/pages/viewsNew/common/ProgressStep/MapStepComponents/MapStepComponents';
import { useSubmitCaseStep } from 'modules/pipelines/hooks/useSubmitCaseStep';
import {
  Box,
  Button,
  ConfirmationModal,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import { useOpenStep } from 'modules/pipelines/hooks/useOpenStep';
import {
  OpenStepConfirmationModal,
  OpenStepConfirmationModalFormTypes,
} from './OpenStepConfirmationModal';
import { CaseStep } from 'modules/pipelines/types/CaseStep';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { UserType } from 'modules/pipelines/types/Step';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';
import { CaseStepStatus } from 'modules/pipelines/types/CaseStepStatus';
import { formatErrorMessage } from 'utils/formatters/errors/formatErrorMessage';
import { useDeleteCaseStep } from 'modules/pipelines/hooks/useDeleteCaseStep';
import { useCurrentUser } from 'config/CurrentUserContext';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

const INITIAL_STEP = 0;
const RESET_PIPELINE_ID = 0;

const SubmitButton = ({ disabled }: { disabled: boolean }) => (
  <div className="tw-flex tw-justify-end">
    <Button disabled={disabled} type="submit" variant="primary">
      Update
    </Button>
  </div>
);

export const CaseSteps = ({ caseId }: { caseId: number }): React.JSX.Element => {
  const toast = useToast();
  const { isHR } = useCurrentUser();
  const viewStepModalState = useModalStore({ defaultOpen: false });
  const deleteStepConfirmationModalState = useModalStore();
  const openStepConfirmationModalState = useModalStore({ defaultOpen: false });

  const isOpenStepConfirmationModalOpen = openStepConfirmationModalState.useState('open');
  const isViewStepModalOpen = viewStepModalState.useState('open');
  const isDeleteStepConfirmationModalOpen = deleteStepConfirmationModalState.useState('open');

  const [submitting, setSubmitting] = useState(false);
  const [activeCaseStepId, setActiveCaseStepId] = useState(INITIAL_STEP);

  const [activeCaseStep, setActiveCaseStep] = useState<Maybe<CaseStep>>(null);

  const { data: casePipelines, isSuccess: isSuccessCasePipelines } = useCasePipelines(caseId);

  const [openPipelineId, setOpenPipelineId] = useState<number>();

  const { mutate: submitCaseStep } = useSubmitCaseStep({
    caseId,
    onSuccess: () => {
      setSubmitting(false);
      viewStepModalState.hide();
      toast('Step submitted successfully.', 'success');
    },
    onError: (error: unknown) =>
      toast(formatErrorMessage(error as Error) || 'The step could not be submitted.', 'error'),
  });

  const { mutate: openStep } = useOpenStep({
    caseId,
    onSuccess: () => {
      openStepConfirmationModalState.hide();
      toast('Step opened successfully.', 'success');
    },
    onError: (error) => {
      rollbarInstance.error(error);
      toast(error.response?.data.message || 'The step could not be opened.', 'error');
    },
  });

  const { mutate: deleteStep } = useDeleteCaseStep({
    caseId,
    onSuccess: () => {
      deleteStepConfirmationModalState.hide();
      toast('Step deleted successfully.', 'success');
    },
    onError: (error) => {
      rollbarInstance.error(error);
      toast(error.response?.data.message || 'The step could not be deleted.', 'error');
    },
  });

  const onSubmit = (values: FormikValues[]) => {
    const validationRequestAppointment = validationRequestAppointmentException(values) as boolean;

    if (!validationRequestAppointment) {
      toast(
        'Something went wrong. To proceed, please enter a date or click the checkbox if no ' +
          'appointment is available',
        'error',
      );
    } else {
      setSubmitting(true);
      submitCaseStep({
        id: activeCaseStepId,
        caseInformations: formikValuesToSubmitStepInformation(values),
      });
    }
  };

  const previewStep = (caseStepId: number) => {
    const selectedCaseStep = (casePipelines || [])
      .flatMap((casePipeline) => casePipeline.case_steps)
      .find(({ id }) => id === caseStepId);
    setActiveCaseStep(selectedCaseStep || null);
    setActiveCaseStepId(caseStepId);
    viewStepModalState.show();
  };

  const trackOpenStepEvent = (caseStep: CaseStep, reason?: string, reasonDetails?: string) => {
    const trackingData =
      caseStep.status === CaseStepStatus.Completed
        ? {
            reason,
            reasonDetails,
            isFutureStep: false,
          }
        : { isFutureStep: true };

    /* should be moved to the backend when the action is performed */
    trackEvent('step: open', {
      ...trackingData,
      caseStepId: caseStep.id,
      stepId: caseStep.step_id,
      userType: caseStep.user_type,
      name: caseStep.name,
    });
  };

  const submitOpenStep = (values: OpenStepConfirmationModalFormTypes) => {
    if (activeCaseStep) {
      trackOpenStepEvent(
        activeCaseStep,
        values.reopen_step_tracking_reason,
        values.reopen_step_tracking_reason_details,
      );
      openStep({ caseStepId: activeCaseStep.id, opening_reason: values.open_step_reason });
    }
  };

  const openDeleteStepConfirmationModal = (caseStepId: number) => {
    const selectedCaseStep = (casePipelines || [])
      .flatMap((casePipeline) => casePipeline.case_steps)
      .find(({ id }) => id === caseStepId);
    setActiveCaseStep(selectedCaseStep || null);
    deleteStepConfirmationModalState.show();
  };

  const openStepConfirmationModal = (caseStepId: number) => {
    const selectedCaseStep = (casePipelines || [])
      .flatMap((casePipeline) => casePipeline.case_steps)
      .find(({ id }) => id === caseStepId);
    setActiveCaseStep(selectedCaseStep || null);
    openStepConfirmationModalState.show();
  };

  useEffect(() => {
    if (!isOpenStepConfirmationModalOpen) {
      setActiveCaseStep(null);
    }
  }, [isOpenStepConfirmationModalOpen]);

  const isUpdateButtonDisabled =
    submitting ||
    activeCaseStep?.status === 'preview' ||
    (isHR && activeCaseStep?.user_type !== UserType.HrManager);

  return (
    <>
      <Box.div
        backgroundColor="colorBackground"
        borderRadius="borderRadius30"
        data-testid="pipelines"
        display="flex"
        flexDirection="column"
        gap="d2"
        h="100%"
        w="100%"
      >
        {!isSuccessCasePipelines ? (
          <ContainedLoadingState />
        ) : casePipelines.length === 0 ? (
          <EmptyState
            gap="d2"
            illustration={<Icon color="contentTertiary" decorative icon="file-plus-2" />}
            text="No pipelines assigned."
          />
        ) : (
          casePipelines.map((casePipeline) => {
            const collapsibleOpenByDefault =
              !casePipeline.completed && openPipelineId === undefined;

            return (
              <CasePipeline
                casePipeline={casePipeline}
                deleteStep={openDeleteStepConfirmationModal}
                handleToggle={() =>
                  openPipelineId === casePipeline.id || collapsibleOpenByDefault
                    ? setOpenPipelineId(RESET_PIPELINE_ID)
                    : setOpenPipelineId(casePipeline.id)
                }
                isOpen={openPipelineId === casePipeline.id || collapsibleOpenByDefault}
                key={casePipeline.id}
                openStep={openStepConfirmationModal}
                previewStep={previewStep}
              />
            );
          })
        )}
      </Box.div>

      {isViewStepModalOpen && (
        <Modal store={viewStepModalState}>
          <ModalHeader> </ModalHeader>
          <ModalBody>
            <ProgressStepWithDocs
              buttons={<SubmitButton disabled={isUpdateButtonDisabled} />}
              caseId={caseId}
              caseStepId={activeCaseStep?.id}
              submit={onSubmit}
            />
          </ModalBody>
        </Modal>
      )}
      <OpenStepConfirmationModal
        caseStep={activeCaseStep}
        onOpenStep={submitOpenStep}
        state={openStepConfirmationModalState}
      />
      {isDeleteStepConfirmationModalOpen && (
        <ConfirmationModal
          confirmLabel="Delete"
          destructive
          onConfirm={() => activeCaseStep && deleteStep(activeCaseStep.id)}
          store={deleteStepConfirmationModalState}
        >
          Are you sure you want to delete this step?
        </ConfirmationModal>
      )}
    </>
  );
};
