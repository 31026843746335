import { GlomoRequest } from '../api/types/GlomoRequest';
import { UpdateGlomoRequestProps } from '../api/useUpdateGlomoRequest/useUpdateGlomoRequest';
import { RequiredActionId } from '../components/RequiredActions/RequiredActions';
import { CompleteGlomoRequestValues } from '../types/CompleteGlomoRequestValues';
import { formatRequiredActions } from './formatRequiredActions';

export const formatUpdatePayload = ({
  glomoRequestId,
  data,
  glomoRequest,
}: {
  data: Pick<
    CompleteGlomoRequestValues,
    RequiredActionId | 'report_pdf' | 'riskLevel' | 'risksAndWarnings'
  >;
  glomoRequestId: string;
  glomoRequest: GlomoRequest;
}): UpdateGlomoRequestProps => {
  const hasTheSamePdfURL = JSON.parse(data.report_pdf).url === glomoRequest.report_pdf_url;

  return {
    values: {
      ...(!hasTheSamePdfURL && { report_pdf: data.report_pdf }),
      riskLevel: data.riskLevel,
      risksAndWarnings: data.risksAndWarnings,
      requiredActions: formatRequiredActions(data),
    },
    glomoRequestId: Number(glomoRequestId),
  };
};
