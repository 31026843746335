import { Box, Heading } from '@localyze-pluto/components';
import LocalyzeIcon from 'assets/img/localyze-logo-globe.png';
import React from 'react';

type Props = {
  heading: string;
  children: React.ReactNode;
};

export const UnauthorizedErrorPage = (
  { heading, children }: Props = {
    heading: 'No access',
    children: <>{"You don't have acces to this page."}</>,
  },
): React.JSX.Element => {
  return (
    <Box.div
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap="d6"
      h="100vh"
      justifyContent="center"
      style={{ margin: 'auto' }}
      textAlign="center"
      w="50%"
    >
      <Box.img src={LocalyzeIcon} width="50px" />
      <Box.div alignItems="center" display="flex" flexDirection="column">
        <Heading as="h1" color="colorTextHeadingStrong" marginBottom="m6" size="heading50">
          {heading}
        </Heading>
        {children}
      </Box.div>
    </Box.div>
  );
};
