import * as yup from 'yup';
import { Validation } from 'constants/formValidations';

export const validationSchema = yup.object({
  registeredCompanyName: yup.string().required('Please specify a company name.'),
  legalForm: yup.string(),
  companyRegistrationNumber: yup.string(),
  competentCourt: yup.string(),
  commercialRegistrationNumber: yup.string(),
  legalRepresentative: yup.string(),
  foundingYear: yup.string(),
  street: yup.string().required('Please specify the street.'),
  streetNumber: yup.string().required('Please specify the street number'),
  postalCode: yup.string().required('Please specify the postal code.'),
  city: yup.string().required('Please specify the city.'),
  countryId: Validation.requiredString,
  averageEmployeeCount: yup.number().positive('Please enter a valid number.'),
  euEmployeeCount: yup.number().positive('Please enter a valid number.'),
  nonEUEmployeeCount: yup.number().positive('Please enter a valid number.'),
  noCriminalProceedings: yup.boolean(),
  noInsolvencyProceedings: yup.boolean(),
  noSocialSecurityContributions: yup.boolean(),
});
