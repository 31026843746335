import { ContentfulClient, ContentfulClientInterface } from 'react-contentful';
import { RawAdditionalServiceContent } from 'modules/additionalServices/types/AdditionalServiceContent';
import { Macro } from 'modules/pipelines/pages/caseEvaluationSummary/types/Macro';
import { TaskTemplate } from 'modules/caseDetails/components/CaseActivityCard/TemplatesDropdown/types/TaskTemplate';
import { StatusUpdateTemplate } from 'modules/caseDetails/components/CaseActivityCard/TemplatesDropdown/types/StatusUpdateTemplate';
import { ReferralCard } from 'modules/home/types/ReferralCard';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { PostNewUpdateType } from 'modules/caseDetails/components/CaseActivityCard/Updates/PostNewUpdate';

type Entries = Promise<unknown[]>;

type Card = ReferralCard & {
  sys: {
    createdAt: string;
  };
};

type ContentfulDocument = {
  nameOfTheDocument: string;
  pdf: Asset;
};

type ContentfulFAQ = { question: string; answer: Document };
type ContentfulWaitingTime = {
  countryFlag: Asset;
  destinationCountry: string;
  institution: string;
  process: string;
  trafficLight: string;
  waitingTime: string;
};

export class ContentfulAPI {
  contentTypeFaq: string;
  contentTypeResources: string;
  contentTypeWaitingTime: string;
  contentTypeDocumentsToTranslate: string;
  contentTypeCaseEvaluationSummaryMacros: string;
  contentTypeStatusUpdateTemplate: string;
  contentTypeTaskTemplate: string;
  additionalServicesMarketplace: string;
  referralCard: string;
  contentfulClient: ContentfulClientInterface;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.contentfulClient = new (ContentfulClient as any)({
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      space: process.env.REACT_APP_CONTENTFUL_SPACE,
    }) as ContentfulClientInterface;
    this.contentTypeFaq = 'knowledgePageFaqs';
    this.contentTypeResources = 'knowledgePageResources';
    this.contentTypeWaitingTime = 'knowledgePageWaitingTimes';
    this.contentTypeDocumentsToTranslate = 'documents-to-translate';
    this.contentTypeCaseEvaluationSummaryMacros = 'caseEvaluationSummaryMacro';
    this.contentTypeStatusUpdateTemplate = 'statusUpdateTemplate';
    this.contentTypeTaskTemplate = 'taskTemplate';
    this.additionalServicesMarketplace = 'additionalServicesMarketplace';
    this.referralCard = 'referralCardDashboard';
  }

  fetchData = async (contentType: string, entries: Array<unknown> = [], skip = 0): Entries => {
    const response = await this.contentfulClient.getEntries({ content_type: contentType, skip });
    const { items, total, skip: skipped, limit } = response;

    const shouldFetchMore = total > skipped + limit;
    entries = entries.concat(items.map((item) => item.fields));

    if (shouldFetchMore) {
      return this.fetchData(contentType, entries, skipped + limit);
    }

    return entries;
  };

  fetchFaq = (): Promise<ContentfulFAQ[]> =>
    this.fetchData(this.contentTypeFaq) as Promise<ContentfulFAQ[]>;

  fetchKnowledgeDocuments = (): Promise<ContentfulDocument[]> =>
    this.fetchData(this.contentTypeResources) as Promise<ContentfulDocument[]>;

  fetchWaitingTime = (): Promise<ContentfulWaitingTime[]> =>
    this.fetchData(this.contentTypeWaitingTime) as Promise<ContentfulWaitingTime[]>;

  fetchDocumentsToTranslate = async (caseType?: string, destinationCountry?: string): Entries => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeDocumentsToTranslate,
      'fields.caseType': caseType,
      'fields.country': destinationCountry,
    });

    return data.items.map((item) => item.fields);
  };

  fetchCaseEvaluationSummaryMacros = async ({
    destinationCountry,
    originCountry,
  }: {
    destinationCountry?: string;
    originCountry?: string;
  }): Promise<Macro[]> => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeCaseEvaluationSummaryMacros,
      'fields.destinationCountry[in]': `Default,${destinationCountry}`,
      'fields.originCountry[in]': `Default,${originCountry}`,
    });

    return data.items.map((item) => item.fields) as Macro[];
  };

  fetchStatusUpdateTemplates = async ({
    type,
  }: {
    type?: PostNewUpdateType;
  }): Promise<StatusUpdateTemplate[]> => {
    if (!type) {
      return [];
    }

    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeStatusUpdateTemplate,
      'fields.type[in]': `Default,${type}`,
    });

    return data.items.map((item) => {
      const { fields } = item;
      // include the id here for the templateDropdown
      return { sys: { id: item.sys.id }, ...(fields as object) };
    }) as StatusUpdateTemplate[];
  };

  fetchAdditionalServices = (): Promise<RawAdditionalServiceContent[]> =>
    this.fetchData(this.additionalServicesMarketplace) as Promise<RawAdditionalServiceContent[]>;

  fetchTaskTemplates = async (): Promise<TaskTemplate[]> => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeTaskTemplate,
    });

    return data.items.map((e) => {
      const { fields } = e;
      // include the id here for the templateDropdown
      return { sys: { id: e.sys.id }, ...(fields as object) };
    }) as TaskTemplate[];
  };

  fetchReferralCard = async (): Promise<Card[]> => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.referralCard,
    });

    return data.items.map((item) => {
      const { fields, sys } = item;

      return { ...(fields as ReferralCard), sys };
    });
  };
}
