import { SubscriptionPlanWithFeatures } from '../types/SubscriptionPlanWithFeatures';
import { SubscriptionPlan as SubscriptionPlan } from 'deprecated/api/subscription_plans/types/SubscriptionPlan';

const SUBSCRIPTION_FEATURES: Record<string, string[]> = {
  business: [
    'Everything in Starter',
    'Up to 3 HR Users',
    'Integrations to HR Tools',
    'Multiple Office Locations',
  ],
  enterprise: ['Everything in Business', 'Unlimited HR Users', 'Dedicated Key Account'],
  starter: ['Case Dashboard', '1 HR User', 'Knowledge Section', 'Notifications'],
};

export const addFeaturesToSubscriptionPlans = (
  subscriptionPlansResponse: SubscriptionPlan[],
): SubscriptionPlanWithFeatures[] =>
  subscriptionPlansResponse.map((subscription) => ({
    ...subscription,
    features: SUBSCRIPTION_FEATURES[subscription.name],
  }));
