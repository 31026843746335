import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { CaseType } from 'modules/cases/types/CaseType';

const getCaseTypes = async ({
  destinationCountryId,
}: {
  destinationCountryId?: number;
} = {}): Promise<CaseType[]> => {
  const params = new URLSearchParams();

  if (destinationCountryId) {
    params.set('destination_country_id', destinationCountryId.toString());
  }

  const { data } = await axios.get<CaseType[]>('/case_types', { params });

  return data;
};

export const useCaseTypes = ({
  destinationCountryId,
  enabled,
}: {
  destinationCountryId?: number;
  enabled?: boolean;
} = {}): UseQueryResult<CaseType[], AxiosError> => {
  const queryKeyByDestinationCountryId = [
    'by-destination-country',
    destinationCountryId && destinationCountryId.toString(),
  ];

  return useQuery({
    queryKey: ['case-types', destinationCountryId && queryKeyByDestinationCountryId],
    queryFn: () => getCaseTypes({ destinationCountryId }),
    enabled,
  });
};
