import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { CaseType } from 'modules/cases/types/CaseType';
import axios from 'axios';

const getCaseType = async (caseTypeId: number): Promise<CaseType> => {
  const url = `/case_types/${caseTypeId}`;
  const { data } = await axios.get<CaseType>(url);

  return data;
};

export const useCaseType = (caseTypeId: number, enabled = true): UseQueryResult<CaseType> => {
  return useQuery({
    queryKey: ['caseType', caseTypeId],
    queryFn: () => getCaseType(caseTypeId),
    enabled: enabled && !Number.isNaN(Number(caseTypeId)),
  });
};
