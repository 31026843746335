import { Control, FieldValues } from 'react-hook-form';
import { DynamicField } from './DynamicField';
import React, { ReactNode } from 'react';
import {
  Box,
  ControlledCheckbox,
  ControlledFormInput,
  ControlledFormSelect,
  ControlledRadioGroup,
  Label,
  Radio,
} from '@localyze-pluto/components';

export type ControlProp<TFieldValues extends FieldValues, TContext> = {
  control: Control<TFieldValues, TContext>;
};

export const ControlledFormField = <TFieldValues extends FieldValues, TContext>({
  field,
  control,
  isEditing,
}: ControlProp<TFieldValues, TContext> & {
  field: DynamicField;
  control: Control<TFieldValues, TContext>;
  isEditing: boolean;
}): ReactNode => {
  if (field.type === 'radio') {
    return (
      <Box.div>
        <Label htmlFor={field.id}>{field.label}</Label>
        <Box.div alignItems="center" display="flex" h="36px">
          <ControlledRadioGroup
            control={control}
            disabled={!isEditing}
            id={field.id}
            name={field.id}
            orientation="horizontal"
          >
            {field.options.map((option) => (
              <Radio key={option.label} label={option.label} value={String(option.value)} />
            ))}
          </ControlledRadioGroup>
        </Box.div>
      </Box.div>
    );
  }

  if (field.type === 'checkbox') {
    return (
      <ControlledCheckbox
        control={control}
        disabled={!isEditing}
        id={field.id}
        name={field.id}
        required={field.required}
      >
        <Label htmlFor={field.id}>{field.label}</Label>
      </ControlledCheckbox>
    );
  }

  if (field.type === 'select') {
    return (
      <ControlledFormSelect
        control={control}
        disabled={field.disabled || !isEditing}
        helpText={isEditing && field.helpText}
        id={field.id}
        items={field.options}
        label={field.label}
        name={field.id}
        required={field.required}
      />
    );
  }
  return (
    <ControlledFormInput
      control={control}
      disabled={field.disabled || !isEditing}
      helpText={isEditing && field.helpText}
      id={field.id}
      key={field.id}
      label={field.label}
      name={field.id}
      required={field.required}
      type={field.type}
    />
  );
};
