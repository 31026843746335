import { CaseType } from 'modules/cases/types/CaseType';
import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';
import { getCaseTypes } from 'deprecated/api/general';

const caseTypesToFilterOptions = (caseTypes: CaseType[]): FilterOption<string>[] =>
  caseTypes.map((item: CaseType) => ({
    filterAttribute: 'case_type_id',
    label: item.name,
    userTypes: ['admin', 'hr'],
    value: item.id.toString(),
  }));

const ALL_CASE_TYPES_OPTION: FilterOption<string> = {
  filterAttribute: 'case_type_id',
  label: 'All',
  userTypes: ['admin', 'hr'],
  value: 'all',
};

export const CASE_TYPE_FILTER: Filter<string> = {
  attribute: 'case_type_id',
  label: 'Case Type',
  options: [],
  populateOptions: async ({}) => [
    ALL_CASE_TYPES_OPTION,
    ...caseTypesToFilterOptions(await getCaseTypes()),
  ],
  type: 'multiselect',
  userTypes: ['admin', 'hr'],
};
