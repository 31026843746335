import React, { createContext, ReactNode, useContext } from 'react';
import { Tab } from 'types/Tab';

const CardWithTabsContext = createContext<Tab<string>[] | undefined>(undefined);

export const CardWithTabsProvider = ({
  children,
  tabs,
}: {
  children: ReactNode;
  tabs: Tab<string>[];
}): React.JSX.Element => {
  return <CardWithTabsContext.Provider value={tabs}>{children}</CardWithTabsContext.Provider>;
};

export const useCardWithTabsContext = (): Tab<string>[] => {
  const tabs = useContext(CardWithTabsContext);

  if (!tabs) {
    throw new Error('CardWithTabsContext: No value provided');
  }

  return tabs;
};
