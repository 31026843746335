import DateTimePick from './FormFields/DateTimePick';
import DropDown from './FormFields/DropDown';
import { FileUpload } from './FormFields/FileUpload/component';
import FormAutoComplete from './FormFields/FormAutoComplete';
import FormInput from './FormFields/FormInput';
import React from 'react';
import { useField } from 'formik';
import { FormCheckbox } from 'deprecated/components/FormFields/FormCheckbox';

export default function FormFields({ fieldType, label = '', options = [], ...props }) {
  const [field] = useField(props);
  const { value } = field;

  const Field = {
    input: <FormInput {...field} {...props} label={label} />,
    dropdown: <DropDown {...field} {...props} label={label} options={options} />,
    checkbox: <FormCheckbox label={label} value={value} {...props} />,
    dateTime: <DateTimePick {...field} {...props} label={label} />,
    file: <FileUpload {...field} {...props} label={label} />,
    autoComp: <FormAutoComplete label={label} {...field} {...props} options={options} />,
  };

  return Field[fieldType] || <h3>Field Not found! Please check your field type</h3>;
}
