import { MessageType } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/MessageType';

export const MESSAGE_TYPES: Record<string, MessageType> = {
  hr: 'hr_message',
  internal: 'internal',
  admin: 'admin_message',
};

export const mapTypeOfMessage = (authorType?: string, isInternal?: boolean): MessageType => {
  const key = authorType === 'admin' && isInternal ? 'internal' : authorType;
  return (MESSAGE_TYPES[key as string] as MessageType | undefined) || MESSAGE_TYPES.hr;
};
