import { Counters } from 'modules/home/api/usePendingCounters';
import { NavGroupProps } from 'modules/layout/components/Navbar/types/NavGroupProps';
import React from 'react';

export enum NavItemComponent {
  InvoiceModal,
}

export type NavItemCommon = {
  label: string;
  icon?: React.JSX.Element;
  withFeatureToggle?: boolean;
};

export type NavLink = NavItemCommon & {
  to: string;
  route?: string; // used for the route with replaced ID
  counter?: keyof Counters;
  exact?: boolean;
};

export type NavComponent = NavItemCommon & {
  // self rendering component (e.g. a Modal)
  component: NavItemComponent;
};

export type NavEntry = NavComponent | NavGroupProps | NavLink;
