import React from 'react';
import { TrackingSection } from './TrackingSection';
import { Box } from '@localyze-pluto/components';
import { useGlomoRequests } from 'modules/glomoRequests/api/useGlomoRequests/useGlomoRequests';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';

export const MobilityTracking = ({
  talent,
}: {
  talent: TalentWithPersonalInformation;
}): React.JSX.Element => {
  const { data, isLoading } = useGlomoRequests({
    params: { status: ['complete', 'request_approved'], talent_id: talent.id },
  });

  if (isLoading) return <ContainedLoadingState />;

  const workations = data?.filter((request) => request.type === 'WorkationAssessment') || [];
  const businessTrips = data?.filter((request) => request.type === 'BusinessTripAssessment') || [];

  return (
    <Box.div display="flex" flexDirection="column" gap="d6" padding="p6">
      <TrackingSection
        glomoRequests={workations}
        heading="Workations"
        tooltipText="List of approved workations"
      />
      <TrackingSection
        glomoRequests={businessTrips}
        heading="Business trips"
        tooltipText="List of approved business trips"
      />
    </Box.div>
  );
};
