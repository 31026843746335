import { Box, Text } from '@localyze-pluto/components';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import React from 'react';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { getTripDuration } from 'modules/talent/pages/talentProfile/utils/getTripDuration';
import { Link } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export const TripDurationItem = ({
  glomoRequest,
  isLastItem,
}: {
  glomoRequest: GlomoRequest;
  isLastItem: boolean;
}): React.JSX.Element => {
  const { data: countries, isSuccess: isSuccessCountries } = useCountries({});

  const routes = useRoutes();

  if (!isSuccessCountries) {
    return <></>;
  }

  const destinationCountry = countries.find(
    (country) => country.id === glomoRequest.trip_destination_country_id,
  )?.name;

  return (
    <>
      <Box.li display="block" w="100%">
        <Link to={routes.glomoRequest.get(glomoRequest.id)}>
          <Box.div
            backgroundColor={{ _: 'colorBackground', hover: 'colorBackgroundWeak' }}
            color="colorTextStrongest"
            display="grid"
            fontSize="fontSize20"
            gap="d4"
            gridTemplateColumns="40% 1fr 1fr"
            px="p6"
            py="p4"
            w="100%"
          >
            <Text.span>
              {`${formatDate(glomoRequest.trip_start_date)}`}
              {` - ${formatDate(glomoRequest.trip_end_date)}`}
            </Text.span>
            <Text.span>{getTripDuration(glomoRequest)} days</Text.span>
            <Text.span>{destinationCountry}</Text.span>
          </Box.div>
        </Link>
      </Box.li>
      {!isLastItem && <SectionDivider />}
    </>
  );
};
