import { NavGroupProps } from 'modules/layout/components/Navbar/types/NavGroupProps';
import { NavComponent, NavEntry, NavLink } from 'modules/layout/components/Navbar/types/NavLink';

export const isNavGroup = (navItem: NavEntry): navItem is NavGroupProps => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (navItem as NavGroupProps).items !== undefined;
};

export const isNavLink = (navItem: NavEntry): navItem is NavLink => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (navItem as NavLink).to !== undefined;
};

export const isNavComponent = (navItem: NavEntry): navItem is NavComponent => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (navItem as NavComponent).component !== undefined;
};
