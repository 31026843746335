import { Box, Input, InputProps } from '@localyze-pluto/components';
import { omit } from 'lodash';
import React, { useState } from 'react';

export type FromToDatepickerProps = {
  fromProps: Omit<InputProps, 'type'>;
  toProps: Omit<InputProps, 'type'>;
  fromText?: string;
  toText?: string;
  direction?: 'column' | 'row';
};

export const FromToDatepicker = ({
  fromProps,
  toProps,
  fromText = 'From:',
  toText = 'To:',
  direction = 'row',
}: FromToDatepickerProps): React.JSX.Element => {
  const [from, setFrom] = useState<string>((fromProps.defaultValue as string) || '');
  const [to, setTo] = useState<string>((toProps.defaultValue as string) || '');

  const fieldsTemplateColumns = direction === 'row' ? 'auto 1fr' : '1fr auto';

  return (
    <Box.ul
      display="grid"
      gap="d2"
      gridTemplateColumns={direction === 'row' ? '1fr 1fr' : 'unset'}
      gridTemplateRows={direction === 'column' ? '1fr 1fr' : 'unset'}
      listStyleType="none"
      margin="m0"
      padding="p0"
    >
      <Box.li
        alignItems="center"
        display="grid"
        gap="d2"
        gridTemplateColumns={fieldsTemplateColumns}
      >
        <Box.span>{fromText}</Box.span>
        <Input
          {...omit({ ...fromProps }, ['defaultValue'])}
          onChange={(ev) => {
            setFrom(ev.target.value);
            fromProps.onChange?.(ev);
          }}
          type="date"
          value={from}
        />
      </Box.li>
      <Box.li
        alignItems="center"
        display="grid"
        gap="d2"
        gridTemplateColumns={fieldsTemplateColumns}
      >
        <Box.span>{toText}</Box.span>
        <Input
          {...omit({ ...toProps }, ['defaultValue'])}
          onChange={(ev) => {
            setTo(ev.target.value);
            toProps.onChange?.(ev);
          }}
          type="date"
          value={to}
        />
      </Box.li>
    </Box.ul>
  );
};
