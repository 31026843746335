import {
  Box,
  Heading,
  Icon,
  Text,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import { getTripDuration } from 'modules/talent/pages/talentProfile/utils/getTripDuration';
import { TripDurationItem } from 'modules/talent/pages/talentProfile/components/TalentInformationCard/MobilityTracking/TripDurationItem';
import { CaseInformation } from 'modules/cases/types/CaseInformation';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';

type TrackingSectionProps = {
  heading: string;
  cases?: Case[];
  caseInformations?: CaseInformation[];
  tooltipText?: string;
  glomoRequests: GlomoRequest[];
};

export const TrackingSection = ({
  heading,
  tooltipText,
  glomoRequests,
}: TrackingSectionProps): React.JSX.Element => {
  const totalDuration = glomoRequests.reduce((acc, request) => acc + getTripDuration(request), 0);
  const tooltipStore = useTooltipStore();

  return (
    <Box.section display="flex" flexDirection="column" gap="d2">
      <Heading color="colorTextStrongest" marginBottom="m0" size="title-body">
        <Box.div display="flex" gap="m2">
          {heading}
          {tooltipText && (
            <>
              <TooltipAnchor store={tooltipStore}>
                <Icon decorative icon="info" size="sizeIcon30" />
              </TooltipAnchor>
              <Tooltip store={tooltipStore} style={{ zIndex: '40' }}>
                {tooltipText}
              </Tooltip>
            </>
          )}
        </Box.div>
      </Heading>
      <Box.ul
        alignItems="flex-start"
        aria-label={heading}
        borderRadius="borderRadius30"
        borderStyle="borderStyleSolid"
        borderWidth="borderWidth10"
        display="flex"
        flexDirection="column"
        marginBottom="m0"
        paddingLeft="p0"
      >
        <Box.li
          borderBottomStyle={totalDuration === 0 ? undefined : 'borderStyleSolid'}
          borderBottomWidth="borderWidth10"
          display="grid"
          fontSize="fontSize20"
          fontWeight="fontWeightBold"
          gap="d4"
          gridTemplateColumns="40% 1fr 1fr"
          px="p6"
          py="p4"
          w="100%"
        >
          <Text.span>Total days this year</Text.span>
          <Text.span>{totalDuration} days</Text.span>
        </Box.li>
        {glomoRequests.map((request, index) => (
          <TripDurationItem
            glomoRequest={request}
            isLastItem={index === glomoRequests.length - 1}
            key={request.id}
          />
        ))}
      </Box.ul>
    </Box.section>
  );
};
