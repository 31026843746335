import axios from 'axios';
import { GlomoRequestRisk } from '../types/GlomoRequestRisk';
import { GlomoRequestStatus } from '../types/GlomoRequestStatus';
import { GlomoRequest } from '../types/GlomoRequest';
import { GlomoRequestType } from '../types/GlomoRequestType';

export type UseGlomoRequestsParams = {
  company_id?: number;
  status?: GlomoRequestStatus[];
  risk_level?: GlomoRequestRisk;
  type?: GlomoRequestType;
  sort_by?: string;
  sort_direction?: 'asc' | 'desc';
  talent_id?: number;
};

export const getGlomoRequests = async (
  params?: UseGlomoRequestsParams,
): Promise<GlomoRequest[]> => {
  const { data } = await axios.get<GlomoRequest[]>('/glomo/requests', { params });

  return data;
};
