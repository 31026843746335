export const SERVICE_LEVELS = [
  {
    id: 3,
    label: 'Premium',
    features: [
      'Dedicated Case Manager with expert knowledge',
      'Individual support via chat and phone',
      'Appointment booking',
      'Step-by-step guide, including document lists and articles',
    ],
  },
  {
    id: 2,
    label: 'Assisted',
    features: [
      'Step-by-step guide, including document lists and articles',
      'Individual support via chat',
    ],
  },
  {
    id: 1,
    label: 'Basic',
    features: ['Step-by-step guide, including document lists and articles'],
  },
];
