import React from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { Box, Callout } from '@localyze-pluto/components';

const namingConventionsUrl = `https://www.notion.so/localyze/
Naming-conventions-for-variables-3eba48e6fea344e1a5f6b583306c9b21`;

const variableCreationGuideUrl = `https://www.notion.so/localyze/
Variable-Creation-Guide-318f9c2647444cb5b49a9c2acb75aa87`;

type InstructionType = 'createGroup' | 'createVariable';

const instructions: Record<InstructionType, React.JSX.Element> = {
  createGroup: (
    <Box.div gridColumn="span 2">
      <Callout variant="information">
        Please remember to create the title of the variable group following our{' '}
        <a
          className="tw-text-blue-700 tw-underline hover:tw-text-blue-700"
          href={namingConventionsUrl}
          onClick={() => trackEvent('variables: view naming convention guide')}
          rel="noreferrer"
          target="_blank"
        >
          naming conventions.
        </a>
      </Callout>
    </Box.div>
  ),
  createVariable: (
    <Box.div gridColumn="span 2">
      <Callout variant="information">
        If you need help to choose the right properties for your variable, check the{' '}
        <a
          className="tw-text-blue-500 tw-underline hover:tw-text-blue-500"
          href={variableCreationGuideUrl}
          onClick={() => trackEvent('variables: view variable creation guide')}
          rel="noreferrer"
          target="_blank"
        >
          variable creation guide
        </a>
        .
      </Callout>
    </Box.div>
  ),
};

export const Instructions = ({ step }: { step: InstructionType }): React.JSX.Element => {
  return instructions[step];
};
