import * as Yup from 'yup';

import FormFields from 'deprecated/components/FormFields';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  fetchNewPipelineData,
  submitAddPipeline,
} from 'deprecated/pages/viewsNew/Admin/PipelineEditor/utils/pipeline_editor';
import PropTypes from 'prop-types';
import { SubHeading } from 'deprecated/components/Fonts';
import { Validation } from 'constants/formValidations';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@localyze-pluto/components';

export default function CreateNewPipeline({ hookSuccessAddPipeline = () => {}, state }) {
  const { data: countries, isPending: isCountriesPending } = useCountryOptions();
  const [data, setData] = useState({ data: { caseTypes: [] }, requestDone: false });

  useEffect(() => {
    const callbackSuccess = ({ caseTypes }) => setData({ data: { caseTypes }, requestDone: true });
    fetchNewPipelineData({ callbackSuccess });
  }, []);

  const handleSubmit = ({ title, destinationCountryId, caseTypeId }) => {
    const newPipelineData = { title, destinationCountryId, caseTypeId };

    const callbackSuccess = ({ newPipeLine }) => hookSuccessAddPipeline(newPipeLine);
    submitAddPipeline({ newPipelineData, callbackSuccess });
    trackEvent('pipeline editor: create new pipeline', { destinationCountryId });
  };

  const initialValues = {
    title: '',
    destinationCountryId: '',
    caseTypeId: '',
  };

  const validationSchema = Yup.object({
    title: Validation.required,
  });

  if (!data.requestDone || isCountriesPending) return null;

  const { caseTypes } = data.data;

  return (
    <Modal store={state}>
      <ModalHeader>
        <SubHeading bold>Create a new pipeline</SubHeading>
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <ModalBody>
            <Box.div>
              <FormFields fieldType="input" name="title" label="Name of the pipeline" />
              <Box.div display="flex" gap="d4">
                <Box.div flexBasis="50%">
                  <FormFields
                    fieldType="dropdown"
                    name="destinationCountryId"
                    label="Destination Country"
                    options={countries}
                    withNone
                  />
                </Box.div>
                <Box.div flexBasis="50%">
                  <FormFields
                    fieldType="dropdown"
                    name="caseTypeId"
                    label="Case Type"
                    options={caseTypes}
                    withNone
                  />
                </Box.div>
              </Box.div>
            </Box.div>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={state.toggle}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Create
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
}

CreateNewPipeline.propTypes = {
  hookSuccessAddPipeline: PropTypes.func,
};
