import { Button, useModalStore } from '@localyze-pluto/components';
import React, { useEffect, useState } from 'react';
import { CreateMessageModal } from './CreateMessageModal/CreateMessageModal';

const generateKey = () => Math.random().toString(16).substring(2);

export const CreateNewMessage = ({ caseId }: { caseId: number }): React.JSX.Element => {
  const modal = useModalStore({ defaultOpen: false });
  const [key, setKey] = useState(generateKey());
  const isModalOpen = modal.useState('open');

  useEffect(() => {
    if (!isModalOpen) {
      setKey(generateKey());
    }
  }, [isModalOpen]);

  return (
    <>
      <Button
        leadingIcon="send"
        onClick={() => modal.show()}
        style={{ whiteSpace: 'nowrap' }}
        variant="ghost"
      >
        New message
      </Button>
      <CreateMessageModal caseId={caseId} key={key} state={modal} />
    </>
  );
};
