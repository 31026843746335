import axios, { AxiosPromise } from 'axios';
import { TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';

export type SearchParameters = {
  search: string;
  companyId?: number;
};

export const searchTalents = ({
  search,
  companyId,
}: SearchParameters): AxiosPromise<TalentSearchResult[]> =>
  axios.get('/talents/search', { params: { search, company_id: companyId } }) as AxiosPromise<
    TalentSearchResult[]
  >;
