import { Box, Icon } from '@localyze-pluto/components';
import React from 'react';
import { TooltipCell } from './TooltipCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  text: Maybe<string>;
  caseOverview: CaseOverview;
  isAdmin?: boolean;
  isPinned: boolean;
};

export const InternalNoteCell = ({
  isAdmin = false,
  caseOverview,
  text,
  isPinned,
}: Props): React.JSX.Element => {
  return (
    <TooltipCell caseOverview={caseOverview} isAdmin={isAdmin} tooltipText={text}>
      {isPinned && <Icon color="colorIconWeaker" decorative icon="pin" size="sizeIcon20" />}
      <Box.span display="block">{text}</Box.span>
    </TooltipCell>
  );
};
