import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Office } from '../../types/Office';
import { UseOfficesProps } from './useOffices.types';
import { AxiosError } from 'axios';
import { getOffices } from 'modules/offices/api/useOffices/getOffices';

/**
 * Gets list of offices by company
 * @param params.companyId - company to fetch offices for
 * @returns offices
 */
export const useOffices = <Result = Office[]>({
  params,
  enabled,
  ...props
}: UseOfficesProps<Result>): UseQueryResult<Result, AxiosError> =>
  useQuery({
    queryKey: ['company-offices', params.companyId],
    queryFn: () => getOffices(params.companyId),
    enabled: params.companyId !== undefined && enabled,
    ...props,
  });
