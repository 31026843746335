import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-theme-pluto.scss';

import { Box, Button, useModalStore, useToast } from '@localyze-pluto/components';
import {
  CsvExportParams,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
  ValueGetterParams,
} from 'ag-grid-community';
import React, { useCallback, useState } from 'react';
import { ReportingTableCardFilter } from './ReportingTableCardFilter/ReportingTableCardFilter';

import { AgGridReact } from 'ag-grid-react';
import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { CaseStateFilter } from 'modules/cases/types/CaseStateFilter';
import { QueryParams } from 'modules/filters/types/QueryParams';
import { ReportingTableSearch } from './ReportingTableSearch/ReportingTableSearch';
import { ReportingTableShareModal } from './ReportingTableShareModal/ReportingTableShareModal';
import { format } from 'date-fns';
import { getCasesNumber } from './getters/getCasesNumber/getCasesNumber';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { useReport } from 'modules/reporting/api/useReport';
import {
  HeaderSkeleton,
  MainSkeleton,
} from 'modules/reporting/pages/reporting/components/ReportingTable/ReportingTableSkeletonLoader';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';
import { CaseState } from 'modules/cases/types/CaseState';
import { useReportingColumnDefs } from 'modules/reporting/pages/reporting/components/ReportingTable/hooks/useReportingColumnDefs';
import { TOOLTIP_TEXTS } from 'modules/reporting/constants/tooltipTexts';
import { shareReport } from 'modules/reporting/api/shareReport';
import { useCurrentUser } from 'config/CurrentUserContext';
import { FromToDatepicker } from 'components/Datepicker/FromToDatepicker/FromToDatepicker';
import { debounce } from 'lodash';
import { EmptyState } from 'components/EmptyState/EmptyState';
import LocalyzeIcon from 'assets/img/localyze-logo-globe.png';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

type ReportingTableProps = {
  companyId: number;
  startDate?: string;
  endDate?: string;
  onChangeStartDate?: (date: string) => void;
  onChangeEndDate?: (date: string) => void;
};

export const ReportingTable = ({
  companyId,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}: ReportingTableProps): React.JSX.Element => {
  const currentUser = useCurrentUser();

  const {
    data: report,
    isPending,
    isSuccess,
    error,
  } = useReport<CaseReport[]>('default', {
    company_id: companyId,
    end_date: endDate || undefined,
    start_date: startDate || undefined,
  });

  const gridRef = React.useRef<AgGridReact>(null);
  const { queryParams, updateQueryParams } = useQueryParams();
  const [gridApi, setGridApi] = useState<GridApi>();
  const results = report?.results || [];

  const [caseState, setCaseState] = useState<CaseStateFilter>(CaseStateFilter.All);
  const shareReportModal = useModalStore();
  const shareReportTextareaRef = React.createRef<HTMLTextAreaElement>();
  const toast = useToast();
  const { columnDefs, defaultColDef } = useReportingColumnDefs();

  const onSearch = (value: string) => {
    gridApi?.setGridOption('quickFilterText', value);
  };

  const onExportClick = useCallback(() => {
    const currDateTime = new Date();

    const from = startDate ? ` from ${formatDate(startDate)}` : '';
    const to = endDate ? ` to ${formatDate(endDate)}` : '';

    const fileName = `Report ${format(currDateTime, 'yyyy-MM-dd')} ${from} ${to} at ${format(
      currDateTime,
      'h.mm.ss a',
    )}.csv`;

    const csvOptions: CsvExportParams = {
      fileName,
      allColumns: true,
    };

    gridApi?.exportDataAsCsv(csvOptions);
    toast('The report was successfully exported.', 'success');

    trackEvent('reporting: export report', {
      company_id: currentUser.company_id,
      user_role: currentUser.user_role,
      reporting_type: 'table',
      filter_for_cases: caseState,
      start_date: startDate,
      end_date: endDate,
    });

    trackEvent('reporting: download report');
  }, [gridApi, currentUser, caseState, toast, startDate, endDate]);

  const onShareClick = () => {
    shareReportModal.toggle();

    trackEvent('reporting: share report', {
      company_id: currentUser.company_id,
      user_role: currentUser.user_role,
      reporting_type: 'table',
      filter_for_cases: caseState,
      start_date: startDate,
      end_date: endDate,
    });
  };

  const casesAtRisk = results.filter((talent) => {
    return talent.talentStartAtRisk === true;
  });

  const onFilterChanged = (event: FilterChangedEvent<CaseReport>) => {
    // On change of filter, saves filters as query params
    const filterModel = event.api.getFilterModel();

    const noItemsLength = 0;
    const updatedQueryParams: QueryParams =
      Object.keys(filterModel).length > noItemsLength
        ? { filters: JSON.stringify(filterModel) }
        : {};

    updateQueryParams(updatedQueryParams, true);
  };

  const onGridReady = (event: GridReadyEvent<CaseReport>) => {
    setGridApi(event.api);
    event.api.autoSizeColumns(['talentEmail']);

    if (queryParams.filters) {
      event.api.setFilterModel(JSON.parse(queryParams.filters));
    }
  };

  const externalFilterChanged = useCallback(
    (newCaseState: CaseStateFilter, _event: React.ChangeEvent<HTMLInputElement>) => {
      setCaseState(caseState !== newCaseState ? newCaseState : CaseStateFilter.All);

      trackEvent('reporting: show cases based on filter', {
        company_id: currentUser.company_id,
        user_role: currentUser.user_role,
        reporting_type: 'table',
      });

      gridRef.current?.api.onFilterChanged();
    },
    [caseState, currentUser],
  );

  const isExternalFilterPresent = useCallback(() => {
    return caseState !== 'all';
  }, [caseState]);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<CaseReport>) => {
      if (!node.data) {
        return true;
      }

      if (caseState === CaseStateFilter.Active) {
        return node.data.currentState !== CaseState.Closed;
      }
      if (caseState === CaseStateFilter.Closed) {
        return node.data.currentState === CaseState.Closed;
      }
      if (caseState === CaseStateFilter.StartDateAtRisk) {
        return node.data.talentStartAtRisk === true;
      }

      return true;
    },
    [caseState],
  );

  const rowClassRules = {
    'row-talent-at-risk': (params: Pick<ValueGetterParams<CaseReport>, 'data'>) => {
      return params.data?.talentStartAtRisk === true;
    },
  };

  if (error) {
    rollbarInstance.error(`Error on Reporting graphs ${error.message}`);
    return <ErrorStatePage />;
  }

  return (
    <>
      <Box.div
        alignItems="stretch"
        display="grid"
        gap="d7"
        gridTemplateColumns={isPending ? 1 : 4}
        marginBottom="m8"
      >
        {isPending && <HeaderSkeleton />}
        {!isPending && (
          <>
            <ReportingTableCardFilter
              checked={caseState === CaseStateFilter.All}
              count={results.length}
              disabled={results.length === 0}
              icon="circle-user"
              onChange={(event) => {
                externalFilterChanged(CaseStateFilter.All, event);
              }}
              title="Total Cases"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.All]}
            />
            <ReportingTableCardFilter
              checked={caseState === CaseStateFilter.Active}
              count={getCasesNumber(CaseStateFilter.Active, results)}
              data-testid="active-checkbox"
              disabled={getCasesNumber(CaseStateFilter.Active, results) === 0}
              icon="circle-ellipsis"
              onChange={(event) => {
                externalFilterChanged(CaseStateFilter.Active, event);
              }}
              title="Active Cases"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.Active]}
            />
            <ReportingTableCardFilter
              checked={caseState === CaseStateFilter.Closed}
              count={getCasesNumber(CaseStateFilter.Closed, results)}
              data-testid="completed-checkbox"
              disabled={getCasesNumber(CaseStateFilter.Closed, results) === 0}
              icon="circle-check"
              onChange={(event) => {
                externalFilterChanged(CaseStateFilter.Closed, event);
              }}
              title="Closed Cases"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.Closed]}
            />

            <ReportingTableCardFilter
              checked={caseState === CaseStateFilter.StartDateAtRisk}
              count={casesAtRisk.length}
              data-testid="danger-checkbox"
              disabled={casesAtRisk.length === 0}
              icon="circle-alert"
              onChange={(event) => {
                externalFilterChanged(CaseStateFilter.StartDateAtRisk, event);
              }}
              title="Start date at risk"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.StartDateAtRisk]}
              variant="danger"
            />
          </>
        )}
      </Box.div>
      <Box.div
        alignItems={{ _: 'flex-end', md: 'center' }}
        className="pluto-search"
        display="flex"
        flexDirection={{ _: 'column', md: 'row' }}
        gap="d3"
        justifyContent={{ md: 'space-between' }}
        marginBottom="m6"
      >
        <Box.div display="flex" gap="d8">
          <FromToDatepicker
            fromProps={{
              'aria-label': 'From:',
              defaultValue: startDate,
              onChange: debounce((ev) => onChangeStartDate?.(ev.target.value), 500),
              role: 'textbox',
            }}
            toProps={{
              'aria-label': 'To:',
              defaultValue: endDate,
              onChange: debounce((ev) => onChangeEndDate?.(ev.target.value), 500),
              role: 'textbox',
            }}
          />
          <ReportingTableSearch onSearch={onSearch} />
        </Box.div>

        <Box.div display="flex" gap="d3">
          <Button disabled={!report?.results.length} onClick={onShareClick} variant="secondary">
            Share
          </Button>
          <Button
            disabled={!report?.results.length}
            onClick={onExportClick}
            trailingIcon="arrow-down-to-line"
            variant="primary"
          >
            Export Report
          </Button>
        </Box.div>
      </Box.div>

      <ReportingTableShareModal
        focusRef={shareReportTextareaRef}
        onSubmit={async (emails) => {
          trackEvent('reporting: report shared', {
            company_id: currentUser.company_id,
            user_role: currentUser.user_role,
            reporting_type: 'table',
            filter_for_cases: caseState,
          });

          return shareReport({ emails, start_date: startDate, end_date: endDate });
        }}
        state={shareReportModal}
      />

      {isPending && <MainSkeleton />}
      {isSuccess && !report.results.length && (
        <EmptyState
          illustration={<Box.img src={LocalyzeIcon} width="50px" />}
          text="There are no cases that match your chosen filters. Try changing them."
        />
      )}
      {isSuccess && !!report.results.length && (
        <Box.div className="ag-theme-alpine ag-theme-pluto" h="100%" paddingBottom="p3" w="100%">
          <AgGridReact<CaseReport>
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            doesExternalFilterPass={doesExternalFilterPass}
            enableCellTextSelection
            isExternalFilterPresent={isExternalFilterPresent}
            onFilterChanged={onFilterChanged}
            onGridReady={onGridReady}
            pagination
            ref={gridRef}
            rowClassRules={rowClassRules}
            rowData={results}
            rowHeight={52}
          />
        </Box.div>
      )}
    </>
  );
};
