import { Container } from 'deprecated/components/Chewpaca/Container';
import { HrRoutes, useRoutes } from 'modules/routes/useRoutes/useRoutes';

import React from 'react';
import { StatusCounter } from 'deprecated/components/Chewpaca/StatusCounter';
import { Button, Icon, Tooltip, TooltipAnchor, useTooltipStore } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';

type Props = {
  readonly preChecksCount: number;
};

const INFORMATION =
  "Pre-Checks allow you to get feedback from Localyze Case Managers' " +
  'prior experience to make an informed decision about global mobility ' +
  'processes for current employees & potential candidates.';

export const PreChecksStatus = ({ preChecksCount }: Props): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const routes = useRoutes();
  const tooltipState = useTooltipStore({ placement: 'left' });

  return (
    <Container className="tw-rounded-2xl tw-bg-white tw-p-8 tw-items-center">
      <h2 className="tw-font-sans tw-text-lg tw-leading-6 tw-font-medium">
        Feel lost? Start with a Pre-check!
        <TooltipAnchor store={tooltipState}>
          <Icon
            aria-hidden="true"
            className="tw-ml-1.5 tw-h-6 tw-w-6"
            color="colorIconWeak"
            decorative={false}
            icon="info"
            size="sizeIcon40"
            title="PreCheck Information"
          />
        </TooltipAnchor>
        <Tooltip store={tooltipState}>{INFORMATION}</Tooltip>
      </h2>
      {!isAdmin && (
        <Button
          as="a"
          fullWidth
          href={(routes as HrRoutes).preCheck.url}
          leadingIcon="square-pen"
          variant="outline"
        >
          New pre-check
        </Button>
      )}
      <StatusCounter
        className="tw-pt-4"
        count={preChecksCount}
        title="Submitted Pre-checks this month"
      />
    </Container>
  );
};
