import {
  Box,
  Button,
  Heading,
  HelpText,
  Icon,
  Modal,
  ModalBody,
  ModalStore,
  Paragraph,
  TextArea,
} from '@localyze-pluto/components';
import React, { useState } from 'react';

export const DenyRequestModal = ({
  store,
  onClickDeny,
}: {
  store: ModalStore;
  onClickDeny: (reason: string) => void;
}): React.JSX.Element => {
  const [denialReason, setDenialReason] = useState('');
  const [hasError, setHasError] = useState(false);

  return (
    <>
      <Modal store={store} style={{ maxWidth: '400px' }}>
        <ModalBody>
          <Box.div
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap="d4"
            textAlign="center"
          >
            <Box.div
              alignContent="center"
              backgroundColor="colorBackgroundError"
              borderRadius="borderRadius20"
              display="flex"
              justifyContent="center"
              padding="p2"
              w={36}
            >
              <Icon color="colorIconError" decorative icon="circle-x" />
            </Box.div>
            <Box.div display="flex" flexDirection="column" gap="d2" textAlign="center">
              <Heading as="h2" marginBottom="m0" size="heading60">
                You are about to deny this request
              </Heading>
              <Paragraph marginBottom="m0" size="large">
                Please let the talent know, why you are denying their request. The reason will be
                shared with your talent.
              </Paragraph>
            </Box.div>
            <Box.div w="100%">
              <TextArea
                aria-errormessage="denial-reason-error-message"
                aria-label="Denial reason"
                hasError={hasError}
                onChange={(event) => {
                  setDenialReason(event.target.value);
                  setHasError(false);
                }}
                placeholder="Please provide a reason"
                value={denialReason}
              />
              {hasError && (
                <HelpText hasError id="denial-reason-error-message">
                  This field is required
                </HelpText>
              )}
            </Box.div>
            <Box.div display="flex" gap="d3" justifyContent="center">
              <Button
                onClick={() => {
                  store.hide();
                  setHasError(false);
                  setDenialReason('');
                }}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (denialReason === '') {
                    setHasError(true);
                  } else {
                    onClickDeny(denialReason);
                  }
                }}
                variant="destructive"
              >
                Deny request
              </Button>
            </Box.div>
          </Box.div>
        </ModalBody>
      </Modal>
    </>
  );
};
