import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { useTalents } from 'modules/talent/api/useTalents/useTalents';
import React, { useMemo } from 'react';
import { TalentsTable } from './components/TalentsTable/TalentsTable';
import {
  Box,
  Button,
  Icon,
  Tab as PlutoTab,
  TabList,
  Tabs,
  useDrawerStore,
} from '@localyze-pluto/components';
import { PaginationWithCounter } from 'components/PaginationWithCounter/PaginationWithCounter';
import { usePagination } from 'modules/filters/hooks/usePagination';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { useTabWithQueryParams } from 'deprecated/hooks/custom/useTabWithQueryParams';
import { useCurrentUser } from 'config/CurrentUserContext';
import { MY_TALENT_TABS } from './constants';
import { getTabsByUserRole } from 'modules/utils/getTabsByUserRole';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import {
  FiltersDrawer,
  FiltersValues,
} from 'modules/filters/components/FiltersDrawer/FiltersDrawer';
import { useFiltersOptions } from 'modules/filters/hooks/useFiltersOptions';
import { COMPANY_FILTER, parseSelectedFilters } from 'deprecated/libs/filters';
import { SIGN_UP_STATUS_FILTER } from 'modules/talent/filters/signUpStatusFilter';
import { Filter } from 'modules/filters/types/Filter';
import { getMyTalentFilterParams } from 'modules/talent/utils/getMyTalentFilterParams';

const ITEMS_PER_PAGE = 60;

export const TalentList = (): React.JSX.Element => {
  const user = useCurrentUser();

  const [page, setPage] = usePagination();

  const drawerStore = useDrawerStore();
  const isDrawerOpen = drawerStore.useState('open');

  const { tabs, selectedTab, getTabIndex, updateSelectedTab } = useTabWithQueryParams(
    getTabsByUserRole(MY_TALENT_TABS, user.user_role),
  );

  const { isSuccess: isSuccessFilters, data: availableFilters = [] } = useFiltersOptions({
    queryKey: `talent-filters-${selectedTab.key}`,
    filterParams: {
      filters: [SIGN_UP_STATUS_FILTER, COMPANY_FILTER],
      isAdmin: user.isAdmin,
      tab: `${selectedTab.key}`,
    },
  });

  const { queryParams, updateQueryParams } = useQueryParams();

  const selectedFilters = useMemo(
    () => parseSelectedFilters(availableFilters, queryParams),
    [availableFilters, queryParams],
  );

  const filterParams = getMyTalentFilterParams(selectedTab.key, user, selectedFilters);

  const {
    data,
    isSuccess: isSuccessTalents,
    isPending: isPendingTalents,
  } = useTalents({
    page,
    limit: ITEMS_PER_PAGE,
    ...filterParams,
  });

  const onApplyDrawerFilters = (filtersData: FiltersValues) => {
    availableFilters.forEach((filter) => {
      updateQueryParams({ [`${filter.attribute}`]: filtersData[filter.attribute] as string });
    });
    drawerStore.hide();
  };

  const talents = data?.talents || [];
  const hasTalents = talents.length > 0;

  const changeTab = (tab: string) => {
    const tabIndex = getTabIndex(tabs, tab);
    updateSelectedTab(tabIndex);
  };

  return (
    <PageLayout flexed title="My Talent">
      <>
        <Box.div alignItems="flex-end" display="flex" justifyContent="space-between">
          <Tabs initialTabId={selectedTab.key}>
            <TabList aria-label="Talent list tabs">
              {tabs.map((tab) => (
                <PlutoTab id={tab.key} key={tab.key} onClick={() => changeTab(tab.key)}>
                  {tab.name}
                </PlutoTab>
              ))}
            </TabList>
          </Tabs>
          <Button
            disabled={!isSuccessTalents || !isSuccessFilters}
            leadingIcon="sliders-vertical"
            onClick={drawerStore.show}
            variant="secondary"
          >
            Filter
          </Button>
        </Box.div>
        <Box.div
          alignItems={hasTalents ? 'stretch' : 'center'}
          display="flex"
          flex={1}
          flexDirection="column"
          gap="d5"
          justifyContent="space-between"
          marginBottom="m6"
          marginTop="d4"
        >
          {isPendingTalents ? (
            <ContainedLoadingState />
          ) : hasTalents ? (
            <TalentsTable talents={talents} />
          ) : (
            <EmptyState
              gap="d2"
              illustration={
                <Icon color="contentTertiary" decorative icon="user-plus" size="sizeIcon30" />
              }
              padding="p0"
              text="Once you create a talent, they will be visible here."
              w="217px"
            />
          )}
        </Box.div>
      </>
      {isSuccessTalents && hasTalents && (
        <PaginationWithCounter
          itemsPerPage={ITEMS_PER_PAGE}
          paginationInfo={data.meta}
          setPage={setPage}
        />
      )}
      {isSuccessTalents && isSuccessFilters && isDrawerOpen && (
        <FiltersDrawer
          availableFilters={availableFilters as Filter<string>[]}
          initialFilterValues={selectedFilters as FiltersValues}
          onApply={onApplyDrawerFilters}
          store={drawerStore}
        />
      )}
    </PageLayout>
  );
};
