// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldValues = Record<string, any>;

export const formatDatesToTimestamps = (values: FieldValues): FieldValues => {
  const formatted: FieldValues = Object.entries(values).reduce((result, [code, date]) => {
    result[code] = `${date}T12:00:00`;

    return result;
  }, {} as FieldValues);

  return formatted;
};
