import Card from 'deprecated/components/Card/CardContainer';
import FormFields from 'deprecated/components/FormFields';
import Line from 'deprecated/components/Line/Line';
import { Form, Formik } from 'formik';
import {
  buildPipeline,
  fetchAllPipelines,
  HandlerLocalStorePE,
} from 'deprecated/pages/viewsNew/Admin/PipelineEditor/utils/pipeline_editor';
import { P1 } from 'deprecated/components/Fonts';
import React, { useEffect, useState } from 'react';

import CreateNewPipeline from './CreateNewPipeline';
import { PageLayout } from 'modules/layout/components/PageLayout';
import PipelineUpdate from 'deprecated/pages/viewsNew/Admin/PipelineEditor/PipelineUpdate';
import StepsList from './StepsList';
import styled from 'styled-components';
import { trackPageView } from 'modules/analytics/analytics';
import { Box, Button, useModalStore } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

export default function PipelineEditor() {
  const handlerLocalStorePE = new HandlerLocalStorePE();
  const newPipelineModalState = useModalStore({
    defaultOpen: false,
  });

  const isModalOpen = newPipelineModalState.useState('open');

  const buildInitState = () => {
    const { selectedPipelineId, selectedDestCountryId } = handlerLocalStorePE.retrieveStatePE();

    return {
      pipelines: [],
      selectedPipelineId,
      destinationCountries: [],
      selectedDestCountryId,
      requestDone: false,
    };
  };

  const [data, setData] = useState(buildInitState());

  const fetchData = () => {
    const callbackSuccess = ({ pipelines, destinationCountries }) =>
      setData({
        ...data,
        destinationCountries,
        pipelines: pipelines.map(({ id, title, destinationCountryId, steps }) => ({
          id,
          title,
          destinationCountryId,
          steps,
        })),
        requestDone: true,
      });
    fetchAllPipelines({ callbackSuccess });
  };
  const {
    destinationCountries,
    pipelines,
    selectedPipelineId,
    selectedDestCountryId,
    requestDone,
  } = data;

  const hookSuccessAddPipeline = (newPipeline) => {
    const newPipeLineToConcat = buildPipeline(newPipeline);
    const { destinationCountryId, id } = newPipeLineToConcat;

    const IsDestCountryOnList = destinationCountries.find(
      ({ value }) => value === destinationCountryId,
    );

    setData({
      ...data,
      pipelines: pipelines.concat({ ...newPipeLineToConcat }),
      destinationCountries: IsDestCountryOnList
        ? destinationCountries
        : destinationCountries.concat({
            value: destinationCountryId,
            label: newPipeline.destination_country.name,
          }),
      selectedPipelineId: id,
      selectedDestCountryId: destinationCountryId,
    });
    newPipelineModalState.toggle();
  };

  const selectPipeline = (id) => {
    setData({ ...data, selectedPipelineId: id });
  };

  useEffect(() => {
    fetchData();
    trackPageView('Pipeline Editor');
  }, []); // eslint-disable-line

  useEffect(() => {
    handlerLocalStorePE.storeStatePE({ selectedPipelineId, selectedDestCountryId });
  }, [data]); // eslint-disable-line

  const handleSelectDestination = (value) =>
    setData({ ...data, selectedDestCountryId: Number(value), selectedPipelineId: 0 });

  const hookAfterOrderStep = () => fetchData();

  const hookAfterEditPipe = ({ id, title }) =>
    setData({
      ...data,
      pipelines: pipelines.map((e) => (e.id === id ? { ...e, title } : e)),
    });

  const selectedPipeline = pipelines.find((pipe) => pipe.id === selectedPipelineId) || {};

  if (!requestDone) return <ContainedLoadingState />;

  const sortedPipelinesForDestination = pipelines
    .filter((pipe) => pipe.destinationCountryId === selectedDestCountryId)
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <PageLayout flexed title="Pipeline Editor">
      <Box.div display="grid" columnGap="p5" gridTemplateColumns="1fr 2fr">
        <Card outlined display="" padding="16px" borderRadius="5px 5px 0px 0px">
          <Formik initialValues={{}}>
            <Form>
              <FormFields
                fieldType="dropdown"
                name="destinationCountryId"
                label="Destination country"
                options={destinationCountries}
                onChange={handleSelectDestination}
                value={selectedDestCountryId}
              />
            </Form>
          </Formik>
        </Card>
        <PipelineUpdate pipeline={selectedPipeline} hookAfterEditPipe={hookAfterEditPipe} />
        <Box.div>
          <Card outlined display="" padding="20px" height="50vh" overflow="auto" borderRadius="0px">
            {sortedPipelinesForDestination.map(({ id, title }) => (
              <React.Fragment key={id}>
                <Pipeline
                  key={id}
                  onClick={() => selectPipeline(id)}
                  selected={selectedPipelineId === id}
                >
                  <P1>{title}</P1>
                </Pipeline>
                <Line />
              </React.Fragment>
            ))}
          </Card>
          <Card outlined display="" padding="11px" borderRadius="0px 0px 5px 5px">
            <Box.div display="flex" justifyContent="center" marginTop="m2" marginBottom="m2">
              <Button variant="secondary" onClick={newPipelineModalState.show}>
                Create new pipeline
              </Button>
            </Box.div>
            {isModalOpen && (
              <CreateNewPipeline
                state={newPipelineModalState}
                hookSuccessAddPipeline={hookSuccessAddPipeline}
                pipelineId={selectedPipelineId}
              />
            )}
          </Card>
        </Box.div>
        {selectedPipeline && (
          <StepsList pipeline={selectedPipeline} hookAfterOrderStep={hookAfterOrderStep} />
        )}
      </Box.div>
    </PageLayout>
  );
}

const Pipeline = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#F0F0F0' : '')};
`;
