import axios, { AxiosResponse } from 'axios';
import { PipelineWithSteps } from 'deprecated/api/pipelines/types/PipelineWithSteps';

export const postPipeline = async ({
  title = '',
  destinationCountryId,
  caseTypeId,
}: {
  title: string;
  destinationCountryId: number;
  caseTypeId: number;
}): Promise<PipelineWithSteps> => {
  const dataToSend = {
    pipeline_type: 'case_pipeline',
    case_type_id: caseTypeId,
    title,
    destination_country_id: destinationCountryId,
  };

  try {
    const { data } = await axios.post<PipelineWithSteps>('/admin/pipelines', dataToSend);
    return data;
  } catch (e) {
    throw new Error(
      `Something is wrong with our services creating the Piepeline.
      Hold on there or send us an email`,
    );
  }
};

export const putPipeline = async ({
  id,
  title = '',
}: {
  id: number;
  title: string;
}): Promise<AxiosResponse<PipelineWithSteps>> => {
  const dataToSend = { title };

  try {
    const response = await axios.put<PipelineWithSteps>(`/admin/pipelines/${id}`, dataToSend);
    return response;
  } catch (e) {
    throw new Error(
      `Something is wrong with our services updating the pipeline.
      Hold on there or send us an email`,
    );
  }
};
