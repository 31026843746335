import { Box, Heading } from '@localyze-pluto/components';
import React from 'react';
import { useReport } from 'modules/reporting/api/useReport';
import { CaseMetrics } from 'modules/reporting/api/types/CaseMetrics';
import { Bar } from 'react-chartjs-2';
import {
  getChartConfig,
  getGroupedChartOptions,
} from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getChartConfig';
import { countWithPercentageFormatter } from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/countWithPercentageFormatter';
import { getCasesByState } from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getCasesByState';
import { getGroupedChartConfig } from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getGroupedChartConfig';
import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon';
import { StatsHeader } from './StatsHeader';
import { AllItemsLink } from '../AllItemsLink';
import { PieChart } from 'modules/reporting/components/PieChart';
import { OverviewCard } from '../OverviewCard/OverviewCard';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';

export const CaseOverview = (): React.JSX.Element => {
  const { isAdmin, isHrAdmin, user_role, company_id, id } = useCurrentUser();
  const userIdKey = isAdmin ? 'case_manager_id' : 'hr_manager_id';
  const routes = useRoutes();

  const {
    data: report,
    isPending,
    isSuccess,
  } = useReport<CaseMetrics>('case_metrics', { [userIdKey]: id });

  const casesByState = isSuccess ? getCasesByState(report) : [];

  return (
    <OverviewCard
      emptyStateIcon="hourglass"
      emptyStateText="There are currently no cases assigned to you."
      headerRightItem={
        isHrAdmin &&
        !isAdmin && (
          <AllItemsLink
            label="See full reporting"
            onClick={() => {
              trackEvent('cases dashboard: click on see full reporting', {
                user_role,
                company_id,
                hr_dashboard_section: 'case overview',
              });
            }}
            path={routes.reporting.url}
          />
        )
      }
      iconCategory="info"
      title="Case overview"
    >
      {isPending && (
        <Box.div display="flex" justifyContent="center" py="d9">
          <LoadingIcon size="sizeIcon50" />
        </Box.div>
      )}
      {report && report.results.totalCases !== 0 && (
        <Box.div display="grid" gridTemplateRows="auto 1fr">
          <StatsHeader results={report.results} />
          <Box.div display="grid" gap="d4" gridTemplateColumns="36% auto">
            <Box.div
              alignItems="center"
              backgroundColor="bgSecondary"
              borderRadius="borderRadius20"
              display="grid"
              padding="p6"
            >
              <Heading as="h4" size="title-group" style={{ color: '#000' }}>
                Cases by state
              </Heading>
              <PieChart
                data={getChartConfig(casesByState, false)}
                formatter={countWithPercentageFormatter}
                items={casesByState}
                legendPosition="bottom"
                padding={30}
              />
            </Box.div>
            <Box.div
              backgroundColor="bgSecondary"
              borderRadius="borderRadius20"
              overflow="hidden"
              padding="p6"
            >
              <Heading as="h4" size="title-group" style={{ color: '#000' }}>
                Cases by case type
              </Heading>
              <Bar
                data={getGroupedChartConfig(report.results.casesByCaseTypeAndDestinationCountry)}
                options={getGroupedChartOptions()}
              />
            </Box.div>
          </Box.div>
        </Box.div>
      )}
    </OverviewCard>
  );
};
