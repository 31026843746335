import React from 'react';
import { commonLinkClasses } from './styles';
import { useModalStore } from '@localyze-pluto/components';
import { NAVBAR } from './constants';
import { InvoicesModal } from 'modules/invoices/components/InvoicesModal';
import { trackEvent } from 'deprecated/utils/helper/segment';

export const InvoicesNavItem = (): React.JSX.Element => {
  const modalState = useModalStore();
  const isModalOpen = modalState.useState('open');

  return (
    <>
      <button
        className={`${commonLinkClasses} tw-border-0 tw-p-0 tw-bg-transparent`}
        onClick={() => {
          modalState.show();
          trackEvent('sidebar: click to open Invoices modal');
        }}
      >
        {NAVBAR.INVOICES}
      </button>
      {isModalOpen && <InvoicesModal modalState={modalState} />}
    </>
  );
};
