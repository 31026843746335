import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { User } from 'deprecated/api/user/types/User';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { Case } from 'modules/cases/types/Case';
import { useCompanyUsers } from 'modules/company/api/useCompanyUsers/useCompanyUsers';
import { mapUsersToSelectOptions } from 'modules/users/utils/mapUsersToSelectOptions';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type EditHRManagerFormTypes = {
  hrManagerId: string;
};

const validationSchema: Yup.ObjectSchema<EditHRManagerFormTypes> = Yup.object({
  hrManagerId: Yup.string().required('This field is required'),
});

export const EditHRManagerModal = ({
  modal,
  kase,
  hrManager,
}: {
  modal: DialogStore;
  kase: Case;
  hrManager?: User;
}): React.JSX.Element => {
  const toast = useToast();
  const { data: hrUsers, isSuccess: isSuccessHRUsers } = useCompanyUsers({
    params: { companyId: kase.company_id },
    enabled: true,
  });
  const hrManagersSelectOptions = mapUsersToSelectOptions(hrUsers);

  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('HR manager updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('HR manager could not be updated', 'error'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: { hrManagerId: hrManager?.id.toString() || '' },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditHRManagerFormTypes> = (values) =>
    updateCase({ caseId: kase.id, payload: { hr_manager_id: Number(values.hrManagerId) } });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit HR Manager</ModalHeading>
      </ModalHeader>

      {!isSuccessHRUsers ? (
        <ModalBody>
          <ContainedLoadingState />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <Box.form id="edit-hr-manager-form" onSubmit={handleSubmit(onSubmit)}>
              <ControlledFormSelect
                control={control}
                id="hrManagerId"
                items={hrManagersSelectOptions}
                label="HR Manager"
                name="hrManagerId"
                placeholder="Select an HR manager"
                required
              />
            </Box.form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={modal.hide} type="button" variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={isPendingUpdateCase}
              form="edit-hr-manager-form"
              loading={isPendingUpdateCase}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
