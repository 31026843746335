import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { TaskTemplate } from 'modules/caseDetails/components/CaseActivityCard/TemplatesDropdown/types/TaskTemplate';

export const useTaskTemplates = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}): UseQueryResult<TaskTemplate[]> => {
  const contentfulAPI = new ContentfulAPI();

  return useQuery({
    queryKey: ['task-templates'],
    queryFn: () => contentfulAPI.fetchTaskTemplates(),
    enabled,
  });
};
