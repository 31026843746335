import { Currency } from 'types/currency';

/**
 * Formats a price with the given currency symbol (i.e. 100 to $100)
 * @param price - the number to format
 * @param currency - currency type
 * @param props - props
 * @param props.showCents - whether or not to display the cents or only the whole currency
 * @returns formatted price string
 */
export const formatPrice = (
  price: number,
  currency: Currency,
  {
    showCents,
  }: {
    showCents?: boolean;
  } = {},
): string =>
  price.toLocaleString(undefined, {
    style: 'currency',
    currency,
    useGrouping: false,

    minimumFractionDigits: showCents ? 2 : 0,
  });

/**
 * Formats a price in cents with the given currency symbol. (i.e. 10000 to $100)
 * @param priceCents - the number of cents to format
 * @param currency - currency type
 * @param props - props
 * @param props.showCents - whether or not to display the cents or only the whole currency
 * @returns formatted price string
 */
export const formatPriceCents = (
  priceCents: number,
  currency: Currency,
  {
    showCents,
  }: {
    showCents?: boolean;
  } = {},
): string => formatPrice(priceCents / 100, currency, { showCents });
