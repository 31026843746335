import {
  Box,
  BoxProps,
  ControlledCheckbox,
  ControlledFormTextArea,
  Heading,
  Icon,
  Paragraph,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export type RequiredActionId =
  | 'a1_exemption_certificate'
  | 'certificate_of_cover'
  | 'consultation_text'
  | 'consultation'
  | 'documentation'
  | 'employers_certificate'
  | 'express_processing'
  | 'other_text'
  | 'other'
  | 'request_a1_certificate'
  | 'request_registration'
  | 'visa';

const requiredActionsHash: Record<RequiredActionId, number> = {
  a1_exemption_certificate: 1,
  certificate_of_cover: 1,
  consultation: 1,
  consultation_text: 1,
  documentation: 1,
  employers_certificate: 1,
  express_processing: 1,
  other: 1,
  other_text: 1,
  request_a1_certificate: 1,
  request_registration: 1,
  visa: 1,
};

// This will necessarily contain every item from RequiredActionId type
export const requiredActionsKeys = Object.keys(requiredActionsHash) as RequiredActionId[];

export const ITEMS_TOOLTIP_TEXT: Record<RequiredActionId, string> = {
  request_a1_certificate:
    'You will receive the A1 certificate and you will receive it via email when it’s ready.',
  a1_exemption_certificate:
    // eslint-disable-next-line max-len
    'You will receive the A1 exception certificate and you will receive it via email when it’s ready.',
  certificate_of_cover: 'You will receive the certificate of cover via email when it’s ready.',
  express_processing: 'This option speeds up the process so it can be completed in less time.',
  visa: 'Your Localyze case manager will reach out to the talent to kick off the visa application.',
  documentation: 'The relevant documentation will be sent to you and your talent via email.',
  request_registration: 'You will receive the registration via email when it’s ready.',
  employers_certificate:
    // eslint-disable-next-line max-len
    'The employer certificate will be sent to you via email. Please sign it and share it with your talent as it will be needed for their trip.',
  consultation:
    // eslint-disable-next-line max-len
    'Our partner, Rhome, will reach out via email to gather more information so they can provide additional guidance.',
  consultation_text: '',
  other: '',
  other_text: '',
};

export const ITEMS_TEXT: Record<RequiredActionId, string> = {
  request_a1_certificate: 'Request A1 certificate',
  a1_exemption_certificate: 'A1 Exemption Certificate',
  certificate_of_cover: 'Certificate of Cover',
  express_processing: 'Express processing',
  visa: 'Visa',
  documentation: 'Documentation',
  employers_certificate: "Employer's certificate",
  request_registration: 'Request registration',
  consultation: 'Consultation',
  consultation_text: '',
  other: 'Other',
  other_text: '',
};

export const CHECK_ITEMS: RequiredActionId[] = [
  'request_a1_certificate',
  'a1_exemption_certificate',
  'certificate_of_cover',
  'express_processing',
  'visa',
  'documentation',
  'employers_certificate',
  'request_registration',
];

const CHECK_WITH_TEXT_ITEMS: RequiredActionId[] = ['consultation', 'other'];

const TooltipWrapper = ({ text, tooltipText }: { text: string; tooltipText: string }) => {
  const tooltip = useTooltipStore({
    showTimeout: 0,
    placement: 'top',
  });

  return (
    <>
      <TooltipAnchor render={<Box.div />} store={tooltip}>
        <Icon
          color="contentSecondary"
          decorative={false}
          display="inline-block"
          h={20}
          icon="info"
          size="sizeIcon30"
          title={`${text} information`}
        />
      </TooltipAnchor>
      <Tooltip store={tooltip}>{tooltipText}</Tooltip>
    </>
  );
};

export const RequiredActions = ({
  isDisabled,
  ...props
}: BoxProps & { isDisabled: boolean }): React.JSX.Element => {
  const { control } = useFormContext();

  return (
    <Box.div {...props}>
      <SectionCard>
        <SectionCard.Header padding={'d4 d6' as BoxProps['padding']}>
          <Box.div
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="d0"
          >
            <Heading as="h3" color="contentPrimary" marginBottom="d0" size="title-subsection">
              Add required actions
            </Heading>
          </Box.div>
        </SectionCard.Header>
        <SectionCard.Content>
          <Box.div w="100%">
            <Paragraph
              color="contentPrimary"
              fontSize="fontSize30"
              fontWeight="fontWeightRegular"
              lineHeight="lh4"
              marginBottom="d6"
              textAlign="left"
            >
              Check all of the required actions that apply based on the risk assessment results.
            </Paragraph>
            <Box.div display="grid" gridTemplateColumns="1fr 2fr">
              <Box.ul marginBottom="d0" padding="d0">
                {CHECK_ITEMS.map((item) => (
                  <Box.li
                    display="grid"
                    gap="d2"
                    gridTemplateColumns="max-content 20px"
                    h={28}
                    key={item}
                  >
                    <ControlledCheckbox
                      control={control}
                      disabled={isDisabled}
                      id={item}
                      name={item}
                    >
                      {ITEMS_TEXT[item]}
                    </ControlledCheckbox>
                    <TooltipWrapper
                      text={ITEMS_TEXT[item]}
                      tooltipText={ITEMS_TOOLTIP_TEXT[item]}
                    />
                  </Box.li>
                ))}
              </Box.ul>
              <Box.ul display="grid" gap="d4" listStyleType="none" marginBottom="d0" padding="d0">
                {CHECK_WITH_TEXT_ITEMS.map((item) => (
                  <Box.li key={item}>
                    <Box.div display="grid" gap="d2" gridTemplateColumns="max-content min-content">
                      <ControlledCheckbox
                        control={control}
                        disabled={isDisabled}
                        id={item}
                        name={item}
                      >
                        {ITEMS_TEXT[item]} (please specify)
                      </ControlledCheckbox>
                      {ITEMS_TOOLTIP_TEXT[item] && (
                        <TooltipWrapper
                          text={ITEMS_TEXT[item]}
                          tooltipText={ITEMS_TOOLTIP_TEXT[item]}
                        />
                      )}
                    </Box.div>
                    <ControlledFormTextArea
                      control={control}
                      disabled={isDisabled}
                      id={`${item}_text`}
                      label={''}
                      name={`${item}_text`}
                    />
                  </Box.li>
                ))}
              </Box.ul>
            </Box.div>
          </Box.div>
        </SectionCard.Content>
      </SectionCard>
    </Box.div>
  );
};
