import { Box, Button } from '@localyze-pluto/components';
import React, { useState } from 'react';

import { DefaultEditor } from 'react-simple-wysiwyg';
import { P } from 'deprecated/components/Fonts';
import PropTypes from 'prop-types';

export default function StepDescription({
  component,
  handleSave,
  buttons,
  index,
  onMoveUp,
  onMoveDown,
}) {
  const { id, editing, content = '', componentType } = component;

  const [html, setHtml] = useState(content);

  const handleSubmit = (event) => {
    event.preventDefault();
    const componentData = { id, componentType, content: html };
    handleSave(componentData, index);
  };

  const onChange = (e) => setHtml(e.target.value);

  return (
    <Box.div display="flex" gap="d4" data-testid="step-component">
      <Box.div display="flex">
        <Box.div>
          <Button
            iconOnly
            variant="ghost"
            aria-label="Move up"
            leadingIcon="ArrowSmallUpIcon"
            disabled={editing}
            onClick={() => onMoveUp(id)}
          />
          <Button
            iconOnly
            variant="ghost"
            aria-label="Move down"
            leadingIcon="ArrowSmallDownIcon"
            onClick={() => onMoveDown(id)}
            disabled={editing}
          />
        </Box.div>
      </Box.div>
      <Box.div flexGrow={1}>
        <P style={{ fontWeight: 'bold', marginBottom: '10px' }}>Description</P>
        <Box.form onSubmit={handleSubmit} display="flex" flexDirection="column" gap="m2">
          <DefaultEditor value={html} onChange={onChange} disabled={!editing} />
          {editing && buttons}
        </Box.form>
      </Box.div>
    </Box.div>
  );
}

StepDescription.defaultProps = {
  index: 0,
  buttons: null,
  component: { id: null, editing: false, content: '', componentType: '' },
  handleSave: () => {},
};

StepDescription.propTypes = {
  index: PropTypes.string,
  buttons: PropTypes.elementType,
  component: PropTypes.shape({
    id: PropTypes.string,
    editing: PropTypes.bool,
    content: PropTypes.string,
    componentType: PropTypes.string,
  }),
  handleSave: PropTypes.func,
};
