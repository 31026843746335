import { Box } from '@localyze-pluto/components';

import React from 'react';
import { MyTasks } from 'modules/caseOverview/components/MyTasks/MyTasks';
import { CasesAtRisk } from 'modules/caseOverview/components/CasesAtRisk/CasesAtRisk';
import { WaitingCases } from 'modules/caseOverview/components/WaitingCases/WaitingCases';
import { ReferralCampaign } from 'deprecated/pages/viewsNew/ChewpacaLayout/Home/components/ReferralCampaign';
import { CaseOverview } from 'modules/caseOverview/components/CaseOverview/CaseOverview';
import { useCurrentUser } from 'config/CurrentUserContext';

export const CaseOverviewDashboard = (): React.JSX.Element => {
  const { isHrViewer, isAdmin } = useCurrentUser();

  return (
    <Box.div paddingBottom="p8">
      {!isAdmin && <ReferralCampaign isDismissible marginBottom="m4" />}

      <Box.div
        alignItems="stretch"
        display="grid"
        gap="d4"
        gridTemplateColumns={2}
        marginBottom="m4"
      >
        {isHrViewer || isAdmin ? null : <MyTasks />}
        <CasesAtRisk />
        <WaitingCases />
      </Box.div>
      {isHrViewer ? null : <CaseOverview />}
    </Box.div>
  );
};
