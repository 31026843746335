import { Avatar, Box } from '@localyze-pluto/components';
import React from 'react';
import { CaseOverviewCell } from '../CaseOverviewCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

export type AvatarProps = {
  caseOverview: CaseOverview;
  text: Maybe<string>;
  isAdmin?: boolean;
};

export const AvatarAndNameCell = ({
  text,
  caseOverview,
  isAdmin,
}: AvatarProps): React.JSX.Element => {
  return (
    <CaseOverviewCell caseOverview={caseOverview} isAdmin={isAdmin}>
      <Box.div alignItems="center" display="flex" flexDirection="row">
        <Box.div paddingRight="d1_5">
          <Avatar
            name={caseOverview.talent_name}
            size="medium"
            src={caseOverview.talent_avatar || undefined}
          />
        </Box.div>
        <Box.div data-testid="talent-name">{text}</Box.div>
      </Box.div>
    </CaseOverviewCell>
  );
};
