import { Anchor, Box } from '@localyze-pluto/components';
import { getProviderPageLink } from 'modules/cases/pages/createCase/utils/getProviderPageLink';
import { StackOneCompanyIntegration } from 'modules/integrations/types/HRISCompanyIntegration';
import React from 'react';

export const HelpText = ({
  isError,
  activeIntegration,
}: {
  isError: boolean;
  activeIntegration: StackOneCompanyIntegration;
}): React.JSX.Element => {
  return isError ? (
    <Box.span color="colorTextError">
      We couldn&apos;t find a talent with the Employee ID you entered. Please double-check and try
      again.
    </Box.span>
  ) : (
    <>
      Not sure where to find the Employee ID?{' '}
      <Anchor href={getProviderPageLink(activeIntegration.provider_data.stackone_provider_name)}>
        Learn more
      </Anchor>
    </>
  );
};
