import { Avatar, Box, Heading, Icon } from '@localyze-pluto/components';
import { BackgroundHeader } from 'components/BackgroundHeader/BackgroundHeader';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import React from 'react';
import { useTalent } from '../TalentContext/hooks/useTalent';
import { useCompany } from 'modules/company/api/useCompany/useCompany';

export const TalentProfileHeader = (): React.JSX.Element => {
  const talent = useTalent();
  const { data: company } = useCompany({ companyId: talent.company_id, throwOnError: false });
  const avatarSrc = talent.avatar_url || '';

  const talentFullName = fullName(talent);

  return (
    <BackgroundHeader backgroundImage="/images/header_vector.svg">
      <Box.div alignItems="center" display="flex" gap="d6">
        <Avatar name={talentFullName} size="xlarge" src={avatarSrc} />
        <Box.div alignItems="flex-start" display="flex" flexDirection="column">
          <Heading color="colorTextInverse" marginBottom="m0" size="title-screen">
            {talentFullName}
          </Heading>
          <Box.div
            alignItems="center"
            color="colorTextInverse"
            display="flex"
            fontSize="fontSize30"
            fontWeight="fontWeightMedium"
            gap="d2"
            lineHeight="lineHeight40"
          >
            {company && (
              <>
                <Icon decorative={false} icon="building" size="sizeIcon20" title="office" />
                {company.name}
              </>
            )}
          </Box.div>
        </Box.div>
      </Box.div>
    </BackgroundHeader>
  );
};
