import isEmpty from 'lodash/isEmpty';
import { CaseType } from '../types/CaseType';
import { CaseTypeCategory } from '../types/CaseTypeCategory';

export type MappedCaseTypeAndCategories = {
  caseTypeCategory: CaseTypeCategory;
  caseTypes: CaseType[];
};

export const mapCaseTypeAndCategories = (
  categories: CaseTypeCategory[],
  caseTypes: CaseType[],
): MappedCaseTypeAndCategories[] =>
  categories.reduce((acc, caseTypeCategory) => {
    const caseTypesForCategory = caseTypes.filter(
      (caseType) => caseType.category === caseTypeCategory.category,
    );

    if (isEmpty(caseTypesForCategory)) {
      return acc;
    }

    return [
      ...acc,
      {
        caseTypeCategory,
        caseTypes: caseTypesForCategory,
      },
    ];
  }, [] as MappedCaseTypeAndCategories[]);
