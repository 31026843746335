export const isBannerVisible = ({
  isFeatureFlagActive,
  isActive,
  lastBannerDate,
  updatedAt,
}: {
  isFeatureFlagActive: boolean;
  isActive: boolean | undefined;
  lastBannerDate: string | null;
  updatedAt: string | undefined;
}): boolean => {
  const isBannerDismissed = lastBannerDate && lastBannerDate === updatedAt;

  return isFeatureFlagActive && Boolean(isActive) && !Boolean(isBannerDismissed);
};
