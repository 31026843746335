import * as Yup from 'yup';
import {
  Box,
  Button,
  ControlledFormInput,
  ControlledFormSelect,
  Heading,
} from '@localyze-pluto/components';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { useAdminCompanyOptions } from 'modules/company/api/useAdminCompanyOptions/useAdminCompanyOptions';
import { CompanyState } from 'modules/company/types/CompanyState';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';
import { Step1Payload } from 'modules/cases/pages/createCase/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Validation } from 'constants/formValidations';
import { getEmailAvailability } from 'deprecated/api/email/getEmailAvailability';
import { HRISIntegration } from '../../../HRISIntegration';
import { HRISTalent } from 'modules/integrations/types/HRISTalent';
import { step1InitialValues } from 'modules/cases/pages/createCase/constants/initialValues';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { MCU_CASE_CREATION } from 'modules/featureFlags/types/flags';

const validationSchema = Yup.object({
  firstName: Validation.requiredString,
  lastName: Validation.requiredString,
  email: Validation.requiredString,
  companyId: Validation.dropDown,
});

export const AddNewTalent = ({
  handleBackClick,
  handleNextClick,
  setHRISData,
  values,
  isDemoModeActive,
}: {
  handleBackClick: () => void;
  handleNextClick: (payload: Step1Payload) => void;
  setHRISData: (talentData: HRISTalent) => void;
  values: Step1Payload;
  isDemoModeActive: boolean;
}): React.JSX.Element => {
  const mcuCaseCreationIsActive = useIsFeatureFlagActive(MCU_CASE_CREATION);

  const { isAdmin } = useCurrentUser();

  const { handleSubmit, control, getValues, reset, setError } = useForm({
    defaultValues: values,
    mode: 'onTouched',
    resolver: isDemoModeActive ? undefined : yupResolver(validationSchema),
  });

  const companiesQuery = useAdminCompanyOptions({ companyState: CompanyState.Active });

  // Watch for changes in values prop (HRIS integration) and update form values
  useEffect(() => {
    reset({
      ...getValues(),
      ...values,
    });
  }, [getValues, reset, values]);

  const onSubmit = async (data: Step1Payload) => {
    const emailIsAvailable = isDemoModeActive || (await getEmailAvailability(data.email));

    if (emailIsAvailable) {
      handleNextClick({ ...step1InitialValues, ...data });
    } else {
      setError(
        'email',
        {
          type: 'manual',
          message: 'Email already taken',
        },
        {
          shouldFocus: true,
        },
      );
    }
  };

  const isPending = isAdmin && companiesQuery.isPending;

  return (
    <>
      {isPending ? (
        <Box.div alignItems="center" display="flex" flexDirection="column">
          <ContainedLoadingState />
        </Box.div>
      ) : (
        <Box.div display="flex" flexDirection="column" gap="d5">
          <Heading as="h1" color="contentPrimary" marginBottom="d0" size="heading50">
            Add the talent&apos;s information
          </Heading>
          {!isAdmin && <HRISIntegration employeeId={values.employeeId} setHRISData={setHRISData} />}
          <Box.form
            display="flex"
            flexDirection="column"
            gap="d5"
            h="100%"
            justifyContent="space-between"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box.div display="flex" flexDirection="column" gap="d5">
              {isAdmin && companiesQuery.isSuccess && (
                <ControlledFormSelect
                  control={control}
                  id="companyId"
                  items={companiesQuery.data}
                  label="Company"
                  name="companyId"
                  required={!isDemoModeActive}
                />
              )}
              <ControlledFormInput
                control={control}
                id="firstName"
                label="First name"
                name="firstName"
                required={!isDemoModeActive}
                type="text"
              />
              <ControlledFormInput
                control={control}
                id="lastName"
                label="Last name"
                name="lastName"
                required={!isDemoModeActive}
                type="text"
              />
              <ControlledFormInput
                control={control}
                id="email"
                label="Email"
                name="email"
                required={!isDemoModeActive}
                type="email"
              />
            </Box.div>
            <Box.div alignItems="center" display="flex" gap="d3" justifyContent="flex-end">
              {mcuCaseCreationIsActive && (
                <Button
                  leadingIcon="chevron-left"
                  onClick={handleBackClick}
                  type="button"
                  variant="outline"
                >
                  Back
                </Button>
              )}
              <Button trailingIcon="chevron-right" type="submit" variant="primary">
                Next
              </Button>
            </Box.div>
          </Box.form>
        </Box.div>
      )}
    </>
  );
};
