import { MessageType } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/MessageType';
import React, { HTMLAttributes } from 'react';
import { format } from 'date-fns/format';
import { Box, BoxProps, Button, Icon, Menu, MenuItemProps, Text } from '@localyze-pluto/components';
import { getMessageItemBackgroundColor } from './getMessageItemBackgroundColor';
import { getMessageItemBorderStyles } from './getMessageItemBorderStyles';

export type MessageItemProps = BoxProps &
  HTMLAttributes<HTMLElement> & {
    date: Date;
    message: string;
    /**
     * @description by this prop we allow developers to use this component as list item by default
     * as well as use as div in cases if dev wants to use only one instance of this component
     * @default 'li'
     */
    containerType?: 'div' | 'li';
    messageId: number;
    messageFrom: string;
    onDelete?: (id: number) => void;
    menuItems?: MenuItemProps[];
    type?: MessageType;
    isPinned?: boolean;
  };

export const MessageItem = ({
  messageId,
  date,
  message,
  menuItems,
  messageFrom,
  onDelete,
  type = 'hr_message',
  isPinned = false,
  ...rest
}: MessageItemProps): React.JSX.Element => {
  return (
    <Box.li
      borderRadius="borderRadius10"
      display="flex"
      gap="d4"
      style={{
        wordBreak: 'break-word',
      }}
      {...getMessageItemBackgroundColor(type)}
      {...getMessageItemBorderStyles(type, isPinned)}
      {...rest}
    >
      <Box.div
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap="d1"
        justifyContent="space-between"
        paddingBottom="p2"
        paddingLeft="p2"
        paddingTop="p2"
      >
        <Box.div
          color="colorText"
          display="flex"
          fontSize="fontSize10"
          gap="d1"
          style={{
            lineHeight: '0.75rem',
          }}
        >
          {isPinned && <Icon decorative icon="pin" size="sizeIcon20" />}
          <Text.span>
            {format(new Date(date), "dd. MMMM yyyy 'at' HH:mm")} by {messageFrom}
          </Text.span>
        </Box.div>

        <Text.span color="colorTextStrongest" fontSize="fontSize20" lineHeight="lineHeight30">
          {message}
        </Text.span>
      </Box.div>
      {/* The z-index is needed to prevent the menu
        from being overlapped by the PostNewItem component. */}
      {menuItems && <Menu items={menuItems} menuZIndex="10" />}
      {onDelete && (
        <Box.div marginRight="m2" marginTop="m2">
          <Button
            aria-label="Delete message"
            data-testid="feed-message-delete-button"
            iconOnly
            leadingIcon="x"
            margin="d0"
            onClick={() => onDelete(messageId)}
            padding="d0"
            size="small"
            variant="ghost"
          />
        </Box.div>
      )}
    </Box.li>
  );
};
