import { Box, Label, Select, useToast } from '@localyze-pluto/components';
import React, { useState } from 'react';
import { AxiosError, isAxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { PostNewItem } from '../PostNewItem/PostNewItem';
import { useStatusUpdateTemplates } from '../TemplatesDropdown/useStatusUpdateTemplates';
import { Template } from '../TemplatesDropdown/TemplatesDropdown';
import { StatusUpdateType } from 'modules/statusUpdates/types/StatusUpdateType';
import { WaitingUntilDatePicker } from './WaitingUntilDatePicker';
import { useCreateStatusUpdate } from 'modules/statusUpdates/api/useCreateStatusUpdate/useCreateStatusUpdate';
import { trackEvent } from 'deprecated/utils/helper/segment';

const TYPE_OF_UPDATE_OPTIONS = [
  { label: 'Milestone', value: 'Milestone' },
  { label: 'Blocker', value: 'Blocker' },
];

export type PostNewUpdateType = 'Blocker' | 'Milestone';

export const PostNewUpdate = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const caseId = Number(id);

  const toast = useToast();

  const [updateType, setUpdateType] = useState<PostNewUpdateType>();
  const [templateType, setTemplateType] = useState<StatusUpdateType | undefined>('other');
  const [waitingUntil, setWaitingUntil] = useState<string>('');

  const { data: statusUpdateTemplates, isLoading } = useStatusUpdateTemplates({
    type: updateType,
    enabled: updateType !== undefined,
  });

  const isMilestone = updateType === 'Milestone';
  const isBlocker = updateType === 'Blocker';

  const { mutate: createStatusUpdate } = useCreateStatusUpdate({
    onSuccess: ({ data: { status_type: statusType } }) => {
      toast(`New ${updateType} was created successfully.`, 'success');
      setWaitingUntil('');
      setUpdateType(undefined);

      if (isMilestone) {
        trackEvent('case profile: post a milestone', { macroType: statusType });
      }
    },
    onError: (error: AxiosError) => {
      const errorMessage = isAxiosError(error)
        ? (error as AxiosErrorWithMessage).response?.data.message
        : '';
      toast(errorMessage || `Error creating new ${updateType}.`, 'error');
    },
  });

  const onPostUpdate = (item: string) => {
    createStatusUpdate({
      subjectId: caseId,
      subjectType: 'Case',
      message: item,
      statusType: (isBlocker ? 'blocker' : templateType) as StatusUpdateType,
      waitingUntil: waitingUntil ? `${waitingUntil}T12:00:00.000Z` : '',
    });
  };

  const onChangeTemplate = (template: Template) => {
    if ('statusType' in template) setTemplateType(template.statusType[0]);
  };

  return (
    <Box.div
      borderLeftColor="borderSecondary"
      borderLeftStyle="borderStyleSolid"
      borderLeftWidth="borderWidth10"
      display="flex"
      flexDirection="column"
      gap="d2"
      p="d6"
    >
      <Box.div columnGap="d3" display="grid" gridTemplateColumns="1fr 120px">
        <WaitingUntilDatePicker
          caseId={caseId}
          setWaitingUntil={setWaitingUntil}
          waitingUntil={waitingUntil}
        />
        <Box.div>
          <Label htmlFor="type-of-update">Type of update</Label>
          <Select
            id="type-of-update"
            items={TYPE_OF_UPDATE_OPTIONS}
            setValue={(value: PostNewUpdateType) => {
              setUpdateType(value);
            }}
            value={updateType || ''}
          />
        </Box.div>
      </Box.div>
      <PostNewItem
        disabled={!updateType}
        isLoading={isLoading}
        onChangeTemplate={onChangeTemplate}
        onPostNewItem={onPostUpdate}
        placeholder="Post a new update..."
        showTemplatesDropdown
        templates={statusUpdateTemplates}
        textAreaAriaLabel="Post a new update"
        tooltipMessage="Select type of update first"
        type={updateType}
      />
    </Box.div>
  );
};
