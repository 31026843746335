import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ControlledFormSelect,
  Paragraph,
} from '@localyze-pluto/components';
import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogStore } from '@ariakit/react';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';
import { useForm } from 'react-hook-form';
import { Validation } from 'constants/formValidations';
import { FileCategory } from 'modules/integrations/hooks/useHRISFileCategories/useHRISFileCategories';
import { StackOneProvider } from 'modules/integrations/types/HRISCompanyIntegration';
import { upperFirst } from 'lodash';

type Props = {
  fileCategories?: FileCategory[];
  store: DialogStore;
  providerName?: StackOneProvider;
  onClickSend: (id: string, label: string) => void;
  onClose: () => void;
  isLoading: boolean;
};

const validationSchema = Yup.object({
  category: Validation.dropDown.required(),
});

export const IntegrationFileCategoriesModal = ({
  store,
  fileCategories = [],
  providerName,
  onClickSend,
  onClose,
  isLoading,
}: Props): React.JSX.Element => {
  const { control, trigger, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      category: '',
    },
    mode: 'onChange',
  });

  const category = watch('category');

  return (
    <Modal data-testid="integration-files-modal" onClose={onClose} store={store}>
      <ModalHeader>
        <ModalHeading>Complete your integration setup</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form onSubmit={(ev) => ev.preventDefault()}>
          <Paragraph>
            Select a file category to set up your integration. The uploaded files will be available
            in the selected category on the individual Employee Profile.
          </Paragraph>
          {providerName && (
            <Paragraph>
              The uploaded files will be available in the selected category on the individual
              Employee in {upperFirst(providerName)}.
            </Paragraph>
          )}
          <ControlledFormSelect
            control={control}
            id="category"
            items={mapToSelectOptions({
              records: fileCategories,
              valueProp: 'id',
              labelProp: 'label',
            })}
            label="Please select a file category"
            name="category"
            placeholder="Select one"
            required
          />
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!category}
          loading={isLoading}
          onClick={async () => {
            const item = fileCategories.find((item) => item.id === category);

            await trigger();

            onClickSend(String(item?.id), String(item?.label));
          }}
          variant="primary"
        >
          Activate integration
        </Button>
      </ModalFooter>
    </Modal>
  );
};
