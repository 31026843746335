import { pdfFromHTML } from './zav';
import axios, { AxiosResponse } from 'axios';

export const PDF_TEMPLATE_IDS = {
  ZAV: 1,
  EU_BLUE_CARD: 2,
};

type GeneratePdfResponse = {
  download_url: string;
};

export const generatePdfFromTemplate = async (
  caseId: number,
  pdfTemplateId: number,
): Promise<AxiosResponse<GeneratePdfResponse>> =>
  axios.post<GeneratePdfResponse>(`/filled_pdf_templates`, {
    pdf_template: { template_id: pdfTemplateId },
    case_id: caseId,
  });

export { pdfFromHTML };
