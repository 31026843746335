import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import { queryClient } from 'config/queryClient';
import { deleteTalent } from './deleteTalent';

export const useDeleteTalent = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}): UseMutationResult<unknown, AxiosError, number> =>
  useMutation({
    mutationFn: deleteTalent,
    onSuccess: async (_, talentId: number) => {
      await queryClient.removeQueries({
        queryKey: ['talent', talentId],
      });

      onSuccess?.();
    },
    onError: (error) => {
      onError?.(error);
    },
  });
