import React from 'react';

import * as Yup from 'yup';
import {
  Box,
  Button,
  ControlledCheckbox,
  ControlledFormSelect,
  Heading,
  Icon,
  Paragraph,
} from '@localyze-pluto/components';
import { CaseTypeOptions } from 'modules/cases/components/CaseTypeOptions/CaseTypeOptions';
import { ServiceLevelOptions } from 'modules/cases/components/ServiceLevelOptions/ServiceLevelOptions';
import { Step2Payload } from 'modules/cases/pages/createCase/types/Payload';
import { Validation } from 'constants/formValidations';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { useCaseTypes } from 'modules/cases/api/useCaseTypes/useCaseTypes';
import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';
import { NoCaseTypes } from '../Step1/NoCaseTypes';
import { useCurrentUser } from 'config/CurrentUserContext';
import { ResponsibleForTheCase } from './components/ResponsibleForTheCase';

const FAMILY_ONLY_CASE_TYPE_ID = 5;

const validationSchema = Yup.object({
  caseTypeId: Validation.requiredString,
  serviceLevelId: Validation.requiredString,
  includesAccompanyingFamilyMembers: Yup.boolean().defined(),
  citizenshipCountry: Validation.dropDown,
  countryOfResidence: Validation.dropDown,
  destinationCountry: Validation.dropDown,
  hrManagerId: Validation.dropDown,
  followerIds: Yup.array().defined(),
});

type Props = {
  isDemoModeActive: boolean;
  handleBackClick(payload: Step2Payload): void;
  handleNextClick(payload: Step2Payload): void;
  values: Step2Payload;
  companyId: string;
};

export const Step2 = ({
  isDemoModeActive,
  handleBackClick,
  handleNextClick,
  values,
  companyId,
}: Props): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm<Step2Payload>({
    defaultValues: values,
    resolver: isDemoModeActive ? undefined : yupResolver(validationSchema),
  });

  const destinationCountry = watch('destinationCountry');

  const destinationCountryId = Number(destinationCountry);

  const {
    data: caseTypesByDestinationCountry,
    isLoading: isLoadingCaseTypes,
    isSuccess: isSuccessCaseTypes,
  } = useCaseTypes({
    destinationCountryId,
    enabled: !!destinationCountryId,
  });

  const selectedServiceLevelId = watch('serviceLevelId');

  const { data: countries } = useCountries({});

  const { data: countryOptions, isSuccess: isSuccessCountryOptions } = useCountryOptions();

  const selectedCountry = countries?.find((country) => country.id === Number(destinationCountry));

  const flagUrl = `https://flagcdn.com/24x18/${selectedCountry?.code.toLowerCase()}.png`;

  // Hide family members option when "Family only" case type is selected
  const hideFamilyMembersOption = Number(watch('caseTypeId')) === FAMILY_ONLY_CASE_TYPE_ID;

  const isSupportedCountry = isSuccessCaseTypes && !isEmpty(caseTypesByDestinationCountry);

  return (
    <>
      {!isSuccessCountryOptions ? (
        <Box.div alignItems="center" display="flex" flexDirection="column">
          <ContainedLoadingState />
        </Box.div>
      ) : (
        <Box.form
          display="flex"
          flexDirection="column"
          gap="d5"
          h="100%"
          justifyContent="space-between"
          noValidate
          onSubmit={handleSubmit((data: Step2Payload) => handleNextClick(data))}
        >
          <Box.div display="flex" flexDirection="column" gap="d5">
            <Box.div display="flex" flexDirection="column" gap="d5">
              <Heading as="h3" color="contentPrimary" marginBottom="d0" size="heading50">
                Select countries
              </Heading>
              <ControlledFormSelect
                control={control}
                id="citizenshipCountry"
                items={countryOptions}
                label="Citizenship"
                name="citizenshipCountry"
                required={!isDemoModeActive}
              />
              <ControlledFormSelect
                control={control}
                id="countryOfResidence"
                items={countryOptions}
                label="Residence country"
                name="countryOfResidence"
                required={!isDemoModeActive}
              />
              <ControlledFormSelect
                control={control}
                id="destinationCountry"
                items={countryOptions}
                label="Destination country"
                name="destinationCountry"
                required={!isDemoModeActive}
              />
            </Box.div>

            <Box.div display="flex" flexDirection="column" gap="d5">
              <Heading as="h3" color="contentPrimary" marginBottom="d0" size="heading50">
                Select a case type{' '}
                {selectedCountry && (
                  <>
                    (
                    <Box.img
                      alt={selectedCountry.name}
                      aria-hidden
                      display="inline-block"
                      paddingLeft="p0_5"
                      paddingRight="p0_5"
                      src={flagUrl}
                    />
                    {selectedCountry.name})
                  </>
                )}
              </Heading>
              {!isEmpty(errors.caseTypeId) && (
                <Paragraph color="colorTextError">Select a case type</Paragraph>
              )}
              {!selectedCountry ? (
                <Box.div
                  alignItems="center"
                  color="contentTertiary"
                  display="flex"
                  flexDirection="column"
                  gap="d2"
                  justifyContent="center"
                  padding="d8"
                  textAlign="center"
                >
                  <Icon decorative={true} icon="earth" />
                  <Box.span w="50%">
                    Please select a destination country first to see which case types we offer.
                  </Box.span>
                </Box.div>
              ) : isLoadingCaseTypes ? (
                <ContainedLoadingState />
              ) : isSupportedCountry ? (
                <>
                  <Box.div display="grid" gridTemplateColumns={{ lg: 1 }} rowGap="d3">
                    <CaseTypeOptions caseTypes={caseTypesByDestinationCountry} control={control} />
                  </Box.div>

                  {hideFamilyMembersOption || (
                    <Box.div display="flex" flexDirection="column" gap="d5">
                      <Heading as="h3" color="contentPrimary" marginBottom="d0" size="heading50">
                        Family members
                      </Heading>
                      <ControlledCheckbox
                        control={control}
                        id="includesAccompanyingFamilyMembers"
                        name="includesAccompanyingFamilyMembers"
                      >
                        The company will pay to extend all booked services to the Talent’s family
                      </ControlledCheckbox>
                    </Box.div>
                  )}
                  <Box.div display="flex" flexDirection="column" gap="d5">
                    <Heading as="h3" color="contentPrimary" marginBottom="d0" size="heading50">
                      Select a service level
                    </Heading>
                    <Box.div marginBottom="m3">
                      <ServiceLevelOptions
                        control={control}
                        selectedServiceLevelId={selectedServiceLevelId}
                      />
                    </Box.div>
                  </Box.div>
                  <ResponsibleForTheCase
                    companyId={companyId}
                    control={control}
                    isDemoModeActive={isDemoModeActive}
                    setValue={setValue}
                    watch={watch}
                  />
                </>
              ) : (
                <NoCaseTypes isAdmin={isAdmin} />
              )}
            </Box.div>
          </Box.div>

          <Box.div alignItems="center" display="flex" gap="d3" justifyContent="flex-end">
            <Button
              leadingIcon="chevron-left"
              onClick={() => handleBackClick(getValues())}
              type="button"
              variant="outline"
            >
              Back
            </Button>
            <Button
              disabled={!isSupportedCountry && !isDemoModeActive}
              trailingIcon="chevron-right"
              type="submit"
              variant="primary"
            >
              Next
            </Button>
          </Box.div>
        </Box.form>
      )}
    </>
  );
};
