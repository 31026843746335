import { Heading, P1 } from 'deprecated/components/Fonts';
import React, { useContext, useEffect, useState } from 'react';

import { DocsContainerStore } from 'deprecated/store/root';
import { DocumentsState } from 'deprecated/store/reducers/DocumentsState';
import { FETCH_DOCUMENTS } from 'deprecated/store/actions/DocumentsContainerActions';
import { FormikValues } from 'formik';
import MapStepComponents from './MapStepComponents/MapStepComponents';
import { StepData } from 'deprecated/utils/helper/international/types/StepData';
import { progressStepData } from 'deprecated/utils/helper/international/progressStepData';
import styled from 'styled-components';
import { withDocsProvider } from 'deprecated/pages/viewsNew/common/DocumentsContainer/withDocsProvider';
import { useCaseStep } from 'modules/pipelines/hooks/useCaseStep';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

const ProgressStepWrapper = styled.div`
  margin: auto;
  padding: 20px;
  background-color: var(--color-background-white);
`;

export const ProgressStep = ({
  caseStepId,
  submit,
  buttons = <></>,
  caseId,
}: {
  caseStepId: number;
  submit: (values: FormikValues) => void;
  buttons: React.JSX.Element;
  caseId: number;
}): React.JSX.Element => {
  const [stepData, setStepData] = useState({
    data: {} as StepData,
    requestDone: false,
  });

  const docsContext = useContext<DocumentsState>(DocsContainerStore);

  const { data: step, isPending, isSuccess } = useCaseStep(caseStepId);

  useEffect(() => {
    setStepData((e) => ({
      ...e,
      requestDone: false,
    }));
    if (isPending || !isSuccess) return;

    progressStepData(
      step,
      (data: StepData) => {
        setStepData((sd) => ({
          ...sd,
          data,
          requestDone: true,
        }));
        docsContext.dispatch({
          payload: { docList: data.listDocs },
          type: FETCH_DOCUMENTS,
        });
      },
      caseId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStepId, caseId, isPending, isSuccess, step]);

  return (
    <ProgressStepWrapper>
      {!stepData.requestDone && <ContainedLoadingState />}
      {stepData.requestDone && (
        <>
          <div style={{ marginBottom: '35px' }}>
            <Heading>{stepData.data.title}</Heading>
            <br />
            <P1>{stepData.data.description}</P1>
          </div>
          <MapStepComponents
            Buttons={buttons}
            caseId={caseId}
            components={stepData.data.components}
            onSubmit={submit}
          />
        </>
      )}
    </ProgressStepWrapper>
  );
};

export default withDocsProvider(ProgressStep);
