import { Anchor, Box, Button, Heading, Icon, Paragraph } from '@localyze-pluto/components';
import React, { useEffect } from 'react';

import { AdditionalServiceOptions } from 'modules/additionalServices/components/AdditionalServiceOptions/AdditionalServiceOptions';
import { AllowanceAmountInput } from './AllowanceAmountInput/AllowanceAmountInput';
import { Currency } from 'types/currency';
import { Step4Payload } from 'modules/cases/pages/createCase/types';
import { useAdditionalServices } from 'modules/additionalServices/api/useAdditionalServices/useAdditionalServices';
import { useForm } from 'react-hook-form';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { Link } from 'react-router-dom';
import { useTheme } from '@localyze-pluto/theme';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useCaseType } from 'modules/cases/api/useCaseType/useCaseType';
import { CaseType } from 'modules/cases/types/CaseTypeEnum';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

type Props = {
  handleBackClick(payload: Step4Payload): void;
  handleNextClick(payload: Step4Payload): void;
  isDemoModeActive: boolean;
  values: Step4Payload;
  isCreatingCase: boolean;
  companyId: string;
  destinationCountry: string;
  caseTypeId: string;
};

export const Step4 = ({
  handleBackClick,
  handleNextClick,
  values,
  isCreatingCase,
  companyId,
  isDemoModeActive,
  destinationCountry,
  caseTypeId,
}: Props): React.JSX.Element => {
  const theme = useTheme();
  const { data: caseType, isSuccess: isCaseTypeSuccess } = useCaseType(
    Number(caseTypeId),
    !isDemoModeActive,
  );
  const { isSuccess: isAdditionalServicesSuccess, data: additionalServices } =
    useAdditionalServices({
      destinationCountryId: isDemoModeActive ? undefined : Number(destinationCountry),
    });
  const routes = useRoutes();
  const { data: company, isSuccess: isCompanySuccess } = useCompany({
    companyId: Number(companyId),
    enabled: !isDemoModeActive,
  });

  const { handleSubmit, control, setValue, getValues } = useForm<Step4Payload>({
    defaultValues: values,
    mode: 'onTouched',
  });

  const allowanceCurrency = isDemoModeActive ? Currency.USD : company?.currency;

  useEffect(() => {
    setValue('allowanceCurrency', allowanceCurrency ?? '');
  }, [allowanceCurrency, setValue]);

  if (
    !isAdditionalServicesSuccess ||
    (!isCaseTypeSuccess && !isDemoModeActive) ||
    (!isCompanySuccess && !isDemoModeActive)
  ) {
    return (
      <Box.div alignItems="center" display="flex" flexDirection="column">
        <ContainedLoadingState />
      </Box.div>
    );
  }

  const onSubmit = (data: Step4Payload) => {
    handleNextClick(data);
  };

  return (
    <Box.form
      display="flex"
      flexDirection="column"
      gap="d5"
      justifyContent="space-between"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box.div display="flex" flexDirection="column" gap="d5">
        <Heading as="h1" color="contentPrimary" marginBottom="d0" size="heading50">
          Book Partner Services to improve your talent’s experience
        </Heading>

        <AdditionalServiceOptions
          availableAdditionalServices={additionalServices}
          caseAdditionalServices={[]}
          caseTypeName={(isDemoModeActive ? '' : caseType?.name) as CaseType}
          control={control}
        />

        <Box.hr my="m12" />

        <Box.div
          backgroundColor="colorBackgroundWeaker"
          borderRadius="borderRadius30"
          display="flex"
          flexDirection="column"
          gap="d4"
          marginBottom="d10"
          padding="p6"
        >
          <Heading as="h2" color="contentPrimary" marginBottom="m0" size="heading50">
            Not sure which service your talent needs? <br /> Let them choose by setting an
            allowance!
          </Heading>
          <Box.div display="flex" flexDirection="column" gap="d2">
            <Paragraph marginBottom="m0">
              Empower your Talent to choose the services that are most important to them. They will
              be able to purchase any services à la carte in our Marketplace. The company will be
              invoiced for each service up to the amount set in the allowance.
            </Paragraph>

            <Anchor
              as={Link}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.space.m2,
                fontSize: theme.fontSizes.fontSize20,
              }}
              target="_blank"
              to={routes.knowledge.url}
            >
              Learn more about allowances
              <Icon decorative icon="square-arrow-out-up-right" size="sizeIcon30" />
            </Anchor>
          </Box.div>

          <AllowanceAmountInput control={control} currency={allowanceCurrency} />
        </Box.div>
      </Box.div>
      <Box.div alignItems="center" display="flex" gap="d3" justifyContent="flex-end">
        <Button
          disabled={isCreatingCase}
          leadingIcon="chevron-left"
          onClick={() => handleBackClick(getValues())}
          type="button"
          variant="outline"
        >
          Back
        </Button>
        <Button
          disabled={isCreatingCase}
          loading={isCreatingCase}
          trailingIcon="chevron-right"
          type="submit"
          variant="primary"
        >
          Submit
        </Button>
      </Box.div>
    </Box.form>
  );
};
