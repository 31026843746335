import { SelectItemProps } from '@localyze-pluto/components';
import { CompanyUser } from 'deprecated/api/companies/company_user';
import { UserRoleName } from 'modules/users/types/UserRoleName';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';

const ROLE_RANK = {
  [UserRoleName.Viewer]: 1,
  [UserRoleName.Regular]: 2,
  [UserRoleName.SuperAdmin]: 3,
};

/**
 * Creates replacement member options by filtering out ineligble replacement members
 * and formatting them into select options
 * @param users - list of company users
 * @param userIdToRemove
 * @returns
 */
export const createReplacementMemberOptions = (
  users: CompanyUser[],
  userToRemove: Pick<CompanyUser, 'id' | 'role_name'>,
): SelectItemProps[] => {
  const filteredUsers = users.filter((user) => {
    const isUserToRemove = user.id === userToRemove.id;
    if (isUserToRemove) {
      return false;
    }

    if (user.role_name in ROLE_RANK === false || userToRemove.role_name in ROLE_RANK === false) {
      // If invalid role, keep user and return true
      return true;
    } else {
      const doesUserHaveRankEqualToOrHigher =
        ROLE_RANK[user.role_name as UserRoleName] >=
        ROLE_RANK[userToRemove.role_name as UserRoleName];

      return doesUserHaveRankEqualToOrHigher;
    }
  });

  return filteredUsers.map((user) => ({
    label: fullName(user),
    value: user.id.toString(),
  }));
};
