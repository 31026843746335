import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DisclosureStore } from '@ariakit/react';
import {
  Box,
  Button,
  ControlledFormInput,
  ControlledFormTextArea,
  ControlledRadioGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Radio,
  useToast,
} from '@localyze-pluto/components';
import React from 'react';
import { Step, UserType } from 'modules/pipelines/types/Step';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCreateStep } from 'modules/pipelines/hooks/useCreateStep';

type Payload = Pick<Step, 'description' | 'title' | 'user_type'>;

const AddNewStep = ({
  pipelineId,
  closeHook,
  hookSuccessAdd,
  modalState,
}: {
  pipelineId: number;
  closeHook: () => void;
  hookSuccessAdd: ({
    stepId,
    pipelineStepId,
    title,
  }: {
    stepId: number;
    pipelineStepId: number;
    title: string;
  }) => void;
  modalState: DisclosureStore;
}): React.JSX.Element => {
  const toast = useToast();

  const { mutate: createStep } = useCreateStep({
    onSuccess: (pipelineStep) => {
      toast('Successfully created.', 'success');
      hookSuccessAdd({
        stepId: pipelineStep.step.id,
        pipelineStepId: pipelineStep.id,
        title: pipelineStep.step.title,
      });
    },
    onError: () =>
      toast(
        'Something went wrong creating the step, please try again refreshing the page',
        'error',
      ),
  });

  const schema: yup.ObjectSchema<Payload> = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
    user_type: yup
      .string()
      .oneOf([UserType.CaseManager, UserType.HrManager, UserType.Talent])
      .required(),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: '',
      description: '',
      user_type: UserType.CaseManager,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Payload> = (values) => {
    const newStepData: Payload = {
      title: values.title,
      description: values.description,
      user_type: values.user_type,
    };

    createStep({ pipelineId, step: newStepData });
    trackEvent('pipeline editor: create new step', { isUserType: values.user_type });
  };

  return (
    <Modal store={modalState}>
      <ModalHeader>
        <ModalHeading>Add a new step</ModalHeading>
      </ModalHeader>
      <Box.form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Box.div display="flex" flexDirection="column" gap="m4">
            <ControlledFormInput
              control={control}
              data-testid="title"
              id={`title`}
              label="Name of the step"
              name="title"
              type="text"
            />
            <ControlledFormTextArea
              control={control}
              id={`description`}
              label="Step description"
              name="description"
            />
            <Box.div>
              <Label htmlFor="user_type">User type</Label>
              <ControlledRadioGroup
                control={control}
                id="user_type"
                name="user_type"
                orientation="horizontal"
              >
                <Radio label="Talent step" value="talent" />
                <Radio label="Case Manager step" value="case_manager" />
                <Radio label="HR step" value="hr_manager" />
              </ControlledRadioGroup>
            </Box.div>
          </Box.div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeHook} variant="outline">
            Cancel
          </Button>
          <Button name="Add" type="submit" variant="primary">
            Add
          </Button>
        </ModalFooter>
      </Box.form>
    </Modal>
  );
};

export default AddNewStep;
