import * as Yup from 'yup';
import Card from 'deprecated/components/Card/CardContainer';
import FormFields from 'deprecated/components/FormFields';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'constants/formValidations';
import { submitUpdatePipeline } from 'deprecated/pages/viewsNew/Admin/PipelineEditor/utils/pipeline_editor';
import { Box, Button, useToast } from '@localyze-pluto/components';

export default function PipelineUpdate({ pipeline, hookAfterEditPipe }) {
  const { id = '', title = '' } = pipeline;
  const [editing, setEditing] = useState(false);
  const toast = useToast();

  const initialValues = { title };

  const validationSchema = Yup.object({
    title: Validation.required,
  });

  const handleSubmit = (values) => {
    const pipelineData = { id, title: values.title };

    const callbackSuccess = () => {
      hookAfterEditPipe(pipelineData);
      setEditing(!editing);
      toast('Successfully updated', 'success');
    };

    const callbackError = () =>
      toast(
        'Something went wrong updating the pipeline, please try again refreshing the page',
        'error',
      );

    submitUpdatePipeline({ pipelineData, callbackSuccess, callbackError });
  };

  return (
    <Card outlined display="" padding="14px" borderRadius="5px 5px 0px 0px">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <Form>
          <Box.div display="flex" gap="d2">
            <FormFields
              fieldType="input"
              name="title"
              label="Name of pipeline"
              disabled={!editing}
            />
            {!editing && (
              <Button
                type="button"
                variant="secondary"
                disabled={!id}
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            )}
            {editing && id && (
              <Button variant="primary" type="submit" h="fit-content">
                Save
              </Button>
            )}
          </Box.div>
        </Form>
      </Formik>
    </Card>
  );
}

PipelineUpdate.propTypes = {
  pipeline: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  hookAfterEditPipe: PropTypes.func,
};
