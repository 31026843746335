import { Box } from '@localyze-pluto/components';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { Container } from 'deprecated/components/Chewpaca/Container';
import React from 'react';

export const NoResultsFound = (): React.JSX.Element => {
  return (
    <Container
      className={`tw-flex tw-flex-col tw-flex-grow tw-rounded-2xl tw-justify-center
    tw-items-center tw-pt-20 tw-pb-20 tw-px-6 tw-bg-white`}
    >
      <EmptyState
        illustration={
          <Box.span aria-label="Magnifying glass" role="img">
            🔎
          </Box.span>
        }
      >
        <div
          className={`tw-flex tw-flex-col tw-text-center tw-text-sm tw-font-normal
        tw-text-gray-500`}
          data-testid="no-results-message"
        >
          <span>Sorry, we couldn’t find any matches for this filter.</span>
          <span>Please try with a different one or search by title.</span>
        </div>
      </EmptyState>
    </Container>
  );
};
