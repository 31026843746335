import React from 'react';

type FloatingLabelProps = {
  label?: string;
};

export const FloatingLabel = ({ label }: FloatingLabelProps): React.JSX.Element | null => {
  if (label) {
    return (
      <label className="floatingLabel float" data-testid="select-menu-floating-label">
        {label}
      </label>
    );
  }
  return null;
};
