import React from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeading,
  DrawerStore,
} from '@localyze-pluto/components';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { GlomoRequestQuestionsTable } from '../GlomoRequestQuestionsTable/GlomoRequestQuestionsTable';

export const FullRequestDrawer = ({
  store,
  questions,
}: {
  store: DrawerStore;
  questions: GlomoRequest['questions'];
}): React.JSX.Element => {
  return (
    <Drawer store={store}>
      <DrawerHeader>
        <DrawerHeading>Required details</DrawerHeading>
      </DrawerHeader>
      <DrawerBody padding="p6" style={{ overflowX: 'hidden' }}>
        <Box.div h="100%" maxW={452} overflowX="hidden">
          <GlomoRequestQuestionsTable questions={questions} />
        </Box.div>
      </DrawerBody>
    </Drawer>
  );
};
