import {
  Badge,
  Box,
  Button,
  ContentCard,
  Heading,
  InteractiveElementType,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDrawerStore,
} from '@localyze-pluto/components';
import { useServiceCategories } from 'modules/additionalServices/api/useServiceCategories/useServiceCategories';
import { PageLayout } from 'modules/layout/components/PageLayout';
import React, { useEffect, useState } from 'react';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { PartnerSettingsDrawer } from './components/PartnerSettingsDrawer/PartnerSettingsDrawer';
import { useStoreState } from '@ariakit/react';
import { usePartnersWithConfiguration } from '../hooks/usePartnersWithConfiguration';
import { useParams } from 'react-router-dom';

export const ManagePartners = (): React.JSX.Element => {
  const { companyId } = useParams<{
    companyId: string;
  }>();
  const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState<number>();

  const { data: serviceCategories, isSuccess: isSuccessCategories } = useServiceCategories();

  const { partnersWithConfiguration, isSuccess: isSuccessPartnersWithConfiguration } =
    usePartnersWithConfiguration({
      serviceCategoryId: selectedServiceCategoryId,
      companyId: Number(companyId),
      enabled: !!selectedServiceCategoryId,
    });

  useEffect(() => {
    if (isSuccessCategories) {
      setSelectedServiceCategoryId(serviceCategories[0].id);
    }
  }, [isSuccessCategories, serviceCategories]);

  const drawerStore = useDrawerStore();
  const isDrawerOpen = useStoreState(drawerStore, 'open');

  if (!isSuccessCategories) {
    return <ContainedLoadingState />;
  }

  return (
    <PageLayout flexed maxW="1200px" mx="auto" px="d16" w="100%">
      <Box.div display="flex" justifyContent="space-between">
        <Heading as="h1" marginBottom="d6" size="title-screen">
          Manage your partners
        </Heading>
        <Button
          leadingIcon="circle-help"
          marginBottom="d6"
          onClick={drawerStore.show}
          variant="ghost"
        >
          Learn more
        </Button>
      </Box.div>
      <Tabs initialTabId={serviceCategories[0].name}>
        <TabList aria-label="Service Categories Tabs" withDivider>
          {serviceCategories.map((serviceCategory) => (
            <Tab
              id={serviceCategory.name}
              key={serviceCategory.id}
              onClick={() => setSelectedServiceCategoryId(serviceCategory.id)}
            >
              {serviceCategory.name}
            </Tab>
          ))}
        </TabList>
        {isSuccessPartnersWithConfiguration ? (
          <TabPanels>
            {serviceCategories.map((serviceCategory) => (
              <TabPanel key={serviceCategory.name} tabId={serviceCategory.name} unmountOnHide>
                <Box.div
                  display="grid"
                  gap="d3"
                  gridAutoRows="1fr"
                  gridTemplateColumns={{
                    _: 'repeat(2, 1fr)',
                    xl: 'repeat(3, 1fr)',
                  }}
                  my="d8"
                >
                  {partnersWithConfiguration.map((partnerWithConfiguration) => {
                    const isDisabled = partnerWithConfiguration.isDisabled;
                    const isPreferred = partnerWithConfiguration.isPreferred;

                    return (
                      <ContentCard
                        background="inverse"
                        hasLowOpacity={isDisabled}
                        href="#"
                        imageAlt=""
                        imagePosition="top"
                        imageSrc={partnerWithConfiguration.image_url}
                        interactiveElementType={InteractiveElementType.Card}
                        key={partnerWithConfiguration.id}
                        text={partnerWithConfiguration.description}
                        title={partnerWithConfiguration.name}
                        topLeftElement={
                          <Box.img
                            aria-hidden
                            h="auto"
                            src={partnerWithConfiguration.logo_url}
                            w={40}
                          />
                        }
                        topRightElement={
                          <Box.div position="absolute" right={24} top={24}>
                            {isPreferred && <Badge icon="star">Preferred partner</Badge>}
                            {isDisabled && <Badge color="red">Disabled</Badge>}
                          </Box.div>
                        }
                      />
                    );
                  })}
                </Box.div>
              </TabPanel>
            ))}
          </TabPanels>
        ) : (
          <ContainedLoadingState />
        )}
      </Tabs>
      {isDrawerOpen && <PartnerSettingsDrawer state={drawerStore} />}
    </PageLayout>
  );
};
