import { Company } from 'modules/company/types/Company';
import { CompanyBaseFields } from 'modules/company/types/CompanyBaseFields';
import axios from 'axios';

export type CompanyResponse<UserRole> = UserRole extends 'international'
  ? CompanyBaseFields
  : Company;

export const getCompany = async <UserRole>(
  companyId?: number,
): Promise<CompanyResponse<UserRole>> => {
  if (companyId === undefined) {
    throw new Error('Company ID is required to fetch company');
  }
  const { data } = await axios.get<CompanyResponse<UserRole>>(`/companies/${companyId}`, {
    headers: { Accept: 'application/json' },
  });

  return data;
};
