import { Filter } from 'modules/filters/types/Filter';

export const TALENT_SIGN_UP_STATUS_FILTER: Filter<string> = {
  attribute: 'talent_sign_up_status',
  label: 'Talent sign-up status',
  options: [
    {
      filterAttribute: 'talent_sign_up_status',
      label: 'All',
      value: 'all',
      userTypes: ['admin', 'hr'],
    },
    {
      filterAttribute: 'talent_sign_up_status',
      label: 'Active',
      value: 'active',
      userTypes: ['admin', 'hr'],
    },
    {
      filterAttribute: 'talent_sign_up_status',
      label: 'Inactive',
      value: 'inactive',
      userTypes: ['admin', 'hr'],
    },
  ],
  type: 'singleselect',
  userTypes: ['admin', 'hr'],
};
