import { postDocument } from 'deprecated/api/international/documents';
import { uploadToS3AndShrineIt } from 'deprecated/api/uploads/uploadToS3AndShrineIt';

const progressFinish = 100;

export const uploadCaseDocument = async (
  referenceCode: string | null | undefined,
  file: File,
  caseId: number,
  onProgress?: (progressPercentage: number) => void,
  onCancel?: () => void,
): Promise<{ data: { url: string } } | undefined> => {
  try {
    const shrineData = await uploadToS3AndShrineIt(file, onProgress, onCancel);

    if (!shrineData) {
      return undefined;
    }

    return postDocument(
      referenceCode,
      shrineData,
      () => {
        onProgress?.(progressFinish);
      },
      caseId,
    );
  } catch (err) {
    throw err;
  }
};
