import { StackOneProvider } from 'modules/integrations/types/HRISCompanyIntegration';

export const providerLinks: Record<StackOneProvider, string> = {
  hibob:
    'https://help.localyze.com/en/articles/9405088-hibob-how-to-retrieve-your-employee-id-number',
  bamboohr:
    // eslint-disable-next-line max-len
    'https://help.localyze.com/en/articles/9405061-bamboohr-how-to-retrieve-your-employee-id-number',
  workday:
    'https://help.localyze.com/en/articles/9404971-workday-how-to-find-your-employee-id-number',
  personio:
    'https://help.localyze.com/en/articles/9405080-personio-how-to-retrieve-your-employee-id',
  sapsuccessfactors:
    // eslint-disable-next-line max-len
    'https://help.localyze.com/en/articles/9455087-sap-succesfactors-how-to-retrieve-your-employee-id-number',
};

export const getProviderPageLink = (provider: StackOneProvider): string => {
  return providerLinks[provider];
};
