import { UseMutationResult, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from 'config/queryClient';
import axios, { AxiosError } from 'axios';
import { DOCUMENTS_QUERY_KEY } from '../useDocuments/useDocuments';

type DeleteAttachmentProps = {
  id: number;
};

export const deleteAttachment = async (id: number): Promise<void> => {
  return axios.delete(`/attachments/${id}`);
};

export const useDeleteAttachment = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, DeleteAttachmentProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, DeleteAttachmentProps> =>
  useMutation({
    mutationFn: ({ id }) => deleteAttachment(id),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEY],
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
