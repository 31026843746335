import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { StackOneCompanyIntegration } from 'modules/integrations/types/HRISCompanyIntegration';

export const COMPANY_INTEGRATIONS_QUERY_KEY = 'company-integrations';

export type UseCompanyHRISIntegrations<Result> = Omit<
  UseQueryOptions<StackOneCompanyIntegration[], AxiosError, Result>,
  'queryFn' | 'queryKey'
> & {
  params: {
    companyId?: number;
  };
};

/**
 * Make a get request to fetch a company's HRIS integration info
 * @param companyId - id of company to fetch for
 * @returns HRISProviders
 */
const getCompanyHRISIntegrations = async (
  companyId?: number,
): Promise<StackOneCompanyIntegration[]> => {
  if (companyId === undefined) {
    throw new Error("Company ID is required to fetch company's HRIS integrations");
  }

  const { data } = await axios.get<StackOneCompanyIntegration[]>(
    `/companies/${companyId}/integrations`,
    { headers: { Accept: 'application/json' } },
  );

  return data;
};

/**
 * Gets HRIS integrations for a company
 * @param props.companyId - company to fetch HRIS integrations for
 * @param props.select - transform returned data
 * @returns HRIS integrations
 */
export const useCompanyHRISIntegrations = <Result = StackOneCompanyIntegration[]>({
  params,
  ...props
}: UseCompanyHRISIntegrations<Result>): UseQueryResult<Result, AxiosError> =>
  useQuery({
    queryKey: [COMPANY_INTEGRATIONS_QUERY_KEY, params.companyId],
    queryFn: () => getCompanyHRISIntegrations(params.companyId),
    enabled: !!params.companyId,
    ...props,
  });
