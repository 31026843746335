import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';
import { getOffices } from 'modules/offices/api/useOffices/getOffices';
import { OfficeWithOfficeInformation } from 'deprecated/api/companies/types/OfficeAPIResponse';

const officesToFilterOptions = (
  offices: ReadonlyArray<OfficeWithOfficeInformation> | null,
): FilterOption<string>[] =>
  offices
    ? offices.map((item) => ({
        filterAttribute: 'office_id',
        label: item.name,
        userTypes: ['admin', 'hr'],
        value: item.id.toString(),
      }))
    : [];

const ALL_OFFICES_OPTION: FilterOption<string> = {
  filterAttribute: 'office_id',
  label: 'All',
  userTypes: ['admin', 'hr'],
  value: 'all',
};

export const officeFilter = (companyId: number): Filter<string> => {
  return {
    attribute: 'office_id',
    label: 'Office',
    options: [],
    populateOptions: async () => [
      ALL_OFFICES_OPTION,
      ...officesToFilterOptions(await getOffices(companyId)),
    ],
    type: 'multiselect',
    userTypes: ['hr'],
  };
};
