import { isEmpty, isEqual } from 'lodash';
import { SelectedFilters } from 'modules/filters/types/SelectedFilters';

type CaseStateValue = 'case_evaluation' | 'closed' | 'ongoing' | 'waiting';

type CaseOverviewUrlFilters = {
  case_manager_id?: string[];
  hr_manager_id?: string[];
  office_id?: string[];
  company_id?: string[];
  case_type_id?: string[];
  process_state?: CaseStateValue[];
  user_active?: boolean;
  sort_by?: string;
  destination_country_id?: string[];
};

export type CaseOverviewUrlFilterEntry = [
  keyof CaseOverviewUrlFilters,
  CaseOverviewUrlFilters[keyof CaseOverviewUrlFilters],
];

const adminMyTalentFilter = (isAdmin: boolean, activeTab: string, currentUserId: string) => {
  if (!isAdmin || activeTab === 'all') return undefined;

  return [currentUserId];
};

const hrMyTalentFilter = (isAdmin: boolean, activeTab: string, currentUserId: string) => {
  if (isAdmin || activeTab === 'following' || activeTab === 'all') return [];

  return [currentUserId];
};

const getUrlFilterEntries = (params: CaseOverviewUrlFilters): CaseOverviewUrlFilterEntry[] =>
  Object.entries(params).sort(([keyA], [keyB]) => {
    const lower = -1,
      greater = 1,
      equal = 0;

    if (keyA < keyB) return lower;
    if (keyA > keyB) return greater;
    return equal;
  }) as CaseOverviewUrlFilterEntry[];

export const getCaseOverviewsFilterParams = (
  { case_manager_id, talent_sign_up_status, ...filters }: SelectedFilters,
  isAdmin: boolean,
  currentUserId: string,
  tab: string,
): CaseOverviewUrlFilterEntry[] => {
  const userActive =
    talent_sign_up_status === 'active'
      ? true
      : talent_sign_up_status === 'inactive'
        ? false
        : undefined;

  const params: CaseOverviewUrlFilters = {
    ...filters,
    case_manager_id: ((!isEmpty(case_manager_id) && case_manager_id) ||
      adminMyTalentFilter(isAdmin, tab, currentUserId)) as string[],
    hr_manager_id: hrMyTalentFilter(isAdmin, tab, currentUserId),
    user_active: userActive,
  };

  return getUrlFilterEntries(params).filter(
    ([_, value]) => value !== undefined && !isEqual(value, []),
  );
};
