import React from 'react';

import { DocumentType } from 'modules/documents/api/attachments/types/DocumentType';
import { ConfirmationModal, ModalStore, useToast } from '@localyze-pluto/components';
import { useDeleteDocument } from '../api/useDeleteDocument/useDeleteDocument';
import { AxiosError } from 'axios';
import { useDeleteAttachment } from '../api/useDeleteAttachment/useDeleteAttachment';

type DeleteDocumentProps = {
  state: ModalStore;
  code?: string | null;
  name: string;
  type?: DocumentType;
  id?: number;
  caseId: number;
};

export const DeleteDocument = ({
  state,
  code,
  type,
  name,
  id,
  caseId,
}: DeleteDocumentProps): React.JSX.Element => {
  const toast = useToast();
  const { mutate: deleteDocument } = useDeleteDocument({
    onSuccess: () => {
      toast(`${name} was deleted successfully.`, 'success');
    },
    onError: (error: AxiosError) => {
      toast(error.message || 'Error deleting document.', 'error');
    },
  });

  const { mutate: deleteAttachment } = useDeleteAttachment({
    onSuccess: () => {
      toast(`${name} was deleted successfully.`, 'success');
    },
    onError: (error: AxiosError) => {
      toast(error.message || 'Error deleting attachment.', 'error');
    },
  });

  const onDelete = () => {
    if (type === 'Attachment') {
      if (id) {
        deleteAttachment({ id });
      }
    } else {
      if (!caseId || !code) return;

      deleteDocument({ caseId: caseId, internalReferenceCode: code });
    }
  };

  return (
    <ConfirmationModal confirmLabel="Delete" destructive onConfirm={onDelete} store={state}>
      Are you sure you want to delete this document? This action cannot be undone.
    </ConfirmationModal>
  );
};
