import React from 'react';
import { CaseOverviewCell } from '../CaseOverviewCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  show: boolean;
  caseOverview: CaseOverview;
};

export const MessageIndicator = ({ show, caseOverview }: Props): React.JSX.Element => (
  <CaseOverviewCell caseOverview={caseOverview} isAdmin>
    {show && <div className="tw-bg-blue-500 tw-h-2 tw-w-2 tw-rounded-full" />}
  </CaseOverviewCell>
);
