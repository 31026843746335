import { ROLE_OPTIONS, ROLE_UPDATE_SUCCESS } from '../../../constants';
import React, { useState } from 'react';

import { useSetMemberRole } from 'modules/company/api/useSetMemberRole/useSetMemberRole';
import { Select, Td, useToast } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';

type Props = {
  readonly companyId: number;
  readonly memberId: number;
  readonly roleName: string;
  readonly disabled: boolean;
};

export const RoleCell = ({ companyId, memberId, roleName, disabled }: Props): React.JSX.Element => {
  const initialRole = ROLE_OPTIONS.find((r) => r.value === roleName) || ROLE_OPTIONS[0];
  const toast = useToast();
  const user = useCurrentUser();

  const [selected, setSelected] = useState<string>(initialRole.value);
  const { mutate: setMemberRole } = useSetMemberRole({
    onSuccess: () => {
      toast(ROLE_UPDATE_SUCCESS, 'success');
      if (memberId === user.id) {
        window.location.reload();
      }
    },
    onError: (error: Error) => {
      toast(error.message, 'error');
    },
  });

  const handleRoleSelection = (value: string[] | string) => {
    const selectedValue = Array.isArray(value) ? value[0] : value;
    setSelected(selectedValue);
    setMemberRole({ companyId, userId: memberId, roleName: selectedValue });
  };

  return (
    <Td data-testid="role-cell">
      <Select
        aria-label="Role"
        disabled={disabled}
        items={ROLE_OPTIONS}
        setValue={handleRoleSelection}
        value={selected}
      />
    </Td>
  );
};
