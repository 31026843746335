import React from 'react';
import { StatusBadge } from '../StatusBadge';
import { CaseOverviewCell } from '../CaseOverviewCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  caseOverview: CaseOverview;
  isAdmin?: boolean;
};

export const CaseStatus = ({ isAdmin = false, caseOverview }: Props): React.JSX.Element => {
  const statusText = caseOverview.has_pending_tasks ? 'task' : 'noOpenTask';
  return (
    <CaseOverviewCell
      caseOverview={caseOverview}
      className="tw-whitespace-nowrap"
      isAdmin={isAdmin}
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      <StatusBadge status={statusText} />
    </CaseOverviewCell>
  );
};
