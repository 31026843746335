import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { ValueGetterParams } from 'ag-grid-community';

/**
 * Gets the talent case status to be used as a value getter
 * @param param.data.currentState - talent case status
 * @returns case status
 */
export const getCaseStatusValue = ({ data }: Pick<ValueGetterParams<CaseReport>, 'data'>): string =>
  `${data?.currentState}`;
