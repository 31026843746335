import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerStore,
} from '@localyze-pluto/components';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';
import React from 'react';
import { MobilityTracking } from 'modules/talent/pages/talentProfile/components/TalentInformationCard/MobilityTracking/MobilityTracking';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import { Link } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { PersonalInformation } from 'modules/talent/pages/talentProfile/components/PersonalInformation/PersonalInformation';
import { AccountInformation } from 'modules/talent/pages/talentProfile/components/AccountInformation/AccountInformation';
import { DrawerSection } from './DrawerSection';
import { CasesSection } from './CasesSection';

export const TalentInformationDrawer = ({
  talent,
  store,
}: {
  talent: TalentWithPersonalInformation;
  store: DrawerStore;
}): React.JSX.Element => {
  const talentFullName = fullName(talent);
  const routes = useRoutes();

  return (
    <Drawer store={store}>
      <DrawerHeader>
        <Box.div alignItems="center" display="flex" gap="d4">
          <Box.div alignItems="center" display="flex" gap="d2">
            <Avatar name={talentFullName} size="large" src={talent.avatar_url ?? ''} />
            <Box.span color="colorTextStrongest" fontSize="fontSize30" fontWeight="fontWeightBold">
              {talentFullName}
            </Box.span>
          </Box.div>

          <Button
            as={Link}
            leadingIcon="square-arrow-out-up-right"
            size="small"
            textDecoration="underline"
            to={routes.talentProfile.get(talent.id)}
            variant="ghost"
          >
            Go to talent profile
          </Button>
        </Box.div>
      </DrawerHeader>

      <DrawerBody padding="p6">
        <Box.div display="flex" flexDirection="column" gap="d4" w="502px">
          <DrawerSection title="Personal information">
            <PersonalInformation talent={talent} />
          </DrawerSection>

          <DrawerSection title="Work abroad tracker">
            <MobilityTracking talent={talent} />
          </DrawerSection>

          <DrawerSection title="Account">
            <AccountInformation talent={talent} />
          </DrawerSection>

          <CasesSection talent={talent} />
        </Box.div>
      </DrawerBody>
    </Drawer>
  );
};
