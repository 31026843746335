import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormInput,
  ControlledFormSelect,
  ControlledRadioGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Radio,
  Tooltip,
  TooltipAnchor,
  useModalStore,
  useToast,
  useTooltipStore,
} from '@localyze-pluto/components';
import { useBillingCenterOptions } from 'deprecated/api/hooks/billing-centers/hooks/useBillingCenters/useBillingCenters';
import { useCreateCompany } from 'modules/company/api/useCreateCompany/useCreateCompany';
import { CreateCompanyPayload } from 'modules/company/api/useCreateCompany/useCreateCompany.types';
import { useForm } from 'react-hook-form';
import { Validation } from 'constants/formValidations';
import * as Yup from 'yup';
import React from 'react';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

type CreateCompanyModalProps = {
  // Called on success of creating company
  onSuccess?: () => void;
};

/**
 * A button that opens a modal to create a new company
 */
export const CreateCompanyModal = ({ onSuccess }: CreateCompanyModalProps): React.JSX.Element => {
  const toast = useToast();
  const testToolTip = useTooltipStore({ defaultOpen: false, placement: 'left' });
  const modalState = useModalStore();
  const isModalOpen = modalState.useState('open');

  const { data: billingCenters, isSuccess: hasBillingCenters } = useBillingCenterOptions({
    enabled: isModalOpen,
  });
  const { handleSubmit, control, reset } = useForm<CreateCompanyPayload>({
    defaultValues: {
      companyName: '',
      hubspotId: '',
      billingCenter: '',
      test: 'false',
    },
    mode: 'onTouched',
    resolver: yupResolver(
      Yup.object({
        companyName: Validation.requiredString,
        billingCenter: Validation.dropDown,
        test: Validation.requiredString,
      }),
    ),
  });
  const { mutate: createCompany, isPending: isSubmitting } = useCreateCompany({
    onSuccess: () => {
      reset();
      modalState.hide();
      toast('The new company was created successfully', 'success');
      onSuccess?.();
    },
    onError: (error) => {
      rollbarInstance.error(error);
      toast(error.response?.data.message || 'There was an error creating a company.', 'error');
    },
  });

  const onSubmit = (values: CreateCompanyPayload) => {
    createCompany(values);
  };

  return (
    <>
      <Button
        aria-label="Add company"
        iconOnly
        leadingIcon="plus"
        onClick={modalState.show}
        variant="outline"
      />

      <Modal store={modalState}>
        <ModalHeader>
          <ModalHeading>Create company</ModalHeading>
        </ModalHeader>

        <ModalBody>
          {hasBillingCenters ? (
            <Box.form id="company-form" onSubmit={handleSubmit(onSubmit)}>
              <Box.div columnGap="d12" display="grid" rowGap="d3">
                <ControlledFormInput
                  control={control}
                  id="companyName"
                  label="Company name"
                  name="companyName"
                  required
                  type="text"
                />
                <ControlledFormSelect
                  control={control}
                  helpText="This controls the currency that the company is invoiced in."
                  id="billingCenter"
                  items={billingCenters}
                  label="Localyze billing center"
                  name="billingCenter"
                  required
                />
                <ControlledFormInput
                  control={control}
                  id="hubspotId"
                  label="Hubspot company ID"
                  name="hubspotId"
                  type="text"
                />
                <TooltipAnchor store={testToolTip}>
                  <Label htmlFor="test">Is this a test company?</Label>
                </TooltipAnchor>
                <Tooltip store={testToolTip} style={{ zIndex: 9999 }}>
                  Test companies are for testing or demonstration purposes only. There will be no
                  invoice items generated for their subscription, created cases and so on, therefore
                  they will not generate any money.
                </Tooltip>
                <ControlledRadioGroup
                  control={control}
                  defaultValue="false"
                  id="test"
                  label="Is this a test company?"
                  name="test"
                  orientation="horizontal"
                >
                  <Radio label="Yes" value="true" />
                  <Radio label="No" value="false" />
                </ControlledRadioGroup>
              </Box.div>
            </Box.form>
          ) : (
            <Box.div alignItems="center" display="flex" flexDirection="column">
              <ContainedLoadingState />
            </Box.div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isSubmitting}
            onClick={modalState.toggle}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            form="company-form"
            loading={isSubmitting}
            type="submit"
            variant="primary"
          >
            Create
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
