import { Badge } from '@localyze-pluto/components';
import React from 'react';
import { BillingStatus } from 'modules/billing/types/BillingStatus';

type BadgeColorOptions = 'blue' | 'gray' | 'green' | 'purple' | 'yellow';

const STATUS_COLORS: Record<BillingStatus, BadgeColorOptions> = {
  cancelled: 'gray',
  needs_review: 'yellow',
  reviewed: 'blue',
  synced: 'green',
};

const STATUS_LABELS = {
  cancelled: 'Cancelled',
  needs_review: 'Needs review',
  reviewed: 'Reviewed',
  synced: 'Submitted to Stripe',
};

export const StatusBadge = ({ status }: { status: BillingStatus }): React.JSX.Element => {
  return <Badge color={STATUS_COLORS[status]}>{STATUS_LABELS[status]}</Badge>;
};
