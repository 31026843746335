import { useMutation, UseMutationResult, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from 'config/queryClient';
import { postDocument } from 'deprecated/api/international/documents';
import { uploadToS3AndShrineIt } from 'deprecated/api/uploads/uploadToS3AndShrineIt';
import { AxiosError } from 'axios';
import { DOCUMENTS_QUERY_KEY } from '../useDocuments/useDocuments';

type UploadDocumentProps = {
  referenceCode: string;
  file: File;
  onProgress: (progressPercentage: number) => void;
  caseId: number;
};

const progressFinish = 100;

export const uploadDocument = async (
  referenceCode: string | null | undefined,
  file: File,
  caseId: number,
  onProgress?: (progressPercentage: number) => void,
  onCancel?: () => void,
): Promise<{ data: { url: string } } | undefined> => {
  try {
    const shrineData = await uploadToS3AndShrineIt(file, onProgress, onCancel);

    if (!shrineData) {
      return undefined;
    }

    return postDocument(
      referenceCode,
      shrineData,
      () => {
        onProgress?.(progressFinish);
      },
      caseId,
    );
  } catch (err) {
    throw err;
  }
};

export const useUploadDocument = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, UploadDocumentProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, UploadDocumentProps> =>
  useMutation({
    mutationFn: ({ referenceCode, file, caseId, onProgress }: UploadDocumentProps) =>
      uploadDocument(referenceCode, file, caseId, onProgress),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [DOCUMENTS_QUERY_KEY] });
      onSuccess?.(data, variables, context);
    },
    onError,
  });
