import { CaseAdditionalService } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { FAMILY_MEMBERS_SERVICE_NAME } from 'modules/additionalServices/constants/familyMembersServiceName';
import { ProductAdditionalService } from 'modules/additionalServices/types/ProductAdditionalService';
import { formatPriceCents } from 'utils/formatters/formatPrice/formatPrice';

export const getFamilySupportDisplayValue = ({
  caseAdditionalServices,
  familySupportProductAdditionalService,
}: {
  caseAdditionalServices?: CaseAdditionalService[];
  familySupportProductAdditionalService?: ProductAdditionalService;
}): string => {
  const includesFamilySupport = !!caseAdditionalServices?.find(
    (service) =>
      service.additional_service.name === FAMILY_MEMBERS_SERVICE_NAME &&
      !service.marketplace_order_id,
  );

  return includesFamilySupport && familySupportProductAdditionalService?.price
    ? `Included - ${formatPriceCents(
        familySupportProductAdditionalService.price.price_cents,
        familySupportProductAdditionalService.price.currency,
      )}`
    : includesFamilySupport
      ? 'Included'
      : 'Not included';
};
