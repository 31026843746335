import React, { useEffect } from 'react';

import { DocumentsList } from './components/DocumentsList';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { FrequentlyAskedQuestions } from './components/FrequentlyAskedQuestions';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { PreChecksStatus } from './components/PreChecksStatus';
import { WaitingTimes } from './components/WaitingTimes/WaitingTimes';
import { useUserStatistics } from 'deprecated/api/user/statistics/useUserStatistics';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { trackPageView } from 'modules/analytics/analytics';

export const Knowledge = (): React.JSX.Element => {
  const {
    data: userStatistics,
    isError: isUserError,
    isSuccess: isUserSuccess,
  } = useUserStatistics();

  useEffect(() => {
    trackPageView('Knowledge');
  }, []);

  if (isUserError) {
    return <ErrorStatePage />;
  }

  if (!isUserSuccess) {
    return <ContainedLoadingState />;
  }

  return (
    <PageLayout title="The knowledge you need, whenever you need it">
      <div className="tw-grid tw-grid-cols-4 tw-grid-rows-2 tw-gap-4">
        <div className="tw-row-start-1 tw-row-end-2 tw-col-start-1 tw-col-end-4">
          <WaitingTimes />
          <p className="tw-pt-3 tw-text-xs tw-text-gray-700 tw-leading-4">
            The information on waiting times displayed on this page gets updated regularly. However,
            please keep in mind that the times are not a guarantee, but only an indication based on
            our experience.
          </p>
        </div>
        <div className="tw-row-start-1 tw-row-end-2 tw-col-start-4 tw-col-end-5 tw-min-w-56">
          <PreChecksStatus preChecksCount={userStatistics.company_pre_checks_count} />
        </div>
        <div className="tw-row-start-2 tw-row-end-3 tw-col-start-1 tw-col-end-3">
          <FrequentlyAskedQuestions />
        </div>
        <div className="tw-row-start-2 tw-row-end-3 tw-col-start-3 tw-col-end-5">
          <DocumentsList />
        </div>
      </div>
    </PageLayout>
  );
};
