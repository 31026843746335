import React, { useState } from 'react';
import { Template, TemplatesDropdown } from '../TemplatesDropdown/TemplatesDropdown';
import { TextAreaWithCounter } from './components/TextAreaWithCounter';
import { Box, Button, Tooltip, TooltipAnchor, useTooltipStore } from '@localyze-pluto/components';
import { PostNewUpdateType } from '../Updates/PostNewUpdate';
import { isEmpty } from 'lodash';

type PostNewItemProps = {
  onPostNewItem: (item: string) => void;
  onChangeTemplate?: (template: Template) => void;
  placeholder: string;
  textAreaAriaLabel: string;
  templates?: Template[];
  isLoading?: boolean;
  type?: PostNewUpdateType;
  noTemplatesMessage?: string;
  charLimit?: number;
  tooltipMessage?: string;
  disabled?: boolean;
  showTemplatesDropdown?: boolean;
};

const CHAR_LIMIT = 256;

export const PostNewItem = ({
  templates,
  onPostNewItem,
  placeholder,
  isLoading,
  onChangeTemplate,
  type,
  tooltipMessage,
  charLimit = CHAR_LIMIT,
  disabled,
  textAreaAriaLabel,
  showTemplatesDropdown,
}: PostNewItemProps): React.JSX.Element => {
  const [text, setText] = useState('');
  const tooltipStore = useTooltipStore({ placement: 'left-start' });

  const onChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newItem = ev.target.value;
    setText(newItem);
  };

  const onPost = (item: string) => {
    onPostNewItem(item);
    setText('');
  };

  const hasBracketsOnText = text.includes('[') || text.includes(']');

  return (
    <Box.div
      display="flex"
      flexDirection="column"
      gap="d2"
      h="100%"
      justifyContent="space-between"
      w="100%"
    >
      <>
        <TooltipAnchor
          store={tooltipStore}
          style={{
            height: '100%',
          }}
        >
          <TextAreaWithCounter
            ariaLabel={textAreaAriaLabel}
            charLimit={charLimit}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            value={text}
          />
        </TooltipAnchor>
        {tooltipMessage && disabled && <Tooltip store={tooltipStore}>{tooltipMessage}</Tooltip>}
        <Box.div columnGap="d3" display="grid" gridTemplateColumns="150px 1fr">
          {showTemplatesDropdown ? (
            <TemplatesDropdown
              isLoading={isLoading}
              key={type}
              onChange={(template: Template) => {
                setText(template.message);
                onChangeTemplate?.(template);
              }}
              templates={templates}
            />
          ) : (
            <Box.div />
          )}
          <Button
            disabled={isEmpty(text) || hasBracketsOnText}
            onClick={() => onPost(text)}
            variant="primary"
          >
            Post
          </Button>
        </Box.div>
      </>
    </Box.div>
  );
};
