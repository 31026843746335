import { axios } from 'config/axios/axios';

// Intercept axios requests and set Auth header with token from Auth0
export function setAxiosAuthHeader(token: string): void {
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );
}
