import { Combobox } from '@headlessui/react';
import React from 'react';
import { SearchInput } from './SearchInput';
import { isEmpty } from 'lodash';
import { useTalentSearch } from 'components/TalentSearch/useTalentSearch';
import { Box } from '@localyze-pluto/components';
import { TalentSearchResultOption, TalentSearchResultWrapper } from './TalentSearchResultOption';
import { styled } from '@localyze-pluto/theme';

const ResultsText = ({ text }: { text: string }) => (
  <Box.div cursor="default" position="relative" px="d4" py="d2" userSelect="none">
    <Box.span color="colorTextStronger" fontSize="fontSize20" lineHeight="lh5">
      {text}
    </Box.span>
  </Box.div>
);

const StyledComboboxOptions = styled(Box.div)`
  * {
    li {
      border-bottom: 1px solid #e2e8f0;
    }

    &:last-child li {
      border-bottom: none;
    }
  }
`;

export const TalentSearch = ({
  resultWrapper,
}: {
  resultWrapper: TalentSearchResultWrapper;
}): React.JSX.Element => {
  const { search, talents, searchTerm, isPending } = useTalentSearch();

  const renderResults = () => {
    if (isEmpty(searchTerm)) return;
    if (isPending) return <ResultsText text="Searching..." />;
    if (isEmpty(talents)) return <ResultsText text="No results." />;

    return (
      <>
        {talents.map((talent) => (
          <TalentSearchResultOption key={talent.id} talent={talent} wrapper={resultWrapper} />
        ))}
      </>
    );
  };

  return (
    <Combobox value={searchTerm}>
      <Box.div className="pluto-search" h="100%" position="relative">
        <Combobox.Input as={SearchInput} onChange={search} />
        <StyledComboboxOptions
          as={Combobox.Options}
          bg="colorBackground"
          border="0"
          borderRadius="borderRadius20"
          boxShadow="shadowStrong"
          listStyleType="none"
          maxH="15rem"
          mb="d0"
          mt="d1"
          overflow="auto"
          p="d0"
          position="absolute"
          w="100%"
          zIndex="zIndex20"
        >
          {renderResults()}
        </StyledComboboxOptions>
      </Box.div>
    </Combobox>
  );
};
