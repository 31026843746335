import { CommunicationPriority } from 'modules/cases/types/CommunicationPriority';
import { Icon } from '@localyze-pluto/components';
import React from 'react';
import { TooltipCell } from './TooltipCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  priority: Maybe<CommunicationPriority>;
  caseOverview: CaseOverview;
};

export const CommunicationPriorityCell = ({ priority, caseOverview }: Props): React.JSX.Element => {
  const priorityText = `Communication priority: ${priority}`;

  return (
    <TooltipCell caseOverview={caseOverview} isAdmin tooltipText={priorityText}>
      {priority === 'high' && (
        <Icon color="colorIconWarning" decorative icon="clock" size="sizeIcon50" />
      )}
      {priority === 'immediately' && (
        <Icon color="colorIconError" decorative icon="clock" size="sizeIcon50" />
      )}
    </TooltipCell>
  );
};
