import { Filter } from 'modules/filters/types/Filter';
import { QueryParams } from 'modules/filters/types/QueryParams';
import { SelectedFilters } from 'modules/filters/types/SelectedFilters';

/**
 * Parses the selected filters from the query parameters and the given filter collection
 * @param filters
 * @param queryParams
 * @returns selected filters
 */
export const parseSelectedFilters = (
  filters: Maybe<Filter<string>[]>,
  queryParams: QueryParams,
): SelectedFilters => {
  // Allow the sort_by and sort_direction to be set from the queryParams
  const result: SelectedFilters = {};
  if (queryParams['sort_by']) {
    result.sort_by = queryParams['sort_by'];
  }

  if (queryParams['sort_direction']) {
    result.sort_direction = queryParams['sort_direction'];
  }

  return (
    filters?.reduce((acc, filter) => {
      const entry = Object.entries(queryParams).find(([key]) => key === filter.attribute);
      if (entry) {
        const [key, value] = entry;
        return {
          ...acc,
          [key]: filter.type === 'multiselect' ? value.split(',') : value,
        };
      }

      if (filter.default) {
        const { attribute, default: value } = filter;
        return {
          ...acc,
          [attribute]: value,
        };
      }

      return acc;
    }, result) || {}
  );
};
