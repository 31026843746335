import axios from 'axios';
import { StackOneProvider } from 'modules/integrations/types/HRISCompanyIntegration';

export type StackOneSessionTokenParams = {
  provider: StackOneProvider;
};

export type StackOneSessionTokenResponse = {
  token: string;
  message: string;
};

export const getStackOneSessionToken = async (
  params: StackOneSessionTokenParams,
): Promise<StackOneSessionTokenResponse> => {
  const { data } = await axios.get<StackOneSessionTokenResponse>('/hris/connections', {
    params,
  });

  return data;
};
