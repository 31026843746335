import React from 'react';
import { Box, Icon, TooltipAnchor, Tooltip, useTooltipStore } from '@localyze-pluto/components';
import { StatusBadge } from 'modules/billing/components/StatusBadge/StatusBadge';
import { AccountEntry } from 'modules/billing/types/AccountEntry';
import { CREDIT_REASON_LABELS } from '../AddCreditModal/constants';

export const StatusCell = ({ accountEntry }: { accountEntry: AccountEntry }): React.JSX.Element => {
  const tooltipState = useTooltipStore({ placement: 'right' });
  const { cancellation_reason, credit_reason, status, reviewed_by } = accountEntry;
  const reason = cancellation_reason || (credit_reason && CREDIT_REASON_LABELS[credit_reason]);
  return (
    <>
      <Box.div alignItems="center" display="flex">
        <StatusBadge status={status} />

        {(cancellation_reason || credit_reason || (status !== 'needs_review' && reviewed_by)) && (
          <>
            <TooltipAnchor data-testid="reason-and-reviewer" store={tooltipState}>
              <Icon decorative icon="circle-help" size="sizeIcon20" />
            </TooltipAnchor>
            <Tooltip store={tooltipState}>
              {cancellation_reason || credit_reason ? <Box.div>Reason: {reason}</Box.div> : ''}
              {reviewed_by ? <Box.div>Reviewed by: {reviewed_by}</Box.div> : ''}
            </Tooltip>
          </>
        )}
      </Box.div>
    </>
  );
};
