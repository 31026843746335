import axios from 'axios';
import { queryClient } from 'config/queryClient';
import { getTasksQueryKey } from 'modules/tasks/api/useTasks/useTasks';
import { Task } from 'modules/tasks/types/Task';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { PENDING_COUNTERS_KEY } from 'modules/home/api/usePendingCounters';

type UpdateTaskRequestPayload = { completed: boolean };

export type UpdateTaskProps = {
  taskId: number;
  payload: UpdateTaskRequestPayload;
};

const updateTask = async ({ taskId, payload }: UpdateTaskProps): Promise<Task> => {
  const response = await axios.put<Task>(`/tasks/${taskId}`, payload);
  return response.data;
};

export const useUpdateTask = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<Task, AxiosErrorWithMessage, UpdateTaskProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<Task, AxiosErrorWithMessage, UpdateTaskProps> =>
  useMutation({
    mutationFn: updateTask,
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: getTasksQueryKey({
          case_id: data.case_id,
          all_items: true,
        }),
      });
      queryClient.invalidateQueries({ queryKey: [PENDING_COUNTERS_KEY] });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
