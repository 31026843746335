import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { Icon, RichText } from '@localyze-pluto/components';
import { DocumentList } from 'modules/caseDetails/components/Documents/components/DocumentList';
import { useFAQs } from 'modules/knowledge/api/useFAQs';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { FAQ } from 'modules/knowledge/types/FAQ';

export const FrequentlyAskedQuestions = (): React.JSX.Element => {
  const faqQuery = useFAQs();
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  useEffect(() => {
    if (faqQuery.isSuccess) {
      setFaqs(faqQuery.data);
    }
  }, [faqQuery.data, faqQuery.isSuccess]);

  if (!faqQuery.isSuccess) {
    return <ContainedLoadingState />;
  }

  const updateOpenedFAQ = (selectedQuestion: string) => {
    const newFaqs = faqs.map((faq) => {
      if (faq.question === selectedQuestion) {
        return {
          ...faq,
          open: !faq.open,
        };
      }

      return faq;
    });

    setFaqs(newFaqs);
  };

  return (
    <DocumentList h="288" title="FAQ">
      {faqs.map((faq) => {
        return (
          <DocumentList.Item
            key={faq.question}
            onClick={() => {
              updateOpenedFAQ(faq.question);
              trackEvent('knowledge: open faq item', { question: faq.question });
            }}
            suffix={
              <Icon
                className={cx('tw-text-gray-400', { 'tw-transform tw-rotate-90': faq.open })}
                decorative
                icon="chevron-right"
                size="sizeIcon30"
              />
            }
          >
            <span className="tw-cursor-pointer tw-text-sm tw-leading-5 tw-font-normal">
              {faq.question}
            </span>
            {faq.open && (
              <div className="tw-pt-6">
                <RichText externalAnchors>{faq.answerHTML}</RichText>
              </div>
            )}
          </DocumentList.Item>
        );
      })}
    </DocumentList>
  );
};
