import { Box, Icon, IconProps, Paragraph } from '@localyze-pluto/components';
import React from 'react';

export const EmptyState = ({
  icon = 'thumbs-up',
  text,
}: {
  icon?: IconProps['icon'];
  text?: string;
}): React.JSX.Element => {
  return (
    <Box.div
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap="d2"
      justifyContent="center"
      px="p4"
      py="p12"
    >
      <Icon color="contentTertiary" decorative icon={icon} size="sizeIcon30" />
      <Paragraph
        color="contentTertiary"
        fontSize="fontSize20"
        marginBottom="d0"
        maxW={220}
        textAlign="center"
      >
        {text || ''}
      </Paragraph>
    </Box.div>
  );
};
