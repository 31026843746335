import { UserType } from 'modules/users/types/UserType';

/**
 * Checks if a Filter is usable by admin or hr.
 * @param objectWithUserTypes Filter
 * @param isAdmin boolean
 * @returns boolean
 */
export const filterByUserType = (
  objectWithUserTypes: { userTypes: UserType[] },
  isAdmin: boolean,
): boolean => objectWithUserTypes.userTypes.includes(isAdmin ? 'admin' : 'hr');
