import {
  Box,
  Button,
  Tab as PlutoTab,
  TabList,
  Tabs,
  useDrawerStore,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import isEmpty from 'lodash/isEmpty';
import { useCaseOverviews } from 'modules/talent/api/useCaseOverviews/useCaseOverviews';
import React, { useMemo } from 'react';
import { useTabWithQueryParams } from 'deprecated/hooks/custom/useTabWithQueryParams';
import { useFiltersOptions } from 'modules/filters/hooks/useFiltersOptions';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { getCaseOverviewsFilterParams } from 'modules/talent/api/useCaseOverviews/utils/getCaseOverviewsFilterParams';
import { parseSelectedFilters } from 'deprecated/libs/filters/parseSelectedFilters';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { getCaseOverviewsFilters } from 'modules/caseOverview/utils/getCaseOverviewsFilters';
import { PaginationWithCounter } from 'components/PaginationWithCounter/PaginationWithCounter';
import { usePagination } from 'modules/filters/hooks/usePagination';
import { Tab } from 'types/Tab';
import { CaseOverviewsEmptyState } from '../CaseOverviewsEmptyState/CaseOverviewsEmptyState';
import { CaseOverviewsTable } from '../CaseOverviewsTable/CaseOverviewsTable';
import { useCurrentUser } from 'config/CurrentUserContext';
import { getTabsByUserRole } from 'modules/utils/getTabsByUserRole';
import {
  FiltersDrawer,
  FiltersValues,
} from 'modules/filters/components/FiltersDrawer/FiltersDrawer';
import { useStoreState } from '@ariakit/react';

const ITEMS_PER_PAGE = 60;

export const CaseOverviewsList = ({ tabsList }: { tabsList: Tab<string>[] }): React.JSX.Element => {
  const { id: userId, company_id, isAdmin, user_role } = useCurrentUser();

  const { data: countries } = useCountries({
    params: {
      association: 'cases',
      filter: 'destination',
    },
  });

  const [page, setPage] = usePagination();

  const { tabs, selectedTab, getTabIndex, updateSelectedTab } = useTabWithQueryParams(
    getTabsByUserRole(tabsList, user_role),
  );

  const {
    isError: isErrorFilter,
    isPending: isPendingFilters,
    data: availableFilters = [],
  } = useFiltersOptions({
    queryKey: `case-overview-filters-${selectedTab.key}`,
    filterParams: {
      filters: getCaseOverviewsFilters(company_id, countries || []),
      isAdmin,
      tab: `${selectedTab.key}`,
    },
    enabled: !!countries,
  });

  const { queryParams, updateQueryParams } = useQueryParams();

  const selectedFilters = useMemo(
    () => parseSelectedFilters(availableFilters, queryParams),
    [availableFilters, queryParams],
  );

  const filters = getCaseOverviewsFilterParams(
    selectedFilters,
    isAdmin,
    String(userId),
    selectedTab.key,
  );

  const {
    data,
    isPending: isPendingCaseOverviews,
    isError: isErrorCaseOverviews,
    isSuccess: isSuccessCaseOverviews,
  } = useCaseOverviews(
    {
      page,
      limit: ITEMS_PER_PAGE,
      ...Object.fromEntries(filters),
    },
    {
      enabled: !isPendingFilters,
    },
  );

  const drawerStore = useDrawerStore();
  const isDrawerOpen = useStoreState(drawerStore, 'open');

  const caseOverviews = data?.cases || [];

  const changeTab = (tab: string) => {
    const tabIndex = getTabIndex(tabs, tab);
    updateSelectedTab(tabIndex);
  };

  const onApplyDrawerFilters = (filtersData: FiltersValues) => {
    availableFilters.forEach((filter) => {
      const filterValue = filtersData[filter.attribute];
      const formattedUrlValue = Array.isArray(filterValue) ? filterValue.join(',') : filterValue;

      updateQueryParams({
        [`${filter.attribute}`]: formattedUrlValue,
      });
    });

    drawerStore.hide();
  };

  if (isErrorCaseOverviews || isErrorFilter) {
    return <ErrorStatePage />;
  }

  if (isPendingFilters) {
    return <ContainedLoadingState />;
  }

  return (
    <>
      <Box.div
        alignItems="center"
        data-testid="filters-bar"
        display="flex"
        justifyContent="space-between"
        paddingRight="p2"
      >
        <Tabs initialTabId={selectedTab.key}>
          <TabList aria-label="Case overviews tabs">
            {tabs.map((tab) => {
              return (
                <PlutoTab id={tab.key} key={tab.key} onClick={() => changeTab(tab.key)}>
                  {tab.name}
                </PlutoTab>
              );
            })}
          </TabList>
        </Tabs>
        <Button
          disabled={!isSuccessCaseOverviews || isPendingFilters}
          leadingIcon="sliders-vertical"
          onClick={drawerStore.show}
          variant="secondary"
        >
          Filter
        </Button>
      </Box.div>

      <Box.div
        display="flex"
        flex={1}
        flexDirection="column"
        gap="d5"
        justifyContent="space-between"
        marginBottom="m6"
        marginTop="d4"
      >
        {isPendingCaseOverviews ? (
          <ContainedLoadingState />
        ) : isEmpty(caseOverviews) ? (
          <CaseOverviewsEmptyState />
        ) : (
          <CaseOverviewsTable caseOverviews={caseOverviews} />
        )}
      </Box.div>
      {isSuccessCaseOverviews && (
        <PaginationWithCounter
          itemsPerPage={ITEMS_PER_PAGE}
          paginationInfo={data.meta}
          setPage={setPage}
        />
      )}
      {isSuccessCaseOverviews && isDrawerOpen && (
        <FiltersDrawer
          availableFilters={availableFilters}
          initialFilterValues={selectedFilters as FiltersValues}
          onApply={onApplyDrawerFilters}
          store={drawerStore}
        />
      )}
    </>
  );
};
