import { UserRoleName } from 'modules/users/types/UserRoleName';

const NAME_HEADER = 'Name & Email';
const ADDED_ON_HEADER = 'Added on';
const STATUS_HEADER = 'Status';
const ROLE_HEADER = 'Role';
export const TABLE_HEADERS = [NAME_HEADER, ADDED_ON_HEADER, STATUS_HEADER, ROLE_HEADER, ''];

export const ACTIVE = 'Active';
export const PENDING = 'Pending';

export const ROLE_OPTIONS = [
  { label: 'Superadmin', value: UserRoleName.SuperAdmin },
  { label: 'Regular', value: UserRoleName.Regular },
  { label: 'Viewer', value: UserRoleName.Viewer },
];

export const ROLE_UPDATE_SUCCESS = 'Role successfully updated.';
