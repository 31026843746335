import { Button, Icon, useModalStore, useToast } from '@localyze-pluto/components';
import React, { useState } from 'react';

import { UploadAttachmentModal } from 'components/UploadAttachmentModal/UploadAttachmentModal';
import { cancelAllUploadsToS3 } from 'deprecated/api/uploads/uploadToS3';
import { AxiosError } from 'axios';
import { useUploadAttachment } from '../api/useUploadAttachment/useUploadAttachment';

export type UploadDocumentProps = {
  className?: string;
  caseId: number;
};

const initial = 0;

export const UploadDocument = ({ className, caseId }: UploadDocumentProps): React.JSX.Element => {
  const toast = useToast();
  const [uploadProgress, setUploadProgress] = useState(initial);
  const [fileUploadError, setFileUploadError] = useState<string | undefined>(undefined);
  const modal = useModalStore({ defaultOpen: false });

  const { mutate: uploadAttachment } = useUploadAttachment({
    onSuccess: () => {
      setUploadProgress(100);
      toast('Uploaded attachment successfully.', 'success');
      setTimeout(modal.hide, 1000);
    },
    onError: (error: AxiosError) => {
      toast(error.message || 'Error uploading attachment.', 'error');
      setFileUploadError('Something went wrong. Try uploading your file again.');
      setUploadProgress(0);
    },
  });

  const onDrop = async (files: File[], name: string) => {
    if (files.length !== 0) {
      uploadAttachment({
        name,
        file: files[0],
        caseId,
        onProgress: setUploadProgress,
      });
    }
  };

  const cancelUpload = () => {
    cancelAllUploadsToS3();
    setUploadProgress(initial);
  };

  return (
    <>
      <Button
        className={className}
        onClick={() => {
          modal.show();
        }}
        variant="ghost"
      >
        <Icon decorative icon="upload" />
        Upload
      </Button>

      <UploadAttachmentModal
        error={fileUploadError}
        onCancel={cancelUpload}
        onClose={cancelUpload}
        onDrop={onDrop}
        progress={uploadProgress}
        state={modal}
      />
    </>
  );
};
