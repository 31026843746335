import { COLUMN_STYLES } from './VariablesList.styles';

import React from 'react';
import { Row } from 'components/StackedTable/types/Row';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';
import { VariableGroup } from 'deprecated/api/internal_variables/types/VariableGroup';
import { Td, Tr } from '@localyze-pluto/components';

export const buildRows = ({
  variableGroups,
  onTitleClick,
  onClick,
}: {
  variableGroups: VariableGroup[];
  onTitleClick: (variable: Variable) => void;
  onClick: (data: VariableGroup) => void;
}): Row[] => {
  return variableGroups.map((variableGroup) => {
    const firstItem = variableGroup.internal_variables[0];

    const rowLabels = [
      <span className="tw-py-6" data-testid="stacklist-row-item" key="title">
        {variableGroup.title}
      </span>,
      firstItem.variable_type,
      '',
      '',
      <button
        className={COLUMN_STYLES.button}
        key="add-button"
        onClick={() => onClick(variableGroup)}
      >
        Add new
      </button>,
    ];

    const content = variableGroup.internal_variables.map((variable: Variable) => (
      <Tr
        hasHover
        isClickable
        key={variable.id}
        onClick={() => onTitleClick(variable)}
        onKeyDown={(ev) => {
          if ([' ', 'Enter'].includes(ev.key)) {
            onTitleClick(variable);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <Td />
        <Td data-testid="stacklist-row-item" key="title">
          {variableGroup.title}
        </Td>
        <Td>{variable.variable_type}</Td>
        <Td>{variable.destination_country?.name || 'Default'}</Td>
        <Td>{variable.origin_country?.name || 'Default'}</Td>
        <Td />
      </Tr>
    ));

    return {
      content,
      id: variableGroup.code,
      labels: rowLabels,
    };
  });
};
