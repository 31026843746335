import { formatMetadata } from './formatMetadata';
import { uploadToS3 } from './uploadToS3';

export const uploadToS3AndShrineIt = async (
  file: File,
  onProgress?: (progressPercentage: number) => void,
  onCancel?: () => void,
  onError?: () => void,
): Promise<string | undefined> => {
  try {
    // Response object can be undefined if uploads are cancelled
    const response = await uploadToS3(file, onProgress, onCancel, onError);

    if (!response || response.successful.length === 0) {
      return undefined;
    }

    const uploadedFileData = formatMetadata(response, file);

    return JSON.stringify(uploadedFileData);
  } catch (err) {
    throw err;
  }
};
