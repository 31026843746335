import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { StackOneProvider } from 'modules/integrations/types/HRISCompanyIntegration';
import axios, { AxiosError } from 'axios';

export const HRIS_UPDATE_TALENT_QUERY_KEY = 'HRIS-update-talent-hris-integration';

type Params = {
  companyId?: number;
  providerName?: StackOneProvider;
  uid?: string;
  talentId?: number;
  employeeId?: string;
};

export type UpdateTalentIntegrationPayload = {
  talent_id?: number;
  external_provider_uid?: string;
  provider_name?: StackOneProvider;
};

const updateTalentHRISIntegration = async (payload: Params): Promise<unknown> => {
  const { companyId, providerName, employeeId, talentId } = payload;
  const uid = btoa(employeeId || '');

  const url = `/companies/${companyId}/integrations/${providerName}/internationals/${uid}`;

  const data: UpdateTalentIntegrationPayload = {
    talent_id: talentId,
    external_provider_uid: employeeId,
    provider_name: providerName,
  };

  return axios.put(url, data, { headers: { Accept: 'application/json' } });
};

export type UseUpdateHRISTalentProps = Pick<
  UseMutationOptions<unknown, AxiosError, unknown>,
  'onError' | 'onSuccess'
>;

export const useUpdateHRISTalent = ({
  onSuccess,
  onError,
}: UseUpdateHRISTalentProps): UseMutationResult<unknown, AxiosError, Params> =>
  useMutation({
    mutationFn: updateTalentHRISIntegration,
    onSuccess,
    onError,
  });
