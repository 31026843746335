import { AnythingElse } from 'modules/pipelines/pages/caseEvaluationSummary/components/AnythingElse/AnythingElse';
import { ExpectedTimeline } from 'modules/pipelines/pages/caseEvaluationSummary/components/ExpectedTimeline/ExpectedTimeline';
import { FamilySupport } from 'modules/pipelines/pages/caseEvaluationSummary/components/FamilySupport/FamilySupport';
import React from 'react';
import { RequiredDocuments } from 'modules/pipelines/pages/caseEvaluationSummary/components/RequiredDocuments/RequiredDocuments';
import { SectionProps } from 'modules/pipelines/pages/caseEvaluationSummary/components/Section/Section';
import { VisaCategory } from 'modules/pipelines/pages/caseEvaluationSummary/components/VisaCategory/VisaCategory';

export type Section = {
  title: string;
  component: React.FC<Pick<SectionProps, 'disabled' | 'title'>>;
};

const SECTIONS: Section[] = [
  {
    title: 'Expected permit/visa category',
    component: VisaCategory,
  },
  {
    title: 'Required documents',
    component: RequiredDocuments,
  },
  {
    title: 'Expected timeline',
    component: ExpectedTimeline,
  },
  {
    title: 'Family support',
    component: FamilySupport,
  },
  {
    title: 'Anything else',
    component: AnythingElse,
  },
];

export const getSections = (includeRequiredDocuments: boolean): Section[] => {
  if (includeRequiredDocuments) {
    return SECTIONS;
  }

  return SECTIONS.filter((section) => section.title !== 'Required documents');
};
