import React, { useState } from 'react';
import { Box, Button, Select, SelectItemProps, useToast } from '@localyze-pluto/components';
import { usePartners } from 'modules/additionalServices/api/usePartners/usePartners';
import type { Partner } from 'modules/additionalServices/types/Partner';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';
import { useUpdateCaseAdditionalService } from 'modules/additionalServices/api/useUpdateCaseAdditionalService/useUpdateCaseAdditionalService';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useForm } from 'react-hook-form';
import { DetailedCaseAdditionalService } from 'modules/additionalServices/api/useDetailedCaseAdditionalService/useDetailedCaseAdditionalService';

type PartnersFormProps = {
  caseAdditionalService: DetailedCaseAdditionalService;
};

const transformPartners = (partners: Partner[]): SelectItemProps[] =>
  mapToSelectOptions({ records: partners, valueProp: 'id', labelProp: 'name' });

export const PartnersForm: React.FC<PartnersFormProps> = ({ caseAdditionalService }) => {
  const toast = useToast();
  const partner = caseAdditionalService.partner;
  const { data: partners } = usePartners();
  const { mutate: updateCaseAdditionalService } = useUpdateCaseAdditionalService({
    onSuccess: () => {
      toast('Partner saved correctly', 'success');
    },
    onError: () => {
      toast('Partner not saved', 'error');
    },
  });
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const { getValues, register, setValue } = useForm({
    defaultValues: {
      servicePartners: partner?.id,
    },
  });

  const handleClick = () => {
    if (!formDisabled && getValues('servicePartners')) {
      updateCaseAdditionalService({
        caseAdditionalService,
        partnerId: Number(getValues('servicePartners')),
      });
      trackEvent('additional service: save partner', {
        partner_id: getValues('servicePartners'),
      });
    }
    setFormDisabled(!formDisabled);
  };

  return (
    <Box.form display=" flex" gap="d2" padding="p4">
      <Select
        defaultValue={String(partner?.id || '')}
        disabled={formDisabled}
        id="servicePartners"
        items={transformPartners(partners || [])}
        placeholder="Select one"
        setValue={(value) => {
          const filteredPartner = partners?.find((item) => item.id === Number(value));
          setValue('servicePartners', filteredPartner?.id || undefined);
        }}
        {...register('servicePartners')}
      />
      <Button onClick={handleClick} type="button" variant="ghost">
        {formDisabled ? 'Edit' : 'Save'}
      </Button>
    </Box.form>
  );
};
