import { isBoolean, isEmpty, isNumber } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { QueryParams } from '../types/QueryParams';
import qs from 'query-string';

type UseQueryParams = {
  readonly queryParams: QueryParams;
  readonly addQueryParam: (key: string, value: string) => void;
  readonly removeQueryParam: (key: string) => void;
  readonly updateQueryParams: (queryParams: QueryParams, clear?: boolean) => void;
};

const paramsToObject = (entries: IterableIterator<[string, string]>): QueryParams => {
  let result = {};
  for (const [key, value] of entries) {
    result = { ...result, [key]: value };
  }
  return result;
};

export const useQueryParams = (): UseQueryParams => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [queryParams, setQueryParams] = useState<QueryParams>(paramsToObject(query.entries()));

  const matchesUrl = useMemo(() => {
    const queryParamsEntries = Object.entries(queryParams);
    const queryEntries: [string, string][] = [];

    for (const entry of query.entries()) {
      queryEntries.push(entry);
    }

    if (queryEntries.length !== queryParamsEntries.length) {
      return false;
    }

    return queryParamsEntries.every(([key, value]) => {
      return queryEntries.find(
        ([entryKey, entryValue]) => entryKey === key && entryValue === value,
      );
    });
  }, [queryParams, query]);

  useEffect(() => {
    if (!matchesUrl) {
      const newQueryParams = paramsToObject(query.entries());
      setQueryParams(newQueryParams);
    }
  }, [query, matchesUrl]);

  const replaceURLParams = () => {
    history.replace({
      pathname,
      search: qs.stringify(paramsToObject(query.entries()), {
        arrayFormat: 'comma',
        encode: false,
      }),
    });
    setQueryParams(paramsToObject(query.entries()));
  };

  const replaceQueryParam = (key: string, value: unknown) => {
    if (query.get(key)) query.delete(key);
    query.append(key, String(value));
  };

  const updateQueryParams = (queryParams: QueryParams, forceClear?: boolean) => {
    if (forceClear) {
      const params = Array.from(query.entries());
      for (const [key] of params) {
        query.delete(key);
      }
    }

    for (const [key, value] of Object.entries(queryParams)) {
      if (isNumber(value) || isBoolean(value) || !isEmpty(value)) {
        replaceQueryParam(key, value);
      } else {
        query.delete(key);
      }
    }

    replaceURLParams();
  };

  const removeQueryParam = (key: string) => {
    query.delete(key);
    replaceURLParams();
  };

  const addQueryParam = (key: string, value: string) => {
    replaceQueryParam(key, value);
    replaceURLParams();
  };

  return { addQueryParam, queryParams, removeQueryParam, updateQueryParams };
};
