import { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';
import { formatDocument } from './formatComponents';
import { StepComponent } from 'modules/pipelines/types/StepComponent';

export const formatDocumentsList = (
  docComponents: StepComponent[],
  codesUploaded: string[],
  caseId: number,
): DocumentWithUrl[] => {
  return docComponents.map((component) => {
    const { internal_reference_code, content } = component;

    return formatDocument(codesUploaded, internal_reference_code || '', content || '', caseId);
  });
};
