import { Filter } from 'modules/filters/types/Filter';

export const SIGN_UP_STATUS_FILTER: Filter<string> = {
  attribute: 'sign_up_status',
  label: 'Sign-up status',
  options: [
    {
      filterAttribute: 'sign_up_status',
      label: 'All',
      value: '',
      userTypes: ['admin', 'hr'],
    },
    {
      filterAttribute: 'sign_up_status',
      label: 'Active',
      value: 'active',
      userTypes: ['admin', 'hr'],
    },
    {
      filterAttribute: 'sign_up_status',
      label: 'Inactive',
      value: 'inactive',
      userTypes: ['admin', 'hr'],
    },
  ],
  type: 'singleselect',
  userTypes: ['admin', 'hr'],
};
