import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import type { AdditionalService } from 'modules/additionalServices/types/AdditionalService';

export type Status = 'booked' | 'canceled' | 'completed' | 'in_progress';

export type CaseAdditionalService = {
  id: number;
  additional_service: AdditionalService;
  status: Status;
  case_id: number;
  marketplace_order_id: Maybe<number>;
};

export const CASE_ADDITIONAL_SERVICES_QUERY_KEY = 'case-additional-services';

const getCaseAdditionalServices = async (caseId: number): Promise<CaseAdditionalService[]> => {
  const { data } = await axios.get<CaseAdditionalService[]>('/case_additional_services', {
    params: {
      case_id: caseId,
    },
  });
  return data;
};

export const useCaseAdditionalServices = ({
  caseId,
  select,
}: {
  caseId: number;
  select?: (data: CaseAdditionalService[]) => CaseAdditionalService[];
}): UseQueryResult<CaseAdditionalService[], AxiosError> => {
  return useQuery({
    queryKey: [CASE_ADDITIONAL_SERVICES_QUERY_KEY, String(caseId)],
    queryFn: () => getCaseAdditionalServices(caseId),
    throwOnError: false,
    select,
  });
};
