import { CreditReason } from 'modules/billing/types/CreditReason';

export const CREDIT_PERCENTAGES = [
  { label: '0%', value: '0' },
  { label: '25%', value: '.25' },
  { label: '50%', value: '.50' },
  { label: '75%', value: '.75' },
  { label: '100%', value: '1' },
];

export const CREDIT_REASON_LABELS = {
  [CreditReason.CASE_STOPPED]: 'Case stopped',
  [CreditReason.DSAT]: 'DSAT',
  [CreditReason.LOCALYZE_MISTAKE]: 'Localyze mistake',
  [CreditReason.ADDITIONAL_SERVICE_NOT_USED]: 'Additional service not used',
  [CreditReason.INCLUDED_IN_SUBSCRIPTION]: 'Included in subscription',
  [CreditReason.OTHER]: 'Other',
};

export const CREDIT_REASONS = Object.entries(CREDIT_REASON_LABELS).map(([key, value]) => ({
  label: value,
  value: key,
}));
