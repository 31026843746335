import { deletePipelineStep, putPipelineStepOrder } from 'deprecated/api/steps';
import { postPipeline, putPipeline } from 'deprecated/api/pipelines';

import { getCaseTypes } from 'deprecated/api/general';
import { getPipelines } from 'modules/caseDetails/hooks/usePipelines';

export const VALUE_FOR_NULL_DEST_COUNTRY = 0;

const buildStep = ({ id, pipeline_step_id, title, required, position }) => ({
  id,
  pipeline_step_id,
  title,
  position,
  required,
});

export class HandlerLocalStorePE {
  constructor() {
    this.itemName = 'statePE';
    this.initState = {
      selectedPipelineId: 0,
      selectedDestCountryId: VALUE_FOR_NULL_DEST_COUNTRY,
    };
  }

  retrieveStatePE = () => {
    const retrievedObject = localStorage.getItem(this.itemName) || JSON.stringify(this.initState);
    return JSON.parse(retrievedObject);
  };

  storeStatePE = ({ selectedPipelineId, selectedDestCountryId }) => {
    const statePE = { selectedPipelineId, selectedDestCountryId: selectedDestCountryId };
    localStorage.setItem(this.itemName, JSON.stringify(statePE));
  };
}

export const buildPipeline = ({
  id,
  title,
  price,
  destination_country = {},
  pipeline_steps = [],
}) => {
  return {
    id,
    title,
    price,
    destinationCountryId: [null].includes(destination_country)
      ? VALUE_FOR_NULL_DEST_COUNTRY
      : destination_country.id,
    steps: pipeline_steps.map(({ id: pipeline_step_id, position, step }) =>
      buildStep({ pipeline_step_id, position, ...step }),
    ),
  };
};

export const fetchAllPipelines = async ({ callbackSuccess }) => {
  const allPipelinesRaw = await getPipelines();

  const allCountriesWithDestination = allPipelinesRaw
    .map((pipe) => pipe.destination_country)
    .filter((country) => country)
    .reduce((acc, country) => {
      if (acc.map((ee) => ee.id).includes(country.id)) return acc;

      return acc.concat([country]);
    }, [])
    .map((country) => ({ value: country.id, label: country.name }))
    .concat([{ value: VALUE_FOR_NULL_DEST_COUNTRY, label: 'None' }]);

  const buildPipelines = () => allPipelinesRaw.map((pipe) => buildPipeline(pipe));

  callbackSuccess({
    pipelines: buildPipelines(),
    destinationCountries: allCountriesWithDestination,
  });
};

export const submitUpdatePipeline = async ({ pipelineData, callbackSuccess, callbackError }) => {
  try {
    const { id, title } = pipelineData;
    await putPipeline({ id, title });
    callbackSuccess({ id });
  } catch (e) {
    callbackError();
  }
};

export const submitUpdatePipelineStepOrder = async ({
  pipelineStepId,
  newPosition,
  callbackSuccess,
  callbackError,
}) => {
  try {
    const { data } = await putPipelineStepOrder({ pipelineStepId, newPosition });
    callbackSuccess(data);
  } catch (e) {
    callbackError();
  }
};

export const submitDeletePipelineStep = async ({ pipelineStepId, callbackSuccess }) => {
  const { data } = await deletePipelineStep({ pipelineStepId });

  callbackSuccess(data);
};

export const submitAddPipeline = async ({ newPipelineData, callbackSuccess }) => {
  const { title, destinationCountryId, caseTypeId } = newPipelineData;
  const newPipeLine = await postPipeline({ title, destinationCountryId, caseTypeId });

  callbackSuccess({ newPipeLine });
};

export const fetchNewPipelineData = async ({ callbackSuccess }) => {
  const caseTypesBE = await getCaseTypes();
  const caseTypes = caseTypesBE.map(({ id: value, name: label }) => ({ value, label }));

  callbackSuccess({ caseTypes });
};
