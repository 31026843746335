import axios from 'axios';
import { StatusUpdate } from 'modules/statusUpdates/types/StatusUpdate';
import { StatusUpdateSubjectType } from 'modules/statusUpdates/types/StatusUpdateSubjectType';

type GetStatusUpdatesParams = {
  subjectType?: StatusUpdateSubjectType;
  subjectId?: number;
};

export const getStatusUpdates = async ({
  subjectType,
  subjectId,
}: GetStatusUpdatesParams = {}): Promise<StatusUpdate[]> => {
  const params =
    subjectType || subjectId
      ? {
          subject_type: subjectType,
          subject_id: subjectId,
        }
      : {};

  const response = await axios.get<StatusUpdate[]>('/status_updates', {
    params,
  });

  return response.data;
};
