import * as Yup from 'yup';

import { Box, Button } from '@localyze-pluto/components';
import { Form, Formik } from 'formik';

import FormFields from 'deprecated/components/FormFields';
import { P } from 'deprecated/components/Fonts';
import PropTypes from 'prop-types';
import React from 'react';
import { Validation } from 'constants/formValidations';
import { filterByComponentTypeAndCountry } from '../helpers/filterByComponentTypeAndCountry';
import { uniqBy } from 'lodash';

const variableTitleFormat = (title, type) => `${title} (${type})`;

export default function InternalVariable({
  component,
  internalVariables,
  pipeline,
  handleSave,
  buttons,
  index,
  onMoveUp,
  onMoveDown,
}) {
  const { id, editing, content, componentType } = component;

  const filteredVariables = filterByComponentTypeAndCountry({
    componentType,
    internalVariables,
    pipeline,
  });

  const internalVariableOptions = filteredVariables.map(({ code, title, variable_type }) => ({
    value: code,
    label: variableTitleFormat(title, variable_type),
  }));

  const initialValue = () => {
    if (!content) return '';

    return internalVariables.find((variable) => variable.code === content)?.code || '';
  };

  const validationSchema = Yup.object({
    inputValue: Validation.autoComplete,
  });

  const capitalize = (string) => string.replace(/^\w/, (c) => c.toUpperCase());

  const handleSubmit = ({ inputValue }) => {
    const componentData = {
      id,
      componentType,
      content: inputValue,
    };

    handleSave(componentData, index);
  };

  return (
    <Box.div display="flex" gap="d4" data-testid="step-component">
      <Box.div display="flex">
        <Box.div>
          <Button
            iconOnly
            variant="ghost"
            aria-label="Move up"
            leadingIcon="ArrowSmallUpIcon"
            onClick={() => onMoveUp(id)}
            disabled={editing}
          />
          <Button
            iconOnly
            variant="ghost"
            aria-label="Move down"
            leadingIcon="ArrowSmallDownIcon"
            onClick={() => onMoveDown(id)}
            disabled={editing}
          />
        </Box.div>
      </Box.div>

      <Box.div flexGrow={1}>
        <P
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
          }}
        >
          {capitalize(componentType)}
        </P>
        <Formik
          initialValues={{ inputValue: initialValue() }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <Box.div display="flex" gap="d5" justifyContent="space-between">
              <Box.div flexBasis="60%">
                <FormFields
                  disabled={!editing}
                  fieldType="autoComp"
                  name="inputValue"
                  label="Select the internal variable"
                  options={uniqBy(internalVariableOptions, (item) => item.value && item.label)}
                />
              </Box.div>
              {editing && buttons}
            </Box.div>
          </Form>
        </Formik>
      </Box.div>
    </Box.div>
  );
}

InternalVariable.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttons: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  component: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    editing: PropTypes.bool,
    content: PropTypes.string,
    componentType: PropTypes.string,
  }),
  internalVariables: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.string,
      variable_type: PropTypes.string,
      internal_references: PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
      }),
      destination_country: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        code: PropTypes.string,
      }),
      origin_country: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        code: PropTypes.string,
      }),
    }),
  ),
  handleSave: PropTypes.func,
};
