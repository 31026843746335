import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { isNaN, isUndefined } from 'lodash';

export type EstimatedCaseDuration = {
  estimated_case_duration_in_days: Maybe<number>;
};

export const getEstimatedCaseDuration = async (
  pipelineId: number,
  originCountryId: number,
): Promise<EstimatedCaseDuration> => {
  const params = { pipeline_id: pipelineId, origin_country_id: originCountryId };
  const url = '/admin/estimated_case_durations';

  const { data } = await axios.get<EstimatedCaseDuration>(url, { params });

  return data;
};

export const useEstimatedCaseDuration = ({
  pipelineId,
  originCountryId,
}: {
  pipelineId?: number;
  originCountryId?: number;
}): UseQueryResult<EstimatedCaseDuration> => {
  return useQuery({
    queryKey: ['estimated-case-duration', pipelineId, originCountryId],
    queryFn: () => getEstimatedCaseDuration(Number(pipelineId), Number(originCountryId)),
    enabled: !(isUndefined(pipelineId) || isNaN(pipelineId) || isUndefined(originCountryId)),
    throwOnError: false,
  });
};
