import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalStore,
  Paragraph,
  useToast,
} from '@localyze-pluto/components';
import React, { useEffect, useMemo } from 'react';

import { AdditionalServiceOptions } from 'modules/additionalServices/components/AdditionalServiceOptions/AdditionalServiceOptions';
import { AdditionalServicesSelectionForm } from 'modules/additionalServices/types/AdditionalServicesSelectionForm';
import { useForm } from 'react-hook-form';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCreateCaseAdditionalService } from 'modules/additionalServices/api/useCreateCaseAdditionalService/useCreateCaseAdditionalService';
import { useDeleteCaseAdditionalService } from 'modules/additionalServices/api/useDeleteCaseAdditionalService/useDeleteCaseAdditionalService';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCaseAdditionalServices } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { useAdditionalServices } from 'modules/additionalServices/api/useAdditionalServices/useAdditionalServices';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { useCase } from 'modules/cases/api/useCase/useCase';

type EditAdditionalServicesModalProps = {
  modalState: ModalStore;
  caseId: number;
};

export const EditAdditionalServicesModal = ({
  caseId,
  modalState,
}: EditAdditionalServicesModalProps): React.JSX.Element => {
  const toast = useToast();
  const { isPending: isPendingKase, data: kase } = useCase({ caseId });

  const { isSuccess: isCaseAdditionalServicesSuccess, data: caseAdditionalServices } =
    useCaseAdditionalServices({ caseId });

  const { isSuccess: isAdditionalServicesSuccess, data: availableAdditionalServices } =
    useAdditionalServices({
      destinationCountryId: kase?.destination_country_id,
      enabled: !!kase,
    });

  const { mutateAsync: addCaseAdditionalService, isPending: isAdding } =
    useCreateCaseAdditionalService();
  const { mutateAsync: deleteCaseAdditionalService, isPending: isDeleting } =
    useDeleteCaseAdditionalService();

  const isSubmitting = isAdding || isDeleting;

  const defaultValues = useMemo(() => {
    if (!availableAdditionalServices || !caseAdditionalServices) {
      return {};
    }

    const allAdditionalServices = uniqBy(
      [...availableAdditionalServices, ...map(caseAdditionalServices, 'additional_service')],
      'id',
    );

    return allAdditionalServices.reduce(
      (acc, service) => {
        const isServiceChecked = caseAdditionalServices.some(
          (caseAdditionalService) => caseAdditionalService.additional_service.id === service.id,
        );

        acc[service.id] = isServiceChecked;

        return acc;
      },
      {} as AdditionalServicesSelectionForm['additionalServices'],
    );
  }, [availableAdditionalServices, caseAdditionalServices]);

  const { handleSubmit, control, reset } = useForm<AdditionalServicesSelectionForm>({
    defaultValues: {
      additionalServices: defaultValues,
    },
    mode: 'onTouched',
  });

  const onSubmit = async (payload: AdditionalServicesSelectionForm) => {
    try {
      const updatePromises: Promise<unknown>[] = [];

      Object.entries(payload.additionalServices).forEach(([serviceId, isIncluded]) => {
        // Only update the service if the user changed the value
        const isServiceOriginallyEnabled = defaultValues[serviceId];
        const updateCaseAdditionalService = isIncluded
          ? addCaseAdditionalService
          : deleteCaseAdditionalService;
        const caseAdditionalServiceId = caseAdditionalServices?.find(
          (caseAdditionalService) =>
            caseAdditionalService.additional_service.id === Number(serviceId),
        )?.id;

        if (isServiceOriginallyEnabled !== isIncluded && kase) {
          updatePromises.push(
            updateCaseAdditionalService({
              kase,
              additionalServiceId: Number(serviceId),
              caseAdditionalServiceId: Number(caseAdditionalServiceId),
            }),
          );
          trackEvent('additional service: book additional service', {
            booked_on: 'talent profile',
            additional_service_id: serviceId,
            case_id: String(caseId),
          });
        }
      });

      await Promise.all(updatePromises);

      modalState.toggle();
    } catch (error) {
      toast('There was an error saving changes.', 'error');
      rollbarInstance.error(error as Error);
    }
  };

  const hasAvailableOrExistingAdditionalServices =
    (availableAdditionalServices?.length ?? 0) + (caseAdditionalServices?.length ?? 0) > 0;

  useEffect(() => {
    // Additional services might have been fetched after the default values of the
    // form was initialized so must reset if that changes
    reset({ additionalServices: defaultValues });
  }, [defaultValues, reset]);

  if (isPendingKase) {
    return <ContainedLoadingState />;
  }

  return (
    <Modal store={modalState}>
      <ModalHeader>
        <ModalHeading>Edit additional services</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form id="editAdditionalServices" onSubmit={handleSubmit(onSubmit)}>
          {isCaseAdditionalServicesSuccess &&
          isAdditionalServicesSuccess &&
          hasAvailableOrExistingAdditionalServices ? (
            <AdditionalServiceOptions
              availableAdditionalServices={availableAdditionalServices}
              caseAdditionalServices={caseAdditionalServices}
              control={control}
            />
          ) : isAdditionalServicesSuccess &&
            isCaseAdditionalServicesSuccess &&
            !hasAvailableOrExistingAdditionalServices ? (
            <Box.div
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Paragraph marginBottom="m0">No additional services available</Paragraph>
            </Box.div>
          ) : (
            <Box.div alignItems="center" display="flex" flexDirection="column">
              <ContainedLoadingState />
            </Box.div>
          )}
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isSubmitting}
          onClick={modalState.toggle}
          type="button"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          form="editAdditionalServices"
          loading={isSubmitting}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
