import { AvatarAndNameCell } from './cells/AvatarCell';
import { CommunicationPriorityCell } from './cells/CommunicationPriorityCell';
import { DateCell } from './cells/DateCell';
import { MessageIndicator } from './cells/MessageIndicator';
import { ProgressCell } from './cells/ProgressCell';
import React from 'react';
import { StatusLastUpdatedAtCell } from './cells/StatusLastUpdatedAtCell';
import { TextCell } from './cells/TextCell';
import { Tr } from '@localyze-pluto/components';
import { InternalNoteCell } from './cells/InternalNoteCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';
import { LastUpdateCell } from './cells/LastUpdateCell';
import { CaseStatus } from './cells/CaseStatus';
import { useCurrentUser } from 'config/CurrentUserContext';
import { ServiceLevelCell } from './cells/ServiceLevelCell';

export const HRRowContent = ({
  caseOverview,
}: {
  caseOverview: CaseOverview;
}): React.JSX.Element => {
  const {
    current_pipeline_step_title: stepTitle,
    completion_percentage: completionPercentage,
    start_date: startDate,
    last_status_update_message: lastUpdateMessage,
    office_name: officeName,
    case_type_name: caseTypeName,
    waiting_until: waitingUntil,
  } = caseOverview;

  return (
    <>
      <TextCell caseOverview={caseOverview} text={officeName} />
      <DateCell caseOverview={caseOverview} date={startDate} />
      <TextCell caseOverview={caseOverview} text={caseTypeName} />
      <TextCell caseOverview={caseOverview} text={stepTitle} />
      <LastUpdateCell
        caseOverview={caseOverview}
        lastUpdateMessage={lastUpdateMessage}
        waitingUntil={waitingUntil}
      />
      <CaseStatus caseOverview={caseOverview} />
      <ProgressCell caseOverview={caseOverview} percentage={completionPercentage} />
    </>
  );
};

export const AdminRowContent = ({
  caseOverview,
}: {
  caseOverview: CaseOverview;
}): React.JSX.Element => {
  const {
    company_name: companyName,
    communication_priority: communicationPriority,
    current_pipeline_step_title: stepTitle,
    start_date: startDate,
    last_internal_message: internalMessage,
    last_message_from_hr: lastMessageFromHR,
    last_status_update_message: lastUpdateMessage,
    case_type_name: caseTypeName,
    status_last_updated_at: statusLastUpdatedAt,
    waiting_until: waitingUntil,
    last_internal_message_pinned: isLastInternalMessagePinned,
  } = caseOverview;

  return (
    <>
      <CommunicationPriorityCell caseOverview={caseOverview} priority={communicationPriority} />
      <TextCell caseOverview={caseOverview} isAdmin text={companyName} />
      <DateCell caseOverview={caseOverview} date={startDate} isAdmin />
      <TextCell caseOverview={caseOverview} isAdmin text={caseTypeName} />
      <TextCell caseOverview={caseOverview} isAdmin text={stepTitle} />
      <LastUpdateCell
        caseOverview={caseOverview}
        isAdmin
        lastUpdateMessage={lastUpdateMessage}
        waitingUntil={waitingUntil}
      />
      <InternalNoteCell
        caseOverview={caseOverview}
        isAdmin
        isPinned={isLastInternalMessagePinned}
        text={internalMessage}
      />
      <StatusLastUpdatedAtCell
        caseOverview={caseOverview}
        isAdmin
        statusLastUpdatedAt={statusLastUpdatedAt}
      />
      <MessageIndicator caseOverview={caseOverview} show={lastMessageFromHR} />
    </>
  );
};

export const CaseOverviewRow = ({
  caseOverview,
}: {
  caseOverview: CaseOverview;
}): React.JSX.Element => {
  const { isAdmin, isHrViewer } = useCurrentUser();

  const { talent_name, case_id } = caseOverview;

  return (
    <Tr hasHover isClickable={!isHrViewer}>
      {isAdmin && <ServiceLevelCell caseOverview={caseOverview} />}
      <AvatarAndNameCell caseOverview={caseOverview} isAdmin={isAdmin} text={talent_name} />
      <TextCell caseOverview={caseOverview} text={`#${String(case_id)}`} />
      {isAdmin ? (
        <AdminRowContent caseOverview={caseOverview} />
      ) : (
        <HRRowContent caseOverview={caseOverview} />
      )}
    </Tr>
  );
};
