import cx from 'clsx';
import React from 'react';
import { SelectMenuOption } from '../SelectMenu';
import { ErrorMessage } from './ErrorMessage';

export type WithSelectMenuProps = {
  disabled: boolean;
  children: React.JSX.Element | (({ open }: { open: boolean }) => React.JSX.Element);
  onChange: (value: SelectMenuOption) => void;
  value: SelectMenuOption | string | undefined;
};

type SelectMenuProps = WithSelectMenuProps & {
  hasCursorPointer: boolean;
  hasError?: boolean;
  errorMessage?: string;
  errorEnabled?: boolean;
};

export const withSelectMenu = (
  WrappedComponent: React.ComponentType<WithSelectMenuProps>,
): React.ComponentType<SelectMenuProps> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithSelectMenu = ({
    disabled,
    onChange,
    value,
    children,
    hasCursorPointer,
    hasError = false,
    errorMessage = '',
    errorEnabled = false,
    ...props
  }: SelectMenuProps): React.JSX.Element => (
    <div {...props}>
      <WrappedComponent disabled={disabled} onChange={onChange} value={value || ''}>
        {({ open }) => (
          <div className={cx('tw-relative', { 'tw-cursor-pointer': hasCursorPointer })}>
            {typeof children === 'function' ? children({ open }) : children}
          </div>
        )}
      </WrappedComponent>
      {errorEnabled && <ErrorMessage errorMessage={errorMessage} hasError={hasError} />}
    </div>
  );

  ComponentWithSelectMenu.displayName = `withSelectMenu(${displayName})`;

  return ComponentWithSelectMenu;
};
