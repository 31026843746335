import { P2 } from 'deprecated/components/Fonts';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export default function InfoBlock({ info }) {
  return (
    <InfoBlockWrapper>
      <P2>{info}</P2>
    </InfoBlockWrapper>
  );
}

const InfoBlockWrapper = styled.div`
  padding: 15px;
  border: 1px solid var(--primary-color-full);

  svg {
    width: 40px;
    height: 40px;
  }
`;

InfoBlock.defaultProps = {
  info: '',
};

InfoBlock.propTypes = {
  info: PropTypes.string,
};
