import React from 'react';

import { ReactComponent as BambooHrLogoComponent } from 'assets/img/bamboohr-logo.svg';
import { ReactComponent as PersonioLogoComponent } from 'assets/img/personio-logo.svg';
import { ReactComponent as WorkdayLogoComponent } from 'assets/img/workday-logo.svg';
import { ReactComponent as HiBobLogoComponent } from 'assets/img/hibob-logo.svg';
import { ReactComponent as SAPLogoComponent } from 'assets/img/sap-logo.svg';

import BambooHrLogo from 'assets/img/integrations/bamboo.jpg';
import PersonioLogo from 'assets/img/integrations/personio.jpg';
import WorkdayLogo from 'assets/img/integrations/workday.jpg';
import HiBobLogo from 'assets/img/integrations/hibob.jpg';
import SAPLogo from 'assets/img/integrations/sap.jpg';

import {
  StackOneCompanyIntegration,
  StackOneProvider,
} from 'modules/integrations/types/HRISCompanyIntegration';
import { PROVIDER_NAME } from 'modules/integrations/constants/providerName';

export type IntegrationsModalFields = {
  label: string;
  name: string;
  required?: boolean;
  placeholder?: string;
};

export type IntegrationStatus = 'active' | 'inactive';

export type StackOneIntegration = {
  id: string;
  providerName: StackOneProvider;
  label: string;
  status: IntegrationStatus;
  logo: React.JSX.Element;
  logoUrl: string;
};

export const STACK_ONE_INTEGRATIONS: StackOneIntegration[] = [
  {
    label: PROVIDER_NAME.bamboohr,
    id: 'bamboohr',
    providerName: 'bamboohr',
    status: 'inactive',
    logo: <BambooHrLogoComponent />,
    logoUrl: BambooHrLogo,
  },
  {
    label: PROVIDER_NAME.personio,
    id: 'personio',
    providerName: 'personio',
    status: 'inactive',
    logo: <PersonioLogoComponent />,
    logoUrl: PersonioLogo,
  },
  {
    label: PROVIDER_NAME.workday,
    id: 'workday',
    status: 'inactive',
    providerName: 'workday',
    logo: <WorkdayLogoComponent />,
    logoUrl: WorkdayLogo,
  },
  {
    label: PROVIDER_NAME.hibob,
    id: 'hibob',
    status: 'inactive',
    providerName: 'hibob',
    logo: <HiBobLogoComponent />,
    logoUrl: HiBobLogo,
  },
  {
    label: PROVIDER_NAME.sapsuccessfactors,
    id: 'sapsuccessfactors',
    status: 'inactive',
    providerName: 'sapsuccessfactors',
    logo: <SAPLogoComponent />,
    logoUrl: SAPLogo,
  },
];

export const getStackOneIntegrationCardSettings = (
  activeIntegration?: StackOneCompanyIntegration,
): StackOneIntegration[] => {
  return STACK_ONE_INTEGRATIONS.map((integration) => {
    if (integration.providerName !== activeIntegration?.provider_data.stackone_provider_name) {
      return integration;
    }

    return {
      ...integration,
      providerData: {
        ...activeIntegration.provider_data,
      },
      status: 'active',
    };
  });
};
