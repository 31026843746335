import axios, { AxiosError, AxiosPromise } from 'axios';
import { PipelineStep } from 'modules/pipelines/types/PipelineStep';
import { Step } from 'modules/pipelines/types/Step';
import { StepComponentResponse } from './types/StepComponentResponse';

type CustomError = AxiosError & {
  response?: {
    data?: {
      message?: string;
    };
  };
};

export const getStep = async (stepId: number): Promise<Step> => {
  try {
    const { data } = await axios.get<Step>(`/admin/steps/${stepId}`);
    return data;
  } catch (e) {
    throw new Error('Something is wrong getting the step data. Hold on there or send us an email');
  }
};

export const putPipelineStepOrder = async ({
  pipelineStepId,
  newPosition = 1,
}: {
  pipelineStepId: number;
  newPosition: number;
}): AxiosPromise<PipelineStep> => {
  const dataToSend = {
    position: newPosition,
  };

  try {
    const response = await axios.put<PipelineStep>(
      `/admin/pipeline_steps/${pipelineStepId}`,
      dataToSend,
    );
    return response;
  } catch (e) {
    throw new Error(
      'Something is wrong with our services updating the pipeline step order.' +
        ' Hold on there or send us an email',
    );
  }
};

export const deletePipelineStep = async ({
  pipelineStepId,
}: {
  pipelineStepId: number;
}): AxiosPromise<void> => {
  try {
    return axios.delete(`/admin/pipeline_steps/${pipelineStepId}`);
  } catch (e) {
    throw new Error(
      'Something is wrong with our services deleting pipeline step.' +
        ' Hold on there or send us an email',
    );
  }
};

export const getStepComponents = async (stepId: number): Promise<StepComponentResponse[]> => {
  try {
    const { data } = await axios.get<StepComponentResponse[]>(`/admin/steps/${stepId}/components`);
    return data;
  } catch (e) {
    throw new Error(
      'Something is wrong getting the step component data. Hold on there or send us an email',
    );
  }
};

export const postStepComponent = async ({
  stepId,
  componentType,
  content = '',
}: {
  stepId: number;
  componentType: string;
  content: string;
}): Promise<StepComponentResponse> => {
  const dataToSend = {
    component_type: componentType,
    content,
  };

  try {
    const { data } = await axios.post<StepComponentResponse>(
      `/admin/steps/${stepId}/components`,
      dataToSend,
    );
    return data;
  } catch (e) {
    throw new Error(
      'Something is wrong with our services creating the step component.' +
        ' Hold on there or send us an email',
    );
  }
};

export const putStepComponent = async ({
  stepId,
  componentId,
  componentType,
  content = '',
}: {
  stepId: number;
  componentId: number;
  componentType: string;
  content: string;
}): Promise<StepComponentResponse> => {
  const dataToSend = {
    component_type: componentType,
    content,
  };

  try {
    const { data } = await axios.put<StepComponentResponse>(
      `/admin/steps/${stepId}/components/${componentId}`,
      dataToSend,
    );
    return data;
  } catch (e) {
    throw new Error(
      'Something is wrong with our services updating the step component.' +
        ' Hold on there or send us an email',
    );
  }
};

export const deleteStepComponent = async ({
  stepId,
  componentId,
}: {
  stepId: number;
  componentId: number;
}): AxiosPromise<void> => {
  try {
    return axios.delete(`/admin/steps/${stepId}/components/${componentId}`);
  } catch (error: unknown) {
    const err = error as CustomError;
    const message =
      err.response?.data?.message ||
      'Something is wrong with our services deleting the step component.';

    throw new Error(message);
  }
};
