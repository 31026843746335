import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';

import { AddOfficePayload, AddOfficeProps, AddOfficeRequestPayload } from './useAddOffice.types';
import { OfficeWithOfficeInformation } from 'deprecated/api/companies/types/OfficeAPIResponse';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

export const formattedOfficePayload = (payload: AddOfficePayload): AddOfficeRequestPayload => {
  const formattedPayload: AddOfficeRequestPayload = {
    office: {
      name: payload.registeredCompanyName,
      city: payload.city,
      country_id: payload.countryId,
      legal_representative: payload.legalRepresentative,
      office_information_attributes: {
        company_legal_form: payload.legalForm,
        registration_number: payload.companyRegistrationNumber,
        commercial_registration_number: payload.commercialRegistrationNumber,
        competent_court: payload.competentCourt,
        average_employee_count: payload.averageEmployeeCount,
        eu_employee_count: payload.euEmployeeCount,
        non_eu_employee_count: payload.nonEUEmployeeCount,
        no_social_security_contributions: payload.noSocialSecurityContributions,
        no_criminal_proceedings: payload.noCriminalProceedings,
        postal_code: payload.postalCode,
        no_insolvency_proceedings: payload.noInsolvencyProceedings,
        house_num: payload.streetNumber,
        street: payload.street,
        founding_year: payload.foundingYear,
      },
    },
  };
  return formattedPayload;
};

/**
 * Makes a post request to office endpoint
 * @param props.payload - new office location details
 * @param props.companyId - company to create new office location for
 * @returns new office location
 */
const addOffice = async ({
  payload,
  companyId,
}: AddOfficeProps): Promise<OfficeWithOfficeInformation> => {
  const formattedPayload = formattedOfficePayload(payload);

  const { data } = await axios.post<OfficeWithOfficeInformation>(
    `/companies/${companyId}/offices`,
    formattedPayload,
    { headers: { Accept: 'application/json' } },
  );
  return data;
};

/**
 * Create a new office location for a company
 */
export const useAddOffice = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<OfficeWithOfficeInformation, AxiosErrorWithMessage, AddOfficeProps>,
  'onError' | 'onSuccess'
>): UseMutationResult<OfficeWithOfficeInformation, AxiosErrorWithMessage, AddOfficeProps> =>
  useMutation({
    mutationFn: addOffice,
    onSuccess,
    onError,
  });
