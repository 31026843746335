import { Listbox } from '@headlessui/react';
import cx from 'clsx';
import React, { HTMLAttributes } from 'react';
import { SelectMenuProps } from '../types/SelectMenuProps';
import { VariantValues } from '../types/VariantValues';
import { Arrow } from './Arrow';
import { DisplayedValue } from './DisplayedValue';
import { FloatingLabel } from './FloatingLabel';
import { simpleSelectStyles } from './SelectMenu.styles';
import { withSelectMenu } from './withSelectMenu';
import { withSelectMenuOption } from './withSelectMenuOption';
import { withSelectMenuOptions } from './withSelectMenuOptions';

export type SimpleSelectProps = Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> &
  Omit<SelectMenuProps, 'error'>;

const SIMPLE_SELECT_VARIANTS: VariantValues = {
  borderless: {
    buttonClasses: 'tw-border-0 focus:tw-ring-0 tw-text-base focus:tw-font-medium tw-pl-0',
    errorMessageClasses: 'tw-text-red-600',
    showErrorMessage: false,
  },
  default: {
    buttonClasses: 'tw-border tw-border-solid focus:tw-ring-1 tw-pl-3',
    errorMessageClasses: '',
    showErrorMessage: true,
  },
};

const ListboxWrapper = withSelectMenu(Listbox);
const ListboxOption = withSelectMenuOption(Listbox.Option);
const ListboxOptions = withSelectMenuOptions(Listbox.Options);

export const SimpleSelect = ({
  variant = 'default',
  options,
  label,
  selected,
  onSelect,
  disabled = false,
  placeholder = ' ',
  getDisplayValue,
  backgroundColor = 'tw-bg-transparent',
  setHasFocus,
  hasCursorPointer = false,
  hasError = false,
  elementRef,
  errorMessage = '',
  ...props
}: SimpleSelectProps): React.JSX.Element => {
  const currentVariant = SIMPLE_SELECT_VARIANTS[variant];
  const displayValue =
    getDisplayValue !== undefined ? getDisplayValue(selected) : selected?.label || placeholder;
  const displayValueClassName = cx({
    [currentVariant.errorMessageClasses as string]: hasError,
  });

  return (
    <ListboxWrapper
      {...props}
      disabled={disabled}
      errorEnabled={!!currentVariant.showErrorMessage}
      errorMessage={errorMessage}
      hasCursorPointer={hasCursorPointer}
      hasError={hasError}
      onChange={onSelect}
      value={selected}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            className={cx(
              simpleSelectStyles.button,
              currentVariant.buttonClasses as string,
              backgroundColor,
              {
                'group-hover:tw-cursor-pointer': hasCursorPointer,
                'tw-border-red-600 focus:tw-border-red-600 focus:tw-ring-red-600 error': hasError,
                'tw-text-gray-500': disabled,
                'tw-text-gray-900': !disabled,
              },
            )}
            data-testid="select-menu-button"
            onBlur={() => setHasFocus && setHasFocus(false)}
            onClick={() => setHasFocus && setHasFocus(true)}
            ref={elementRef as React.Ref<HTMLButtonElement>}
            type="button"
          >
            <DisplayedValue className={displayValueClassName} label={displayValue} />
            <Arrow hasError={hasError} open={open} />
          </Listbox.Button>

          <FloatingLabel label={label} />
          <ListboxOptions options={options} show={open}>
            {(option) => (
              <ListboxOption
                disabled={disabled || false}
                key={`select-menu-option-${option.value}`}
                option={option}
              />
            )}
          </ListboxOptions>
        </>
      )}
    </ListboxWrapper>
  );
};
