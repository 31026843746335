import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { queryClient } from 'config/queryClient';
import { getStatusUpdatesQueryKey } from 'modules/statusUpdates/api/useStatusUpdates/useStatusUpdates';
import { getCaseQueryKey } from 'modules/cases/api/useCase/useCase';
import { StatusUpdate } from 'modules/statusUpdates/types/StatusUpdate';

type DeleteStatusUpdateProps = {
  statusUpdate: StatusUpdate;
};

async function deleteStatusUpdate(statusUpdate: StatusUpdate) {
  const response = await axios.delete(`/status_updates/${statusUpdate.id}`);

  return response;
}

export const useDeleteStatusUpdate = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, DeleteStatusUpdateProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, DeleteStatusUpdateProps> =>
  useMutation({
    mutationFn: ({ statusUpdate }) => deleteStatusUpdate(statusUpdate),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getStatusUpdatesQueryKey({
          subjectType: variables.statusUpdate.subject_type,
          subjectId: variables.statusUpdate.subject_id,
        }),
      });

      if (variables.statusUpdate.subject_type === 'Case') {
        await queryClient.invalidateQueries({
          queryKey: getCaseQueryKey(variables.statusUpdate.subject_id),
        });
      }

      onSuccess?.(data, variables, context);
    },
    onError,
  });
