import { PageLayout } from 'modules/layout/components/PageLayout';
import React, { useEffect } from 'react';
import { CaseOverviewsList } from '../components/CaseOverviewsList/CaseOverviewsList';
import { trackPageView } from 'modules/analytics/analytics';
import { TABS } from '../utils/constants';

export const CaseOverviews = (): React.JSX.Element => {
  useEffect(() => {
    trackPageView('Case Overviews');
  }, []);

  return (
    <PageLayout flexed title="My Cases">
      <CaseOverviewsList tabsList={TABS} />
    </PageLayout>
  );
};
