import React from 'react';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';
import { Badge, BoxProps } from '@localyze-pluto/components';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { getTalentStatusBadgeProps } from 'modules/talent/utils/getTalentStatusBadgeProps';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';

export const AccountInformation = ({
  talent,
  borderBottomStyle,
}: {
  talent: TalentWithPersonalInformation;
  borderBottomStyle?: BoxProps['borderBottomStyle'];
}): React.JSX.Element => {
  const { is_activated: isActive, created_at, signed_up_at } = talent;

  const { color, status } = getTalentStatusBadgeProps(isActive);

  const items = [
    {
      key: 'Talent status',
      value: <Badge color={color}>{status}</Badge>,
    },
    {
      key: 'Talent created on',
      value: formatDate(created_at),
    },
    {
      key: 'Talent signed up on',
      value: formatDate(signed_up_at),
    },
  ];

  return <KeyValueList borderBottomStyle={borderBottomStyle} items={items} />;
};
