import { Document } from 'modules/documents/api/attachments/types/Document';
import { getPreviewDownloadDocUrl } from '../international/getPreviewDownloadDocUrl';

export const DOCUMENT_URL_PREFIX =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';

export const formatUrlByType = (document: Document, caseId: number): string => {
  return document.type === 'Attachment'
    ? `${DOCUMENT_URL_PREFIX}/attachments/${document.id}`
    : getPreviewDownloadDocUrl(String(document.internal_reference_code), caseId);
};
