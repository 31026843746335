import React from 'react';
import {
  Box,
  Button,
  ControlledRadioGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  Radio,
} from '@localyze-pluto/components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DisclosureStore } from '@ariakit/react';

const CANCELLATION_REASONS = [
  'Duplicated item',
  'Test account',
  'Charge talent directly',
  'Incorrect charge',
  'Case cancelled',
];

const CancelEntryConfirmationDialog = ({
  onConfirm,
  state,
}: {
  onConfirm: (cancellation_reason: string) => void;
  state: DisclosureStore;
}): React.JSX.Element => {
  const schema: yup.ObjectSchema<{ cancellation_reason: string }> = yup.object().shape({
    cancellation_reason: yup.string().oneOf(CANCELLATION_REASONS).required(),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      cancellation_reason: CANCELLATION_REASONS[0],
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<{ cancellation_reason: string }> = ({ cancellation_reason }) => {
    onConfirm(cancellation_reason);
    state.toggle();
  };

  return (
    <Modal store={state}>
      <ModalHeader>
        <ModalHeading>Are you sure?</ModalHeading>
      </ModalHeader>
      <Box.form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Paragraph marginBottom="m6" size="large">
            You are about to decline this charge. This action cannot be undone and the item will no
            longer appear on the billed invoice. Please select the reason why you want to decline
            this charge.
          </Paragraph>
          <Box.div paddingBottom="p4">
            <ControlledRadioGroup
              control={control}
              id="cancellation_reason"
              name="cancellation_reason"
              required
            >
              {CANCELLATION_REASONS.map((reason) => {
                return <Radio key={reason} label={reason} value={reason} />;
              })}
            </ControlledRadioGroup>
          </Box.div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={state.hide} type="button" variant="secondary">
            Cancel
          </Button>
          <Button name="decline" type="submit" variant="destructive">
            Decline account entry
          </Button>
        </ModalFooter>
      </Box.form>
    </Modal>
  );
};

export default CancelEntryConfirmationDialog;
