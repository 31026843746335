import React from 'react';

import { DocumentList } from './components/DocumentList';
import { DocumentListItem } from './components/DocumentListItem';
import { UploadDocument } from './components/UploadDocument';
import { sortDocuments } from 'deprecated/libs/fileUpload/sortDocuments';
import { Box, Button, Icon } from '@localyze-pluto/components';
import GoogleDriveImg from 'assets/img/google-drive.png';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { useDocuments } from './api/useDocuments/useDocuments';
import { buildDocumentsWithUrl } from './utils/buildDocumentsWithUrl';
import { DocumentFillingMenu } from 'modules/caseDetails/components/Documents/components/DocumentFillingMenu/DocumentFillingMenu';

export const Documents = ({ caseId }: { caseId: number }): React.JSX.Element => {
  const { data: kase } = useCase({ caseId });
  const { isAdmin } = useCurrentUser();
  const { data: documents, isPending: isPendingDocuments } = useDocuments(caseId);

  return (
    <DocumentList
      actions={
        <Box.div display="flex" gap="d2" paddingRight="p6">
          {isAdmin && kase && (
            <Button
              as="a"
              href={`https://drive.google.com/drive/folders/${kase.google_drive_folder_id}`}
              rel="noopener noreferrer"
              target="_blank"
              variant="ghost"
            >
              <Box.img height="auto" src={GoogleDriveImg} w="16px" />
              Go to Google Drive
            </Button>
          )}
          <UploadDocument caseId={caseId} />
          {isAdmin && <DocumentFillingMenu caseId={caseId} />}
        </Box.div>
      }
      title="Documents"
    >
      {isPendingDocuments ? (
        <Box.div alignItems="center" display="flex" justifyContent="center" padding="d10">
          <Icon
            className="tw-animate-spin tw-mx-auto tw-text-blue-500 tw-w-8 tw-h-8"
            data-testid="documents-loading-globe"
            decorative={false}
            icon="earth"
            size="sizeIcon60"
            title="Loading documents"
          />
        </Box.div>
      ) : (
        sortDocuments(buildDocumentsWithUrl(documents ?? [], caseId)).map((document, index) => (
          <DocumentListItem
            caseId={caseId}
            isLast={index === (documents?.length ?? 0) - 1}
            key={(document.id || document.docUrl) as string}
            {...document}
          />
        ))
      )}
    </DocumentList>
  );
};
