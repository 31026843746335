import { DialogStore } from '@ariakit/react';
import {
  Box,
  Button,
  ControlledFormInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type EditHiringManagerFormTypes = {
  hiringManagerName: string;
};

export const EditHiringManagerModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const { hiring_manager_name: hiringManagerName } = kase;
  const toast = useToast();
  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Hiring manager updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Hiring manager could not be updated', 'error'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: { hiringManagerName: hiringManagerName || '' },
  });

  const onSubmit: SubmitHandler<EditHiringManagerFormTypes> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { hiring_manager_name: values.hiringManagerName ? values.hiringManagerName : null },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Hiring Manager</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form id="edit-hiring-manager-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            id="hiringManagerName"
            label="Hiring Manager"
            name="hiringManagerName"
            type="text"
          />
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isPendingUpdateCase}
          form="edit-hiring-manager-form"
          loading={isPendingUpdateCase}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
