import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { GlomoRequestRisk } from '../api/types/GlomoRequestRisk';
import { CompleteGlomoRequestValues } from '../types/CompleteGlomoRequestValues';

export const getAdminFormInitialValues = (
  glomoRequest?: GlomoRequest,
): CompleteGlomoRequestValues => {
  const requiredActionsInitialValues = {
    a1_exemption_certificate: false,
    certificate_of_cover: false,
    consultation: false,
    consultation_text: '',
    documentation: false,
    employers_certificate: false,
    request_registration: false,
    express_processing: false,
    other: false,
    other_text: '',
    request_a1_certificate: false,
    visa: false,
  };

  if (!glomoRequest || glomoRequest.status === 'risk_assessment_in_progress') {
    return {
      riskLevel: '',
      riskAssessmentUrl: '',
      risksAndWarnings: [],
      report_pdf: '',

      ...requiredActionsInitialValues,
    };
  }

  const requiredActions = glomoRequest.required_actions.reduce((acc, cur) => {
    return {
      ...acc,
      [`${cur.action}`]: cur.value,
    };
  }, {});

  return {
    riskLevel: glomoRequest.risk_level as GlomoRequestRisk,
    riskAssessmentUrl: String(glomoRequest.risk_assessment_url),
    risksAndWarnings: glomoRequest.risks_and_warnings,
    report_pdf: JSON.stringify({
      metadata: {
        mime_type: 'application/pdf',
      },
      url: glomoRequest.report_pdf_url,
    }),

    ...requiredActionsInitialValues,

    ...requiredActions,
  };
};
