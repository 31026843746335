import { Box, useModalStore } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCaseInformations } from 'modules/cases/api/useCaseInformation/useCaseInformations';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';
import { EditRelevantDateModal } from 'modules/caseDetails/components/CaseDetailsCard/RelevantDates/EditRelevantDateModal';
import { CaseInformation } from 'modules/cases/types/CaseInformation';

export const RelevantDates = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const caseId = Number(id);

  const { data: appointments, isSuccess } = useCaseInformations({
    caseId,
    referenceType: 'datetime',
  });

  const [selectedAppointment, setSelectedAppointment] = useState<Maybe<CaseInformation>>(null);
  const modal = useModalStore();
  const isModalOpen = modal.useState('open');

  if (!isSuccess) {
    return <ContainedLoadingState />;
  }

  const items = appointments.map((appointment) => ({
    key: appointment.description,
    value: <Box.b>{formatDate(appointment.value)}</Box.b>,
    onEdit: () => {
      setSelectedAppointment(appointment);
      modal.show();
    },
  }));

  return (
    <>
      <KeyValueList borderBottomStyle="borderStyleSolid" items={items} py="d6" />
      {isModalOpen && selectedAppointment && (
        <EditRelevantDateModal caseId={caseId} date={selectedAppointment} modal={modal} />
      )}
    </>
  );
};
