import React from 'react';
import { Avatar, Badge, BadgeProps, Box, Button } from '@localyze-pluto/components';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { Link } from 'react-router-dom';
import { useCurrentUser } from 'config/CurrentUserContext';

export const Case = ({
  caseOverview,
  badge,
}: {
  badge: 'startDate' | 'waitingUntil';
  caseOverview: CaseOverview;
}): React.JSX.Element => {
  const { user_role, company_id } = useCurrentUser();
  const routes = useRoutes();

  const [badgeColor, badgeText]: [BadgeProps['color'], string] =
    badge === 'waitingUntil'
      ? ['yellow', `Waiting until: ${formatDate(caseOverview.waiting_until)}`]
      : ['red', `Start date: ${formatDate(caseOverview.start_date)}`];

  return (
    <Box.div
      alignItems="center"
      backgroundColor="bgSecondary"
      borderRadius="borderRadius20"
      display="flex"
      flexDirection="column"
      gap="d4"
      justifyContent="space-between"
      padding="p4"
    >
      <Box.div
        alignItems="center"
        display="flex"
        flexDirection="row"
        gap="d2"
        justifyContent="space-between"
        w="100%"
      >
        <Box.div display="flex" flexDirection="column" gap="d2">
          <Avatar
            name={caseOverview.talent_name}
            showName
            size="small"
            src={caseOverview.talent_avatar || undefined}
          />
          <Box.div>
            <Badge color={badgeColor}>{badgeText}</Badge>
          </Box.div>
        </Box.div>
        <Box.div
          alignItems="flex-start"
          display="flex"
          justifyContent="flex-start"
          minWidth="fit-content"
        >
          <Button
            as={Link}
            onClick={() => {
              trackEvent('cases dashboard: click on go to profile', {
                talent_id: caseOverview.talent_id,
                user_role,
                company_id,
                case_id: caseOverview.case_id,
                hr_dashboard_section:
                  badge === 'waitingUntil' ? 'waiting cases' : 'start date at risk',
              });
            }}
            to={routes.case.get(caseOverview.case_id)}
            variant="ghost"
          >
            Go to case
          </Button>
        </Box.div>
      </Box.div>
      <Box.div>{caseOverview.last_status_update_message}</Box.div>
    </Box.div>
  );
};
