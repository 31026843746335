import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { formatUrlByType } from 'deprecated/utils/helper/documents/formatUrlByType';
import type { Document } from 'modules/documents/api/attachments/types/Document';
import type { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';

export const buildDocumentsWithUrl = (documents: Document[], caseId: number): DocumentWithUrl[] => {
  const mappedDocuments = documents.map((document: Document) => ({
    docUrl: formatUrlByType(document, caseId),
    docName: document.name,
    docDate: formatDate(document.updated_at),
    docCode: document.internal_reference_code,
    type: document.type,
    id: document.id,
  }));

  return mappedDocuments;
};
