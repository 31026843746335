import { Box, Th, Tr } from '@localyze-pluto/components';
import { adminColumns, hrColumns } from '../columns';
import React from 'react';
import { useCurrentUser } from 'config/CurrentUserContext';

export const HeaderRow = (): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();

  const headers = isAdmin ? adminColumns : hrColumns;

  return (
    <Tr>
      {headers.map(({ id, header }) => (
        <Th key={id} style={{ padding: '0.5rem' }}>
          <Box.div whiteSpace="nowrap">{header}</Box.div>
        </Th>
      ))}
    </Tr>
  );
};
