import { AdminCountriesParams, UseAdminCompaniesProps } from './useAdminCompanies.types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { CompanyBaseFields } from 'modules/company/types/CompanyBaseFields';
import { useCurrentUser } from 'config/CurrentUserContext';

export const ADMIN_COMPANIES_QUERY_KEY = 'admin-companies';

/**
 * Makes a get request to fetch companies, must be admin
 * @props params.search - filters results based on matching string
 * @props params.companyState - filters results based on company state
 * @returns companies
 */
const getAdminCompanies = async (params?: AdminCountriesParams): Promise<CompanyBaseFields[]> => {
  const { data } = await axios.get<CompanyBaseFields[]>(
    '/admin/companies',
    params ? { params: { company_state: params.companyState, search: params.search } } : undefined,
  );

  return data;
};

/**
 * Gets list of companies, must be admin
 * @param props.select - transform returned data
 * @param props.params.search - filters results based on matching string
 * @param props.params.companyState - filters results based on company state
 * @returns companies
 */
export const useAdminCompanies = <Result = CompanyBaseFields[]>({
  select,
  params,
}: UseAdminCompaniesProps<Result> = {}): UseQueryResult<Result, AxiosError> => {
  const { isAdmin } = useCurrentUser();

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['admin-companies', params?.companyState, params?.search],
    queryFn: () => getAdminCompanies(params),
    enabled: isAdmin,
    select,
  });
};
