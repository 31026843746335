import { Box, Icon, useToast } from '@localyze-pluto/components';
import { EmptyState } from 'components/EmptyState/EmptyState';
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useMessages } from 'modules/talent/api/useMessages/useMessages';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useParams } from 'react-router-dom';
import { InternalNote } from 'modules/caseDetails/components/CaseActivityCard/InternalNotes/InternalNote';
import { PostNewItem } from '../PostNewItem/PostNewItem';
import { useCreateMessage } from 'modules/talent/api/useCreateMessage/useCreateMessage';

export const InternalNotes = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const caseId = Number(id);

  const {
    data: internalNotes,
    isSuccess,
    isError,
    error,
  } = useMessages({
    caseId,
    internalMessage: true,
  });

  const { mutate: createMessage } = useCreateMessage({
    onSuccess: () => {
      toast('New Internal Note was created successfully.', 'success');
    },
    onError: (error) => {
      const toastMessage = error.message || 'Error creating new Internal Note.';

      toast(toastMessage, 'error');
    },
    internalMessage: true,
  });

  useEffect(() => {
    if (isError) {
      toast(error.message || 'Error fetching internal notes.', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  if (!isSuccess) {
    return <ContainedLoadingState />;
  }

  const onPostNewInternalNote = (note: string): void => {
    createMessage({
      caseId,
      body: note,
      internal: true,
    });
  };

  const hasAlreadyOnePinnedNote = internalNotes.some((note) => note.pinned);

  return (
    <Box.div display="grid" gridTemplateColumns="2fr 1fr" h="100%">
      {isEmpty(internalNotes) ? (
        <EmptyState
          gap="d2"
          illustration={
            <Icon color="contentTertiary" decorative icon="list-plus" size="sizeIcon30" />
          }
          text="There are no internal notes yet."
        />
      ) : (
        <Box.div display="flex" flexDirection="column" gap="d3" overflowY="auto" p="d6" w="100%">
          {internalNotes.map((internalNote) => (
            <InternalNote
              hasAlreadyOnePinnedNote={hasAlreadyOnePinnedNote}
              internalNote={internalNote}
              key={internalNote.id}
            />
          ))}
        </Box.div>
      )}
      <Box.div
        borderLeftColor="borderSecondary"
        borderLeftStyle="borderStyleSolid"
        borderLeftWidth="borderWidth10"
        p="d6"
      >
        <PostNewItem
          onPostNewItem={onPostNewInternalNote}
          placeholder="Post a new internal note..."
          textAreaAriaLabel="Post a new internal note"
        />
      </Box.div>
    </Box.div>
  );
};
