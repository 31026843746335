import { Box, Td, TdProps } from '@localyze-pluto/components';
import { ColorTokens, FontSizeTokens } from '@localyze-pluto/design-tokens';
import React, { ReactNode, TdHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';
import { useCurrentUser } from 'config/CurrentUserContext';

type Props = TdHTMLAttributes<HTMLTableCellElement> &
  TdProps & {
    children: ReactNode;
    caseOverview: CaseOverview;
    isAdmin?: boolean;
  };

export const CaseOverviewCell = ({ caseOverview, children, ...rest }: Props): React.JSX.Element => {
  const { isHrViewer } = useCurrentUser();
  const routes = useRoutes();

  const talentId = caseOverview.talent_id;
  const caseId = caseOverview.case_id;

  const ConditionalLink = isHrViewer ? Box.div : Link;

  const caseProfilePath = routes.case.get(caseId);

  const ariaLabel = `navigate to case profile id ${caseId}`;

  return (
    <Td padding="p2" style={{ fontSize: FontSizeTokens.fontSize20 }}>
      <Box.div
        aria-label={isHrViewer ? undefined : ariaLabel}
        as={ConditionalLink}
        key={talentId}
        onClick={() => trackEvent('case: select case')}
        style={{
          textDecoration: 'none',
          color: ColorTokens.colorTextStrongest,
          fontSize: FontSizeTokens.fontSize20,
          ...(rest.style || {}),
        }}
        to={isHrViewer ? '' : caseProfilePath}
      >
        {children}
      </Box.div>
    </Td>
  );
};
