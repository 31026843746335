import { BadgeProps } from '@localyze-pluto/components';
import { CaseState } from 'modules/cases/types/CaseState';

export const getCurrentStateBadgeProps: {
  [key in CaseState]: BadgeProps;
} = {
  ongoing: {
    color: 'blue',
    children: 'Case ongoing',
  },
  onboarding: {
    color: 'blue',
    children: 'Case onboarding',
  },
  submitting_information: {
    color: 'blue',
    children: 'Submitting information',
  },
  in_assessment: {
    color: 'blue',
    children: 'In assessment',
  },
  waiting: {
    color: 'yellow',
    children: 'Case waiting',
  },
  closed: {
    color: 'green',
    children: 'Case closed',
  },
};
