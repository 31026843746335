import { Table, THead, TBody } from '@localyze-pluto/components';
import React from 'react';
import { Talent } from 'modules/talent/types/Talent';
import { TalentRow } from './Rows/TalentRow';
import { HeaderRow } from './Rows/HeaderRow';

export const TalentsTable = ({ talents }: { talents: Talent[] }): React.JSX.Element => {
  return (
    <Table bordered striped>
      <THead>
        <HeaderRow />
      </THead>
      <TBody>
        {talents.map((talent) => (
          <TalentRow key={talent.id} talent={talent} />
        ))}
      </TBody>
    </Table>
  );
};
