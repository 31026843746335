import {
  Box,
  Icon,
  IconProps,
  Text,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import type { SystemProp, Theme } from '@xstyled/styled-components';
import React from 'react';

export const useHover = <T extends HTMLElement = HTMLElement>(
  elementRef: React.RefObject<T>,
): boolean => {
  const [value, setValue] = React.useState(false);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  React.useEffect(() => {
    const node = elementRef.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
    return;
  }, [elementRef]);

  return value;
};

type ReportingTableCardFilterVariants = 'danger' | 'default';

export interface ReportingTableCardFilterProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'color'> {
  count: number;
  checked?: boolean;
  disabled?: boolean;
  icon: IconProps['icon'];
  title: string;
  tooltipText: string;
  variant?: ReportingTableCardFilterVariants;
}

const getReportingTableFilterCardIconColors = (
  count: number,
  isHovered: boolean,
  variant: ReportingTableCardFilterVariants,
  checked?: boolean,
): {
  backgroundColor: SystemProp<keyof Theme['colors'], Theme>;
  color: SystemProp<keyof Theme['colors'], Theme>;
} => {
  if (variant === 'danger' && count !== 0) {
    return {
      backgroundColor: 'colorBackgroundError',
      color: 'colorTextError',
    };
  }
  if ((isHovered || checked) && variant !== 'danger') {
    return {
      backgroundColor: 'colorBackgroundInfo',
      color: 'colorTextInfo',
    };
  }
  return {
    backgroundColor: 'colorBackgroundWeaker',
    color: 'colorTextInfo',
  };
};

/**
 * Clickable card that allows a user to quickly filter the table content.
 * @param props.count - number to be displayed in the card
 * @param props.icon - icon to be used in the card: IconProps['icon']
 * @param props.title - title text of the card
 * @param props.variant - color variant of the card: default, danger
 */
export const ReportingTableCardFilter = ({
  count,
  checked,
  disabled,
  icon,
  title,
  variant = 'default',
  tooltipText,
  ...props
}: ReportingTableCardFilterProps): React.JSX.Element => {
  const hoverRef = React.useRef(null);
  const isHovered = useHover(hoverRef);
  const tooltipState = useTooltipStore({ placement: 'bottom' });

  return (
    <>
      <TooltipAnchor render={<Box.div />} store={tooltipState}>
        <Box.div
          borderRadius="borderRadius20"
          h="100%"
          outlineColor={{ focusWithin: 'colorBorderPrimary' }}
          outlineOffset={{ focusWithin: 'borderWidth20' }}
          outlineStyle={{ focusWithin: 'borderSolid' }}
          outlineWidth={{ focusWithin: 'borderWidth20' }}
          position="relative"
          ref={hoverRef}
        >
          <Box.div
            alignItems="center"
            backgroundColor={isHovered ? 'colorBackgroundWeaker' : 'colorBackground'}
            borderColor={checked ? 'colorBorderPrimary' : 'colorBorderWeaker'}
            borderRadius="borderRadius20"
            borderStyle="borderStyleSolid"
            borderWidth="borderWidth10"
            boxShadow={isHovered || checked ? 'shadow' : undefined}
            display="flex"
            gap="d3"
            h="100%"
            margin="m0"
            padding="p5"
            position="relative"
            transition="box-shadow 100ms ease-in"
          >
            <Box.span
              alignItems="center"
              borderRadius="borderRadius20"
              display="flex"
              h="100%"
              justifyContent="center"
              padding="p5"
              {...getReportingTableFilterCardIconColors(count, isHovered, variant, checked)}
            >
              <Icon decorative icon={icon} size="sizeIcon40" />
            </Box.span>
            <Box.span>
              <Box.span alignItems="center" display="flex">
                <Text.span
                  color="colorText"
                  display="block"
                  fontSize="fontSize20"
                  fontWeight="fontWeightMedium"
                  lineHeight="lineHeight20"
                  marginRight="m2"
                >
                  {title}
                </Text.span>
                <Icon color="colorText" decorative icon="info" size="sizeIcon20" />
              </Box.span>
              <Text.span
                color={variant === 'danger' && count !== 0 ? 'colorTextError' : 'colorTextLink'}
                display="block"
                fontSize="fontSize70"
                fontWeight="fontWeightBold"
                lineHeight="lineHeight70"
              >
                {count}
              </Text.span>
            </Box.span>
          </Box.div>
          <Box.input
            background="none"
            border="none"
            checked={checked}
            cursor={{
              _: 'pointer',
              disabled: 'not-allowed',
            }}
            disabled={disabled}
            h="100%"
            left={0}
            opacity="0"
            position="absolute"
            top={0}
            type="checkbox"
            w="100%"
            {...props}
          />
        </Box.div>
      </TooltipAnchor>
      <Tooltip store={tooltipState}>{tooltipText}</Tooltip>
    </>
  );
};
