import { Box, BoxProps, Paragraph } from '@localyze-pluto/components';
import React, { ReactNode } from 'react';
import type { SystemProp, Theme } from '@xstyled/styled-components';

type EmptyStateProps = BoxProps & {
  children?: ReactNode;
  illustration?: ReactNode;
  gap?: SystemProp<keyof Theme['space'], Theme>;
  text?: string;
};

export const EmptyState = ({
  children,
  illustration = (
    <Box.span aria-label="Tada!" role="img">
      🎉
    </Box.span>
  ),
  gap = 'd6',
  text,
  ...props
}: EmptyStateProps): React.JSX.Element => {
  return (
    <Box.div
      alignItems="center"
      data-testid="empty-state"
      display="flex"
      flexDirection="column"
      gap={gap}
      h="100%"
      justifyContent="center"
      padding="p6"
      w="100%"
      {...props}
    >
      <Box.div fontSize="fontSize70" lineHeight="lineHeight70">
        {illustration}
      </Box.div>
      {text ? (
        <Paragraph
          color="colorText"
          fontSize="fontSize30"
          lineHeight="lineHeight40"
          marginBottom="m0"
          textAlign="center"
        >
          {text}
        </Paragraph>
      ) : null}
      {children}
    </Box.div>
  );
};
