import { NavLink } from 'modules/layout/components/Navbar/types/NavLink';

export const isNavLinkAllowed = (navItem: NavLink, allowedPathnames: string[]): boolean => {
  const isPathAllowed = !!allowedPathnames.find((pathname) => {
    if (pathname === '/' && navItem.to === '/') {
      return true;
    }

    const toWithoutQueryParams = navItem.to.split('?')[0];

    if (pathname === toWithoutQueryParams) {
      return true;
    }

    // replaces ids with two dots as /foo/:id/bar to /foo/bar
    const pathWithoutIds = pathname.replace(/\/:[^/]{1,}/, '');

    // replaces numeric ids as /foo/45/bar to /foo/bar
    const toWithoutNumbers = toWithoutQueryParams.replace(/[0-9]{1,}\/?/, '');

    return pathWithoutIds === toWithoutNumbers;
  });

  const isFeatureEnabled =
    navItem.withFeatureToggle === true || navItem.withFeatureToggle === undefined;

  return isPathAllowed && isFeatureEnabled;
};
