import React from 'react';
import cx from 'clsx';
import { Icon } from '@localyze-pluto/components';

type ArrowProps = {
  hasError?: boolean;
  open: boolean;
};

export const Arrow = ({ hasError, open }: ArrowProps): React.JSX.Element => (
  <Icon
    className={cx('tw-w-5 tw-h-5 tw-text-gray-400 tw-transition tw-ease-in-out', {
      'tw-text-red-600': hasError,
      'tw-transform tw-rotate-180': open,
    })}
    data-testid="select-menu-arrow"
    decorative
    icon="chevron-down"
    size="sizeIcon30"
  />
);
