import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';
import { PaginationInformation } from 'deprecated/api/types/PaginationInformation';

export const CASE_OVERVIEWS_PATH = '/case_overviews';

export type CaseOverviewParams = {
  page?: string;
  items?: number;
  [key: string]: unknown;
};

type CaseOverviewsResponse = {
  cases: CaseOverview[];
  meta: PaginationInformation;
};

const getCaseOverviews = async (params: CaseOverviewParams): Promise<CaseOverviewsResponse> => {
  const { data } = await axios.get<CaseOverviewsResponse>(CASE_OVERVIEWS_PATH, { params });

  return data;
};

export const useCaseOverviews = (
  params: CaseOverviewParams = {},
  options: Pick<UseQueryOptions<CaseOverviewsResponse, AxiosError>, 'enabled'> = {
    enabled: true,
  },
): UseQueryResult<CaseOverviewsResponse, AxiosError> => {
  return useQuery({
    queryKey: ['case-overviews', ...Object.values(params)],
    queryFn: () => getCaseOverviews(params),
    enabled: options.enabled,
  });
};
