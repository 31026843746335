import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { ValueGetterParams } from 'ag-grid-community';

/**
 * Gets the talent full name to be used as a value getter
 * @param param.data.talentFirstName - talent first name
 * @param param.data.talentLastName - talent last name
 * @returns full name
 */
export const getTalentValue = ({ data }: Pick<ValueGetterParams<CaseReport>, 'data'>): string =>
  `${data?.talentFirstName} ${data?.talentLastName}`;
