import React from 'react';
import { Button } from '@localyze-pluto/components';
import axios from 'axios';

export const DocumentAnchor = ({
  href,
  label,
}: {
  href: string;
  label?: string;
}): React.JSX.Element => {
  const download = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const response = await axios.get(href, { responseType: 'blob' });

    window.open(window.URL.createObjectURL(response.data), '_blank');
  };

  const buttonLabel = label || 'Preview';

  return (
    <Button
      aria-label={buttonLabel}
      as="a"
      href={href}
      iconOnly={!label}
      leadingIcon={label ? undefined : 'eye'}
      onClick={download}
      rel="noopener noreferrer"
      target="_blank"
      type="button"
      variant="ghost"
    >
      {buttonLabel}
    </Button>
  );
};
