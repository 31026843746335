import React from 'react';
import { Message } from 'modules/talent/types/Message';
import { Box, ConfirmationModal, Icon, useModalStore, useToast } from '@localyze-pluto/components';
import { useDeleteMessage } from 'modules/talent/api/useDeleteMessage/useDeleteMessage';
import { useUpdateMessage } from 'modules/talent/api/useUpdateMessage/useUpdateMessage';
import { MessageItem } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/MessageItem';
import { mapTypeOfMessage } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/helpers';

export const InternalNote = ({
  internalNote,
  hasAlreadyOnePinnedNote,
}: {
  internalNote: Message;
  hasAlreadyOnePinnedNote: boolean;
}): React.JSX.Element => {
  const { id, pinned, author, author_type, body, created_at, internal_message } = internalNote;

  const toast = useToast();

  const pinInternalNoteModalState = useModalStore();
  const isPinInternalNoteModalOpen = pinInternalNoteModalState.useState('open');

  const { mutate: deleteMessage } = useDeleteMessage({
    onError: (error) => toast(error.message || 'Error deleting internal note.', 'error'),
    onSuccess: () => toast('Deleted internal note successfully.', 'success'),
    internalMessage: true,
  });

  const { mutate: updateMessage } = useUpdateMessage({
    onError: (error) => toast(error.message || 'Error while updating internal note.', 'error'),
    onSuccess: () => toast('Internal note updated successfully.', 'success'),
    internalMessage: true,
  });

  const handleUpdateMessage = (messageId: number, isPinned: boolean): void =>
    updateMessage({
      messageId: Number(messageId),
      pinned: !isPinned,
    });

  const getMenuItems = (messageId: number, pinned: boolean) => [
    {
      onClick: () => {
        if (hasAlreadyOnePinnedNote && !pinned) {
          pinInternalNoteModalState.show();
        } else {
          handleUpdateMessage(messageId, pinned);
        }
      },
      label: (
        <Box.div alignItems="center" display="flex" gap="d2">
          <Icon decorative icon={pinned ? 'pin-off' : 'pin'} size="sizeIcon20" />
          <Box.span>{pinned ? 'Unpin' : 'Pin to top'}</Box.span>
        </Box.div>
      ),
    },
    {
      onClick: () => deleteMessage({ messageId }),
      label: (
        <Box.div alignItems="center" display="flex" gap="d2">
          <Icon decorative icon="trash-2" size="sizeIcon20" />
          <Box.span>Delete</Box.span>
        </Box.div>
      ),
    },
  ];

  return (
    <>
      <MessageItem
        aria-label={body}
        date={new Date(created_at)}
        isPinned={pinned}
        key={id}
        menuItems={getMenuItems(id, !!pinned)}
        message={body}
        messageFrom={author.first_name}
        messageId={id}
        type={mapTypeOfMessage(author_type, internal_message)}
      />
      {hasAlreadyOnePinnedNote && isPinInternalNoteModalOpen && (
        <ConfirmationModal
          confirmLabel="Yes"
          onConfirm={() => handleUpdateMessage(id, !!pinned)}
          store={pinInternalNoteModalState}
        >
          There is a pinned note already. By pinning this note, it will replace the existing one.
          Are you sure you want to proceed?
        </ConfirmationModal>
      )}
    </>
  );
};
