import { Box, Tooltip, TooltipAnchor, useTooltipStore } from '@localyze-pluto/components';
import React, { TdHTMLAttributes } from 'react';
import { CaseOverviewCell } from '../CaseOverviewCell';
import { isEmpty } from 'lodash';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = TdHTMLAttributes<HTMLTableCellElement> & {
  tooltipText: Maybe<string>;
  caseOverview: CaseOverview;
  isAdmin?: boolean;
};

export const TooltipCell = ({
  children,
  caseOverview,
  isAdmin,
  tooltipText,
  ...rest
}: Props): React.JSX.Element => {
  const tooltip = useTooltipStore({
    placement: 'bottom',
  });

  return (
    <CaseOverviewCell caseOverview={caseOverview} isAdmin={isAdmin} {...rest}>
      {!tooltipText || isEmpty(tooltipText) ? (
        '-'
      ) : (
        <Box.div
          alignItems="center"
          display="flex"
          style={{
            wordBreak: 'break-all',
          }}
        >
          <TooltipAnchor store={tooltip}>
            <Box.div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                lineClamp: 3,
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {children}
            </Box.div>
          </TooltipAnchor>
          <Tooltip store={tooltip}>{tooltipText}</Tooltip>
        </Box.div>
      )}
    </CaseOverviewCell>
  );
};
