import './style.scss';

import { Box, Button, Icon, useModalStore, useToast } from '@localyze-pluto/components';

import CompanyStateDropDown from 'deprecated/components/DropDown/CompanyStateDropDown';
import { Link } from 'react-router-dom';
import React from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { updateCompany } from 'deprecated/utils/helper/admin/companies';
import { InvoicesModal } from 'modules/invoices/components/InvoicesModal';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useStoreState } from '@ariakit/react';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';

export const CompanyCard = ({
  updateCompanies,
  company,
  companyState,
  companyStateOptions,
  paginationParams,
}) => {
  const toast = useToast();
  const invoiceModalState = useModalStore();
  const isModalInvoicesOpen = useStoreState(invoiceModalState, 'open');
  const routes = useRoutes();
  const isPartnersHubActive = useIsFeatureFlagActive('partners_hub');

  const selectCompanyState = (companyState) => {
    const params = { company: { state: companyState } };
    updateCompany(
      company.id,
      params,
      () => {
        toast(`Company state updated successfully.`, 'success');
        updateCompanies(company.company_name, companyState);
      },
      (error) => console.error(error),
    );
  };

  return (
    <Box.div
      className={'company-card'}
      display="grid"
      gridTemplateColumns={{ _: '1', md: '3fr 2fr 1fr' }}
    >
      <p className="name">{company.name}</p>
      <div className="company-card-label">
        <CompanyStateDropDown
          onChangeStatus={selectCompanyState}
          value={companyState}
          companyStateOptions={companyStateOptions}
        />
      </div>
      <Box.div display="flex" justifyContent="flex-end" alignItems="center" minHeight="100%">
        <>
          <Button variant="ghost" onClick={() => invoiceModalState.show()}>
            <Icon
              color={{
                _: 'colorIconInfo',
                hover: 'colorIconWeak',
              }}
              icon="credit-card"
              title="Invoices"
            />
          </Button>
          {isModalInvoicesOpen && (
            <InvoicesModal modalState={invoiceModalState} companyId={company.id} />
          )}
        </>
        <Link
          aria-label="Navigate to company's report"
          to={{
            pathname: routes.reportingPage.get(company.id),
            state: {
              backTo: 'Companies',
              paginationParams: paginationParams,
              prevPath: window.location.pathname,
            },
          }}
          onClick={() => {
            trackEvent("companies: navigate to company's report");
          }}
        >
          <Box.div padding="p2 p3">
            <Icon
              decorative
              color={{
                _: 'colorIconInfo',
                hover: 'colorIconWeak',
              }}
              icon="file-bar-chart"
            />
          </Box.div>
        </Link>

        <Link
          aria-label="Navigate to company's profile"
          to={{
            pathname: isPartnersHubActive
              ? routes.settings.get(company.id)
              : routes.companyProfile.get(company.id),
            state: { backTo: 'Companies', paginationParams, prevPath: window.location.pathname },
          }}
        >
          <Box.div padding="p2 p3">
            <Icon
              decorative
              color={{
                _: 'colorIconInfo',
                hover: 'colorIconWeak',
              }}
              icon="cog"
            />
          </Box.div>
        </Link>
      </Box.div>
    </Box.div>
  );
};
