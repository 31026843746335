import { CompanyState } from 'modules/company/types/CompanyState';
import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';
import { getCompanies } from 'deprecated/api/companies/company';
import { CompanyBaseFields } from 'modules/company/types/CompanyBaseFields';

const companiesToFilterOptions = (companies: CompanyBaseFields[]): FilterOption<string>[] =>
  companies.map((item: CompanyBaseFields) => ({
    filterAttribute: 'company_id',
    label: item.state === CompanyState.Inactive ? `${item.name} (inactive)` : item.name,
    userTypes: ['admin'],
    value: item.id.toString(),
  }));

const ALL_COMPANIES_OPTION: FilterOption<string> = {
  filterAttribute: 'company_id',
  label: 'All companies',
  userTypes: ['admin'],
  value: 'all',
};

export const COMPANY_FILTER: Filter<string> = {
  attribute: 'company_id',
  label: 'Company',
  options: [],
  populateOptions: async () => [
    ALL_COMPANIES_OPTION,
    ...companiesToFilterOptions(await getCompanies()),
  ],
  type: 'singleselect',
  userTypes: ['admin'],
};
