import { AxiosError, isAxiosError } from 'axios';

// Legacy! Do not use on new code
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const prepareErrorMessage = (error: unknown): string => {
  if (isAxiosError(error)) {
    const requestError = error as AxiosError<{ message: string }>;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const message = requestError?.response?.data || '';
    return `Something went wrong. ${message}`;
  }

  const err = error as Error;

  if (err.message) {
    return `Something went wrong: ${err.message}`;
  }

  return `Something went wrong. Please try again`;
};
