import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { ServiceLevelOptions } from 'modules/cases/components/ServiceLevelOptions/ServiceLevelOptions';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type EditServiceLevelFormTypes = {
  serviceLevelId: string;
};

const validationSchema: Yup.ObjectSchema<EditServiceLevelFormTypes> = Yup.object({
  serviceLevelId: Yup.string().required('This field is required'),
});

export const EditServiceLevelModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const { service_level_id: serviceLevelId } = kase;
  const toast = useToast();
  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Service level updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Service level could not be updated', 'error'),
  });

  const { handleSubmit, control, watch } = useForm({
    defaultValues: { serviceLevelId: serviceLevelId.toString() },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditServiceLevelFormTypes> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { service_level_id: Number(values.serviceLevelId) },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Service level</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form id="edit-service-level-form" onSubmit={handleSubmit(onSubmit)}>
          <Box.div fontSize="fontSize20" marginBottom="d4">
            Select the service level for this case
          </Box.div>
          <ServiceLevelOptions control={control} selectedServiceLevelId={watch('serviceLevelId')} />
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isPendingUpdateCase}
          form="edit-service-level-form"
          loading={isPendingUpdateCase}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
