import { AxiosError } from 'axios';
import { Office } from '../../types/Office';
import { SelectItemProps } from '@localyze-pluto/components';
import { UseQueryResult } from '@tanstack/react-query';
import { sortByLabel } from 'utils/sortByLabel';
import { useOffices } from 'modules/offices/api/useOffices/useOffices';
import { UseOfficesProps } from 'modules/offices/api/useOffices/useOffices.types';

/**
 * Gets list of offices (by company) as select options, sorted alphabetically by label
 * @param props.params.companyId
 * @param props - remaining useQuery params are available
 * @returns offices transformed into options usable by a select
 */
export const useOfficesWithSelectOptions = ({
  params,
  ...props
}: Omit<UseOfficesProps<SelectItemProps[]>, 'select'>): UseQueryResult<
  SelectItemProps[],
  AxiosError
> => {
  const officesToSortedSelectOptions = (offices: Office[]): SelectItemProps[] => {
    return offices
      .map((office) => ({
        label: `${office.city} - ${office.name}`,
        value: office.id.toString(),
      }))
      .sort(sortByLabel);
  };

  return useOffices({ params, select: officesToSortedSelectOptions, ...props });
};
