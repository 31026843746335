import React, { useEffect } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { Box, Button, Heading, Paragraph } from '@localyze-pluto/components';
import { Link, useParams } from 'react-router-dom';
import { GlomoRequestQuestionsTable } from '../components/GlomoRequestQuestionsTable/GlomoRequestQuestionsTable';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentLink } from 'modules/glomoRequests/components/DocumentLink/DocumentLink';
import { useGlomoRequest } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { humanizeGlomoRequestType } from 'modules/glomoRequests/utils/humanizeGlomoRequestType';
import { trackPageView } from 'modules/analytics/analytics';
import { GlomoRequestStatusBadge } from 'modules/glomoRequests/components/GlomoRequestStatusBadge';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export type LegacyCompleteGlomoRequestValues = {
  riskLevel: string;
  riskAssessmentUrl: string;
};

const validationSchema = Yup.object().shape({
  riskLevel: Yup.string().required('This field is required.'),
  riskAssessmentUrl: Yup.string().url('Invalid URL format.').required('This field is required.'),
});

export const AdminGlomoRequestLegacy = (): React.JSX.Element => {
  const { id: glomoRequestId } = useParams<{ id: string }>();
  const routes = useRoutes();

  const {
    data: glomoRequest,
    isSuccess: isSuccessGlomoRequest,
    isError,
  } = useGlomoRequest(Number(glomoRequestId));

  const { data: company, isSuccess: isSuccessCompany } = useCompany({
    companyId: glomoRequest?.company_id,
    enabled: !!glomoRequest,
  });

  const methods = useForm<LegacyCompleteGlomoRequestValues>({
    defaultValues: {
      riskLevel: '',
      riskAssessmentUrl: '',
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const requestType = glomoRequest?.type;

  useEffect(() => {
    if (requestType) {
      trackPageView('glomo request: request details', {
        request_type: requestType,
      });
    }
  }, [requestType]);

  if (isError && !glomoRequest) return <ErrorStatePage />;

  if (!isSuccessGlomoRequest || !isSuccessCompany) {
    return <ContainedLoadingState />;
  }

  const {
    employee_first_name,
    employee_last_name,
    status,
    questions,
    risk_assessment_url,
    risk_level,
    type,
  } = glomoRequest;

  const employeeFullName = `${employee_first_name} ${employee_last_name}`;

  return (
    <PageLayout
      flexed
      titleButton={
        <Button
          as={Link}
          leadingIcon="arrow-left"
          to={routes.glomoRequestsList.url}
          variant="ghost"
        >
          Back to all requests
        </Button>
      }
    >
      <Box.div display="flex" flexDirection="column" gap="d6" maxW="815px" py="p8">
        <FormProvider {...methods}>
          <Box.div display="flex" flexDirection="column" gap="d2">
            <Box.div alignItems="center" display="flex" justifyContent="space-between">
              <Box.div alignItems="center" display="flex" gap="d4">
                <Heading as="h1" marginBottom="m0" size="heading40">
                  {humanizeGlomoRequestType[type]} request
                </Heading>
                <GlomoRequestStatusBadge status={status} />
              </Box.div>
            </Box.div>
            <Paragraph marginBottom="m0" size="large">
              {employeeFullName} - {company.name}
            </Paragraph>
          </Box.div>
        </FormProvider>
        <Box.div display="flex" flexDirection="column" gap="d4">
          {status === 'complete' && (
            <DocumentLink
              name={employeeFullName}
              risk={risk_level as GlomoRequestRisk}
              url={String(risk_assessment_url)}
            />
          )}
          <GlomoRequestQuestionsTable questions={questions} />
        </Box.div>
      </Box.div>
    </PageLayout>
  );
};
