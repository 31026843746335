import { Box, Pagination } from '@localyze-pluto/components';
import { PaginatedResultsCounter } from 'components/PaginatedResultsCounter/PaginatedResultsCounter';
import React from 'react';
import { PaginationInformation } from 'deprecated/api/types/PaginationInformation';

type PaginationWithCounterProps = {
  paginationInfo: PaginationInformation;
  itemsPerPage?: number;
  setPage: (value: number | string) => void;
};

export const PaginationWithCounter = ({
  paginationInfo,
  itemsPerPage,
  setPage,
}: PaginationWithCounterProps): React.JSX.Element => {
  if (paginationInfo.count === 0) return <></>;

  return (
    <Box.div
      alignItems="center"
      display="flex"
      marginBottom="m2"
      paddingLeft="p2"
      paddingRight="p2"
    >
      <Box.div flexGrow={1}>
        <PaginatedResultsCounter itemsPerPage={itemsPerPage} paginationInfo={paginationInfo} />
      </Box.div>

      <Box.div flexGrow={2}>
        <Pagination
          currentPage={paginationInfo.page}
          onPageChange={setPage}
          pageCount={paginationInfo.pages}
        />
      </Box.div>
    </Box.div>
  );
};
