import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isNavLink } from './helpers';
import { NavGroupProps } from 'modules/layout/components/Navbar/types/NavGroupProps';
import { NavEntry } from 'modules/layout/components/Navbar/types/NavLink';
import kebabCase from 'lodash/kebabCase';
import { Box, Icon } from '@localyze-pluto/components';
import { styled, useTheme } from '@localyze-pluto/theme';

const isChildRoute = (navItems: NavEntry[], pathname: string) =>
  navItems.some((navItem) => isNavLink(navItem) && pathname === (navItem.route || navItem.to));

const CollapsibleIconContainer = styled.div`
  transition: all 0.2s;
  transform: ${(props: { isOpen: boolean }) => props.isOpen && 'rotate(180deg)'};
`;

const StyledListItem = styled.li<{
  color: string;
  fontSize: string;
}>`
  font-size: ${(props) => props.fontSize};

  &:hover button svg {
    color: ${(props) => props.color};
  }
`;

export const NavGroup = ({
  icon,
  label,
  items,
  children,
}: NavGroupProps & { children: ReactNode }): React.JSX.Element => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(isChildRoute(items, pathname));
  }, [pathname, items]);

  const onClick = () => {
    setOpened(!opened);
  };

  return (
    <StyledListItem
      color={theme.colors.colorTextStronger}
      fontSize={theme.fontSizes.fontSize30}
      id={kebabCase(`sidebar-${label}`)}
    >
      <Box.button
        alignItems="center"
        appearance="none"
        background="transparent"
        border="none"
        color="colorTextStronger"
        display="flex"
        fontSize="fontSize20"
        fontWeight="fontWeightMedium"
        onClick={onClick}
        padding="p0"
        w="100%"
      >
        {icon}
        <Box.span color="colorTextStronger" textAlign="left" w="100%">
          {label}
        </Box.span>
        <Box.div
          alignItems="center"
          as={CollapsibleIconContainer}
          display="flex"
          h="36"
          isOpen={opened}
          justifyContent="center"
          w="36"
        >
          <Icon decorative icon="chevron-down" size="sizeIcon30" />
        </Box.div>
      </Box.button>
      <Box.ul display={opened ? 'block' : 'none'} listStyleType="none" paddingLeft="d9">
        {children}
      </Box.ul>
    </StyledListItem>
  );
};
