import axios from 'axios';
import { queryClient } from 'config/queryClient';
import { COMPANY_USERS_QUERY_KEY } from 'modules/company/api/useCompanyUsers/useCompanyUsers';
import { useMutation } from '@tanstack/react-query';
import {
  DeleteUserProps,
  DeleteUserRequestPayload,
  UseDeleteUserProps,
  UseDeleteUserResult,
} from './useDeleteUser.types';

/**
 * Makes a DELETE request to delete the user and move associated data to a different user
 * @param props.userId - id of user to delete
 * @param props.replacementUserId - id of the user to move associated data to
 */
export const deleteUser = async ({ userId, replacementUserId }: DeleteUserProps): Promise<void> => {
  const data: DeleteUserRequestPayload = {
    replacement_user_id: replacementUserId,
  };

  await axios.delete<void>(`/users/${userId}`, {
    data,
  });
};

/**
 * Deletes a user and moves associated data to a different user
 * @param props.onSuccess - callback to call on success of request
 * @param props.onError - callback to call on error of request
 * @param props.companyId - the id of the company to refetch users for on success
 */
export const useDeleteUser = ({
  onSuccess,
  onError,
  companyId,
}: UseDeleteUserProps): UseDeleteUserResult =>
  useMutation({
    mutationFn: deleteUser,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [COMPANY_USERS_QUERY_KEY, companyId] });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
