import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import {
  Box,
  Button,
  ControlledFormTextArea,
  ControlledRadioGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  Radio,
} from '@localyze-pluto/components';
import { DisclosureStore } from '@ariakit/react';
import { CaseStep } from 'modules/pipelines/types/CaseStep';

export type OpenStepConfirmationModalFormTypes = {
  open_step_reason: string;
  reopen_step_tracking_reason?: string;
  reopen_step_tracking_reason_details?: string;
};

export const OpenStepConfirmationModal = ({
  state,
  caseStep,
  onOpenStep,
}: {
  state: DisclosureStore;
  caseStep: Maybe<CaseStep>;
  onOpenStep: (values: OpenStepConfirmationModalFormTypes) => void;
}): React.JSX.Element => {
  const isModalOpen = state.useState('open');
  const isClosedCaseStep = caseStep?.status === 'completed';

  const getCaseStepSchema = (isClosedCaseStep: boolean) => {
    const caseStepSchema = {
      open_step_reason: yup.string().required('Please enter an opening reason'),
    };
    if (isClosedCaseStep) {
      return {
        ...caseStepSchema,
        reopen_step_tracking_reason: yup
          .string()
          .oneOf(['document_quality', 'document_outdated', 'wrong_document', 'other']),
        reopen_step_tracking_reason_details: yup
          .string()
          .when('reopen_tracking_reason', ([reopen_tracking_reason], schema) => {
            return reopen_tracking_reason === 'other'
              ? schema.required('Please enter a reason you are re-opening this step.')
              : schema.notRequired();
          }),
      };
    } else {
      return caseStepSchema;
    }
  };
  const schema: yup.ObjectSchema<
    OpenStepConfirmationModalFormTypes | { open_step_reason: string }
  > = yup.object().shape({
    ...getCaseStepSchema(isClosedCaseStep),
  });

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      open_step_reason: '',
      reopen_step_tracking_reason: '',
      reopen_step_tracking_reason_details: '',
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const onSubmit: SubmitHandler<OpenStepConfirmationModalFormTypes> = (values) => {
    onOpenStep(values);
    reset({
      reopen_step_tracking_reason: '',
      open_step_reason: '',
      reopen_step_tracking_reason_details: '',
    });
  };

  const watchTrackingReason = watch('reopen_step_tracking_reason');

  if (!isModalOpen) {
    return <></>;
  }

  return (
    <Modal store={state}>
      <Box.form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <ModalHeading>Why do you want to set this step as To-Do?</ModalHeading>
        </ModalHeader>
        <ModalBody>
          <Box.div display="flex">
            {isClosedCaseStep && (
              <Box.div marginBottom="d6">
                <Paragraph marginBottom="d6">
                  Please select one of the following options to indicate the reason for setting this
                  step as To-Do.
                </Paragraph>
                <Box.div display="flex" justifyContent="center">
                  <Box.div display="flex" flexDirection="column">
                    <ControlledRadioGroup
                      control={control}
                      id="reopen_step_tracking_reason"
                      label={
                        'Please select one of the following options' +
                        ' to indicate the reason for setting this step as To-Do.'
                      }
                      name="reopen_step_tracking_reason"
                      orientation="vertical"
                      required={isClosedCaseStep}
                      style={{ paddingBottom: '10px' }}
                    >
                      <Radio
                        label="The quality of the uploaded document is too low"
                        value="document_quality"
                      />
                      <Radio label="The uploaded document is outdated" value="document_outdated" />
                      <Radio label="The user uploaded the wrong document" value="wrong_document" />
                      <Radio label="Other" value="other" />
                    </ControlledRadioGroup>
                    <Box.div paddingLeft="p6" paddingRight="p6">
                      <ControlledFormTextArea
                        control={control}
                        disabled={watchTrackingReason !== 'other'}
                        id="reopen_step_tracking_reason_details"
                        label="Write a reason here"
                        maxLength={50}
                        name="reopen_step_tracking_reason_details"
                        placeholder="Please specify the reason here."
                        required={watchTrackingReason === 'other'}
                      />
                    </Box.div>
                  </Box.div>
                </Box.div>
              </Box.div>
            )}
          </Box.div>
          <Box.div>
            <Paragraph marginBottom="d6">Please add a message to the user below.</Paragraph>
            <ControlledFormTextArea
              control={control}
              id="open_step_reason"
              label="Message"
              maxLength={500}
              name="open_step_reason"
              required
            />
          </Box.div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={state.hide} type="button" variant="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Set as To-Do
          </Button>
        </ModalFooter>
      </Box.form>
    </Modal>
  );
};
