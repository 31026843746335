import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Box } from '@localyze-pluto/components';
import React from 'react';
import cx from 'clsx';

type Props = {
  readonly progress: number;
  readonly strokeWidth?: number;
  readonly className?: string;
  readonly labelClassName?: string;
  readonly wrapperClassName?: string;
  readonly pathColor?: string;
  readonly trailColor?: string;
};

const defaults = {
  strokeWidth: 16,
  labelClassName: 'tw-text-xxs tw-leading-4',
  className: 'tw-h-9 tw-w-9',
  pathColor: '#4A63FC',
  trailColor: '#EBEDFF',
};

export const CircularProgress = ({
  progress,
  strokeWidth = 16,
  pathColor = defaults.pathColor,
  trailColor = defaults.trailColor,
  className = defaults.className,
  labelClassName = defaults.labelClassName,
}: Props): React.JSX.Element => (
  <Box.div zIndex="zIndex0">
    <Box.div alignItems="center" display="flex" justifyContent="center" position="relative">
      <CircularProgressbar
        className={className}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor,
          trailColor,
        })}
        value={progress}
      />
      <Box.span className={cx('tw-absolute tw-leading-none', labelClassName)}>{progress}%</Box.span>
    </Box.div>
  </Box.div>
);
