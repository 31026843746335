import { User } from 'deprecated/api/user/types/User';
import { SelectedFilters } from 'modules/filters/types/SelectedFilters';
import { TalentsQueryFilters } from 'modules/talent/api/useTalents/useTalents';
import { UserRoleName } from 'modules/users/types/UserRoleName';

export const getMyTalentFilterParams = (
  selectedTab: string,
  user: User,
  drawerFilters: SelectedFilters,
): TalentsQueryFilters => {
  const hrManagerId =
    selectedTab !== 'all' &&
    !user.localyze_admin &&
    user.user_role !== UserRoleName.Viewer &&
    user.id;
  const caseManagerId = selectedTab !== 'all' && user.localyze_admin && user.id;

  return {
    ...drawerFilters,
    ...(hrManagerId && { hr_manager_id: hrManagerId }),
    ...(caseManagerId && { case_manager_id: caseManagerId }),
  };
};
