import axios from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from 'config/queryClient';
import { ACCOUNT_ENTRIES_QUERY_KEY } from '../useAccountEntries/useAccountEntries';
import { AccountEntry } from 'modules/billing/types/AccountEntry';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

type CancelAccountEntryPayload = {
  accountEntry: AccountEntry;
  cancellation_reason: string;
};

const cancelAccountEntry = async ({
  accountEntry,
  cancellation_reason,
}: {
  accountEntry: AccountEntry;
  cancellation_reason: string;
}): Promise<void> => {
  await axios.delete<void>(`/admin/account_entries/${accountEntry.id}`, {
    data: {
      accountEntry,
      cancellation_reason,
    },
  });
};

/**
 * Cancels an AccountEntry
 * @param props.onSuccess - callback to call on success of request
 * @param props.onError - callback to call on error of request
 */
export const useCancelAccountEntry = ({
  onSuccess,
  onError,
}: UseMutationOptions<
  void,
  AxiosErrorWithMessage,
  CancelAccountEntryPayload,
  'onError' | 'onSuccess'
>): UseMutationResult<void, AxiosErrorWithMessage, CancelAccountEntryPayload> => {
  return useMutation({
    mutationFn: cancelAccountEntry,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_ENTRIES_QUERY_KEY] });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
