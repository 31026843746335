import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormInput,
  ControlledFormTextArea,
  IconProps,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';

import { Validation } from 'constants/formValidations';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DisclosureStore } from '@ariakit/react';
import React from 'react';
import { Company } from 'modules/company/types/Company';
import { Currency } from 'types/currency';
import { CaseSearchComboBox } from 'modules/billing/components/CaseSearchComboBox/CaseSearchComboBox';
import { useCreateAccountEntry } from 'modules/billing/hooks/useCreateAccountEntry/useCreateAccountEntry';
import { amountToCents } from 'modules/billing/utils/amountToCents';
import { AccountEntryType } from 'modules/billing/types/AccountEntryType';
import { CaseSearchResult } from 'deprecated/types/talents/talentSearchResult';

export type CaseFormValue = Maybe<Pick<CaseSearchResult, 'id' | 'talent_id'>>;

export type AddDebitModalFormTypes = {
  kase: CaseFormValue;
  description: string;
  amount: number;
};

export const CaseSearchValidation = Yup.object()
  .shape({
    id: Yup.number().required(),
    talent_id: Yup.number().required(),
  })
  .nullable();

export const AddDebitModal = ({
  modalState,
  company,
  onDebitCreation,
}: {
  modalState: DisclosureStore;
  company: Company;
  onDebitCreation: () => void;
}): React.JSX.Element => {
  const toast = useToast();

  const schema: Yup.ObjectSchema<AddDebitModalFormTypes> = Yup.object({
    kase: CaseSearchValidation,
    description: Validation.requiredString,
    amount: Yup.number()
      .typeError('Amount must be a number.')
      .test('maxDigitsAfterDecimal', 'number must have 2 digits after decimal', (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      )
      .required('Please enter an amount.'),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      kase: null,
      description: '',
      amount: 0,
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const { mutate: createAccountEntry } = useCreateAccountEntry({
    onSuccess: () => {
      reset();
      modalState.hide();
      toast('The account entry was created successfully', 'success');
      onDebitCreation();
    },
    onError: (error) => {
      toast(
        error.response?.data.message || 'There was an error creating an account entry.',
        'error',
      );
    },
  });

  const onSubmit = (values: AddDebitModalFormTypes) => {
    const payload = {
      amount_cents: amountToCents(values.amount),
      description: values.description,
      invoiceable_id: values.kase?.id || company.id,
      invoiceable_type: values.kase?.id ? 'case' : 'company',
      type: AccountEntryType.Debit,
    };
    createAccountEntry(payload);
    modalState.toggle();
    reset();
  };

  const CURRENCY_ICONS = {
    [Currency.USD]: 'CurrencyDollarIcon',
    [Currency.GBP]: 'CurrencyPoundIcon',
    [Currency.EUR]: 'badge-euro',
  };

  return (
    <Modal store={modalState} style={{ width: '400px' }}>
      <ModalHeader>
        <ModalHeading>Add Debit</ModalHeading>
      </ModalHeader>

      <ModalBody>
        <Box.form id="add-debit-form" onSubmit={handleSubmit(onSubmit)}>
          <Box.div paddingBottom="p4">
            <Label htmlFor="kase">Case (optional)</Label>
            <Controller
              control={control}
              name="kase"
              render={({ field: { onChange, value } }) => (
                <CaseSearchComboBox companyId={company.id} onChange={onChange} value={value} />
              )}
            />
          </Box.div>
          <Box.div flexDirection="column">
            <ControlledFormTextArea
              aria-label="description"
              control={control}
              id="description"
              label="Debit description"
              name="description"
              placeholder="Please add a description for the debit"
              required
            />
            <Box.div className="pluto-number-input">
              <ControlledFormInput
                control={control}
                id="amount"
                label="Amount"
                leadingIcon={CURRENCY_ICONS[company.currency] as IconProps['icon']}
                name="amount"
                required
                type="number"
              />
            </Box.div>
          </Box.div>
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            reset();
            modalState.toggle();
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button form="add-debit-form" type="submit" variant="primary">
          Add Debit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
