import axios from 'axios';

import { HRISProvider, StackOneSettings } from 'modules/integrations/types/HRISCompanyIntegration';

export const updateCompanyIntegration = async (
  company_id: number | string,
  provider_name: HRISProvider,
  provider_data: StackOneSettings & {
    stackone_file_category_id?: string;
    stackone_file_category_label?: string;
  },
): Promise<void> => {
  return axios.put(
    `/companies/${company_id}/integrations/${provider_name}`,
    {
      company_integration: provider_data,
    },
    { headers: { Accept: 'application/json' } },
  );
};
