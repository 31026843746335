import { CreateCasePayload } from 'modules/cases/pages/createCase/types';
import { CreateCaseRequestPayload } from '../useCreateCase.types';

/**
 * Formats additional services for create case endpoint
 * @param additionalServices - Object with additional services as keys and a boolean as value
 * @returns additional services formatted for create case endpoint
 */
export const formatAdditionalServices = (
  additionalServices: CreateCasePayload['additionalServices'],
): CreateCaseRequestPayload['case']['case_additional_services_attributes'] => {
  return Object.entries(additionalServices).reduce(
    (acc, [id, isSelected]) => {
      if (isSelected) {
        acc.push({ additional_service_id: id });
      }
      return acc;
    },
    [] as { additional_service_id: string }[],
  );
};
