import { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';

type Document = Pick<DocumentWithUrl, 'docCode' | 'docUrl' | 'id' | 'type'>;

const hasSameProperties = (payload: Document) => (doc: DocumentWithUrl) =>
  payload.type === 'Attachment' ? doc.id === payload.id : doc.docCode === payload.docCode;

const mapDocListToUpdateDocUrl = (payload: Document) => (doc: DocumentWithUrl) => {
  return hasSameProperties(payload)(doc)
    ? {
        ...doc,
        docUrl: payload.docUrl,
      }
    : doc;
};

export const updateDocUrl = (
  docList: Array<DocumentWithUrl>,
  payload: Pick<DocumentWithUrl, 'docCode' | 'docUrl' | 'id'>,
): Array<DocumentWithUrl> => docList.map(mapDocListToUpdateDocUrl(payload));

export const addNewDocToDocList = (
  docList: Array<DocumentWithUrl>,
  payload: DocumentWithUrl,
): Array<DocumentWithUrl> => {
  const isPayloadDocCodeExistInDocList = docList.find(hasSameProperties(payload));

  return isPayloadDocCodeExistInDocList ? updateDocUrl(docList, payload) : [...docList, payload];
};
