import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { FAQ } from 'modules/knowledge/types/FAQ';
import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const getFAQs = async (): Promise<FAQ[]> => {
  const faqData = await new ContentfulAPI().fetchFaq();

  return faqData.map(({ question, answer }) => ({
    question,
    answerHTML: documentToHtmlString(answer),
    open: false,
  }));
};

export const useFAQs = (): UseQueryResult<FAQ[]> => {
  return useQuery({ queryKey: ['faqs'], queryFn: getFAQs });
};
