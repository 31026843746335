import { User } from 'deprecated/api/user/types/User';
import { getCaseManagers } from 'modules/users/api/getCaseManagers/getCaseManagers';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const CASE_MANAGERS_QUERY_KEY = 'case-managers';

export const useCaseManagers = ({
  params,
  options,
}: {
  params?: { withCases?: boolean };
  options?: Pick<UseQueryOptions<User[]>, 'enabled'>;
} = {}): UseQueryResult<User[]> => {
  return useQuery({
    queryKey: [CASE_MANAGERS_QUERY_KEY, params],
    queryFn: () => getCaseManagers({ ...params }),
    ...options,
  });
};
