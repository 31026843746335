import { Macro } from 'modules/pipelines/pages/caseEvaluationSummary/types/Macro';
import { SelectItemProps } from '@localyze-pluto/components';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

export const convertMacrosToItems = (macros?: Macro[]): SelectItemProps[] =>
  !isUndefined(macros)
    ? map(macros, (macro) => ({
        value: macro.description,
        label: macro.title,
      }))
    : [];
