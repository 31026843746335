import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { Case } from '../../types/Case';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { CaseState } from 'modules/cases/types/CaseState';
import { getCasePipelinesQueryKey } from 'modules/pipelines/hooks/useCasePipelines';

export type UpdateCaseRequestPayload = Partial<
  Omit<Case, 'followers'> & { follower_ids: string[] }
> & { state?: CaseState };

export type UpdateCaseProps = {
  caseId: number;
  payload: UpdateCaseRequestPayload;
};

async function updateCase({ caseId, payload }: UpdateCaseProps): Promise<Case> {
  const response = await axios.put<Case>(
    `/cases/${caseId}`,
    { case: payload },
    { headers: { Accept: 'application/json' } },
  );
  return response.data;
}

export const useUpdateCase = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosErrorWithMessage, UpdateCaseProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosErrorWithMessage, UpdateCaseProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCase,
    onSuccess: async (data, variables, context) => {
      queryClient.setQueryData(['case', data.id], data);

      if (onSuccess) {
        onSuccess(data, variables, context);

        if (data.current_state === CaseState.Closed) {
          queryClient.invalidateQueries({
            queryKey: getCasePipelinesQueryKey(data.id),
          });
        }
      }
    },
    onError,
  });
};
