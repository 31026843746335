import { Box } from '@localyze-pluto/components';
import React from 'react';
import { waitingUntilText } from 'modules/statusUpdates/updateItem/UpdateItem';
import { TooltipCell } from './TooltipCell';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  caseOverview: CaseOverview;
  lastUpdateMessage: string | null;
  isAdmin?: boolean;
  waitingUntil: string | null;
};

export const LastUpdateCell = ({
  isAdmin = false,
  lastUpdateMessage,
  caseOverview,
  waitingUntil,
}: Props): React.JSX.Element => {
  const waitingText = waitingUntil ? waitingUntilText(waitingUntil) : '';
  const tooltipText = waitingUntil ? `${waitingText}${lastUpdateMessage}` : lastUpdateMessage;

  return (
    <TooltipCell caseOverview={caseOverview} isAdmin={isAdmin} tooltipText={tooltipText}>
      {waitingUntil && <Box.span fontWeight="fontWeightBold">{waitingText}</Box.span>}
      {lastUpdateMessage}
    </TooltipCell>
  );
};
