import {
  Step1Payload,
  Step2Payload,
  Step3Payload,
  Step4Payload,
} from 'modules/cases/pages/createCase/types';

export const step1InitialValues: Step1Payload = {
  talentId: undefined,
  firstName: '',
  lastName: '',
  email: '',
  companyId: '',
};

export const step2InitialValues: Step2Payload = {
  caseTypeId: '',
  serviceLevelId: '3',
  includesAccompanyingFamilyMembers: false,
  citizenshipCountry: '',
  countryOfResidence: '',
  destinationCountry: '',
  hrManagerId: '',
  followerIds: [],
};

export const step3InitialValues: Step3Payload = {
  jobDescriptionMetadata: '',
  jobTitle: '',
  startDate: '',
  workContractMetadata: '',
  costCenter: '',
  hiringManager: '',
  officeId: '',
};

export const step4InitialValues: Step4Payload = {
  allowanceCurrency: '',
  additionalServices: {},
  allowanceAmountDollars: 0,
};
