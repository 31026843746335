import { Box, HelpText, Input, Label } from '@localyze-pluto/components';
import { Section, SectionProps } from '../Section/Section';

import { CaseEvaluationSummarySection } from 'modules/pipelines/pages/caseEvaluationSummary/types/CaseEvaluationSummarySection';
import { EditorWithMacro } from 'modules/pipelines/pages/caseEvaluationSummary/components/EditorWithMacro/EditorWithMacro';
import React from 'react';
import { convertMacrosToItems } from 'modules/pipelines/pages/caseEvaluationSummary/utils/convertMacrosToItems/convertMacrosToItems';
import { useFormContext } from 'react-hook-form';
import { useMacros } from 'modules/pipelines/pages/caseEvaluationSummary/content/useMacros/useMacros';

export const VisaCategory = ({
  disabled,
  title,
}: Pick<SectionProps, 'disabled' | 'title'>): React.JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { data: macros } = useMacros({
    section: CaseEvaluationSummarySection.ExpectedPermitVisaCategory,
  });

  return (
    <Section title={title}>
      <Box.div display="flex" flexDirection="column" gap="d4">
        <Box.div w="50%">
          <Label htmlFor="visa_category" required>
            Permit/visa category
          </Label>
          <Input
            aria-describedby="visaCategoryHelpText"
            disabled={disabled}
            hasError={!!errors.visa_category}
            id="visa_category"
            placeholder="e.g. Blue card"
            type="text"
            {...register('visa_category', {
              required: { value: true, message: 'This field is required.' },
            })}
          />
          {errors.visa_category && (
            <HelpText hasError id="visaCategoryHelpText" role="alert">
              {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                String(errors.visa_category?.message)
              }
            </HelpText>
          )}
        </Box.div>

        <EditorWithMacro
          disabled={disabled}
          items={convertMacrosToItems(macros)}
          label="Additional comments (optional)"
          name="visa_category_comment"
        />
      </Box.div>
    </Section>
  );
};
