import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { OfficeWithOfficeInformation } from 'deprecated/api/companies/types/OfficeAPIResponse';
import { queryClient } from 'config/queryClient';
import { updateOffice, UpdateOfficeProps } from 'modules/offices/api/useUpdateOffice/updateOffice';

type UseUpdateOfficeResult = UseMutationResult<
  OfficeWithOfficeInformation,
  AxiosErrorWithMessage,
  UpdateOfficeProps
>;

/**
 * Updates an office
 * @param props.onSuccess - callback to call on success of request
 * @param props.onError - callback to call on error of request
 */
export const useUpdateOffice = ({
  onSuccess,
  onError,
}: {
  onError?: (error: AxiosErrorWithMessage) => void;
  onSuccess?: () => void;
}): UseUpdateOfficeResult => {
  return useMutation({
    mutationFn: updateOffice,
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: ['company-offices', variables.companyId] });
      await queryClient.invalidateQueries({
        queryKey: ['company-office', variables.companyId, variables.officeId],
      });

      onSuccess?.();
    },
    onError,
  });
};
