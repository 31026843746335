import { Box, HelpText, Label, Select, SelectItemProps } from '@localyze-pluto/components';
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnUndo,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { marked } from 'marked';

export type EditorWithMacroProps = Omit<ControllerProps, 'control' | 'render'> & {
  items: SelectItemProps[];
  disabled?: boolean;
  label: string;
};

export const EditorWithMacro = ({
  disabled,
  items,
  label,
  name,
  ...controllerProps
}: EditorWithMacroProps): React.JSX.Element => {
  const { setValue, control } = useFormContext();
  const selectId = `${name}-macro`;

  const opacity = { opacity: disabled ? '0.5' : '1' };

  const { rules } = controllerProps;

  const required = !!rules?.required;

  return (
    <Box.div display="flex" flexDirection="column" gap="d4">
      {!isEmpty(items) && (
        <Box.div w="50%">
          <Label htmlFor={selectId}>Macro</Label>
          <Select
            defaultValue=""
            disabled={disabled}
            id={selectId}
            items={items}
            setValue={(macro) => {
              setValue(name, marked.parse(macro as string, {}));
            }}
          />
        </Box.div>
      )}
      <Controller
        {...controllerProps}
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box.div>
            <Label htmlFor={name} id={`${name}-label`} required={required}>
              {label}
            </Label>
            <EditorProvider>
              <Editor
                aria-describedby={`${name}-error`}
                aria-labelledby={`${name}-label`}
                aria-multiline="true"
                aria-required={required}
                containerProps={{ style: { resize: 'vertical', height: '200px', ...opacity } }}
                disabled={disabled}
                onChange={onChange}
                role="textbox"
                value={value as string}
              >
                <Toolbar style={opacity}>
                  <BtnUndo />
                  <BtnRedo />
                  <Separator />
                  <BtnBold />
                  <BtnItalic />
                  <Separator />
                  <BtnNumberedList />
                  <BtnBulletList />
                  <Separator />
                  <BtnLink />
                  <BtnClearFormatting />
                </Toolbar>
              </Editor>
            </EditorProvider>
            {error && (
              <HelpText hasError id={`${name}-error`} role="alert">
                {error.message}
              </HelpText>
            )}
          </Box.div>
        )}
      />
    </Box.div>
  );
};
