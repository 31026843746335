import React, { useEffect, useState } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { ReportingTable } from './components/ReportingTable/ReportingTable';
import { ReportingTableCompany } from './components/ReportingTable/ReportingTableCompany/ReportingTableCompany';
import { Box, Tab, TabList, Tabs } from '@localyze-pluto/components';
import { trackPageView } from 'modules/analytics/analytics';
import { Tab as TabType } from 'types/Tab';
import { ReportingGraphs } from 'modules/reporting/pages/reporting/components/ReportingGraphs/ReportingGraphs';
import { useTabWithQueryParams } from 'deprecated/hooks/custom/useTabWithQueryParams';
import { useParams } from 'react-router-dom';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { useCurrentUser } from 'config/CurrentUserContext';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';

type ReportingTab = 'graphs' | 'table';

const getTabs = (): TabType<ReportingTab>[] => [
  { key: 'table', name: 'Table' },
  { key: 'graphs', name: 'Graphs' },
];

/**
 * The view for the reporting page
 */
export const Reporting = (): React.JSX.Element => {
  const reportingTabs = getTabs();
  const { tabs, selectedTab, getTabIndex, updateSelectedTab } =
    useTabWithQueryParams(reportingTabs);
  const { queryParams, addQueryParam, removeQueryParam } = useQueryParams();
  const { companyId } = useParams<{ companyId: string }>();
  const { company_id, isAdmin, user_role } = useCurrentUser();
  const [startDate, setStartDate] = useState<string | undefined>(queryParams.start_date);
  const [endDate, setEndDate] = useState<string | undefined>(queryParams.end_date);

  const reportCompanyId = isAdmin ? Number(companyId) : company_id;

  const { data: company } = useCompany({ companyId: reportCompanyId });

  const onChangeStartDate = (date: string, tabKey: string) => {
    setStartDate(date);
    date ? addQueryParam('start_date', date) : removeQueryParam('start_date');

    trackEvent('reporting: select specific timeframe', {
      company_id,
      user_role,
      reporting_type: tabKey,
      start_date: startDate,
      end_date: endDate,
    });
  };

  const onChangeEndDate = (date: string, tabKey: string) => {
    setEndDate(date);
    date ? addQueryParam('end_date', date) : removeQueryParam('end_date');

    trackEvent('reporting: select specific timeframe', {
      company_id,
      user_role,
      reporting_type: tabKey,
      start_date: startDate,
      end_date: endDate,
    });
  };

  useEffect(() => {
    trackPageView('Reporting');
  }, []);

  return (
    <PageLayout flexed title="Reporting">
      {company && <ReportingTableCompany company={company.name} />}
      <Tabs initialTabId={selectedTab.key} key={selectedTab.key}>
        <TabList aria-label="Report tabs" withDivider>
          {tabs.map((tab) => (
            <Tab
              id={tab.key}
              key={tab.key}
              onClick={() => {
                trackEvent('reporting: choose type of report', {
                  company_id,
                  user_role,
                  reporting_type: tab.key,
                });

                updateSelectedTab(getTabIndex(reportingTabs, tab.key));
              }}
            >
              {tab.name}
            </Tab>
          ))}
        </TabList>
        <Box.div
          display="flex"
          flexDirection="column"
          flexGrow="1"
          key={selectedTab.key}
          marginTop="m4"
        >
          {selectedTab.key === 'table' && (
            <ReportingTable
              companyId={Number(reportCompanyId)}
              endDate={endDate}
              onChangeEndDate={(date) => onChangeEndDate(date, selectedTab.key)}
              onChangeStartDate={(date) => onChangeStartDate(date, selectedTab.key)}
              startDate={startDate}
            />
          )}
          {selectedTab.key === 'graphs' && (
            <ReportingGraphs
              companyId={Number(reportCompanyId)}
              endDate={endDate}
              onChangeEndDate={(date) => onChangeEndDate(date, selectedTab.key)}
              onChangeStartDate={(date) => onChangeStartDate(date, selectedTab.key)}
              startDate={startDate}
            />
          )}
        </Box.div>
      </Tabs>
    </PageLayout>
  );
};
