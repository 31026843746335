import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { BillingPeriod } from 'modules/billing/types/BillingPeriod';
import { UseBillingPeriodsProps } from 'modules/billing/hooks/useBillingPeriods/useBillingPeriods.types';

export const BILLING_PERIODS_QUERY_KEY = 'billing-periods';

const getBillingPeriods = async (): Promise<BillingPeriod[]> => {
  const { data } = await axios.get<BillingPeriod[]>('/admin/billing_periods');

  return data;
};

/**
 * Gets list of periods for billing, must be admin
 * @param props.select - transform returned data
 * @returns billing periods
 */
export const useBillingPeriods = <Result = BillingPeriod[]>({
  select,
}: UseBillingPeriodsProps<Result> = {}): UseQueryResult<Result, AxiosError> => {
  return useQuery({
    queryKey: [BILLING_PERIODS_QUERY_KEY],
    queryFn: () => getBillingPeriods(),
    select,
  });
};
