import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import axios from 'axios';
import { queryClient } from 'config/queryClient';
import { getCasePipelinesQueryKey } from 'modules/pipelines/hooks/useCasePipelines';

type OpenStepProps = {
  caseStepId: number;
  opening_reason: string;
};

const openStep = async ({ caseStepId, opening_reason }: OpenStepProps): Promise<void> => {
  await axios.post<{ opening_reason: string }>(`/case/steps/${caseStepId}/open`, {
    opening_reason,
  });
};

export const useOpenStep = ({
  caseId,
  onError,
  onSuccess,
}: Pick<UseMutationOptions<void, AxiosErrorWithMessage, OpenStepProps>, 'onError' | 'onSuccess'> & {
  caseId: number;
}): UseMutationResult<void, unknown, OpenStepProps> => {
  return useMutation({
    mutationFn: openStep,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getCasePipelinesQueryKey(caseId),
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
