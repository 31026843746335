import React from 'react';
import { Box, ConfirmationModal, ModalStore } from '@localyze-pluto/components';
import { formatPrice } from 'utils/formatters/formatPrice/formatPrice';
import { Currency } from 'types/currency';

export const ConfirmIntegrationModal = ({
  onConfirm,
  onCancel,
  modalState,
  currency,
}: {
  onConfirm: () => void;
  modalState: ModalStore;
  currency: Currency;
  onCancel: () => void;
}): React.JSX.Element => {
  return (
    <ConfirmationModal onCancel={onCancel} onConfirm={onConfirm} store={modalState}>
      <Box.div marginBottom="m2">
        You are about to activate an integration. The changes will reflect in your monthly bill.
      </Box.div>
      <Box.div marginBottom="m2">
        Once the integration is activated, you will be charged a {formatPrice(1500, currency)}{' '}
        implementation fee, which covers
      </Box.div>
      <ul>
        <li>Access to the documentation</li>
        <li>The technical setup</li>
        <li>48h SLAs on questions regarding the integration for the first 14 days</li>
      </ul>
      Please contact our customer success team if you have any questions.
    </ConfirmationModal>
  );
};
