import { Box, ControlledFormSelect, Heading } from '@localyze-pluto/components';
import isEmpty from 'lodash/isEmpty';
import { HRManagerOptionsHelpText } from 'components/HRManagerOptionsHelpText/HRManagerOptionsHelpText';
import filter from 'lodash/filter';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MultiUserSelect } from 'components/MultiUserSelect/MultiUserSelect';
import { FollowersOptionsHelpText } from 'components/FollowersOptionsHelpText/FollowersOptionsHelpText';
import { mapUsersToMultiUserSelectItems } from 'utils/mapUsersToMultiUserSelectItems';
import React from 'react';
import { UserRoleName } from 'modules/users/types/UserRoleName';
import { mapUsersToSelectOptions } from 'modules/users/utils/mapUsersToSelectOptions';
import { useCompanyUsers } from 'modules/company/api/useCompanyUsers/useCompanyUsers';
import { getAllowedFollowers } from 'modules/utils/getAllowedFollowers';
import { Step2Payload } from 'modules/cases/pages/createCase/types';

export const ResponsibleForTheCase = ({
  companyId,
  control,
  setValue,
  watch,
  isDemoModeActive,
}: {
  companyId: string;
  control: Control<Step2Payload, string>;
  setValue: UseFormSetValue<Step2Payload>;
  watch: UseFormWatch<Step2Payload>;
  isDemoModeActive: boolean;
}): React.JSX.Element => {
  const { data: companyUsers, isLoading: isLoadingCompanyUsers } = useCompanyUsers({
    params: {
      companyId: isEmpty(companyId) ? undefined : Number(companyId),
    },
  });

  const notViewerUsers = companyUsers?.filter((user) => user.role_name !== UserRoleName.Viewer);
  const hrManagerOptions = mapUsersToSelectOptions(notViewerUsers);

  return (
    <Box.div display="flex" flexDirection="column" gap="d5">
      <Heading as="h3" color="contentPrimary" marginBottom="d0" size="heading50">
        Who is responsible for this case?
      </Heading>
      <>
        <ControlledFormSelect
          control={control}
          disabled={isEmpty(companyId) || isLoadingCompanyUsers}
          helpText={
            isEmpty(companyId) ? (
              'Select a company to see HR Managers'
            ) : (
              <HRManagerOptionsHelpText />
            )
          }
          id="hrManager"
          items={hrManagerOptions}
          label="HR Manager responsible"
          name="hrManagerId"
          required={!isDemoModeActive}
          setValue={(value) => {
            setValue('hrManagerId', value as string);
            setValue(
              'followerIds',
              filter(watch('followerIds'), (followerId) => followerId !== value),
            );
          }}
        />

        <Controller
          control={control}
          name="followerIds"
          render={({ field: { onChange, value, name } }) => {
            return (
              <MultiUserSelect
                disabled={isEmpty(companyId) || isLoadingCompanyUsers}
                helpText={
                  isEmpty(companyId) ? (
                    'Select a company to see followers'
                  ) : (
                    <FollowersOptionsHelpText />
                  )
                }
                items={mapUsersToMultiUserSelectItems(
                  getAllowedFollowers(companyUsers, Number(watch('hrManagerId'))),
                )}
                label="Follower (optional)"
                name={name}
                onChange={(value) => onChange(value)}
                value={value}
              />
            );
          }}
        />
      </>
    </Box.div>
  );
};
