import { Anchor, Box, Button } from '@localyze-pluto/components';
import React, { useMemo } from 'react';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { Link } from 'react-router-dom';
import LocalyzeIcon from 'assets/img/localyze-logo-globe.png';
import { PERMISSIONS_DOCUMENTATION_URL } from 'constants/permissions';
import includes from 'lodash/includes';
import { parseSelectedFilters } from 'deprecated/libs/filters';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { useTabWithQueryParams } from 'deprecated/hooks/custom/useTabWithQueryParams';
import { TABS } from 'modules/talent/utils/constants';
import { getCaseOverviewsFilters } from 'modules/caseOverview/utils/getCaseOverviewsFilters';
import { getCaseOverviewsEmptyMessage } from 'modules/caseOverview/utils/getCaseOverviewsEmptyMessage';
import { useCurrentUser } from 'config/CurrentUserContext';
import { getTabsByUserRole } from 'modules/utils/getTabsByUserRole';

/*
 These filters are always selected by default, so we don't want to count them as applied filters.
 */
const IGNORED_FILTERS = ['sort_by'];

const renderEmptyStateChildren = (selectedTab: string) => {
  switch (selectedTab) {
    case 'my':
      return (
        <Anchor href={PERMISSIONS_DOCUMENTATION_URL} rel="noopener noreferrer" target="_blank">
          Find out more about roles and permissions.
        </Anchor>
      );
    case 'all':
      return (
        <Button as={Link} leadingIcon="user-plus" to="/create-case" variant="primary">
          New Case
        </Button>
      );
    default:
      return null;
  }
};

export const CaseOverviewsEmptyState = (): React.JSX.Element => {
  const { company_id, user_role } = useCurrentUser();
  const { queryParams } = useQueryParams();

  const { data: countries } = useCountries({
    params: {
      association: 'cases',
      filter: 'destination',
    },
  });

  const { selectedTab } = useTabWithQueryParams(getTabsByUserRole(TABS, user_role || null));

  const filters = getCaseOverviewsFilters(company_id, countries || []);

  const selectedFilters = useMemo(
    () => parseSelectedFilters(filters, queryParams),
    [filters, queryParams],
  );

  const hasAppliedFilters = useMemo(
    () =>
      filters.some((filter) => {
        if (includes(IGNORED_FILTERS, filter.attribute)) {
          return false;
        }
        return selectedFilters[filter.attribute];
      }),
    [filters, selectedFilters],
  );

  return (
    <Box.div alignItems="center" display="flex" h="100%" justifyContent="center">
      <EmptyState
        illustration={<Box.img src={LocalyzeIcon} width="50px" />}
        text={getCaseOverviewsEmptyMessage(selectedTab.key, hasAppliedFilters)}
      >
        {hasAppliedFilters ? null : renderEmptyStateChildren(selectedTab.key)}
      </EmptyState>
    </Box.div>
  );
};
