import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { ValueFormatterParams } from 'ag-grid-community';

/**
 * Makes currency value human friendly with commas
 * @param params.value - unformatted number
 * @returns formatted currency value
 */
export const formatCurrency = ({
  value,
}: Pick<ValueFormatterParams<CaseReport, number | string | null>, 'value'>): string =>
  Number(value).toLocaleString();
