import { Box } from '@localyze-pluto/components';
import React from 'react';

const TableWrapper = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  return (
    <Box.div flex="1 1 auto" h="20rem" minWidth="100%" overflowY="auto" w="100%">
      {children}
    </Box.div>
  );
};

export default TableWrapper;
