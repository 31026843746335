import {
  Box,
  ConfirmationModal,
  Heading,
  Paragraph,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import React, { useEffect, useState } from 'react';
import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard';
import { SubscriptionPlanWithFeatures } from '../../types/SubscriptionPlanWithFeatures';

import { useSubscriptionPlans } from '../../hooks/useSubscriptionPlans';
import { Company } from 'modules/company/types/Company';

const DIALOG_CONTENT = `You are about to change your subscription.
  The changes will reflect in your monthly bill.
  Please contact our customer success team if you have any questions.`;

export const SubscriptionPlans = ({ company }: { company: Company }): React.JSX.Element => {
  const companyId = company.id;
  const currency = company.currency;
  const modalState = useModalStore();
  const isModalOpen = modalState.useState('open');
  const toast = useToast();
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState<Maybe<SubscriptionPlanWithFeatures>>(null);

  const { subscriptions, current, recommended, errorMessage, changeSubscriptionPlan } =
    useSubscriptionPlans(companyId);

  const displayConfirmationModal = (subscription: SubscriptionPlanWithFeatures) => {
    setSelectedSubscriptionPlan(subscription);
    modalState.show();
  };

  const updateSubscriptionPlan = () => {
    if (selectedSubscriptionPlan) {
      changeSubscriptionPlan(selectedSubscriptionPlan);
      modalState.hide();
    }
  };

  useEffect(() => {
    if (errorMessage) {
      toast(errorMessage, 'error');
    }
    // adding the toast causes the test to be in an endless loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <Box.div paddingTop="d10">
      <Heading as="h3" size="heading60">
        Subscription details
      </Heading>

      <Box.div marginBottom="d7">
        <Paragraph marginBottom="m0">
          If your contract covers a subscription plan, please see below. If not, please see your
          contract for details.
        </Paragraph>
      </Box.div>
      <Box.div columnGap="d6" display="grid" gridTemplateColumns="256px 256px 256px">
        {subscriptions.map((subscription) => (
          <SubscriptionCard
            currency={currency}
            current={subscription.id === current?.id}
            key={subscription.id}
            onSelect={displayConfirmationModal}
            recommended={subscription.id === recommended?.id}
            subscription={subscription}
          />
        ))}
      </Box.div>
      {isModalOpen && (
        <ConfirmationModal onConfirm={updateSubscriptionPlan} store={modalState}>
          {DIALOG_CONTENT}
        </ConfirmationModal>
      )}
    </Box.div>
  );
};
