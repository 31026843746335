import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { CaseEvaluationSummary } from '../../types/CaseEvaluationSummary';
import { FormValues } from './useSaveCaseEvaluationSummary.types';
import { removeEmptyValues } from '../../utils/removeEmptyValues/removeEmptyValues';
import { useEstimatedCaseDuration } from 'modules/cases/api/useEstimatedCaseDuration/useEstimatedCaseDuration';
import { useParams } from 'react-router-dom';
import { useCase } from 'modules/cases/api/useCase/useCase';

type SaveCaseEvaluationSummaryProps = {
  formValues: FormValues;
  processId: number;
  processPipelineId: number;
  estimatedCaseDurationInDays?: Maybe<number>;
};

const saveCaseEvaluationSummary = async ({
  formValues,
  processId,
  processPipelineId,
  estimatedCaseDurationInDays,
}: SaveCaseEvaluationSummaryProps): Promise<CaseEvaluationSummary> => {
  const payload = removeEmptyValues({
    ...formValues,
    estimated_case_duration_in_days: estimatedCaseDurationInDays,
    process_id: processId,
    process_pipeline_id: processPipelineId,
  });

  const { data } = await axios.post<CaseEvaluationSummary>('/case_evaluation/summaries', payload);

  return data;
};

export const useSaveCaseEvaluationSummary = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: UseMutationOptions<
    CaseEvaluationSummary,
    AxiosError,
    { formValues: FormValues; processPipelineId: number }
  >['onSuccess'];
}): UseMutationResult<
  CaseEvaluationSummary,
  AxiosError,
  { formValues: FormValues; processPipelineId: number }
> => {
  const { caseId, pipelineId } = useParams<{ caseId: string; pipelineId: string }>();
  const { data: kase } = useCase({ caseId: Number(caseId) });

  const { data } = useEstimatedCaseDuration({
    pipelineId: Number(pipelineId),
    originCountryId: kase?.origin_country_id,
  });
  const estimatedCaseDurationInDays = data?.estimated_case_duration_in_days;

  return useMutation({
    mutationFn: ({ formValues, processPipelineId }) =>
      saveCaseEvaluationSummary({
        formValues,
        processId: Number(caseId),
        processPipelineId,
        estimatedCaseDurationInDays,
      }),
    onSuccess,
    onError,
  });
};
