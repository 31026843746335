import { Box, Button, Heading, Paragraph, useToast } from '@localyze-pluto/components';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import React, { Fragment, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { getSections } from 'modules/pipelines/pages/caseEvaluationSummary/utils/getSections/getSections';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useAssignPipeline } from 'modules/pipelines/hooks/useAssignPipeline';
import { usePipelineRequiredDocuments } from 'modules/pipelines/hooks/usePipelineRequiredDocuments';
import { useSaveCaseEvaluationSummary } from './api/useSaveCaseEvaluationSummary/useSaveCaseEvaluationSummary';
import { useTalentQuery } from 'modules/talent/api/useTalentQuery/useTalentQuery';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { CaseEvaluationSummary as CaseEvaluationSummaryType } from './types/CaseEvaluationSummary';
import { useCaseEvaluationSummary } from 'modules/pipelines/pages/caseEvaluationSummary/api/useCaseEvaluationSummary/useCaseEvaluationSummary';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useCase } from 'modules/cases/api/useCase/useCase';

const defaultValues = {
  visa_category: '',
  visa_category_comment: '',
  required_documents_comment: '',
  start_date_can_be_met: true,
  start_date_postponed_weeks: 1,
  waiting_time_for_authority_appointment: '',
  family_support_section: true,
  family_support_comment: '',
  anything_else_section: true,
  anything_else_comment: '',
} as CaseEvaluationSummaryType;

export const CaseEvaluationSummary = (): React.JSX.Element => {
  const { caseId, summaryId } = useParams<{
    caseId: string;
    summaryId: string;
  }>();
  const {
    queryParams: { pipelineId },
  } = useQueryParams();

  const {
    data: kase,
    isSuccess: isSuccessCase,
    isError: isErrorCase,
  } = useCase({ caseId: Number(caseId) });

  const { data: talent, isSuccess: isTalentSuccess, isError } = useTalentQuery(kase?.talent_id);
  const { first_name: currentUserFirstName } = useCurrentUser();

  const routes = useRoutes();
  const toast = useToast();

  const { data: requiredDocuments, isSuccess: isRequiredDocumentsSuccess } =
    usePipelineRequiredDocuments(Number(pipelineId));

  const { data: caseEvaluation, isSuccess: isCaseEvaluationSummarySuccess } =
    useCaseEvaluationSummary({
      caseEvaluationSummaryId: Number(summaryId),
    });

  const history = useHistory();
  const contentRef = useRef<HTMLDivElement>(null);

  const methods = useForm({
    defaultValues,
  });

  React.useEffect(() => {
    if (isCaseEvaluationSummarySuccess) {
      methods.reset({ ...caseEvaluation });
    }
  }, [caseEvaluation, isCaseEvaluationSummarySuccess, methods]);

  const scrollToTop = () => {
    // The scrollTo validation is needed because it doesn't exist in the tests
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (contentRef.current && contentRef.current.scrollTo) {
      contentRef.current.scrollTo(0, 0);
    }
  };

  const { mutate: saveCaseEvaluationSummary } = useSaveCaseEvaluationSummary({
    onSuccess: () => {
      toast('Case evaluation summary & pipeline were successfully sent to the talent.', 'success');
      scrollToTop();
      history.push(routes.case.get(kase?.id));
    },
    onError: () => {
      toast("Sorry, an error occurred. Summary couldn't be saved. Please try again.", 'error');
    },
  });

  const { mutate: assignPipeline } = useAssignPipeline({
    onSuccess: (processPipeline) => {
      saveCaseEvaluationSummary({
        formValues: methods.getValues(),
        processPipelineId: processPipeline.id,
      });
    },
    onError: () => {
      toast("Sorry, an error occurred. Pipeline couldn't be assigned. Please try again.", 'error');
    },
  });

  if (isError || isErrorCase) {
    return <ErrorStatePage />;
  }

  if (!isTalentSuccess || !isRequiredDocumentsSuccess || !isSuccessCase) {
    return <ContainedLoadingState />;
  }

  const hasRequiredDocuments = !isEmpty(requiredDocuments);
  const sections = getSections(hasRequiredDocuments);

  const handleSubmitSummary = () => {
    pipelineId &&
      assignPipeline({
        caseId: Number(caseId),
        pipelineId: Number(pipelineId),
      });
    trackEvent('case evaluation summary: click on submit button', {
      pipelineId: Number(pipelineId),
      caseId: Number(caseId),
    });
  };

  return (
    <PageLayout flexed>
      <Box.main
        alignItems="center"
        backgroundColor="bgPrimary"
        borderRadius="borderRadius40"
        display="flex"
        flexDirection="column"
        h="100%"
        marginBottom="m6"
        px={{ _: 'p6', lg: 'p0' }}
        py="p8"
      >
        <Box.div
          display="flex"
          flexDirection="column"
          gap="d12"
          h="100%"
          maxW="814px"
          ref={contentRef}
        >
          <Box.div display="flex" flexDirection="column" gap="d2">
            <Heading as="h1" marginBottom="m0" size="heading40">
              {`Complete ${talent.first_name}’s Case Evaluation`}
            </Heading>
            <Paragraph fontSize="fontSize40" lineHeight="lineHeight50">
              {`Hey ${currentUserFirstName}, we need some more information so ${talent.first_name}
                knows what to expect for their upcoming journey.`}
            </Paragraph>
          </Box.div>
          <FormProvider {...methods}>
            <Box.form
              display="flex"
              flexDirection="column"
              gap="d12"
              onSubmit={methods.handleSubmit(handleSubmitSummary)}
            >
              {map(sections, ({ title, component: Section }, index: number) => {
                const sectionNumber = index + 1;
                return (
                  <Fragment key={`case-evaluation-summary-section-${index}`}>
                    <SectionDivider />
                    <Section disabled={!!summaryId} title={`${sectionNumber}. ${title}`} />
                  </Fragment>
                );
              })}
              <Box.div display="flex" flexDirection="column" gap="d8">
                <SectionDivider />
                <Box.div alignItems="center" display="flex" justifyContent="flex-end">
                  <Button disabled={!!summaryId} type="submit" variant="primary">
                    Send to talent
                  </Button>
                </Box.div>
              </Box.div>
            </Box.form>
          </FormProvider>
        </Box.div>
      </Box.main>
    </PageLayout>
  );
};
