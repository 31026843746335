import {
  useParameterFilter,
  UseParameterFilterResult,
} from 'modules/filters/hooks/useParameterFilter';
import { useEffect } from 'react';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import isEmpty from 'lodash/isEmpty';

export const usePagination = (): UseParameterFilterResult => {
  const [page, setPage] = useParameterFilter('page');
  const { queryParams } = useQueryParams();

  // we need this to set the page correctly after changing another filter on the same page
  // which resets the page in the queryParams to 1, which should change the page in the state
  // to mark the correct page as current page in the pagination component
  useEffect(() => {
    if (queryParams['page'] !== page && !isEmpty(queryParams['page'])) {
      setPage(queryParams['page']);
    }
  }, [queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return [page || '1', setPage];
};
