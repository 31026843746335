import { SystemProp, Theme } from '@localyze-pluto/theme';
import { MessageType } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/MessageType';

type GetMessageItemBorderStylesReturnedProps = {
  borderStyle?: SystemProp<keyof Theme['borderStyles'], Theme>;
  borderWidth?: SystemProp<keyof Theme['borderWidths'], Theme>;
  borderColor?: SystemProp<keyof Theme['colors'], Theme>;
};

export const getMessageItemBorderStyles = (
  type: MessageType,
  isPinned: boolean,
): GetMessageItemBorderStylesReturnedProps | undefined => {
  const baseStyles: GetMessageItemBorderStylesReturnedProps = {
    borderStyle: 'borderStyleSolid',
    borderWidth: 'borderWidth10',
  };

  if (type === 'internal' && isPinned) {
    return {
      ...baseStyles,
      borderColor: 'colorBorderWeaker',
    };
  }

  return undefined;
};
