import { useActiveCompanyHRISIntegration } from 'modules/integrations/hooks/useActiveCompanyHRISIntegration/useActiveCompanyHRISIntegration';
import { useCallback, useState } from 'react';
import { getStackOneSessionToken } from 'modules/integrations/hooks/getStackOneSessionToken';
import { trackEvent } from 'deprecated/utils/helper/segment/index';
import { StackOneIntegration } from 'modules/integrations/hooks/getIntegrationCardSettings';
import { useStackOneHub } from '@stackone/react-hub';
import { useCurrentUser } from 'config/CurrentUserContext';
import { StackOneProvider } from 'modules/integrations/types/HRISCompanyIntegration';

type SuccessCallback = (account: { id: string; providerName: StackOneProvider }) => void;

type UseStackOneConnect = {
  activateIntegration: (integration: StackOneIntegration, onSuccess: SuccessCallback) => void;
  loading: boolean;
};

export const useStackOneConnect = (): UseStackOneConnect => {
  const { company_id } = useCurrentUser();

  const { refetch: refetchIntegrations } = useActiveCompanyHRISIntegration(company_id);
  const [loading, setLoading] = useState(false);

  const { startConnect } = useStackOneHub();

  const connectStackOneAccount = useCallback(
    async (integration: StackOneIntegration, onSuccess?: SuccessCallback) => {
      setLoading(true);

      const { token } = await getStackOneSessionToken({
        provider: integration.providerName,
      });

      startConnect({
        sessionToken: token,
        onSuccess: (account) => {
          if (onSuccess) {
            onSuccess({
              id: account.id,
              providerName: account.provider as StackOneProvider,
            });

            setLoading(false);
            refetchIntegrations();

            return;
          }
        },
        onClose: () => {
          setLoading(false);
        },
        onCancel: () => {
          setLoading(false);
        },
      });
    },
    [refetchIntegrations, startConnect],
  );

  const activateIntegration = (integration: StackOneIntegration, onSuccess?: SuccessCallback) => {
    if (integration.status === 'inactive') {
      trackEvent('integrations: activate integration', { integrationName: integration.label });
    }
    connectStackOneAccount(integration, onSuccess);
  };

  return {
    activateIntegration,
    loading,
  };
};
