import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { HRISProvider } from 'modules/integrations/types/HRISCompanyIntegration';
import { HRISTalent } from 'modules/integrations/types/HRISTalent';
import axios from 'axios';

export const HRIS_TALENT_QUERY_KEY = 'HRIS-talent';

export type UseHRISTalentProps<Result> = Omit<
  UseQueryOptions<HRISTalent, AxiosErrorWithMessage, Result>,
  'queryFn' | 'queryKey'
> & {
  params: {
    companyId?: number;
    providerName?: HRISProvider;
    uid?: string;
  };
};

/**
 * Make a get request to fetch companies HRIS integration info
 * @param companyId - id of company to fetch for
 * @param providerName - name of HRIS provider (e.g. 'bamboo')
 * @param uid - id of talent to fetch
 * @returns Talent data from HRIS partner
 */
const getTalentFromHRISIntegration = async (
  companyId?: number,
  providerName?: HRISProvider,
  uid?: string,
): Promise<HRISTalent> => {
  if (!companyId || !providerName || !uid) {
    throw new Error('Missing required params');
  }

  uid = btoa(String(uid));

  const { data } = await axios.get<HRISTalent>(
    `/companies/${companyId}/integrations/${providerName}/internationals/${uid}`,
    { headers: { Accept: 'application/json' } },
  );

  return data;
};

/**
 * Hook to fetch Talent data from a HRIS integration
 * @param props.params.companyId - id of company to fetch for
 * @param props.params.providerName - name of HRIS provider (e.g. 'bamboo')
 * @param props.params.uid - id of talent to fetch
 * @param props.enabled - additional conditions to enable the query
 * @param props - react query props
 * @returns HRISTalent
 */
export const useHRISTalent = <Result = HRISTalent>({
  params,
  enabled,
  ...props
}: UseHRISTalentProps<Result>): UseQueryResult<Result, AxiosErrorWithMessage> =>
  useQuery({
    queryKey: [HRIS_TALENT_QUERY_KEY, params.uid, params.companyId, params.providerName],
    queryFn: () => getTalentFromHRISIntegration(params.companyId, params.providerName, params.uid),
    enabled: !!params.companyId && !!params.providerName && !!params.uid && enabled,
    // otherwise when entering an invalid employee id the whole create case form is replaced
    // with an error page
    throwOnError: false,
    ...props,
  });
