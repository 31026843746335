import React, { ReactNode } from 'react';
import { GradientCounter } from './Variants/GradientCounter';
import { SimpleCounter } from './Variants/SimpleCounter';

export type StatusCounterVariant = 'gradient' | 'simple';

export interface StatusCounterProps {
  readonly title: string;
  readonly icon?: ReactNode;
  readonly count?: number;
  readonly className?: string;
  readonly gradientClasses?: string;
  readonly lineBreak?: boolean;
  /**
   * @desc The variant of the status counter
   * @default simple
   */
  readonly variant?: StatusCounterVariant;
}

export const StatusCounter = ({
  variant = 'simple',
  ...props
}: StatusCounterProps): React.JSX.Element => {
  switch (variant) {
    case 'simple':
      return <SimpleCounter {...props} />;
    case 'gradient':
      return <GradientCounter {...props} />;
  }
};
