import React, { useEffect } from 'react';
import { Box, Icon, useToast } from '@localyze-pluto/components';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { useMessages } from 'modules/talent/api/useMessages/useMessages';
import { useDeleteMessage } from 'modules/talent/api/useDeleteMessage/useDeleteMessage';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import type { Message } from 'modules/talent/types/Message';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';
import { mapTypeOfMessage } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/helpers';
import { MessageItem } from 'modules/caseDetails/components/CaseActivityCard/MessageItem/MessageItem';
import { PostNewItem } from '../PostNewItem/PostNewItem';
import { useCreateMessage } from 'modules/talent/api/useCreateMessage/useCreateMessage';

const MESSAGE_CHAR_LIMIT = 500;

export const Messages = (): React.JSX.Element => {
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const caseId = Number(id);
  const { isAdmin, id: currentUserId } = useCurrentUser();

  const { data: messages, isSuccess: isSuccessMessages, isError, error } = useMessages({ caseId });

  const { mutate: createMessage } = useCreateMessage({
    onSuccess: () => {
      toast('New Message was created successfully.', 'success');
    },
    onError: (error) => {
      const toastMessage = error.message || 'Error creating new Message.';

      toast(toastMessage, 'error');
    },
    internalMessage: false,
  });

  const { mutate: deleteMessage } = useDeleteMessage({
    onSuccess: () => {
      toast('Deleted message successfully.', 'success');
    },
    onError: (error) => {
      toast(error.message || 'Error deleting message.', 'error');
    },
  });

  useEffect(() => {
    if (isError) {
      toast(error.message || 'Error fetching messages.', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const canDelete = (message: Message): boolean => message.author.id === currentUserId && isAdmin;

  const handleDelete = (messageId: number): void => {
    deleteMessage({ messageId });
  };

  const onPostNewMessage = (message: string): void => {
    createMessage({
      caseId,
      body: message,
      internal: false,
    });
  };

  if (!isSuccessMessages) {
    return <ContainedLoadingState />;
  }

  return (
    <Box.div display="grid" gridTemplateColumns={isAdmin ? '2fr 1fr' : undefined} h="100%">
      {isEmpty(messages) ? (
        <EmptyState
          gap="d2"
          illustration={
            <Icon color="contentTertiary" decorative icon="mail-plus" size="sizeIcon30" />
          }
          text="There are no messages yet."
        />
      ) : (
        <Box.ul
          display="flex"
          flexDirection="column"
          gap="d4"
          marginBottom="d0"
          overflowY="auto"
          p="d6"
          w="100%"
        >
          {messages.map((message) => {
            const { created_at, id, body, author, author_type, internal_message } = message;
            const messageFromUser = message.author.id === currentUserId;

            return (
              <Box.div
                display="flex"
                justifyContent={messageFromUser ? 'flex-end' : 'flex-start'}
                key={message.id}
              >
                <MessageItem
                  date={new Date(created_at)}
                  key={id}
                  message={body}
                  messageFrom={author.first_name}
                  messageId={id}
                  onDelete={canDelete(message) ? handleDelete : undefined}
                  type={mapTypeOfMessage(author_type, internal_message)}
                  w={isAdmin ? '100%' : '70%'}
                />
              </Box.div>
            );
          })}
        </Box.ul>
      )}
      {isAdmin && (
        <Box.div
          borderLeftColor="borderSecondary"
          borderLeftStyle="borderStyleSolid"
          borderLeftWidth="borderWidth10"
          p="d6"
        >
          <PostNewItem
            charLimit={MESSAGE_CHAR_LIMIT}
            onPostNewItem={onPostNewMessage}
            placeholder="Post a new message..."
            textAreaAriaLabel="Post a new message"
          />
        </Box.div>
      )}
    </Box.div>
  );
};
