import { Sizes } from '../../types';

type ClassMap = Record<string, boolean>;

export const completeContainerClasses = 'tw-rounded-full tw-bg-blue-500 tw-p-0.5 tw-relative';

export const currentContainerClasses = `tw-rounded-full 
  tw-border-2 tw-border-solid tw-border-blue-500 tw-bg-white 
  tw-flex tw-justify-center tw-items-center`;

export const incompleteContainerClasses = `tw-rounded-full 
tw-border-2 tw-border-solid tw-border-gray-300 tw-bg-white 
  tw-flex tw-justify-center tw-items-center`;

export const getContainerSizeClass = (size: Sizes): ClassMap => ({
  'tw-w-5 tw-h-5': size === Sizes.Small,
  'tw-w-6 tw-h-6': size === Sizes.Large,
});

export const getIconSizeClass = (size: Sizes): ClassMap => ({
  'tw-w-4 tw-h-4': size === Sizes.Small,
  'tw-w-5 tw-h-5': size === Sizes.Large,
});

export const getDotSizeClass = (size: Sizes): ClassMap => ({
  'tw-w-1.5 tw-h-1.5': size === Sizes.Small,
  'tw-w-2.5 tw-h-2.5': size === Sizes.Large,
});
