import { Checkbox, Paragraph } from '@localyze-pluto/components';
import { Section, SectionProps } from '../Section/Section';

import { CaseEvaluationSummarySection } from 'modules/pipelines/pages/caseEvaluationSummary/types/CaseEvaluationSummarySection';
import { EditorWithMacro } from 'modules/pipelines/pages/caseEvaluationSummary/components/EditorWithMacro/EditorWithMacro';
import React from 'react';
import { convertMacrosToItems } from 'modules/pipelines/pages/caseEvaluationSummary/utils/convertMacrosToItems/convertMacrosToItems';
import { useFormContext } from 'react-hook-form';
import { useMacros } from 'modules/pipelines/pages/caseEvaluationSummary/content/useMacros/useMacros';

export const AnythingElse = ({
  disabled,
  title,
}: Pick<SectionProps, 'disabled' | 'title'>): React.JSX.Element => {
  const { register, setValue, watch, unregister } = useFormContext();
  const { data: macros } = useMacros({ section: CaseEvaluationSummarySection.AnythingElse });

  const watchedShowAnythingElse = watch('anything_else_section') as boolean;

  const handleShowAnythingElse = (value: boolean) => {
    if (!value) {
      unregister('anything_else_comment', { keepDirty: false });
    }
    setValue('anything_else_section', value);
  };

  return (
    <Section
      description={
        <>
          <Paragraph>
            Please select the checkbox below to show this section to your talent if you have further
            information you want your talent to know, e.g. about a required travel insurance or
            about the booking of visa appointments (3rd party tool).
          </Paragraph>
          <Paragraph>
            Remember all step related information should be on the pipeline step.
          </Paragraph>
        </>
      }
      title={title}
    >
      <Checkbox
        checked={watchedShowAnythingElse}
        disabled={disabled}
        id="anything_else_section"
        onCheckedChange={handleShowAnythingElse}
        {...register('anything_else_section')}
      >
        Show this section to your talent
      </Checkbox>

      <EditorWithMacro
        disabled={disabled || !watchedShowAnythingElse}
        items={convertMacrosToItems(macros)}
        label="Additional information"
        name="anything_else_comment"
        rules={{ required: { value: watchedShowAnythingElse, message: 'This field is required.' } }}
      />
    </Section>
  );
};
