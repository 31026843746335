import { useAuth0 } from '@auth0/auth0-react';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { setWindowDataLayer, useAnalyticsData } from 'modules/analytics/api/useAnalyticsData';
import { useAuthCheck } from 'modules/auth/api/useAuthCheck/useAuthCheck';
import { AuthError } from 'modules/auth/components/AuthError';

import React, { useEffect } from 'react';
import { CurrentUserProvider } from 'config/CurrentUserContext';

export const UserAuthManager = ({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element | null => {
  const { loginWithRedirect, logout, error: auth0Error, isLoading, isAuthenticated } = useAuth0();
  const { user, hasUser, hasUserSessionError } = useAuthCheck();

  const analyticsQuery = useAnalyticsData({ enabled: !!user?.id });

  useEffect(() => {
    if (analyticsQuery.data) setWindowDataLayer(analyticsQuery.data);
  }, [analyticsQuery.data]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !hasUserSessionError && !auth0Error && !hasUser) {
      const queryParams = !!window.location.search ? `${window.location.search}` : '';
      const returnTo = `${window.location.pathname}${queryParams}`;
      loginWithRedirect({ appState: { returnTo } });
    }
  }, [isLoading, loginWithRedirect, hasUserSessionError, isAuthenticated, auth0Error, hasUser]);

  if (hasUserSessionError || auth0Error) {
    return <AuthError errorMessage={auth0Error?.message} logout={logout} />;
  }

  if (!hasUser || isLoading) {
    return <ContainedLoadingState fullPage />;
  }

  const isTalent = user.user_type === 'international';

  if (isTalent) {
    const talentAppURL = new URL('/', process.env.REACT_APP_TALENT_APP);
    window.location.href = talentAppURL.href;

    return null;
  }

  return <CurrentUserProvider user={user}>{children}</CurrentUserProvider>;
};
