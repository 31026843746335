import { Icon } from '@localyze-pluto/components';
import React from 'react';

const ThButtonArrowIcon = ({ asc = false }: { asc?: boolean }): React.JSX.Element => {
  return asc ? (
    <Icon decorative icon="arrow-up" size="sizeIcon10" />
  ) : (
    <Icon decorative icon="arrow-down" size="sizeIcon10" />
  );
};

export default ThButtonArrowIcon;
