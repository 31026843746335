import { useField } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { isAxiosError, RawAxiosRequestConfig } from 'axios';

import { FileUploadModal } from 'components/FileUploadModal/FileUploadModal';
import { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';
import { Props } from './props';
import { useModalStore } from '@localyze-pluto/components';

const INITIAL = 0;
const STARTED = 0.1;
const FINISHED = 100;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDocument = (result: any): result is DocumentWithUrl => !!result.type;

const getKey = () => Math.random().toString(16).substring(2);

const FIFTY_K = 50e3;

export const FileUpload: React.FC<Props> = ({
  component,
  name,
  docName,
  label,
  onUpload,
  onUploadSuccess,
  onCancel,
  onClose,
  maxFileSize,
  fileTypes,
}: Props) => {
  const modal = useModalStore({ defaultOpen: false });
  const isModalOpen = modal.useState('open');

  const [key, setKey] = useState(getKey());
  const [, _meta, helpers] = useField<File | undefined>(name);
  const { setValue } = helpers;

  const [uploadProgress, setUploadProgress] = useState(INITIAL);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const onSubmit = useCallback(
    async (file: File) => {
      setError(undefined);
      if (!onUpload) {
        setValue(file);
        return;
      }

      setIsUploading(true);
      const config: RawAxiosRequestConfig = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * FINISHED) / (progressEvent.total || FIFTY_K),
          );
          setUploadProgress(percentCompleted);
        },
      };

      setUploadProgress(STARTED);

      try {
        const result = (await onUpload(
          {
            name,
            value: file,
          },
          config,
          setUploadProgress,
        )) as DocumentWithUrl | { data: { url: string } } | undefined;

        if (onUploadSuccess && result) {
          if (isDocument(result)) {
            onUploadSuccess(`/attachments/${result.id}`);
          } else {
            onUploadSuccess(result.data.url || '');
          }
        }

        if (result) {
          modal.hide();
          setValue(file);
        }

        setIsUploading(false);
        setUploadProgress(INITIAL);
      } catch (e) {
        setIsUploading(false);

        if (isAxiosError(e)) {
          setError(e.message);
        } else {
          setError('Something went wrong. Try uploading your file again.');
          setUploadProgress(INITIAL);
        }
      }
    },
    [name, onUpload, onUploadSuccess, setValue, modal],
  ); // eslint-disable-line

  const onDeselect = useCallback(() => {
    setValue(undefined);
    setUploadProgress(INITIAL);
    setIsUploading(false);
    onCancel?.();
    setError(undefined);
  }, [setValue, onCancel]);

  useEffect(() => {
    if (!isModalOpen) {
      setKey(getKey());
      onCancel?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <>
      <div
        onClick={modal.show}
        onKeyDown={({ key }) => {
          if ([' ', 'Enter'].includes(key)) {
            modal.show();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {component}
      </div>
      <FileUploadModal
        error={error}
        fileTypes={fileTypes}
        heading={`Upload ${docName || label || ''}`}
        isLoading={isUploading}
        key={key}
        maxFileSize={maxFileSize}
        onCancel={() => onDeselect()}
        onClose={() => {
          setValue(undefined);
          setError(undefined);
          onClose?.();
        }}
        onDrop={(files) => {
          setValue(files[0]);
          onSubmit(files[0]);
        }}
        progress={uploadProgress}
        state={modal}
      />
    </>
  );
};
