import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type { Document } from 'modules/documents/api/attachments/types/Document';
import axios, { AxiosError } from 'axios';

export const DOCUMENTS_QUERY_KEY = 'documents';

export const getDocuments = async (
  internalReferenceCodes: unknown,
  caseId: number,
): Promise<Document[]> => {
  const params = {
    case_id: caseId,
    internal_reference_codes: internalReferenceCodes,
  };

  const { data } = await axios.get<Document[]>('/documents', { params });

  return data;
};

export const useDocuments = (
  caseId: number,
  internalReferenceCodes?: unknown,
  options: Pick<UseQueryOptions<Document[], AxiosError>, 'enabled'> = {
    enabled: true,
  },
): UseQueryResult<Document[], AxiosError> => {
  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, internalReferenceCodes, caseId],
    queryFn: () => getDocuments(internalReferenceCodes, caseId),
    enabled: !Number.isNaN(caseId) && options.enabled,
  });
};
