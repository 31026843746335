import { useEffect, useState } from 'react';

import { SubscriptionPlanWithFeatures } from '../types/SubscriptionPlanWithFeatures';
import { addFeaturesToSubscriptionPlans } from '../helpers/addFeaturesToSubscriptionPlans';
import { getCompany } from 'modules/company/api/useCompany/getCompany';
import { getSubscriptionPlans } from 'deprecated/api/subscription_plans';
import { postCompanyUpdate } from 'deprecated/api/companies/company';

export const useSubscriptionPlans = (
  companyId: number,
): {
  subscriptions: SubscriptionPlanWithFeatures[];
  current: Maybe<SubscriptionPlanWithFeatures>;
  recommended: Maybe<SubscriptionPlanWithFeatures>;
  changeSubscriptionPlan: (subscription: SubscriptionPlanWithFeatures) => void;
  errorMessage: Maybe<string>;
} => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionPlanWithFeatures[]>([]);
  const [current, setCurrent] = useState<Maybe<SubscriptionPlanWithFeatures>>(null);
  const [recommended, setRecommended] = useState<Maybe<SubscriptionPlanWithFeatures>>(null);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  useEffect(() => {
    const loadData = async (companyId: number) => {
      const subscriptionsResponse = await getSubscriptionPlans();
      const subscriptionPlans = addFeaturesToSubscriptionPlans(subscriptionsResponse);
      setSubscriptions(subscriptionPlans);

      const { subscription_plan } = await getCompany(companyId);
      const currentSubscription = subscriptionPlans.find(
        (subscription) => subscription_plan.id === subscription.id,
      );

      if (currentSubscription) {
        setCurrent(currentSubscription);
      }
    };

    loadData(companyId);
  }, [companyId, setSubscriptions, setCurrent]);

  useEffect(() => {
    if (current) {
      const currentIndex = subscriptions.findIndex((item) => item.id === current.id);

      const nextSubscriptionPlan = subscriptions[currentIndex + 1] || null;
      setRecommended(nextSubscriptionPlan);
    }
  }, [current, subscriptions]);

  const changeSubscriptionPlan = async (subscription: SubscriptionPlanWithFeatures) => {
    setErrorMessage(null);
    try {
      await postCompanyUpdate({ company: { subscription_plan_id: subscription.id } }, companyId);
      setCurrent(subscription);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  return { subscriptions, current, recommended, errorMessage, changeSubscriptionPlan };
};
