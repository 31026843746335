/**
 * Adds user metadata in Segment/Amplitude so that user info can be tied to tracked events
 * @param userId - user id for the user
 * @param userInfo - metadata about the user
 * @param options - options for the identify call
 */
export const identifyUser = (
  userId: string,
  userInfo: Record<string, unknown>,
  options: Record<string, unknown>,
): void => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.analytics && analytics.identify(userId, userInfo, options);
};

/**
 * Track a pageview in Segment/Amplitude
 * @param pageName - name of the page that was viewed
 * @param properties - a dictionary of properties for the event. If the event
 * was 'added to cart', it might have properties like price and product_type.
 */
export const trackPageView = (pageName: string, properties?: Record<string, string>): void => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (window.analytics) {
    properties ? analytics.page(pageName, properties) : analytics.page(pageName);
  }
};
