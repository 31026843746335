import React from 'react';
import { StackedTable } from 'components/StackedTable/StackedTable';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';
import { VariableGroup } from 'deprecated/api/internal_variables/types/VariableGroup';
import { buildRows } from './buildRows';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

export const VariablesList = ({
  handleRowClick,
  handleTitleClick,
  isPending,
  variableGroups,
}: {
  handleRowClick: (data: VariableGroup) => void;
  handleTitleClick: (variable: Variable) => void;
  isPending: boolean;
  variableGroups: VariableGroup[];
}): React.JSX.Element => {
  if (isPending) {
    return (
      <div className="tw-flex tw-flex-auto tw-justify-center">
        <ContainedLoadingState />
      </div>
    );
  }

  const rows = buildRows({
    onClick: handleRowClick,
    onTitleClick: handleTitleClick,
    variableGroups,
  });

  return (
    <div className="tw-flex tw-flex-grow tw-flex-col tw-h-0 tw-pt-2" data-testid="variables-list">
      <StackedTable
        headerLabels={['', 'Title', 'Type', 'Destination country', 'Origin country', '']}
        isHeaderFixed
        rows={rows}
      />
    </div>
  );
};
