import React from 'react';
import { companyStateOptions } from 'deprecated/utils/helper/admin/companies';
import { Select } from '@localyze-pluto/components';
import { upperFirst } from 'lodash/string';

class CompanyStateDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyState: [],
    };
  }

  componentDidMount() {
    if (!this.props.companyStateOptions) {
      companyStateOptions(
        (response) => this.setState({ companyState: response.data }),
        (error) => console.log(error),
      );
    }
  }

  selectCompanyState = (companyState) => this.props.onChangeStatus(companyState);

  render() {
    return (
      <Select
        aria-label="Select company state"
        id="companyStateSelect"
        items={(this.props.companyStateOptions || this.state.companyState).map((status) => ({
          label: upperFirst(status),
          value: status,
        }))}
        setValue={this.selectCompanyState}
        placeholder={this.props.placeholder || ''}
        disabled={this.props.disabled || false}
        value={this.props.value || null}
      />
    );
  }
}

export default CompanyStateDropDown;
