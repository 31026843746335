import { Box, Heading, Paragraph } from '@localyze-pluto/components';
import React, { ReactNode } from 'react';
import isString from 'lodash/isString';

export type SectionProps = {
  children: ReactNode;
  description?: ReactNode | string;
  title: string;
  disabled?: boolean;
};

const renderDescription = (description: ReactNode | string): ReactNode => {
  if (isString(description)) {
    return <Paragraph>{description}</Paragraph>;
  }

  return description;
};

export const Section = ({ children, description, title }: SectionProps): React.JSX.Element => (
  <Box.section data-testid={title} display="flex" flexDirection="column" gap="d6">
    <Box.div>
      <Heading as="h2" marginBottom={description ? 'm2' : 'm0'} size="heading60">
        {title}
      </Heading>
      {description && renderDescription(description)}
    </Box.div>
    {children}
  </Box.section>
);
