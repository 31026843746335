import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { queryClient } from 'config/queryClient';
import { getCasePipelinesQueryKey } from 'modules/pipelines/hooks/useCasePipelines';

const deleteCaseStep = async (caseStepId: number): Promise<void> => {
  await axios.delete(`/case/steps/${caseStepId}`);
};

export const useDeleteCaseStep = ({
  caseId,
  onError,
  onSuccess,
}: Pick<UseMutationOptions<void, AxiosErrorWithMessage, number>, 'onError' | 'onSuccess'> & {
  caseId: number;
}): UseMutationResult<void, unknown, number> => {
  return useMutation({
    mutationFn: deleteCaseStep,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getCasePipelinesQueryKey(caseId),
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
