import * as Yup from 'yup';

import { Currency } from 'types/currency';
import { PreCheckFormData } from './types/PreCheckFormData';
import { Validation } from 'constants/formValidations';

export const initialValues: PreCheckFormData = {
  firstName: '',
  lastName: '',
  originCountry: '',
  destinationCountry: '',
  destinationCity: '',
  nationalityCountry: '',
  secondNationalityCountry: '',
  jobTitle: '',
  annualSalary: '',
  university: '',
  degree: '',
  cv: '',
  visa: '',
  comment: '',
  currency: '',
};

export const validationSchema = Yup.object({
  originCountry: Validation.dropDown,
  destinationCountry: Validation.dropDown,
  nationalityCountry: Validation.dropDown,
  firstName: Yup.string().defined(),
  lastName: Yup.string().defined(),
  destinationCity: Yup.string().defined(),
  secondNationalityCountry: Yup.string().defined(),
  jobTitle: Yup.string().defined(),
  annualSalary: Yup.string().defined(),
  university: Yup.string().defined(),
  degree: Yup.string().defined(),
  cv: Yup.string().defined(),
  visa: Yup.string().defined(),
  comment: Yup.string().defined(),
  currency: Yup.string().defined(),
});

export const currencyOptions = [
  { label: 'EUR', value: Currency.EUR },
  {
    label: 'GBP',
    value: Currency.GBP,
  },
  {
    label: 'USD',
    value: Currency.USD,
  },
];
