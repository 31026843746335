import { Box, useModalStore } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useCaseAdditionalServices } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { useProductAdditionalServices } from 'modules/additionalServices/api/useProductAdditionalServices/useProductAdditionalServices';
import { FAMILY_MEMBERS_SERVICE_NAME } from 'modules/additionalServices/constants/familyMembersServiceName';
import { EditAllowanceModal } from 'modules/caseDetails/components/CaseDetailsCard/CaseInformation/EditAllowanceModal/EditAllowanceModal';
import { EditCaseTypeModal } from 'modules/caseDetails/components/CaseDetailsCard/CaseInformation/EditCaseTypeModal/EditCaseTypeModal';
import { EditCommunicationPriorityModal } from 'modules/caseDetails/components/CaseDetailsCard/CaseInformation/EditCommunicationPriorityModal/EditCommunicationPriorityModal';
import { EditFamilySupportModal } from 'modules/caseDetails/components/CaseDetailsCard/CaseInformation/EditFamilySupportModal/EditFamilySupportModal';
import { EditOfficeModal } from 'modules/caseDetails/components/CaseDetailsCard/CaseInformation/EditOfficeModal/EditOfficeModal';
import { EditServiceLevelModal } from 'modules/caseDetails/components/CaseDetailsCard/CaseInformation/EditServiceLevelModal/EditServiceLevelModal';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';
import { getFamilySupportDisplayValue } from 'modules/caseDetails/utils/getFamilySupportDisplayValue';
import { humanizeCommunicationPriority } from 'modules/caseDetails/utils/humanizeCommunicationPriority';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { useCaseType } from 'modules/cases/api/useCaseType/useCaseType';
import { SERVICE_LEVELS } from 'modules/cases/components/ServiceLevelOptions/serviceLevels';
import { useOffice } from 'modules/offices/api/useOffice/useOffice';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Currency } from 'types/currency';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { formatPriceCents } from 'utils/formatters/formatPrice/formatPrice';
import { EditDestinationCountryModal } from './EditDestinationCountryModal/EditDestinationCountryModal';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { EditCountryOfOriginModal } from './EditCountryOfOriginModal/EditCountryOfOriginModal';

export const CaseInformation = (): React.JSX.Element => {
  const { id: caseId } = useParams<{ id: string }>();
  const { isAdmin } = useCurrentUser();
  const { data: kase, isSuccess: isSuccessCase } = useCase({ caseId: Number(caseId) });
  const { data: caseType, isSuccess: isSuccessCaseType } = useCaseType(
    Number(kase?.case_type_id),
    isSuccessCase,
  );

  const { data: office, isSuccess: isSuccessOffice } = useOffice({
    params: { companyId: Number(kase?.company_id), officeId: Number(kase?.office_id) },
    enabled: isSuccessCase,
  });

  const { data: countries, isSuccess: isSuccessCountries } = useCountries({});

  const {
    data: familySupportProductAdditionalService,
    isSuccess: isSuccessProductAdditionalServices,
  } = useProductAdditionalServices({
    companyId: Number(kase?.company_id),
    destinationCountryId: Number(kase?.destination_country_id),
    select: (services) =>
      services.find((service) => service.additional_service.name === FAMILY_MEMBERS_SERVICE_NAME),
  });
  const { data: caseAdditionalServices, isSuccess: isSuccessCaseAdditionalServices } =
    useCaseAdditionalServices({ caseId: Number(caseId) });

  const accompanyingFamilyMembersValue = getFamilySupportDisplayValue({
    caseAdditionalServices,
    familySupportProductAdditionalService,
  });

  const serviceLevel = SERVICE_LEVELS.find((level) => level.id === kase?.service_level_id);

  const modal = useModalStore();
  const isModalOpen = modal.useState('open');
  const [modalType, setModalType] = useState<
    | 'allowance'
    | 'caseType'
    | 'communicationPriority'
    | 'countryOfOrigin'
    | 'destinationCountry'
    | 'familySupport'
    | 'office'
    | 'serviceLevel'
  >();

  if (
    !isSuccessCase ||
    !isSuccessCaseType ||
    !isSuccessCaseAdditionalServices ||
    !isSuccessProductAdditionalServices ||
    !isSuccessOffice ||
    !isSuccessCountries
  ) {
    return <ContainedLoadingState />;
  }

  const currency = kase.price_currency as Currency;
  const destinationCountry = countries.find(
    (country) => country.id === Number(kase.destination_country_id),
  );
  const originCountry = countries.find((country) => country.id === Number(kase.origin_country_id));

  const modalComponents = {
    office: <EditOfficeModal kase={kase} modal={modal} />,
    communicationPriority: <EditCommunicationPriorityModal kase={kase} modal={modal} />,
    caseType: <EditCaseTypeModal kase={kase} modal={modal} />,
    serviceLevel: <EditServiceLevelModal kase={kase} modal={modal} />,
    familySupport: familySupportProductAdditionalService && (
      <EditFamilySupportModal
        caseAdditionalServices={caseAdditionalServices}
        familySupportProductAdditionalService={familySupportProductAdditionalService}
        kase={kase}
        modal={modal}
      />
    ),
    allowance: <EditAllowanceModal kase={kase} modal={modal} />,
    destinationCountry: <EditDestinationCountryModal kase={kase} modal={modal} />,
    countryOfOrigin: <EditCountryOfOriginModal kase={kase} modal={modal} />,
  };

  const ModalComponent = modalType && modalComponents[modalType];

  const items = [
    {
      key: 'Office',
      value: <Box.b>{office.name}</Box.b>,
      onEdit: () => {
        setModalType('office');
        modal.show();
      },
    },
    ...(isAdmin
      ? [
          {
            key: 'Communication priority',
            value: <Box.b>{humanizeCommunicationPriority(kase.communication_priority)}</Box.b>,
            onEdit: () => {
              setModalType('communicationPriority');
              modal.show();
            },
          },
        ]
      : []),
    {
      key: 'Created on',
      value: <Box.b>{formatDate(kase.created_at)}</Box.b>,
    },
    {
      key: 'Case type',
      value: (
        <Box.div display="flex" flexDirection="column">
          <Box.b>{caseType.name}</Box.b>
          <Box.span>{caseType.description}</Box.span>
        </Box.div>
      ),
      onEdit: () => {
        setModalType('caseType');
        modal.show();
      },
    },
    {
      key: 'Service level',
      value: serviceLevel && (
        <Box.div display="flex" flexDirection="column">
          <Box.b>{serviceLevel.label}</Box.b>
          <Box.span>{serviceLevel.features.join('. ')}</Box.span>
        </Box.div>
      ),
      onEdit: () => {
        setModalType('serviceLevel');
        modal.show();
      },
    },
    {
      key: 'Destination country',
      value: <Box.b>{destinationCountry?.name}</Box.b>,
      onEdit: () => {
        setModalType('destinationCountry');
        modal.show();
      },
    },
    {
      key: 'Country of origin',
      value: <Box.b>{originCountry?.name}</Box.b>,
      onEdit: () => {
        setModalType('countryOfOrigin');
        modal.show();
      },
    },
    {
      key: 'Family support',
      value: <Box.b>{accompanyingFamilyMembersValue}</Box.b>,
      onEdit: () => {
        setModalType('familySupport');
        modal.show();
      },
    },
    {
      key: 'Case price',
      value: <Box.b>{formatPriceCents(kase.price_cents, currency, { showCents: true })}</Box.b>,
    },
    {
      key: 'Allowance',
      value: (
        <Box.div display="flex" flexDirection="column">
          <Box.b>{formatPriceCents(kase.total_allowance_fundings_cents, currency)}</Box.b>
          <Box.span>
            {formatPriceCents(kase.total_remaining_allowance_cents, currency)} remaining
          </Box.span>
        </Box.div>
      ),
      onEdit: isAdmin
        ? () => {
            setModalType('allowance');
            modal.show();
          }
        : undefined,
    },
  ];

  return (
    <>
      <KeyValueList borderBottomStyle="borderStyleSolid" items={items} py="d6" />
      {isModalOpen && ModalComponent}
    </>
  );
};
