import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';
import axios, { AxiosError } from 'axios';

const getTalent = async (id: number): Promise<TalentWithPersonalInformation> => {
  const url = `/talents/${id}`;
  const { data } = await axios.get<TalentWithPersonalInformation>(url, {
    headers: { Accept: 'application/json' },
  });

  return data;
};

export const useTalentQuery = (
  talentId?: number,
  options: Pick<
    UseQueryOptions<TalentWithPersonalInformation, AxiosError>,
    'enabled' | 'throwOnError'
  > = {
    enabled: true,
  },
): UseQueryResult<TalentWithPersonalInformation, AxiosError> => {
  return useQuery({
    queryKey: ['talent', talentId] as QueryKey,
    queryFn: () => getTalent(Number(talentId)),
    enabled: !Number.isNaN(Number(talentId)) && options.enabled,
    throwOnError: options.throwOnError,
  });
};
