import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { isNull, isUndefined } from 'lodash';
import { CaseStepWithComponents } from 'modules/pipelines/types/CaseStep';

const getCaseStep = async (id: number): Promise<CaseStepWithComponents> => {
  const { data } = await axios.get<CaseStepWithComponents>(`/case/steps/${id}`);

  return data;
};

export const useCaseStep = (id?: number): UseQueryResult<CaseStepWithComponents> => {
  return useQuery({
    queryKey: ['case-step', id],
    queryFn: () => getCaseStep(Number(id)),
    enabled: !isUndefined(id) && !isNull(id),
  });
};
