import React from 'react';
import { Box, Paragraph } from '@localyze-pluto/components';
import { EmptyState } from 'components/EmptyState/EmptyState';

export const GlomoRequestsEmptyState = (): React.JSX.Element => {
  return (
    <EmptyState illustration={<Box.span>☕</Box.span>}>
      <Box.div maxW={328}>
        <Paragraph
          color="colorText"
          fontSize="fontSize30"
          lineHeight="lineHeight40"
          marginBottom="m0"
          textAlign="center"
        >
          There are no requests to manage yet. Share your company’s request link or submit a request
          on your talent’s behalf
        </Paragraph>
      </Box.div>
    </EmptyState>
  );
};
