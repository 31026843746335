import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import axios, { AxiosError } from 'axios';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';
import { queryClient } from 'config/queryClient';

export type UpdateTalentProps = {
  talentId: number;
  payload: Partial<TalentWithPersonalInformation>;
};

// TODO: Remove the 'talent' key on the payload when we stop using the old talent
// profile and only send talent attributes (not case attributes) to
// the talents#update endpoint
async function updateTalent({
  talentId,
  payload,
}: UpdateTalentProps): Promise<TalentWithPersonalInformation> {
  const response = await axios.put<TalentWithPersonalInformation>(
    `/talents/${talentId}`,
    { talent: payload },
    {
      headers: { Accept: 'application/json' },
    },
  );
  return response.data;
}

export const useUpdateTalent = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, UpdateTalentProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, UpdateTalentProps> =>
  useMutation({
    mutationFn: updateTalent,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: ['talent', variables.talentId] });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
