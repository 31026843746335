import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

export const removeEmptyValues = (values: Record<string, unknown>): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(values).filter(([_, value]) => {
      if (typeof value === 'boolean' || typeof value === 'number') {
        return true;
      }

      return !(isUndefined(value) || isEmpty(value));
    }),
  );
