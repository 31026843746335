import { CaseState } from 'modules/cases/types/CaseState';

/**
 * Makes case status human friendly
 * @param currentState - unformatted talent case state value
 * @returns formatted case status
 */
export const formatCaseStatus = (currentState: string): string => {
  switch (currentState) {
    case CaseState.Closed:
      return 'Closed';
    case CaseState.InAssessment:
      return 'In Assessment';
    case CaseState.Ongoing:
      return 'Ongoing';
    case CaseState.Onboarding:
      return 'Onboarding';
    case CaseState.SubmittingInformation:
      return 'Submitting Information';
    case CaseState.Waiting:
      return 'Waiting';
    default:
      return 'Status Not Available';
  }
};
