import React from 'react';
import type { Case } from 'modules/cases/types/Case';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { Box, Paragraph } from '@localyze-pluto/components';
import { useCaseType } from 'modules/cases/api/useCaseType/useCaseType';
import { CaseState } from 'modules/cases/types/CaseState';
import { StepCircle } from '../../CasePipeline/components/Step/StepCircle';
import { Status } from '../../CasePipeline/types';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Link } from 'react-router-dom';

export const CaseCardRow = ({
  kase,
  showCreationDate = true,
}: {
  kase: Case;
  showCreationDate?: boolean;
}): JSX.Element => {
  const { data: countries } = useCountries({});
  const routes = useRoutes();

  const { data: caseType } = useCaseType(kase.case_type_id);

  const originCountry = countries?.find((country) => country.id === kase.origin_country_id);
  const destinationCountry = countries?.find(
    (country) => country.id === kase.destination_country_id,
  );

  return (
    <Box.div
      alignItems="center"
      display="grid"
      gridTemplateColumns="1fr 1fr"
      justifyContent="space-between"
      key={kase.id}
      px="d6"
      py="d4"
    >
      <Link
        style={{ alignItems: 'center', display: 'flex', textDecoration: 'none' }}
        to={{
          pathname: routes.case.get(kase.id),
        }}
      >
        <StepCircle
          disabled
          status={kase.current_state === CaseState.Closed ? Status.Complete : Status.Current}
        />
        <Paragraph
          color="colorTextStrongest"
          fontSize="fontSize20"
          fontWeight="fontWeightBold"
          lineHeight="lh5"
          marginBottom="m0"
          marginLeft="d2"
        >
          {caseType?.name} - #{kase.id}
        </Paragraph>
      </Link>
      <Box.div display="flex" justifyContent="space-between">
        {showCreationDate && (
          <Paragraph
            color="colorTextStrongest"
            fontSize="fontSize20"
            marginBottom="d0"
            px="d6"
            py="d0"
          >
            Created on {formatDate(kase.created_at)}
          </Paragraph>
        )}
        <Paragraph
          color="colorTextStrongest"
          fontSize="fontSize20"
          marginBottom="d0"
          px="d6"
          py="d0"
        >
          {originCountry?.name} to {destinationCountry?.name}
        </Paragraph>
      </Box.div>
    </Box.div>
  );
};
