import {
  Button,
  Dropzone,
  FileTypes,
  HelpText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
} from '@localyze-pluto/components';
import { MAX_FILE_SIZE, PDF_EXTENSION } from 'deprecated/components/Chewpaca/FileUpload/constants';
import React, { useEffect, useState } from 'react';

import { DisclosureStore } from '@ariakit/react';

type Props = {
  error?: string;
  heading?: string;
  state: DisclosureStore;
  onClose: () => void;
  onDrop: (files: File[], name: string) => void;
  progress?: number;
  onCancel?: () => void;
  isLoading?: boolean;
  maxFileSize?: number;
  fileTypes?: FileTypes;
};

const initialProgress = 0;

const generateKey = () => Math.random().toString(16).substring(2);

export const UploadAttachmentModal = ({
  error,
  heading = 'Upload your document',
  onClose,
  progress,
  onDrop,
  onCancel,
  state,
  isLoading,
  maxFileSize = MAX_FILE_SIZE,
  fileTypes = PDF_EXTENSION,
}: Props): React.JSX.Element => {
  const [key, setKey] = useState(generateKey());
  const [name, setName] = useState('');
  const [hasError, setHasError] = useState(false);
  const isModalOpen = state.useState('open');

  useEffect(() => {
    if (!isModalOpen) {
      setKey(generateKey());
      setName('');
      setHasError(false);
      onCancel?.();
    }
  }, [isModalOpen, onCancel]);

  const onType = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    setName(value);

    if (value) {
      setHasError(false);
    }
  };

  const onDropFile = (files: File[]) => {
    if (name !== '') {
      onDrop(files, name);
    } else {
      setHasError(true);
    }
  };

  if (!isModalOpen) {
    return <></>;
  }

  return (
    <Modal store={state}>
      <ModalHeader>
        <ModalHeading>{heading}</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <div className="tw-mb-6 tw-mx-1">
          <Input
            aria-describedby={'document-name'}
            autoComplete="off"
            disabled={Boolean(progress && progress > initialProgress)}
            name="input"
            onChange={onType}
            placeholder="e.g. Contract Amendment, Payment receipt"
            type="text"
            value={name}
          />
          {hasError && (
            <HelpText hasError>Please name the document before selecting a file</HelpText>
          )}
          <HelpText aria-describedby={'document-name'}>
            <span className="tw-text-gray-700">Please write the document name in English</span>
          </HelpText>
        </div>
        <Dropzone
          error={error}
          fileTypes={fileTypes}
          key={error ? generateKey() : key}
          maxFileSize={maxFileSize}
          onCancel={() => onCancel?.()}
          onDrop={onDropFile}
          progress={progress}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            onCancel?.();
            onClose();
            state.hide();
          }}
          variant="primary"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
