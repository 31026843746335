import axios from 'axios';
import { prepareErrorMessage } from 'deprecated/utils/helper/legacy/apiErrors';
import { SubscriptionPlan as SubscriptionPlan } from 'deprecated/api/subscription_plans/types/SubscriptionPlan';

export const getSubscriptionPlans = async (): Promise<Array<SubscriptionPlan>> => {
  try {
    return (await axios.get<SubscriptionPlan[]>('/subscription_plans')).data;
  } catch (error) {
    throw new Error(prepareErrorMessage(error));
  }
};
