import { DialogStore } from '@ariakit/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Callout,
  ControlledCheckbox,
  ControlledFormInput,
  ControlledFormSelect,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  SelectItemProps,
  useToast,
} from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';
import { DeleteTalent } from 'modules/talent/pages/talentProfile/components/EditTalentModal/DeleteTalent';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { TALENT_DELETION_PERMITTED } from 'modules/featureFlags/types/flags';
import { useUpdateTalent } from 'modules/talent/api/useUpdateTalent/useUpdateTalent';
import { Gender } from 'modules/talent/types/Gender';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type EditTalentFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  gender?: string;
  birthday?: string;
  leftCompany?: boolean;
};

const validationSchema: Yup.ObjectSchema<EditTalentFormValues> = Yup.object({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required'),
  gender: Yup.string(),
  birthday: Yup.string(),
  leftCompany: Yup.boolean(),
});

export const EditTalentModal = ({
  modal,
  talent,
}: {
  modal: DialogStore;
  talent: TalentWithPersonalInformation;
}): React.JSX.Element => {
  const toast = useToast();
  const { isAdmin } = useCurrentUser();
  const isDeleteTalentActive = useIsFeatureFlagActive(TALENT_DELETION_PERMITTED);

  const { mutate: updateTalent, isPending: isPendingUpdateTalent } = useUpdateTalent({
    onSuccess: () => {
      toast('Talent updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Talent could not be updated', 'error'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      firstName: talent.first_name,
      lastName: talent.last_name,
      email: talent.email,
      gender: talent.gender || '',
      birthday: talent.birthday || '',
      leftCompany: talent.left_company || false,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<EditTalentFormValues> = (values) =>
    updateTalent({
      talentId: talent.id,
      payload: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        gender: values.gender ? (values.gender as Gender) : null,
        birthday: values.birthday,
        left_company: values.leftCompany,
      },
    });

  const GENDERS: SelectItemProps[] = [
    { value: 'other', label: 'Other' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Talent</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form
          display="flex"
          flexDirection="column"
          gap="d4"
          id="edit-talent-form"
          marginBottom="d4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ControlledFormInput
            control={control}
            id="firstName"
            label="First name"
            name="firstName"
            required
            type="text"
          />
          <ControlledFormInput
            control={control}
            id="lastName"
            label="Last name"
            name="lastName"
            required
            type="text"
          />
          <ControlledFormInput
            control={control}
            id="email"
            label="Email"
            name="email"
            required
            type="text"
          />
          <ControlledFormSelect
            control={control}
            id="gender"
            items={GENDERS}
            label="Gender"
            name="gender"
            placeholder="Select one"
          />
          <ControlledFormInput
            control={control}
            id="birthday"
            label="Date of birth"
            name="birthday"
            type="date"
          />
          <ControlledCheckbox control={control} id="leftCompany" name="leftCompany" wrapped>
            {`Talent no longer works for ${isAdmin ? 'this company' : 'us'}`}
          </ControlledCheckbox>
        </Box.form>

        {isDeleteTalentActive && (
          <Callout variant="error">
            <Heading as="h6" color="colorTextStrongest" marginBottom="m0" size="heading60">
              Danger zone
            </Heading>
            <Paragraph>
              Deleting a talent is permanent and cannot be undone. This talent profile and all of
              the associated data will be lost.
            </Paragraph>
            <DeleteTalent talentId={talent.id} />
          </Callout>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isPendingUpdateTalent}
          form="edit-talent-form"
          loading={isPendingUpdateTalent}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
