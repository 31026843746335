import axios from 'axios';
import { PreCheckFormData } from '../../types/PreCheckFormData';
import { PreCheckPayload } from './useSubmitPreCheck.types';

/**
 * Makes a POST request to the pre-check endpoint
 * @param data - pre-check data
 */
export const submitPreCheck = async (formData: PreCheckFormData): Promise<void> => {
  const mappedFormData: PreCheckPayload = {
    first_name: formData.firstName,
    last_name: formData.lastName,
    origin_country_id: formData.originCountry,
    destination_city: formData.destinationCity,
    destination_country_id: formData.destinationCountry,
    nationality_id: formData.nationalityCountry,
    secondary_nationality_id: formData.secondNationalityCountry,
    job_title: formData.jobTitle,
    annual_salary: formData.annualSalary,
    university: formData.university,
    degree: formData.degree,
    cv: formData.cv,
    visa: formData.visa,
    comment: formData.comment,
    currency: formData.currency,
  };

  const payload = new FormData();
  let key: keyof PreCheckPayload;
  for (key in mappedFormData) {
    const value = mappedFormData[key];
    if (value) {
      payload.append(`pre_check[${key}]`, value);
    }
  }

  await axios.post<unknown>('/pre_checks', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
