import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { CreateCasePayload } from 'modules/cases/pages/createCase/types';
import { CreateCaseRequestPayload } from './useCreateCase.types';
import { Case } from 'modules/cases/types/Case';
import axios from 'axios';
import { formatAdditionalServices } from './formatAdditionalServices/formatAdditionalServices';

/**
 * Makes a POST request to the cases endpoint
 * @param payload - info about the case to be created
 */
const createCase = async (payload: CreateCasePayload): Promise<Case> => {
  const allowanceAmountCents = payload.allowanceAmountDollars * 100;
  const formattedAdditionalServices = formatAdditionalServices(payload.additionalServices);

  const international_integrations_attributes =
    payload.providerName && payload.employeeId
      ? [
          {
            provider_name: payload.providerName,
            provider_uid: payload.employeeId,
          },
        ]
      : [];

  const formattedPayload: CreateCaseRequestPayload = {
    case: {
      ...(payload.talentId
        ? { talent_id: payload.talentId }
        : {
            talent_attributes: {
              first_name: payload.firstName,
              last_name: payload.lastName,
              email: payload.email,
              international_integrations_attributes,
            },
          }),
      nationality_id: payload.citizenshipCountry,
      origin_country_id: payload.countryOfResidence,
      destination_country_id: payload.destinationCountry,
      office_id: payload.officeId,
      case_type_id: payload.caseTypeId,
      cost_center: payload.costCenter,
      service_level_id: payload.serviceLevelId,
      hiring_manager_name: payload.hiringManager,
      hr_manager_id: payload.hrManagerId,
      follower_ids: payload.followerIds,
      includes_accompanying_family_members: payload.includesAccompanyingFamilyMembers,
      allowance_fundings_attributes:
        allowanceAmountCents > 0
          ? [{ price_cents: allowanceAmountCents, currency: payload.allowanceCurrency }]
          : [],
      case_additional_services_attributes: formattedAdditionalServices,
      case_documents_attributes: [
        {
          code: 'cd_employment_contract_cd',
          file: payload.workContractMetadata,
        },
        {
          code: 'cd_job_description_cd',
          file: payload.jobDescriptionMetadata,
        },
      ],
      case_informations_attributes: [
        {
          code: 'cd_job_title_cd',
          value: payload.jobTitle,
        },
        {
          code: 'cd_date_appointment_first_day_work_cd',
          value: payload.startDate,
        },
      ],
    },
  };

  const { data } = await axios.post<Case>('/cases', formattedPayload, {
    headers: { Accept: 'application/json' },
  });

  return data;
};

/**
 * Creates a new case
 * @param onSuccess - Callback to be executed when the mutation is successful
 */
export const useCreateCase = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<Case, AxiosErrorWithMessage, CreateCasePayload>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<Case, AxiosErrorWithMessage, CreateCasePayload> => {
  return useMutation({
    mutationFn: createCase,
    onSuccess,
    onError,
  });
};
