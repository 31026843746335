import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

import { PreCheckFormData } from 'modules/precheck/pages/precheck/types/PreCheckFormData';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { submitPreCheck } from 'modules/precheck/pages/precheck/api/useSubmitPreCheck/submitPreCheck';

/**
 * Submits a pre-check request
 * @param onSuccess - Callback to be executed when the mutation is successful
 * @param onError - Callback to be executed when the mutation fails
 */
export const useSubmitPreCheck = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<void, AxiosErrorWithMessage, PreCheckFormData>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<void, AxiosErrorWithMessage, PreCheckFormData> =>
  useMutation({
    mutationFn: submitPreCheck,
    onSuccess,
    onError,
  });
