import React, { useEffect, useMemo, useState } from 'react';
import { OverviewCard } from 'modules/caseOverview/components/OverviewCard/OverviewCard';
import { Box } from '@localyze-pluto/components';
import { useCaseOverviews } from 'modules/talent/api/useCaseOverviews/useCaseOverviews';
import { Case } from 'modules/caseOverview/components/Case/Case';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';
import { AllItemsLink } from 'modules/caseOverview/components/AllItemsLink';
import { isEmpty } from 'lodash';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';

export const WaitingCases = (): React.JSX.Element => {
  const { id, isAdmin, user_role, company_id } = useCurrentUser();
  const userIdKey = isAdmin ? 'case_manager_id' : 'hr_manager_id';
  const routes = useRoutes();

  const { data, isSuccess } = useCaseOverviews({
    items: 100,
    process_state: ['waiting'],
    sort_by: 'waiting_until',
    sort_direction: 'asc',
    [userIdKey]: [id],
  });

  const count = data?.meta.count;
  const allCases = useMemo(() => data?.cases || [], [data]);
  const [cases, setCases] = useState<CaseOverview[]>([]);

  const allCasesPath = routes.cases.url;

  const showMore = () => {
    setCases(allCases.slice(0, cases.length + 3));

    trackEvent('cases dashboard: click on show more', {
      user_role,
      company_id,
      hr_dashboard_section: 'waiting cases',
    });
  };

  useEffect(() => {
    setCases(allCases.slice(0, 3));
  }, [setCases, allCases]);

  return (
    <OverviewCard
      count={count}
      emptyStateText="There are currently no waiting cases."
      hasShowMore={allCases.length > 0 && cases.length < allCases.length}
      headerRightItem={
        <AllItemsLink
          label="All cases"
          onClick={() =>
            trackEvent('cases dashboard: click on show more', {
              user_role,
              company_id,
              hr_dashboard_section: 'waiting cases',
            })
          }
          path={`${allCasesPath}?tab=my&process_state=waiting`}
        />
      }
      iconCategory="warning"
      onClickShowMore={showMore}
      title="Waiting cases"
      tooltipText={
        'No relevant information is expected over a longer period of time. The ' +
        'Waiting state contains a specific date to indicate when the next action is planned.'
      }
    >
      {isSuccess && !isEmpty(cases) && (
        <Box.div display="flex" flexDirection="column" gap="d4">
          {cases.map((caseOverview) => (
            <Case badge="waitingUntil" caseOverview={caseOverview} key={caseOverview.id} />
          ))}
        </Box.div>
      )}
    </OverviewCard>
  );
};
