import * as Yup from 'yup';

import { LABELS, Validation } from 'constants/formValidations';
import { ModalMode } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/ModalMode';
import { VariableFormValues } from 'deprecated/variablesEditor/components/VariablesEditorFormModal/types/VariableFormValues';
import { Variable } from 'deprecated/api/internal_variables/types/Variable';

export const groupSchema = Yup.object({
  title: Validation.requiredString,
  variableType: Yup.string().required(LABELS.required),
  destinationCountryId: Yup.string().required(LABELS.required),
  originCountryId: Yup.string().required(LABELS.required),
  variableValue: Yup.string().required(LABELS.required),
});

export const groupLinkSchema = Yup.object({
  title: Validation.requiredString,
  variableType: Yup.string().required(LABELS.required),
  destinationCountryId: Yup.string().required(LABELS.required),
  originCountryId: Yup.string().required(LABELS.required),
  linkTitle: Yup.string().required(LABELS.required),
  linkLabel: Yup.string().required(LABELS.required),
  variableValue: Yup.string().required(LABELS.required),
});

export const confirmationContent = (mode: ModalMode): string =>
  mode === 'editVariable'
    ? `Are you sure you want to change the value of this variable?
Your change might affect a talent’s process.`
    : 'Are you sure you want to create a new variable?';

export const DESCRIPTIONS = {
  createGroup: 'To create a new variable group, please fill out the details below.',
  editVariable: 'To edit this variable, please update the variable value below.',
  createVariable: 'To create a new variable, please fill out the variable details below.',
};

export const TITLES = {
  createGroup: 'Create a new variable group with a first variable',
  createVariable: 'Add new variable to group',
  editVariable: 'Edit variable',
};

export const SUBMIT_BUTTON_LABELS = {
  createGroup: 'Create',
  editVariable: 'Save changes',
  createVariable: 'Add variable',
};

export const EVENTS = {
  createVariable: 'variables: create new variable',
  editVariable: 'variables: edit variable',
  createGroup: 'variables: create group',
};

export const getInitialValues = (
  variable: Variable | undefined,
  editVariable: boolean,
): VariableFormValues => ({
  title: variable?.title || '',
  variableType: variable?.variable_type || '',
  destinationCountryId: !editVariable ? '' : variable?.destination_country?.id.toString() || 'all',
  originCountryId: !editVariable ? '' : variable?.origin_country?.id.toString() || 'all',
  linkTitle: !editVariable ? '' : (variable?.link_title ?? ''),
  linkLabel: !editVariable ? '' : (variable?.link_label ?? ''),
  variableValue: !editVariable ? '' : variable?.value || '',
});
