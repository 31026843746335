import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { getCasePipelinesQueryKey } from 'modules/pipelines/hooks/useCasePipelines';

type DeleteCasePipelineProps = {
  casePipelineId: number;
};

const deleteCasePipeline = async ({ casePipelineId }: DeleteCasePipelineProps): Promise<void> => {
  await axios.delete(`/case/pipelines/${casePipelineId}`);
};

export const useDeleteCasePipeline = ({
  caseId,
  onError,
  onSuccess,
}: Pick<
  UseMutationOptions<unknown, AxiosError, DeleteCasePipelineProps>,
  'onError' | 'onSuccess'
> & { caseId: number }): UseMutationResult<void, unknown, DeleteCasePipelineProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCasePipeline,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getCasePipelinesQueryKey(caseId),
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
