import * as Yup from 'yup';

import {
  Anchor,
  Box,
  Button,
  Callout,
  ControlledFormInput,
  Tooltip,
  TooltipAnchor,
  TooltipStore,
  useModalStore,
  useToast,
  useTooltipStore,
} from '@localyze-pluto/components';

import { HRISTalent } from 'modules/integrations/types/HRISTalent';
import React, { ReactNode, useEffect, useState } from 'react';
import { Validation } from 'constants/formValidations';
import startCase from 'lodash/startCase';
import { useActiveCompanyHRISIntegration } from 'modules/integrations/hooks/useActiveCompanyHRISIntegration/useActiveCompanyHRISIntegration';
import { useForm } from 'react-hook-form';
import { useHRISTalent } from 'modules/integrations/hooks/useHRISTalent/useHRISTalent';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon';
import {
  hasMultipleCitizenships,
  hasMultipleEmails,
  HRISDataSelectionModal,
} from 'modules/cases/pages/createCase/components/HRISDataSelectionModal';
import { getProviderPageLink } from '../utils/getProviderPageLink';
import { PROVIDER_NAME } from 'modules/integrations/constants/providerName';
import { useCurrentUser } from 'config/CurrentUserContext';

type Props = {
  setHRISData: (talentData: HRISTalent) => void;
  employeeId?: string;
};

const validationSchema = Yup.object({
  employeeId: Validation.requiredString,
});

const hasMultipleAttributeOptions = (talent: HRISTalent) =>
  hasMultipleCitizenships(talent) || hasMultipleEmails(talent);

const InputWrapper = ({
  children,
  tooltipState,
  shouldShowTooltip,
}: {
  children: ReactNode;
  tooltipState: TooltipStore;
  shouldShowTooltip: boolean;
}) => (
  <>
    {shouldShowTooltip ? (
      <TooltipAnchor store={tooltipState}>{children}</TooltipAnchor>
    ) : (
      <>{children}</>
    )}
    {shouldShowTooltip && (
      <Tooltip store={tooltipState}>Activate an integration to enter the Employee ID.</Tooltip>
    )}
  </>
);

export const HRISIntegration = ({ setHRISData, employeeId }: Props): React.JSX.Element => {
  const { company_id } = useCurrentUser();
  const toast = useToast();
  const modalState = useModalStore();
  const isModalOpen = modalState.useState('open');

  const [uid, setUid] = useState(employeeId || '');
  const [talent, setTalent] = useState<HRISTalent>({});
  const tooltipState = useTooltipStore({ placement: 'bottom' });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      employeeId: uid,
    },
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const {
    data: activeHRISIntegration,
    isPending,
    isError,
  } = useActiveCompanyHRISIntegration(company_id);

  const providerName = activeHRISIntegration?.provider_data.stackone_provider_name;

  const {
    isLoading: isLoadingData,
    error,
    data,
  } = useHRISTalent({ params: { companyId: company_id, providerName: 'stackone', uid } });

  useEffect(() => {
    if (error)
      toast(
        error.response?.data.message || 'An error occurred while importing the employee data.',
        'error',
      );
    // adding more dependencies will cause infinite loop displaying the toast many times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data) {
      setHRISData({ ...data, providerName });
      toast('Employee data imported', 'success');

      setTalent(data);

      if (hasMultipleAttributeOptions(data)) {
        modalState.show();
      }
    }
    // adding more dependencies will cause infinite loop displaying the toast many times and not
    // allowing the modal to be closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, providerName]);

  if (isError || isPending) return <></>;

  const onSubmit = ({ employeeId }: { employeeId: string }) => {
    setUid(employeeId);
  };

  const templateColumns = [
    'auto',
    'min-content',
    hasMultipleAttributeOptions(talent) ? 'max-content' : '',
  ].join(' ');

  return (
    <>
      <Box.form
        alignItems={formState.errors['employeeId'] ? 'center' : 'end'}
        display="grid"
        gap="d4"
        gridTemplateColumns={templateColumns}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputWrapper shouldShowTooltip={!activeHRISIntegration} tooltipState={tooltipState}>
          <ControlledFormInput
            control={control}
            disabled={!activeHRISIntegration}
            id="employeeId"
            label={
              activeHRISIntegration ? `Employee ID (${startCase(providerName)})` : 'Employee ID'
            }
            name="employeeId"
            role="textbox"
            type="text"
          />
        </InputWrapper>

        <Button
          disabled={!activeHRISIntegration}
          loading={isLoadingData}
          type="submit"
          variant="outline"
        >
          Search
          {isLoadingData && <LoadingIcon size="sizeIcon30" />}
        </Button>
        {hasMultipleAttributeOptions(talent) && (
          <Button loading={isLoadingData} onClick={modalState.show} type="button" variant="outline">
            Select attributes
          </Button>
        )}
      </Box.form>

      {activeHRISIntegration && (
        <Box.div marginBottom="m4">
          {providerName && (
            <Callout>
              {[
                'Enter Employee ID to automatically fill the data fields below.',
                'During the case, this ID is also required for automatic file upload to',
                `${PROVIDER_NAME[providerName]}.`,
                'Not sure where to find the Employee ID? ',
              ].join(' ')}
              <Anchor
                href={getProviderPageLink(
                  activeHRISIntegration.provider_data.stackone_provider_name,
                )}
              >
                Learn more
              </Anchor>
            </Callout>
          )}
        </Box.div>
      )}
      {isModalOpen && (
        <HRISDataSelectionModal setHRISData={setHRISData} state={modalState} talent={talent} />
      )}
    </>
  );
};
