export const getTalentStatusBadgeProps = (
  isActive: boolean,
): {
  status: string;
  color: 'gray' | 'green';
} => {
  return isActive
    ? {
        status: 'Active',
        color: 'green',
      }
    : {
        status: 'Inactive',
        color: 'gray',
      };
};
