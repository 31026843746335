import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { PaginationInformation } from 'deprecated/api/types/PaginationInformation';
import { Task } from 'modules/tasks/types/Task';

export type GetTasksResponse = {
  tasks: Task[];
  meta?: PaginationInformation;
};

export type UseTasksParams = {
  page?: string;
  items?: number;
  open?: boolean;
  case_id?: number;
  all_items?: boolean;
  company_id?: number;
  follower_id?: number;
  hr_manager_id?: number;
  case_manager_id?: number;
  sort_by?: string;
  sort_direction?: string;
};

const getTasks = async (params: UseTasksParams): Promise<GetTasksResponse> => {
  const { data } = await axios.get<GetTasksResponse>('/tasks', { params });

  return data;
};

export const getTasksQueryKey = (params: UseTasksParams): (boolean | number | string)[] => {
  return ['tasks', ...Object.values(params)];
};

export const useTasks = (
  params: UseTasksParams = {},
  options: Pick<UseQueryOptions<GetTasksResponse, AxiosError>, 'enabled'> = {
    enabled: true,
  },
): UseQueryResult<GetTasksResponse, AxiosError> => {
  return useQuery({
    queryKey: getTasksQueryKey(params),
    queryFn: () => getTasks(params),
    throwOnError: false,
    ...options,
  });
};
