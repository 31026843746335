import { Box, Heading } from '@localyze-pluto/components';
import { useTheme } from '@localyze-pluto/theme';
import { SectionCard } from 'components/SectionCard/SectionCard';
import React from 'react';

export const DrawerSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}): React.JSX.Element => {
  const theme = useTheme();

  return (
    <SectionCard>
      <SectionCard.Header px="d6" py="d4">
        <Heading
          as="h2"
          mb="0"
          style={{ fontSize: theme.fontSizes.fontSize30, lineHeight: theme.lineHeights.lh6 }}
        >
          {title}
        </Heading>
      </SectionCard.Header>
      <SectionCard.Content padding="d0">
        <Box.div w="100%">{children}</Box.div>
      </SectionCard.Content>
    </SectionCard>
  );
};
