import React, { useState } from 'react';
import { Row as RowType } from './types/Row';
import { Rows } from './components/Rows';
import { Table, TBody, Th, THead, Tr } from '@localyze-pluto/components';
import { isEmpty } from 'lodash';

type Props = {
  /**
   * @desc the size of the columns applied to the content in each row
   **/
  headerLabels?: string[];
  /**
   * @desc rows labels and content
   **/
  isHeaderFixed?: boolean;
  /**
   * @desc header is fixed and does not scroll with list if true
   **/
  rows?: RowType[];
  /**
   * @desc index of the row that is initially opened
   **/
  openedIndex?: number | undefined;
};

const defaultIndex = -1;

export const StackedTable = ({
  rows = [],
  headerLabels = [],
  isHeaderFixed = false,
  openedIndex = defaultIndex,
}: Props): React.JSX.Element => {
  const [currentOpenedIndex, setCurrentOpenedIndex] = useState(openedIndex);

  const onClick = (index: number) => {
    setCurrentOpenedIndex(currentOpenedIndex === index ? defaultIndex : index);
  };

  return (
    <Table>
      <THead isSticky={isHeaderFixed}>
        <Tr>
          {!isEmpty(headerLabels) &&
            headerLabels.map((header, index) => {
              return <Th key={header || index}>{header}</Th>;
            })}
        </Tr>
      </THead>
      <TBody data-testid="stacked-list-rows">
        <Rows currentOpenedIndex={currentOpenedIndex} onClick={onClick} rows={rows} />
      </TBody>
    </Table>
  );
};
