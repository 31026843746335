import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { queryClient } from 'config/queryClient';
import { getStatusUpdatesQueryKey } from 'modules/statusUpdates/api/useStatusUpdates/useStatusUpdates';
import { StatusUpdateSubjectType } from 'modules/statusUpdates/types/StatusUpdateSubjectType';
import { StatusUpdateType } from 'modules/statusUpdates/types/StatusUpdateType';
import { StatusUpdate } from 'modules/statusUpdates/types/StatusUpdate';
import { getCaseQueryKey } from 'modules/cases/api/useCase/useCase';

type CreateStatusUpdatePayload = {
  subjectId: number;
  subjectType: StatusUpdateSubjectType;
  message: string;
  statusType: StatusUpdateType;
  waitingUntil: Maybe<string>;
};

async function createStatusUpdate(params: CreateStatusUpdatePayload) {
  return await axios.post('/status_updates', {
    subject_id: params.subjectId,
    subject_type: params.subjectType,
    message: params.message,
    status_type: params.statusType,
    waiting_until: params.waitingUntil,
  });
}

export const useCreateStatusUpdate = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<AxiosResponse<StatusUpdate>, AxiosError, CreateStatusUpdatePayload>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<AxiosResponse<StatusUpdate>, AxiosError, CreateStatusUpdatePayload> =>
  useMutation({
    mutationFn: createStatusUpdate,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getStatusUpdatesQueryKey({
          subjectId: variables.subjectId,
          subjectType: variables.subjectType,
        }),
      });

      if (variables.subjectType === 'Case') {
        await queryClient.invalidateQueries({ queryKey: getCaseQueryKey(variables.subjectId) });
      }

      onSuccess?.(data, variables, context);
    },
    onError,
  });
