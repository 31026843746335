import axios from 'axios';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';
import { BillingPeriod } from 'modules/billing/types/BillingPeriod';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

const processBillingPeriod = async (id: number): Promise<BillingPeriod> => {
  const { data } = await axios.post<BillingPeriod>(`/admin/billing_periods/${id}/process`, { id });

  return data;
};

export const useProcessBillingPeriod = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<BillingPeriod, AxiosErrorWithMessage, number>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<BillingPeriod, AxiosErrorWithMessage, number> => {
  return useMutation({
    mutationFn: processBillingPeriod,
    onSuccess,
    onError,
  });
};
