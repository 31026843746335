import { AxiosResponse } from 'axios';
import { axios } from 'config/axios/axios';
import { CompanyBaseFields } from 'modules/company/types/CompanyBaseFields';

type GetCompaniesDataParams = {
  q: string;
  account_status: string;
};

/**
 * @deprecated use useAdminCompanies instead
 */
export const getCompaniesData = (
  params: GetCompaniesDataParams,
  callbackSuccess: (response: AxiosResponse<CompanyBaseFields[]>) => void,
): void => {
  axios('/admin/companies', {
    method: 'get',
    params,
  })
    .then((response) => {
      return callbackSuccess(response);
    })
    .catch(() => {});
};
