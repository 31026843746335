import axios from 'axios';

export const getCaseInformation = async (caseId, internalReferenceCodes) => {
  const params = {
    case_id: caseId,
    internal_reference_codes: internalReferenceCodes,
  };
  try {
    const response = await axios.get('/case_informations', { params });
    return response;
  } catch (e) {
    throw new Error('Something is wrong with our services. Hold on there or send us an email');
  }
};
