import { Company } from 'modules/company/types/Company';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { identifyUser } from '../analytics';
import { getAnalyticsData } from './getAnalyticsData';

export type AnalyticsData = {
  id: number;
  company: Pick<Company, 'hubspot_id' | 'id' | 'name'>;
  email: string;
  first_name: string;
  role_name: string;
  user_type: string;
  user_hash: string;
  internal_id: string;
  identifier: string;
};

export const setWindowDataLayer = (analyticsData: AnalyticsData): void => {
  const data = {
    internal_id: analyticsData.id,
    email: analyticsData.email,
    name: analyticsData.first_name,
    user_type: analyticsData.user_type,
    role: analyticsData.role_name,
    company: analyticsData.company,
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
  identifyUser(analyticsData.identifier, data, {
    integrations: {
      All: true,
      Intercom: {
        user_hash: analyticsData.user_hash,
      },
    },
  });
};

export const useAnalyticsData = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}): UseQueryResult<AnalyticsData> => {
  return useQuery({
    queryKey: ['analytics'],
    queryFn: getAnalyticsData,
    enabled,
  });
};
