import { COMPANY_FILTER, parseSelectedFilters, populateFilters } from 'deprecated/libs/filters';
import React, { useEffect, useMemo } from 'react';

import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { TasksFilters } from './components/TasksFilters';
import { TasksTable } from './components/TasksTable';
import { getColumns } from './helpers/getColumns';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { useTabWithQueryParams } from 'deprecated/hooks/custom/useTabWithQueryParams';
import { Box, Tab, TabList, Tabs } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useTableSorting } from 'components/DataTable/useTableSorting';
import { Tab as TabType } from 'types/Tab';
import { trackPageView } from 'modules/analytics/analytics';
import { PaginationWithCounter } from 'components/PaginationWithCounter/PaginationWithCounter';
import { useTasks } from 'modules/tasks/api/useTasks/useTasks';
import { usePagination } from 'modules/filters/hooks/usePagination';
import { User } from 'deprecated/api/user/types/User';
import { useFiltersOptions } from 'modules/filters/hooks/useFiltersOptions';
import { PaginationInformation } from 'deprecated/api/types/PaginationInformation';
import { useCurrentUser } from 'config/CurrentUserContext';

type TaskTabKey = 'all-tasks' | 'my-tasks';
const TASKS_TITLE = 'Help your talent move forward';

const TABS: TabType<TaskTabKey>[] = [
  {
    name: 'My tasks',
    key: 'my-tasks',
  },
  {
    name: 'All',
    key: 'all-tasks',
  },
];

const getMyTasksFilter = (selectedTab: TaskTabKey, user?: User) => {
  if (!user || selectedTab === 'all-tasks') return {};

  const userIdFilter = user.localyze_admin ? 'case_manager_id' : 'hr_manager_id';
  return { [userIdFilter]: user.id };
};

export const TasksList = (): React.JSX.Element => {
  const user = useCurrentUser();
  const { queryParams } = useQueryParams();
  const { tabs, selectedTab, getTabIndex, updateSelectedTab } = useTabWithQueryParams(TABS);

  const {
    isError: isErrorFilter,
    isSuccess: isSuccessFilters,
    data: availableFilters = [],
  } = useFiltersOptions({
    queryKey: `task-filters-${selectedTab.key}`,
    filterParams: {
      filters: [COMPANY_FILTER],
      isAdmin: user.isAdmin,
      tab: `${selectedTab.key}-tasks-filter`,
    },
  });

  const selectedFilters = useMemo(
    () => parseSelectedFilters(availableFilters, queryParams),
    [availableFilters, queryParams],
  );

  const handleTabChange = (tab: TaskTabKey) => {
    const tabIndex = getTabIndex(tabs, tab);
    updateSelectedTab(tabIndex);
  };

  useEffect(() => {
    populateFilters({ filters: [COMPANY_FILTER], isAdmin: user.isAdmin, tab: selectedTab.key });
  }, [user, selectedTab.key]);

  useEffect(() => {
    trackPageView('My Tasks');
  }, []);

  const { sorting, onSortingChange } = useTableSorting();

  const myTasksFilter = getMyTasksFilter(selectedTab.key, user);
  const [page, setPage] = usePagination();
  const {
    data,
    isSuccess: isSuccessTasks,
    isError: isErrorTasks,
  } = useTasks({ page, open: true, ...selectedFilters, ...myTasksFilter });

  if (isErrorFilter || isErrorTasks) {
    return <ErrorStatePage />;
  }

  return (
    <PageLayout flexed title={TASKS_TITLE}>
      <Box.div alignItems="center" display="flex" justifyContent="space-between" paddingRight="p2">
        <Tabs initialTabId={selectedTab.key}>
          <TabList aria-label="Task tabs">
            {tabs.map((tab) => {
              return (
                <Tab id={tab.key} key={tab.key} onClick={() => handleTabChange(tab.key)}>
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
        </Tabs>
        <Box.section
          alignItems="center"
          data-testid="filters-bar"
          display="flex"
          justifyContent="space-between"
        >
          {!!availableFilters.length && <TasksFilters availableFilters={availableFilters} />}
        </Box.section>
      </Box.div>

      <Box.div
        display="flex"
        flex={1}
        flexDirection="column"
        gap="d5"
        justifyContent="space-between"
        marginTop="m4"
      >
        {!isSuccessTasks || !isSuccessFilters ? (
          <ContainedLoadingState />
        ) : (
          <>
            <TasksTable
              columns={getColumns()}
              onSortingChange={onSortingChange}
              sorting={sorting}
              tasks={data.tasks}
            />
            <PaginationWithCounter
              paginationInfo={data.meta as PaginationInformation}
              setPage={setPage}
            />
          </>
        )}
      </Box.div>
    </PageLayout>
  );
};
