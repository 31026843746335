import React, { useEffect, useState } from 'react';

import { IntercomIcon } from 'deprecated/components/Chewpaca/Icons';
import { getInternationalIntegration } from 'deprecated/api/admin/internationals/integrations';
import { useToast } from '@localyze-pluto/components';
import { isAxiosError } from 'axios';

export const Intercom = ({ talentId }: { talentId: number }): React.JSX.Element => {
  const [intercomProfileUrl, setIntercomProfileUrl] = useState('');
  const toast = useToast();

  useEffect(() => {
    const fetchIntegration = async () => {
      try {
        const { data } = await getInternationalIntegration(talentId, 'intercom');

        setIntercomProfileUrl(data[0]?.url || '');
      } catch (e) {
        const errorMessage = isAxiosError(e) ? e.message : 'Something went wrong';
        toast(errorMessage, 'error');
      }
    };

    fetchIntegration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentId]);

  return (
    <a
      className={`tw-font-sans tw-text-xs tw-leading-4 tw-font-medium tw-text-blue-500
        tw-flex tw-items-center
        tw-justify-center tw-w-full hover:tw-text-blue-700 hover:tw-no-underline`}
      href={intercomProfileUrl}
      rel="noreferrer"
      target="_blank"
    >
      <IntercomIcon />
      <span className="tw-ml-1">Intercom</span>
    </a>
  );
};
