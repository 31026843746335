import { THead, Table, Th, Tr, Text, TBody, Td } from '@localyze-pluto/components';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import React from 'react';

type GlomoRequestQuestionsTableProps = {
  questions: GlomoRequest['questions'];
};

export const GlomoRequestQuestionsTable = ({
  questions,
}: GlomoRequestQuestionsTableProps): React.JSX.Element => (
  <Table striped>
    <THead>
      <Tr>
        <Th
          style={{
            width: '50%',
          }}
        >
          <Text.span fontSize="fontSize20" fontWeight="fontWeightBold" lineHeight="lineHeight20">
            Required information
          </Text.span>
        </Th>
        <Th>
          <Text.span fontSize="fontSize20" fontWeight="fontWeightBold" lineHeight="lineHeight20">
            Details
          </Text.span>
        </Th>
      </Tr>
    </THead>
    <TBody>
      {questions.map(({ key, question, answer }) => {
        return (
          <Tr key={key}>
            <Td>
              <Text.span
                fontSize="fontSize20"
                fontWeight="fontWeightRegular"
                lineHeight="lineHeight20"
              >
                {question}
              </Text.span>
            </Td>
            <Td>
              <Text.span
                fontSize="fontSize20"
                fontWeight="fontWeightBold"
                lineHeight="lineHeight20"
              >
                {answer}
              </Text.span>
            </Td>
          </Tr>
        );
      })}
    </TBody>
  </Table>
);
