import { getFilteredChartEntries } from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getFilteredChartEntries';
import { Context } from 'chartjs-plugin-datalabels';
import { CaseMetricEntry } from 'modules/reporting/api/types/CaseMetrics';
import { CHART_COLORS } from 'modules/reporting/constants/chartColors';

export type ChartOptions = {
  plugins: {
    datalabels: {
      formatter: (value: Record<string, number>, context: Context) => number | string;
    };
  };
  skipNull: boolean;
};

type DataRecord = Record<string, number>;
export type DataItem = DataRecord | number;
type Data = DataItem[];

export type ChartConfig = {
  labels: string[];
  datasets: {
    data: Data;
    backgroundColor: string[] | string;
    datalabels: { color: string };
  }[];
};

export const getChartConfig = (
  entries: CaseMetricEntry[],
  shouldApply5PercentFilter = true,
): ChartConfig => {
  const filteredEntries = shouldApply5PercentFilter ? getFilteredChartEntries(entries) : entries;

  return {
    labels: filteredEntries.map((entry) => entry.label),
    datasets: [
      {
        data: filteredEntries.map((entry) => entry.count),
        backgroundColor: CHART_COLORS,
        datalabels: {
          color: 'black',
        },
      },
    ],
  };
};

const labelFormatter = (value: Record<string, number>, context: Context) => {
  return value[context.dataset.label as string] || '';
};

export const getGroupedChartOptions = (): ChartOptions => ({
  plugins: {
    datalabels: {
      formatter: labelFormatter,
    },
  },
  skipNull: true,
});
