import React, { forwardRef, Ref } from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { Search } from '@localyze-pluto/components';

type Props = { readonly onChange: () => void };

export const SearchInput = forwardRef(
  ({ onChange }: Props, ref: Ref<HTMLInputElement>): React.JSX.Element => (
    <Search
      onBlur={() => {
        trackEvent('topbar: search for talent');
      }}
      onChange={onChange}
      placeholder="Search talents"
      ref={ref}
    />
  ),
);

SearchInput.displayName = 'SearchInput';
