import axios, { AxiosError } from 'axios';
import { isUndefined } from 'lodash';
import { ProductAdditionalService } from 'modules/additionalServices/types/ProductAdditionalService';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

const getProductAdditionalServices = async (
  destinationCountryId: number,
  companyId: number,
): Promise<ProductAdditionalService[]> => {
  const url = '/product/additional_services';
  const params = {
    destination_country_id: destinationCountryId,
    company_id: companyId,
  };
  const { data } = await axios.get<ProductAdditionalService[]>(url, { params });

  return data;
};

type UseProductAdditionalServicesProps<Result> = Pick<
  UseQueryOptions<ProductAdditionalService[], AxiosError, Result>,
  'select'
> & { companyId: number; destinationCountryId: number };

export const useProductAdditionalServices = <Result = ProductAdditionalService[]>({
  select,
  companyId,
  destinationCountryId,
}: UseProductAdditionalServicesProps<Result>): UseQueryResult<Result> => {
  return useQuery({
    select,
    queryKey: ['product-additional-services', destinationCountryId, companyId],
    enabled: !isUndefined(companyId) && !isUndefined(destinationCountryId),
    queryFn: () => getProductAdditionalServices(destinationCountryId, companyId),
  });
};
