import { AdminRoutes, HrRoutes, useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Anchor, Box, Button, Icon, useToast } from '@localyze-pluto/components';
import { Link, useHistory, useParams } from 'react-router-dom';

import { AddOfficePayload } from 'modules/offices/api/useAddOffice/useAddOffice.types';
import React from 'react';
import { useAddOffice } from 'modules/offices/api/useAddOffice/useAddOffice';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { OfficeForm } from 'modules/company/pages/Offices/OfficeForm';
import { useCurrentUser } from 'config/CurrentUserContext';

export const AddOffice = (): React.JSX.Element => {
  const { companyId: companyIdParam } = useParams<{ companyId: string }>();
  const { company_id, isAdmin } = useCurrentUser();

  const companyId = isAdmin ? companyIdParam : company_id;

  const history = useHistory();
  const toast = useToast();
  const routes = useRoutes();
  const officesLink = isAdmin
    ? (routes as AdminRoutes).companyProfile.get(companyId)
    : (routes as HrRoutes).offices.get();
  const addOffice = useAddOffice({
    onSuccess: () => {
      toast('Office was successfully added.', 'success');
      history.push(officesLink);
    },
    onError: (error) => {
      rollbarInstance.error(error);
      toast(error.response?.data.message || 'There was an error adding an office.', 'error');
    },
  });

  const onSubmit = async (values: AddOfficePayload) => {
    addOffice.mutate({
      companyId,
      payload: values,
    });
  };

  return (
    <PageLayout title="Add a new office">
      <Anchor as={Link} to={officesLink}>
        <Box.span alignItems="center" display="flex" gap="d1" marginBottom="m4">
          <Icon decorative icon="arrow-left" size="sizeIcon20" />
          Back to all offices
        </Box.span>
      </Anchor>
      <Box.section className="tw-rounded-2xl tw-bg-white tw-p-8">
        <OfficeForm isEditing={true} onSubmit={onSubmit} />
        <Box.div display="flex" flexDirection="row" gap="d4" marginTop="d5">
          <Button
            disabled={addOffice.isPending}
            form="officeForm"
            loading={addOffice.isPending}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>

          <Button as={Link} to={officesLink} type="button" variant="secondary">
            Cancel
          </Button>
        </Box.div>
      </Box.section>
    </PageLayout>
  );
};
