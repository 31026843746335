import React from 'react';
import { Box, Heading, Paragraph, Icon } from '@localyze-pluto/components';
import { YellowIcon } from 'modules/cases/components/YellowIcon';
import { GrayIcon } from 'modules/cases/components/GrayIcon';

export const SatisfactionScore = ({
  items,
  score,
}: {
  items: string[];
  score: number;
}): React.JSX.Element => {
  const lastStarPercentage = (score % 1) * 100;

  return (
    <Box.div
      backgroundColor="colorBackgroundInfo"
      borderRadius="borderRadius10"
      display="flex"
      flexDirection="column"
      gap="d4"
      padding="p6"
    >
      <Box.div alignItems="center" display="flex" flexDirection="column">
        <Heading as="h4" fontSize="fontSize20" lineHeight="lh8" marginBottom="d0">
          Talent Satisfaction Score
        </Heading>
        <Box.div alignItems="center" display="flex">
          <Box.div
            color="colorTextInfo"
            fontSize="fontSize30"
            fontWeight="fontWeightBold"
            marginRight="d1"
          >
            {score}
          </Box.div>
          <Box.div alignItems="end" display="flex">
            <YellowIcon decorative icon="star" size="sizeIcon30" />
            <YellowIcon decorative icon="star" size="sizeIcon30" />
            <YellowIcon decorative icon="star" size="sizeIcon30" />
            <YellowIcon decorative icon="star" size="sizeIcon30" />
            <Box.div h={20} position="relative">
              <GrayIcon decorative icon="star" size="sizeIcon30" />
              <Box.div
                left="0"
                overflow="hidden"
                position="absolute"
                top="0"
                w={`${lastStarPercentage}%`}
              >
                <YellowIcon decorative icon="star" size="sizeIcon30" />
              </Box.div>
            </Box.div>
          </Box.div>
        </Box.div>
      </Box.div>
      <Paragraph
        color="colorTextInfo"
        fontSize="fontSize10"
        lineHeight="lineHeight10"
        marginBottom="d0"
      >
        We recommend booking additional services for your talent to improve their experience and
        ensure a higher satisfaction score.
      </Paragraph>
      <Box.ul
        color="colorTextInfo"
        display="grid"
        fontSize="fontSize10"
        gap="d1_5"
        listStyleType="none"
        margin="d0"
        padding="p0"
      >
        {items.map((label) => (
          <Box.li display="flex" key={label}>
            <Icon color="colorIconSuccess" decorative icon="check" size="sizeIcon20" />
            <Box.span lineHeight="lineHeight10" marginLeft="m1">
              {label}
            </Box.span>
          </Box.li>
        ))}
      </Box.ul>
    </Box.div>
  );
};
