import { Box } from '@localyze-pluto/components';
import { PageLayout } from 'modules/layout/components/PageLayout';
import React, { useEffect } from 'react';
import { TalentProfileHeader } from './components/TalentProfileHeader/TalentProfileHeader';
import { TalentInformationCard } from './components/TalentInformationCard/TalentInformationCard';
import { CasesCard } from './components/CasesCard/CasesCard';
import { PersonalDataCollectionConsentCallout } from 'modules/talent/pages/talentProfile/components/PersonalDataCollectionConsentCallout';
import { shouldShowConsentCallout } from 'modules/talent/pages/talentProfile/utils/shouldShowConsentCallout';
import { useTalent } from 'modules/talent/pages/talentProfile/components/TalentContext/hooks/useTalent';

export const TalentProfile = (): React.JSX.Element => {
  const talent = useTalent();

  useEffect(() => {
    document.title = `${talent.first_name} ${talent.last_name}`;

    return () => {
      document.title = 'Localyze';
    };
  }, [talent]);

  return (
    <PageLayout flexed maxW={1094} mx="auto" px="d8" w="100%">
      <Box.div
        display="flex"
        flexDirection="column"
        gap="d4"
        justifyContent="center"
        marginBottom="m4"
        w="100%"
      >
        <TalentProfileHeader />
        {shouldShowConsentCallout(talent) && <PersonalDataCollectionConsentCallout />}
        <TalentInformationCard />
        <CasesCard />
      </Box.div>
    </PageLayout>
  );
};
