import React, { Fragment } from 'react';
import { Row } from '../types/Row';
import { RowItems } from './RowItems';
import { isEmpty } from 'lodash';
import { Button, Td, Tr } from '@localyze-pluto/components';

type Props = {
  rows: Row[];
  currentOpenedIndex?: number;
  onClick: (index: number) => void;
};

const ToggleButton = ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => {
  return (
    <Button
      aria-label="Toggle variable group"
      iconOnly
      leadingIcon={isOpen ? 'chevron-down' : 'chevron-up'}
      onClick={onClick}
      variant="ghost"
    />
  );
};

export const Rows = ({ rows, currentOpenedIndex, onClick }: Props): React.JSX.Element => (
  <>
    {rows.map((row, index) => {
      const isOpen = currentOpenedIndex === index;

      const actionItem = isEmpty(row.content) ? (
        <Td />
      ) : (
        <Td>
          <ToggleButton isOpen={isOpen} onClick={() => onClick(index)} />
        </Td>
      );

      return (
        <Fragment key={row.id}>
          <Tr>
            {actionItem}
            <RowItems row={row} />
          </Tr>
          {isOpen && <>{row.content}</>}
        </Fragment>
      );
    })}
  </>
);
