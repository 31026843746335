import {
  Box,
  BoxProps,
  Button,
  ControlledFormInput,
  ControlledFormSelect,
  Heading,
} from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import React from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';

const RISK_TYPES = [
  {
    label: 'Warning',
    value: 'warning',
  },
  {
    label: 'Risk',
    value: 'risk',
  },
];

const Row = ({
  control,
  isDisabled,
  onClickRemove,
  index,
}: {
  control: Control;
  isDisabled: boolean;
  onClickRemove: () => void;
  index: number;
}) => {
  return (
    <Box.li display="grid" gap="d4" gridTemplateColumns="1fr 1fr 28px" marginBottom="d4">
      <ControlledFormInput
        control={control}
        disabled={isDisabled}
        id={`risksAndWarnings.${index}.description`}
        label="Description"
        name={`risksAndWarnings.${index}.description`}
        type="text"
      />

      <ControlledFormSelect
        control={control}
        disabled={isDisabled}
        id={`risksAndWarnings.${index}.risk_type`}
        items={RISK_TYPES}
        label="Type"
        name={`risksAndWarnings.${index}.risk_type`}
      />

      <Button
        aria-label="Delete row"
        backgroundColor="transparent"
        disabled={isDisabled}
        h={28}
        iconOnly
        leadingIcon="trash-2"
        marginTop="d6"
        onClick={onClickRemove}
        title="Delete row"
        variant="ghost"
      />
    </Box.li>
  );
};

export const RisksAndWarnings = ({ isDisabled }: { isDisabled: boolean }): React.JSX.Element => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'risksAndWarnings',
  });

  return (
    <Box.div marginBottom="d4">
      <SectionCard>
        <SectionCard.Header padding={'d4 d6' as BoxProps['padding']}>
          <Box.div
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="d0"
            zIndex="zIndex10"
          >
            <Heading as="h3" color="contentPrimary" marginBottom="d0" size="title-subsection">
              Add risks and warnings
            </Heading>
          </Box.div>
        </SectionCard.Header>
        <SectionCard.Content>
          <Box.div w="100%">
            <Heading
              as="h4"
              color="contentPrimary"
              fontSize="fontSize30"
              fontWeight="fontWeightRegular"
              lineHeight="lh4"
              marginBottom="d4"
              textAlign="left"
            >
              Copy the description of each warning and risk (one item per row).
            </Heading>
            <Box.ul listStyleType="none" margin="d0" padding="d0">
              {fields.map(({ id }, index) => (
                <Row
                  control={control}
                  index={index}
                  isDisabled={isDisabled}
                  key={id}
                  onClickRemove={() => remove(index)}
                />
              ))}
            </Box.ul>
            <Button
              disabled={isDisabled}
              leadingIcon="plus"
              onClick={() => append({ description: '', risk_type: 'warning' })}
              type="button"
              variant="ghost"
            >
              Add row
            </Button>
          </Box.div>
        </SectionCard.Content>
      </SectionCard>
    </Box.div>
  );
};
