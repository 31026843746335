import { Table, TBody, THead } from '@localyze-pluto/components';
import { HeaderRow } from './components/HeaderRow';
import React from 'react';
import { CaseOverviewRow } from './components/CaseOverviewRow';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  caseOverviews: CaseOverview[];
};

export const CaseOverviewsTable = ({ caseOverviews }: Props): React.JSX.Element => {
  return (
    <Table bordered striped>
      <THead isSticky style={{ zIndex: '15' }}>
        <HeaderRow />
      </THead>
      <TBody>
        {caseOverviews.map((caseOverview) => (
          <CaseOverviewRow caseOverview={caseOverview} key={caseOverview.id} />
        ))}
      </TBody>
    </Table>
  );
};
