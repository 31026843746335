import cx from 'clsx';

export const gradientCounterStyles = (gradient: string, className?: string): string =>
  cx(
    `tw-flex tw-flex-col tw-items-center tw-p-6 tw-rounded-2xl tw-outline-none tw-justify-center
      hover:tw-shadow-lg
      focus:tw-outline-none focus:tw-shadow-lg focus:tw-ring-4 focus:tw-ring-blue-500`,
    gradient,
    className,
  );
