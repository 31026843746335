import { Box, Avatar, Text } from '@localyze-pluto/components';
import React from 'react';

export const CaseUser = ({
  name,
  userType,
  avatarUrl,
}: {
  name?: string;
  userType: string;
  avatarUrl?: Maybe<string>;
}): React.JSX.Element => {
  return (
    <Box.div alignItems="center" display="flex" gap="d2">
      <Text.span
        color="locaMarine300"
        fontSize="fontSize20"
        fontWeight="fontWeightBold"
        lineHeight="lh5"
      >
        {userType}:
      </Text.span>
      <Box.div alignItems="center" display="flex" gap="d2">
        {name && <Avatar name={name} size="small" src={avatarUrl ?? ''} />}
        <Text.span color="colorTextInverse" fontSize="fontSize20">
          {name ?? 'Unassigned'}
        </Text.span>
      </Box.div>
    </Box.div>
  );
};
