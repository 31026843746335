import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { StripeBillingPortalSession } from '../../types/StripeBillingPortalSession';
import { UseStripeBillingPortalSessionProps } from './useStripeBillingPortalSession.types';

const getStripeBillingPortalSession = async (
  companyId?: number,
): Promise<StripeBillingPortalSession> => {
  if (companyId === undefined) {
    throw new Error('Company ID is required to fetch stripe billing portal session');
  }

  const { data } = await axios.get<StripeBillingPortalSession>(
    `/stripe_billing_portal_session?company_id=${companyId}`,
  );

  return data;
};

/**
 * Gets stripe billing session
 * Used to link to a company's stripe invoices
 * @param props.select - transform returned data
 * @param props.params.companyId - company to fetch stripe billing session for
 * @returns stripe billing session
 */
export const useStripeBillingPortalSession = <Result = StripeBillingPortalSession>({
  select,
  params,
}: UseStripeBillingPortalSessionProps<Result> = {}): UseQueryResult<Result, AxiosError> => {
  return useQuery({
    queryKey: ['stripe-billing-portal-session', params?.companyId],
    queryFn: () => getStripeBillingPortalSession(params?.companyId),
    enabled: !!params?.companyId,
    select,
  });
};
