import { Box, Heading } from '@localyze-pluto/components';

import React from 'react';

type FormSectionProps = {
  /** Section heading */
  heading: string;
  /** Section fields */
  children: React.ReactNode;
  /** Number of columns fields should be in */
  columns?: number;
  /** Action next to the section heading */
  headerAction?: React.ReactNode;
  border?: boolean;
};

/**
 * Section heading with a column grid for content
 */
const FormSection = ({
  heading,
  headerAction,
  children,
  columns = 3,
  border = true,
}: FormSectionProps): React.JSX.Element => {
  return (
    <>
      {headerAction ? (
        <Box.div alignItems="center" display="flex" justifyContent="space-between">
          <Heading as="h2" size="heading60">
            {heading}
          </Heading>
          {headerAction}
        </Box.div>
      ) : (
        <Heading as="h2" size="heading60">
          {heading}
        </Heading>
      )}

      <Box.div
        {...(border && {
          borderBottomColor: 'colorBorderWeaker',
          borderBottomStyle: 'borderStyleSolid',
          borderBottomWidth: 'borderWidth10',
          paddingBottom: 'p4',
          marginBottom: 'm3',
        })}
        display="grid"
        gap="d4"
        gridTemplateColumns={{ lg: columns }}
        justifyContent="space-between"
      >
        {children}
      </Box.div>
    </>
  );
};

export default FormSection;
