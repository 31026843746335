import React, { ReactNode, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  HelpText,
  Label,
  Select,
  SelectItemProps,
} from '@localyze-pluto/components';
import { sortByLabel } from 'utils/sortByLabel';
import { isEmpty } from 'lodash';

export type MultiUserSelectItem = SelectItemProps & {
  email: string;
  name: string;
  avatar?: Maybe<string>;
};

type Props = {
  items: MultiUserSelectItem[];
  name: string;
  disabled?: boolean;
  label?: string;
  helpText?: ReactNode | string;
  value?: string[];
  onChange?: (value: string[]) => void;
  emptyMessage?: string;
};

/**
 * Multi User Select that lists the selected users and shows their name, email
 * and avatar
 * @param disabled
 * @param items - the available items in the select
 * @param label
 * @param helpText
 * @param value - array with the selected values
 * @param onChange - callback for when the value of the select field changes
 * @param name - name of the underlying input component
 */
export const MultiUserSelect = ({
  disabled,
  items = [],
  label,
  helpText,
  value = [],
  onChange,
  name,
  emptyMessage,
}: Props): React.JSX.Element => {
  const [usersList, setUsersList] = useState<string[]>([]);
  const [availableItems, setAvailableItems] = useState<MultiUserSelectItem[]>([]);

  const helpTextID = `${name}-help-text`;

  useEffect(() => {
    setUsersList(value);
  }, [value]);

  useEffect(() => {
    const availableItems = items.filter((item) => !usersList.includes(item.value));
    setAvailableItems(availableItems);
  }, [items, usersList, value]);

  const removeSelectedItem = (itemValue: string) => {
    const removedItem = items.find((item) => item.value === itemValue);
    const isItemAvailable = availableItems.find((item) => item.value === itemValue);

    if (!isItemAvailable && removedItem) {
      setAvailableItems((prevItems) => [...prevItems, removedItem].sort(sortByLabel));
    }

    const updatedUsers = usersList.filter((f) => f !== itemValue);
    setUsersList(updatedUsers);

    if (onChange) {
      onChange(updatedUsers);
    }
  };

  const addSelectedItems = (value: string[]) => {
    const updatedItems = [...usersList, ...value];
    setAvailableItems((prevAvailableItems) =>
      prevAvailableItems.filter((item) => !updatedItems.includes(item.value)),
    );

    setUsersList(updatedItems);

    if (onChange) {
      onChange(updatedItems);
    }
  };

  const noUsersAvailable = emptyMessage ?? 'No users available to select.';

  return (
    <Box.div>
      <Label htmlFor={name}>{label}</Label>
      <Select
        aria-describedby={helpTextID}
        disabled={disabled}
        id={name}
        items={isEmpty(availableItems) ? [{ label: noUsersAvailable, value: '' }] : availableItems}
        name={name}
        placeholder="Select one or multiple"
        setValue={(value) => addSelectedItems(value as string[])}
        // Adds empty array to avoid setting empty labels in the input
        // @example (,,,)
        value={[]}
      />
      <HelpText id={helpTextID}>{helpText}</HelpText>
      {!isEmpty(usersList) && (
        <Box.ul
          data-testid="selected-users-list"
          display="flex"
          flexDirection="column"
          gap="d4"
          listStyleType="none"
          marginBottom="m0"
          marginTop="m4"
          paddingLeft="p0"
        >
          {usersList.map((itemValue) => {
            const item = items.find((item) => item.value === itemValue);

            return (
              !!item && (
                <Box.li
                  alignItems="center"
                  aria-label={item.name}
                  display="flex"
                  justifyContent="space-between"
                  key={itemValue}
                  paddingBottom="p1"
                >
                  <Box.div>
                    <Box.div display="inline-block" marginRight="m2" verticalAlign="top">
                      <Avatar name={item.name} size="small" src={String(item.avatar)} />
                    </Box.div>
                    <Box.span
                      fontSize="fontSize20"
                      fontWeight="fontWeightBold"
                      lineHeight="lineHeight30"
                    >
                      {item.name}
                    </Box.span>
                    <Box.span color="colorText" fontSize="fontSize20" paddingLeft="p4">
                      {item.email}
                    </Box.span>
                  </Box.div>
                  <Box.div h="d4">
                    {!disabled && (
                      <Button
                        aria-label={`Remove follower ${item.name}`}
                        iconOnly
                        leadingIcon="x"
                        onClick={() => removeSelectedItem(itemValue)}
                        type="button"
                        variant="ghost"
                      />
                    )}
                  </Box.div>
                </Box.li>
              )
            );
          })}
        </Box.ul>
      )}
    </Box.div>
  );
};
