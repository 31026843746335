import { Combobox } from '@headlessui/react';
import React from 'react';
import { Box, Input } from '@localyze-pluto/components';
import { isEmpty } from 'lodash';
import { CaseSearchResult, TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';
import { CaseFormValue } from 'modules/billing/components/AddDebitModal/AddDebitModal';
import { useTalentSearch } from 'components/TalentSearch/useTalentSearch';

const SearchResultOption = ({ text }: { text: string }) => (
  <div className="tw-cursor-default tw-select-none tw-relative tw-py-2 tw-px-4">
    <span className="tw-text-sm tw-leading-5 tw-text-gray-700">{text}</span>
  </div>
);

const getCaseLabel = (kase: Maybe<CaseSearchResult>, withCaseInfo: boolean) => {
  if (!kase) return '';

  return withCaseInfo
    ? `${kase.full_name_sorted} - ${kase.case_type_name} #${kase.id}`
    : kase.full_name_sorted;
};

const talentResultToCaseResult = (
  talent: TalentSearchResult,
  kase: TalentSearchResult['cases'][0],
): CaseSearchResult => ({
  ...talent,
  talent_id: talent.id,
  ...kase,
});

export const CaseSearchComboBox = ({
  onChange,
  value,
  companyId,
  displayCases = true,
}: {
  onChange: (kase: CaseSearchResult) => void;
  value: Maybe<CaseFormValue>;
  companyId?: number;
  displayCases?: boolean;
}): React.JSX.Element => {
  const { search, talents, searchTerm, isPending } = useTalentSearch(companyId);

  const cases: CaseSearchResult[] = talents.flatMap((talent) =>
    displayCases
      ? talent.cases.map((kase) => talentResultToCaseResult(talent, kase))
      : [talentResultToCaseResult(talent, talent.cases[0])],
  );

  const renderSearchResults = () => {
    if (isEmpty(searchTerm)) return;
    if (isPending) return <SearchResultOption text="Searching..." />;
    if (isEmpty(talents)) return <SearchResultOption text="No results." />;

    return (
      <>
        {cases.map((kase) => (
          <Box.div className="tw-border-r-0 tw-border-l-0 tw-block" key={kase.id}>
            <Combobox.Option
              className={`tw-cursor-pointer tw-select-none tw-relative
                tw-py-2 tw-px-4 tw-text-gray-900 hover:tw-bg-gray-100`}
              value={kase}
            >
              <Box.span
                className={`tw-truncate tw-text-sm tw-leading-5 tw-font-normal tw-w-full
            tw-text-black`}
              >
                {getCaseLabel(kase, displayCases)}
              </Box.span>
            </Combobox.Option>
          </Box.div>
        ))}
      </>
    );
  };

  const handleSelect = (kase: CaseSearchResult) => {
    onChange(kase);
  };

  const label = displayCases ? 'Search Case' : 'Search Talent';

  return (
    <Combobox nullable onChange={handleSelect} value={value}>
      <div className="tw-relative tw-h-full">
        <Combobox.Input
          aria-label={label}
          as={Input}
          displayValue={(kase: Maybe<CaseSearchResult>) => getCaseLabel(kase, displayCases)}
          leadingIcon="search"
          onChange={search}
          placeholder={label}
          type="text"
        />
        <Combobox.Options
          className="tw-absolute tw-w-full tw-mt-1 tw-mb-0 tw-border-none tw-overflow-auto
                     tw-bg-white tw-rounded-lg tw-shadow-xl tw-max-h-60 tw-list-none tw-p-0
                     tw-divide-y tw-divide-solid tw-z-20"
        >
          {renderSearchResults()}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};
