import React, { useEffect, useState } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import {
  Box,
  Button,
  Icon,
  Tooltip,
  TooltipAnchor,
  useDrawerStore,
  useToast,
  useTooltipStore,
} from '@localyze-pluto/components';
import { isEmpty } from 'lodash';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { trackPageView } from 'modules/analytics/analytics';
import { useGlomoRequests } from 'modules/glomoRequests/api/useGlomoRequests/useGlomoRequests';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { GlomoRequestsTable } from 'modules/glomoRequests/components/GlomoRequestsTable';
import { GlomoRequestType } from 'modules/glomoRequests/api/types/GlomoRequestType';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';
import { GlomoRequestsEmptyState } from 'modules/glomoRequests/components/GlomoRequestsEmptyState';
import { getGlomoRequestFilters } from '../utils/getGlomoRequestFilters';
import { useCurrentUser } from 'config/CurrentUserContext';
import { FiltersData, FiltersDrawer } from '../components/FiltersDrawer/FiltersDrawer';
import { ColumnId } from '../components/GlomoRequestRowCell';

const tooltipTitle =
  'Share this link with your talent so they can submit a workation or business trip request.';

type TableColumn = { id: ColumnId; name: string };

const columns: TableColumn[] = [
  { id: 'Notification', name: '' },
  { id: 'Name', name: 'Name' },
  { id: 'RequestType', name: 'Request type' },
  { id: 'TravelStartDate', name: 'Travel start date' },
  { id: 'Status', name: 'Status' },
  { id: 'RiskLevel', name: 'Risk level' },
  { id: 'RequestDate', name: 'Request date' },
];

export const HRGlomoRequests = (): React.JSX.Element => {
  const toast = useToast();

  useEffect(() => {
    trackPageView('glomo requests: list');
  }, []);

  const drawerStore = useDrawerStore();
  const copyTooltipStore = useTooltipStore({ placement: 'left' });

  const { queryParams, updateQueryParams } = useQueryParams();

  const [riskLevel, setRiskLevel] = useState<GlomoRequestRisk | undefined>(
    queryParams.risk_level as GlomoRequestRisk,
  );

  const [status, setStatus] = useState<GlomoRequestStatus | undefined>(
    queryParams.status as GlomoRequestStatus,
  );

  const [requestType, setRequestType] = useState<GlomoRequestType | undefined>(
    queryParams.type as GlomoRequestType,
  );

  const filters = getGlomoRequestFilters(true);

  filters[0].value = riskLevel;
  filters[1].value = status;
  filters[2].value = requestType;

  const { company_id } = useCurrentUser();

  const { data: company, isPending: isPendingCompany } = useCompany({ companyId: company_id });

  const { data: glomoRequests, isPending: isPendingGlomoRequests } = useGlomoRequests({
    params: {
      risk_level: riskLevel,
      status: status ? [status] : status,
      type: requestType,
      sort_by: 'created_at',
      sort_direction: 'desc',
    },
  });

  const onApplyFilters = (filtersData: FiltersData) => {
    setRiskLevel(filtersData.risk_level || undefined);
    setStatus(filtersData.status || undefined);
    setRequestType(filtersData.type || undefined);

    updateQueryParams(filtersData);

    drawerStore.hide();

    trackEvent('glomo request: filter requests', {
      user_type: 'hr',
      status: filtersData.status,
      type: filtersData.type,
      risk_level: filtersData.risk_level,
    });
  };

  const glomoRequestsURL = `${process.env.REACT_APP_TALENT_APP}/requests/${company?.uid}`;

  if (isPendingCompany) {
    return <ContainedLoadingState />;
  }

  const onCopyToClipboardWithSuccess = () => {
    toast('URL copied!', 'success');

    trackEvent(`glomo requests: copy company url to clipboard`, {
      company_id: company?.id,
      company_name: company?.name,
    });
  };

  return (
    <PageLayout flexed title="Manage Requests">
      <Box.div display="flex" flexDirection="column" gap="d6" marginBottom="m8">
        <Box.div display="flex" justifyContent="space-between">
          <Box.div alignItems="center" display="flex" gap="d3">
            <Button leadingIcon="sliders-vertical" onClick={drawerStore.show} variant="secondary">
              Filter
            </Button>
          </Box.div>
          <Box.div alignItems="center" display="flex" gap="d2">
            <TooltipAnchor render={<Box.div />} store={copyTooltipStore}>
              <Icon
                color="contentTertiary"
                decorative={false}
                display="flex"
                icon="info"
                size="sizeIcon30"
                title={tooltipTitle}
              />
            </TooltipAnchor>

            <CopyToClipboard
              onError={() => toast('Failed to copy URL.', 'error')}
              onSuccess={onCopyToClipboardWithSuccess}
              textToCopy={glomoRequestsURL}
            />
          </Box.div>
        </Box.div>
        {isPendingGlomoRequests ? (
          <ContainedLoadingState />
        ) : (
          <>
            {isEmpty(glomoRequests) ? (
              <GlomoRequestsEmptyState />
            ) : (
              <GlomoRequestsTable
                columns={columns}
                glomoRequests={glomoRequests as GlomoRequest[]}
              />
            )}
          </>
        )}
      </Box.div>
      <FiltersDrawer
        filters={filters}
        initialFilterValues={{
          risk_level: riskLevel,
          status: status,
          type: requestType,
        }}
        onApply={onApplyFilters}
        store={drawerStore}
      />
      <Tooltip store={copyTooltipStore}>{tooltipTitle}</Tooltip>
    </PageLayout>
  );
};
