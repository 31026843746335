import {
  AdditionalServiceContent,
  RawAdditionalServiceContent,
} from 'modules/additionalServices/types/AdditionalServiceContent';

type MappedAdditionalServiceContent = {
  [id: string]: AdditionalServiceContent;
};

/**
 * Maps the additional service content by id to make it easier to find
 * content for a specific service
 *
 * @param content - additional service content
 * @returns content mapped by id
 */
export const mapAdditionalServiceContentById = (
  content: RawAdditionalServiceContent[],
): MappedAdditionalServiceContent => {
  return content.reduce<MappedAdditionalServiceContent>((mappedContent, serviceContent) => {
    mappedContent[serviceContent.databaseId] = {
      name: serviceContent.displayName || serviceContent.title,
      description: serviceContent.cardDescription,
    };

    return mappedContent;
  }, {});
};
