import { CompanyUser } from 'deprecated/api/companies/company_user';
import { MultiUserSelectItem } from 'components/MultiUserSelect/MultiUserSelect';
import isUndefined from 'lodash/isUndefined';
import { sortByLabel } from 'utils/sortByLabel';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';

export const mapUsersToMultiUserSelectItems = (users?: CompanyUser[]): MultiUserSelectItem[] => {
  if (isUndefined(users)) {
    return [];
  }

  return users
    .map((user: CompanyUser) => ({
      label: `${fullName(user)} (${user.email})`,
      value: user.id.toString(),
      avatar: user.avatar_url,
      email: user.email,
      name: fullName(user),
    }))
    .sort(sortByLabel);
};
