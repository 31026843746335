import { Document } from './types/Document';
import { updateAttachment } from './updateAttachment';
import { uploadToS3AndShrineIt } from 'deprecated/api/uploads/uploadToS3AndShrineIt';

export const updateAttachmentAndShrineIt = async (
  id: number,
  file: File,
  onProgress?: (progressPercentage: number) => void,
  onCancel?: () => void,
): Promise<Document | undefined> => {
  try {
    const shrineData = await uploadToS3AndShrineIt(file, onProgress, onCancel);

    if (!shrineData) {
      return undefined;
    }

    return updateAttachment(id, shrineData);
  } catch (err) {
    throw err;
  }
};
