import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import React, { useState } from 'react';

import { Step, UserType } from 'modules/pipelines/types/Step';
import { useSaveStep } from 'modules/pipelines/hooks/useSaveStep';
import {
  Box,
  Button,
  ControlledFormInput,
  ControlledFormTextArea,
  ControlledRadioGroup,
  Label,
  Radio,
  useToast,
} from '@localyze-pluto/components';

type Payload = Pick<Step, 'description' | 'id' | 'title' | 'user_type'>;

const StepBasicInformation = ({ step }: { step: Step }): React.JSX.Element => {
  const { id, title, description, user_type } = step;
  const [isEditMode, setIsEditMode] = useState(false);

  const toast = useToast();

  const { mutate: saveStep } = useSaveStep({
    onSuccess: () => {
      toast('Successfully updated.', 'success');
    },
    onError: () =>
      toast(
        'Something went wrong updating the step, please try again refreshing the page',
        'error',
      ),
  });

  const schema: yup.ObjectSchema<Payload> = yup.object().shape({
    id: yup.number().defined(),
    title: yup.string().required(),
    description: yup.string().required(),
    user_type: yup
      .string()
      .oneOf([UserType.CaseManager, UserType.HrManager, UserType.Talent])
      .required(),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title,
      description,
      user_type,
      id,
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const onSubmit: SubmitHandler<Payload> = (values) => {
    const newStepData: Step = {
      id,
      title: values.title,
      description: values.description,
      user_type: values.user_type,
    };

    saveStep(newStepData);
    setIsEditMode(!isEditMode);
  };
  return (
    <Box.form onSubmit={handleSubmit(onSubmit)}>
      <Box.div display="grid" gap="d5" gridTemplateColumns="1fr 1fr">
        <ControlledFormInput
          control={control}
          data-testid="title"
          disabled={!isEditMode}
          id={`title`}
          label="Name of the step"
          name="title"
          type="text"
        />
        <Box.div display="flex" flexDirection="column">
          <Label htmlFor="user_type">User type</Label>
          <Box.div display="flex" flex="1">
            <ControlledRadioGroup
              control={control}
              disabled={!isEditMode}
              id="user_type"
              name="user_type"
              orientation="horizontal"
            >
              <Radio label="Talent step" value="talent" />
              <Radio label="Case Manager step" value="case_manager" />
              <Radio label="HR step" value="hr_manager" />
            </ControlledRadioGroup>
          </Box.div>
        </Box.div>
        <Box.div gridColumn="1 / span 2" gridRow="2">
          <ControlledFormTextArea
            control={control}
            disabled={!isEditMode}
            id={`description`}
            label="Step description"
            name="description"
          />
        </Box.div>
      </Box.div>
      {isEditMode && (
        <Box.div display="flex" justifyContent="flex-end" marginBottom="m4" marginTop="m2">
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Box.div>
      )}
      {!isEditMode && (
        <Box.div display="flex" justifyContent="flex-end" marginBottom="m4" marginTop="m2">
          <Button onClick={() => setIsEditMode(!isEditMode)} type="button" variant="primary">
            Edit
          </Button>
        </Box.div>
      )}
    </Box.form>
  );
};

export default StepBasicInformation;
