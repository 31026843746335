import { ComponentResponse } from 'deprecated/api/pipelines/types/ComponentResponse';

export enum UserType {
  CaseManager = 'case_manager',
  HrManager = 'hr_manager',
  Talent = 'talent',
}

export type Step = {
  id: number;
  description: string;
  title: string;
  user_type: UserType;
  components?: ComponentResponse[];
};
