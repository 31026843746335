import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import {
  disableTwoFactorAuthentication,
  generateSecretOtp,
  qrCode,
  validateTwoFactorCode,
} from 'deprecated/utils/helper/companyusers/company_user';
import { Box, Button, Heading, Input } from '@localyze-pluto/components';

import './index.scss';

export const TwoFactorAuthentication = () => {
  const [useQrCode, setUseQrCode] = useState();
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);
  const [secretCode, setSecretCode] = useState('');

  useEffect(() => {
    getQrCode();
  }, []);

  const getQrCode = () => {
    qrCode((res) => {
      setUseQrCode(res.data.qrcode);
      setIsTwoFactorEnabled(res.data.enabled);
    });
  };

  const generateOtp = () => generateSecretOtp(() => getQrCode());

  const setTwoFactorAuthenticationStatus = (status) => {
    switch (status) {
      case 'enable':
        validateTwoFactorCode({ secret_code: secretCode }, () => {
          setUseQrCode('');
          setIsTwoFactorEnabled(true);
        });
        break;
      case 'disable':
        disableTwoFactorAuthentication(() => {
          setUseQrCode('');
          setIsTwoFactorEnabled(false);
        });
        break;
      default:
        break;
    }
  };

  const qrCodeImage = () => {
    return (
      <div>
        <div className="center">
          <QRCodeSVG value={useQrCode} />
        </div>
        <div className="center">
          <Box.div className="tw-w-40 tw-mx-auto tw-my-2">
            <Input
              placeholder="Eg. 123456"
              onChange={(e) => setSecretCode(e.target.value)}
              type="number"
            />
          </Box.div>
        </div>
        <div className="center">
          <Button variant="primary" onClick={() => setTwoFactorAuthenticationStatus('enable')}>
            Enable
          </Button>
        </div>
      </div>
    );
  };

  const disableMessage = () => {
    return (
      <Box.div
        backgroundColor="colorBackgroundInfo"
        borderRadius="borderRadius20"
        borderColor="colorBorderPrimary"
        borderWidth="borderWidth10"
        borderStyle="borderStyleSolid"
        padding="p2"
        marginTop="m3"
      >
        <Heading as="h5" color="colorTextInfo">
          {DATA.positive_msg.header + ' enabled!'}
        </Heading>
        <p>
          <Box.button
            backgroundColor="transparent"
            borderColor="transparent"
            display="inline"
            color="colorTextLink"
            padding="0"
            onClick={() => setTwoFactorAuthenticationStatus('disable')}
            type="button"
          >
            {DATA.positive_msg.click_here}
          </Box.button>{' '}
          {DATA.positive_msg.text}
        </p>
      </Box.div>
    );
  };

  const enableInstructions = () => {
    return (
      <div>
        <p>{DATA.disabled.text}</p>
        <ol>
          {!useQrCode && <li>{DATA.disabled.list1}</li>}
          <li>{DATA.disabled.list2}</li>
          <li>{DATA.disabled.list3}</li>
        </ol>
      </div>
    );
  };

  return (
    <>
      <div className="two-factor-configuration container">
        <h3>Enable two factor authentication</h3>
        <div>
          <p>{DATA.title}</p>
        </div>
        {isTwoFactorEnabled && disableMessage()}
        {!isTwoFactorEnabled && enableInstructions()}
        {!isTwoFactorEnabled && !useQrCode && (
          <div>
            <Button variant="primary" onClick={generateOtp}>
              {DATA.generate_secret}
            </Button>
          </div>
        )}
        {!isTwoFactorEnabled && useQrCode && qrCodeImage()}
      </div>
    </>
  );
};

const DATA = {
  title:
    'Two factor authentication (2FA) strengthens access security by requiring two methods to verify your identity. It protects you against pishing, social engineering and password brute force attacks and secures your logins from attackers exploiting weak or stolen credentials.',
  positive_msg: {
    header: 'Two Factor Authentication for your account is currently',
    click_here: 'Click here',
    text: ' to disable Two Factor Authentication (not recommended)',
  },
  disabled: {
    text: 'To enable Two Factor Authentication on your account, you need to do the following steps',
    list1: 'Click on Generate Secret button. To generate a unique secret QR code to your profile',
    list2: 'Scan the QR code using Google Authenticator or Authy apps',
    list3: 'Enter the code generated in the app to enable the 2FA',
  },
  generate_secret: 'Generate secret',
  enable: 'enable',
};
