import axios, { AxiosPromise } from 'axios';
import { StackOneProvider } from 'modules/integrations/types/HRISCompanyIntegration';

export type ExternalIntegration = {
  id: number;
  provider_name: StackOneProvider | 'intercom';
  provider_uid: string;
  provider_public_uid?: Maybe<string>;
  url: string;
};

export const getInternationalIntegration = (
  internationalId: number,
  providerName: string,
): AxiosPromise<ReadonlyArray<ExternalIntegration>> =>
  axios.get(`/admin/internationals/${internationalId}/integrations/${providerName}`);
