import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

export const PENDING_COUNTERS_KEY = 'pending-counters';

export type Counters = {
  pending_requests_count: number;
  pending_tasks_count: number;
};

const getPendingCounters = async () => {
  const { data } = await axios.get<Counters>('/pending_counters');

  return data;
};

export const usePendingCounters = ({
  enabled,
}: Pick<UseQueryOptions<Counters, AxiosError, Counters>, 'enabled'>): UseQueryResult<
  Counters,
  AxiosError
> => {
  return useQuery({ queryKey: [PENDING_COUNTERS_KEY], queryFn: getPendingCounters, enabled });
};
