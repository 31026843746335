import { Box, TextArea } from '@localyze-pluto/components';
import { CharacterCounter } from '../../TemplatesDropdown/CharacterCounter';
import React from 'react';

export const TextAreaWithCounter = ({
  charLimit,
  onChange,
  value,
  placeholder,
  disabled,
  ariaLabel,
}: {
  charLimit: number;
  onChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder: string;
  ariaLabel: string;
  disabled?: boolean;
}): React.JSX.Element => {
  return (
    <Box.div display="flex" flexDirection="column" gap="d1" h="100%">
      <Box.div display="grid" gridTemplateRows="1fr auto" h="100%">
        <TextArea
          aria-label={ariaLabel}
          disabled={disabled}
          maxLength={charLimit}
          onChange={onChange}
          placeholder={placeholder}
          style={{
            height: '100%',
            resize: 'none',
          }}
          value={value}
        />
      </Box.div>
      <Box.div
        color="colorIconWeaker"
        display="flex"
        fontSize="fontSize20"
        justifyContent="flex-end"
      >
        <CharacterCounter limit={charLimit} value={value} />
      </Box.div>
    </Box.div>
  );
};
