import React from 'react';
import { TalentContext } from '../TalentContext';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';

export const useTalent = (): TalentWithPersonalInformation => {
  const talent = React.useContext(TalentContext);

  if (!talent) {
    throw new Error('TalentContext: No talent provided');
  }

  return talent;
};
