import React from 'react';
import { Anchor, Box, Icon } from '@localyze-pluto/components';
import {
  GLOMO_REQUEST_RISK_COLOR,
  GLOMO_REQUEST_RISK_LEVEL,
} from 'modules/glomoRequests/constants';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';

export const DocumentLink = ({
  url,
  name,
  risk,
}: {
  url: string;
  name: string;
  risk: GlomoRequestRisk;
}): React.JSX.Element => {
  return (
    <Box.div
      alignItems="center"
      backgroundColor="bgPrimary"
      borderRadius="borderRadius30"
      display="flex"
      gap="d3"
      padding="p4"
    >
      <Box.div
        alignContent="center"
        backgroundColor="colorBackgroundInfo"
        borderRadius="borderRadius20"
        display="flex"
        justifyContent="center"
        padding="p2"
        w={36}
      >
        <Icon color="colorIconInfo" decorative icon="link" size="sizeIcon30" />
      </Box.div>
      <Box.div
        alignItems="center"
        display="flex"
        flexGrow={1}
        gap="d3"
        justifyContent="space-between"
      >
        <Box.div display="flex" flexDirection="column" gap="d0_5">
          <Box.span fontSize="fontSize20" fontWeight="fontWeightBold">
            View {name}’s risk assessment below:
          </Box.span>
          <Box.div fontSize="fontSize20">
            <Anchor href={url} isExternal style={{ wordBreak: 'break-all' }} target="_blank">
              {url}
            </Anchor>
          </Box.div>
        </Box.div>

        <Box.div
          alignItems="center"
          backgroundColor={GLOMO_REQUEST_RISK_COLOR[risk].background}
          borderRadius="borderRadiusPill"
          display="inline-flex"
          gap="d2"
          h="2.25rem"
          paddingBottom="p2"
          paddingLeft="p4"
          paddingRight="p4"
          paddingTop="d1_5"
        >
          <Box.span
            backgroundColor={GLOMO_REQUEST_RISK_COLOR[risk].dot}
            borderRadius="borderRadiusCircle"
            h={8}
            w={8}
          />
          <Box.span
            color="colorTextStrongest"
            fontSize="fontSize20"
            fontWeight="fontWeightBold"
            style={{ whiteSpace: 'nowrap' }}
          >
            {GLOMO_REQUEST_RISK_LEVEL[risk]}
          </Box.span>
        </Box.div>
      </Box.div>
    </Box.div>
  );
};
