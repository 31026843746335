import { Badge, Heading } from '@localyze-pluto/components';
import { CardWithTabs } from 'components/CardWithTabs/CardWithTabs';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useCase } from 'modules/cases/api/useCase/useCase';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CreateNewMessage } from './CreateNewMessage';
import { Intercom } from 'modules/caseDetails/components/CaseActivityCard/Intercom/Intercom';
import { CASE_ACTIVITY_TABS } from 'modules/caseDetails/utils/constants';
import { humanizeCommunicationPriority } from 'modules/caseDetails/utils/humanizeCommunicationPriority';

export const CASE_ACTIVITY_CARD_HEIGHT = 400;

export const CaseActivityCard = (): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const { id } = useParams<{ id: string }>();
  const caseId = Number(id);
  const { data: talentCase, isSuccess: isSuccessCase } = useCase({
    caseId,
  });
  const [headerHeight, setHeaderHeight] = React.useState<number>(0);

  const headerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, []);

  const { Header, Content } = CardWithTabs;

  if (!isSuccessCase) {
    return <ContainedLoadingState />;
  }

  const contentHeight = `calc(${CASE_ACTIVITY_CARD_HEIGHT}px -  ${headerHeight}px)`;

  return (
    <CardWithTabs
      flexed
      h={CASE_ACTIVITY_CARD_HEIGHT}
      maxH={CASE_ACTIVITY_CARD_HEIGHT}
      tabs={CASE_ACTIVITY_TABS}
    >
      <Header
        action={
          isAdmin ? (
            <>
              <Intercom talentId={talentCase.talent_id} />
            </>
          ) : (
            <CreateNewMessage caseId={caseId} />
          )
        }
        heading={
          <>
            <Heading color="colorTextStrongest" marginBottom="m0" size="title-subsection">
              Case activity
            </Heading>
            {isAdmin && (
              <Badge>{humanizeCommunicationPriority(talentCase.communication_priority)}</Badge>
            )}
          </>
        }
        ref={headerRef}
        tabListAriaLabel="Case activity tabs"
      />
      <Content maxH={contentHeight} padding="d0" />
    </CardWithTabs>
  );
};
