import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';
import React from 'react';
import { DrawerSection } from './DrawerSection';
import { useCases } from 'modules/cases/api/useCases/useCases';
import { Box } from '@localyze-pluto/components';
import { CaseCardRow } from 'modules/talent/pages/talentProfile/components/CasesCard/CaseCardRow/CaseCardRow';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';

export const CasesSection = ({
  talent,
}: {
  talent: TalentWithPersonalInformation;
}): React.JSX.Element => {
  const { data: cases, isSuccess } = useCases({ talentId: talent.id });

  return (
    <DrawerSection title="Cases">
      {!isSuccess ? (
        <ContainedLoadingState />
      ) : (
        <Box.ul mb="d0" p="d0">
          {cases.map((kase, index) => (
            <Box.li display="flex" flexDirection="column" key={index}>
              <CaseCardRow kase={kase} key={kase.id} showCreationDate={false} />
              {index !== cases.length - 1 && <SectionDivider />}
            </Box.li>
          ))}
        </Box.ul>
      )}
    </DrawerSection>
  );
};
