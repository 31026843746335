import React from 'react';
import { Td } from '@localyze-pluto/components';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

type Props = {
  readonly dateString: string;
};

export const DateCell = ({ dateString }: Props): React.JSX.Element => (
  <Td className="tw-text-sm tw-leading-5 tw-font-normal">{formatDate(dateString)}</Td>
);
