export enum lightColors {
  yellow = 'yellow',
  red = 'red',
  green = 'green',
}
export const getTrafficLightColor = (trafficLightColor: lightColors): { bg: string } => {
  switch (trafficLightColor) {
    case 'red':
      return { bg: 'colorIconError' };
    case 'yellow':
      return { bg: 'colorIconWarning' };
    default:
      return { bg: 'colorIconSuccess' };
  }
};
