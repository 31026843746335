import React, { isValidElement } from 'react';
import { Row } from '../types/Row';
import { isString } from 'lodash';
import { Td } from '@localyze-pluto/components';

export const RowItems = ({ row }: { row: Row }): React.JSX.Element => (
  <>
    {row.labels.map((item, itemIndex) => {
      const rowKey = `${row.id}-${itemIndex}`;

      if (!isString(item) && isValidElement(item)) {
        return <Td key={rowKey}>{item}</Td>;
      }

      return (
        <Td key={rowKey}>
          <span data-testid="stacklist-row-item">{item}</span>
        </Td>
      );
    })}
  </>
);
