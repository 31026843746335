import { differenceInDays } from 'date-fns';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';

/**
 * The duration is calculated by finding the difference between the endDate and startDate,
 * then adding 1 to ensure both dates are included in the count.
 */
export const getTripDuration = (glomoRequest: GlomoRequest): number =>
  differenceInDays(new Date(glomoRequest.trip_end_date), new Date(glomoRequest.trip_start_date)) +
  1;
