import {
  Box,
  Button,
  ControlledFormInput,
  ControlledFormSelect,
  ControlledFormTextArea,
  useToast,
} from '@localyze-pluto/components';
import { currencyOptions, initialValues, validationSchema } from '../constants';

import { ControlledFileUploader } from 'components/ControlledFileUploader/ControlledFileUploader';
import { PreCheckFormData } from '../types/PreCheckFormData';
import React from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCountryOptions } from 'modules/countries/api/useCountryOptions/useCountryOptions';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSubmitPreCheck } from '../api/useSubmitPreCheck/useSubmitPreCheck';
import { yupResolver } from '@hookform/resolvers/yup';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export const PreCheckForm = (): React.JSX.Element => {
  const history = useHistory();
  const toast = useToast();
  const countryOptions = useCountryOptions().data || [];
  const routes = useRoutes();

  const { mutate, isPending: isSubmitting } = useSubmitPreCheck({
    onSuccess: (_, variables) => {
      toast(
        // eslint-disable-next-line max-len
        `Your request was submitted successfully.`,
        'success',
      );

      // Track which inputs weren't filled out
      const emptyInputs = Object.entries(variables).reduce<string[]>(
        (accum, [inputName, inputValue]) => {
          if (!inputValue) {
            accum.push(inputName);
          }

          return accum;
        },
        [],
      );
      trackEvent('precheck: submit precheck', { emptyInputs });

      history.push(routes.home.url);
    },
    onError: (error) => {
      rollbarInstance.error(error);
      toast(error.response?.data.message || 'Failed to submit your Pre-check', 'error');
    },
  });

  const { handleSubmit, control } = useForm({
    defaultValues: initialValues,
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: PreCheckFormData) => {
    mutate(data);
  };

  return (
    <Box.form display="grid" onSubmit={handleSubmit(onSubmit)} rowGap="d3">
      <ControlledFormInput
        control={control}
        id="firstName"
        label="First name"
        name="firstName"
        type="text"
      />

      <ControlledFormInput
        control={control}
        id="lastName"
        label="Last name"
        name="lastName"
        type="text"
      />

      <ControlledFormSelect
        control={control}
        id="originCountry"
        items={countryOptions}
        label="Country of residence"
        name="originCountry"
        required
      />

      <ControlledFormSelect
        control={control}
        id="destinationCountry"
        items={countryOptions}
        label="Destination country"
        name="destinationCountry"
        required
      />

      <ControlledFormSelect
        control={control}
        id="nationalityCountry"
        items={countryOptions}
        label="Primary nationality"
        name="nationalityCountry"
        required
      />

      <ControlledFormSelect
        control={control}
        id="secondNationalityCountry"
        items={countryOptions}
        label="Secondary nationality, if applicable"
        name="secondNationalityCountry"
      />

      <ControlledFormInput
        control={control}
        id="destinationCity"
        label="City in destination country"
        name="destinationCity"
        required
        type="text"
      />

      <ControlledFormInput
        control={control}
        helpText="Write N/A if Talent does not have a degree"
        id="university"
        label="University name"
        name="university"
        required
        type="text"
      />

      <ControlledFormInput
        control={control}
        helpText="Write N/A if Talent does not have a degree"
        id="degree"
        label="Full name of university degree"
        name="degree"
        required
        type="text"
      />

      <ControlledFormInput
        control={control}
        id="jobTitle"
        label="Planned job title"
        name="jobTitle"
        required
        type="text"
      />

      <Box.div columnGap="d4" display="grid" gridTemplateColumns="3fr 2fr">
        <ControlledFormInput
          control={control}
          id="annualSalary"
          label="Planned annual salary"
          name="annualSalary"
          required
          type="number"
        />

        <ControlledFormSelect
          control={control}
          id="currency"
          items={currencyOptions}
          label="Currency"
          name="currency"
        />
      </Box.div>

      <ControlledFormTextArea
        control={control}
        id="comment"
        label="Please add more details and any questions you have"
        name="comment"
      />

      <Box.div marginBottom="m4">
        <ControlledFileUploader
          accept=".pdf"
          control={control}
          label="Curriculum Vitae"
          name="cv"
        />
      </Box.div>

      <Box.div marginBottom="m4">
        <ControlledFileUploader
          accept=".pdf"
          control={control}
          label="Current visa or residence permit, if applicable"
          name="visa"
        />
      </Box.div>

      <Box.div display="flex" justifyContent="flex-end">
        <Button loading={isSubmitting} type="submit" variant="primary">
          Request pre-check
        </Button>
      </Box.div>
    </Box.form>
  );
};
