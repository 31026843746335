import axios from 'axios';
import { User } from 'deprecated/api/user/types/User';

export const getCaseManagers = async ({ withCases }: { withCases?: boolean } = {}): Promise<
  User[]
> => {
  const params = { localyze_admin: true, ...(withCases && { with_cases: withCases }) };
  const { data } = await axios.get<User[]>('/users', { params });

  return data;
};
