import React from 'react';
import { Badge, Tr, Box } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';
import { Talent } from 'modules/talent/types/Talent';
import { getTalentStatusBadgeProps } from 'modules/talent/utils/getTalentStatusBadgeProps';
import { AvatarAndNameCell } from '../Cells/AvatarAndNameCell';
import { LinkToTalentCell } from '../Cells/LinkToTalentCell';
import { formatDate } from 'utils/formatters/formatDate/formatDate';
import { TALENT_TABLE_COLUMNS } from '../constants';

export const TalentRow = ({ talent }: { talent: Talent }): React.JSX.Element => {
  const { isAdmin, isHrViewer } = useCurrentUser();
  const { color, status } = getTalentStatusBadgeProps(talent.is_activated);
  const adminColumn = TALENT_TABLE_COLUMNS.find(
    ({ adminOnly }) => adminOnly && adminOnly === isAdmin,
  )?.adminOnly;

  return (
    <Tr hasHover isClickable={!isHrViewer}>
      <AvatarAndNameCell talent={talent} />
      {adminColumn && (
        <LinkToTalentCell talentId={talent.id}>
          <Box.span color="colorTextStrongest" fontSize="fontSize20" lineHeight="lh45">
            {talent.company.name}
          </Box.span>
        </LinkToTalentCell>
      )}
      <LinkToTalentCell talentId={talent.id}>
        <Badge color="gray">{talent.open_cases_count}</Badge>
      </LinkToTalentCell>
      <LinkToTalentCell talentId={talent.id}>
        <Badge color={color}>{status}</Badge>
      </LinkToTalentCell>
      <LinkToTalentCell talentId={talent.id}>
        <Box.span color="colorTextStrongest" fontSize="fontSize20" lineHeight="lh45">
          {formatDate(talent.created_at)}
        </Box.span>
      </LinkToTalentCell>
    </Tr>
  );
};
