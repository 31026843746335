import { useEffect, useState } from 'react';
import { setAxiosAuthHeader } from '../../utils/setAxiosAuthHeader';
import { setUppyToken } from 'deprecated/api/uploads/setup';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserQuery } from 'modules/users/api/useCurrentUserQuery/useCurrentUserQuery';
import { User } from 'deprecated/api/user/types/User';

type UseAuthCheckSuccess = {
  hasUser: true;
  user: User;
  hasUserSessionError: false;
  auth0Token: string;
};
type UseAuthCheckFailure = {
  hasUser: false;
  user: undefined;
  hasUserSessionError: boolean;
  auth0Token: string;
};
type UseAuthCheckReturn = UseAuthCheckFailure | UseAuthCheckSuccess;

/**
 * Checks if user is logged in to Auth0.
 * If logged in, gets token, sets Auth header
 */
export function useAuthCheck(): UseAuthCheckReturn {
  const { getAccessTokenSilently, user: auth0User } = useAuth0();

  const [auth0Token, setAuth0Token] = useState('');
  // Logged in to Auth0, no token
  // Fetch token, update Axios config to set Auth header

  useEffect(() => {
    async function getAuth0Token() {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
      setAxiosAuthHeader(token);
      setUppyToken(token);
    }

    if (auth0User && !auth0Token) {
      getAuth0Token();
    }
  }, [auth0Token, auth0User, getAccessTokenSilently]);

  // Logged in to Auth0, has token, User data not yet fetched
  // Fetch User data
  // This sets isLoggedIn on the UserStore, effectively logging them in on our end
  const { data: user, isError: hasUserSessionError } = useCurrentUserQuery({
    enabled: Boolean(auth0Token),
    throwOnError: false,
  });

  if (user) {
    return {
      user,
      hasUser: true,
      hasUserSessionError: false,
      auth0Token,
    };
  }

  return {
    user,
    hasUser: false,
    hasUserSessionError,
    auth0Token,
  };
}
