import { CaseReport } from 'modules/reporting/api/types/CaseReport';
import { ValueFormatterParams } from 'ag-grid-community';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

/**
 * Formats date from ISO string into dd/MM/yyyy (e.g 17/10/2022)
 * @param param.value - ISO string
 * @returns formatted date
 */
export const formatReportingDateValue = ({
  value,
}: Pick<ValueFormatterParams<CaseReport, string | null>, 'value'>): string =>
  formatDate(value, 'dd/MM/yyyy', '');
