import { RequiredDocument } from './types/RequiredDocument';
import axios from 'axios';

export const getPipelineRequiredDocuments = async (
  pipelineId: number,
): Promise<RequiredDocument[]> => {
  const url = `/pipelines/${pipelineId}/required_documents`;
  const { data } = await axios.get<RequiredDocument[]>(url);

  return data;
};
