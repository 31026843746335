import { Control, useController } from 'react-hook-form';
import { Input, Label } from '@localyze-pluto/components';
import React, { ChangeEventHandler } from 'react';

import { Currency } from 'types/currency';
import { Step4Payload } from 'modules/cases/pages/createCase/types';
import { VisuallyHidden } from '@ariakit/react';
import { parseNumberFromString } from 'utils/formatters/parseNumberFromString/parseNumberFromString';

type AllowanceAmountInputProps = {
  control: Control<Step4Payload>;
  currency?: Currency;
};

export const AllowanceAmountInput = ({
  control,
  currency,
}: AllowanceAmountInputProps): React.JSX.Element => {
  const { field } = useController({ name: 'allowanceAmountDollars', control });

  // TODO: Replace when Pluto supports masked inputs
  const displayValue = field.value
    .toLocaleString(undefined, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    })
    .replace(/^[^\d]+/, (match) => `${match} `);

  const placeholder = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    useGrouping: false,
  })
    .format(2000)
    .replace(/^[^\d]+/, (match) => `${match} e.g. `);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // Removes all non-numbers, limits length to max digits

    const parsed = parseNumberFromString(event.target.value, 5);

    field.onChange(parsed);
  };

  return (
    <>
      <VisuallyHidden>
        <Label htmlFor="allowance-amount">Allowance amount</Label>
      </VisuallyHidden>
      <Input
        id="allowance-amount"
        onBlur={field.onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        ref={field.ref}
        type="text"
        value={field.value ? displayValue : ''}
      />
    </>
  );
};
