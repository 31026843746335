import { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';

const sortByName = (docA: DocumentWithUrl, docB: DocumentWithUrl) =>
  docA.docName.toUpperCase() < docB.docName.toUpperCase() ? -1 : 1;

// Pushes the items with no url to the end and sorts the list alphabetically ascending by name
export const sortDocuments = (list: DocumentWithUrl[]): DocumentWithUrl[] => {
  return list.sort((docA, docB) => {
    if (!docA.docUrl && docB.docUrl) {
      return 1;
    }

    if (docA.docUrl && !docB.docUrl) {
      return -1;
    }

    return sortByName(docA, docB);
  });
};
