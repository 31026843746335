import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import { Step } from 'modules/pipelines/types/Step';
import { PipelineStep } from 'modules/pipelines/types/PipelineStep';

const createStep = async ({ title, description, user_type }: Omit<Step, 'id'>): Promise<Step> => {
  const { data } = await axios.post<Step>(`/admin/steps`, {
    title,
    description,
    user_type,
  });
  return data;
};

const addStepToPipeline = async ({
  pipelineId,
  stepId,
}: {
  pipelineId: number;
  stepId: number;
}): Promise<PipelineStep> => {
  const { data } = await axios.post<PipelineStep>(`/admin/pipeline_steps`, {
    pipeline_id: pipelineId,
    step_id: stepId,
  });
  return data;
};

export const useCreateStep = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: (pipelineStep: PipelineStep) => void;
} = {}): UseMutationResult<
  PipelineStep,
  unknown,
  { pipelineId: number; step: Omit<Step, 'id'> }
> => {
  return useMutation({
    mutationFn: async ({ pipelineId, step }: { pipelineId: number; step: Omit<Step, 'id'> }) => {
      const { id: stepId } = await createStep(step);
      return addStepToPipeline({ stepId, pipelineId });
    },
    onSuccess: (pipelineStep) => onSuccess && onSuccess(pipelineStep),
    onError,
  });
};
