import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { PaginationInformation } from 'deprecated/api/types/PaginationInformation';
import { Talent } from 'modules/talent/types/Talent';

type TalentsResponse = {
  talents: Talent[];
  meta: PaginationInformation;
};

export type TalentsQueryFilters = {
  hr_manager_id?: number;
  case_manager_id?: number;
  company_id?: number;
  sign_up_status?: string;
};

export type GetTalentsParams = TalentsQueryFilters & {
  page?: string;
  limit?: number;
};

const getTalents = async (params: GetTalentsParams): Promise<TalentsResponse> => {
  const response = await axios.get<TalentsResponse>('/talents', {
    params,
    headers: { Accept: 'application/json' },
  });

  return response.data;
};

export const useTalents = (
  params: GetTalentsParams = {},
  options?: UseQueryOptions<TalentsResponse>,
): UseQueryResult<TalentsResponse> => {
  return useQuery({
    queryKey: ['talents', params],
    queryFn: () => getTalents(params),
    ...options,
  });
};
