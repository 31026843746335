import { Badge, Box } from '@localyze-pluto/components';
import React from 'react';
import { CaseOverviewCell } from '../CaseOverviewCell';
import { formatDateDashesToFriendly } from 'utils/formatters/formatDateDashesToFriendly/formatDateDashesToFriendly';
import { CaseOverview } from 'modules/talent/api/useCaseOverviews/CaseOverview';

type Props = {
  caseOverview: CaseOverview;
  date?: string;
  isAdmin?: boolean;
};

export const DateCell = ({ caseOverview, date, isAdmin }: Props): React.JSX.Element => (
  <CaseOverviewCell caseOverview={caseOverview} isAdmin={isAdmin}>
    <Box.div display="flex" whiteSpace="nowrap">
      {date ? <Badge>{formatDateDashesToFriendly(date)}</Badge> : '-'}
    </Box.div>
  </CaseOverviewCell>
);
