import { Combobox } from '@headlessui/react';
import React from 'react';
import { TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';
import { Avatar, Box } from '@localyze-pluto/components';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import { useCurrentUser } from 'config/CurrentUserContext';

export type TalentSearchResultWrapperProps = {
  talent: TalentSearchResult;
  children: NonNullable<React.ReactNode>;
};

export type TalentSearchResultWrapper = (props: TalentSearchResultWrapperProps) => JSX.Element;

type Props = {
  readonly talent: TalentSearchResult;
  wrapper: TalentSearchResultWrapper;
};

export const TalentSearchResultOption = ({
  talent,
  talent: { full_name_sorted, avatar_url, company_name, email, first_name, last_name },
  wrapper: Wrapper,
}: Props): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();

  return (
    <Wrapper talent={talent}>
      <Box.div
        as={Combobox.Option}
        backgroundColor={{ hover: 'gray100' }}
        color="gray900"
        cursor="pointer"
        position="relative"
        px="d4"
        py="d2"
        userSelect="none"
        value={full_name_sorted}
        w="100%"
      >
        <Box.div display="flex" gap="d4">
          <Avatar
            name={fullName({ first_name, last_name })}
            size="large"
            src={avatar_url || undefined}
          />

          <Box.div
            display="flex"
            flexDirection="column"
            flexGrow="1"
            overflow="hidden"
            textAlign="left"
          >
            <Box.span
              color="colorTextStrongest"
              fontSize="fontSize20"
              fontWeight="fontWeightRegular"
              lineHeight="lh5"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {full_name_sorted}
            </Box.span>
            <Box.span
              color="gray600"
              fontSize="fontSize10"
              fontWeight="fontWeightRegular"
              lineHeight="lh4"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {email}
            </Box.span>
            {isAdmin && (
              <Box.span
                color="gray600"
                fontSize="fontSize10"
                fontWeight="fontWeightRegular"
                lineHeight="lh4"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {company_name}
              </Box.span>
            )}
          </Box.div>
        </Box.div>
      </Box.div>
    </Wrapper>
  );
};
