import React from 'react';
import { Field as FormikField, FieldProps } from 'formik';
import { Checkbox, Label } from '@localyze-pluto/components';

type FormCheckboxProps = {
  name: string;
  id: string;
  disabled: boolean;
  label: string;
  value: boolean;
};

export const FormCheckbox = ({
  name,
  id,
  disabled,
  label,
  value,
}: FormCheckboxProps): React.JSX.Element => (
  <FormikField name={name} type="checkbox">
    {({ form }: FieldProps) => (
      <Checkbox
        checked={value}
        disabled={disabled}
        id={id}
        name={name}
        onCheckedChange={() => {
          form.setFieldValue(name, !value);
        }}
      >
        <Label disabled={disabled} htmlFor={name}>
          {label}
        </Label>
      </Checkbox>
    )}
  </FormikField>
);
