import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CasePipeline } from 'modules/pipelines/types/CasePipeline';
import axios, { AxiosError } from 'axios';

const CASE_PIPELINES_QUERY_KEY = 'case-pipelines';

export const getCasePipelinesQueryKey = (caseId: number): Array<number | string> => [
  CASE_PIPELINES_QUERY_KEY,
  caseId,
];

const getCasePipelines = async (caseId: number): Promise<CasePipeline[]> => {
  const { data } = await axios.get<CasePipeline[]>('/case/pipelines', {
    params: { case_id: caseId },
  });

  return data;
};

export const useCasePipelines = (caseId: number): UseQueryResult<CasePipeline[], AxiosError> =>
  useQuery<CasePipeline[], AxiosError>({
    queryKey: getCasePipelinesQueryKey(caseId),
    queryFn: () => getCasePipelines(caseId),
  });
