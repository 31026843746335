import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { Case } from '../../types/Case';

const getCases = async (talentId: number): Promise<Case[]> => {
  const url = '/cases';
  const { data } = await axios.get<Case[]>(url, {
    params: { talent_id: talentId },
    headers: { Accept: 'application/json' },
  });

  return data;
};

type UseCasesProps = {
  talentId: number;
};

export const getCasesQueryKey = (talentId: number): Array<number | string> => ['cases', talentId];

export const useCases = ({ talentId }: UseCasesProps): UseQueryResult<Case[]> => {
  return useQuery({
    queryKey: getCasesQueryKey(talentId),
    queryFn: () => getCases(talentId),
  });
};
