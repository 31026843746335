import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

export const parseErrorMessages = (error: AxiosErrorWithMessage): string[] => {
  const errors = Object.values(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    error?.response?.data?.errors || {},
  ).flat() as unknown as string[];

  if (errors.length) {
    return errors;
  }

  return ['Try again or contact support.'];
};
