import { useToast } from '@localyze-pluto/components';
import { AxiosError } from 'axios';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { DeleteTaskProps, useDeleteTask } from 'modules/tasks/api/useDeleteTask/useDeleteTask';
import { useTasks } from 'modules/tasks/api/useTasks/useTasks';
import { UpdateTaskProps, useUpdateTask } from 'modules/tasks/api/useUpdateTask/useUpdateTask';
import { Task } from 'modules/tasks/types/Task';
import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { useEffect } from 'react';

type UseManageTasksResult = {
  tasks: Task[];
  updateTask: UseMutateFunction<Task, AxiosErrorWithMessage, UpdateTaskProps, unknown>;
  deleteTask: UseMutateFunction<unknown, AxiosError, DeleteTaskProps>;
  isPendingTasks: boolean;
};

type UseManageTasksProps = {
  caseId: number;
};

export const useManageTasks = ({ caseId }: UseManageTasksProps): UseManageTasksResult => {
  const toast = useToast();

  const {
    data,
    isPending: isPendingTasks,
    isError,
  } = useTasks({
    case_id: caseId,
    all_items: true,
  });

  const tasks = data?.tasks || [];

  const { mutate: updateTask } = useUpdateTask({
    onSuccess: (task: Task) => {
      toast('Task completed! We will notify your case manager', 'success');

      trackEvent('task: complete a task', {
        taskId: task.id,
        caseId,
        createdAt: task.created_at,
        completedAt: task.completed_at,
      });
    },
    onError: () => {
      toast('Error updating task.', 'error');
    },
  });

  const { mutate: deleteTask } = useDeleteTask({
    onSuccess: () => {
      toast('Deleted task successfully.', 'success');
    },
    onError: (error: AxiosError) => {
      toast(error.message || 'Error deleting task.', 'error');
    },
  });

  useEffect(() => {
    if (isError) {
      toast('Error fetching tasks.', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    tasks,
    updateTask,
    deleteTask,
    isPendingTasks,
  };
};
