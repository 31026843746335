import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { postUserUpdate } from 'deprecated/api/companies/company';

type SetMemberRole = {
  companyId: number;
  userId: number;
  roleName: string;
};

const submitSetUserRole = ({ companyId, userId, roleName }: SetMemberRole) => {
  const dataToSend = { user: { company_user_attributes: { role_name: roleName } } };

  return postUserUpdate(dataToSend, companyId, userId);
};

export const useSetMemberRole = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosErrorWithMessage, SetMemberRole>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosErrorWithMessage, SetMemberRole> =>
  useMutation({
    mutationFn: ({ companyId, userId, roleName }) =>
      submitSetUserRole({ companyId, userId, roleName }),
    onSuccess,
    onError,
  });
