import { Box, BoxProps, Td } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import React from 'react';
import { Link } from 'react-router-dom';

export const LinkToTalentCell = ({
  children,
  talentId,
  w = '190px',
}: {
  children: React.ReactNode;
  talentId: number;
  w?: BoxProps['w'];
}): React.JSX.Element => {
  const { isHrViewer } = useCurrentUser();
  const routes = useRoutes();

  const ConditionalLink = isHrViewer ? Box.div : Link;

  const talentPath = isHrViewer ? '' : routes.talentProfile.get(talentId);

  const ariaLabel = `navigate to talent profile id ${talentId}`;

  return (
    <Td fontSize="fontSize20" px="p3" py="p4">
      <Box.div
        aria-label={ariaLabel}
        as={ConditionalLink}
        color="colorTextStrongest"
        display="block"
        fontSize="fontSize20"
        key={talentId}
        textDecoration="none"
        to={talentPath}
        w={w}
      >
        {children}
      </Box.div>
    </Td>
  );
};
