export const CHART_COLORS = [
  '#C1CAFE',
  '#7083FF',
  '#FFB540',
  '#A890F0',
  '#A0EBE7',
  '#FF7461',
  '#FDE68A',
  '#6EE7B7',
  '#55C2BD',
  '#99C157',
  '#E9E010',
  '#73C1ED',
  '#EE90F0',
  '#F090A1',
  '#D7F090',
  '#D1B99D',
  '#9CB4BF',
  '#D3C892',
  '#CFB8D0',
  '#B3DFD5',
  '#EBEBD3',
  '#A4AF69',
  '#03F7EB',
  '#FFE0B5',
  '#F56476',
  '#99B2DD',
  '#FF521B',
  '#BCB6FF',
  '#F5F749',
  '#ff70a6',
  '#70d6ff',
  '#00ffcc',
  '#abff4f',
  '#963FFF',
  '#c8553d',
  '#ff7b00',
];
