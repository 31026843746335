import React from 'react';
import {
  Box,
  Button,
  Search,
  Select,
  Tooltip,
  TooltipAnchor,
  useToast,
  useTooltipStore,
} from '@localyze-pluto/components';
import { UseSearchResult } from 'modules/filters/hooks/useSearch';
import { useBillingPeriods } from 'modules/billing/hooks/useBillingPeriods/useBillingPeriods';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';
import { useProcessBillingPeriod } from '../hooks/useProcessBillingPeriod/useProcessBillingPeriod';
import { isSubmitToStripeDisabled } from '../utils/isSubmitToStripeDisabled';

type BillingHeaderProps = Pick<UseSearchResult, 'onSearch' | 'search'> & {
  setBillingPeriodId: (id: string) => void;
  billingPeriodId: string;
};

export const BillingHeader = ({
  onSearch,
  search,
  setBillingPeriodId,
  billingPeriodId,
}: BillingHeaderProps): React.JSX.Element => {
  const { isSuccess, data, refetch } = useBillingPeriods();
  const toast = useToast();
  const needsReviewToolTip = useTooltipStore();

  const { mutate: processBillingPeriod } = useProcessBillingPeriod({
    onSuccess: () => {
      toast('The entries are getting submitted to Stripe.', 'success');
      refetch();
    },
    onError: (error) => {
      toast(
        `The entries could not be submitted to Stripe: ${error.response?.data.message}`,
        'error',
      );
    },
  });

  const billingPeriodOptions = isSuccess
    ? mapToSelectOptions({
        records: data,
        labelProp: 'name',
        valueProp: 'id',
        allLabel: 'All billing periods',
      })
    : [];

  const selectedBillingPeriod = data?.find(
    (billingPeriod) => billingPeriod.id === Number(billingPeriodId),
  );

  const billingPeriodHasNeedsReviewEntries =
    (selectedBillingPeriod?.entries_needs_review_count || 0) > 0;

  return (
    <Box.div alignItems="center" display="flex" justifyContent="space-between" marginBottom="m4">
      <Box.div display="flex" gap="d2">
        <Box.div className="pluto-search" minWidth="200px">
          <Search onChange={onSearch} placeholder="Search company" value={search} />
        </Box.div>
        <Select
          disabled={!isSuccess}
          items={billingPeriodOptions}
          placeholder={isSuccess ? 'Billing periods' : 'Loading billing periods...'}
          setValue={(value) => setBillingPeriodId(typeof value === 'object' ? value[0] : value)}
          value={billingPeriodId}
        />
      </Box.div>

      <Box.div display="flex" gap="d2">
        <Button
          as="a"
          href={
            `https://www.notion.so/localyze/` +
            `Billing-process-documentation-42b9e443db9b4435a1a3e459792ff0ea`
          }
          leadingIcon="circle-help"
          target="_blank"
          variant="ghost"
        >
          Need help?
        </Button>
        <TooltipAnchor store={needsReviewToolTip}>
          <Button
            disabled={isSubmitToStripeDisabled(selectedBillingPeriod)}
            leadingIcon="circle-check"
            onClick={() => billingPeriodId && processBillingPeriod(Number(billingPeriodId))}
            variant="primary"
          >
            Submit to Stripe
          </Button>
        </TooltipAnchor>
        {billingPeriodHasNeedsReviewEntries && (
          <Tooltip store={needsReviewToolTip}>
            Please review all items before submitting to Stripe.
          </Tooltip>
        )}
      </Box.div>
    </Box.div>
  );
};
