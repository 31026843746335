import { Bar } from 'react-chartjs-2';
import React, { createRef, useEffect, useRef, useState } from 'react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Box, Button, Heading, useModalStore } from '@localyze-pluto/components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ReportingTableCardFilter } from 'modules/reporting/pages/reporting/components/ReportingTable/ReportingTableCardFilter/ReportingTableCardFilter';
import { BarChart } from 'modules/reporting/pages/reporting/components/ReportingGraphs/BarChart';
import {
  getChartConfig,
  getGroupedChartOptions,
} from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getChartConfig';
import { useReport } from 'modules/reporting/api/useReport';
import { CaseMetrics } from 'modules/reporting/api/types/CaseMetrics';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { rollbarInstance } from 'config/rollbar/rollbarConfig';
import { getGroupedChartConfig } from './utils/getGroupedChartConfig';
import { countWithPercentageFormatter } from './utils/countWithPercentageFormatter';
import { Report } from 'modules/reporting/api/types/Report';
import { TOOLTIP_TEXTS } from 'modules/reporting/constants/tooltipTexts';
import { CaseStateFilter } from 'modules/cases/types/CaseStateFilter';
import { usePDF } from '@react-pdf/renderer';
// @ts-expect-error file-saver does not have type declarations
import { saveAs } from 'file-saver';
import { ReportinGraphsExportedPdf } from 'modules/reporting/pages/reporting/components/ReportingGraphs/ReportingGraphsExportedPdf';
import { uploadToS3AndShrineIt } from 'deprecated/api/uploads/uploadToS3AndShrineIt';
import { shareReport } from 'modules/reporting/api/shareReport';
import { ReportingTableShareModal } from '../ReportingTable/ReportingTableShareModal/ReportingTableShareModal';
import { getCasesByState } from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getCasesByState';
import { PieChart } from 'modules/reporting/components/PieChart';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';
import { FromToDatepicker } from 'components/Datepicker/FromToDatepicker/FromToDatepicker';
import { HeaderSkeleton, MainSkeleton } from '../ReportingTable/ReportingTableSkeletonLoader';
import { debounce } from 'lodash';
import { EmptyState } from 'components/EmptyState/EmptyState';
import LocalyzeIcon from 'assets/img/localyze-logo-globe.png';
import { areMetricsEmpty } from './utils/areMetricsEmpty';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

type ReportingGraphsProps = {
  companyId: number;
  startDate?: string;
  endDate?: string;
  onChangeStartDate?: (date: string) => void;
  onChangeEndDate?: (date: string) => void;
};

const getBarCharts = (report: Report<CaseMetrics>) => {
  return [
    { heading: 'Cases by case type', entries: report.results.casesByCaseType },
    { heading: 'Cases by origin country', entries: report.results.casesByOriginCountry },
    {
      heading: 'Cases by destination country',
      entries: report.results.casesByDestinationCountry,
    },
    { heading: 'Cases by nationality', entries: report.results.casesByNationality },
  ];
};

export const ReportingGraphs = ({
  companyId,
  startDate,
  endDate,
  onChangeEndDate,
  onChangeStartDate,
}: ReportingGraphsProps): React.JSX.Element => {
  const currentUser = useCurrentUser();

  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [isUploadingPdf, setIsUploadingPdf] = useState(false);
  const shareReportModal = useModalStore();
  const shareReportTextareaRef = createRef<HTMLTextAreaElement>();
  const {
    data: report,
    isPending,
    isSuccess,
    error,
  } = useReport<CaseMetrics>('case_metrics', {
    company_id: companyId,
    end_date: endDate || undefined,
    start_date: startDate || undefined,
  });

  const graphsContainer = useRef<HTMLDivElement | null>(null);
  const [pdf, setPdf] = usePDF({ document: <></> });

  const casesByState = isSuccess ? getCasesByState(report) : [];

  const barCharts = isSuccess ? getBarCharts(report) : [];

  useEffect(() => {
    if (isExportingPdf && pdf.blob) {
      const from = startDate ? ` from ${formatDate(startDate)}` : '';
      const to = endDate ? ` to ${formatDate(endDate)}` : '';

      saveAs(pdf.blob, `Report ${from}${to}.pdf`);
      setIsExportingPdf(false);
    }
  }, [isExportingPdf, pdf, startDate, endDate]);

  if (error) {
    rollbarInstance.error(`Error on Reporting graphs ${error.message}`);
    return <ErrorStatePage />;
  }

  const getCanvasImages = () => {
    const canvasElements = graphsContainer.current?.querySelectorAll('canvas') || [];
    const imagesToDataURL: string[] = [];

    canvasElements.forEach((canvasGraph) => {
      imagesToDataURL.push(canvasGraph.toDataURL('image/png'));
    });

    return imagesToDataURL;
  };

  const updatePdf = (imagesToDataURL: string[]): Promise<void> => {
    if (!report) {
      return Promise.resolve();
    }

    return new Promise((accept) => {
      setPdf(
        <ReportinGraphsExportedPdf
          activeCases={report.results.activeCases}
          casesAtRisk={report.results.casesAtRisk}
          closedCases={report.results.completedCases}
          endDate={endDate}
          images={imagesToDataURL}
          onRender={accept}
          startDate={startDate}
          totalCases={report.results.totalCases}
        />,
      );
    });
  };

  const onSendReportClick = async (emails: string) => {
    setIsUploadingPdf(true);

    const file = new File([pdf.blob as Blob], 'report.pdf', {
      type: 'application/pdf',
    });

    const meta = await uploadToS3AndShrineIt(file);

    trackEvent('reporting: report shared', {
      company_id: currentUser.company_id,
      user_role: currentUser.user_role,
      reporting_type: 'graph',
      start_date: startDate,
      end_date: endDate,
    });

    setIsUploadingPdf(false);
    return shareReport({ emails, pdf: meta, start_date: startDate, end_date: endDate });
  };

  const onExportClick = async () => {
    setIsExportingPdf(true);
    const imagesToDataURL = getCanvasImages();
    await updatePdf(imagesToDataURL);

    trackEvent('reporting: export report', {
      company_id: currentUser.company_id,
      user_role: currentUser.user_role,
      reporting_type: 'graph',
      start_date: startDate,
      end_date: endDate,
    });
  };

  const isEmpty = areMetricsEmpty(report);

  return (
    <>
      <Box.div
        display="grid"
        gap="d7"
        gridTemplateColumns={isPending ? 1 : 4}
        marginBottom="m8"
        minH={118}
      >
        {isPending && <HeaderSkeleton />}
        {report?.results && (
          <>
            <ReportingTableCardFilter
              count={report.results.totalCases}
              disabled
              icon="circle-user"
              title="Total Cases"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.All]}
            />
            <ReportingTableCardFilter
              count={report.results.activeCases}
              disabled
              icon="circle-ellipsis"
              title="Active cases"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.Active]}
            />
            <ReportingTableCardFilter
              count={report.results.completedCases}
              disabled
              icon="circle-check"
              title="Closed Cases"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.Closed]}
            />
            <ReportingTableCardFilter
              count={report.results.casesAtRisk}
              disabled
              icon="circle-alert"
              title="Start date at risk"
              tooltipText={TOOLTIP_TEXTS[CaseStateFilter.StartDateAtRisk]}
              variant="danger"
            />
          </>
        )}
      </Box.div>
      <Box.div display="grid" gap="d4" gridTemplateColumns="1fr 1fr">
        <Box.div maxW={420}>
          <FromToDatepicker
            fromProps={{
              'aria-label': 'From:',
              defaultValue: startDate,
              onChange: debounce((ev) => onChangeStartDate?.(ev.target.value), 500),
              role: 'textbox',
            }}
            toProps={{
              'aria-label': 'To:',
              defaultValue: endDate,
              onChange: debounce((ev) => onChangeEndDate?.(ev.target.value), 500),
              role: 'textbox',
            }}
          />
        </Box.div>
        <Box.div display="grid" gap="d4" gridTemplateColumns="auto auto" justifySelf="end">
          <Button
            disabled={isEmpty}
            onClick={() => {
              updatePdf(getCanvasImages());
              shareReportModal.show();

              trackEvent('reporting: share report', {
                company_id: currentUser.company_id,
                user_role: currentUser.user_role,
                reporting_type: 'graph',
              });
            }}
            variant="secondary"
          >
            Share
          </Button>
          <Button
            disabled={isEmpty}
            onClick={onExportClick}
            trailingIcon="arrow-down-to-line"
            variant="primary"
          >
            Export Report
          </Button>
        </Box.div>
      </Box.div>
      {isPending && (
        <Box.div paddingTop="p8">
          <MainSkeleton />
        </Box.div>
      )}
      <Box.div
        borderRadius="borderRadius40"
        display="grid"
        gap="d4"
        gridTemplateColumns={isEmpty ? 1 : 2}
        justifyContent="center"
        minH={600}
        paddingBottom="p8"
        paddingTop="p8"
        ref={graphsContainer}
      >
        {isSuccess && isEmpty && (
          <EmptyState
            illustration={<Box.img src={LocalyzeIcon} width="50px" />}
            text="There are no cases that match your chosen filters. Try changing them."
          />
        )}
        {isSuccess && !isEmpty && (
          <>
            <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius20" padding="p4">
              <Heading as="h2" size="heading60">
                Cases by state
              </Heading>
              <PieChart
                aspectRatio={2}
                data={getChartConfig(casesByState, false)}
                formatter={countWithPercentageFormatter}
                items={casesByState}
              />
            </Box.div>
            {barCharts.map((chart) => (
              <Box.div
                backgroundColor="bgPrimary"
                borderRadius="borderRadius20"
                key={chart.heading}
                padding="p4"
              >
                <BarChart entries={chart.entries} heading={chart.heading} key={chart.heading} />
              </Box.div>
            ))}
            <Box.div backgroundColor="bgPrimary" borderRadius="borderRadius20" padding="p4">
              <Heading as="h2" size="heading60">
                Cases by case type and destination country
              </Heading>
              <Bar
                data={getGroupedChartConfig(report.results.casesByCaseTypeAndDestinationCountry)}
                options={getGroupedChartOptions()}
              />
            </Box.div>
          </>
        )}
      </Box.div>

      <ReportingTableShareModal
        focusRef={shareReportTextareaRef}
        isLoading={isUploadingPdf}
        onSubmit={async (emails) => onSendReportClick(emails)}
        state={shareReportModal}
      />
    </>
  );
};
