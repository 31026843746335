import { Paragraph } from '@localyze-pluto/components';
import { upperFirst } from 'lodash';
import { StackOneCompanyIntegration } from 'modules/integrations/types/HRISCompanyIntegration';
import { TalentWithPersonalInformation } from 'modules/talent/types/TalentWithPersonalInformation';
import React from 'react';

export const Instructions = ({
  talent,
  activeIntegration,
}: {
  talent: TalentWithPersonalInformation;
  activeIntegration: StackOneCompanyIntegration;
}): React.JSX.Element => (
  <Paragraph color="gray900" fontSize="fontSize30" lineHeight="lh6" marginBottom="d0">
    Please enter {talent.first_name} {talent.last_name}&apos;s employee ID and verify it belongs to
    the right employee. This ID is necessary for automatic file uploads to{' '}
    {upperFirst(activeIntegration.provider_data.stackone_provider_name)}.
  </Paragraph>
);
