import { DialogStore } from '@ariakit/react';
import {
  Box,
  Button,
  ControlledFormInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  useToast,
} from '@localyze-pluto/components';
import { useUpdateCase } from 'modules/cases/api/useUpdateCase/useUpdateCase';
import { Case } from 'modules/cases/types/Case';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type EditCostCenterFormTypes = {
  costCenter: string;
};

export const EditCostCenterModal = ({
  modal,
  kase,
}: {
  modal: DialogStore;
  kase: Case;
}): React.JSX.Element => {
  const { cost_center: costCenter } = kase;
  const toast = useToast();
  const { mutate: updateCase, isPending: isPendingUpdateCase } = useUpdateCase({
    onSuccess: () => {
      toast('Cost center updated successfully', 'success');
      modal.hide();
    },
    onError: () => toast('Cost center could not be updated', 'error'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: { costCenter: costCenter || '' },
  });

  const onSubmit: SubmitHandler<EditCostCenterFormTypes> = (values) =>
    updateCase({
      caseId: kase.id,
      payload: { cost_center: values.costCenter ? values.costCenter : null },
    });

  return (
    <Modal store={modal}>
      <ModalHeader>
        <ModalHeading>Edit Cost Center</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box.form id="edit-cost-center-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            id="costCenter"
            label="Cost Center"
            name="costCenter"
            type="text"
          />
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={modal.hide} type="button" variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={isPendingUpdateCase}
          form="edit-cost-center-form"
          loading={isPendingUpdateCase}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
