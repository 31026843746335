import { axios } from 'config/axios/axios';

export const generateSecretOtp = (callbackSuccess) => {
  axios(`/admin/users/generate_otp_secret_key`, {
    method: 'get',
  })
    .then((response) => callbackSuccess(response))
    .catch((error) => console.error(error));
};

export const qrCode = (callbackSuccess) => {
  axios(`/admin/users/qr_code`, {
    method: 'get',
  })
    .then((response) => callbackSuccess(response))
    .catch((error) => console.error(error));
};

export const validateTwoFactorCode = (params, callbackSuccess) => {
  axios('/admin/users/validate_two_factor_code', {
    method: 'post',
    data: params,
  })
    .then((response) => callbackSuccess(response))
    .catch((error) => console.error(error));
};

export const disableTwoFactorAuthentication = (callbackSuccess) => {
  axios('/admin/users/disable_two_factor', {
    method: 'delete',
  })
    .then((response) => callbackSuccess(response))
    .catch((error) => console.error(error));
};
