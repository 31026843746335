import { SelectMenu, SelectMenuOption } from 'deprecated/components/Chewpaca/SelectMenu';

import { Filter } from './types/Filter';
import { FilterOption } from './types/FilterOption';
import React from 'react';

export type Props = {
  filter: Filter<string>;
  onChange: (selectedOption: FilterOption<string>) => void;
  selectedOption?: FilterOption<string>;
  placeholder?: string;
  className?: string;
  prefix?: string;
};

export const FilterSelector = ({
  className,
  filter,
  onChange,
  selectedOption,
  placeholder,
  prefix = '',
}: Props): React.JSX.Element => {
  const getDisplayValue = (option?: SelectMenuOption) => {
    let selectedLabel;

    if (option) {
      selectedLabel = option.label;
    } else if (filter.default) {
      selectedLabel = filter.options.find((option) => option.value === filter.default)?.label;
    }

    if (selectedLabel) return `${prefix} ${selectedLabel}`.trim();

    return placeholder || '';
  };

  const onSelect = (newOption: SelectMenuOption) => {
    const filterOption = filter.options.find((option) => option.value === newOption.value);

    if (filterOption) {
      onChange(filterOption);
    }
  };

  return (
    <SelectMenu
      className={className}
      getDisplayValue={getDisplayValue}
      onSelect={onSelect}
      options={filter.options}
      placeholder={placeholder}
      selected={selectedOption}
    />
  );
};
