import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { Uppy } from '@uppy/core';

const MAX_FILE_SIZE = 50 * 1024 * 1024;

export const uppy = new Uppy({
  id: 'S3Uploads',
  autoProceed: false,
  restrictions: {
    maxFileSize: MAX_FILE_SIZE,
    maxNumberOfFiles: 5,
  },
});

export const setUppyToken = (token: string): Uppy => {
  // avoids calling uppy.use twice
  if (uppy.getPlugin('AwsS3MultipartPlugin')) {
    return uppy;
  }

  return uppy.use(AwsS3Multipart, {
    id: 'AwsS3MultipartPlugin',
    companionHeaders: {
      'uppy-auth-token': `Bearer ${token}`,
    },
    companionUrl: '/', // Will call the presign endpoint on `/s3/multipart`
  });
};
