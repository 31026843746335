import { Form, Formik } from 'formik';
import React, { useCallback, useContext } from 'react';

import { ADD_DOCUMENT } from 'deprecated/store/actions/DocumentsContainerActions';
import { DocsContainerStore } from 'deprecated/store/root';
import FormFields from 'deprecated/components/FormFields';
import PropTypes from 'prop-types';
import { cancelAllUploadsToS3 } from 'deprecated/api/uploads/uploadToS3';
import styled from 'styled-components';
import { uploadAttachment } from 'modules/documents/api/attachments/uploadAttachment';
import { uploadCaseDocument } from 'deprecated/api/talents/uploadCaseDocument';
import { Box, Icon } from '@localyze-pluto/components';
import { P2 } from 'deprecated/components/Fonts';

export default function UploadBar({ doc, caseId }) {
  const { docName, docCode, id, type } = doc;
  const initialValues = {
    file: {},
    docCode,
  };

  const DocsUploadContext = useContext(DocsContainerStore);
  const { dispatch } = DocsUploadContext;

  const onUploadDocument = useCallback(
    (value, _, onProgress) => {
      if (type === 'Attachment') {
        return uploadAttachment(docName, value.value, caseId, onProgress);
      } else {
        return uploadCaseDocument(value.name, value.value, caseId, onProgress);
      }
    },
    [caseId, docName, type],
  );

  const onUploadSuccess = useCallback(
    (url) => {
      dispatch({
        type: ADD_DOCUMENT,
        payload: {
          docCode,
          docUrl: url,
          id,
          type,
        },
      });
    },
    [dispatch, docCode, id, type],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      <Form>
        <FormFields
          fieldType="file"
          name={docCode || docName}
          docName={docName}
          onUpload={onUploadDocument}
          onCancel={cancelAllUploadsToS3}
          onUploadSuccess={onUploadSuccess}
          component={
            <UploadbarWrapper>
              <Box.div display="flex" alignItems="center" data-testid="upload-bar" gap="d2">
                <Icon decorative icon="cloud-upload" />
                <P2>{docName}</P2>
              </Box.div>
            </UploadbarWrapper>
          }
        />
      </Form>
    </Formik>
  );
}

UploadBar.propTypes = {
  doc: PropTypes.shape({
    docName: PropTypes.string,
    docDate: PropTypes.string,
    docCode: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  }),
};

const UploadbarWrapper = styled.div`
  cursor: pointer;
  margin: 15px 0px 15px 0px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  padding: 5px 10px 5px 10px;
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
