import React from 'react';
import { TalentSearchResultWrapperProps } from 'components/TalentSearch/TalentSearchResultOption';
import { TalentSearch } from 'components/TalentSearch/TalentSearch';
import { Box, Button } from '@localyze-pluto/components';
import { TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';

export const TalentSelect = ({
  onSelectTalent,
}: {
  onSelectTalent: (talent: TalentSearchResult) => void;
}): React.JSX.Element => {
  const TalentSelectWrapper = ({
    talent,
    children,
  }: TalentSearchResultWrapperProps): React.JSX.Element => {
    return (
      <Button onClick={() => onSelectTalent(talent)} p="d0" variant="ghost" w="100%">
        {children}
      </Button>
    );
  };

  return (
    <Box.div data-testid="talent-select">
      <TalentSearch resultWrapper={TalentSelectWrapper} />
    </Box.div>
  );
};
