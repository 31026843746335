import { DELETE_DOCUMENT } from 'deprecated/store/actions/DocumentsContainerActions';
import Card from 'deprecated/components/Card/CardContainer';
import DownloadBar from 'deprecated/components/DownloadBar/DownloadBar';
import UploadBar from 'deprecated/components/UploadBar/UploadBar';
import React, { useContext } from 'react';
import { DocsContainerStore } from 'deprecated/store/root';
import { DocumentWithUrl } from 'modules/documents/types/DocumentWithUrl';
import { deleteDocument } from 'deprecated/api/international/documents';
import { Box, ConfirmationModal, useModalStore } from '@localyze-pluto/components';
import { DocumentsState } from 'deprecated/store/reducers/DocumentsState';
import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon';

type Props = {
  caseId: number;
  height?: string;
  margin?: string;
  padding?: string;
  outlined: boolean;
  document?: DocumentWithUrl;
  title?: string;
};

export const SingleDocumentContainer = ({
  caseId,
  height,
  margin,
  padding,
  outlined,
  document,
  title,
}: Props): React.JSX.Element => {
  const DocsUploadContext = useContext<DocumentsState>(DocsContainerStore);
  const { docList, loading, docCode, docName } = DocsUploadContext.state;
  const { dispatch } = DocsUploadContext;
  const state = useModalStore();
  const isModalOpen = state.useState('open');

  const handleDocDelete = () => {
    const callbackSuccess = () => {
      dispatch({
        payload: { docCode },
        type: DELETE_DOCUMENT,
      });
    };
    deleteDocument(docCode, callbackSuccess, caseId);
  };

  const documentFromList = docList?.find((doc) => doc.docCode === document?.docCode);

  return (
    <div style={{ margin }}>
      <Card
        alignItems={'true'}
        height={height}
        margin={margin}
        outlined={outlined}
        overflow="true"
        padding={padding}
      >
        <Box.div flexBasis="100%">
          {loading && (
            <Box.div display="flex" flexGrow="1" h={60} justifyContent="center">
              <LoadingIcon size="sizeIcon40" />
            </Box.div>
          )}
          {!loading && documentFromList?.docUrl ? (
            <DownloadBar
              caseId={caseId}
              doc={{ ...document, docName: title }}
              key={document?.docName}
              onClickDelete={() => {
                state.show();
              }}
            />
          ) : (
            <UploadBar
              caseId={caseId}
              doc={{ ...document, docName: title }}
              key={document?.docName}
            />
          )}
        </Box.div>
      </Card>
      {isModalOpen && (
        <ConfirmationModal
          confirmLabel="Delete"
          destructive
          onConfirm={handleDocDelete}
          store={state}
        >
          Are you sure you want to delete {docName}?
        </ConfirmationModal>
      )}
    </div>
  );
};
