import { usePartnerServices } from '../api/usePartnerServices/usePartnerServices';
import { usePartnerConfigurations } from '../api/usePartnerConfigurations/usePartnerConfigurations';
import { Partner } from 'modules/additionalServices/types/Partner';

type UsePartnersWithConfigurationParams = {
  companyId: number;
  serviceCategoryId?: number;
  enabled?: boolean;
};

export type PartnerWithConfiguration = Partner & {
  isDisabled?: boolean;
  isPreferred?: boolean;
};

type UsePartnersWithConfigurationResult = {
  partnersWithConfiguration: PartnerWithConfiguration[];
  isSuccess: boolean;
};
export const usePartnersWithConfiguration = ({
  serviceCategoryId,
  companyId,
  enabled,
}: UsePartnersWithConfigurationParams): UsePartnersWithConfigurationResult => {
  const { data: partnerServices, isSuccess: isSuccessPartnerServices } = usePartnerServices({
    serviceCategoryId,
    enabled,
  });
  const { data: partnerConfigurations, isSuccess: isSuccessPartnerConfigurations } =
    usePartnerConfigurations({
      companyId,
      serviceCategoryId,
      enabled,
    });

  if (!isSuccessPartnerServices || !isSuccessPartnerConfigurations) {
    return {
      partnersWithConfiguration: [],
      isSuccess: false,
    };
  }

  const partnersWithConfiguration: PartnerWithConfiguration[] = partnerServices.reduce<
    PartnerWithConfiguration[]
  >((result, partnerService) => {
    const existingPartner = result.find((partner) => partner.id === partnerService.partner.id);

    if (!existingPartner) {
      const partnerConfiguration = partnerConfigurations.find(
        (partnerConfiguration) => partnerConfiguration.partner.id === partnerService.partner.id,
      );

      if (!partnerConfiguration) {
        return [
          ...result,
          {
            ...partnerService.partner,
          },
        ];
      }

      return [
        ...result,
        {
          ...partnerService.partner,
          ...(partnerConfiguration.disabled_at && { isDisabled: true }),
          isPreferred: partnerConfiguration.preferred_partner || false,
        },
      ];
    }

    return result;
  }, []);

  return {
    partnersWithConfiguration,
    isSuccess: true,
  };
};
