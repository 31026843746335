import { CaseMetrics } from 'modules/reporting/api/types/CaseMetrics';
import { Report } from 'modules/reporting/api/types/Report';

const isZeroOrEmpty = (item: unknown[] | number): boolean =>
  item === 0 || (Array.isArray(item) && item.length === 0);

export const areMetricsEmpty = (report?: Report<CaseMetrics>): boolean => {
  const results = report?.results || [];

  return Object.values(results).every(isZeroOrEmpty);
};
