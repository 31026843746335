import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { PartnerConfiguration } from 'modules/partners/types/PartnerConfiguration';

const STALE_TIME_5_MINUTES = 5 * 60 * 1000;

type GetPartnerConfigurationsParams = {
  companyId: number;
  serviceCategoryId?: number;
};

const getPartnerConfigurations = async ({
  companyId,
  serviceCategoryId,
}: GetPartnerConfigurationsParams): Promise<PartnerConfiguration[]> => {
  const { data } = await axios.get<PartnerConfiguration[]>('/partner_configurations', {
    params: { company_id: companyId, service_category_id: serviceCategoryId },
  });

  return data;
};

export const usePartnerConfigurations = ({
  serviceCategoryId,
  companyId,
  enabled,
}: GetPartnerConfigurationsParams &
  Pick<UseQueryOptions<PartnerConfiguration[]>, 'enabled'>): UseQueryResult<
  PartnerConfiguration[]
> => {
  return useQuery({
    queryKey: ['partner-configurations', serviceCategoryId, companyId],
    queryFn: () => getPartnerConfigurations({ serviceCategoryId, companyId }),
    staleTime: STALE_TIME_5_MINUTES,
    enabled,
  });
};
