import includes from 'lodash/includes';
import { CaseType } from 'modules/cases/types/CaseTypeEnum';
import { ServiceCategory } from '../types/ServiceCategory';

const allowedCaseTypes = [CaseType.FullRelocation, CaseType.VisaOnly, CaseType.DomesticRelocation];

export const isAdditionalServiceCategoryRecommended = (
  serviceCategory: ServiceCategory,
  caseTypeName?: CaseType,
): boolean => {
  if (serviceCategory.name === 'Temporary housing') {
    return includes(allowedCaseTypes, caseTypeName);
  }

  return false;
};
