import { Box, Button } from '@localyze-pluto/components';
import React from 'react';

export const UpdateGlomoRequestButton = ({
  isEditMode,
  onSave,
  onEdit,
  onCancel,
  isLoading,
}: {
  isEditMode: boolean;
  isLoading?: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
}): React.JSX.Element =>
  isEditMode ? (
    <Box.div alignItems="center" display="flex" gap="d4">
      <Button loading={isLoading} onClick={onCancel} variant="secondary">
        Cancel
      </Button>
      <Button
        leadingIcon="save"
        loading={isLoading}
        onClick={onSave}
        type="submit"
        variant="primary"
      >
        Save
      </Button>
    </Box.div>
  ) : (
    <Button leadingIcon="pen-square" loading={isLoading} onClick={onEdit} variant="primary">
      Update
    </Button>
  );
