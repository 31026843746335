import React from 'react';
import { StackOneCompanyIntegration } from 'modules/integrations/types/HRISCompanyIntegration';
import { Icon } from '@localyze-pluto/components';

type IntegrationsTipProps = {
  activeIntegration?: StackOneCompanyIntegration;
};

export const IntegrationsTip = ({ activeIntegration }: IntegrationsTipProps): React.JSX.Element => {
  if (!activeIntegration) {
    return (
      <div className="tw-flex tw-bg-white tw-rounded-lg tw-p-4">
        <div>
          <Icon
            aria-hidden="true"
            className="tw-ml-1.5 tw-h-6 tw-w-6 tw-text-blue-300"
            decorative
            icon="info"
          />
        </div>
        <div className="tw-pl-2">
          <span className="tw-block">
            Tip: Connect your Localyze account to other apps you are using to make your life easier
            by following our{' '}
            <a
              href={
                // eslint-disable-next-line max-len
                'https://help.localyze.com/en/articles/8899495-localyze-integrations-human-resources-information-systems-hris'
              }
              rel="noreferrer"
              target="_blank"
            >
              Step-by-Step Instructions
            </a>
            .
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="tw-flex tw-bg-blue-50 tw-rounded-lg tw-p-4">
        <div>
          <Icon
            aria-hidden="true"
            className="tw-ml-1.5 tw-h-6 tw-w-6 tw-text-blue-300"
            decorative
            icon="info"
          />
        </div>
        <div className="tw-pl-2">
          <span className="tw-block">
            Your integration is active. If you want to deactivate it, please contact the Localyze
            team!
          </span>
        </div>
      </div>
    );
  }
};
