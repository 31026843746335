import React from 'react';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

const getCredentials = () => {
  if (window.Cypress) {
    return {
      audience: process.env.REACT_APP_CYPRESS_AUTH0_AUDIENCE,
      clientId: process.env.REACT_APP_CYPRESS_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_CYPRESS_AUTH0_DOMAIN,
    };
  }

  return {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
  };
};

export const Auth0ProviderWithHistory = ({ children }: Props): React.JSX.Element => {
  const history = useHistory();

  const onRedirectCallback = async (appState: AppState | undefined) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        audience: getCredentials().audience,
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
      }}
      cacheLocation={window.Cypress ? 'localstorage' : 'memory'}
      clientId={getCredentials().clientId || ''}
      domain={getCredentials().domain || ''}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
