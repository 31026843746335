import { getFilteredChartEntries } from 'modules/reporting/pages/reporting/components/ReportingGraphs/utils/getFilteredChartEntries';
import { GroupedCaseMetricEntry } from 'modules/reporting/api/types/CaseMetrics';
import { CHART_COLORS } from 'modules/reporting/constants/chartColors';
import { ChartConfig, DataItem } from './getChartConfig';

// sorts labels alphabetically, but puts 'Other' at the end
const sortLabels = (labels: string[]) =>
  labels.sort((a, b) => (a === 'Other' ? 1 : b === 'Other' ? -1 : a.localeCompare(b)));

export const getGroupedChartConfig = (entryGroups: GroupedCaseMetricEntry[]): ChartConfig => {
  const filteredGroups = entryGroups.map((group) => ({
    ...group,
    counters: getFilteredChartEntries(group.counters),
  }));

  const groupedChartData = filteredGroups.map((group) => [
    ['x', group.label],
    ...group.counters.map(({ label, count }) => [label, count]),
  ]);

  const dataSetLabels = [
    ...new Set(filteredGroups.flatMap((group) => group.counters.map((entry) => entry.label))),
  ];

  return {
    labels: sortLabels(filteredGroups.map(({ label }) => label)),
    datasets: sortLabels(dataSetLabels).map((dataSetLabel, index) => ({
      label: dataSetLabel,
      data: groupedChartData.map((group) => Object.fromEntries(group) as DataItem),
      backgroundColor: CHART_COLORS[index],
      datalabels: { color: 'black' },
      parsing: { yAxisKey: dataSetLabel },
    })),
  };
};
