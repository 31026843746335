import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { RequiredDocument } from 'deprecated/api/pipelines/types/RequiredDocument';
import { getPipelineRequiredDocuments } from 'deprecated/api/pipelines/getPipelineRequiredDocuments';
import isUndefined from 'lodash/isUndefined';

export const usePipelineRequiredDocuments = (
  pipelineId?: number,
): UseQueryResult<RequiredDocument[]> => {
  return useQuery({
    queryKey: ['pipeline-required-documents', pipelineId],
    queryFn: () => getPipelineRequiredDocuments(Number(pipelineId)),
    enabled: !isUndefined(pipelineId),
  });
};
