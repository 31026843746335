import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { ReferralCard } from '../types/ReferralCard';

export const useReferralCard = (): UseQueryResult<ReferralCard[]> => {
  const contentfulAPI = new ContentfulAPI();

  return useQuery({
    queryKey: ['referral-card'],
    queryFn: () => contentfulAPI.fetchReferralCard(),
  });
};
