import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { queryClient } from 'config/queryClient';
import { Task } from 'modules/tasks/types/Task';
import { getTasksQueryKey } from 'modules/tasks/api/useTasks/useTasks';
import { PENDING_COUNTERS_KEY } from 'modules/home/api/usePendingCounters';

export type DeleteTaskProps = {
  task: Task;
  caseId: number;
};

const deleteTask = async (task: Task) => {
  const response = await axios.delete(`/tasks/${task.id}`);

  return response;
};

export const useDeleteTask = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, DeleteTaskProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, DeleteTaskProps> =>
  useMutation({
    mutationFn: ({ task }) => deleteTask(task),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getTasksQueryKey({ case_id: variables.caseId, all_items: true }),
      });
      queryClient.invalidateQueries({ queryKey: [PENDING_COUNTERS_KEY] });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
