import { Box } from '@localyze-pluto/components';
import { AccountEntryType } from 'modules/billing/types/AccountEntryType';
import React from 'react';
import { Currency } from 'types/currency';
import { formatPrice } from 'utils/formatters/formatPrice/formatPrice';

export const FormattedAmount = ({
  accountEntryType,
  amount,
  currency,
}: {
  accountEntryType: AccountEntryType;
  amount: number;
  currency: Currency;
}): React.JSX.Element => {
  const sign = accountEntryType === AccountEntryType.Credit ? '-' : '';

  return (
    <Box.span
      aria-label={`account-entry-type-${accountEntryType.toLowerCase()}`}
      color={accountEntryType === AccountEntryType.Debit ? 'colorTextSuccess' : 'colorTextError'}
    >
      {`${sign}${formatPrice(amount, currency, { showCents: true })}`}
    </Box.span>
  );
};
