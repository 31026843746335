import {
  Box,
  Button,
  Icon,
  Menu,
  Tooltip,
  TooltipAnchor,
  useModalStore,
  useTooltipStore,
} from '@localyze-pluto/components';
import { AssignPipelineModal } from 'modules/caseDetails/components/CaseManagement/AssignPipelineModal/AssignPipelineModal';
import { DeleteCasePipelineModal } from 'modules/caseDetails/components/CaseManagement/DeleteCasePipelineModal/DeleteCasePipelineModal';
import { EditCaseStateModal } from 'modules/caseDetails/components/CaseManagement/EditCaseStateModal/EditCaseStateModal';
import { Case } from 'modules/cases/types/Case';
import { useCasePipelines } from 'modules/pipelines/hooks/useCasePipelines';
import React, { useState } from 'react';

const AssignPipelineLabel = ({ disabled }: { disabled: boolean }): React.JSX.Element => {
  const assignPipelineTooltip = useTooltipStore({ placement: 'right' });
  const AssignPipelineMenuOption = () => (
    <Box.div alignItems="center" display="flex" gap="d2">
      <Icon decorative icon="list-plus" size="sizeIcon20" />
      <Box.span>Assign pipeline</Box.span>
    </Box.div>
  );

  return disabled ? (
    <>
      <TooltipAnchor store={assignPipelineTooltip}>
        <AssignPipelineMenuOption />
      </TooltipAnchor>
      <Tooltip store={assignPipelineTooltip}>
        You can&apos;t assign a pipeline because this talent already has one in progress.
      </Tooltip>
    </>
  ) : (
    <AssignPipelineMenuOption />
  );
};

export const EditMenu = ({ kase }: { kase: Case }): React.JSX.Element => {
  const modal = useModalStore();
  const isModalOpen = modal.useState('open');
  const [modalType, setModalType] = useState<'assignPipeline' | 'changeState' | 'deletePipeline'>();
  const modalComponents = {
    changeState: EditCaseStateModal,
    assignPipeline: AssignPipelineModal,
    deletePipeline: DeleteCasePipelineModal,
  };
  const ModalComponent = modalType && modalComponents[modalType];

  const { data: casePipelines, isSuccess: isSuccessCasePipelines } = useCasePipelines(kase.id);
  const activePipelineExists = !!casePipelines?.some((casePipeline) => !casePipeline.completed);

  return (
    <>
      <Menu
        items={[
          {
            label: (
              <Box.div alignItems="center" display="flex" gap="d2">
                <Icon decorative icon="pen-square" size="sizeIcon20" />
                <Box.span>Change case state</Box.span>
              </Box.div>
            ),
            onClick: () => {
              setModalType('changeState');
              modal.show();
            },
          },
          {
            label: <AssignPipelineLabel disabled={activePipelineExists} />,
            onClick: () => {
              setModalType('assignPipeline');
              modal.show();
            },
            disabled: !isSuccessCasePipelines || activePipelineExists,
          },
          {
            label: (
              <Box.div alignItems="center" display="flex" gap="d2">
                <Icon decorative icon="trash-2" size="sizeIcon20" />
                <Box.span>Delete pipeline</Box.span>
              </Box.div>
            ),
            onClick: () => {
              setModalType('deletePipeline');
              modal.show();
            },
            disabled: !isSuccessCasePipelines || casePipelines.length === 0,
          },
        ]}
        menuButton={
          <Button trailingIcon="chevron-down" type="button" variant="secondary">
            Edit
          </Button>
        }
      />

      {isModalOpen && ModalComponent && <ModalComponent kase={kase} modal={modal} />}
    </>
  );
};
